import { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../context';
import { StoryStatus, useGetStoriesByChannelIdQuery } from '../../services';
import {
  StoryFeedItemsHeader,
  useAnalytics,
  useBeforeUnload,
  useDeepLink,
  useDuration,
} from '../../shared';
import { NoContent } from '../Error';
import { StoryFeed } from './StoryFeed';

import classes from './StoryFeedItems.module.scss';

export const StoryFeedItems: FunctionComponent = () => {
  const { channelId } = useContext(UserContext).userInfo.userData;

  const { isDeepLink } = useDeepLink();

  const storyId = Number(useParams().storyId);

  const { data: stories, isFetching } = useGetStoriesByChannelIdQuery({ channelId });

  const { logClickOpenStory } = useAnalytics();

  const { startTrackTime, getDuration } = useDuration();

  const logClickOpen = useCallback(
    () =>
      logClickOpenStory({
        channel_id: channelId,
        story_id: storyId,
        mix_id: storyId,
        time_stamp: new Date().toISOString(),
        duration: getDuration(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channelId, logClickOpenStory, storyId]
  );

  useEffect(() => {
    startTrackTime();
    return () => logClickOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logClickOpen]);

  useBeforeUnload(logClickOpen);

  const story = stories?.find(({ id }) => id === storyId);

  if (isFetching) {
    return <Skeleton height={'10rem'} />;
  }

  if (!story || (isDeepLink && story.status !== StoryStatus.PUBLISHED)) {
    return <NoContent />;
  }

  return (
    <div className={classes['feed-items']}>
      <StoryFeedItemsHeader story={story} />
      <StoryFeed storyId={storyId} />
    </div>
  );
};
