import { FunctionComponent, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfigContext, UserContext } from '../../context';
import { Button, ButtonType } from '../../shared';
import { Profile } from './Profile';
import { ProfileFeed } from './ProfileFeed';
import { UserFeed } from './UserFeed';

import classes from './Profile.module.scss';

export const ProfileMe: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { userProfile } = useContext(UserContext);

  const { freeLogin } = useContext(ConfigContext).config.elements;

  const { id: userId, avatar, screenName, bio, links, isAnonymous, roleId, url } = userProfile;

  const { subTitleLocaliseKey = '' } = { ...freeLogin };

  const bottomContent = useMemo(() => {
    if (isAnonymous) {
      return (
        <div className={classes['profile__anonymous']}>
          {subTitleLocaliseKey && (
            <div className={classes['profile__anonymous-text']}>{t(subTitleLocaliseKey)}</div>
          )}
          <Button
            type={ButtonType.primary}
            label={`${t('common.login')} / ${t('auth.sign-up')}`}
            onClick={() => navigate('/auth/login')}
          />
        </div>
      );
    }

    return (
      <div className={classes['profile__feeds-wrapper']}>
        <ProfileFeed userId={userId} />
        <UserFeed userId={userId} />
      </div>
    );
  }, [isAnonymous, navigate, subTitleLocaliseKey, t, userId]);

  return (
    <>
      <Profile
        userId={userId}
        roleId={roleId}
        avatar={avatar}
        screenName={screenName}
        bio={bio}
        links={links}
        url={url}
      />
      {bottomContent}
    </>
  );
};
