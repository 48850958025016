import {
  ChangeEvent,
  ClipboardEventHandler,
  HTMLInputTypeAttribute,
  forwardRef,
  memo,
  useCallback,
  useMemo,
} from 'react';
import { getCssVar } from '../../utils';
import { IconLabel } from '../IconLabel';

import classNames from 'classnames';
import classes from './InputField.module.scss';

interface InputFieldProps {
  value: string;
  label?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  icon?: JSX.Element;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  borderless?: boolean;
  min?: number; //used for type number
  max?: number; //used for type number
  autoFocus?: boolean;
  className?: string;
  inputClassName?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onPaste?: ClipboardEventHandler<HTMLInputElement>;
}

export const InputField = memo(
  forwardRef<HTMLInputElement, InputFieldProps>(
    (
      {
        value,
        label,
        placeholder,
        type = 'text',
        disabled = false,
        error = false,
        errorMessage,
        icon,
        onChange,
        maxLength,
        borderless,
        min,
        max,
        autoFocus,
        className,
        inputClassName,
        onFocus,
        onBlur,
        onPaste,
      },
      ref
    ) => {
      const isInputNumber = useMemo(() => type === 'number', [type]);

      const renderError = useMemo(() => {
        if (!error || disabled) {
          return null;
        }

        return (
          <>
            <IconLabel
              className={classNames(classes['input-field__icon'], {
                [classes['input-field__icon--without-label']]: !label,
              })}
              iconId={'alert'}
              iconSize={18}
              color={getCssVar('--input-icon-error-color')}
              singleColor
              nonClickable
            />
            {errorMessage && (
              <div className={classes['input-field__error-message']}>{errorMessage}</div>
            )}
          </>
        );
      }, [disabled, error, errorMessage, label]);

      const inputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          const eventCopy = { ...event };

          const { target } = eventCopy;

          if (isInputNumber && min && parseInt(target.value, 10) < min) {
            target.value = min.toString();
          }

          if (isInputNumber && max && parseInt(target.value, 10) > max) {
            target.value = max.toString();
          }

          onChange?.(eventCopy);
        },
        [isInputNumber, max, min, onChange]
      );

      return (
        <div className={classNames(classes['input-field'], className)}>
          {label && (
            <label
              className={classNames(classes['input-field__label'], {
                [classes['input-field__label--clickable']]: !disabled,
              })}
              htmlFor={label}
            >
              {label}
            </label>
          )}

          <input
            id={label}
            ref={ref}
            type={type}
            className={classNames(
              classes['input-field__value'],
              {
                [classes['input-field__value--error']]: error,
                [classes['input-field__value--borderless']]: borderless,
                [classes['input-field__value--number']]: isInputNumber,
              },
              inputClassName
            )}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={inputChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onPaste={onPaste}
            maxLength={maxLength}
            min={min}
            max={max}
            autoFocus={autoFocus}
          />

          {renderError}

          <div
            className={classNames(classes['input-field__icon'], {
              [classes['input-field__icon--without-label']]: !label,
            })}
          >
            {icon}
          </div>
        </div>
      );
    }
  )
);
