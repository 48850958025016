import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context';
import {
  RestrictDuplicateUrlOfCardsInTimeFrame,
  StoryCardLimit,
  StoryDisplayItemUpdatedTime,
} from '../../../../../services';
import {
  FilterGroup,
  FilterGroupItemsAlignment,
  FilterValue,
  optArrItem,
  useFilter,
} from '../../../../../shared';
import {
  OTHER,
  OTHER_CARD_LIMIT,
  OTHER_DUPLICATES,
  OTHER_TIME,
  OtherProps,
} from '../../CreateStoryModal';

export const useCreateStoryOtherFilter = (filter: FilterValue[]) => {
  const { t } = useTranslation();

  const { getGroupValue } = useFilter();

  const {
    userInfo: {
      userData: { channelRestrictDuplicateUrlOfCardsGQL },
    },
    userProfile: { isStaff },
  } = useContext(UserContext);

  const restrictDuplicateDisabled = useMemo(
    () => channelRestrictDuplicateUrlOfCardsGQL !== RestrictDuplicateUrlOfCardsInTimeFrame.NEVER,
    [channelRestrictDuplicateUrlOfCardsGQL]
  );

  const restrictDuplicateValue = useMemo(
    () =>
      getGroupValue({ filter, groupId: OTHER_DUPLICATES }) ??
      RestrictDuplicateUrlOfCardsInTimeFrame.NEVER,
    [filter, getGroupValue]
  );

  const cardLimitValue = useMemo(
    () => getGroupValue({ filter, groupId: OTHER_CARD_LIMIT }) ?? StoryCardLimit.ONE_THOUSAND,
    [filter, getGroupValue]
  );

  return {
    groups: [
      {
        id: OTHER,
        multiCheck: true,
        items: [
          {
            id: OtherProps.shouldSyncItemPositionsOnConnectedStories,
            label: t('createStorySettings.sync-positions'),
            value: OtherProps.shouldSyncItemPositionsOnConnectedStories,
          },
        ],
      },
      {
        id: OTHER_TIME,
        title: t('createStorySettings.time-stamp'),
        itemsAlignment: FilterGroupItemsAlignment.ROW,
        items: [
          {
            id: StoryDisplayItemUpdatedTime.POSTED_TIME,
            label: t('common.posted-time'),
            value: StoryDisplayItemUpdatedTime.POSTED_TIME,
          },
          {
            id: StoryDisplayItemUpdatedTime.UPDATED,
            label: t('common.updated-time'),
            value: StoryDisplayItemUpdatedTime.UPDATED,
          },
        ],
      },
      {
        id: OTHER_DUPLICATES,
        title: t('createStorySettings.avoid-duplicates'),
        value: restrictDuplicateValue,
        dropdown: {
          disabled: restrictDuplicateDisabled,
          options: [
            { id: RestrictDuplicateUrlOfCardsInTimeFrame.NEVER, title: t('common.never') },
            {
              id: RestrictDuplicateUrlOfCardsInTimeFrame.ONE_HOUR,
              title: t('common.hours', { count: 1 }),
            },
            {
              id: RestrictDuplicateUrlOfCardsInTimeFrame.THREE_HOURS,
              title: t('common.hours', { count: 3 }),
            },
            {
              id: RestrictDuplicateUrlOfCardsInTimeFrame.SIX_HOURS,
              title: t('common.hours', { count: 6 }),
            },
            {
              id: RestrictDuplicateUrlOfCardsInTimeFrame.HALF_DAY,
              title: t('common.hours', { count: 12 }),
            },
            {
              id: RestrictDuplicateUrlOfCardsInTimeFrame.ONE_DAY,
              title: t('common.days', { count: 1 }),
            },
            {
              id: RestrictDuplicateUrlOfCardsInTimeFrame.TWO_DAYS,
              title: t('common.days', { count: 2 }),
            },
            {
              id: RestrictDuplicateUrlOfCardsInTimeFrame.THREE_DAYS,
              title: t('common.days', { count: 3 }),
            },
            {
              id: RestrictDuplicateUrlOfCardsInTimeFrame.ONE_WEEK,
              title: t('common.days', { count: 7 }),
            },
          ],
        },
      },
      ...optArrItem(isStaff, {
        id: OTHER_CARD_LIMIT,
        title: t('createStorySettings.card-limit'),
        value: cardLimitValue,
        dropdown: {
          options: [
            { id: StoryCardLimit.ONE_THOUSAND, title: 1000 },
            { id: StoryCardLimit.TWO_THOUSANDS, title: 2000 },
            { id: StoryCardLimit.FIVE_THOUSANDS, title: 5000 },
            { id: StoryCardLimit.TEN_THOUSANDS, title: 10000 },
            { id: StoryCardLimit.TWENTY_THOUSANDS, title: 20000 },
            { id: StoryCardLimit.UNLIMITED, title: t('common.unlimited') },
          ],
        },
      }),
    ] as FilterGroup[],
  };
};
