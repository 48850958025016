import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Config } from '../../../services';
import { Button, ButtonType } from '../../components';
import { NOTIFICATIONS_PERMISSION_COOKIE_KEY } from '../../constants';
import { cookieOptions, isPushNotificationsAllowed } from '../../utils';
import { useDeepLink } from '../useDeepLink';

import classNames from 'classnames';
import classes from './useNotificationsPermissionPopup.module.scss';

export const useNotificationsPermissionPopup = (config?: Config) => {
  const { t } = useTranslation();

  const { isDeepLink } = useDeepLink();

  const [cookies, setCookie] = useCookies([NOTIFICATIONS_PERMISSION_COOKIE_KEY]);

  const { requested = false } = { ...cookies[NOTIFICATIONS_PERMISSION_COOKIE_KEY] };

  const nonDefaultPermission = useMemo(() => window.Notification?.permission !== 'default', []);

  const abandonPopup = useMemo(
    () => Boolean(!isPushNotificationsAllowed || requested || nonDefaultPermission || isDeepLink),
    [isDeepLink, nonDefaultPermission, requested]
  );

  useEffect(() => {
    if (!config || abandonPopup) {
      return;
    }

    const {
      titleLocaliseKey = null,
      introLocaliseKey = null,
      allowLocaliseKey = null,
      denyLocaliseKey = null,
    } = { ...config.elements.notificationsPermission };

    const content = (
      <div className={classNames(classes['permission'])}>
        <div className={classes['permission__title']}>
          {t(titleLocaliseKey ?? 'notificationsPermission.title')}
        </div>
        <div className={classes['permission__intro']}>
          {t(introLocaliseKey ?? 'notificationsPermission.intro')}
        </div>
        <div className={classes['permission__buttons']}>
          <Button
            type={ButtonType.secondary}
            label={t(denyLocaliseKey ?? 'notificationsPermission.deny')}
          />
          <Button
            type={ButtonType.primary}
            label={t(allowLocaliseKey ?? 'notificationsPermission.allow')}
            onClick={() => Promise.resolve(Notification.requestPermission())}
          />
        </div>
      </div>
    );

    const timer = setTimeout(() => {
      toast(content, { position: 'top-center', autoClose: false });
      setCookie(NOTIFICATIONS_PERMISSION_COOKIE_KEY, { requested: true }, cookieOptions());
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [abandonPopup, config, setCookie, t]);
};
