import { useLocation, useParams } from 'react-router-dom';
import { Story } from '../../../../../services';

interface UsePostInStoryIdDefaultArgs {
  postInStories: Story[];
  postInStorageKey: string;
}

export const usePostInStoryIdDefault = ({
  postInStories,
  postInStorageKey,
}: UsePostInStoryIdDefaultArgs) => {
  const { pathname } = useLocation();

  const storyId = Number(useParams().storyId);

  const items = `${storyId}/items`;

  const routes = [
    `/storyTab1/${items}`,
    `/storyTab2/${items}`,
    `/pinnedStoryTab/${items}`,
    `/admin/storyTab/${items}`,
  ];

  const id = routes.includes(pathname)
    ? storyId
    : parseInt(localStorage.getItem(postInStorageKey) ?? '', 10);

  if (postInStories.find((postInStory) => postInStory.id === id)) {
    return id;
  }

  return postInStories[0]?.id;
};
