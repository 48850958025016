import { FunctionComponent } from 'react';
import { CardActionsAdmin, DetailsContentCommon, DetailsContentProps } from '../../../shared';

export const DetailsContentAdmin: FunctionComponent<DetailsContentProps> = ({
  card,
  chatId,
  scrollToTags,
  scrollToComments,
  activeCommentsInput,
  commentsWrapper,
}) => {
  return (
    <DetailsContentCommon
      card={card}
      scrollToTags={scrollToTags}
      scrollToComments={scrollToComments}
      activeCommentsInput={activeCommentsInput}
      commentsWrapper={commentsWrapper}
      chatId={chatId}
      CardActionsComponent={CardActionsAdmin}
    />
  );
};
