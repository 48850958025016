import { FunctionComponent, memo, useState } from 'react';
import { Deeplink, DeeplinkType, DropdownMenu, Popup, getCssVar } from '../../../../shared';

import dropdownMenuClasses from '../../../../shared/components/DropdownMenu/DropdownMenu.module.scss';
import classes from './StoryMenuAdmin.module.scss';

interface StoryMenuAdminProps {
  storyId: number;
}

export const StoryMenuAdmin: FunctionComponent<StoryMenuAdminProps> = memo(({ storyId }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <Popup
      isOpen={isMenuOpen}
      setIsOpen={setIsMenuOpen}
      iconId={'dots-menu'}
      color={getCssVar('--action-pane-icon-color')}
      hoverColor={getCssVar('--action-pane-icon-hover-color')}
      parentClassName={classes['story-menu']}
      bodyBottom={'1.5rem'}
      bodyRight={'0'}
      body={
        <DropdownMenu
          width={'10rem'}
          content={
            <Deeplink
              type={DeeplinkType.STORY}
              storyId={storyId}
              onClick={() => setIsMenuOpen(false)}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
            />
          }
        />
      }
    />
  );
});
