import { FunctionComponent, memo } from 'react';

import { StoryCard, StoryCardType } from '../../../../services';
import { Article } from './Article';
import { Editorial } from './Editorial';
import { Media } from './Media';
import { Post } from './Post';
import { Social } from './Social';
import { Thread } from './Thread';

export interface LatestsStoryCardProps {
  card: StoryCard;
}

export const LatestsStoryCard: FunctionComponent<LatestsStoryCardProps> = memo(({ card }) => {
  switch (card.type) {
    case StoryCardType.IMAGE:
    case StoryCardType.AUDIO:
    case StoryCardType.VIDEO:
    case StoryCardType.PDF:
      return <Media card={card} />;
    case StoryCardType.EDITORIAL:
      return <Editorial card={card} />;
    case StoryCardType.THREAD:
      return <Thread card={card} />;
    case StoryCardType.QUOTE:
      return <Social card={card} />;
    case StoryCardType.ARTICLE:
      return <Article card={card} />;
    case StoryCardType.POST:
      return <Post card={card} />;
    default:
      return null;
  }
});
