/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../../services';
import { Avatar, FilterGroupAutoComplete, FilterValue } from '../../../components';
import { useFilter } from '../useFilter';

import classes from './useAuthorsFilter.module.scss';

interface UseAuthorsFilterArgs {
  filter: FilterValue[];
  fetchItemsQuery: any;
  fetchItemsQueryArgs: any;
}

export const useAuthorsFilter = ({
  filter,
  fetchItemsQuery,
  fetchItemsQueryArgs,
}: UseAuthorsFilterArgs) => {
  const { t } = useTranslation();

  const { getGroupById } = useFilter();

  const itemTemplate = useCallback(({ screenName, avatar }: User) => {
    return (
      <>
        <Avatar url={avatar?.url} />
        <div className={classes['name']}>{screenName}</div>
      </>
    );
  }, []);

  const groupId = 'authors';

  const autoComplete: FilterGroupAutoComplete = {
    propToIdentify: 'id',
    propToDisplay: 'screenName',
    fetchItemsQuery,
    fetchItemsQueryArgs,
    itemTemplate,
    placeholder: t('authorsFilter.placeholder'),
  };

  return {
    group: {
      id: groupId,
      title: t('authorsFilter.title'),
      value: getGroupById({ filter, groupId })?.groupItems ?? [],
      allowReset: true,
      autoComplete,
    },
  };
};
