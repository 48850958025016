import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';
import {
  ChannelAppTabAction,
  StoriesByChannelIdFilter,
  StoryAccess,
  StorySection,
  StoryStatus,
  StoryType,
} from '../../../services';
import {
  Filter,
  FilterGroup,
  FilterValue,
  STORY_TAB_ADMIN_COOKIE_KEY,
  cookieOptions,
  useAppDispatch,
  useAppTabsLabel,
  useFilter,
} from '../../../shared';
import { getStories } from '../../../slices';

import classes from './StoryTabListAdminRightSide.module.scss';

const SECTIONS_GROUP_ID = 'sections';
const STATUS_GROUP_ID = 'status';
const TYPE_GROUP_ID = 'type';
const ACCESS_GROUP_ID = 'access';

export const StoryTabListAdminRightSide: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { getAppTabsLabel } = useAppTabsLabel();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const [cookies, setCookie] = useCookies([STORY_TAB_ADMIN_COOKIE_KEY]);

  const [filter, setFilter] = useState<FilterValue[]>(
    cookies[STORY_TAB_ADMIN_COOKIE_KEY]?.filter ?? []
  );

  const { getGroupValue } = useFilter();

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      const section = getGroupValue({ filter, groupId: SECTIONS_GROUP_ID, multiCheck: true });
      const status = getGroupValue({ filter, groupId: STATUS_GROUP_ID, multiCheck: true });
      const access = getGroupValue({ filter, groupId: ACCESS_GROUP_ID, multiCheck: true });
      const type = getGroupValue({ filter, groupId: TYPE_GROUP_ID, multiCheck: true });

      const requestFilter: StoriesByChannelIdFilter = {
        ...(section?.length && { section }),
        ...(status?.length && { status }),
        ...(type?.length && { type }),
        ...(access?.length && { access }),
      };

      setCookie(STORY_TAB_ADMIN_COOKIE_KEY, { requestFilter, filter }, cookieOptions());

      dispatch(getStories({ channelId, filter: requestFilter }));

      setFilter(filter);
    },
    [channelId, dispatch, getGroupValue, setCookie]
  );

  const groups: FilterGroup[] = [
    {
      id: SECTIONS_GROUP_ID,
      title: t(`common.${SECTIONS_GROUP_ID}`),
      multiCheck: true,
      allowReset: true,
      items: [
        {
          id: StorySection.WEB_FEED,
          label: `${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)} ${t('common.web')}`,
          value: StorySection.WEB_FEED,
        },
        {
          id: StorySection.MOBILE_FEED,
          label: `${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)} ${t('common.mobile')}`,
          value: StorySection.MOBILE_FEED,
        },
        {
          id: StorySection.MIX_LIST,
          label: getAppTabsLabel(ChannelAppTabAction.STORIES),
          value: StorySection.MIX_LIST,
        },
        {
          id: StorySection.MIX_LIST_2,
          label: getAppTabsLabel(ChannelAppTabAction.STORIES2),
          value: StorySection.MIX_LIST_2,
        },
        {
          id: StorySection.PINNED,
          label: getAppTabsLabel(ChannelAppTabAction.PINNED_STORY),
          value: StorySection.PINNED,
        },
        {
          id: StorySection.HORIZONTAL,
          label: t(`storySections.${StorySection.HORIZONTAL}`),
          value: StorySection.HORIZONTAL,
        },
        {
          id: StorySection.UNASSIGNED,
          label: t(`storySections.${StorySection.UNASSIGNED}`),
          value: StorySection.UNASSIGNED,
        },
      ],
    },
    {
      id: STATUS_GROUP_ID,
      title: t(`common.${STATUS_GROUP_ID}`),
      multiCheck: true,
      allowReset: true,
      items: [
        {
          id: StoryStatus.PUBLISHED,
          label: t('common.published'),
          value: StoryStatus.PUBLISHED,
        },
        {
          id: StoryStatus.UNPUBLISHED,
          label: t('common.unpublished'),
          value: StoryStatus.UNPUBLISHED,
        },
      ],
    },
    {
      id: TYPE_GROUP_ID,
      title: t(`common.${TYPE_GROUP_ID}`),
      multiCheck: true,
      allowReset: true,
      items: [
        {
          id: StoryType.STANDARD,
          label: t('common.standard'),
          value: StoryType.STANDARD,
        },
        {
          id: StoryType.READONLY,
          label: t('common.readonly'),
          value: StoryType.READONLY,
        },
      ],
    },
    {
      id: ACCESS_GROUP_ID,
      title: t(`common.${ACCESS_GROUP_ID}`),
      multiCheck: true,
      allowReset: true,
      items: [
        {
          id: StoryAccess.EDITOR_LIMITED,
          label: t('roles.editor-limited'),
          value: StoryAccess.EDITOR_LIMITED,
        },
        {
          id: StoryAccess.READER,
          label: t('roles.reader'),
          value: StoryAccess.READER,
        },
      ],
    },
  ];

  return (
    <div className={classes['story-tab-list-right-side']}>
      <Filter groups={groups} filter={filter} onChange={filterChange} resetPageScrollOnChange />
    </div>
  );
};
