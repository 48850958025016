import { Fragment, FunctionComponent, memo, useCallback, useMemo } from 'react';
import { CommentAttachment } from '../../../../services';
import { CloseButton } from '../../CloseButton';
import { IconLabel } from '../../IconLabel';
import { ImageSingle } from '../../ImageGallery';
import { VideoPlayer } from '../../VideoPlayer';

import classNames from 'classnames';
import classes from './CommentAttachments.module.scss';

interface CommentAttachmentsProps {
  attachments: CommentAttachment[];
  setAttachments?: (attachments: CommentAttachment[]) => void;
}

export const CommentAttachments: FunctionComponent<CommentAttachmentsProps> = memo(
  ({ attachments, setAttachments }) => {
    const previewMode = useMemo(() => !Boolean(setAttachments), [setAttachments]);

    const removeAttachment = useCallback(
      (index: number) => {
        setAttachments?.(attachments.filter((_, i) => i !== index));
      },
      [attachments, setAttachments]
    );

    const renderRemoveButton = useCallback(
      (index: number) => {
        if (previewMode) {
          return null;
        }

        return (
          <CloseButton
            className={classes['comment-attachments__remove']}
            onClick={() => removeAttachment(index)}
          />
        );
      },
      [previewMode, removeAttachment]
    );

    const attachmentsPreview = useMemo(() => {
      return attachments.map(({ image, video }, index) => {
        if (video) {
          const { url } = video;

          if (!url) {
            return (
              <div
                key={video.id}
                className={classes['comment-attachments__video-placeholder']}
                style={{ backgroundImage: `url(${image?.url})` }}
              >
                <IconLabel
                  iconId={'video'}
                  iconSize={32}
                  className={classes['comment-attachments__video-placeholder-icon']}
                  color={'#ffffff'}
                  nonClickable
                  singleColor
                />
                {renderRemoveButton(index)}
              </div>
            );
          }

          return (
            <Fragment key={video.id}>
              <VideoPlayer url={video.url} className={classes['comment-attachments__video']} />
              {renderRemoveButton(index)}
            </Fragment>
          );
        }

        if (image) {
          return (
            <Fragment key={image.id}>
              <div className={classes['comment-attachments__image-wrapper']}>
                <ImageSingle item={{ image }} modalPreviewOnClick={previewMode} />
              </div>
              {renderRemoveButton(index)}
            </Fragment>
          );
        }

        return null;
      });
    }, [attachments, previewMode, renderRemoveButton]);

    if (!attachments.length) {
      return null;
    }

    return (
      <div
        className={classNames(classes['comment-attachments'], {
          [classes['comment-attachments--preview']]: previewMode,
        })}
      >
        {attachmentsPreview}
      </div>
    );
  }
);
