/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { storyCardApi } from '../../../../../../services';
import {
  FileAcceptType,
  ImageUploadType,
  TransloaditAuthTemplate,
  UploadHandlerArgs,
  UploadType,
  useLoaderText,
} from '../../../../../hooks';
import {
  AudioBlockDataItem,
  FileBlockDataItem,
  ImageBlockDataItem,
  PdfBlockDataItem,
  VideoBlockDataItem,
} from '../../../../../models';
import {
  getAcceptTypeByCardType,
  getAcceptTypeByFileType,
  getStoryCardParsedUrlFile,
} from '../../../../../utils';
import { Button, ButtonType } from '../../../../Button';
import { CircularLoader } from '../../../../CircularLoader';
import { InputField } from '../../../../InputField';
import { TabItem, Tabs } from '../../../../Tabs';

import classes from './MediaUpload.module.scss';

export enum MediaUploadTabType {
  FILE,
  LINK,
}

type MediaItems =
  | ImageBlockDataItem[]
  | AudioBlockDataItem[]
  | VideoBlockDataItem[]
  | PdfBlockDataItem[]
  | FileBlockDataItem[];

interface MediaUploadProps {
  dispatch: any;
  uploadHandler: (args: UploadHandlerArgs) => Promise<{ id: number; assembly_id: string }>;
  template: TransloaditAuthTemplate;
  uploadType: UploadType;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  items: MediaItems;
  setItems: (items: MediaItems) => void;
  pasteFile: File | null;
  fileAcceptType: FileAcceptType;
  multiple?: boolean;
  uploadLimit?: number;
}

interface GetItemProps {
  id: number;
  acceptType: FileAcceptType;
  file?: File;
  url?: string;
}

export const MediaUpload: FunctionComponent<MediaUploadProps> = memo(
  ({
    dispatch,
    loading,
    setLoading,
    items,
    setItems,
    pasteFile,
    uploadHandler,
    template,
    uploadType,
    fileAcceptType,
    multiple,
    uploadLimit,
  }) => {
    const { t } = useTranslation();

    const { loaderText } = useLoaderText(loading);

    const [selectedTabIndex, setSelectedTabIndex] = useState(MediaUploadTabType.FILE);

    const fileRef = useRef<HTMLInputElement | null>(null);

    const getItem = useCallback(({ id, acceptType, file, url }: GetItemProps) => {
      switch (acceptType) {
        case FileAcceptType.IMAGE:
          return { image: { id, url: url ?? (file && URL.createObjectURL(file)) }, caption: '' };
        case FileAcceptType.AUDIO:
          return { audio: { id } };
        case FileAcceptType.VIDEO:
          return { video: { id } };
        case FileAcceptType.PDF:
          return { pdf: { id } };
        case FileAcceptType.FILE:
          return { file: { id } };
      }
    }, []);

    const onFileChange = useCallback(
      async (files: FileList | File[] | null) => {
        if (!files?.length || (uploadLimit && items.length >= uploadLimit)) {
          return;
        }

        setLoading(true);

        const uploadedFiles = [];

        const uploadedFilesCount = items.length + files.length;

        const filesToProcess =
          uploadLimit && uploadedFilesCount > uploadLimit
            ? Array.from(files).slice(0, uploadLimit - uploadedFilesCount)
            : files;

        for (const file of filesToProcess) {
          const { type } = file;

          const fileType = type.includes('application/') ? type : type.split('/')[0];

          const acceptType = getAcceptTypeByFileType(fileType);

          if (!acceptType || fileAcceptType !== acceptType) {
            toast.error(t('fileUpload.file-type-error'));
            continue;
          }

          const { id } = await uploadHandler({
            file,
            template,
            type: uploadType,
            ...(acceptType === FileAcceptType.IMAGE && { imageUploadType: ImageUploadType.ITEM }),
          });

          uploadedFiles.push(getItem({ id, file, acceptType }));

          setItems([...items, ...uploadedFiles] as MediaItems);
        }

        setLoading(false);
      },
      [
        fileAcceptType,
        getItem,
        items,
        setItems,
        setLoading,
        t,
        template,
        uploadHandler,
        uploadLimit,
        uploadType,
      ]
    );

    useEffect(() => {
      if (!pasteFile) {
        return;
      }
      onFileChange([pasteFile]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pasteFile]);

    const fileUploadContent = useMemo(() => {
      return (
        <>
          <input
            type={'file'}
            ref={fileRef}
            disabled={loading}
            accept={fileAcceptType}
            className={classes['content__file']}
            onChange={({ target }) => onFileChange(target.files)}
            onClick={({ target }) => ((target as HTMLInputElement).value = '')}
            multiple={multiple}
          />

          <Button
            label={`${t('fileUpload.upload')} ${t(`fileUploadTypes.${uploadType}`)}`}
            type={ButtonType.secondary}
            onClick={() => fileRef.current?.click()}
            className={classes['content__button']}
          />
        </>
      );
    }, [fileAcceptType, loading, multiple, onFileChange, t, uploadType]);

    const uploadFileByUrl = useCallback(
      async (url: string) => {
        if (!url) {
          return;
        }

        setLoading(true);

        if (uploadType === UploadType.FILE) {
          const { id } = await uploadHandler({
            url,
            template: TransloaditAuthTemplate.URL,
            type: uploadType,
          });

          if (!id) {
            toast.error(t('fileUpload.error'));
            setLoading(false);
            return;
          }

          setItems([...items, getItem({ id, acceptType: FileAcceptType.FILE, url })] as MediaItems);
          setLoading(false);
          return;
        }

        const parsedData = await dispatch(
          storyCardApi.endpoints.storyCardParseUrl.initiate({ url })
        ).unwrap();

        const acceptType = getAcceptTypeByCardType(parsedData?.type);

        if (!acceptType || fileAcceptType !== acceptType) {
          toast.error(t('fileUpload.file-type-error'));
          setLoading(false);
          return;
        }

        const file = getStoryCardParsedUrlFile(parsedData);

        if (!file) {
          setLoading(false);
          return;
        }

        setItems([...items, getItem({ id: file.id, acceptType, url })] as MediaItems);

        setLoading(false);
      },
      [dispatch, fileAcceptType, getItem, items, setItems, setLoading, t, uploadHandler, uploadType]
    );

    const pasteFileHandler = useCallback(
      (event: React.ClipboardEvent<HTMLInputElement>) => {
        const { clipboardData } = event;
        const { files } = clipboardData;

        if (files.length) {
          onFileChange(files);
          return;
        }

        uploadFileByUrl(clipboardData.getData('Text'));
      },
      [onFileChange, uploadFileByUrl]
    );

    const linkUploadContent = useMemo(() => {
      return (
        <InputField
          value={''}
          placeholder={t(`fileUpload.paste-url-or-${uploadType}`)}
          onPaste={pasteFileHandler}
          autoFocus
        />
      );
    }, [pasteFileHandler, t, uploadType]);

    const tabContent = useCallback((content: JSX.Element) => {
      return <div className={classes['content']}>{content}</div>;
    }, []);

    const tabItems: TabItem[] = useMemo(
      () => [
        {
          index: MediaUploadTabType.FILE,
          name: t('fileUpload.file'),
          content: tabContent(fileUploadContent),
        },
        {
          index: MediaUploadTabType.LINK,
          name: t('fileUpload.embed'),
          content: tabContent(linkUploadContent),
        },
      ],
      [fileUploadContent, linkUploadContent, t, tabContent]
    );

    if (loading) {
      return <CircularLoader text={loaderText} />;
    }

    return (
      <Tabs
        key={selectedTabIndex}
        items={tabItems}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
      />
    );
  }
);
