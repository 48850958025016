import { ElementType, FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ConfigContext } from '../../../../context';
import { Story, StoryCard, StoryCardStatus } from '../../../../services';
import { horizontalStoryFeedLoadMore } from '../../../../slices';
import { useAppDispatch } from '../../../hooks';
import { PaginationInfo } from '../../../models';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';

import classes from './HorizontalStoryFeedCommon.module.scss';

export interface HorizontalStoryFeedProps {
  story: Story;
  cards: StoryCard[];
  pageInfo: PaginationInfo;
  zIndex: number;
}

export interface HorizontalStoryFeedCardProps {
  card: StoryCard;
}

interface HorizontalStoryFeedCommonProps extends HorizontalStoryFeedProps {
  CardComponent: ElementType<HorizontalStoryFeedCardProps>;
  viewAllClick: () => void;
}

export const HorizontalStoryFeedCommon: FunctionComponent<HorizontalStoryFeedCommonProps> = memo(
  ({ story, cards, pageInfo, zIndex, CardComponent, viewAllClick }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { config } = useContext(ConfigContext);

    const { perPage, hasNextPage } = pageInfo;

    const { cardsPerView = 3, spaceBetweenCards = 12 } = { ...config.elements.horizontalFeed };

    const slidesPerView = useMemo(() => (isMobileOnly ? 2 : cardsPerView), [cardsPerView]);

    const { title, id: storyId } = story;

    const cardsLength = useMemo(() => cards.length, [cards.length]);

    const onSlideChangeHandler = useCallback(
      (activeIndex: number) => {
        const index = activeIndex + slidesPerView;

        if (index >= cardsLength && hasNextPage) {
          dispatch(
            horizontalStoryFeedLoadMore({
              storyId,
              page: Math.floor(index / perPage) + 1,
              size: perPage,
              filter: { onlyCompletedMedia: true, status: [StoryCardStatus.PUBLISHED] },
            })
          );
        }
      },
      [cardsLength, dispatch, hasNextPage, perPage, slidesPerView, storyId]
    );

    return (
      <div className={classes['horizontal-feed']}>
        <div className={classes['horizontal-feed__header']}>
          <div className={classes['horizontal-feed__header-title']}>{title}</div>
          <IconLabel
            className={classes['horizontal-feed__header-view']}
            iconId={'right-arrow'}
            iconSize={18}
            label={t('common.view-all')}
            onClick={viewAllClick}
            color={getCssVar('--base-link-text-color')}
            hoverColor={getCssVar('--base-link-text-hover-color')}
            labelFirst
          />
        </div>

        <Swiper
          navigation
          keyboard={{ enabled: true, onlyInViewport: true }}
          slidesPerView={slidesPerView}
          spaceBetween={spaceBetweenCards}
          modules={[Navigation, Keyboard]}
          onSlideChange={({ activeIndex }) => onSlideChangeHandler(activeIndex)}
          style={{ overflow: 'visible', zIndex }} //needed to show card actions popup
          touchStartPreventDefault={false} //needed for useOnClickOutside for card actions popup
        >
          {cards.map((card) => (
            <SwiperSlide key={card.id}>
              <CardComponent key={card.id} card={card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
);
