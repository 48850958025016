import {
  Fragment,
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfigContext, UserContext } from '../../context';
import { OrganisationUserRole, UserSocialLinks, rootApi, userApi } from '../../services';
import {
  AbuseReportType,
  Avatar,
  Button,
  ButtonType,
  ConfirmationModal,
  CookieActionType,
  DSAReportType,
  Deeplink,
  DeeplinkType,
  DropdownMenu,
  IconLabel,
  ImageFile,
  Modal,
  Popup,
  USER_DEVICE_COOKIE_KEY,
  cookieOptions,
  getCssVar,
  getOrganisationDomain,
  layoutPath,
  useAbuseReportPopup,
  useAppDispatch,
  useDSAReportPopup,
} from '../../shared';
import { TwoFactorAuthSetup, TwoFactorAuthSetupProps } from '../Auth';
import { ProfileRightSide } from './ProfileRightSide';

import dropdownMenuClasses from '../../shared/components/DropdownMenu/DropdownMenu.module.scss';
import classes from './Profile.module.scss';

interface ProfileProps {
  userId: number;
  screenName: string;
  bio: string;
  links: UserSocialLinks;
  url: string;
  roleId?: OrganisationUserRole;
  avatar?: ImageFile;
  me?: boolean;
}

export const Profile: FunctionComponent<ProfileProps> = memo(
  ({ userId, avatar, screenName, bio, links, url, roleId, me = true }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const [userLogout] = userApi.endpoints.userLogout.useLazyQuery();

    const [userDeactivate] = userApi.endpoints.userDeactivate.useLazyQuery();

    const [userRequestNew2FASetup] = userApi.endpoints.userRequestNew2FASetup.useLazyQuery();

    const [userConfirmNew2FASetup] = userApi.endpoints.userConfirmNew2FASetup.useLazyQuery();

    const [twoFactorAuthSetup, setTwoFactorAuthSetup] = useState<TwoFactorAuthSetupProps>({
      otpAuthSecret: '',
      otpAuthUrlQRCode: '',
      onVerify: () => null,
    });

    const reportArgs = useMemo(() => {
      return {
        userID: userId,
        userName: screenName,
      };
    }, [screenName, userId]);

    const { abuseReportModal, abuseReportHandler } = useAbuseReportPopup({
      type: AbuseReportType.User,
      ...reportArgs,
    });

    const { DSAReportModal, DSAReportHandler } = useDSAReportPopup({
      type: DSAReportType.User,
      ...reportArgs,
    });

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const [twoFactorResetOpen, setTwoFactorResetOpen] = useState<boolean>(false);

    const [avatarPreviewOpen, setAvatarPreviewOpen] = useState<boolean>(false);

    const [isDeactivateOpen, setIsDeactivateOpen] = useState<boolean>(false);

    const {
      userInfo: {
        userData: { twoFactorEnabled },
      },
      userProfile: { isAnonymous },
    } = useContext(UserContext);

    const {
      profile: {
        avatar: { size: avatarSize },
        socialFields,
        fields,
        showDeepLink,
        showRole,
        showDeactiveAccount,
      },
      abuseReport: { enabled: abuseReportEnabled },
      dsaReport: { enabled: dsaReportEnabled },
    } = useContext(ConfigContext).config.elements;

    const cookieKey = useMemo(() => `${USER_DEVICE_COOKIE_KEY}_${getOrganisationDomain()}`, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, _, removeCookie] = useCookies([cookieKey]);

    const { fcmToken = '' } = { ...cookies[cookieKey] };

    const logoutCallback = useCallback(() => {
      setIsMenuOpen(false);

      dispatch(rootApi.util.resetApiState());

      removeCookie(cookieKey, cookieOptions({ action: CookieActionType.REMOVE }));

      navigate('/auth/login');
    }, [cookieKey, dispatch, navigate, removeCookie]);

    const logoutHandler = useCallback(() => {
      userLogout({ fcmToken });
      logoutCallback();
    }, [fcmToken, logoutCallback, userLogout]);

    const deactivateHandler = useCallback(() => {
      setIsMenuOpen(false);
      setIsDeactivateOpen(true);
    }, []);

    const deactivateConfirmationHandler = useCallback(() => {
      userDeactivate();
      logoutCallback();
    }, [logoutCallback, userDeactivate]);

    const editHandler = useCallback(() => {
      navigate(layoutPath('/profile/edit'));
    }, [navigate]);

    const renderRole = useMemo(() => Boolean(showRole && roleId), [roleId, showRole]);

    const renderButtons = useMemo(
      () => Boolean((me || renderRole) && !isAnonymous),
      [isAnonymous, me, renderRole]
    );

    const socialLinks = useMemo(() => {
      const sortedLinkTypes: (keyof UserSocialLinks)[] = [
        'facebook',
        'instagram',
        'twitter',
        'youtube',
        'snapchat',
        'tiktok',
        'linkedin',
      ];

      return sortedLinkTypes.map((linkType: keyof UserSocialLinks) => {
        if (!links[linkType]) {
          return null;
        }

        return (
          <Fragment key={linkType}>
            {socialFields.includes(linkType) && (
              <IconLabel
                iconId={linkType}
                className={classes['profile__social-link']}
                onClick={() => window.open(`${links[linkType]}`, '_blank', 'noreferrer')}
                color={getCssVar('--profile-social-color')}
                singleColor
              />
            )}
          </Fragment>
        );
      });
    }, [links, socialFields]);

    const renderBackLink = useMemo(() => !me && window.history.state, [me]);

    const renderReset2FALink = useMemo(() => me && twoFactorEnabled, [me, twoFactorEnabled]);

    const otpProcessing = useCallback(
      async ({ otp }: { otp: string }) => {
        const payload = await userConfirmNew2FASetup({ otp }).unwrap();

        if (payload) {
          setTwoFactorResetOpen(false);
          toast.success(t('auth.2fa-reset-success'));
          return;
        }

        toast.error(t('auth.2fa-otp-invalid'));
      },
      [t, userConfirmNew2FASetup]
    );

    const reset2FAClick = useCallback(async () => {
      setIsMenuOpen(false);

      const { otpAuthSecret, otpAuthUrlQRCode } = await userRequestNew2FASetup().unwrap();

      setTwoFactorAuthSetup({ otpAuthSecret, otpAuthUrlQRCode, onVerify: otpProcessing });

      setTwoFactorResetOpen(true);
    }, [otpProcessing, userRequestNew2FASetup]);

    const abuseReportClick = useCallback(() => {
      setIsMenuOpen(false);
      abuseReportHandler();
    }, [abuseReportHandler]);

    const DSAReportClick = useCallback(() => {
      setIsMenuOpen(false);
      DSAReportHandler();
    }, [DSAReportHandler]);

    const avatarUrl = useMemo(() => avatar?.url, [avatar?.url]);

    const dropdownMenuContent = useMemo(() => {
      return (
        <>
          {showDeepLink && (
            <Deeplink
              type={DeeplinkType.USER}
              userId={userId}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={() => setIsMenuOpen(false)}
            />
          )}
          {abuseReportEnabled && !me && (
            <IconLabel
              iconId={'report'}
              iconSize={18}
              label={t('abuseReport.label')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={abuseReportClick}
              singleColor
            />
          )}
          {dsaReportEnabled && !me && (
            <IconLabel
              iconId={'problem'}
              iconSize={18}
              label={t('DSAReport.label')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={DSAReportClick}
              singleColor
            />
          )}
          {renderReset2FALink && (
            <IconLabel
              iconId={'verified'}
              iconSize={18}
              label={t('auth.2fa-reset')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={reset2FAClick}
              singleColor
            />
          )}
          {me && (
            <>
              <IconLabel
                iconId={'exit'}
                iconSize={18}
                label={t('common.logout')}
                className={dropdownMenuClasses['dropdown-menu__item--small']}
                color={getCssVar('--color-danger')}
                onClick={logoutHandler}
                singleColor
              />

              {showDeactiveAccount && (
                <IconLabel
                  iconId={'person-cancel'}
                  iconSize={18}
                  label={t('profile.deactivate')}
                  className={dropdownMenuClasses['dropdown-menu__item--small']}
                  color={getCssVar('--color-danger')}
                  onClick={deactivateHandler}
                  singleColor
                />
              )}
            </>
          )}
        </>
      );
    }, [
      DSAReportClick,
      abuseReportClick,
      abuseReportEnabled,
      deactivateHandler,
      dsaReportEnabled,
      logoutHandler,
      me,
      renderReset2FALink,
      reset2FAClick,
      showDeactiveAccount,
      showDeepLink,
      t,
      userId,
    ]);

    return (
      <div className={classes['profile']}>
        <Avatar
          url={avatarUrl}
          size={avatarSize}
          className={classes['profile__avatar']}
          onClick={() => avatarUrl && setAvatarPreviewOpen(true)}
          nonClickable={!avatarUrl}
        />

        {renderBackLink && (
          <IconLabel
            className={classes['profile__back']}
            iconId={'arrow-rounded-left'}
            iconSize={18}
            label={t('common.back')}
            onClick={() => navigate(-1)}
            color={getCssVar('--profile-back-color')}
            hoverColor={getCssVar('--profile-back-hover-color')}
          />
        )}

        {!isAnonymous && (
          <Popup
            isOpen={isMenuOpen}
            setIsOpen={setIsMenuOpen}
            iconId={'dots-menu'}
            color={getCssVar('--profile-menu-icon-color')}
            hoverColor={getCssVar('--profile-menu-icon-hover-color')}
            parentClassName={classes['profile__menu']}
            bodyTop={'1.5rem'}
            bodyRight={'0'}
            body={<DropdownMenu width={'12rem'} content={dropdownMenuContent} />}
          />
        )}

        {fields['screenName'].visible && screenName && (
          <div className={classes['profile__name']}>{screenName}</div>
        )}

        {fields['bio'].visible && bio && <div className={classes['profile__bio']}>{bio}</div>}

        {renderButtons && (
          <div className={classes['profile__action-buttons']}>
            {renderRole && (
              <Button type={ButtonType.primary} label={t(`orgUserRoles.${roleId}`)} nonClickable />
            )}

            {me && (
              <Button
                type={ButtonType.secondary}
                label={t('profile.edit-profile')}
                onClick={editHandler}
              />
            )}
          </div>
        )}

        {Boolean(socialLinks.filter((link) => Boolean(link)).length) && (
          <div className={classes['profile__social']}>{socialLinks}</div>
        )}

        {fields['url'].visible && url && (
          <IconLabel
            label={url}
            className={classes['profile__url']}
            color={getCssVar('--base-link-text-color')}
            hoverColor={getCssVar('--base-link-text-hover-color')}
            onClick={() => window.open(url, '_blank', 'noreferrer')}
          />
        )}

        {isMobileOnly && <ProfileRightSide />}

        {abuseReportModal}

        {DSAReportModal}

        <Modal
          isOpen={twoFactorResetOpen}
          body={<TwoFactorAuthSetup {...twoFactorAuthSetup} />}
          onClose={() => setTwoFactorResetOpen(false)}
          contentStyle={{ width: '23rem' }}
          alignTop
        />

        <ConfirmationModal
          isOpen={isDeactivateOpen}
          onClose={() => setIsDeactivateOpen(false)}
          acceptLabel={t('profile.deactivate')}
          onAccept={deactivateConfirmationHandler}
          title={t('profile.deactivate-confirmation-title')}
          subTitle={t('profile.deactivate-confirmation-sub-title')}
          danger
        />

        <Modal
          isOpen={avatarPreviewOpen}
          body={<img src={avatarUrl} className={classes['profile__preview']} alt={'preview'} />}
          onClose={() => setAvatarPreviewOpen(false)}
          root={'modal-preview-root'}
          previewMode
        />
      </div>
    );
  }
);
