import {
  ChangeEvent,
  FunctionComponent,
  memo,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import Skeleton from 'react-loading-skeleton';
import { ImageUploadType, TransloaditAuthTemplate, UploadType, useUpload } from '../../hooks';
import { ImageFile } from '../../models';
import { getCssVar } from '../../utils';
import { Avatar } from '../Avatar';
import { Button, ButtonType } from '../Button';
import { IconLabel } from '../IconLabel';
import { Modal } from '../Modal';

import classNames from 'classnames';
import 'react-image-crop/src/ReactCrop.scss';
import classes from './AvatarUpload.module.scss';

interface AvatarUploadProps {
  avatar: ImageFile | null;
  setAvatar: (image: ImageFile | null) => void;
  avatarSize: number;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  title: string;
  className?: string;
  wrapperClassName?: string;
}

export const AvatarUpload: FunctionComponent<AvatarUploadProps> = memo(
  ({ avatar, setAvatar, avatarSize, loading, setLoading, title, className, wrapperClassName }) => {
    const { t } = useTranslation();

    const { uploadHandler, onUploadCompleted } = useUpload();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [imagePreview, setImagePreview] = useState<string | undefined>(avatar?.url);

    const [imageCrop, setImageCrop] = useState<string>('');

    const [crop, setCrop] = useState<Crop>();

    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    const imageRef = useRef<HTMLImageElement | null>(null);

    const inputFile = useRef<HTMLInputElement | null>(null);

    const renderOverlay = useMemo(() => avatarSize > 140, [avatarSize]);

    const avatarContent = useMemo(() => {
      if (loading) {
        return <Skeleton circle height={'100%'} />;
      }

      return (
        <>
          <Avatar url={imagePreview ?? avatar?.url} size={avatarSize} />

          {renderOverlay && (
            <div className={classes['avatar-upload__overlay']}>
              <IconLabel
                iconId={'photo-upload'}
                className={classes['avatar-upload__overlay-icon']}
                iconSize={20}
                color={getCssVar('--profile-upload-icon-color')}
                singleColor
              />
            </div>
          )}
        </>
      );
    }, [avatar?.url, avatarSize, imagePreview, loading, renderOverlay]);

    const deleteImageHandler = useCallback(() => {
      setImagePreview('');
      setAvatar(null);
    }, [setAvatar]);

    const addOrDelete = useMemo(() => {
      const hasAvatar = Boolean(avatar);

      return (
        <button
          disabled={loading}
          className={classes['avatar-upload__add-or-delete']}
          onClick={hasAvatar ? deleteImageHandler : () => inputFile.current?.click()}
        >
          {t(`avatarUpload.${hasAvatar ? 'delete' : 'add'}-picture`)}
        </button>
      );
    }, [avatar, deleteImageHandler, loading, t]);

    const onClose = () => {
      setIsModalOpen(false);
      setCrop(undefined);
      setCompletedCrop(undefined);
    };

    const getCroppedImage = useCallback(() => {
      const image = imageRef.current;

      if (!image || !completedCrop) {
        return;
      }

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      const pixelRatio = window.devicePixelRatio;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = Math.floor(completedCrop.width * pixelRatio * scaleX);
      canvas.height = Math.floor(completedCrop.height * pixelRatio * scaleY);

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY
      );

      return new Promise((resolve) => {
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              return;
            }
            resolve(blob);
          },
          'image/png',
          1
        );
      });
    }, [completedCrop]);

    const upload = useCallback(
      async (file: File) => {
        try {
          const { id, assembly_id } = await uploadHandler({
            file,
            template: TransloaditAuthTemplate.PROFILE_FILE,
            type: UploadType.IMAGE,
            imageUploadType: ImageUploadType.PROFILE,
          });

          onUploadCompleted({
            assembly_id,
            callback: () => {
              const url = URL.createObjectURL(file);
              setImagePreview(url);
              setAvatar({ id, url } as ImageFile);
              setLoading(false);
            },
          });
        } catch (e) {}
      },
      [onUploadCompleted, setAvatar, setLoading, uploadHandler]
    );

    const onSave = useCallback(async () => {
      setIsModalOpen(false);
      setLoading(true);
      upload((await getCroppedImage()) as File);
    }, [getCroppedImage, setLoading, upload]);

    const onFileChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      const file = target.files?.[0];

      if (file) {
        setIsModalOpen(true);
        setImageCrop(URL.createObjectURL(file));
      }
    };

    const onLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
      const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

      const crop = centerCrop(
        makeAspectCrop({ unit: '%', width: 50 }, 1, width, height),
        width,
        height
      );

      setCrop(crop);
    };

    return (
      <div className={classNames(classes['avatar-upload'], className)}>
        <label
          className={classNames(classes['avatar-upload__wrapper'], wrapperClassName)}
          style={{ width: `${avatarSize}px`, height: `${avatarSize}px` }}
          htmlFor={'avatar'}
        >
          {avatarContent}
        </label>

        <input
          ref={inputFile}
          id={'avatar'}
          type={'file'}
          accept={'image/*'}
          className={classes['avatar-upload__file']}
          onChange={onFileChange}
          onClick={({ target }) => ((target as HTMLInputElement).value = '')}
          disabled={loading}
        />

        {addOrDelete}

        <Modal
          isOpen={isModalOpen}
          title={title}
          contentStyle={{ textAlign: 'center' }}
          onClose={onClose}
          body={
            <>
              {imageCrop && (
                <ReactCrop
                  className={classes['avatar-upload__crop']}
                  crop={crop}
                  onChange={setCrop}
                  onComplete={setCompletedCrop}
                  minWidth={100}
                  minHeight={100}
                  maxWidth={500}
                  maxHeight={500}
                  keepSelection
                  ruleOfThirds
                  circularCrop
                >
                  <img
                    ref={imageRef}
                    src={imageCrop}
                    onLoad={onLoad}
                    style={{ maxHeight: `calc(${window.innerHeight}px - 15rem)` }}
                    alt={'crop'}
                  />
                </ReactCrop>
              )}

              <div className={classes['avatar-upload__crop-buttons']}>
                <Button label={t('common.cancel')} type={ButtonType.secondary} onClick={onClose} />
                <Button
                  label={t('avatarUpload.crop-and-save')}
                  type={ButtonType.primary}
                  onClick={onSave}
                  disabled={!completedCrop}
                />
              </div>
            </>
          }
          root={'modal-preview-root'}
        />
      </div>
    );
  }
);
