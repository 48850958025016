import { FilterValue } from '../../components';
import { useLastDate } from '../useLastDate';

interface GetGroupByIdProps {
  filter: FilterValue[];
  groupId: string;
}

interface GetGroupItemValueProps {
  filter: FilterValue[];
  groupId: string;
  itemId: string;
  propToReturn?: string;
}

interface GetGroupValueProps {
  filter: FilterValue[];
  groupId: string;
  multiCheck?: boolean;
  propToMap?: string;
}

interface GetDateRangeProps {
  filter: FilterValue[];
  groupId: string;
}

export const useFilter = () => {
  const { isLastDate, getLastDate } = useLastDate();

  const getGroupById = ({ filter, groupId }: GetGroupByIdProps) => {
    return filter.find((group) => group.groupId === groupId);
  };

  const getGroupValue = ({
    filter,
    groupId,
    multiCheck = false,
    propToMap = 'value',
  }: GetGroupValueProps) => {
    const groupValue =
      getGroupById({ filter, groupId })?.groupItems?.map((value) => value[propToMap]) ?? [];

    return !multiCheck ? groupValue[0] : groupValue;
  };

  const getGroupItemValue = ({
    filter,
    groupId,
    itemId,
    propToReturn = 'value',
  }: GetGroupItemValueProps) => {
    return getGroupById({ filter, groupId })?.groupItems.find(({ id }) => id === itemId)?.[
      propToReturn
    ];
  };

  const getDateRangeValue = ({ filter, groupId }: GetDateRangeProps) => {
    const dateRange = getGroupValue({ filter, groupId });

    const date = isLastDate(dateRange) ? getLastDate(dateRange) : dateRange;

    return { dateRange, from: date?.from ?? null, to: date?.to ?? null };
  };

  return { getGroupById, getGroupItemValue, getGroupValue, getDateRangeValue };
};
