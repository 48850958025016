/* eslint-disable @typescript-eslint/no-explicit-any */
import { endOfDay, isAfter, startOfDay } from 'date-fns';
import { FunctionComponent, memo, useCallback, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import sprite from '../../../assets/icons/sprite.svg';
import { LastDateValue, useLastDate, useOnClickOutside } from '../../hooks';
import { getCssVar } from '../../utils';
import { AutoComplete } from '../AutoComplete';
import { IconLabel, IconLabelSizes } from '../IconLabel';
import { InputField } from '../InputField';
import { Select, SelectItem } from '../Select';

import 'react-datepicker/dist/react-datepicker.css';

import classNames from 'classnames';
import classes from './Filter.module.scss';

export enum FilterGroupItemsAlignment {
  ROW = 'ROW',
  COLUMN = 'COLUMN',
}

export enum FilterListAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface GroupItem {
  id: string;
  [key: string]: any;
}

export interface FilterValue {
  groupId: string;
  groupItems: GroupItem[];
}

interface FilterGroupSearch {
  placeholder: string;
}

interface FilterGroupDropdown {
  options: SelectItem[];
  disabled?: boolean;
  search?: boolean;
  staticListPosition?: boolean;
  multiCheck?: boolean;
}

interface FilterGroupItemDropdown {
  options: SelectItem[];
}

interface FilterGroupItemInputNumber {
  min: number;
  max: number;
  label?: string;
}

export interface FilterGroupAutoComplete {
  propToIdentify: string;
  propToDisplay: string;
  placeholder: string;
  fetchItemsQuery: any;
  fetchItemsQueryArgs: any;
  itemTemplate: (item: any) => JSX.Element;
  excludeLabel?: string;
}

interface FilterGroupItem {
  id: string;
  label: string;
  hint?: string;
  value: any;
  dateRange?: boolean;
  dropdown?: FilterGroupItemDropdown;
  inputNumber?: FilterGroupItemInputNumber;
}

export interface FilterGroup {
  id: string;
  title?: string;
  value?: any;
  items?: FilterGroupItem[];
  itemsAlignment?: FilterGroupItemsAlignment;
  multiCheck?: boolean;
  allowReset?: boolean;
  search?: FilterGroupSearch;
  dropdown?: FilterGroupDropdown;
  autoComplete?: FilterGroupAutoComplete;
}

interface FilterProps {
  filter: FilterValue[];
  groups: FilterGroup[];
  onChange: (value: FilterValue[]) => void;
  iconId?: string;
  label?: string;
  labelSize?: IconLabelSizes;
  groupItemsLimit?: number;
  className?: string;
  resetPageScrollOnChange?: boolean;
  listAlignment?: FilterListAlignment;
}

interface GroupCollapsion {
  groupId: string;
  collapsed: boolean;
}

export const Filter: FunctionComponent<FilterProps> = memo(
  ({
    filter,
    groups,
    onChange,
    label,
    labelSize,
    iconId,
    className,
    groupItemsLimit,
    resetPageScrollOnChange,
    listAlignment,
  }) => {
    const { t } = useTranslation();

    const { isLastDate, getLastDate } = useLastDate();

    const containerRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);

    const [groupCollapsion, setGroupCollapsion] = useState<GroupCollapsion[]>(
      groups.map(({ id, multiCheck, items }) => {
        return {
          groupId: id,
          collapsed: Boolean(
            groupItemsLimit && multiCheck && items && items.length > groupItemsLimit
          ),
        };
      })
    );

    useOnClickOutside(containerRef, () => {
      setIsOpen(false);
    });

    const onChangeHandler = useCallback(
      (value: FilterValue[]) => {
        if (resetPageScrollOnChange) {
          window.scrollTo(0, 0);
        }
        onChange(value);
      },
      [onChange, resetPageScrollOnChange]
    );

    const groupReset = useCallback(
      (groupId: string) => {
        const filterCopy = [...filter];

        filterCopy.splice(
          filterCopy.findIndex((item) => item.groupId === groupId),
          1
        );

        onChangeHandler(filterCopy);
      },
      [filter, onChangeHandler]
    );

    const renderGroupReset = useCallback(
      (groupId: string) => {
        return (
          <IconLabel
            label={t('common.clear')}
            labelSize={IconLabelSizes.small}
            color={getCssVar('--base-link-text-color')}
            hoverColor={getCssVar('--base-link-text-hover-color')}
            onClick={() => groupReset(groupId)}
          />
        );
      },
      [groupReset, t]
    );

    const groupItemChange = useCallback(
      (groupId: string, groupItem: FilterGroupItem, multiCheck?: boolean) => {
        const filterCopy = [...filter];

        const group = filterCopy.find((item) => item.groupId === groupId);

        if (!group) {
          onChangeHandler([...filterCopy, { groupId, groupItems: [groupItem] }]);
          return;
        }

        if (!multiCheck) {
          group.groupItems = [groupItem];
        } else {
          const groupItems = group.groupItems;

          const index = groupItems.findIndex(({ id }) => id === groupItem.id) ?? -1;

          index !== -1 ? groupItems.splice(index, 1) : groupItems.push(groupItem);

          if (!groupItems.length) {
            filterCopy.splice(
              filterCopy.findIndex((item) => item.groupId === groupId),
              1
            );
          }
        }

        onChangeHandler(filterCopy);
      },
      [filter, onChangeHandler]
    );

    const onGroupComponentChange = useCallback(
      (groupId: string, items: any) => {
        const filterCopy = [...filter];

        const group = filterCopy.find((item) => item.groupId === groupId);

        if (!group) {
          onChangeHandler([...filterCopy, { groupId, groupItems: items }]);
          return;
        }

        group.groupItems = items;

        if (!items.length) {
          filterCopy.splice(
            filterCopy.findIndex((item) => item.groupId === groupId),
            1
          );
        }

        onChangeHandler(filterCopy);
      },
      [filter, onChangeHandler]
    );

    const renderGroupSearch = useCallback(
      (groupId: string, value: any, search: FilterGroupSearch) => {
        const { placeholder } = search;

        return (
          <InputField
            key={groupId}
            placeholder={placeholder}
            value={value}
            onChange={({ target }) => onGroupComponentChange(groupId, [{ value: target.value }])}
            icon={
              <IconLabel
                iconId={'search'}
                iconSize={18}
                color={getCssVar('--filter-search-icon-color')}
                singleColor
                nonClickable
              />
            }
          />
        );
      },
      [onGroupComponentChange]
    );

    const renderGroupDropdown = useCallback(
      (groupId: string, value: any, dropdown: FilterGroupDropdown) => {
        const { options, disabled, search, multiCheck, staticListPosition = true } = dropdown;

        const selectProps = {
          key: groupId,
          items: options,
          staticListPosition,
          search,
          disabled,
        };

        if (multiCheck) {
          return (
            <Select
              {...selectProps}
              selectedItemIds={value}
              onMultiCheckChange={(ids: (string | number)[]) =>
                onGroupComponentChange(
                  groupId,
                  ids.map((id) => {
                    return { id };
                  })
                )
              }
            />
          );
        }

        return (
          <Select
            {...selectProps}
            selectedItemId={value}
            onChange={(value) =>
              onGroupComponentChange(groupId, [{ value: value as string | number }])
            }
          />
        );
      },
      [onGroupComponentChange]
    );

    const renderGroupAutoComplete = useCallback(
      (groupId: string, value: any, autoComplete: FilterGroupAutoComplete) => {
        const {
          propToDisplay,
          propToIdentify,
          itemTemplate,
          fetchItemsQuery,
          fetchItemsQueryArgs,
          placeholder,
          excludeLabel,
        } = autoComplete;

        return (
          <AutoComplete
            value={value}
            propToDisplay={propToDisplay}
            propToIdentify={propToIdentify}
            fetchQuery={(query: string, page?: number) =>
              fetchItemsQuery({ query, page, ...fetchItemsQueryArgs })
            }
            itemTemplate={itemTemplate}
            onChange={(value) => onGroupComponentChange(groupId, value)}
            excludeLabel={excludeLabel}
            placeholder={placeholder}
          />
        );
      },
      [onGroupComponentChange]
    );

    const renderGroupComponent = useCallback(
      (
        groupId: string,
        value: any,
        allowReset?: boolean,
        search?: FilterGroupSearch,
        dropdown?: FilterGroupDropdown,
        autoComplete?: FilterGroupAutoComplete
      ) => {
        const groupComponent = (() => {
          switch (true) {
            case Boolean(search):
              return search && renderGroupSearch(groupId, value, search);
            case Boolean(dropdown):
              return dropdown && renderGroupDropdown(groupId, value, dropdown);
            case Boolean(autoComplete):
              return autoComplete && renderGroupAutoComplete(groupId, value, autoComplete);
            default:
              return null;
          }
        })();

        return (
          <>
            {groupComponent}
            {allowReset && groupComponent && Boolean(value.length) && renderGroupReset(groupId)}
          </>
        );
      },
      [renderGroupAutoComplete, renderGroupDropdown, renderGroupReset, renderGroupSearch]
    );

    const onItemComponentChange = useCallback(
      (groupId: string, itemId: string, value: any, propName?: string) => {
        const filterCopy = [...filter];

        const group = filterCopy.find((item) => item.groupId === groupId);

        if (!group) {
          return;
        }

        const item = group.groupItems.find(({ id }) => id === itemId);

        if (!item) {
          return;
        }

        propName ? (item.value = { ...item.value, [propName]: value }) : (item.value = value);

        onChangeHandler(filterCopy);
      },
      [filter, onChangeHandler]
    );

    const renderItemDateRange = useCallback(
      (groupId: string, itemId: string, value: any) => {
        const { from, to } = isLastDate(value) ? getLastDate(LastDateValue.LAST_7_DAYS) : value;

        const fromDate = new Date(from);

        const toDate = new Date(to);

        const fromDateChange = (date: Date | null) => {
          if (!date) {
            return;
          }

          if (isAfter(date, toDate)) {
            onItemComponentChange(groupId, itemId, endOfDay(date).toISOString(), 'to');
          }
          onItemComponentChange(groupId, itemId, startOfDay(date).toISOString(), 'from');
        };

        const toDateChange = (date: Date | null) => {
          if (!date) {
            return;
          }
          onItemComponentChange(groupId, itemId, endOfDay(date).toISOString(), 'to');
        };

        return (
          <>
            <DatePicker
              className={classes['filter__list-group-item-date']}
              selected={fromDate}
              dateFormat={'dd.MM.yyyy'}
              onChange={fromDateChange}
              selectsStart
              startDate={fromDate}
              endDate={toDate}
            />
            <DatePicker
              className={classes['filter__list-group-item-date']}
              selected={toDate}
              dateFormat={'dd.MM.yyyy'}
              onChange={toDateChange}
              selectsEnd
              startDate={fromDate}
              endDate={toDate}
              minDate={fromDate}
            />
          </>
        );
      },
      [getLastDate, isLastDate, onItemComponentChange]
    );

    const renderItemDropdown = useCallback(
      (
        groupId: string,
        itemId: string,
        value: string | number,
        dropdown: FilterGroupItemDropdown
      ) => {
        const { options } = dropdown;

        return (
          <Select
            className={classes['filter__list-group-item-dropdown']}
            items={options}
            onChange={(value) => onItemComponentChange(groupId, itemId, value)}
            selectedItemId={value}
            staticListPosition
          />
        );
      },
      [onItemComponentChange]
    );

    const renderItemInputNumber = useCallback(
      (groupId: string, itemId: string, value: string, inputNumber: FilterGroupItemInputNumber) => {
        const { label, min, max } = inputNumber;

        return (
          <InputField
            label={label}
            className={classes['filter__list-group-item-input-number']}
            type={'number'}
            value={value}
            min={min}
            max={max}
            onChange={({ target }) =>
              onItemComponentChange(groupId, itemId, parseInt(target.value, 10))
            }
          />
        );
      },
      [onItemComponentChange]
    );

    const renderItemComponent = useCallback(
      (
        groupId: string,
        itemId: string,
        value: any,
        dateRange?: boolean,
        dropdown?: FilterGroupItemDropdown,
        inputNumber?: FilterGroupItemInputNumber
      ) => {
        switch (true) {
          case Boolean(dateRange):
            return dateRange && renderItemDateRange(groupId, itemId, value);
          case Boolean(dropdown):
            return dropdown && renderItemDropdown(groupId, itemId, value, dropdown);
          case Boolean(inputNumber):
            return inputNumber && renderItemInputNumber(groupId, itemId, value, inputNumber);
          default:
            return null;
        }
      },
      [renderItemDateRange, renderItemDropdown, renderItemInputNumber]
    );

    const changeGroupCollapsion = useCallback(
      (groupId: string) => {
        const copyGroupCollapsion = [...groupCollapsion];

        const group = copyGroupCollapsion.find((groupItem) => groupItem.groupId === groupId);

        if (!group) {
          return;
        }

        group.collapsed = false;

        setGroupCollapsion(copyGroupCollapsion);
      },
      [groupCollapsion]
    );

    return (
      <div ref={containerRef} className={classNames(classes['filter'], className)}>
        {label && (
          <IconLabel
            iconId={iconId}
            labelSize={labelSize}
            iconSize={20}
            label={label}
            labelFirst
            color={getCssVar('--base-link-text-color')}
            hoverColor={getCssVar('--base-link-text-hover-color')}
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
        {(!label || isOpen) && (
          <div
            className={classNames(classes['filter__list'], {
              [classes['filter__list--static']]: !label,
              [classes['filter__list--right-aligned']]: listAlignment === FilterListAlignment.RIGHT,
            })}
          >
            {groups.map(
              ({
                id: groupId,
                value: groupValue,
                title: groupTitle,
                items: groupItems,
                itemsAlignment: groupItemsAlignment,
                allowReset: groupAllowReset,
                multiCheck: groupMultiCheck,
                search: groupSearch,
                dropdown: groupDropdown,
                autoComplete: groupAutoComplete,
              }) => {
                const { groupItems: filterGroupItems } = {
                  ...filter.find((value) => value.groupId === groupId),
                };

                const groupReset = groupAllowReset && filterGroupItems;

                return (
                  <div key={groupId} className={classes['filter__list-group']}>
                    {groupTitle && (
                      <div className={classes['filter__list-group-title']}>{groupTitle}</div>
                    )}

                    {renderGroupComponent(
                      groupId,
                      groupValue,
                      groupAllowReset,
                      groupSearch,
                      groupDropdown,
                      groupAutoComplete
                    )}

                    {Boolean(groupItems?.length) && (
                      <div
                        className={classNames(classes['filter__list-group-items'], {
                          [classes['filter__list-group-items--no-group-title']]: !groupTitle,
                          [classes['filter__list-group-items--row-alignment']]:
                            groupItemsAlignment === FilterGroupItemsAlignment.ROW,
                        })}
                      >
                        {groupItems?.map((groupItem, groupItemIndex) => {
                          const {
                            id: itemId,
                            label: itemLabel,
                            hint: itemHint,
                            dateRange: itemDateRange,
                            dropdown: itemDropdown,
                            inputNumber: itemInputNumber,
                            value: itemValue,
                          } = groupItem;

                          const groupCollapsed = groupCollapsion.find(
                            (group) => group.groupId === groupId
                          )?.collapsed;

                          if (
                            groupCollapsed &&
                            groupItemsLimit &&
                            groupItemsLimit < groupItemIndex
                          ) {
                            return null;
                          }

                          if (
                            groupCollapsed &&
                            groupItemsLimit &&
                            groupItemsLimit < groupItemIndex + 1
                          ) {
                            return (
                              <IconLabel
                                key={`${groupId}${itemId}`}
                                iconId={'down-arrow'}
                                iconSize={16}
                                label={t('common.show-all')}
                                color={getCssVar('--base-link-text-color')}
                                hoverColor={getCssVar('--base-link-text-hover-color')}
                                onClick={() => changeGroupCollapsion(groupId)}
                                labelFirst
                              />
                            );
                          }

                          const isChecked = Boolean(
                            filterGroupItems?.find(({ id }) => id === itemId)
                          );

                          return (
                            <div
                              key={`${groupId}${itemId}`}
                              className={classNames(
                                classes['filter__list-group-item'],
                                {
                                  [classes['filter__list-group-item--checked']]: isChecked,
                                  [classes['filter__list-group-item--multi']]: groupMultiCheck,
                                  [classes['filter__list-group-item--range']]: itemDateRange,
                                },
                                'group-item-change-trigger'
                              )}
                              onClick={({ target }) =>
                                (target as Element).classList.contains(
                                  'group-item-change-trigger'
                                ) && groupItemChange(groupId, groupItem, groupMultiCheck)
                              }
                            >
                              {isChecked && groupMultiCheck && (
                                <svg
                                  className={classNames(
                                    classes['filter__list-group-item-icon'],
                                    'group-item-change-trigger'
                                  )}
                                >
                                  <use href={`${sprite}#checkmark`} />
                                </svg>
                              )}

                              {itemLabel}

                              {itemHint && (
                                <div
                                  className={classNames(
                                    classes['filter__list-group-item-hint'],
                                    'group-item-change-trigger'
                                  )}
                                >
                                  {itemHint}
                                </div>
                              )}

                              {isChecked &&
                                renderItemComponent(
                                  groupId,
                                  itemId,
                                  itemValue,
                                  itemDateRange,
                                  itemDropdown,
                                  itemInputNumber
                                )}
                            </div>
                          );
                        })}

                        {groupReset && renderGroupReset(groupId)}
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        )}
      </div>
    );
  }
);
