import {
  ImageFile,
  isAdminLayout,
  optArrItem,
  ORG_TAGS_SIZE_LIMIT,
  PaginationInfo,
} from '../shared';
import { graphqlApi } from './graphqlApi';
import { StoryCardCategory } from './storyCard';
import { User, UserGender } from './user';

export enum OrganisationTagType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  USER = 'USER',
}

export enum OrganisationUsersFiltersSection {
  DEPARTMENT = 'DEPARTMENT',
  POSITION = 'POSITION',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum OrganisationRole {
  OWNER = 6,
  ADMIN = 7,
  TEAM_MEMBER = 8,
}

export interface OrganisationUsersFilter {
  query?: string;
  genders?: UserGender[];
  filterDepartments?: string[];
  filterPositions?: string[];
  filterSubscriptions?: string[];
  organisationTagsId?: number[];
  usersId?: number[];
  rolesId?: number[];
  queryLocation?: string;
}

export interface OrganisationUsersArgs {
  page?: number;
  size?: number;
  filter?: OrganisationUsersFilter;
}

interface OrganisationUsersPayload {
  items: User[];
  pageInfo: PaginationInfo;
}

interface OrganisationUsersFiltersArgs {
  filters?: {
    sections?: OrganisationUsersFiltersSection[];
  };
}

interface OrganisationUsersResponse {
  data: {
    organisationUsers: OrganisationUsersPayload;
  };
}

interface OrganisationUserFilter {
  name: string;
  values: [{ count: number; value: string }];
}

interface OrganisationUsersFiltersResponse {
  data: {
    organisationUsersFilters: OrganisationUserFilter[];
  };
}

export interface OrganisationTag {
  id: number;
  name: string;
  type: OrganisationTagType;
}

export interface Organisation {
  id: number;
  name: string;
  subdomain: string;
  pinnedStoryId?: number;
  permissions: {
    chatGroupAllowToCreate: boolean;
    chatP2PAllowToCreate: boolean;
    organisationInAppMessageAllowToCreate: boolean;
    organisationPushNotificationAllowToCreate: boolean;
    organisationTagAllowToManage: boolean;
  };
  role?: { id: number };
  tags: OrganisationTag[];
  categories: StoryCardCategory[];
}

interface OrganisationResponse {
  data: {
    organisation: Organisation;
    organisationTags: { items: OrganisationTag[] };
    storyCardCategories: { items: StoryCardCategory[] };
  };
}

interface OrganisationRelatedStoriesArgs {
  channelId: number;
}

export interface OrganisationRelatedStory {
  id: number;
  image: ImageFile;
  subtitle: string;
  title: string;
}

interface OrganisationRelatedChannel {
  id: number;
  name: string;
  stories: OrganisationRelatedStory[];
  subdomain: string;
}

interface OrganisationRelatedStoriesResponse {
  data: {
    organisationRelatedStories: OrganisationRelatedChannel[];
  };
}

interface OrganisationDownloadAppLinkRequestForUserArgs {
  channelId: number;
}

interface OrganisationDownloadAppLinkRequestForUserPayload {
  link: string;
}

interface OrganisationDownloadAppLinkRequestForUserResponse {
  data: {
    organisationDownloadAppLinkRequestForUser: {
      payload: OrganisationDownloadAppLinkRequestForUserPayload;
    };
  };
}

export interface OrganisationTagsFiltersArgs {
  query?: string;
  types?: OrganisationTagType[];
}

export interface OrganisationTagsArgs {
  page?: number;
  size?: number;
  filters?: OrganisationTagsFiltersArgs;
}

interface OrganisationTagsData {
  items: OrganisationTag[];
  pageInfo: PaginationInfo;
}

interface OrganisationTagsResponse {
  data: {
    organisationTags: OrganisationTagsData;
  };
}

interface OrganisationTagCreateArgs {
  name: string;
  type: OrganisationTagType;
}

interface OrganisationTagCreateData {
  payload: OrganisationTag;
  error: {
    __typename: string;
    message: string;
  };
}

interface OrganisationTagCreateResponse {
  data: {
    organisationTagCreate: OrganisationTagCreateData;
  };
}

interface OrganisationTagUpdateArgs {
  tagId: number;
  name?: string;
  type?: OrganisationTagType;
}

interface OrganisationTagUpdateData {
  payload: OrganisationTag;
  error: { message: string };
}

interface OrganisationTagUpdateResponse {
  data: {
    organisationTagUpdate: OrganisationTagUpdateData;
  };
}

interface OrganisationTagDeleteArgs {
  tagId: number;
}

interface OrganisationTagDeleteData {
  payload: boolean;
  error: { message: string };
}

interface OrganisationTagDeleteResponse {
  data: {
    organisationTagDelete: OrganisationTagDeleteData;
  };
}

export const organisationApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    organisation: builder.query<Organisation, void>({
      query: () => ({
        url: '/graphql/webapp?organisation',
        method: 'POST',
        body: {
          query: `query Organisation($size: Int, $filters: GetOrganisationTagsFiltersArgs) {
            organisation {
              id
              subdomain
              pinnedStoryId
              permissions {
                chatGroupAllowToCreate
                chatP2PAllowToCreate
                organisationInAppMessageAllowToCreate
                organisationPushNotificationAllowToCreate
                organisationTagAllowToManage
              }
              role {
                id
              }
            }
            organisationTags(size: $size, filters: $filters) {
              items {
                id
                name
                type
              }
            }
            storyCardCategories {
              items {
                id
                name
              }
            }
          }`,
          variables: {
            size: ORG_TAGS_SIZE_LIMIT,
            filters: {
              types: [
                ...optArrItem(isAdminLayout(), OrganisationTagType.INTERNAL),
                OrganisationTagType.EXTERNAL,
                OrganisationTagType.USER,
              ],
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: OrganisationResponse) => {
        return {
          ...response.data.organisation,
          tags: response.data.organisationTags.items,
          categories: response.data.storyCardCategories.items,
        };
      },
    }),
    organisationUsers: builder.query<OrganisationUsersPayload, OrganisationUsersArgs>({
      query: ({ page = 1, size = 15, filter }) => ({
        url: '/graphql/webapp?organisationUsers',
        method: 'POST',
        body: {
          query: `query OrganisationUsers($page: Int, $size: Int, $filter: GetOrganisationUsersFilterArgsType) {
            organisationUsers(page: $page, size: $size, filter: $filter) {
              items {
                id
                avatar {
                  averageColor
                  externalId
                  externalType
                  id
                  originalFilename
                  rightholder
                  status
                  statusCopyrightId
                  thumb
                  url
                }
                department
                email
                phone
                position
                screenName
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: { page, size, filter },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: OrganisationUsersResponse) => response.data.organisationUsers,
    }),
    organisationUsersFilters: builder.query<OrganisationUserFilter[], OrganisationUsersFiltersArgs>(
      {
        query: ({ filters }) => ({
          url: '/graphql/webapp?organisationUsersFilters',
          method: 'POST',
          body: {
            query: `query OrganisationUsersFilters($filters: GetOrganisationUsersFiltersArgs) {
              organisationUsersFilters(filters: $filters) {
                name
                values {
                  count
                  value
                }
              }
            }`,
            variables: { filters },
          },
        }),
        forceRefetch: () => true,
        transformResponse: (response: OrganisationUsersFiltersResponse) =>
          response.data.organisationUsersFilters,
      }
    ),
    organisationRelatedStories: builder.query<
      OrganisationRelatedChannel[],
      OrganisationRelatedStoriesArgs
    >({
      query: ({ channelId }) => ({
        url: '/graphql/webapp?organisationRelatedStories',
        method: 'POST',
        body: {
          query: `query OrganisationRelatedStories($channelId: Int!) {
            organisationRelatedStories(channelId: $channelId) {
              id
              name
              stories {
                id
                title
                subtitle
                image {
                  id
                  url
                  rightholder
                }
              }
            }
          }`,
          variables: { channelId },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: OrganisationRelatedStoriesResponse) =>
        response.data.organisationRelatedStories,
    }),
    organisationDownloadAppLinkRequestForUser: builder.query<
      OrganisationDownloadAppLinkRequestForUserPayload,
      OrganisationDownloadAppLinkRequestForUserArgs
    >({
      query: ({ channelId }) => ({
        url: '/graphql/webapp?organisationDownloadAppLinkRequestForUser',
        method: 'POST',
        body: {
          query: `mutation OrganisationDownloadAppLinkRequestForUser($input: GetOrganisationDownloadAppLinkArgsType!) {
            organisationDownloadAppLinkRequestForUser(input: $input) {
              payload {
                link
              }
              error {
                ... on OrganisationDoesNotHaveLinkToDownloadAppError {
                  message
                }
                ... on UnsupportedDevicePlatformError {
                  message
                }
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: { input: { channelId } },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: OrganisationDownloadAppLinkRequestForUserResponse) =>
        response.data.organisationDownloadAppLinkRequestForUser.payload,
    }),
    organisationTags: builder.query<OrganisationTagsData, OrganisationTagsArgs>({
      query: ({ page = 1, size = 10, filters }) => ({
        url: '/graphql/webapp?organisationTags',
        method: 'POST',
        body: {
          query: `query OrganisationTags($page: Int, $size: Int, $filters: GetOrganisationTagsFiltersArgs) {
            organisationTags(page: $page, size: $size, filters: $filters) {
              items {
                id
                name
                type
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            page,
            size,
            filters,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: OrganisationTagsResponse) => response.data.organisationTags,
    }),
    organisationTagCreate: builder.query<OrganisationTagCreateData, OrganisationTagCreateArgs>({
      query: ({ name, type }) => ({
        url: '/graphql/webapp?organisationTagCreate',
        method: 'POST',
        body: {
          query: `mutation OrganisationTagCreate($input: OrganisationTagCreateInput!) {
            organisationTagCreate(input: $input) {
              payload {
                id
                name
                type
              }
              error {
                __typename
                ... on UnknownError {
                  message
                  kind
                }
                ... on OrganisationTagConflictNameError {
                  message
                }
                ... on OrganisationTagsReachedLimitError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { fields: { name, type } },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: OrganisationTagCreateResponse) =>
        response.data.organisationTagCreate,
    }),
    organisationTagUpdate: builder.query<OrganisationTagUpdateData, OrganisationTagUpdateArgs>({
      query: ({ tagId, name, type }) => ({
        url: '/graphql/webapp?organisationTagUpdate',
        method: 'POST',
        body: {
          query: `mutation OrganisationTagUpdate($input: OrganisationTagUpdateInput!) {
            organisationTagUpdate(input: $input) {
                payload {
                  id
                  name
                  type
                }
                error {
                  ... on UnknownError {
                    message
                    kind
                  }
                  ... on OrganisationTagNotFoundError {
                    message
                  }
                }
              }
          }`,
          variables: {
            input: {
              tagId,
              fields: { name, type },
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: OrganisationTagUpdateResponse) =>
        response.data?.organisationTagUpdate,
    }),
    organisationTagDelete: builder.query<boolean, OrganisationTagDeleteArgs>({
      query: ({ tagId }) => ({
        url: '/graphql/webapp?organisationTagDelete',
        method: 'POST',
        body: {
          query: `mutation OrganisationTagDelete($input: OrganisationTagDeleteInput!) {
            organisationTagDelete(input: $input) {
                payload
                error {
                  ... on UnknownError {
                    message
                    kind
                  }
                  ... on OrganisationTagNotFoundError {
                    message
                  }
                }
              }
          }`,
          variables: {
            input: { tagId },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: OrganisationTagDeleteResponse) =>
        response.data.organisationTagDelete.payload,
    }),
  }),
});

export const {
  useOrganisationQuery,
  useOrganisationUsersQuery,
  useOrganisationUsersFiltersQuery,
  useOrganisationRelatedStoriesQuery,
  useOrganisationDownloadAppLinkRequestForUserQuery,
  useOrganisationTagsQuery,
} = organisationApi;
