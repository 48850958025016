import PubNub from 'pubnub';
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ChannelContext,
  Channels,
  ChannelsUnreadCount,
  ChannelTypingUsers,
  ChatContext,
  ConfigContext,
  OrganisationContext,
  UserContext,
} from '../../context';
import {
  ChannelAppTabAction,
  Chat,
  ChatAccessTypes,
  chatsApi,
  ChatTypes,
  User,
} from '../../services';
import {
  DropdownMenu,
  IconLabel,
  InputField,
  isAdminLayout,
  layoutPath,
  Modal,
  Popup,
  useDebounce,
  useDeepLink,
} from '../../shared';
import { ChatHistory } from './ChatHistory';
import { ChatsList } from './ChatsList';
import { CreateChatModal } from './CreateChatModal';

import sprite from '../../assets/icons/sprite.svg';
import chatPlaceholder from '../../assets/images/chat-placeholder.png';

import dropdownMenuClasses from '../../shared/components/DropdownMenu/DropdownMenu.module.scss';
import classes from './Chats.module.scss';
import { JoinChat } from './JoinChat';

export enum ChatTabsTypes {
  all,
  direct,
  public,
}

export const Chats: FunctionComponent = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const navigate = useNavigate();

  const { isDeepLink } = useDeepLink();

  const [getChannelChats] = chatsApi.endpoints.getChannelChats.useLazyQuery();

  const [getChannelChatById] = chatsApi.endpoints.getChannelChatById.useLazyQuery();

  const { id: userId, isAnonymous } = useContext(UserContext).userProfile;

  if (isAnonymous) {
    navigate('/');
  }

  const {
    chats: { showChatCreate },
    mobileLayout: { iosLink, androidLink },
  } = useContext(ConfigContext).config.elements;

  const chatId = Number(new URLSearchParams(location.search).get('chatId'));

  const { appTabs, permissions: channelPermissions } = useContext(ChannelContext);

  const { permissions: orgPermissions } = useContext(OrganisationContext).organisation;

  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [searchActive, setSearchActive] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');

  const debouncedSearch = useDebounce<string>(search);

  const [activeChat, setActiveChat] = useState<Chat | null>(null);

  const [chats, setChats] = useState<Chat[]>([]);

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(ChatTabsTypes.all);

  const [pubnub, setPubnub] = useState<PubNub | null>(null);

  const [channels, setChannels] = useState<Channels | null>(null);

  const [channelsChanges, setChannelsChanges] = useState<Channels | null>(null);

  const [channelsUnreadCount, setChannelsUnreadCount] = useState<ChannelsUnreadCount | null>(null);

  const [chatUsers, setChatUsers] = useState<User[]>([]);

  const [channelTypingUsers, setChannelTypingUsers] = useState<ChannelTypingUsers | null>(null);

  const [newChatType, setNewChatType] = useState<ChatTypes>(ChatTypes.p2p);

  const [isCreateMenuOpen, setIsCreateMenuOpen] = useState<boolean>(false);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  const [chatToJoin, setChatToJoin] = useState<Chat | null>(null);

  const p2pChatCreateAllowed = useMemo(
    () => orgPermissions.chatP2PAllowToCreate,
    [orgPermissions.chatP2PAllowToCreate]
  );

  const groupChatCreateAllowed = useMemo(
    () => channelPermissions.chatGroupAllowToCreate || orgPermissions.chatGroupAllowToCreate,
    [channelPermissions.chatGroupAllowToCreate, orgPermissions.chatGroupAllowToCreate]
  );

  const renderChatCreate = useMemo(
    () => (p2pChatCreateAllowed || groupChatCreateAllowed) && (isAdminLayout() || showChatCreate),
    [groupChatCreateAllowed, p2pChatCreateAllowed, showChatCreate]
  );

  const init = useCallback(async () => {
    const { chats, publishKey, subscribeKey, tokenV3 } = await getChannelChats().unwrap();

    const activeChat = chats.find((chat) => chat.chatId === chatId) ?? null;

    setActiveChat(activeChat);

    setChats(chats);

    setPubnub(
      new PubNub({ publishKey, subscribeKey, userId: userId.toString(), authKey: tokenV3 })
    );

    if (!activeChat && isDeepLink) {
      try {
        const data = await getChannelChatById({ chatId }).unwrap();

        if (
          !data ||
          ![ChatAccessTypes.publicRead, ChatAccessTypes.publicWrite].includes(data.accessType)
        ) {
          toast.error(t('chatActions.join-error'));
        } else {
          setChatToJoin(data);
        }
      } catch (_) {
        toast.error(t('chats.not-found'));
      }
    }

    setInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMobileOnly) {
      return;
    }

    init();

    const spacing = getComputedStyle(document.documentElement).getPropertyValue('--card-spacing');

    document.documentElement.style.setProperty('--card-spacing', '0.5rem');

    return () => document.documentElement.style.setProperty('--card-spacing', spacing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createHandler = useCallback((type: ChatTypes) => {
    setIsCreateMenuOpen(false);
    setNewChatType(type);
    setIsCreateModalOpen(true);
  }, []);

  const dropdownMenuContent = useMemo(() => {
    return (
      <>
        {p2pChatCreateAllowed && (
          <IconLabel
            iconId={'user'}
            iconSize={18}
            label={t('chatActions.create-direct-chat')}
            className={dropdownMenuClasses['dropdown-menu__item--small']}
            onClick={() => createHandler(ChatTypes.p2p)}
            singleColor
          />
        )}

        {groupChatCreateAllowed && (
          <IconLabel
            iconId={'users'}
            iconSize={18}
            label={t('chatActions.create-group-chat')}
            className={dropdownMenuClasses['dropdown-menu__item--small']}
            onClick={() => createHandler(ChatTypes.group)}
            singleColor
          />
        )}
      </>
    );
  }, [createHandler, groupChatCreateAllowed, p2pChatCreateAllowed, t]);

  const onSearchClose = useCallback(() => {
    setSearchActive(false);
    setSearch('');
  }, []);

  const searchContent = useMemo(() => {
    if (searchActive) {
      return (
        <InputField
          value={search}
          onChange={({ target }) => setSearch(target.value)}
          placeholder={t('common.search')}
          autoFocus
          icon={
            <IconLabel
              iconId={'close'}
              iconSize={18}
              color={'#B3B3B3'}
              onClick={onSearchClose}
              singleColor
            />
          }
        />
      );
    }

    return (
      <>
        <div className={classes['chats__header-search-title']}>{t('adminLayoutMenu.chats')}</div>
        <div className={classes['chats__header-search-icons']}>
          <IconLabel iconId={'search'} iconSize={18} onClick={() => setSearchActive(true)} />
          {renderChatCreate && (
            <Popup
              isOpen={isCreateMenuOpen}
              setIsOpen={setIsCreateMenuOpen}
              iconId={'add-plus'}
              iconSize={18}
              bodyTop={'1.5rem'}
              bodyRight={'0'}
              body={<DropdownMenu width={'10rem'} content={dropdownMenuContent} />}
            />
          )}
        </div>
      </>
    );
  }, [
    dropdownMenuContent,
    isCreateMenuOpen,
    onSearchClose,
    renderChatCreate,
    search,
    searchActive,
    t,
  ]);

  const joinChatCallback = useCallback(() => {
    navigate(layoutPath(`/chats?chatId=${chatToJoin?.chatId}`));
    setChatToJoin(null);
  }, [chatToJoin?.chatId, navigate]);

  if (!appTabs.map(({ action }) => action.type).includes(ChannelAppTabAction.CHATS)) {
    navigate(layoutPath('/'));
  }

  if (isMobileOnly) {
    return (
      <div className={classes['placeholder']}>
        <img src={chatPlaceholder} className={classes['placeholder__img']} alt={'placeholder'} />
        <div className={classes['placeholder__title']}>{t('chatsPlaceholder.title')}</div>
        <div className={classes['placeholder__subTitle']}>{t('chatsPlaceholder.sub-title')}</div>
        {iosLink && (
          <a href={iosLink}>
            <svg width={153} height={45}>
              <use href={`${sprite}#donwload-ios-app`} />
            </svg>
          </a>
        )}
        {androidLink && (
          <a href={androidLink}>
            <svg width={153} height={45}>
              <use href={`${sprite}#donwload-android-app`} />
            </svg>
          </a>
        )}
      </div>
    );
  }

  if (initialLoad) {
    return <Skeleton height={'10rem'} />;
  }

  if (!pubnub) {
    return null;
  }

  return (
    <ChatContext.Provider
      value={{
        pubnub,
        activeChat,
        setActiveChat,
        chats,
        setChats,
        selectedTabIndex,
        setSelectedTabIndex,
        channels,
        setChannels,
        channelsChanges,
        setChannelsChanges,
        channelsUnreadCount,
        setChannelsUnreadCount,
        chatUsers,
        setChatUsers,
        channelTypingUsers,
        setChannelTypingUsers,
      }}
    >
      <div className={classes['chats']}>
        <div className={classes['chats__header']}>
          <div className={classes['chats__header-search']}>{searchContent}</div>
          <ChatsList search={debouncedSearch} />
        </div>
        <ChatHistory />
      </div>
      {isCreateModalOpen && (
        <CreateChatModal
          chat={{ type: newChatType } as Chat}
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}
      {Boolean(chatToJoin) && (
        <Modal
          isOpen={Boolean(chatToJoin)}
          body={<JoinChat chat={chatToJoin as Chat} callback={joinChatCallback} />}
          onClose={() => setChatToJoin(null)}
        />
      )}
    </ChatContext.Provider>
  );
};
