import { FunctionComponent, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { UserContext } from '../../../../context';
import {
  FileBlockData,
  FileLink,
  FileStatus,
  ImageGallery,
  ImageGalleryItem,
  MediaUploadPreview,
  useFreeLoginPopup,
} from '../../../../shared';

import classes from './File.module.scss';

export const File: FunctionComponent<FileBlockData> = memo(({ items, isPdf }) => {
  const { t } = useTranslation();

  const { freeLoginModal, freeLoginHandler } = useFreeLoginPopup();

  const { isAnonymous } = useContext(UserContext).userProfile;

  const fileClickHandler = useCallback(() => {
    if (isAnonymous) {
      freeLoginHandler();
      return;
    }

    window.open(items?.[0]?.file?.url, '_blank', 'noopener, noreferrer');
  }, [freeLoginHandler, isAnonymous, items]);

  if (!items.length) {
    return null;
  }

  const { file, previewUrl, caption, copyright } = items[0];

  if (!file) {
    return null;
  }

  const { url, status } = file;

  if (status === FileStatus.PROCESSING) {
    return <Skeleton height={'3rem'} />;
  }

  const type = isPdf ? 'pdf' : 'file';

  if (!url) {
    return <MediaUploadPreview iconId={type} label={t(`richTextEditor.${type}-placeholder`)} />;
  }

  const imageGalleryItems: ImageGalleryItem[] = [
    { image: { url: previewUrl, rightholder: copyright } },
  ];

  return (
    <>
      <div className={classes['file']} onClick={fileClickHandler}>
        <ImageGallery items={imageGalleryItems} singleImageFullSize />
        <FileLink file={file} caption={caption} />
      </div>

      {freeLoginModal}
    </>
  );
});
