/* eslint-disable @typescript-eslint/no-explicit-any */
import { Message, MessageAction } from 'pubnub/lib/types/core/types/api/subscription';
import { useContext, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ChatContext, UserContext } from '../../../context';
import { Chat, ChatAccess, chatsApi, MessageSubTypes, MessageTypes, User } from '../../../services';
import { cookieOptions, Emoji, layoutPath } from '../../../shared';
import { MessageReaction, MessageReactionData } from '../ChatHistory';
import { getChatCookieKey } from '../helpers';

export const useMessageListener = (chatsCookieKeys: string[]) => {
  const navigate = useNavigate();

  const [getChannelChats] = chatsApi.endpoints.getChannelChats.useLazyQuery();

  const userId = useContext(UserContext).userProfile.id;

  const {
    pubnub,
    activeChat,
    setActiveChat,
    setChats,
    setChannels,
    setChannelsChanges,
    setChannelsUnreadCount,
    setChannelTypingUsers,
  } = useContext(ChatContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies(chatsCookieKeys);

  const activeChatRef = useRef<Chat | null>(null);

  useEffect(() => {
    if (!activeChat) {
      return;
    }

    activeChatRef.current = activeChat;
  }, [activeChat]);

  const messageListener = {
    message: async (fetchedMessage: Message) => {
      const { channel, message, publisher, timetoken } = fetchedMessage;

      const { type, subtype, text, data } = message as {
        type: string;
        subtype: MessageSubTypes;
        text: string;
        data: { users?: any[] };
      };

      const { users = [] } = { ...data };

      if (subtype === MessageSubTypes.leave) {
        const user = users.find((id) => id === userId);

        if (!user) {
          return;
        }

        pubnub.unsubscribe({ channels: [channel, `${channel}.changes`] });

        const { chats, tokenV3 } = await getChannelChats().unwrap();

        pubnub.setAuthKey(tokenV3);

        setChats(chats);

        if (activeChatRef.current?.chatDescriptor === channel) {
          setActiveChat(null);
          navigate(layoutPath('/chats'));
        }
      }

      if (subtype === MessageSubTypes.changeUsersAccess) {
        const user = users.find(({ id }) => id === userId);

        if (!user) {
          return;
        }

        const { access } = user;

        access === ChatAccess.banned
          ? pubnub.unsubscribe({ channels: [channel, `${channel}.changes`] })
          : pubnub.subscribe({ channels: [channel, `${channel}.changes`] });

        const { chats, tokenV3 } = await getChannelChats().unwrap();

        pubnub.setAuthKey(tokenV3);

        setChats(chats);

        if (activeChatRef.current?.chatDescriptor !== channel) {
          return;
        }

        const isBanned = access === ChatAccess.banned;

        setActiveChat(isBanned ? null : { ...activeChatRef.current, access });

        if (isBanned) {
          navigate(layoutPath('/chats'));
        }

        return;
      }

      if (type === MessageTypes.chatDelete) {
        const { chats, tokenV3 } = await getChannelChats().unwrap();

        pubnub.setAuthKey(tokenV3);

        setChannels((channelsPrev) => {
          const channelsPrevCopy = { ...channelsPrev };

          delete channelsPrevCopy[channel];

          return channelsPrevCopy;
        });

        setChannelsChanges((channelsChangesPrev) => {
          const channelsChangesPrevCopy = { ...channelsChangesPrev };

          delete channelsChangesPrevCopy[`${channel}.changes`];

          return channelsChangesPrevCopy;
        });

        setChats(chats);

        if (activeChatRef.current?.chatDescriptor === channel) {
          setActiveChat(null);
          toast(text);
          navigate(layoutPath('/chats'));
        }

        pubnub.unsubscribe({ channels: [channel, `${channel}.changes`] });

        return;
      }

      const channelsSetter = type === MessageTypes.delete ? setChannelsChanges : setChannels;

      channelsSetter((prevChannels) => {
        const prevMessages = prevChannels?.[channel] ?? [];

        return {
          ...prevChannels,
          [channel]: [...prevMessages, { ...fetchedMessage, uuid: publisher }],
        };
      });

      if (activeChatRef.current?.chatDescriptor === channel) {
        setChannelsUnreadCount((channelsUnreadCountPrev) => {
          return { ...channelsUnreadCountPrev, [channel]: 0 };
        });

        setCookie(getChatCookieKey(channel), { timetoken }, cookieOptions());
      } else {
        setChannelsUnreadCount((channelsUnreadCountPrev) => {
          return {
            ...channelsUnreadCountPrev,
            [channel]: (channelsUnreadCountPrev?.[channel] ?? 0) + 1,
          };
        });
      }
    },
    messageAction: (fetchedMessageAction: MessageAction) => {
      const { channel, event, data } = fetchedMessageAction;
      const { actionTimetoken, messageTimetoken, type, uuid, value } = data;

      setChannels((channelsPrev) => {
        const messages = [...(channelsPrev?.[channel] ?? [])];

        const messageIndex = messages.findIndex(({ timetoken }) => timetoken === messageTimetoken);

        if (messageIndex === -1) {
          return channelsPrev;
        }

        if (type === 'reaction') {
          const val = value as Emoji;

          const reactionCopy = { ...messages[messageIndex].data?.reaction } as MessageReaction;

          const data = (reactionCopy?.[val] ?? []) as MessageReactionData[];

          if (event === 'added') {
            reactionCopy[val] = [...data, { uuid, actionTimetoken }];
          }

          if (event === 'removed') {
            const dataCopy = [...data];

            const index = dataCopy.findIndex(
              (reaction) => reaction.uuid === uuid && reaction.actionTimetoken === actionTimetoken
            );

            dataCopy.splice(index, 1);

            if (!dataCopy.length) {
              delete reactionCopy[val];
            } else {
              reactionCopy[val] = dataCopy;
            }
          }

          messages[messageIndex].data = {
            ...messages[messageIndex].data,
            reaction: reactionCopy as any,
          };
        }

        return { ...channelsPrev, [channel]: messages };
      });
    },
    signal: ({ channel, publisher, message }: Message) => {
      const { typing, authorName } = message as { typing: boolean; authorName: string };

      setChannelTypingUsers((channelTypingUsersPrev) => {
        const channelUsersCopy = [...(channelTypingUsersPrev?.[channel] ?? [])];

        const userId = parseInt(publisher ?? '', 10);

        if (!typing) {
          const index = channelUsersCopy.findIndex(({ id }) => id === userId);

          if (index === -1) {
            return channelTypingUsersPrev;
          }

          channelUsersCopy.splice(index, 1);

          return { ...channelTypingUsersPrev, [channel]: channelUsersCopy };
        }

        const newUser = { id: userId, screenName: authorName } as User;

        if (!channelUsersCopy.length) {
          return { ...channelTypingUsersPrev, [channel]: [newUser] };
        }

        const user = channelUsersCopy.find(({ id }) => id === userId);

        if (!user) {
          return { ...channelTypingUsersPrev, [channel]: [...channelUsersCopy, newUser] };
        }

        return channelTypingUsersPrev;
      });
    },
  };

  return { messageListener };
};
