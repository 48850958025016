import {
  emailValidationError,
  isHtmlString,
  isUrl,
  phoneValidationError,
  unescape,
} from '../utils';

interface LinkStringProps {
  href: string;
  isEmail?: boolean;
  isPhone?: boolean;
}

export const useLinkify = () => {
  const getLink = ({ href, isEmail, isPhone }: LinkStringProps) => {
    const getHref = () => {
      switch (true) {
        case isEmail:
          return `mailto:${href}`;
        case isPhone:
          return `tel:${href}`;
        default:
          return href;
      }
    };

    return `<a href=${getHref()} target='_blank' rel='noreferrer'>${href}</a>`;
  };

  const mapper = (text: string) => {
    return text
      .split('\n')
      .map((part) => {
        return part
          .split(' ')
          .map((word) => {
            if (isUrl(word)) {
              return getLink({ href: word });
            }

            if (!emailValidationError(word)) {
              return getLink({ href: word, isEmail: true });
            }

            if (!phoneValidationError(word)) {
              return getLink({ href: word, isPhone: true });
            }

            return word;
          })
          .join(' ');
      })
      .join('\n');
  };

  const linkify = (content: string) => {
    if (!isHtmlString(content)) {
      return mapper(content);
    }

    const template = document.createElement('template');

    template.innerHTML = content;

    template.content.childNodes.forEach((node) => {
      node.childNodes.forEach((childNode) => {
        const { nodeType, nodeValue } = childNode;

        if (nodeType === Node.TEXT_NODE && nodeValue) {
          childNode.textContent = mapper(nodeValue);
        }
      });
    });

    return unescape(template.innerHTML);
  };

  return { linkify };
};
