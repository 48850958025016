import { FunctionComponent, memo, useMemo } from 'react';
import { StoryCardType } from '../../services';
import { FeedCardProps } from '../../shared';
import { FeedCardAdminArticle } from './FeedCardAdminArticle';
import { FeedCardAdminAudio } from './FeedCardAdminAudio';
import { FeedCardAdminEditorial } from './FeedCardAdminEditorial';
import { FeedCardAdminImage } from './FeedCardAdminImage';
import { FeedCardAdminPdf } from './FeedCardAdminPdf';
import { FeedCardAdminPost } from './FeedCardAdminPost';
import { FeedCardAdminSocial } from './FeedCardAdminSocial';
import { FeedCardAdminThread } from './FeedCardAdminThread';
import { FeedCardAdminVideo } from './FeedCardAdminVideo';
import { FeedCardAdminWrapper } from './FeedCardAdminWrapper';

export const FeedCardAdmin: FunctionComponent<FeedCardProps> = memo((props) => {
  const card = useMemo(() => {
    switch (props.card.type) {
      case StoryCardType.THREAD:
        return <FeedCardAdminThread {...props} />;
      case StoryCardType.IMAGE:
        return <FeedCardAdminImage {...props} />;
      case StoryCardType.EDITORIAL:
        return <FeedCardAdminEditorial {...props} />;
      case StoryCardType.AUDIO:
        return <FeedCardAdminAudio {...props} />;
      case StoryCardType.VIDEO:
        return <FeedCardAdminVideo {...props} />;
      case StoryCardType.PDF:
        return <FeedCardAdminPdf {...props} />;
      case StoryCardType.QUOTE:
        return <FeedCardAdminSocial {...props} />;
      case StoryCardType.ARTICLE:
        return <FeedCardAdminArticle {...props} />;
      case StoryCardType.POST:
        return <FeedCardAdminPost {...props} />;
      default:
        return null;
    }
  }, [props]);

  return <FeedCardAdminWrapper {...props}>{card}</FeedCardAdminWrapper>;
});
