import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getCssVar } from '../../utils';
import { IconLabel, IconLabelHintDirection } from '../IconLabel';

import classNames from 'classnames';

interface ScheduleIconProps {
  postingTime: Date | string | null;
  hintDirection?: IconLabelHintDirection;
  className?: string;
}

export const ScheduleIcon: FunctionComponent<ScheduleIconProps> = memo(
  ({ postingTime, hintDirection, className }) => {
    const { t } = useTranslation();

    const data = useMemo(
      () =>
        postingTime
          ? format(parseISO(new Date(postingTime).toISOString()), 'dd.MM.yy, HH:mm')
          : t('addContent.schedule-post'),
      [postingTime, t]
    );

    return (
      <IconLabel
        className={classNames(className)}
        iconId={`calendar-${postingTime ? 'added' : 'add'}`}
        color={getCssVar('--schedule-icon-color')}
        hoverColor={getCssVar('--schedule-icon-hover-color')}
        data={data}
        hintDirection={hintDirection}
      />
    );
  }
);
