/* eslint-disable jsx-a11y/anchor-has-content */
import { useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfigContext, UserContext } from '../../../context';
import {
  Button,
  ButtonType,
  Check,
  IconLabel,
  InputField,
  Modal,
  TextAreaField,
} from '../../components';
import { DSA_REPORT_COOKIE_KEY } from '../../constants';
import { cookieOptions, emailValidationError, nameValidationError, optArrItem } from '../../utils';

import classes from './useDSAReportPopup.module.scss';

export enum DSAReportType {
  Post = 'post',
  Comment = 'comment',
  Chat = 'chat',
  Message = 'message',
  User = 'user',
}

export enum DSAReason {
  HateSpeechOrIncitementToViolence = 'hate_speech_or_incitement_to_violence',
  InfringementOfCopyrightOrIntellectualProperty = 'infringement_of_copyright_or_intellectual_property',
  ChildPornographyOrSexualAbuse = 'child_pornography_or_sexual_abuse',
  FraudOrFraudulentActivities = 'fraud_or_fraudulent_activities',
  ViolationOfDataProtectionRegulations = 'violation_of_data_protection_regulations',
  ViolationOfSitePoliciesOrTermsOfUse = 'violation_policies_or_terms_of_use',
  Other = 'other',
}

interface Reporter {
  email?: string;
  name?: string;
}

export interface DSAReport {
  type: DSAReportType;
  reason: DSAReason;
  reportedBy?: Reporter;
  reporterComment?: string;
}

export interface PostDSAReport extends DSAReport {
  channelId: string;
  channelName: string;
  storyId: string;
  storyName: string;
  postId: string;
  postType: string;
  postTitle: string;
  authorId?: string;
  authorName?: string;
}

export interface CommentDSAReport extends DSAReport {
  channelId: string;
  channelName: string;
  storyId: string;
  storyName: string;
  postId: string;
  postType: string;
  postTitle: string;
  authorId?: string;
  authorName?: string;
  commentId: string;
  commentText: string;
  commentAuthorId?: string;
  commentAuthorName?: string;
}

export interface ChatDSAReport extends DSAReport {
  channelId?: string;
  channelName?: string;
  chatId: string;
  chatName: string;
  chatType: string;
}

export interface MessageDSAReport extends ChatDSAReport {
  messageId: string;
  messageText: string;
  messageAuthorId: string;
  messageAuthorName: string;
  messageType: string;
}

export interface UserDSAReport extends DSAReport {
  channelId: string;
  channelName: string;
  userID: string;
  userName: string;
  userEmail?: string;
}

interface DSAReportProps {
  type: DSAReportType;
  userID?: number;
  userName?: string;
  userEmail?: string;
  storyId?: number;
  storyName?: string;
  postId?: number;
  postType?: string;
  postTitle?: string;
  authorId?: number;
  authorName?: string;
  commentId?: number;
  commentText?: string;
  commentAuthorId?: number;
  commentAuthorName?: string;
  chatId?: number;
  chatName?: string;
  chatType?: string;
  messageId?: string;
  messageText?: string;
  messageAuthorId?: string;
  messageAuthorName?: string;
  messageType?: string;
}

export const useDSAReportPopup = ({
  type,
  userID,
  userEmail,
  userName,
  storyId,
  storyName,
  postId,
  postType,
  postTitle,
  authorId,
  authorName,
  commentId,
  commentText,
  commentAuthorId,
  commentAuthorName,
  chatId,
  chatName,
  chatType,
  messageId,
  messageText,
  messageAuthorId,
  messageAuthorName,
  messageType,
}: DSAReportProps) => {
  const { t } = useTranslation();

  const { emailPlaceholder, dsaReport } = useContext(ConfigContext).config.elements;

  const { appDomain, recipient, links = [] } = { ...dsaReport };

  const { channelId, channelName } = useContext(UserContext).userInfo.body;

  const [isOpen, setIsOpen] = useState(false);

  const [reason, setReason] = useState<DSAReason>();

  const [reporterComment, setReporterComment] = useState<string>('');

  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);

  const [reporterEmail, setReporterEmail] = useState<string>('');

  const [isReporterEmailValid, setIsReporterEmailValid] = useState<boolean>(true);

  const [reporterName, setReporterName] = useState<string>('');

  const [isReporterNameValid, setIsReporterNameValid] = useState<boolean>(true);

  const [consent, setConsent] = useState<boolean>(false);

  const [cookies, setCookie] = useCookies([DSA_REPORT_COOKIE_KEY]);

  const {
    reportedUserIds = [],
    reportedPostIds = [],
    reportedCommentIds = [],
    reportedChatIds = [],
    reportedMessageIds = [],
  } = {
    ...cookies[DSA_REPORT_COOKIE_KEY],
  };

  const submitDisabled = useMemo(() => Boolean(!consent), [consent]);

  const allowAnonymous = useMemo(
    () => Boolean(reason === DSAReason.ChildPornographyOrSexualAbuse),
    [reason]
  );

  const renderLinks = useMemo(() => {
    return links.map(({ localiseKey, href }) => (
      <div className={classes['dsa-report__links']}>
        <Trans i18nKey={localiseKey}>
          {href && (
            <a
              className={classes['dsa-report__links-item']}
              href={href}
              target={'_blank'}
              rel={'noreferrer'}
            ></a>
          )}
        </Trans>
      </div>
    ));
  }, [links]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    setReason(undefined);
    setReporterComment('');
    setIsAnonymous(false);
    setReporterEmail('');
    setIsReporterEmailValid(true);
    setReporterName('');
    setIsReporterNameValid(true);
    setConsent(false);
  }, []);

  const reporterEmailChangeHandler = useCallback((value: string) => {
    setIsReporterEmailValid(true);
    setReporterEmail(value);
  }, []);

  const reporterNameChangeHandler = useCallback((value: string) => {
    setIsReporterNameValid(true);
    setReporterName(value);
  }, []);

  const reported = useMemo(() => {
    switch (type) {
      case DSAReportType.User:
        return reportedUserIds.includes(userID);
      case DSAReportType.Post:
        return reportedPostIds.includes(postId);
      case DSAReportType.Comment:
        return reportedCommentIds.includes(commentId);
      case DSAReportType.Chat:
        return reportedChatIds.includes(chatId);
      case DSAReportType.Message:
        return reportedMessageIds.includes(messageId);
      default:
        return false;
    }
  }, [
    chatId,
    commentId,
    messageId,
    postId,
    reportedChatIds,
    reportedCommentIds,
    reportedMessageIds,
    reportedPostIds,
    reportedUserIds,
    type,
    userID,
  ]);

  const submitHandler = useCallback(async () => {
    if (!isAnonymous && nameValidationError(reporterName)) {
      setIsReporterNameValid(false);
      return;
    }

    if (!isAnonymous && emailValidationError(reporterEmail)) {
      setIsReporterEmailValid(false);
      return;
    }

    onClose();

    if (!appDomain) {
      return;
    }

    const response = await toast.promise(
      fetch(`${appDomain}/api/v1/dsa-reports`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type,
          reason,
          channelId,
          channelName,
          reporterComment,
          ...(!isAnonymous && { reportedBy: { email: reporterEmail, name: reporterName } }),
          ...(userID && { userID }),
          ...(userEmail && { userEmail }),
          ...(userName && { userName }),
          ...(storyId && { storyId }),
          ...(storyName && { storyName }),
          ...(postId && { postId }),
          ...(postType && { postType }),
          ...(postTitle && { postTitle }),
          ...(authorId && { authorId }),
          ...(authorName && { authorName }),
          ...(commentId && { commentId }),
          ...(commentText && { commentText }),
          ...(commentAuthorId && { commentAuthorId }),
          ...(commentAuthorName && { commentAuthorName }),
          ...(chatId && { chatId }),
          ...(chatName && { chatName }),
          ...(chatType && { chatType }),
          ...(messageId && { messageId }),
          ...(messageText && { messageText }),
          ...(messageAuthorId && { messageAuthorId }),
          ...(messageAuthorName && { messageAuthorName }),
          ...(messageType && { messageType }),
        }),
      }),
      {
        pending: t('DSAReport.submitting'),
        success: t('DSAReport.submitted'),
        error: t('DSAReport.submit-error'),
      }
    );

    if (response.ok) {
      setCookie(
        DSA_REPORT_COOKIE_KEY,
        {
          reportedUserIds: [...reportedUserIds, ...optArrItem(type === DSAReportType.User, userID)],
          reportedPostIds: [...reportedPostIds, ...optArrItem(type === DSAReportType.Post, postId)],
          reportedCommentIds: [
            ...reportedCommentIds,
            ...optArrItem(type === DSAReportType.Comment, commentId),
          ],
          reportedChatIds: [...reportedChatIds, ...optArrItem(type === DSAReportType.Chat, chatId)],
          reportedMessageIds: [
            ...reportedMessageIds,
            ...optArrItem(type === DSAReportType.Message, messageId),
          ],
        },
        cookieOptions()
      );
    }
  }, [
    appDomain,
    authorId,
    authorName,
    channelId,
    channelName,
    chatId,
    chatName,
    chatType,
    commentAuthorId,
    commentAuthorName,
    commentId,
    commentText,
    isAnonymous,
    messageAuthorId,
    messageAuthorName,
    messageId,
    messageText,
    messageType,
    onClose,
    postId,
    postTitle,
    postType,
    reason,
    reportedChatIds,
    reportedCommentIds,
    reportedMessageIds,
    reportedPostIds,
    reportedUserIds,
    reporterComment,
    reporterEmail,
    reporterName,
    setCookie,
    storyId,
    storyName,
    t,
    type,
    userEmail,
    userID,
    userName,
  ]);

  const bodyContent = useMemo(() => {
    if (!reason) {
      return (
        <div className={classes['dsa-report__reasons']}>
          {Object.entries(DSAReason).map(([key, value]) => {
            return (
              <span
                key={key}
                className={classes['dsa-report__reasons-item']}
                onClick={() => setReason(value)}
              >
                {t(`DSAReportReasons.${value}`)}
                <IconLabel iconId={'arrow-right'} iconSize={18} singleColor />
              </span>
            );
          })}
        </div>
      );
    }

    return (
      <>
        <TextAreaField
          placeholder={t('DSAReport.comment-placeholder')}
          value={reporterComment}
          maxLength={2000}
          onChange={({ target }) => setReporterComment(target.value)}
          disableLineBreak
        />

        {allowAnonymous && (
          <Check
            className={classes['dsa-report__anonymous']}
            label={t('DSAReport.send-anonymously')}
            checked={isAnonymous}
            onChange={setIsAnonymous}
          />
        )}

        <InputField
          label={t('common.name')}
          value={reporterName}
          placeholder={t('common.name-placeholder')}
          onChange={({ target }) => reporterNameChangeHandler(target.value)}
          disabled={isAnonymous}
          error={!isReporterNameValid}
          {...(!isReporterNameValid && { errorMessage: t('auth.name-invalid') })}
        />

        <InputField
          label={t('common.email')}
          value={reporterEmail}
          placeholder={emailPlaceholder ?? t('common.email-placeholder')}
          onChange={({ target }) => reporterEmailChangeHandler(target.value)}
          disabled={isAnonymous}
          error={!isReporterEmailValid}
          {...(!isReporterEmailValid && { errorMessage: t('auth.email-invalid') })}
        />

        <Check
          className={classes['dsa-report__consent']}
          label={t('DSAReport.consent')}
          checked={consent}
          onChange={setConsent}
        />

        {renderLinks}

        <div className={classes['dsa-report__buttons']}>
          <Button
            type={ButtonType.secondary}
            label={t('common.back')}
            onClick={() => setReason(undefined)}
          />
          <Button
            type={ButtonType.primary}
            label={t('common.submit')}
            disabled={submitDisabled}
            onClick={submitHandler}
          />
        </div>
      </>
    );
  }, [
    allowAnonymous,
    consent,
    emailPlaceholder,
    isAnonymous,
    isReporterEmailValid,
    isReporterNameValid,
    reason,
    renderLinks,
    reporterComment,
    reporterEmail,
    reporterEmailChangeHandler,
    reporterName,
    reporterNameChangeHandler,
    submitDisabled,
    submitHandler,
    t,
  ]);

  const body = useMemo(() => {
    return (
      <div className={classes['dsa-report']}>
        <div className={classes['dsa-report__intro']}>
          {t('DSAReport.intro', { email: recipient })}
        </div>
        {bodyContent}
      </div>
    );
  }, [bodyContent, recipient, t]);

  const DSAReportModal = useMemo(() => {
    if (!isOpen) {
      return null;
    }
    return <Modal isOpen={isOpen} body={body} onClose={onClose} alignTop />;
  }, [body, isOpen, onClose]);

  const DSAReportHandler = useCallback(async () => {
    if (reported) {
      toast.info(t('DSAReport.was-reported'));
      return;
    }

    setIsOpen(true);
  }, [reported, t]);

  return { DSAReportModal, DSAReportHandler };
};
