import { FunctionComponent, memo, useMemo } from 'react';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';

import classes from './NotificationDefaultContent.module.scss';

interface NotificationDefaultContentProps {
  title: string;
  message: string;
  hasLink?: boolean;
}

export const NotificationDefaultContent: FunctionComponent<NotificationDefaultContentProps> = memo(
  ({ title, message, hasLink = false }) => {
    const linkIcon = useMemo(
      () =>
        hasLink && (
          <IconLabel
            className={classes['content__link-icon']}
            iconId={'arrow-rounded-right-up'}
            iconSize={14}
            color={getCssVar('--base-link-text-color')}
            singleColor
          />
        ),
      [hasLink]
    );

    return (
      <div className={classes['content']}>
        {title && (
          <div className={classes['content__title']}>
            {title} {linkIcon}
          </div>
        )}
        {message && <div className={classes['content__message']}>{message}</div>}
      </div>
    );
  }
);
