import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import classes from './FeedCardCollapsedLoader.module.scss';

export const FeedCardCollapsedLoader: FunctionComponent = () => {
  return (
    <div className={classes['loader']}>
      <Skeleton height={'3rem'} />
      <Skeleton height={'3rem'} />
    </div>
  );
};
