import { FetchedMessage } from 'pubnub/lib/types/core/types/api/history';
import { FunctionComponent, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatContext } from '../../../../context';
import {
  ChatAccess,
  ChatAccessTypes,
  ChatLevels,
  ChatTypes,
  MessageSubTypes,
} from '../../../../services';
import { unescape } from '../../../../shared';

import classes from './ServerMessage.module.scss';

interface ServerMessageProps {
  fetchedMessage: FetchedMessage;
}

export const ServerMessage: FunctionComponent<ServerMessageProps> = memo(({ fetchedMessage }) => {
  const { t } = useTranslation();

  const { chatUsers } = useContext(ChatContext);

  const { chatType, chatName, subtype, actionAuthor, actionAuthorName, data } =
    fetchedMessage.message as {
      chatType: string;
      chatName: string;
      subtype: MessageSubTypes;
      actionAuthor: number;
      actionAuthorName: string;
      data: {
        name?: string;
        payload?: string;
        accessType?: ChatAccessTypes;
        level?: ChatLevels;
        users?: { id: number; access: ChatAccess }[];
      };
    };

  const { name = '', payload = '', accessType = '', level, users = [] } = { ...data };

  const message = useMemo(() => {
    switch (subtype) {
      case MessageSubTypes.create: {
        switch (chatType) {
          case ChatTypes.p2p:
            return t('chatServerMessages.p2p-chat-created');
          default:
            return t('chatServerMessages.group-chat-created', { name: chatName });
        }
      }
      case MessageSubTypes.join:
        return t('chatServerMessages.users-added', { count: users.length });
      case MessageSubTypes.leave:
        return t('chatServerMessages.users-removed', { count: users.length });
      case MessageSubTypes.change: {
        switch (true) {
          case Boolean(name):
            return t('chatServerMessages.name-changed', { name });
          case Boolean(Object.hasOwn(data, 'payload')):
            return t(`chatServerMessages.purpose-${payload ? 'changed' : 'cleared'}`, {
              payload,
            });
          case Boolean(accessType):
            return t('chatServerMessages.access-type-changed', {
              accessType: t(`chatAccessTypes.${accessType}`),
            });
          case Boolean(level):
            return t('chatServerMessages.level-changed', { level: t(`chatLevels.${level}`) });
          default:
            return '';
        }
      }
      default:
        return '';
    }
  }, [accessType, chatName, chatType, data, level, name, payload, subtype, t, users.length]);

  const author = useMemo(
    () => chatUsers.find(({ id }) => id === actionAuthor)?.screenName ?? actionAuthorName,
    [actionAuthor, actionAuthorName, chatUsers]
  );

  if (subtype === MessageSubTypes.changeUsersAccess) {
    return null;
  }

  return (
    <div className={classes['server-message']}>
      {author} {unescape(message)}
    </div>
  );
});
