import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { useUserByIdQuery } from '../../services';
import { NoContent } from '../Error';
import { Profile } from './Profile';
import { ProfileFeed } from './ProfileFeed';
import { UserFeed } from './UserFeed';

import classes from './Profile.module.scss';

export const ProfileOther: FunctionComponent = () => {
  const { id } = useParams();

  const { data: profileOther, isLoading } = useUserByIdQuery({
    userId: Number(id),
  });

  if (isLoading) {
    return <Skeleton height={'10rem'} />;
  }

  if (!profileOther) {
    return <NoContent />;
  }

  const { id: userId, avatar, screenName, roleId, bio, links, url } = profileOther;

  return (
    <>
      <Profile
        userId={userId}
        roleId={roleId}
        avatar={avatar}
        screenName={screenName}
        bio={bio}
        links={links}
        me={false}
        url={url}
      />

      <div className={classes['profile__feeds-wrapper']}>
        <ProfileFeed userId={userId} me={false} />
        <UserFeed userId={userId} me={false} />
      </div>
    </>
  );
};
