import { FunctionComponent } from 'react';
import app from '../../../assets/images/teaser-app.png';
import qr from '../../../assets/images/teaser-qr.png';
import { IconLabel, IconLabelSizes, Modal } from '../../../shared';

import classes from './TeaserDetails.module.scss';

interface TeaserDetailsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TeaserDetails: FunctionComponent<TeaserDetailsProps> = ({ isOpen, onClose }) => {
  const body = (
    <div className={classes['teaser-details']}>
      <div className={classes['teaser-details__title']}>Jetzt auch als App!</div>
      <div className={classes['teaser-details__subtitle']}>
        Einfach QR-Code mit dem Smartphone scannen und gleich loslegen!
      </div>
      <img className={classes['teaser-details__qr']} src={qr} alt={'qr'} />
      <img className={classes['teaser-details__app']} src={app} alt={'app'} />
      <a
        className={classes['teaser-details__info']}
        href={'https://www.aok.de/mk/niedersachsen/dialog-online'}
        target={'_blank'}
        rel={'noreferrer'}
      >
        <IconLabel
          iconId={'arrow-rounded-right'}
          iconSize={10}
          label={'Mehr Infos'}
          labelSize={IconLabelSizes.large}
          color={'#18ab42'}
          hoverColor={'#005e3f'}
          labelFirst
        />
      </a>
    </div>
  );

  return <Modal isOpen={isOpen} body={body} onClose={onClose} />;
};
