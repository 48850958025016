import { COMMENTS_SIZE, OrderDirection } from '../../../shared';
import { commentQuery } from './commentQuery';
import { contentBlocksQuery } from './contentBlocksQuery';
import { storyQuery } from './storyQuery';

interface StoryCardQueryArgs {
  isFeed: boolean;
  isAdminLayout: boolean;
}

export const storyCardQuery = ({ isFeed, isAdminLayout }: StoryCardQueryArgs) => `
  apiClient {
    type
    name
  }
  localeId
  author {
    avatar {
      averageColor
      externalId
      externalType
      id
      originalFilename
      rightholder
      status
      statusCopyrightId
      thumb
      url
    }
    email
    id
    screenName
  }
  user {
    avatar {
      averageColor
      externalId
      externalType
      id
      originalFilename
      rightholder
      status
      statusCopyrightId
      thumb
      url
    }
    email
    id
    screenName
  }
  mentionedUsers {
    avatar {
      averageColor
      externalId
      externalType
      id
      originalFilename
      rightholder
      status
      statusCopyrightId
      thumb
      url
    }
    email
    id
    screenName
  }
  category {
    id
    name
    settings {
      userSelection
    }
  }
  authorId
  isReadOnly
  commentsCount(filters: {showHidden: ${isAdminLayout}})
  commentsRootCount(filters: {showHidden: ${isAdminLayout}})
  status
  assignedUserId
  originalUserId
  isPinnedToTopOfNewsFeed
  section
  content {
    ... on StoryCardThreadContent {
      title
    }
    ... on StoryCardEditorialContent {
      headline
      sourceName
      subHeadline
      text
      textHTML
    }
    ... on StoryCardImageContent {
      headline
      sourceName
      subHeadline
      text
      textHTML
      gallery {
        id
        image {
          id
          originalFilename
          url
          averageColor
          rightholder
          status
        }
        title
      }
    }
    ... on StoryCardAudioContent {
      headline
      sourceName
      subHeadline
      text
      textHTML
      gallery {
        id
        audio {
          id
          originalFilename
          thumb
          url
          status
        }
        image {
          id
          originalFilename
          url
          averageColor
          rightholder
          status
        }
        title
      }
    }
    ... on StoryCardVideoContent {
      headline
      sourceName
      subHeadline
      text
      textHTML
      gallery {
        id
        video {
          id
          originalFilename
          thumb
          url
          status
        }
        image {
          id
          originalFilename
          url
          averageColor
          rightholder
          status
        }
        title
      }
    }
    ... on StoryCardPdfContent {
      headline
      sourceName
      subHeadline
      text
      textHTML
      gallery {
        id
        pdf {
          id
          originalFilename
          thumb
          url
          status
        }
        image {
          id
          originalFilename
          url
          averageColor
          rightholder
          status
        }
        title
      }
    }
    ... on StoryCardArticleContent {
      abstract
      contentAuthor
      contentReadTime
      gallery {
        id
        image {
          id
          originalFilename
          url
          averageColor
          rightholder
          status
        }
      }
      headline
      sourceName
      styles {
        teaserImageStyle
      }
      title
      url
    }
    ... on StoryCardQuoteContent {
      gallery {
        id
        image {
          id
          originalFilename
          url
          averageColor
          rightholder
          status
        }
      }            
      headline
      quote
      quoteCreated
      quotePerson
      quotePersonHandle
      quotePersonImage {
        id
        url
      }
      quoteSource
      url
    }
    ... on StoryCardPostContent {
      abstract
      contentAuthor
      contentReadTime
      gallery {
        id
        image {
          id
          originalFilename
          url
          averageColor
          rightholder
          status
        }
      }
      ${!isFeed ? `contentBlocks { ${contentBlocksQuery} }` : ''}
      headline
      sourceName
      styles {
        teaserImageStyle
      }
      title
    }
  }
  createdAt
  dateToDisplay
  ${
    isFeed
      ? 'comments: highlightedComments'
      : `comments(size: ${COMMENTS_SIZE}, filters: {showHidden: ${isAdminLayout}})`
  } {
    ${commentQuery(2, OrderDirection.ASC)}
  }
  id
  myReaction
  options {
    allowCardReaction
    allowComment
    allowDisplayAuthor
    allowSharing
    shouldUpdatePostedTimeByComments
  }
  externalTags: organisationTags(filters: {types: [EXTERNAL]}) {id name type }
  ${
    isAdminLayout
      ? `internalTags: organisationTags(filters: {types: [INTERNAL]}) { id name type }`
      : ''
  }
  permissions {
    allowToDelete
    allowToUpdate
    allowToUpdatePosition
    allowToUpdateStatus
  }
  position
  postedAt
  postingTime
  published
  reactions {
    count
    name
  }
  story {
    ${storyQuery({ isAdminLayout })}
  }
  storyId
  type
  updatedAt
`;
