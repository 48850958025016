import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { getCssVar } from '../../utils';
import { IconLabel } from '../IconLabel';

import classNames from 'classnames';
import classes from './CollapseSwitcher.module.scss';

export enum CollapseMode {
  STANDARD = 'STANDARD',
  COLLAPSED = 'COLLAPSED',
}

interface CollapseSwitcherProps {
  storageKey: string;
  defaultMode?: CollapseMode;
  className?: string;
}

export const CollapseSwitcher: FunctionComponent<CollapseSwitcherProps> = memo(
  ({ storageKey, defaultMode = CollapseMode.STANDARD, className }) => {
    const [mode, setMode] = useState(localStorage.getItem(storageKey) || defaultMode);

    const isStandardMode = useMemo(() => mode === CollapseMode.STANDARD, [mode]);

    const onChange = useCallback(
      (mode: CollapseMode) => {
        setMode(mode);
        localStorage.setItem(storageKey, mode);
        window.dispatchEvent(new Event('storage'));
      },
      [storageKey]
    );

    return (
      <div className={classNames(classes['switcher'], className)}>
        <IconLabel
          iconId={'collapse-mode-standard'}
          className={classNames(classes['switcher__mode'], {
            [classes['switcher__mode--active']]: isStandardMode,
          })}
          color={isStandardMode ? getCssVar('--collapse-switcher-active-color') : '#b3b3b3'}
          hoverColor={getCssVar('--collapse-switcher-active-color')}
          onClick={() => onChange(CollapseMode.STANDARD)}
        />
        <IconLabel
          iconId={'collapse-mode-collapsed'}
          className={classNames(classes['switcher__mode'], {
            [classes['switcher__mode--active']]: !isStandardMode,
          })}
          color={!isStandardMode ? getCssVar('--collapse-switcher-active-color') : '#b3b3b3'}
          hoverColor={getCssVar('--collapse-switcher-active-color')}
          onClick={() => onChange(CollapseMode.COLLAPSED)}
        />
      </div>
    );
  }
);
