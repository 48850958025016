import { forwardRef, memo, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { getCssVar } from '../../utils';
import { DropdownMenu } from '../DropdownMenu';
import { IconLabelSizes } from '../IconLabel';
import { Popup } from '../Popup';

import classNames from 'classnames';
import dropdownMenuClasses from '../DropdownMenu/DropdownMenu.module.scss';
import classes from './Button.module.scss';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
}

interface ButtonOptionItem {
  id: number;
  title: string;
}

interface ButtonProps {
  type: ButtonType;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  disabled?: boolean;
  nonClickable?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  danger?: boolean;
  optionsOnly?: boolean;
  options?: ButtonOptionItem[];
  optionsWidth?: string;
  onOptionClick?: (id: number) => void;
}

export const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        type,
        label,
        onClick,
        disabled = false,
        nonClickable = false,
        fullWidth = false,
        loading = false,
        danger = false,
        className,
        optionsOnly = false,
        options = [],
        optionsWidth,
        onOptionClick,
      },
      ref
    ) => {
      const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

      const hasOption = useMemo(() => Boolean(options.length === 1), [options.length]);

      const hasOptions = useMemo(
        () => Boolean((!optionsOnly && options.length) || options.length > 1),
        [options.length, optionsOnly]
      );

      const renderButton = useMemo(() => {
        const commonProps = {
          ref,
          disabled,
          className: classNames(classes['button'], classes[`button--${type}`], {
            [classes['button--full-width']]: fullWidth,
            [classes['button--loading']]: loading,
            [classes['button--danger']]: danger,
            [classes['button--has-options']]: hasOptions,
          }),
        };

        if (!optionsOnly) {
          return (
            <button {...commonProps} onClick={onClick}>
              {label}
            </button>
          );
        }

        if (hasOption) {
          const { id, title } = options[0];
          return (
            <button {...commonProps} onClick={() => onOptionClick?.(id)}>
              {title}
            </button>
          );
        }

        return null;
      }, [
        danger,
        disabled,
        fullWidth,
        hasOption,
        hasOptions,
        label,
        loading,
        onClick,
        onOptionClick,
        options,
        optionsOnly,
        ref,
        type,
      ]);

      const popupBody = useMemo(() => {
        const onClickHandler = (id: number) => {
          onOptionClick?.(id);
          setIsPopupOpen(false);
        };

        return (
          <DropdownMenu
            width={optionsWidth}
            content={
              <>
                {options?.map((option) => {
                  const { id, title } = option;

                  return (
                    <div
                      key={id}
                      className={dropdownMenuClasses['dropdown-menu__item--small']}
                      onClick={() => onClickHandler(id)}
                    >
                      {title}
                    </div>
                  );
                })}
              </>
            }
          />
        );
      }, [onOptionClick, options, optionsWidth]);

      return (
        <div
          className={classNames(className, classes['button-wrapper'], {
            [classes['button-wrapper--full-width']]: fullWidth,
            [classes['button-wrapper--non-clickable']]: nonClickable,
          })}
        >
          {renderButton}

          {hasOptions && (
            <Popup
              parentClassName={classNames(classes['options'], classes[`options--${type}`], {
                [classes[`options--${type}-disabled`]]: disabled,
                [classes['options--danger']]: danger,
                [classes[`options--only`]]: optionsOnly,
                [classes[`options--full-width`]]: optionsOnly && fullWidth,
              })}
              isOpen={isPopupOpen}
              setIsOpen={setIsPopupOpen}
              body={popupBody}
              iconId={`${isPopupOpen ? 'up' : 'down'}-arrow`}
              iconSize={isMobileOnly ? 21 : 24}
              bodyTop={'3rem'}
              bodyRight={'0.5rem'}
              color={getCssVar(`--btn-${type}${disabled ? '-disabled' : ''}-text-color`)}
              className={classes['options__icon-label']}
              labelClassName={classes['options__label']}
              {...(optionsOnly && { label, labelSize: IconLabelSizes.large })}
              singleColor
              labelFirst
            />
          )}
        </div>
      );
    }
  )
);
