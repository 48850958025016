/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  StoryCard,
  storyCardApi,
  StoryCardsChannelSearchArgs,
  StoryCardsChannelSearchFilters,
} from '../services';
import {
  addComment,
  deleteCard,
  deleteComment,
  editComment,
  hideComment,
  highlightComment,
  pinCard,
  reactOnCard,
  reactOnComment,
  repostCard,
  toggleCardSection,
  togglePublishOnCard,
} from './common';
import {
  addCommentReducer,
  deleteCommentReducer,
  editCommentReducer,
  hideCommentReducer,
  highlightCommentReducer,
  reactOnCardReducer,
  reactOnCommentReducer,
} from './extraReducers';

interface SearchFeedState {
  isFetching: boolean;
  searchFeed: StoryCard[];
  page: number;
  hasNextPage: boolean;
  totalItems: number;
  filters?: StoryCardsChannelSearchFilters;
}

const initialState: SearchFeedState = {
  isFetching: false,
  searchFeed: [],
  page: 0,
  hasNextPage: false,
  totalItems: 0,
  filters: undefined,
};

const searchFeedHanlder = async (args: StoryCardsChannelSearchArgs, { dispatch }: any) => {
  return dispatch(storyCardApi.endpoints.storyCardsChannelSearch.initiate({ ...args })).unwrap();
};

export const getSearchFeed = createAsyncThunk('searchFeed', searchFeedHanlder);

export const loadMoreSearchFeed = createAsyncThunk('searchFeed/loadMore', searchFeedHanlder);

const searchFeedSlice = createSlice({
  name: 'searchFeed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSearchFeed.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSearchFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage, totalItems } = pageInfo;

        state.isFetching = false;
        state.searchFeed = items;
        state.page = page;
        state.hasNextPage = hasNextPage;
        state.totalItems = totalItems;
        state.filters = action.meta.arg.filters;
      })
      .addCase(loadMoreSearchFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.searchFeed.push(...items);
        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        const { itemId, comment } = action.payload;

        const card = state.searchFeed.find(({ id }) => id === itemId);

        if (!card) {
          return;
        }

        addCommentReducer({ card, comment });
      })
      .addCase(editComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.searchFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        editCommentReducer({ card, comment });
      })
      .addCase(hideComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.searchFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        hideCommentReducer({ card, comment });
      })
      .addCase(highlightComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.searchFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        highlightCommentReducer({ card, comment });
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        const { deleted, cardId, commentId, parentId } = action.payload;

        if (!deleted) {
          return;
        }

        const card = state.searchFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        deleteCommentReducer({ card, commentId, parentId });
      })
      .addCase(reactOnComment.fulfilled, (state, action) => {
        const { reactions, cardId, commentId } = action.payload;

        const card = state.searchFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        reactOnCommentReducer({ card, commentId, reactions });
      })
      .addCase(reactOnCard.fulfilled, (state, action) => {
        const { cardId, reaction, reactions } = action.payload;

        const card = state.searchFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        reactOnCardReducer({ card, reaction, reactions });
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        const { deleted, storyCardId } = action.payload;

        if (!deleted) {
          return;
        }

        state.searchFeed = state.searchFeed.filter(({ id }) => id !== storyCardId);
      })
      .addCase(togglePublishOnCard.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!payload) {
          return;
        }

        const { id: storyCardId, status, postingTime } = payload;

        const card = state.searchFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        if (state.filters?.status?.length && !state.filters.status.includes(status)) {
          state.searchFeed = state.searchFeed.filter(({ id }) => id !== storyCardId);
          return;
        }

        card.status = status;
        card.postingTime = postingTime;
      })
      .addCase(repostCard.fulfilled, (state, action) => {
        const storyCard = action.payload;

        if (!storyCard) {
          return;
        }

        const cardIndex = state.searchFeed.findIndex(({ id }) => id === storyCard.id);

        if (cardIndex === -1) {
          return;
        }

        state.searchFeed[cardIndex] = {
          ...state.searchFeed[cardIndex],
          dateToDisplay: storyCard.dateToDisplay,
        };
      })
      .addCase(pinCard.fulfilled, (state, action) => {
        const { payload, storyCardId } = action.payload;

        if (!payload) {
          return;
        }

        const card = state.searchFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        const pinnedCard = state.searchFeed.find(
          ({ isPinnedToTopOfNewsFeed }) => isPinnedToTopOfNewsFeed
        );

        if (pinnedCard && pinnedCard.id !== storyCardId) {
          pinnedCard.isPinnedToTopOfNewsFeed = false;
        }

        card.isPinnedToTopOfNewsFeed = !card.isPinnedToTopOfNewsFeed;
      })
      .addCase(toggleCardSection.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!payload) {
          return;
        }

        const { id: storyCardId, section } = payload;

        const card = state.searchFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        card.section = section;
      });
  },
});

export default searchFeedSlice.reducer;
