import {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ConfigContext } from '../../../context';
import { Story, StoryCard, StoryCardCategory, StoryCardCategoryTemplate } from '../../../services';
import { getLocale } from '../../utils';
import { Button, ButtonType } from '../Button';
import { CategoryTemplateSelectionModal } from './CategoryTemplateSelectionModal';
import { CreateCardModal, ShortPostFields } from './CreateCardModal';

interface CreateCardProps {
  postInStories: Story[];
  categories: StoryCardCategory[];
  onMobilePanelClose?: () => void;
}

export const CreateCard: FunctionComponent<CreateCardProps> = memo(
  ({ postInStories, categories, onMobilePanelClose }) => {
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();

    const { showForReader } = useContext(ConfigContext).config.elements.addContent;

    const defaultCreateCardModalOpen = useMemo(
      () => searchParams.get('createCard') === 'true',
      [searchParams]
    );

    const defaultCategoryId = useMemo(
      () => parseInt(searchParams.get('categoryId') ?? '', 10),
      [searchParams]
    );

    const [isCreateCardModalOpen, setIsCreateCardModalOpen] = useState<boolean>(
      defaultCreateCardModalOpen && !defaultCategoryId
    );

    const [categoryId, setCategoryId] = useState<number | undefined>(
      defaultCreateCardModalOpen ? defaultCategoryId : undefined
    );

    const [shortPostFilledFields, setShortPostFilledFields] = useState<
      ShortPostFields | undefined
    >();

    const categoriesAsOption = useMemo(
      () =>
        categories
          .filter(({ settings: { showAsOption } }) => showAsOption)
          .map(({ id, settings: { buttonTextEn, buttonTextDe } }) => ({
            id,
            title: getLocale() === 'en-US' ? buttonTextEn : buttonTextDe,
          })),
      [categories]
    );

    const categoriesAsButton = useMemo(
      () =>
        categories
          .filter(({ settings: { showAsButton } }) => showAsButton)
          .map(({ id, settings: { buttonTextEn, buttonTextDe } }) => (
            <Button
              key={id}
              type={ButtonType.primary}
              label={getLocale() === 'en-US' ? buttonTextEn : buttonTextDe}
              onClick={() => setCategoryId(id)}
              fullWidth
            />
          )),
      [categories]
    );

    const category = useMemo(
      () => categories.find(({ id }) => id === categoryId),
      [categories, categoryId]
    );

    const templates = useMemo(() => category?.templates ?? [], [category?.templates]);

    const onTemplateSelectCallback = useCallback((template: StoryCardCategoryTemplate) => {
      const { name, text, gallery } = template;

      setShortPostFilledFields({ sourceName: '', headline: name, subHeadline: '', text, gallery });

      setIsCreateCardModalOpen(true);
    }, []);

    const onCreateCardModalClose = useCallback(() => {
      setIsCreateCardModalOpen(false);
      setShortPostFilledFields(undefined);
      setCategoryId(undefined);
    }, []);

    useEffect(() => {
      if (isCreateCardModalOpen) {
        onMobilePanelClose?.();
      }
    }, [isCreateCardModalOpen, onMobilePanelClose]);

    return (
      <>
        {showForReader && (
          <Button
            type={ButtonType.primary}
            label={t('addContent.label')}
            onClick={() => setIsCreateCardModalOpen(true)}
            optionsOnly={!Boolean(postInStories.length)}
            options={categoriesAsOption}
            optionsWidth={'15.8rem'}
            onOptionClick={setCategoryId}
            fullWidth
          />
        )}

        {categoriesAsButton}

        {Boolean(category) && (
          <CategoryTemplateSelectionModal
            isOpen={Boolean(category && !isCreateCardModalOpen)}
            onClose={() => setCategoryId(undefined)}
            templates={templates}
            onTemplateSelectCallback={onTemplateSelectCallback}
          />
        )}

        {isCreateCardModalOpen && (
          <CreateCardModal
            isOpen={isCreateCardModalOpen}
            onClose={onCreateCardModalClose}
            postInStories={postInStories}
            shortPostFilledFields={shortPostFilledFields}
            card={{ category } as StoryCard}
          />
        )}
      </>
    );
  }
);
