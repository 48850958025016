import { FunctionComponent, useContext, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../context';
import { useUserByIdQuery } from '../../../services';
import { PersonalInfo } from '../PersonalInfo';
import { PostStats } from '../PostStats';

import classNames from 'classnames';
import classes from './ProfileRightSide.module.scss';

export const ProfileRightSide: FunctionComponent = () => {
  const { userProfile } = useContext(UserContext);

  const { isAnonymous } = userProfile;

  const { id } = useParams();

  const isOther = useMemo(() => Boolean(id), [id]);

  const { data: profileOther, isFetching: isFetchingCollaboratorProfile } = useUserByIdQuery(
    { userId: Number(id) },
    { skip: !isOther }
  );

  if (isFetchingCollaboratorProfile) {
    return (
      <div className={classes['profile-right-side__loader']}>
        <Skeleton width={'100%'} height={'10rem'} />
      </div>
    );
  }

  if (isAnonymous || (isOther && !profileOther)) {
    return null;
  }

  return (
    <div
      className={classNames(classes['profile-right-side'], {
        [classes['profile-right-side--mobile']]: isMobileOnly,
      })}
    >
      <PersonalInfo user={profileOther ?? userProfile} />
      {!isOther && <PostStats />}
    </div>
  );
};
