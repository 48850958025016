import { useLocation } from 'react-router-dom';

export const useDeepLink = () => {
  const location = useLocation();

  return {
    isDeepLink: Boolean(new URLSearchParams(location.search).get('r')),
    isDraft: Boolean(new URLSearchParams(location.search).get('draft')),
  };
};
