import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { ConfigContext, UserContext } from '../../context';
import { AppTab, ChannelAppTabAction, useStoriesCardsUserFeedStatisticQuery } from '../../services';
import {
  DropdownMenu,
  Popup,
  getAppTabHrefByActionType,
  getCssVar,
  getLocale,
  useFreeLoginPopup,
} from '../../shared';

import classNames from 'classnames';
import dropdownMenuClasses from '../../shared/components/DropdownMenu/DropdownMenu.module.scss';
import classes from './Menu.module.scss';

interface MenuProps {
  appTabs: AppTab[];
  hasPostInStories: boolean;
  channelId: number;
  userId: number;
}

interface MenuItemClickArgs {
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>;
  type: ChannelAppTabAction;
}

export const Menu: FunctionComponent<MenuProps> = memo(
  ({ appTabs, hasPostInStories, channelId, userId }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { freeLoginModal, freeLoginHandler } = useFreeLoginPopup();

    const { isAnonymous } = useContext(UserContext).userProfile;

    const { appTabsSecondaryOrders, frontDoorLinks, userDirectory } =
      useContext(ConfigContext).config.elements;

    const {
      showAsPrimaryTab: userDirectoryShowAsPrimaryTab,
      localiseKey: userDirectoryLocaliseKey,
    } = userDirectory;

    const renderAppTabs = useMemo(() => Boolean(appTabs.length), [appTabs.length]);

    const timeStamp = useMemo(() => Date.now(), []);

    const {
      visible: frontDoorLinksVisible,
      items: frontDoorLinksItems,
      titleLocaliseKey: frontDoorLinksTitleLocaliseKey,
      tabIconUrl: frontDoorLinksTabIconUrl,
    } = frontDoorLinks;

    const renderAppTabsSecondary = useMemo(
      () => Boolean(appTabsSecondaryOrders.length),
      [appTabsSecondaryOrders.length]
    );

    const renderFrontDoorLinks = useMemo(
      () => Boolean(frontDoorLinksVisible && frontDoorLinksItems.length),
      [frontDoorLinksItems.length, frontDoorLinksVisible]
    );

    const { data: stats } = useStoriesCardsUserFeedStatisticQuery(
      { channelId, userId },
      { skip: !appTabsSecondaryOrders.includes('myContent') }
    );

    const renderMyContentTab = useMemo(
      () =>
        Boolean(
          (stats && Object.values(stats).find((value) => Boolean(value))) || hasPostInStories
        ),
      [hasPostInStories, stats]
    );

    const [secondaryMenuOpen, setSecondaryMenuOpen] = useState<boolean>(false);

    const myContentClick = useCallback(() => {
      setSecondaryMenuOpen(false);
      navigate('/profile');
    }, [navigate]);

    const userDirectoryClick = useCallback(() => {
      setSecondaryMenuOpen(false);
      navigate('/userDirectory');
    }, [navigate]);

    const getSecondaryTab = useCallback(
      (tab: string) => {
        switch (true) {
          case tab === 'myContent' && renderMyContentTab:
            return (
              <span
                key={tab}
                className={dropdownMenuClasses['dropdown-menu__item']}
                onClick={myContentClick}
              >
                {t('secondaryTabs.my-content')}
              </span>
            );
          case tab === 'userDirectory' && !userDirectoryShowAsPrimaryTab:
            return (
              <span
                key={tab}
                className={dropdownMenuClasses['dropdown-menu__item']}
                onClick={userDirectoryClick}
              >
                {t(userDirectoryLocaliseKey)}
              </span>
            );
          default:
            return null;
        }
      },
      [
        myContentClick,
        renderMyContentTab,
        t,
        userDirectoryClick,
        userDirectoryLocaliseKey,
        userDirectoryShowAsPrimaryTab,
      ]
    );

    const appTabsSecondaryContent = useMemo(
      () => appTabsSecondaryOrders.map((tab) => getSecondaryTab(tab)).filter((tab) => Boolean(tab)),
      [appTabsSecondaryOrders, getSecondaryTab]
    );

    const renderAppTabsSecondaryContent = useMemo(
      () => Boolean(appTabsSecondaryContent.length),
      [appTabsSecondaryContent.length]
    );

    const menuItemClick = useCallback(
      ({ event, type }: MenuItemClickArgs) => {
        if (isAnonymous && type === ChannelAppTabAction.CHATS) {
          event.preventDefault();
          freeLoginHandler();
        }
      },
      [freeLoginHandler, isAnonymous]
    );

    return (
      <>
        {(renderAppTabs || renderAppTabsSecondary) && (
          <ul className={classes['menu']}>
            {appTabs.map(({ action, title, iconUrl }) => {
              const { type, storyId } = action;

              const label = title.find(({ language }) => language === getLocale())?.localization;

              return (
                <li key={label}>
                  <NavLink
                    to={getAppTabHrefByActionType({ action: type, storyId })}
                    className={({ isActive }) =>
                      classNames(classes['menu__item'], {
                        [classes['menu__item--active']]: isActive,
                      })
                    }
                    onClick={(event) => menuItemClick({ event, type })}
                  >
                    {iconUrl && (
                      <div
                        className={classes['menu__item-icon']}
                        style={{
                          mask: `url('${iconUrl}?date=${timeStamp}') no-repeat center`,
                          WebkitMask: `url('${iconUrl}?date=${timeStamp}') no-repeat center`,
                          maskSize: 'cover',
                          WebkitMaskSize: 'cover',
                        }}
                      />
                    )}

                    <span className={classes['menu__item-label']}>{label}</span>
                  </NavLink>
                </li>
              );
            })}

            {renderFrontDoorLinks && (
              <li key={'frontDoorLinks'}>
                <NavLink
                  to={'/frontDoorLinksTab'}
                  className={({ isActive }) =>
                    classNames(classes['menu__item'], {
                      [classes['menu__item--active']]: isActive,
                    })
                  }
                >
                  {frontDoorLinksTabIconUrl && (
                    <div
                      className={classes['menu__item-icon']}
                      style={{
                        mask: `url('${frontDoorLinksTabIconUrl}') no-repeat center`,
                        WebkitMask: `url('${frontDoorLinksTabIconUrl}') no-repeat center`,
                        maskSize: 'cover',
                        WebkitMaskSize: 'cover',
                      }}
                    />
                  )}
                  {frontDoorLinksTitleLocaliseKey && (
                    <span className={classes['menu__item-label']}>
                      {t(frontDoorLinksTitleLocaliseKey)}
                    </span>
                  )}
                </NavLink>
              </li>
            )}

            {renderAppTabsSecondary && renderAppTabsSecondaryContent && (
              <Popup
                isOpen={secondaryMenuOpen}
                setIsOpen={setSecondaryMenuOpen}
                iconId={'more'}
                label={t('common.more')}
                labelClassName={classes['menu__item-label']}
                className={classes['menu__item']}
                color={getCssVar('--nav-menu-link-text-color')}
                hoverColor={getCssVar('--nav-menu-link-text-hover-color')}
                bodyTop={'3rem'}
                bodyLeft={'1rem'}
                body={<DropdownMenu width={'13rem'} content={<>{appTabsSecondaryContent}</>} />}
              />
            )}
          </ul>
        )}

        {freeLoginModal}
      </>
    );
  }
);
