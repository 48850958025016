import {
  ChangeEvent,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import {
  StoryCardArticleParsedUrl,
  StoryCardParseUrlPayload,
  StoryCardQuoteParsedUrl,
  StoryCardTeaserImageStyle,
  StoryCardType,
  storyCardApi,
} from '../../../../services';
import { GalleryItem } from '../../../models';
import { isMediaParsed, isUrl } from '../../../utils';
import { InputField } from '../../InputField';
import { LinkPostArticleFields, LinkPostFields, LinkPostQuoteFields } from '../CreateCardModal';
import { LinkPostArticle } from './LinkPostArticle';
import { LinkPostQuote } from './LinkPostQuote';

import classes from './LinkPost.module.scss';

interface LinkPostProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  linkPostFields: LinkPostFields;
  setLinkPostFields: Dispatch<SetStateAction<LinkPostFields>>;
  linkPostPrevUrl: string;
  setLinkPostPrevUrl: Dispatch<SetStateAction<string>>;
  activeLinkPostGalleryItemIndex: number;
  setActiveLinkPostGalleryItemIndex: (index: number) => void;
  settingsOpen: boolean;
  setSettingsOpen: (isOpen: boolean) => void;
  activeLinkPostSettingsMediaTab: boolean;
  setActiveLinkPostSettingsTabIndex: (index: number) => void;
  setLinkPostArticleGalleryItems: (galleryItems: GalleryItem[]) => void;
  setLinkPostQuoteGalleryItems: (galleryItems: GalleryItem[]) => void;
  postInStoryTeaserStyle: StoryCardTeaserImageStyle;
}

export const LinkPost: FunctionComponent<LinkPostProps> = memo(
  ({
    loading,
    setLoading,
    linkPostFields,
    setLinkPostFields,
    linkPostPrevUrl,
    setLinkPostPrevUrl,
    activeLinkPostGalleryItemIndex,
    setActiveLinkPostGalleryItemIndex,
    settingsOpen,
    setSettingsOpen,
    activeLinkPostSettingsMediaTab,
    setActiveLinkPostSettingsTabIndex,
    setLinkPostArticleGalleryItems,
    setLinkPostQuoteGalleryItems,
    postInStoryTeaserStyle,
  }) => {
    const { t } = useTranslation();

    const [invalidUrl, setInvalidUrl] = useState(false);

    const [parseUrl] = storyCardApi.endpoints.storyCardParseUrl.useLazyQuery();

    const { url, type, article, quote } = linkPostFields;

    const renderInput = useMemo(
      () =>
        Boolean(
          !loading && (!type || ![StoryCardType.ARTICLE, StoryCardType.QUOTE].includes(type))
        ),
      [loading, type]
    );

    const processParsedData = useCallback(
      async (url: string, parsedData: StoryCardParseUrlPayload) => {
        const { type } = parsedData;

        if (![StoryCardType.ARTICLE, StoryCardType.QUOTE].includes(type)) {
          setInvalidUrl(true);
          setLoading(false);
          return;
        }

        const parsedLinkPostFields: LinkPostFields = { url, type };

        switch (type) {
          case StoryCardType.ARTICLE:
            parsedLinkPostFields.article = {
              ...parsedData,
              headline: article?.headline ?? '',
              styles: article?.styles ?? { teaserImageStyle: postInStoryTeaserStyle },
            } as LinkPostArticleFields;
            break;
          case StoryCardType.QUOTE:
            parsedLinkPostFields.quote = {
              ...parsedData,
              headline: quote?.headline ?? '',
            } as LinkPostQuoteFields;
            break;
        }

        const { gallery } = parsedData as StoryCardArticleParsedUrl | StoryCardQuoteParsedUrl;
        const { externalId } = { ...gallery[0]?.image };

        try {
          await isMediaParsed(externalId);
        } catch (_) {
          toast.error(t('common.media-parse-error'));

          switch (type) {
            case StoryCardType.ARTICLE:
              parsedLinkPostFields.article = {
                ...parsedLinkPostFields.article,
                gallery: [],
              } as LinkPostArticleFields;
              break;
            case StoryCardType.QUOTE:
              parsedLinkPostFields.quote = {
                ...parsedLinkPostFields.quote,
                gallery: [],
              } as LinkPostQuoteFields;
              break;
          }
        }

        setLinkPostFields(parsedLinkPostFields);
        setLoading(false);
      },
      [
        article?.headline,
        article?.styles,
        postInStoryTeaserStyle,
        quote?.headline,
        setLinkPostFields,
        setLoading,
        t,
      ]
    );

    const linkCheck = useCallback(
      async (value: string) => {
        if (value && !isUrl(value)) {
          setInvalidUrl(true);
          return;
        }

        if (value) {
          setLoading(true);

          const parsedData = await parseUrl({ url: value }).unwrap();

          processParsedData(
            value,
            parsedData ?? {
              type: StoryCardType.ARTICLE,
              sourceName: '',
              title: '',
              abstract: '',
              contentAuthor: '',
              gallery: [],
            }
          );
        }
      },
      [parseUrl, processParsedData, setLoading]
    );

    useEffect(() => {
      if (!url) {
        setInvalidUrl(false);
      }

      if (url && url !== linkPostPrevUrl) {
        linkCheck(url);
        setLinkPostPrevUrl(url);
      }
    }, [linkCheck, linkPostPrevUrl, setLinkPostPrevUrl, url]);

    const inputChangeHandler = useCallback(
      ({ target }: ChangeEvent<HTMLInputElement>) => {
        setLinkPostFields({ ...linkPostFields, url: target.value });
      },
      [linkPostFields, setLinkPostFields]
    );

    const setArticle = useCallback(
      (article: LinkPostArticleFields) => {
        setLinkPostFields({ ...linkPostFields, article });
      },
      [linkPostFields, setLinkPostFields]
    );

    const setQuote = useCallback(
      (quote: LinkPostQuoteFields) => {
        setLinkPostFields({ ...linkPostFields, quote });
      },
      [linkPostFields, setLinkPostFields]
    );

    const renderParsedData = useMemo(() => {
      switch (type) {
        case StoryCardType.ARTICLE:
          return (
            <LinkPostArticle
              loading={loading}
              setLoading={setLoading}
              url={url}
              articleData={article as LinkPostArticleFields}
              setArticle={setArticle}
              setLinkPostArticleGalleryItems={setLinkPostArticleGalleryItems}
              activeLinkPostGalleryItemIndex={activeLinkPostGalleryItemIndex}
              setActiveLinkPostGalleryItemIndex={setActiveLinkPostGalleryItemIndex}
              activeLinkPostSettingsMediaTab={activeLinkPostSettingsMediaTab}
              setActiveLinkPostSettingsTabIndex={setActiveLinkPostSettingsTabIndex}
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpen}
            />
          );
        case StoryCardType.QUOTE:
          return (
            <LinkPostQuote
              loading={loading}
              setLoading={setLoading}
              url={url}
              quoteData={quote as LinkPostQuoteFields}
              setQuote={setQuote}
              setLinkPostQuoteGalleryItems={setLinkPostQuoteGalleryItems}
              activeLinkPostGalleryItemIndex={activeLinkPostGalleryItemIndex}
              setActiveLinkPostGalleryItemIndex={setActiveLinkPostGalleryItemIndex}
              activeLinkPostSettingsMediaTab={activeLinkPostSettingsMediaTab}
              setActiveLinkPostSettingsTabIndex={setActiveLinkPostSettingsTabIndex}
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpen}
            />
          );
        default:
          return null;
      }
    }, [
      activeLinkPostGalleryItemIndex,
      activeLinkPostSettingsMediaTab,
      article,
      loading,
      quote,
      setActiveLinkPostGalleryItemIndex,
      setActiveLinkPostSettingsTabIndex,
      setArticle,
      setLinkPostArticleGalleryItems,
      setLinkPostQuoteGalleryItems,
      setLoading,
      setQuote,
      setSettingsOpen,
      settingsOpen,
      type,
      url,
    ]);

    const renderLoader = useMemo(() => Boolean(loading && !type), [loading, type]);

    return (
      <div className={classes['link-post']}>
        {renderInput && (
          <div className={classes['link-post__input']}>
            <InputField
              value={url}
              placeholder={t('addContent.link-post-url-placeholder')}
              onChange={inputChangeHandler}
              error={invalidUrl}
              errorMessage={t('common.url-invalid')}
              disabled={loading}
              borderless
            />
          </div>
        )}
        {renderLoader && (
          <div className={classes['link-post__loader']}>
            <Skeleton height={'3rem'} />
          </div>
        )}
        {renderParsedData}
      </div>
    );
  }
);
