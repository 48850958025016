import { FullPostFields, LinkPostFields, PostType, ThreadPostFields } from '../CreateCardModal';

interface GetPostTypeValueDefaultArgs {
  fullPostFilledFields?: FullPostFields;
  linkPostFilledFields?: LinkPostFields;
  threadPostFilledFields?: ThreadPostFields;
}

export const getPostTypeValueDefault = ({
  fullPostFilledFields,
  linkPostFilledFields,
  threadPostFilledFields,
}: GetPostTypeValueDefaultArgs) => {
  switch (true) {
    case Boolean(fullPostFilledFields):
      return PostType.FULL;
    case Boolean(linkPostFilledFields):
      return PostType.LINK;
    case Boolean(threadPostFilledFields):
      return PostType.THREAD;
    default:
      return PostType.SHORT;
  }
};
