import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';
import { useGetStoriesByChannelIdQuery } from '../../../services';
import { FilterValue, SelectItem } from '../../components';
import { useFilter } from './useFilter';

interface UseCardStoryFilterArgs {
  filter: FilterValue[];
}

export const useCardStoryFilter = ({ filter }: UseCardStoryFilterArgs) => {
  const { t } = useTranslation();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { data: stories, isFetching } = useGetStoriesByChannelIdQuery({ channelId });

  const { getGroupValue } = useFilter();

  const options = useMemo(() => {
    return (
      stories?.map(({ id, title }) => {
        return { id, title } as SelectItem;
      }) ?? []
    );
  }, [stories]);

  const groupId = 'story';

  const dropdown = useMemo(() => {
    return {
      disabled: isFetching,
      options,
      staticListPosition: false,
      search: true,
      multiCheck: true,
    };
  }, [isFetching, options]);

  return {
    group: {
      id: groupId,
      title: t('storiesFilter.title'),
      value: getGroupValue({ filter, groupId, multiCheck: true, propToMap: 'id' }) ?? [],
      dropdown,
    },
  };
};
