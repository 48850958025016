import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DetailsModalContext, UserContext } from '../../../../context';
import { StoryCardType, User } from '../../../../services';
import { FeedCardMargin, ImageFile, UserMentionPreview } from '../../../../shared';
import { useAnalytics } from '../../../hooks';
import { getCssVar, layoutPath } from '../../../utils';
import { Copyright } from '../../Copyright';
import { IconLabel } from '../../IconLabel';
import { LineClamp, LineClampProps } from '../../LineClamp';

import classNames from 'classnames';
import classes from './FeedCardContentCommon.module.scss';

export interface FeedCardContentProps {
  type: StoryCardType;
  pinned: boolean;
  storyId?: number;
  cardId?: number;
  chatId?: number;
  source?: string;
  title?: string;
  media?: JSX.Element;
  subTitle?: string;
  text?: string;
  htmlText?: string;
  teaser?: ImageFile;
  mentionedUsers?: User[];
}

export const FeedCardContentCommon: FunctionComponent<FeedCardContentProps> = memo(
  ({
    storyId,
    cardId,
    chatId,
    type,
    pinned,
    source,
    title,
    media,
    subTitle,
    text,
    htmlText,
    teaser,
    mentionedUsers = [],
  }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { logClickOpenArticle } = useAnalytics();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const { setDetailsModalData } = useContext(DetailsModalContext);

    const { url, rightholder } = { ...teaser };

    const html = htmlText?.replace(/href/g, "target='_blank' href") ?? '';

    const useLineClamp = useMemo(
      () => ![StoryCardType.QUOTE, StoryCardType.ARTICLE, StoryCardType.POST].includes(type),
      [type]
    );

    const doLinkify = useMemo(
      () => ![StoryCardType.QUOTE, StoryCardType.ARTICLE, StoryCardType.POST].includes(type),
      [type]
    );

    const isEditorial = useMemo(() => Boolean(type === StoryCardType.EDITORIAL), [type]);

    const useCustomTextSize = useMemo(
      () => isEditorial && !source && !title && !subTitle,
      [isEditorial, source, subTitle, title]
    );

    const useMediumText = useMemo(
      () => useCustomTextSize && (text || html).length <= 250,
      [html, text, useCustomTextSize]
    );

    const useLargeText = useMemo(
      () => useCustomTextSize && (text || html).length <= 100,
      [html, text, useCustomTextSize]
    );

    const clickCallBack = useCallback(() => {
      if (!storyId || !cardId) {
        return;
      }

      isMobileOnly
        ? navigate(layoutPath(`/details/${storyId}/${cardId}`))
        : setDetailsModalData({ storyId, cardId, chatId });
    }, [cardId, chatId, navigate, setDetailsModalData, storyId]);

    const lineClampCommonProps = useMemo(() => {
      return {
        maxLines: 4,
        allowExpand: true,
        readMore: t('common.read-more'),
        contentClassName: classNames(classes['card-content__fields-text'], {
          [classes['card-content__fields-text--medium']]: useMediumText,
          [classes['card-content__fields-text--large']]: useLargeText,
        }),
        readMoreClassName: classes['card-content__read-more'],
        onClick: clickCallBack,
        onTextReveal: () =>
          logClickOpenArticle({
            channel_id: channelId,
            story_id: Number(storyId),
            item_id: Number(cardId),
            time_stamp: new Date().toISOString(),
          }),
      } as LineClampProps;
    }, [
      cardId,
      channelId,
      clickCallBack,
      logClickOpenArticle,
      storyId,
      t,
      useLargeText,
      useMediumText,
    ]);

    const renderText = useMemo(() => {
      if (subTitle || html || !text) {
        return null;
      }

      if (useLineClamp) {
        return <LineClamp {...lineClampCommonProps} stringContent={text} />;
      }
      return <div className={classes['card-content__fields-text']}>{text}</div>;
    }, [html, lineClampCommonProps, subTitle, text, useLineClamp]);

    const renderHtml = useMemo(() => {
      if (subTitle || !html) {
        return null;
      }

      if (useLineClamp) {
        return (
          <LineClamp {...lineClampCommonProps} doLinkify={doLinkify} htmlStringContent={html} />
        );
      }
      return (
        <div
          className={classes['card-content__fields-text']}
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      );
    }, [doLinkify, html, lineClampCommonProps, subTitle, useLineClamp]);

    const renderSource = useMemo(() => {
      if (!source && !pinned) {
        return null;
      }

      return (
        <div className={classes['card-content__fields-source-wrapper']}>
          {pinned && (
            <IconLabel
              iconId={'pin'}
              {...(source && { label: '•' })}
              color={getCssVar('--base-link-text-color')}
              singleColor
              nonClickable
            />
          )}
          {source && <div className={classes['card-content__fields-source']}>{source}</div>}
        </div>
      );
    }, [pinned, source]);

    const renderTitleIcon = useMemo(() => {
      if (!type || ![StoryCardType.ARTICLE].includes(type)) {
        return null;
      }

      return (
        <span className={classes['card-content__fields-title-icon']}>
          <IconLabel
            iconId={'arrow-rounded-right-up'}
            iconSize={chatId ? 18 : 24}
            color={getCssVar('--card-content-headline-icon-color')}
            singleColor
          />
        </span>
      );
    }, [chatId, type]);

    const renderTitle = useMemo(() => {
      if (!title) {
        return null;
      }

      return (
        <div className={classes['card-content__fields-title']}>
          {title} {renderTitleIcon}
        </div>
      );
    }, [renderTitleIcon, title]);

    const renderMedia = useMemo(() => {
      if (!media) {
        return null;
      }

      return <div className={classes['card-content__fields-media']}>{media}</div>;
    }, [media]);

    const subTitleReadMore = useMemo(
      () => Boolean(subTitle && (text || html)),
      [html, subTitle, text]
    );

    const renderSubTitle = useMemo(() => {
      if (!subTitle) {
        return null;
      }

      return (
        <div
          className={classNames(classes['card-content__fields-subTitle'], {
            [classes['card-content__fields-subTitle--clickable']]: subTitleReadMore,
          })}
          onClick={() => subTitleReadMore && clickCallBack()}
        >
          {subTitle}
        </div>
      );
    }, [clickCallBack, subTitle, subTitleReadMore]);

    const readMore = useMemo(() => {
      return (
        <span className={classes['card-content__read-more']} onClick={clickCallBack}>
          {t('common.read-more')}
        </span>
      );
    }, [clickCallBack, t]);

    const renderSubTitleReadMore = useMemo(() => {
      if (!subTitleReadMore) {
        return null;
      }

      return readMore;
    }, [readMore, subTitleReadMore]);

    const renderTeaser = useMemo(() => {
      if (!teaser) {
        return null;
      }

      return (
        <div className={classes['card-content__teaser']} style={{ backgroundImage: `url(${url})` }}>
          {rightholder && <Copyright copyright={rightholder} />}
        </div>
      );
    }, [rightholder, teaser, url]);

    const useTopMargin = useMemo(
      () => Boolean(renderSource || renderTitle || isEditorial),
      [isEditorial, renderSource, renderTitle]
    );

    if (![source, title, media, subTitle, text, htmlText, pinned].some((item) => Boolean(item))) {
      return null;
    }

    return (
      <FeedCardMargin {...(useTopMargin && { top: true })} right left>
        <div
          className={classNames(classes['card-content'], {
            [classes['card-content--chat']]: Boolean(chatId),
          })}
        >
          <div
            className={classNames(classes['card-content__fields'], {
              [classes['card-content__fields--with-side-teaser']]: teaser,
            })}
          >
            {renderSource}

            {renderTitle}

            <UserMentionPreview mentionedUsers={mentionedUsers} openProfile />

            {renderMedia}

            {renderSubTitle}

            {renderSubTitleReadMore}

            {renderText}

            {renderHtml}

            {type === StoryCardType.POST && readMore}
          </div>

          {renderTeaser}
        </div>
      </FeedCardMargin>
    );
  }
);
