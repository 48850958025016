import {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { UserContext } from '../../../../context';
import { Story, useGetStoriesByChannelIdQuery } from '../../../../services';
import { useDebounce } from '../../../hooks';
import { isUrl } from '../../../utils';
import { Button, ButtonType } from '../../Button';
import { InputField } from '../../InputField';
import { CreateCardModal, LinkPostFields, ShortPostFields } from '../CreateCardModal';

import classes from './CreateCardWithInput.module.scss';

interface CreateCardWithInputProps {
  story?: Story;
  label?: string;
  adminActions?: JSX.Element;
}

interface PostFilledFields {
  shortPostFilledFields?: ShortPostFields;
  linkPostFilledFields?: LinkPostFields;
}

interface PostFilesToUpload {
  files?: FileList;
  fileUrl?: string;
}

export const CreateCardWithInput: FunctionComponent<CreateCardWithInputProps> = memo(
  ({ story, label, adminActions }) => {
    const { t } = useTranslation();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const { data: postInStories, isFetching } = useGetStoriesByChannelIdQuery({
      channelId,
      filter: { onlyPostIn: true },
    });

    const [isOpen, setIsOpen] = useState(false);

    const [input, setInput] = useState('');

    const [postFilledFields, setPostFilledFields] = useState<PostFilledFields>({
      linkPostFilledFields: undefined,
      shortPostFilledFields: undefined,
    });

    const [postFilesToUpload, setPostFilesToUpload] = useState<PostFilesToUpload>({
      files: undefined,
      fileUrl: undefined,
    });

    const debouncedValue = useDebounce<string>(input);

    useEffect(() => {
      if (debouncedValue) {
        setPostFilledFields(
          isUrl(debouncedValue)
            ? { linkPostFilledFields: { url: debouncedValue } }
            : {
                shortPostFilledFields: {
                  sourceName: '',
                  headline: '',
                  subHeadline: '',
                  text: debouncedValue,
                  gallery: [],
                },
              }
        );

        setInput('');
        setIsOpen(true);
      }
    }, [debouncedValue]);

    const modalClose = useCallback(() => {
      setIsOpen(false);

      setPostFilledFields({ linkPostFilledFields: undefined, shortPostFilledFields: undefined });

      setPostFilesToUpload({ files: undefined, fileUrl: undefined });
    }, []);

    const pasteHandler = useCallback(async (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();

      const { clipboardData } = event;

      const { files } = clipboardData;

      const clipboardText = clipboardData.getData('Text');

      //image jpg|jpeg|png|webp|avif|gif
      //auido mp3|wav|aiff
      //video mp4|webm|mov

      const isMedia = /\.(jpg|jpeg|png|webp|avif|gif|mp3|wav|aiff|mp4|webm|mov|pdf)$/.test(
        clipboardText
      );

      switch (true) {
        case Boolean(files.length): {
          setPostFilesToUpload({ files });
          break;
        }
        case isMedia: {
          setPostFilesToUpload({ fileUrl: clipboardText });
          break;
        }
        case !isMedia: {
          setPostFilledFields(
            isUrl(clipboardText)
              ? { linkPostFilledFields: { url: clipboardText } }
              : {
                  shortPostFilledFields: {
                    sourceName: '',
                    headline: '',
                    subHeadline: '',
                    text: clipboardText,
                    gallery: [],
                  },
                }
          );
          break;
        }
      }

      setIsOpen(true);
    }, []);

    const postInStoriesLatest = useMemo(
      () =>
        postInStories?.map((postInStory) => (postInStory.id === story?.id ? story : postInStory)),
      [postInStories, story]
    );

    if (isFetching) {
      return <Skeleton height={'4rem'} />;
    }

    if (!postInStoriesLatest?.length) {
      return null;
    }

    return (
      <div className={classes['create-card']}>
        <InputField
          value={input}
          onChange={({ target }) => setInput(target.value)}
          inputClassName={classes['create-card__input']}
          placeholder={t(`addContent.input-placeholder${isMobileOnly ? '-mobile' : ''}`)}
          onPaste={pasteHandler}
          borderless
        />

        <Button
          className={classes['create-card__button']}
          type={ButtonType.primary}
          label={label ?? t('addContent.label')}
          onClick={() => setIsOpen(true)}
          fullWidth
        />

        {adminActions}

        {isOpen && (
          <CreateCardModal
            isOpen={isOpen}
            onClose={modalClose}
            postInStories={postInStoriesLatest}
            {...postFilledFields}
            {...postFilesToUpload}
          />
        )}
      </div>
    );
  }
);
