import { ChartType } from '../pages';
import { rootApi } from './rootApi';

export interface ConfigArgs {
  organisationDomain: string;
}

export interface ConfigInput {
  backgroundColor: string;
  backgroundHoverColor: string;
  backgroundDisabledColor: string;
  backgroundErrorColor: string;
  textColor: string;
  textHoverColor: string;
  textDisabledColor: string;
  textErrorColor: string;
  placeholderColor: string;
  borderColor: string;
  borderFocusColor: string;
  borderDisabledColor: string;
  borderErrorColor: string;
  labelColor: string;
  iconColor: string;
  iconErrorColor: string;
  errorMessageColor: string;
}

interface ConfigCardDetail {
  channelSubdomain: string;
  storyId: number;
  cardId: number;
}

export interface ConfigLink {
  title: string;
  localiseKey?: string;
  iconUrl?: string;
  iconSize?: number;
  url?: string;
  cardDetail?: ConfigCardDetail;
  mailto?: string;
  promo?: boolean;
  color?: string;
  hoverColor?: string;
}

export interface DropdownMenu {
  iconColor: string;
  textColor: string;
  backgroundColor: string;
  itemBackgroundHoverColor: string;
}

export interface ConfigSelect {
  iconColor: string;
  backgroundColor: string;
  backgroundHoverColor: string;
  labelColor: string;
  titleColor: string;
  borderColor: string;
}

export interface ConfigCardContent {
  sourceColor: string;
  headlineColor: string;
  headlineIconColor: string;
  subHeadlineColor: string;
  textColor: string;
}

export interface ConfigThreadCard {
  backgroundColor: string;
  discussionColor: string;
  titleColor: string;
  localiseKey?: string;
}

export interface ConfigSocialCard {
  borderColor: string;
  iconColor: string;
  textColor: string;
}

export interface ConfigCardTeaser {
  iconColor: string;
  backgroundColor: string;
  textColor: string;
}

export interface ConfigCard {
  content: ConfigCardContent;
  backgroundColor: string;
  borderColor: string;
  postedTimeColor: string;
  overlayColor: string;
  overlayIconColor: string;
  commentColor: string;
  byAuthorColor: string;
  thread: ConfigThreadCard;
  social: ConfigSocialCard;
  teaser: ConfigCardTeaser;
  relativeTime: boolean;
  individualReaction: boolean;
}

export interface ConfigChannelLogo {
  channelId: number;
  logoUrl: string;
}

const profileFields = [
  'email',
  'url',
  'phone',
  'screenName',
  'location',
  'bio',
  'department',
  'position',
  'gender',
  'subscription',
  'url',
  'organisationTags',
  'role',
] as const;

export type ConfigProfileFields = {
  [key in typeof profileFields[number]]: {
    visible: boolean;
    editable?: boolean;
    localiseKey?: string;
  };
};

export interface ConfigHeader {
  backgroundColor: string;
  borderBottomColor: string;
}

export interface ConfigSearchInput extends ConfigInput {
  closeColor: string;
}

export interface ConfigSettings {
  iconColor: string;
  iconHoverColor: string;
  links: ConfigLink[];
}

export interface ConfigFooter {
  backgroundColor: string;
  dividerColor: string;
  copyText: string;
  copyTextColor: string;
  links: ConfigLink[];
}

export interface ConfigNavMenuLink {
  textColor: string;
  textHoverColor: string;
  selectedTextColor: string;
  selectedBackgroundColor: string;
}

export interface ConfigNavMenu {
  backgroundColor: string;
  link: ConfigNavMenuLink;
}

export interface ConfigProfileAvatar {
  size: number;
  borderColor: string;
  borderHoverColor: string;
  backgroundColor: string;
}

interface ConfigLatestsStoryCardsSetupItem {
  channelId: number;
  storyIds: number[];
}

export interface ConfigLatestsStoryCards {
  setup: ConfigLatestsStoryCardsSetupItem[];
  visible: boolean;
  cardsLimit: number;
  textColor: string;
  backgroundColor: string;
  backgroundHoverColor: string;
}

export interface ConfigExternalTagsList {
  visible: boolean;
  backgroundColor: string;
}

export interface ConfigNoContent {
  borderColor: string;
  backgroundColor: string;
  faceBackgroundColor: string;
  faceColor: string;
  titleColor: string;
  subTitleColor: string;
}

export interface ConfigErrorLayoutPage {
  outerBorderColor: string;
  innerBorderColor: string;
  headerColor: string;
  outerBackgroundColor: string;
  innerBackgroundColor: string;
  faceColor: string;
  textColor: string;
  overlayColor: string;
  circleColor: string;
}

export interface ConfigErrorLayout {
  borderColor: string;
  backgroundColor: string;
  outerBackgroundColor: string;
  titleColor: string;
  subTitleColor: string;
  messageColor: string;
  page: ConfigErrorLayoutPage;
}

interface ConfigMobileLayout {
  iosLink: string;
  androidLink: string;
}

export interface ConfigModal {
  titleColor: string;
  overlayBackgroundColor: string;
  backgroundColor: string;
  closeColor: string;
  previewCloseColor: string;
}

export interface ConfigStoryTab {
  headlineColor: string;
  titleColor: string;
  titleHoverColor: string;
  subTitleColor: string;
  subTitleHoverColor: string;
  iconColor: string;
  borderColor: string;
  backgroundColor: string;
}

export interface ConfigStoryFeed {
  backColor: string;
  titleColor: string;
  subTitleColor: string;
  overlayColor: string;
  overlayTitleColor: string;
  overlaySubTitleColor: string;
}

export interface ConfigHorizontalFeed {
  titleColor: string;
  cardsPerView: number;
  spaceBetweenCards: number;
  overlayBackgroundColor: string;
  placeholderBackgroundColor: string;
  placeholderColor: string;
}

export interface ConfigButton {
  borderColor: string;
  borderHoverColor: string;
  borderDisabledColor: string;
  textColor: string;
  textHoverColor: string;
  textDisabledColor: string;
  backgroundColor: string;
  backgroundHoverColor: string;
  backgroundDisabledColor: string;
}

export interface ConfigProfileUpload {
  overlayColor: string;
  labelColor: string;
  labelHoverColor: string;
  iconColor: string;
}

export interface ConfigProfileFeedCreateCard {
  iconColor: string;
  iconBackgroundColor: string;
  labelColor: string;
  labelHoverColor: string;
  backgroundColor: string;
}

export interface ConfigProfileFeed {
  enabled: boolean;
  titleColor: string;
  createCard: ConfigProfileFeedCreateCard;
}

export interface ConfigProfile {
  headBackgroundColor: string;
  borderColor: string;
  nameColor: string;
  bioColor: string;
  infoLabelColor: string;
  infoValueColor: string;
  infoBackgroundColor: string;
  menuIconColor: string;
  menuIconHoverColor: string;
  backColor: string;
  backHoverColor: string;
  avatar: ConfigProfileAvatar;
  socialFieldsColor: string;
  socialFields: string[];
  fields: ConfigProfileFields;
  upload: ConfigProfileUpload;
  showDeepLink: boolean;
  showDeactiveAccount: boolean;
  showRole: boolean;
  feed: ConfigProfileFeed;
}

export interface ConfigDetailsBlockHeader {
  h1Color: string;
  h2Color: string;
  h3Color: string;
  h4Color: string;
  h5Color: string;
  h6Color: string;
}

export interface ConfigDetailsBlockParagraph {
  color: string;
}

export interface ConfigDetailsBlockAudio {
  nameColor: string;
  captionColor: string;
}

export interface ConfigDetailsBlockVideo {
  captionColor: string;
}

export interface ConfigDetailsBlockCallout {
  textColor: string;
  backgroundColor: string;
}

export interface ConfigDetailsBlockCheckList {
  textColor: string;
  borderColor: string;
  backgroundColor: string;
  iconColor: string;
}

export interface ConfigDetailsBlockDelimiter {
  url: string;
  color: string;
}

export interface ConfigDetailsBlockEmbedLink {
  borderColor: string;
  borderHoverColor: string;
  sourceColor: string;
  titleColor: string;
  textColor: string;
  iconColor: string;
  urlColor: string;
}

export interface ConfigDetailsBlockCommon {
  markBackground: string;
  codeBackground: string;
}

export interface ConfigDetailsBlockList {
  textColor: string;
}

export interface ConfigDetailsBlockQuote {
  iconColor: string;
  textColor: string;
  captionColor: string;
}

export interface ConfigDetailsBlockTable {
  headerTextColor: string;
  headerBackgroundColor: string;
}

export interface ConfigDetailsBlock {
  common: ConfigDetailsBlockCommon;
  header: ConfigDetailsBlockHeader;
  paragraph: ConfigDetailsBlockParagraph;
  audio: ConfigDetailsBlockAudio;
  video: ConfigDetailsBlockVideo;
  callout: ConfigDetailsBlockCallout;
  checkList: ConfigDetailsBlockCheckList;
  delimiter: ConfigDetailsBlockDelimiter;
  embedLink: ConfigDetailsBlockEmbedLink;
  list: ConfigDetailsBlockList;
  quote: ConfigDetailsBlockQuote;
  table: ConfigDetailsBlockTable;
}

export interface ConfigSearch {
  titleColor: string;
  countColor: string;
  queryColor: string;
  textColor: string;
}

export interface ConfigMediaAudio {
  iconColor: string;
  timeColor: string;
  backgroundColor: string;
  indicatorColor: string;
  progressColor: string;
  captionColor: string;
}

export interface ConfigMediaVideo {
  captionColor: string;
}

export interface ConfigMediaFile {
  typeColor: string;
  nameColor: string;
  iconColor: string;
  backgroundColor: string;
  captionColor: string;
}

export interface ConfigMedia {
  audio: ConfigMediaAudio;
  video: ConfigMediaVideo;
  file: ConfigMediaFile;
}

export interface ConfigAuthorInfo {
  titleColor: string;
  subTitleColor: string;
}

export interface ConfigCommentsItem {
  like: {
    color: string;
    filledColor: string;
    hoverColor: string;
    counterColor: string;
  };
  hide: {
    color: string;
    filledColor: string;
    hoverColor: string;
  };
  highlight: {
    color: string;
    filledColor: string;
    hoverColor: string;
  };
  authorColor: string;
  publishedColor: string;
  editedColor: string;
  textColor: string;
  confirmationTextColor: string;
}

export interface ConfigCommentsInput {
  iconColor: string;
  iconHoverColor: string;
  textColor: string;
  placeholderColor: string;
  backgroundColor: string;
  borderFocusColor: string;
}

export interface ConfigComments {
  item: ConfigCommentsItem;
  input: ConfigCommentsInput;
}

export interface ConfigForm {
  backgroundColor: string;
  outerBackgroundColor: string;
  borderColor: string;
  errorColor: string;
  titleColor: string;
  subTitleColor: string;
}

export interface ConfigLogin {
  title: string;
  intro: string;
  logoUrl: string;
  forgotUrl: string;
  SSOLabel: string;
  signUpTextColor: string;
  dividerTextColor: string;
  dividerBorderColor: string;
  isRegular: boolean;
  social: {
    google: boolean;
    facebook: boolean;
    apple: boolean;
  };
  saml: {
    label: string;
    enabled: boolean;
  };
  email: {
    enabled: boolean;
    localiseKey: string;
  };
}

export interface ConfigSignup {
  textColor: string;
}

export interface ConfigMeta {
  title: string;
  faviconUrl: string;
}

export interface ConfigActionPane {
  iconColor: string;
  iconHoverColor: string;
}

export interface ConfigButtons {
  primary: ConfigButton;
  secondary: ConfigButton;
}

export interface ConfigBaseLink {
  textColor: string;
  textHoverColor: string;
}

interface ConfigFontFaceUrl {
  url: string;
  format: string;
}

export interface ConfigFontFace {
  src: ConfigFontFaceUrl[];
  style: string;
  family: string;
  weight: string;
}

export interface ConfigNotifications {
  iconColor: string;
  iconHoverColor: string;
  iconIndicatorColor: string;
  defaultLogoUrl: string;
  backgroundColor: string;
  backgroundHoverColor: string;
  borderColor: string;
  titleColor: string;
  commentLineColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
}

export interface ConfigTabs {
  labelColor: string;
  activeColor: string;
  backgroundColor: string;
  borderColor: string;
}

export interface ConfigCreateCardThread {
  backgroundColor: string;
  iconColor: string;
  titleColor: string;
  textColor: string;
  inputColor: string;
}

export interface ConfigCreateCardTeaser {
  titleColor: string;
  itemTextColor: string;
  itemActiveColor: string;
  backgroundActiveColor: string;
}

export interface ConfigCreateCardSettings {
  iconColor: string;
  iconHoverColor: string;
  titleColor: string;
  itemTitleColor: string;
  itemTextColor: string;
  itemActiveColor: string;
}

export interface ConfigCreateCard {
  titleColor: string;
  postInColor: string;
  captionTitleColor: string;
  captionInputColor: string;
  linkColor: string;
  linkHoverColor: string;
  filePreviewBackgroundColor: string;
  settings: ConfigCreateCardSettings;
  teaser: ConfigCreateCardTeaser;
  thread: ConfigCreateCardThread;
  showOnPinnedStoryFeed: boolean;
  addContentLocaliseKey: string;
  showForReader: boolean;
  excludeLongPost: boolean;
  excludeThreadPost: boolean;
}

export interface ConfigCircularLoader {
  backgroundColor: string;
  fillColor: string;
}

export interface ConfigSpecialTeaserSetupItem {
  channelId?: number;
  storyId: number;
}

export interface ConfigSpecialTeaser {
  setup: ConfigSpecialTeaserSetupItem[];
  backgroundColor: string;
  sourceColor: string;
  titleColor: string;
  timeColor: string;
}

export interface ConfigFilter {
  titleColor: string;
  itemColor: string;
  activeColor: string;
  activeIconColor: string;
  searchIconColor: string;
  badgeBackgroundColor: string;
  badgeTextColor: string;
}

const userDirectoryFilters = [
  'name',
  'gender',
  'position',
  'department',
  'subscription',
  'organisationTags',
  'role',
  'location',
] as const;

export type ConfigUserDirectoryFilters = {
  [key in typeof userDirectoryFilters[number]]: {
    visible: boolean;
  };
};

export interface ConfigUserDirectory {
  localiseKey: string;
  showAsPrimaryTab: boolean;
  primaryTabIconUrl: string;
  titleColor: string;
  emptyMessageColor: string;
  nameColor: string;
  positionColor: string;
  itemColor: string;
  itemHoverColor: string;
  linkColor: string;
  linkHoverColor: string;
  filters: ConfigUserDirectoryFilters;
}

interface ConfigFrontDoorLinksItem {
  titleLocaliseKey: string;
  subTitleLocaliseKey: string;
  imageSrc: string;
  linkUrl: string;
}

export interface ConfigFrontDoorLinks {
  visible: boolean;
  tabIconUrl: string;
  titleLocaliseKey: string;
  titleColor: string;
  itemTitleColor: string;
  itemTitleHoverColor: string;
  itemSubTitleColor: string;
  itemSubTitleHoverColor: string;
  itemIconColor: string;
  itemBorderColor: string;
  itemBackgroundColor: string;
  items: ConfigFrontDoorLinksItem[];
}

interface ConfigTermsConditions {
  visible: boolean;
  titleLocaliseKey: string;
  subTitleLocaliseKey: string;
  links: string[];
  hideCancel: boolean;
}

export interface ConfigPublishToggle {
  textColor: string;
  textHoverColor: string;
}

export interface ConfigCollapseSwitcher {
  activeColor: string;
  backgroundActiveColor: string;
}

export interface ConfigIconLabelWithActive {
  textColor: string;
  textActiveColor: string;
  backgroundActiveColor: string;
}

export interface ConfigCheck {
  backgroundColor: string;
}

export interface ConfigRadio {
  backgroundColor: string;
}

export interface ConfigFirebaseAnalytics {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

interface ConfigFreeLogin {
  login: string;
  password: string;
  titleLocaliseKey: string;
  subTitleLocaliseKey: string;
  isDefault: boolean;
  sessionsLimit: number;
}

export interface ConfigScheduleIcon {
  iconColor: string;
  iconHoverColor: string;
}

export interface ConfigNotificationsPermission {
  titleLocaliseKey: string;
  introLocaliseKey: string;
  allowLocaliseKey: string;
  denyLocaliseKey: string;
}

export interface ConfigAdminBadge {
  backgroundColor: string;
  textColor: string;
}

export interface ConfigInAppNotifications {
  backgroundColor: string;
  textColor: string;
  buttonBackgroundColor: string;
  buttonTextColor: string;
}

export interface ConfigMobile {
  menuIconColor: string;
  saveCardIconColor: string;
  saveCardIconHoverColor: string;
}

interface ConfigDeepLink {
  appIconUrl: string;
  appName: string;
}

export interface ConfigMultiStepFilter {
  textColor: string;
  textHoverColor: string;
}

interface ConfigAbuseReport {
  enabled: boolean;
  appDomain: string | null;
}

interface ConfigDSAReportLink {
  localiseKey: string;
  href: string;
}

interface ConfigDSAReport {
  enabled: boolean;
  appDomain: string | null;
  recipient: string;
  links: ConfigDSAReportLink[];
}

export interface ConfigPostedIn {
  color: string;
  visible: boolean;
}

interface ConfigDateRangeFilter {
  allTimeStartDate: string;
}

export interface ConfigAnalyticsChartsTable {
  headerBackgroundColor: string;
  headerTextColor: string;
}

export interface ConfigAnalyticsCharts {
  table: ConfigAnalyticsChartsTable;
}

export interface ConfigAnalytics {
  charts: ConfigAnalyticsCharts;
  channelsExclude: number[];
  chartsExclude: ChartType[];
}

export interface ConfigChatsMessage {
  textColor: string;
  backgroundColor: string;
  dateColor: string;
}

export interface ConfigChatsInput {
  iconColor: string;
  iconHoverColor: string;
  sendIconColor: string;
  sendIconHoverColor: string;
  textColor: string;
  placeholderColor: string;
  backgroundColor: string;
}

export interface ConfigChats {
  showChatCreate: boolean;
  message: ConfigChatsMessage;
  messageOther: ConfigChatsMessage;
  input: ConfigChatsInput;
}

export interface Config {
  elements: {
    bodyBackgroundColor: string;
    switchColor: string;
    baseLink: ConfigBaseLink;
    card: ConfigCard;
    avatarBackgroundColor: string;
    input: ConfigInput;
    buttons: ConfigButtons;
    meta: ConfigMeta;
    dropdownMenu: DropdownMenu;
    select: ConfigSelect;
    header: ConfigHeader;
    searchInput: ConfigSearchInput;
    headerLinks: ConfigLink[];
    settings: ConfigSettings;
    navMenu: ConfigNavMenu;
    footer: ConfigFooter;
    channelLogos: ConfigChannelLogo[];
    externalTagsList: ConfigExternalTagsList;
    latestsStoryCards: ConfigLatestsStoryCards;
    noContent: ConfigNoContent;
    errorLayout: ConfigErrorLayout;
    mobileLayout: ConfigMobileLayout;
    modal: ConfigModal;
    storyTab: ConfigStoryTab;
    storyFeed: ConfigStoryFeed;
    horizontalFeed: ConfigHorizontalFeed;
    profile: ConfigProfile;
    detailsBlock: ConfigDetailsBlock;
    search: ConfigSearch;
    media: ConfigMedia;
    authorInfo: ConfigAuthorInfo;
    comments: ConfigComments;
    form: ConfigForm;
    login: ConfigLogin;
    signup: ConfigSignup;
    actionPane: ConfigActionPane;
    notifications: ConfigNotifications;
    inAppNotifications: ConfigInAppNotifications;
    tabs: ConfigTabs;
    addContent: ConfigCreateCard;
    circularLoader: ConfigCircularLoader;
    specialTeaser: ConfigSpecialTeaser;
    organisationLogoUrl: string;
    emailPlaceholder: string;
    standardEmojiSet: boolean;
    filter: ConfigFilter;
    appTabsOrders: string[];
    appTabsSecondaryOrders: string[];
    frontDoorLinks: ConfigFrontDoorLinks;
    userDirectory: ConfigUserDirectory;
    termsConditions: ConfigTermsConditions;
    supportEmail: string;
    publishToggle: ConfigPublishToggle;
    collapseSwitcher: ConfigCollapseSwitcher;
    iconLabelWithActive: ConfigIconLabelWithActive;
    check: ConfigCheck;
    radio: ConfigRadio;
    firebaseAnalytics: ConfigFirebaseAnalytics | null;
    freeLogin: ConfigFreeLogin | null;
    scheduleIcon: ConfigScheduleIcon;
    vapidKey: string | null;
    notificationsPermission: ConfigNotificationsPermission;
    adminBadge: ConfigAdminBadge;
    mobile: ConfigMobile;
    deeplink: ConfigDeepLink;
    multiStepFilter: ConfigMultiStepFilter;
    abuseReport: ConfigAbuseReport;
    dsaReport: ConfigDSAReport;
    postedIn: ConfigPostedIn;
    dateRangeFilter: ConfigDateRangeFilter;
    analytics: ConfigAnalytics;
    chats: ConfigChats;
  };
  styles: { fonts: ConfigFontFace[] };
}

export const configApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getConfig: builder.query<Config, ConfigArgs>({
      query: ({ organisationDomain }) => `/webapp/config?organisation=${organisationDomain}`,
    }),
  }),
});

export const { useGetConfigQuery } = configApi;
