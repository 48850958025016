import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ChannelContext, ConfigContext } from '../../../../context';
import { ChannelAppTabAction, StoryCard, StoryCardStatus, StoryStatus } from '../../../../services';
import { deleteCard } from '../../../../slices';
import {
  AbuseReportType,
  DSAReportType,
  useAbuseReportPopup,
  useAppDispatch,
  useCardEdit,
  useCardTitle,
  useDSAReportPopup,
  useRefreshStoryCardsUserStats,
} from '../../../hooks';
import { getCssVar, layoutPath } from '../../../utils';
import { ConfirmationModal } from '../../ConfirmationModal';
import { DropdownMenu } from '../../DropdownMenu';
import { IconLabel } from '../../IconLabel';
import { Popup } from '../../Popup';
import { ForwardToChatModal } from './ForwardToChatModal';

import classNames from 'classnames';
import dropdownMenuClasses from '../../DropdownMenu/DropdownMenu.module.scss';
import classes from './CardActionsCommon.module.scss';

export interface CardActionsProps {
  card: StoryCard;
  isHorizontalFeedCard?: boolean;
  isProfileFeedCard?: boolean;
}

interface CardActionsCommonProps extends CardActionsProps {
  isListOpen: boolean;
  setIsListOpen: (isOpen: boolean) => void;
  adminActions?: JSX.Element;
}
export const CardActionsCommon: FunctionComponent<CardActionsCommonProps> = memo(
  ({ card, adminActions, isListOpen, setIsListOpen, isHorizontalFeedCard, isProfileFeedCard }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { refreshStoryCardsUserStats } = useRefreshStoryCardsUserStats();

    const { pathname } = useLocation();

    const { cardEditModal, cardEditHandler } = useCardEdit({ storyCard: card, isProfileFeedCard });

    const { appTabs } = useContext(ChannelContext);

    const {
      abuseReport: { enabled: abuseReportEnabled },
      dsaReport: { enabled: dsaReportEnabled },
    } = useContext(ConfigContext).config.elements;

    const cardTitle = useCardTitle(card);

    const {
      id: cardId,
      type: cardType,
      storyId,
      permissions,
      status: cardStatus,
      user,
      story,
    } = card;

    const { title: storyTitle, status: storyStatus } = story;

    const { id: userId, screenName: userName } = { ...user };

    const { allowToDelete, allowToUpdate } = permissions;

    const cardPublished = useMemo(() => cardStatus === StoryCardStatus.PUBLISHED, [cardStatus]);

    const storyPublished = useMemo(() => storyStatus === StoryStatus.PUBLISHED, [storyStatus]);

    const isNotProfileFeedCard = useMemo(() => !isProfileFeedCard, [isProfileFeedCard]);

    const allowForwardToChat = useMemo(
      () =>
        Boolean(
          isNotProfileFeedCard &&
            storyPublished &&
            cardPublished &&
            appTabs.find(({ action: { type } }) => type === ChannelAppTabAction.CHATS)
        ),
      [appTabs, cardPublished, isNotProfileFeedCard, storyPublished]
    );

    const reportArgs = useMemo(() => {
      return {
        storyId,
        storyName: storyTitle,
        postId: cardId,
        postType: cardType,
        postTitle: cardTitle,
        authorId: userId,
        authorName: userName,
      };
    }, [cardId, cardTitle, cardType, storyId, storyTitle, userId, userName]);

    const { abuseReportModal, abuseReportHandler } = useAbuseReportPopup({
      type: AbuseReportType.Post,
      ...reportArgs,
    });

    const { DSAReportModal, DSAReportHandler } = useDSAReportPopup({
      type: DSAReportType.Post,
      ...reportArgs,
    });

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [isChatForwardOpen, setIsChatForwardOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const editHandler = useCallback(async () => {
      setIsListOpen(false);
      cardEditHandler();
    }, [cardEditHandler, setIsListOpen]);

    const deleteHandler = useCallback(() => {
      setIsListOpen(false);
      setIsDeleteOpen(true);
    }, [setIsListOpen]);

    const abuseReportClick = useCallback(() => {
      setIsListOpen(false);
      abuseReportHandler();
    }, [abuseReportHandler, setIsListOpen]);

    const DSAReportClick = useCallback(() => {
      setIsListOpen(false);
      DSAReportHandler();
    }, [DSAReportHandler, setIsListOpen]);

    const cardDelete = useCallback(async () => {
      setLoading(true);
      try {
        const { deleted } = await dispatch(deleteCard({ storyId, storyCardId: cardId })).unwrap();

        toast(t(deleted ? 'cardActions.card-deleted' : 'deleteConfirmation.error-message'));

        if (!deleted) {
          return;
        }

        refreshStoryCardsUserStats();

        if (pathname === layoutPath(`/details/${storyId}/${cardId}`)) {
          navigate(layoutPath('/'));
        }
      } catch (_) {
        toast.error(t('deleteConfirmation.error-message'));
      } finally {
        setLoading(false);
      }
    }, [cardId, dispatch, navigate, pathname, refreshStoryCardsUserStats, storyId, t]);

    const forwardToChatClick = useCallback(() => {
      setIsListOpen(false);
      setIsChatForwardOpen(true);
    }, [setIsListOpen]);

    const dropdownMenuContent = useMemo(() => {
      return (
        <>
          {adminActions}

          {allowForwardToChat && (
            <IconLabel
              iconId={'chats'}
              iconSize={18}
              label={t('cardActions.forward-to-chat')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={forwardToChatClick}
              singleColor
            />
          )}
          {abuseReportEnabled && isNotProfileFeedCard && (
            <IconLabel
              iconId={'report'}
              iconSize={18}
              label={t('abuseReport.label')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={abuseReportClick}
              singleColor
            />
          )}
          {dsaReportEnabled && isNotProfileFeedCard && (
            <IconLabel
              iconId={'problem'}
              iconSize={18}
              label={t('DSAReport.label')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={DSAReportClick}
              singleColor
            />
          )}
          {allowToUpdate && (
            <IconLabel
              iconId={'edit'}
              iconSize={18}
              label={t('common.edit')}
              onClick={editHandler}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              singleColor
            />
          )}
          {allowToDelete && (
            <IconLabel
              iconId={'delete'}
              iconSize={18}
              label={t('common.delete')}
              color={getCssVar('--color-danger')}
              onClick={deleteHandler}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              singleColor
            />
          )}
        </>
      );
    }, [
      DSAReportClick,
      abuseReportClick,
      abuseReportEnabled,
      adminActions,
      allowForwardToChat,
      allowToDelete,
      allowToUpdate,
      deleteHandler,
      dsaReportEnabled,
      editHandler,
      forwardToChatClick,
      isNotProfileFeedCard,
      t,
    ]);

    if (
      [adminActions, allowForwardToChat, isNotProfileFeedCard, allowToUpdate, allowToDelete].every(
        (condition) => !Boolean(condition)
      )
    ) {
      return null;
    }

    return (
      <>
        <Popup
          isOpen={isListOpen}
          setIsOpen={setIsListOpen}
          iconId={'dots-menu'}
          color={getCssVar('--action-pane-icon-color')}
          hoverColor={getCssVar('--action-pane-icon-hover-color')}
          bodyTop={'1.5rem'}
          bodyRight={'0.0625rem'}
          body={<DropdownMenu width={'12rem'} content={dropdownMenuContent} />}
          parentClassName={classNames(classes['card-actions'], 'card-actions', {
            [classes['card-actions--horizontal']]: isHorizontalFeedCard,
          })}
        />

        {cardEditModal}

        <ConfirmationModal
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          acceptLabel={t('common.delete')}
          onAccept={cardDelete}
          title={t('deleteConfirmation.title')}
          subTitle={t('deleteConfirmation.sub-title')}
          keepOpened={loading}
          loading={loading}
          danger
        />

        {abuseReportModal}

        {DSAReportModal}

        {isChatForwardOpen && (
          <ForwardToChatModal
            isOpen={isChatForwardOpen}
            onClose={() => setIsChatForwardOpen(false)}
            storyCard={card}
          />
        )}
      </>
    );
  }
);
