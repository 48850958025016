import { MultiStepFilterValue } from '../../components';

interface GetGroupByIdProps {
  filter: MultiStepFilterValue[];
  groupId: string;
}

interface GetGroupValueProps {
  filter: MultiStepFilterValue[];
  groupId: string;
  multiCheck?: boolean;
}

export const useMultiStepFilter = () => {
  const getGroupById = ({ filter, groupId }: GetGroupByIdProps) => {
    return filter.find((group) => group.groupId === groupId);
  };

  const getGroupValue = ({ filter, groupId, multiCheck = false }: GetGroupValueProps) => {
    const group = getGroupById({ filter, groupId });

    const groupValue = group?.groupValues?.map(({ value }) => value) ?? [];

    return !multiCheck ? groupValue[0] : groupValue;
  };

  return { getGroupById, getGroupValue };
};
