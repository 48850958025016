import { FunctionComponent, memo } from 'react';
import { StoryCardImageContent } from '../../../services';
import { FeedCardContentCommon, FeedCardImageCommon, FeedCardProps } from '../../../shared';

export const FeedCardAdminImage: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { storyId, id, type, content, isPinnedToTopOfNewsFeed, mentionedUsers } = card;
  const { sourceName, headline, subHeadline, text, textHTML } = content as StoryCardImageContent;

  return (
    <FeedCardContentCommon
      storyId={storyId}
      cardId={id}
      type={type}
      pinned={isPinnedToTopOfNewsFeed}
      source={sourceName}
      title={headline}
      media={<FeedCardImageCommon card={card} />}
      subTitle={subHeadline}
      text={text}
      htmlText={textHTML}
      mentionedUsers={mentionedUsers}
    />
  );
});
