import { FunctionComponent, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../context';
import { User } from '../../../services';
import { BadgeList } from '../../../shared';

import classNames from 'classnames';
import classes from './PersonalInfo.module.scss';

interface PersonalInfoProps {
  user: User;
}

export const PersonalInfo: FunctionComponent<PersonalInfoProps> = memo(({ user }) => {
  const { t } = useTranslation();

  const { config } = useContext(ConfigContext);

  const { fields } = config.elements.profile;

  const { email, phone, gender, position, department, location, subscription, organisationTags } =
    user;

  const showEmail = useMemo(() => Boolean(fields['email'].visible && email), [email, fields]);

  const showPhone = useMemo(() => Boolean(fields['phone'].visible && phone), [fields, phone]);

  const showGender = useMemo(() => Boolean(fields['gender'].visible && gender), [fields, gender]);

  const showPosition = useMemo(
    () => Boolean(fields['position'].visible && position),
    [fields, position]
  );

  const showDepartment = useMemo(
    () => Boolean(fields['department'].visible && department),
    [department, fields]
  );

  const showLocation = useMemo(
    () => Boolean(fields['location'].visible && location.text),
    [fields, location]
  );

  const showSubscription = useMemo(
    () => Boolean(fields['subscription'].visible && subscription),
    [fields, subscription]
  );

  const showTags = useMemo(
    () => Boolean(fields['organisationTags'].visible && organisationTags.length),
    [fields, organisationTags.length]
  );

  if (
    ![
      showEmail,
      showPhone,
      showGender,
      showPosition,
      showDepartment,
      showLocation,
      showSubscription,
    ].some((fieldEnabled) => Boolean(fieldEnabled))
  ) {
    return null;
  }

  return (
    <div className={classes['personal-info']}>
      <div className={classes['personal-info__title']}>{t('profile.information')}</div>

      {showEmail && (
        <div className={classes['personal-info__field']}>
          <span className={classes['personal-info__field-label']}>
            {t(`${fields['email'].localiseKey ?? 'common.email'}`)}
          </span>
          <a
            className={classNames(
              classes['personal-info__field-value'],
              classes['personal-info__field-value--email']
            )}
            href={`mailto:${email}`}
          >
            {email}
          </a>
        </div>
      )}
      {showPhone && (
        <div className={classes['personal-info__field']}>
          <span className={classes['personal-info__field-label']}>
            {t(`${fields['phone'].localiseKey ?? 'common.phone'}`)}
          </span>
          <a
            className={classNames(
              classes['personal-info__field-value'],
              classes['personal-info__field-value--phone']
            )}
            href={`tel:${phone}`}
          >
            {phone}
          </a>
        </div>
      )}
      {showGender && (
        <div className={classes['personal-info__field']}>
          <span className={classes['personal-info__field-label']}>
            {t(`${fields['gender'].localiseKey ?? 'common.gender'}`)}
          </span>
          <span className={classes['personal-info__field-value']}>
            {t(`genderTypes.${gender}`)}
          </span>
        </div>
      )}
      {showPosition && (
        <div className={classes['personal-info__field']}>
          <span className={classes['personal-info__field-label']}>
            {t(`${fields['position'].localiseKey ?? 'common.position'}`)}
          </span>
          <span className={classes['personal-info__field-value']}>{position}</span>
        </div>
      )}
      {showDepartment && (
        <div className={classes['personal-info__field']}>
          <span className={classes['personal-info__field-label']}>
            {t(`${fields['department'].localiseKey ?? 'common.department'}`)}
          </span>
          <span className={classes['personal-info__field-value']}>{department}</span>
        </div>
      )}
      {showLocation && (
        <div className={classes['personal-info__field']}>
          <span className={classes['personal-info__field-label']}>
            {t(`${fields['location'].localiseKey ?? 'common.location'}`)}
          </span>
          <span className={classes['personal-info__field-value']}>{location.text}</span>
        </div>
      )}
      {showSubscription && (
        <div className={classes['personal-info__field']}>
          <span className={classes['personal-info__field-label']}>
            {t(`${fields['subscription'].localiseKey ?? 'common.subscription'}`)}
          </span>
          <span className={classes['personal-info__field-value']}>{subscription}</span>
        </div>
      )}
      {showTags && <BadgeList items={organisationTags} />}
    </div>
  );
});
