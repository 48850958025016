import { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import sprite from '../../../../assets/icons/sprite.svg';
import {
  StoryCard,
  StoryCardContentGallery,
  StoryCardContentText,
  StoryCardContentTitle,
  StoryCardQuoteContent,
  StoryCardType,
} from '../../../../services';
import { useCardDate } from '../../../hooks';
import { isHtmlString } from '../../../utils';
import { IconLabel, IconLabelSizes } from '../../IconLabel';
import { LineClamp } from '../../LineClamp';

import classNames from 'classnames';
import classes from './FeedCardCollapsedHeader.module.scss';

interface FeedCardCollapsedHeaderProps {
  card: StoryCard;
  collapsed: boolean;
  toggleCollapsed: () => void;
}

export const FeedCardCollapsedHeader: FunctionComponent<FeedCardCollapsedHeaderProps> = memo(
  ({ card, collapsed, toggleCollapsed }) => {
    const { t } = useTranslation();

    const { type, content, dateToDisplay } = card;

    const date = useCardDate(dateToDisplay);

    const { headline, subHeadline, textHTML, text } = content as StoryCardContentText;
    const { quoteSource, quote } = content as StoryCardQuoteContent;
    const { gallery } = content as StoryCardContentGallery;
    const { title } = content as StoryCardContentTitle;

    const hasPreview = useMemo(
      () => [StoryCardType.IMAGE, StoryCardType.QUOTE].includes(type) && gallery.length > 0,
      [gallery?.length, type]
    );

    const previewSrc = useMemo(
      () => (hasPreview ? gallery[0]?.image?.url : ''),
      [gallery, hasPreview]
    );

    const isImageGallery = useMemo(
      () => hasPreview && gallery?.length > 1,
      [gallery?.length, hasPreview]
    );

    const iconId = useMemo(() => {
      switch (type) {
        case StoryCardType.ARTICLE:
        case StoryCardType.POST:
          return 'article';
        case StoryCardType.IMAGE:
          return isImageGallery ? 'gallery' : type.toLowerCase();
        case StoryCardType.QUOTE:
          return quoteSource?.toLowerCase();
        default:
          return type.toLowerCase();
      }
    }, [isImageGallery, quoteSource, type]);

    const { heading, headingBold } = useMemo(() => {
      switch (type) {
        case StoryCardType.EDITORIAL:
        case StoryCardType.AUDIO:
        case StoryCardType.VIDEO:
        case StoryCardType.IMAGE:
        case StoryCardType.PDF:
          return {
            heading: headline || subHeadline || textHTML || text,
            headingBold: Boolean(headline || subHeadline),
          };
        case StoryCardType.ARTICLE:
        case StoryCardType.POST:
        case StoryCardType.THREAD:
          return { heading: title, headingBold: true };
        case StoryCardType.QUOTE:
          return { heading: quote, headingBold: false };
        default:
          return { heading: '', headingBold: false };
      }
    }, [headline, quote, subHeadline, text, textHTML, title, type]);

    const clickHandler = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((event.target as HTMLElement).tagName.toLowerCase() === 'a') {
          return;
        }

        toggleCollapsed();
      },
      [toggleCollapsed]
    );

    const renderHeading = useMemo(() => {
      if (!heading) {
        return null;
      }

      if (!isHtmlString(heading)) {
        return (
          <div
            className={classNames(classes['card-collapsed-header__description-heading'], {
              [classes['card-collapsed-header__description-heading--bold']]: headingBold,
            })}
          >
            {heading}
          </div>
        );
      }

      return (
        <LineClamp
          maxLines={1}
          readMore={'...'}
          contentClassName={classes['card-collapsed-header__description-heading']}
          htmlStringContent={heading.replace(/href/g, "target='_blank' href")}
        />
      );
    }, [heading, headingBold]);

    return (
      <div
        className={classNames(classes['card-collapsed-header'], {
          [classes['card-collapsed-header--opened']]: !collapsed,
        })}
        onClick={clickHandler}
      >
        <IconLabel
          className={classes['card-collapsed-header__type']}
          iconId={iconId}
          label={t(`cardTypes.${type.toLowerCase()}`)}
          labelSize={IconLabelSizes.large}
          color={'#43445B'}
          nonClickable
        />

        {collapsed && (
          <>
            <div
              className={classNames(classes['card-collapsed-header__description'], {
                [classes['card-collapsed-header__description--has-preview']]: hasPreview,
              })}
            >
              {renderHeading}
              <div className={classes['card-collapsed-header__description-posted-time']}>
                {date}
              </div>
            </div>
            {hasPreview && (
              <div
                className={classes['card-collapsed-header__description-preview']}
                style={{ backgroundImage: `url('${previewSrc}')` }}
              ></div>
            )}
          </>
        )}
        <svg
          className={classNames(classes['card-collapsed-header__arrow'], {
            [classes['card-collapsed-header__arrow--up']]: !collapsed,
          })}
        >
          <use href={`${sprite}#down-arrow`} />
        </svg>
      </div>
    );
  }
);
