/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StoriesCardsUserFeedArgs, StoryCard, storyCardApi } from '../services';
import {
  addComment,
  deleteCard,
  deleteComment,
  editComment,
  hideComment,
  highlightComment,
  reactOnCard,
  reactOnComment,
  togglePublishOnCard,
} from './common';
import {
  addCommentReducer,
  deleteCommentReducer,
  editCommentReducer,
  hideCommentReducer,
  highlightCommentReducer,
  reactOnCardReducer,
  reactOnCommentReducer,
} from './extraReducers';

const getStoriesCardsUserFeedHandler = async (
  args: StoriesCardsUserFeedArgs,
  { dispatch }: any
) => {
  return dispatch(storyCardApi.endpoints.storiesCardsUserFeed.initiate({ ...args })).unwrap();
};

export const getStoriesCardsUserFeed = createAsyncThunk(
  'storiesCardsUserFeed',
  getStoriesCardsUserFeedHandler
);

export const loadMoreStoriesCardsUserFeed = createAsyncThunk(
  'storiesCardsUserFeed/loadMore',
  getStoriesCardsUserFeedHandler
);

interface StoriesCardsUserFeedState {
  storiesCardsUserFeed: StoryCard[];
  page: number;
  hasNextPage: boolean;
  isFetching: boolean;
}

const initialState: StoriesCardsUserFeedState = {
  storiesCardsUserFeed: [],
  page: 0,
  hasNextPage: false,
  isFetching: false,
};

const storiesCardsUserFeedSlice = createSlice({
  name: 'storiesCardsUserFeed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStoriesCardsUserFeed.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getStoriesCardsUserFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.storiesCardsUserFeed = items;
        state.page = page;
        state.hasNextPage = hasNextPage;
        state.isFetching = false;
      })
      .addCase(loadMoreStoriesCardsUserFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.storiesCardsUserFeed.push(...items);
        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        const { itemId, comment } = action.payload;

        const card = state.storiesCardsUserFeed.find(({ id }) => id === itemId);

        if (!card) {
          return;
        }

        addCommentReducer({ card, comment });
      })
      .addCase(editComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.storiesCardsUserFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        editCommentReducer({ card, comment });
      })
      .addCase(hideComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.storiesCardsUserFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        hideCommentReducer({ card, comment });
      })
      .addCase(highlightComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = state.storiesCardsUserFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        highlightCommentReducer({ card, comment });
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        const { deleted, cardId, commentId, parentId } = action.payload;

        if (!deleted) {
          return;
        }

        const card = state.storiesCardsUserFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        deleteCommentReducer({ card, commentId, parentId });
      })
      .addCase(reactOnComment.fulfilled, (state, action) => {
        const { reactions, cardId, commentId } = action.payload;

        const card = state.storiesCardsUserFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        reactOnCommentReducer({ card, commentId, reactions });
      })
      .addCase(reactOnCard.fulfilled, (state, action) => {
        const { cardId, reaction, reactions } = action.payload;

        const card = state.storiesCardsUserFeed.find(({ id }) => id === cardId);

        if (!card) {
          return;
        }

        reactOnCardReducer({ card, reaction, reactions });
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        const { deleted, storyCardId } = action.payload;

        if (!deleted) {
          return;
        }

        state.storiesCardsUserFeed = state.storiesCardsUserFeed.filter(
          ({ id }) => id !== storyCardId
        );
      })
      .addCase(togglePublishOnCard.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!payload) {
          return;
        }

        const { id: storyCardId } = payload;

        state.storiesCardsUserFeed = state.storiesCardsUserFeed.filter(
          ({ id }) => id !== storyCardId
        );
      });
  },
});

export default storiesCardsUserFeedSlice.reducer;
