import { FunctionComponent, memo, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Emoticon } from '../../../../assets/emojis';
import { UserContext } from '../../../../context';
import { useFreeLoginPopup, useOnClickOutside } from '../../../hooks';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';
import { Reactions } from './Reactions';

import classNames from 'classnames';
import classes from './ReactionsSelect.module.scss';

interface ReactionSelectProps {
  myReaction?: string;
  onSelect: (reaction: string) => void;
  onShowPicker?: () => void;
  onHidePicker?: () => void;
  hideLabel?: boolean;
  className?: string;
  pickerClassName?: string;
  defaultIconId?: string;
}

export const ReactionSelect: FunctionComponent<ReactionSelectProps> = memo(
  ({
    myReaction,
    onSelect,
    onShowPicker,
    onHidePicker,
    hideLabel,
    className,
    pickerClassName,
    defaultIconId = 'thumb-up-off',
  }) => {
    const { t } = useTranslation();

    const { isAnonymous } = useContext(UserContext).userProfile;

    const { freeLoginModal, freeLoginHandler } = useFreeLoginPopup();

    const ref = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);

    useOnClickOutside(ref, () => {
      setIsOpen(false);
      setTimeout(() => onHidePicker?.(), 0);
    });

    const onSelectHandler = useCallback(
      (reaction: string) => {
        onSelect(reaction);
        setIsOpen(false);
      },
      [onSelect]
    );

    const onClickHandler = useCallback(() => {
      if (isAnonymous) {
        freeLoginHandler();
        return;
      }

      isOpen ? onHidePicker?.() : onShowPicker?.();
      setIsOpen(!isOpen);
    }, [freeLoginHandler, isAnonymous, isOpen, onHidePicker, onShowPicker]);

    const showLabel = useMemo(() => Boolean(!hideLabel && !isMobileOnly), [hideLabel]);

    return (
      <>
        <div ref={ref} className={classNames(classes['reaction-select'], className)}>
          <IconLabel
            {...(myReaction && {
              label: showLabel ? t(`reactions.${myReaction}`) : '',
              icon: <Emoticon reaction={myReaction} />,
            })}
            {...(!myReaction && {
              label: showLabel ? t('reactions.like') : '',
              iconId: defaultIconId,
              iconSize: 20,
            })}
            color={getCssVar('--action-pane-icon-color')}
            hoverColor={getCssVar('--action-pane-icon-hover-color')}
            onClick={onClickHandler}
          />
          {isOpen && (
            <div className={classNames(classes['reaction-select__picker'], pickerClassName)}>
              <Reactions onSelect={onSelectHandler} />
            </div>
          )}
        </div>

        {freeLoginModal}
      </>
    );
  }
);
