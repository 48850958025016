import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatContext } from '../../../context';
import { ChatAccess, ChatTypes } from '../../../services';
import { Avatar, getCssVar, IconLabel, Modal } from '../../../shared';
import { CreateChatModal } from '../CreateChatModal';
import { AddChatMembers } from './AddChatMembers';
import { ManageChatMembers } from './ManageChatMembers';

import classNames from 'classnames';
import classes from './ChatDetails.module.scss';

export enum ChatDetailsType {
  INFO = 'INFO',
  ADD_USERS = 'ADD_USERS',
  MANAGE_USERS = 'MANAGE_USERS',
}

interface ChatDetailsProps {
  detailsType: ChatDetailsType | null;
  setDetailsType: (type: ChatDetailsType | null) => void;
}

export const ChatDetails: FunctionComponent<ChatDetailsProps> = memo(
  ({ detailsType, setDetailsType }) => {
    const { t } = useTranslation();

    const { activeChat } = useContext(ChatContext);

    const { type, access, usersCount, created, accessType, name, payload, image, level } = {
      ...activeChat,
    };

    const isOpen = useMemo(() => Boolean(detailsType), [detailsType]);

    const isGroupChat = useMemo(() => type === ChatTypes.group, [type]);

    const hasAdminAccess = useMemo(() => access === ChatAccess.admin, [access]);

    const avatarUrl = useMemo(() => image?.url, [image?.url]);

    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

    const [avatarPreviewOpen, setAvatarPreviewOpen] = useState<boolean>(false);

    const editChatHandler = useCallback(() => {
      setDetailsType(null);
      setIsCreateModalOpen(true);
    }, [setDetailsType]);

    const body = useMemo(() => {
      switch (detailsType) {
        case ChatDetailsType.ADD_USERS:
          return <AddChatMembers setDetailsType={setDetailsType} />;
        case ChatDetailsType.MANAGE_USERS:
          return <ManageChatMembers setDetailsType={setDetailsType} />;
        case ChatDetailsType.INFO:
          return (
            <div className={classes['chat-details__content-body']}>
              {isGroupChat && (
                <div className={classes['chat-details__content-body-group']}>
                  <Avatar
                    size={64}
                    url={avatarUrl}
                    onClick={() => avatarUrl && setAvatarPreviewOpen(true)}
                    nonClickable={!avatarUrl}
                  />

                  {name && (
                    <div className={classes['chat-details__content-body-group-name']}>{name}</div>
                  )}

                  {payload && (
                    <div className={classes['chat-details__content-body-group-purpose']}>
                      {payload}
                    </div>
                  )}

                  <div className={classes['chat-details__content-body-group-buttons']}>
                    {hasAdminAccess && (
                      <>
                        <IconLabel
                          iconId={'edit'}
                          iconSize={18}
                          label={t('common.edit')}
                          color={getCssVar('--base-link-text-color')}
                          hoverColor={getCssVar('--base-link-text-hover-color')}
                          onClick={editChatHandler}
                        />

                        <IconLabel
                          iconId={'users-add'}
                          iconSize={18}
                          label={t('chatActions.add-members')}
                          color={getCssVar('--base-link-text-color')}
                          hoverColor={getCssVar('--base-link-text-hover-color')}
                          onClick={() => setDetailsType(ChatDetailsType.ADD_USERS)}
                        />
                      </>
                    )}

                    <IconLabel
                      iconId={'users'}
                      iconSize={18}
                      label={t('common.participants')}
                      color={getCssVar('--base-link-text-color')}
                      hoverColor={getCssVar('--base-link-text-hover-color')}
                      onClick={() => setDetailsType(ChatDetailsType.MANAGE_USERS)}
                    />
                  </div>
                </div>
              )}
              <div className={classes['chat-details__content-body-info']}>
                <div className={classes['chat-details__content-body-info-title']}>
                  {t('chatActions.info')}
                </div>
                <div className={classes['chat-details__content-body-info-item']}>
                  <div className={classes['chat-details__content-body-info-item-label']}>
                    {t('common.created')}
                  </div>
                  <div className={classes['chat-details__content-body-info-item-value']}>
                    {format(parseISO(created ?? ''), 'dd.MM.yyyy, HH:mm')}
                  </div>
                </div>
                <div
                  className={classNames(classes['chat-details__content-body-info-item'], {
                    [classes['chat-details__content-body-info-item--clickable']]: isGroupChat,
                  })}
                  onClick={() => isGroupChat && setDetailsType(ChatDetailsType.MANAGE_USERS)}
                >
                  <div className={classes['chat-details__content-body-info-item-label']}>
                    {t('common.participants')}
                  </div>
                  <div
                    className={classNames(classes['chat-details__content-body-info-item-value'], {
                      [classes['chat-details__content-body-info-item-value--link']]: isGroupChat,
                    })}
                  >
                    {usersCount}
                  </div>
                </div>
                <div className={classes['chat-details__content-body-info-item']}>
                  <div className={classes['chat-details__content-body-info-item-label']}>
                    {t('common.access')}
                  </div>
                  <div className={classes['chat-details__content-body-info-item-value']}>
                    {t(`chatAccessTypes.${accessType}`)}
                  </div>
                </div>
                <div className={classes['chat-details__content-body-info-item']}>
                  <div className={classes['chat-details__content-body-info-item-label']}>
                    {t('common.type')}
                  </div>
                  <div className={classes['chat-details__content-body-info-item-value']}>
                    {t(`chatTypes.${type}`)}
                  </div>
                </div>
                {isGroupChat && (
                  <div className={classes['chat-details__content-body-info-item']}>
                    <div className={classes['chat-details__content-body-info-item-label']}>
                      {t('common.level')}
                    </div>

                    <div className={classes['chat-details__content-body-info-item-value']}>
                      {t(`chatLevels.${level}`)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        default:
          return null;
      }
    }, [
      accessType,
      avatarUrl,
      created,
      detailsType,
      editChatHandler,
      hasAdminAccess,
      isGroupChat,
      level,
      name,
      payload,
      setDetailsType,
      t,
      type,
      usersCount,
    ]);

    if (!activeChat) {
      return null;
    }

    return (
      <>
        <div
          className={classNames(classes['chat-details'], {
            [classes['chat-details--open']]: isOpen,
          })}
        >
          <div
            className={classes['chat-details__overlay']}
            onClick={() => setDetailsType(null)}
          ></div>
          <div className={classes['chat-details__content']}>{body}</div>
        </div>

        {isCreateModalOpen && (
          <CreateChatModal
            isOpen={isCreateModalOpen}
            chat={activeChat}
            onClose={() => setIsCreateModalOpen(false)}
          />
        )}

        <Modal
          isOpen={avatarPreviewOpen}
          body={<img src={avatarUrl ?? ''} alt={'preview'} />}
          onClose={() => setAvatarPreviewOpen(false)}
          root={'modal-preview-root'}
          previewMode
        />
      </>
    );
  }
);
