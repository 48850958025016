import { format, isSameDay } from 'date-fns';
import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChatContext, UserContext } from '../../../../context';
import { Chat, ChatAccessTypes, ChatMessageType, ChatTypes } from '../../../../services';
import { Avatar, IconLabel, layoutPath, timeTokenToDate } from '../../../../shared';

import classNames from 'classnames';
import classes from './ChatsListItem.module.scss';

interface ChatsListItemProps {
  chat: Chat;
}

export const ChatsListItem: FunctionComponent<ChatsListItemProps> = memo(({ chat }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { activeChat, setActiveChat, channels, channelsChanges, channelsUnreadCount, chatUsers } =
    useContext(ChatContext);

  const userId = useContext(UserContext).userProfile.id;

  const { chatId, type, name, chatDescriptor, accessType, image, thumbnails, access, usersCount } =
    chat;

  const typeIconId = useMemo(() => {
    if (type === ChatTypes.p2p) {
      return 'chats';
    }

    switch (accessType) {
      case ChatAccessTypes.private:
        return 'lock';
      case ChatAccessTypes.publicRead:
        return 'eye';
      case ChatAccessTypes.publicWrite:
        return 'planet';
    }
  }, [accessType, type]);

  const isP2P = useMemo(() => type === ChatTypes.p2p, [type]);

  const avatarUrl = useMemo(
    () => (isP2P ? thumbnails[0].image : image?.url),
    [image?.url, isP2P, thumbnails]
  );

  const avatarFallBackIconId = useMemo(() => (isP2P ? 'avatar' : 'avatars'), [isP2P]);

  const topFetchedMessage = useMemo(() => {
    const fetchedMessages = channels?.[chatDescriptor];

    if (!fetchedMessages) {
      return;
    }

    for (let i = fetchedMessages.length - 1; i >= 0; i--) {
      const fetchedMessage = fetchedMessages[i];

      if (
        [ChatMessageType.text, ChatMessageType.post].includes(
          (fetchedMessage.message as { type: ChatMessageType }).type
        )
      ) {
        return fetchedMessage;
      }
    }
  }, [channels, chatDescriptor]);

  const renderTopMessage = useMemo(() => {
    if (!topFetchedMessage) {
      return null;
    }

    const { message, timetoken } = topFetchedMessage;

    const isDeleted = Boolean(
      channelsChanges?.[`${chatDescriptor}.changes`]?.find(
        ({ message }) => (message as { messageId: string }).messageId === timetoken
      )
    );

    const {
      type,
      author = 0,
      authorName,
      text = '',
    } = { ...(message as { type: string; author: number; authorName: string; text: string }) };

    const publisher = (() => {
      switch (true) {
        case author === userId:
          return t('common.me');
        case isP2P:
          return name;
        default:
          return chatUsers.find(({ id }) => id === author)?.screenName ?? authorName;
      }
    })();

    const messageText = (() => {
      switch (true) {
        case isDeleted:
          return t('chatServerMessages.message-removed');
        case Boolean(type === ChatMessageType.post):
          return t('chatActions.shared-attachment');
        case Boolean(text.length):
          return text.replace(/<a href="[^"]*">([^<]*)<\/a>/g, '$1');
      }
    })();

    return (
      <div className={classes['chat__content-message-text']}>
        {messageText ? `${publisher}: ${messageText}`.replace(/(.{70})..+/, '$1…') : ''}
      </div>
    );
  }, [channelsChanges, chatDescriptor, chatUsers, isP2P, name, t, topFetchedMessage, userId]);

  const topMessageDate = useMemo(() => {
    const timetoken = topFetchedMessage?.timetoken;

    if (!timetoken) {
      return '';
    }

    const date = timeTokenToDate(timetoken);

    return format(date, isSameDay(Date.now(), date) ? 'HH:mm' : 'LLL d');
  }, [topFetchedMessage?.timetoken]);

  const unread = useMemo(
    () => channelsUnreadCount?.[chatDescriptor] ?? 0,
    [channelsUnreadCount, chatDescriptor]
  );

  const renderUnread = useMemo(() => {
    if (!unread) {
      return null;
    }

    return <div className={classes['chat__content-message-unread']}>{unread}</div>;
  }, [unread]);

  const renderUserCount = useMemo(() => {
    if (access) {
      return null;
    }

    return <IconLabel iconId={'users'} label={`${usersCount}`} singleColor nonClickable />;
  }, [access, usersCount]);

  const clickHandler = useCallback(() => {
    setActiveChat(chat);
    navigate(layoutPath(`/chats?chatId=${chatId}`));
  }, [chat, chatId, navigate, setActiveChat]);

  return (
    <div
      className={classNames(classes['chat'], {
        [classes['chat--active']]: activeChat?.chatId === chatId,
      })}
      onClick={clickHandler}
    >
      <div className={classes['chat__avatar']}>
        <Avatar size={48} url={avatarUrl} fallBackIconId={avatarFallBackIconId} nonClickable />
        {/* {isOnline && <span className={classes['chat__avatar-status']}></span>} */}
      </div>

      <div className={classes['chat__content']}>
        <div className={classes['chat__content-header']}>
          <IconLabel
            iconId={typeIconId}
            iconSize={18}
            label={name}
            className={classes['chat__content-header-title']}
            labelClassName={classes['chat__content-header-title-text']}
            nonClickable
          />
          <div className={classes['chat__content-header-time']}>{topMessageDate}</div>
        </div>
        <div className={classes['chat__content-message']}>
          {renderTopMessage}
          {renderUnread}
          {renderUserCount}
        </div>
      </div>
    </div>
  );
});
