import {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context';
import {
  storyCardApi,
  StoryCardCategory,
  StoryCardCategorySettings,
  StoryCardCategoryTemplate,
  StoryType,
  useGetStoriesByChannelIdQuery,
} from '../../../../services';
import {
  Button,
  ButtonType,
  ConfirmationModal,
  getCssVar,
  IconLabel,
  InputField,
  Modal,
  Select,
  Switch,
} from '../../../../shared';
import { CategoryTemplatesModal } from '../CategoryTemplatesModal';

import classes from './CreateCategoryModal.module.scss';

const TIME_TO_LIVE_VALUE = 15;

interface CreateCategoryModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  templates: StoryCardCategoryTemplate[];
  setTemplates: (templates: StoryCardCategoryTemplate[]) => void;
  categories: StoryCardCategory[];
  editCategoryId: number | null;
  setEditCategoryId: (editCategoryId: number | null) => void;
  submitCallback: () => void;
}

export const CreateCategoryModal: FunctionComponent<CreateCategoryModalProps> = memo(
  ({
    isOpen,
    setIsOpen,
    templates,
    setTemplates,
    editCategoryId,
    setEditCategoryId,
    categories,
    submitCallback,
  }) => {
    const { t } = useTranslation();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const { data: stories, isFetching } = useGetStoriesByChannelIdQuery({
      channelId,
      filter: { onlyPostIn: false, type: [StoryType.STANDARD] },
    });

    const [categoryCreate] = storyCardApi.endpoints.storyCardCategoryCreate.useLazyQuery();

    const [categoryUpdate] = storyCardApi.endpoints.storyCardCategoryUpdate.useLazyQuery();

    const [name, setName] = useState<string>('');

    const [storyId, setStoryId] = useState<number | undefined>();

    const [customTemplateIndex, setCustomTemplateIndex] = useState<number | null>(null);

    const [deleteTemplateIndex, setDeleteTemplateIndex] = useState<number | null>(null);

    const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState<boolean>(false);

    const defaultSettings = useMemo(() => {
      return {
        userSelection: false,
        emailNotification: false,
        editableMedia: false,
        timeToLive: 0,
        buttonTextEn: '',
        buttonTextDe: '',
        showAsOption: true,
        showAsButton: false,
      };
    }, []);

    const [settings, setSettings] = useState<StoryCardCategorySettings>(defaultSettings);

    const {
      userSelection,
      emailNotification,
      editableMedia,
      timeToLive,
      buttonTextEn,
      buttonTextDe,
      showAsOption,
      showAsButton,
    } = settings;

    const storyItems = useMemo(() => {
      return (
        stories?.map(({ id, title }) => {
          return { id, title };
        }) ?? []
      );
    }, [stories]);

    const title = useMemo(
      () => t(`storyCardCategory.${editCategoryId ? 'edit' : 'new'}-category`),
      [editCategoryId, t]
    );

    const submitLabel = useMemo(
      () => t(`common.${editCategoryId ? 'save' : 'create'}`),
      [editCategoryId, t]
    );

    const submitDisabled = useMemo(
      () => [name, buttonTextEn, buttonTextDe].some((field) => !field.length),
      [buttonTextDe, buttonTextEn, name]
    );

    const editCategory = useMemo(
      () => categories.find(({ id }) => id === editCategoryId),
      [categories, editCategoryId]
    );

    useEffect(() => {
      if (!editCategory) {
        return;
      }

      const { name, settings, templates, story } = editCategory;

      setName(name);
      setSettings(settings);
      setTemplates(templates);
      setStoryId(story?.id);
      setIsOpen(true);
    }, [editCategory, setIsOpen, setTemplates]);

    const onClose = useCallback(() => {
      setIsOpen(false);
      setName('');
      setSettings(defaultSettings);
      setTemplates([]);
      setStoryId(undefined);
      setEditCategoryId(null);
    }, [defaultSettings, setEditCategoryId, setIsOpen, setTemplates]);

    const onSubmit = useCallback(async () => {
      const templatesMapped = templates.map(({ gallery, ...template }) => {
        return {
          ...template,
          gallery: gallery.map(({ image, ...galleryItem }) => {
            return { ...galleryItem, image: { id: image.id } };
          }),
        };
      }) as StoryCardCategoryTemplate[];

      const args = { name, settings, templates: templatesMapped, storyId };

      await (editCategoryId
        ? categoryUpdate({ categoryId: editCategoryId, ...args })
        : categoryCreate(args));

      onClose();

      submitCallback();
    }, [
      categoryCreate,
      categoryUpdate,
      editCategoryId,
      name,
      onClose,
      settings,
      storyId,
      submitCallback,
      templates,
    ]);

    const onTemplateDelete = useCallback(() => {
      if (deleteTemplateIndex === null) {
        return;
      }

      const templatesCopy = [...templates];

      templatesCopy.splice(deleteTemplateIndex, 1);

      setTemplates(templatesCopy);

      setDeleteTemplateIndex(null);
    }, [deleteTemplateIndex, setTemplates, templates]);

    const body = useMemo(() => {
      return (
        <div className={classes['category']}>
          <div className={classes['category__title']}>{title}</div>

          <div className={classes['category__scrollable']}>
            <InputField
              value={name}
              placeholder={t('storyCardCategory.name')}
              onChange={({ target }) => setName(target.value)}
            />

            <div className={classes['category__row']}>
              <InputField
                value={buttonTextEn}
                placeholder={t('storyCardCategory.button-placeholder-US')}
                onChange={({ target }) => setSettings({ ...settings, buttonTextEn: target.value })}
                icon={<IconLabel iconId={'flag-US'} iconSize={18} singleColor nonClickable />}
              />
              <InputField
                value={buttonTextDe}
                placeholder={t('storyCardCategory.button-placeholder-DE')}
                onChange={({ target }) => setSettings({ ...settings, buttonTextDe: target.value })}
                icon={<IconLabel iconId={'flag-DE'} iconSize={18} singleColor nonClickable />}
              />
            </div>

            <Select
              label={t('common.story')}
              selectedItemId={storyId}
              items={storyItems}
              disabled={isFetching}
              listMaxHeightRem={12.5}
              onChange={setStoryId}
              staticListPosition
              search
              uncheck
            />

            <div className={classes['category__row']}>
              <Switch
                inputId={'user-selection'}
                label={t('storyCardCategory.user-selection-switch')}
                className={classes['category__row-switch']}
                checked={userSelection}
                onChange={(userSelection) => setSettings({ ...settings, userSelection })}
              />

              <Switch
                inputId={'email-notification'}
                label={t('storyCardCategory.email-notification-switch')}
                className={classes['category__row-switch']}
                checked={emailNotification}
                onChange={(emailNotification) => setSettings({ ...settings, emailNotification })}
              />
            </div>

            <div className={classes['category__row']}>
              <Switch
                inputId={'editable-media'}
                label={t('storyCardCategory.editable-media-switch')}
                className={classes['category__row-switch']}
                checked={editableMedia}
                onChange={(editableMedia) => setSettings({ ...settings, editableMedia })}
              />

              <Switch
                inputId={'time-to-live'}
                label={t('storyCardCategory.time-to-live')}
                className={classes['category__row-switch']}
                checked={Boolean(timeToLive)}
                onChange={(checked) =>
                  setSettings({ ...settings, timeToLive: checked ? TIME_TO_LIVE_VALUE : 0 })
                }
              />
            </div>

            <div className={classes['category__row']}>
              <Switch
                inputId={'show-as-option'}
                label={t('storyCardCategory.show-as-option')}
                className={classes['category__row-switch']}
                checked={showAsOption}
                onChange={(showAsOption) => setSettings({ ...settings, showAsOption })}
              />
              <Switch
                inputId={'show-as-button'}
                label={t('storyCardCategory.show-as-button')}
                className={classes['category__row-switch']}
                checked={showAsButton}
                onChange={(showAsButton) => setSettings({ ...settings, showAsButton })}
              />
            </div>

            <div className={classes['category__row']}>
              <IconLabel
                iconId={'gallery'}
                iconSize={20}
                label={t('storyCardCategory.templates-library')}
                color={getCssVar('--base-link-text-color')}
                hoverColor={getCssVar('--base-link-text-hover-color')}
                onClick={() => setIsTemplatesModalOpen(true)}
              />

              <IconLabel
                iconId={'arrow-up'}
                iconSize={20}
                label={t('storyCardCategory.upload-custom-template')}
                color={getCssVar('--base-link-text-color')}
                hoverColor={getCssVar('--base-link-text-hover-color')}
                onClick={() => setCustomTemplateIndex(-1)}
              />
            </div>

            <div className={classes['category__templates']}>
              {templates.map(({ gallery, name, text }, index) => {
                return (
                  <div key={`template-${index}`} className={classes['category__templates-item']}>
                    <div
                      className={classes['category__templates-item-image']}
                      style={{ backgroundImage: `url(${gallery[0].image.url})` }}
                    ></div>
                    <div className={classes['category__templates-item-info']}>
                      <div className={classes['category__templates-item-info-name']}>{name}</div>
                      <div className={classes['category__templates-item-info-text']}>
                        {text.replace(/(.{140})..+/, '$1…')}
                      </div>
                    </div>

                    <IconLabel
                      className={classes['category__templates-item-edit']}
                      iconId={'edit'}
                      iconSize={18}
                      color={getCssVar('--base-link-text-color')}
                      hoverColor={getCssVar('--base-link-text-hover-color')}
                      onClick={() => setCustomTemplateIndex(index)}
                    />

                    <IconLabel
                      iconId={'delete'}
                      iconSize={20}
                      color={getCssVar('--color-danger')}
                      hoverColor={getCssVar('--color-danger-hover')}
                      onClick={() => setDeleteTemplateIndex(index)}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <Button
            className={classes['category__button']}
            type={ButtonType.primary}
            label={submitLabel}
            disabled={submitDisabled}
            onClick={onSubmit}
          />
        </div>
      );
    }, [
      buttonTextDe,
      buttonTextEn,
      editableMedia,
      emailNotification,
      isFetching,
      name,
      onSubmit,
      settings,
      showAsButton,
      showAsOption,
      storyId,
      storyItems,
      submitDisabled,
      submitLabel,
      t,
      templates,
      timeToLive,
      title,
      userSelection,
    ]);

    return (
      <>
        <Modal isOpen={isOpen} body={body} onClose={onClose} alignTop />

        <CategoryTemplatesModal
          isOpen={isTemplatesModalOpen}
          setIsOpen={setIsTemplatesModalOpen}
          templates={templates}
          setTemplates={setTemplates}
          customTemplateIndex={customTemplateIndex}
          setCustomTemplateIndex={setCustomTemplateIndex}
        />

        <ConfirmationModal
          isOpen={deleteTemplateIndex !== null}
          onClose={() => setDeleteTemplateIndex(null)}
          acceptLabel={t('common.delete')}
          onAccept={onTemplateDelete}
          title={t(' storyCardCategoryTemplate.delete-confirmation-title')}
          subTitle={t(' storyCardCategoryTemplate.delete-confirmation-sub-title')}
          danger
        />
      </>
    );
  }
);
