/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FileAcceptType,
  TransloaditAuthTemplate,
  UploadHandlerArgs,
  UploadType,
} from '../../../../hooks';
import { AudioBlockData } from '../../../../models';
import { BaseBlockTool } from '../baseBlockTool';
import { MediaUpload, MediaUploadPreview, ResetBlockTool } from '../helpers';

interface State extends AudioBlockData {
  loading: boolean;
  pasteFile: File | null;
}

type Config = {
  dispatch: any;
  uploadHandler: (args: UploadHandlerArgs) => Promise<{ id: number; assembly_id: string }>;
};

export class AudioTool extends BaseBlockTool<State, AudioBlockData, Config> {
  static get toolbox() {
    return {
      title: 'Audio',
      icon: `
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" fill="white"/>
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" stroke="#EBEBEB"/>
        <path d="M11.6699 18.1648V10.222L19.0018 9V16.9429" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.83297 19.9976C10.8453 19.9976 11.6659 19.177 11.6659 18.1646C11.6659 17.1523 10.8453 16.3317 9.83297 16.3317C8.82065 16.3317 8 17.1523 8 18.1646C8 19.177 8.82065 19.9976 9.83297 19.9976Z" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.1611 18.7758C18.1734 18.7758 18.9941 17.9551 18.9941 16.9428C18.9941 15.9305 18.1734 15.1099 17.1611 15.1099C16.1488 15.1099 15.3281 15.9305 15.3281 16.9428C15.3281 17.9551 16.1488 18.7758 17.1611 18.7758Z" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
    };
  }

  static get pasteConfig() {
    return {
      files: { extensions: FileAcceptType.AUDIO.split(',').map((ext) => ext.replace(/^\./, '')) },
    };
  }

  onPaste(event: any) {
    if (event.type !== 'file') {
      return;
    }

    this.setState({ pasteFile: event.detail.file });
  }

  getJSXSettings(): JSX.Element | null {
    if (!this.state.items.length) {
      return null;
    }

    return <ResetBlockTool onClick={() => this.setState({ items: [] })} />;
  }

  getJSXTool(): JSX.Element {
    const { loading, items, pasteFile } = this.state;

    const { audio = null } = { ...items[0] };

    if (audio) {
      return <MediaUploadPreview iconId={'audio'} label={this.api.i18n.t('label')} />;
    }

    return (
      <MediaUpload
        {...(this.config as Config)}
        loading={loading}
        setLoading={(loading) => this.setState({ loading })}
        items={items}
        setItems={(items) => this.setState({ items } as AudioBlockData)}
        pasteFile={pasteFile}
        template={TransloaditAuthTemplate.FILE}
        uploadType={UploadType.AUDIO}
        fileAcceptType={FileAcceptType.AUDIO}
      />
    );
  }

  getDefaultState(data?: AudioBlockData): State {
    return { items: data?.items ?? [], loading: false, pasteFile: null };
  }

  validate({ items }: AudioBlockData): boolean {
    return Boolean(items.length);
  }

  save(): AudioBlockData {
    return { items: this.state.items };
  }
}
