import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';
import { StoriesCardsUserFeedFilter, StoryCardStatus } from '../../../services';
import {
  DATE_RANGE_GROUP_ID,
  Filter,
  FilterListAlignment,
  FilterValue,
  TabItem,
  Tabs,
  USER_FEED_COOKIE_KEY,
  cookieOptions,
  optArrItem,
  useAppDispatch,
  useCardTypeFilter,
  useDateRangeFilter,
  useFilter,
} from '../../../shared';
import { getStoriesCardsUserFeed } from '../../../slices';

import classes from './UserFeedCommon.module.scss';

export enum UserFeedTabType {
  PUBLISHED,
  SCHEDULED,
  UNPUBLISHED,
}

interface UserFeedCommonProps {
  userId: number;
  userFeedComponent: JSX.Element;
  me?: boolean;
}

export const UserFeedCommon: FunctionComponent<UserFeedCommonProps> = memo(
  ({ userId, userFeedComponent, me = true }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const [cookies, setCookie] = useCookies([USER_FEED_COOKIE_KEY]);

    const status = useMemo(
      () => cookies[USER_FEED_COOKIE_KEY]?.requestFilter?.status ?? StoryCardStatus.PUBLISHED,
      [cookies]
    );

    const [filter, setFilter] = useState<FilterValue[]>(
      cookies[USER_FEED_COOKIE_KEY]?.filter ?? []
    );

    const selectedTabIndex = useMemo(
      () => (me ? Object.keys(StoryCardStatus).indexOf(status) : 0),
      [me, status]
    );

    const { getGroupValue, getDateRangeValue } = useFilter();

    const { group: dateRangeGroup } = useDateRangeFilter({ filter });

    const { group: cardTypeGroup } = useCardTypeFilter();

    const TYPE = useMemo(() => cardTypeGroup.id, [cardTypeGroup.id]);

    const groups = useMemo(() => [dateRangeGroup, cardTypeGroup], [cardTypeGroup, dateRangeGroup]);

    const onTabChange = useCallback(
      (index: number) => {
        const requestFilter = {
          ...cookies[USER_FEED_COOKIE_KEY]?.requestFilter,
          status: Object.values(StoryCardStatus)[index],
        };

        setCookie(
          USER_FEED_COOKIE_KEY,
          { requestFilter, filter: cookies[USER_FEED_COOKIE_KEY]?.filter },
          cookieOptions()
        );

        dispatch(getStoriesCardsUserFeed({ channelId, userId, filter: requestFilter }));
      },
      [channelId, cookies, dispatch, setCookie, userId]
    );

    const filterChange = useCallback(
      (filter: FilterValue[]) => {
        const { dateRange, from, to } = getDateRangeValue({ filter, groupId: DATE_RANGE_GROUP_ID });

        const storyCardType = getGroupValue({ filter, groupId: TYPE, multiCheck: true });

        const requestFilterToSave: StoriesCardsUserFeedFilter = {
          ...(storyCardType?.length && { storyCardType }),
          dateRange,
          status,
        };

        const requestFilter: StoriesCardsUserFeedFilter = {
          ...requestFilterToSave,
          ...(from && { from }),
          ...(to && { to }),
        };

        setCookie(
          USER_FEED_COOKIE_KEY,
          { requestFilter: requestFilterToSave, filter },
          cookieOptions()
        );

        dispatch(getStoriesCardsUserFeed({ channelId, userId, filter: requestFilter }));

        setFilter(filter);
      },
      [TYPE, channelId, dispatch, getDateRangeValue, getGroupValue, setCookie, status, userId]
    );

    const tabContent = useMemo(() => {
      return <div className={classes['user-feed__tab-content']}>{userFeedComponent}</div>;
    }, [userFeedComponent]);

    const appliedFiltersCount = useMemo(
      () => filter.reduce((res, { groupItems }) => res + (groupItems ?? []).length, 0),
      [filter]
    );

    const tabItems: TabItem[] = useMemo(
      () => [
        {
          index: UserFeedTabType.PUBLISHED,
          name: t('userFeed.tab1-title'),
          content: tabContent,
        },
        ...optArrItem(me, {
          index: UserFeedTabType.SCHEDULED,
          name: t('userFeed.tab2-title'),
          content: tabContent,
        }),
        ...optArrItem(me, {
          index: UserFeedTabType.UNPUBLISHED,
          name: t('userFeed.tab3-title'),
          content: tabContent,
        }),
      ],
      [me, t, tabContent]
    );

    return (
      <div className={classes['user-feed']}>
        <Filter
          iconId={'filter'}
          className={classes['user-feed__filter']}
          label={`${!isMobileOnly ? t('common.filter') : ''} (${appliedFiltersCount})`}
          groups={groups}
          filter={filter}
          onChange={filterChange}
          listAlignment={FilterListAlignment.RIGHT}
        />
        <Tabs
          items={tabItems}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={onTabChange}
          noBackground
        />
      </div>
    );
  }
);
