import { StoryCardType } from '../../services';
import { GalleryItem } from '../models';

interface StoryCardPreviewUrlProps {
  type?: StoryCardType;
  galleryItem?: GalleryItem;
}

export const getStoryCardPreviewUrl = ({ type, galleryItem }: StoryCardPreviewUrlProps) => {
  const defaultUrl = galleryItem?.image?.url;

  switch (type) {
    case StoryCardType.IMAGE:
    case StoryCardType.QUOTE:
    case StoryCardType.POST:
    case StoryCardType.ARTICLE:
    case StoryCardType.PDF:
      return defaultUrl;
    case StoryCardType.AUDIO:
      return defaultUrl ?? galleryItem?.audio?.thumb;
    case StoryCardType.VIDEO:
      return defaultUrl ?? galleryItem?.video?.thumb;
    default:
      return '';
  }
};
