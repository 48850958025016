import {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FeedCardPreview } from '../../../components';
import { ConfigContext, UserContext } from '../../../context';
import {
  ChannelRole,
  InAppMessageChannelCreateArgs,
  PushNotificationChannelCreateArgs,
  PushNotificationOrganisationCreateArgs,
  StoryCard,
  StoryCardContentArticlePost,
  StoryCardContentText,
  StoryCardQuoteContent,
  StoryCardThreadContent,
  StoryCardType,
  StoryCardsFeedPayload,
  notificationsApi,
  storyCardApi,
  useGetStoriesByChannelIdQuery,
  useOrganisationUsersFiltersQuery,
} from '../../../services';
import {
  Button,
  ButtonType,
  ColorPicker,
  FileAcceptType,
  FilePreview,
  FileUpload,
  GalleryItem,
  InputField,
  Modal,
  MultiStepFilter,
  MultiStepFilterData,
  MultiStepFilterItemType,
  MultiStepFilterValue,
  OrderDirection,
  ScheduleInput,
  Select,
  SelectItem,
  TextAreaField,
  isUrl,
  useAppDispatch,
  useMultiStepFilter,
} from '../../../shared';
import { getInAppMessages, getPushNotifications } from '../../../slices';
import { NotificationsType } from '../Notifications';

import classes from './CreateNotificationModal.module.scss';

interface CreateStoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: NotificationsType;
  organisationPushNotificationAllowToCreate: boolean;
  query: string;
  orderDirection: OrderDirection;
  card?: StoryCard;
}

export const PRIORITY = 'priority';
export const DELIVERY = 'delivery';

const PUSH_TITLE_MIN_LIMIT = 4;
const PUSH_TITLE_MAX_LIMIT = 50;
const PUSH_MESSAGE_MIN_LIMIT = 1;
const PUSH_MESSAGE_MAX_LIMIT = 160;

const INAPP_TITLE_MIN_LIMIT = 4;
const INAPP_TITLE_MAX_LIMIT = 120;
const INAPP_MESSAGE_MIN_LIMIT = 4;
const INAPP_MESSAGE_MAX_LIMIT = 300;

const INAPP_BUTTON_MIN_LIMIT = 2;
const INAPP_BUTTON_MAX_LIMIT = 20;

enum PinContentType {
  NONE = 'NONE',
  URL = 'URL',
  CARD = 'CARD',
}

enum RecipientType {
  CHANNEL = 'CHANNEL',
  ORG = 'ORG',
}

enum InAppRecipientType {
  CHANNEL = 'CHANNEL',
  USER = 'USER',
}

export enum ButtonLinkType {
  CANCEL = 'CANCEL',
  URL_APP = 'URL_APP',
  URL_BROWSER = 'URL_BROWSER',
  SHARE = 'SHARE',
}

enum ExpirationHours {
  HALF_DAY = 12,
  DAY = 24,
  TWO_DAYS = 48,
  ONE_WEEK = 168,
  ONE_YEAR = 8760,
}

const AVATAR_GROUP_ID = 'avatar';
const ROLES_GROUP_ID = 'roles';
const POSITION_GROUP_ID = 'position';
const DEPARTMENT_GROUP_ID = 'department';

export const CreateNotificationModal: FunctionComponent<CreateStoryModalProps> = memo(
  ({
    isOpen,
    onClose,
    type,
    organisationPushNotificationAllowToCreate,
    query,
    orderDirection,
    card,
  }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const {
      textColor: inAppTextColor,
      backgroundColor: inAppBackgroundColor,
      buttonTextColor: inAppButtonTextColor,
      buttonBackgroundColor: inAppButtonBackgroundColor,
    } = useContext(ConfigContext).config.elements.inAppNotifications;

    const navigate = useNavigate();

    const { search } = useLocation();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const [storyCardsFeedTrigger] = storyCardApi.endpoints.storyCardsFeed.useLazyQuery();

    const [pushNotificationChannelCreate] =
      notificationsApi.endpoints.pushNotificationChannelCreate.useLazyQuery();

    const [pushNotificationOrganisationCreate] =
      notificationsApi.endpoints.pushNotificationOrganisationCreate.useLazyQuery();

    const [inAppMessageChannelCreate] =
      notificationsApi.endpoints.inAppMessageChannelCreate.useLazyQuery();

    const isPush = useMemo(() => type === NotificationsType.PUSH, [type]);

    const [pushCard, setPushCard] = useState<StoryCard | undefined>(card);

    const [loading, setLoading] = useState<boolean>(false);

    const [galleryItems, setGalleryItems] = useState<GalleryItem[]>([]);

    const inputDefaults = useMemo(() => {
      if (!pushCard) {
        return ['', ''];
      }

      const { type, content } = pushCard;

      switch (type) {
        case StoryCardType.EDITORIAL:
        case StoryCardType.IMAGE:
        case StoryCardType.AUDIO:
        case StoryCardType.VIDEO:
        case StoryCardType.PDF: {
          const { headline, text } = content as StoryCardContentText;
          return [headline, text];
        }
        case StoryCardType.THREAD: {
          const { title } = content as StoryCardThreadContent;
          return [title, ''];
        }
        case StoryCardType.ARTICLE:
        case StoryCardType.POST: {
          const { title, abstract } = content as StoryCardContentArticlePost;
          return [title, abstract];
        }
        case StoryCardType.QUOTE: {
          const { quote, quotePerson, quoteSource } = content as StoryCardQuoteContent;
          return [`${quotePerson} via ${quoteSource?.toLowerCase()}`, quote];
        }
        default:
          return ['', ''];
      }
    }, [pushCard]);

    const [titleDefault, messageDefault] = inputDefaults;

    const [title, setTitle] = useState<string>(titleDefault.substring(0, PUSH_TITLE_MAX_LIMIT));

    const [message, setMessage] = useState<string>(
      messageDefault.substring(0, PUSH_MESSAGE_MAX_LIMIT)
    );

    const [storyCardsFeed, setStoryCardsFeed] = useState<StoryCardsFeedPayload | null>(null);

    const [recipient, setRecipient] = useState(RecipientType.CHANNEL);

    const recipientTypeItems = useMemo(() => {
      return Object.keys(RecipientType).map((type) => {
        return { id: type, title: t(`createNotificationModal.recipient-${type}`) };
      });
    }, [t]);

    const [pinContentType, setPinContentType] = useState(PinContentType.NONE);

    const pinContentTypeItems = useMemo(() => {
      return Object.keys(PinContentType)
        .map((type) => {
          return { id: type, title: t(`createNotificationModal.pin-content-${type}`) };
        })
        .filter(
          ({ id }) =>
            recipient === RecipientType.CHANNEL ||
            (recipient === RecipientType.ORG && id !== PinContentType.CARD)
        );
    }, [recipient, t]);

    const [backgroundColor, setBackgroundColor] = useState<string>(inAppBackgroundColor);

    const [textColor, setTextColor] = useState<string>(inAppTextColor);

    const [buttonBackgroundColor, setButtonBackgroundColor] = useState<string>(
      inAppButtonBackgroundColor
    );

    const [buttonTextColor, setButtonTextColor] = useState<string>(inAppButtonTextColor);

    const [buttonText, setButtonText] = useState<string>('');

    const [buttonLinkType, setButtonLinkType] = useState<ButtonLinkType>(ButtonLinkType.CANCEL);

    const buttonLinkTypeItems = useMemo(() => {
      return Object.keys(ButtonLinkType).map((type) => {
        return {
          id: type,
          title: t(`createNotificationModal.button-link-${type}`),
          hint: t(`createNotificationModal.button-link-hint-${type}`),
        };
      });
    }, [t]);

    const [expirationHours, setExpirationHours] = useState<number>(ExpirationHours.HALF_DAY);

    const expirationHoursItems = useMemo(() => {
      return Object.values(ExpirationHours)
        .filter((value) => Number(value))
        .map((hr) => {
          return { id: hr, title: t(`createNotificationModal.expiration-hours-${hr}`) };
        });
    }, [t]);

    const [inAppRecipientType, setInAppRecipientType] = useState(InAppRecipientType.CHANNEL);

    const inAppRecipientTypeItems = useMemo(() => {
      return Object.keys(InAppRecipientType).map((type) => {
        return { id: type, title: t(`createNotificationModal.in-app-recipient-${type}`) };
      });
    }, [t]);

    const isUserRecipientType = useMemo(
      () => inAppRecipientType === InAppRecipientType.USER,
      [inAppRecipientType]
    );

    const { data: orgUsersFilters, isFetching: isFetchingOrgUsersFilters } =
      useOrganisationUsersFiltersQuery(
        { filters: { sections: [] } },
        { skip: !isUserRecipientType }
      );

    const orgUsersFiltersData = useMemo(() => {
      if (!orgUsersFilters) {
        return [];
      }

      return orgUsersFilters
        .filter(({ values }) => values.length)
        .map(({ name, values }) => {
          return {
            title: t(`createNotificationModal.${name}`),
            items: [
              {
                groupId: name,
                value: null,
                title: t('common.empty'),
                itemType: MultiStepFilterItemType.CHECK,
              },
              ...values.map(({ value }) => {
                return {
                  groupId: name,
                  value,
                  title: value,
                  itemType: MultiStepFilterItemType.CHECK,
                };
              }),
            ],
          };
        });
    }, [orgUsersFilters, t]);

    const [userFilter, setUserFilter] = useState<MultiStepFilterValue[]>([]);

    const { getGroupValue } = useMultiStepFilter();

    const userFilterData: MultiStepFilterData = useMemo(() => {
      return {
        title: t('createNotificationModal.filter-setup'),
        items: [
          {
            title: t(`createNotificationModal.${ROLES_GROUP_ID}`),
            items: [
              {
                groupId: ROLES_GROUP_ID,
                value: ChannelRole.EDITOR_LIMITED,
                title: t(`${ROLES_GROUP_ID}.editor-limited`),
                itemType: MultiStepFilterItemType.CHECK,
              },
              {
                groupId: ROLES_GROUP_ID,
                value: ChannelRole.READER,
                title: t(`${ROLES_GROUP_ID}.reader`),
                itemType: MultiStepFilterItemType.CHECK,
              },
              {
                groupId: ROLES_GROUP_ID,
                value: ChannelRole.EDITOR,
                title: t(`${ROLES_GROUP_ID}.editor`),
                itemType: MultiStepFilterItemType.CHECK,
              },
              {
                groupId: ROLES_GROUP_ID,
                value: ChannelRole.ADMIN,
                title: t(`${ROLES_GROUP_ID}.admin`),
                itemType: MultiStepFilterItemType.CHECK,
              },
            ],
          },
          {
            title: t('createNotificationModal.user-profile'),
            items: [
              {
                title: t(`createNotificationModal.${AVATAR_GROUP_ID}`),
                items: [
                  {
                    groupId: AVATAR_GROUP_ID,
                    value: true,
                    title: t(`createNotificationModal.with-${AVATAR_GROUP_ID}`),
                    itemType: MultiStepFilterItemType.RADIO,
                  },
                  {
                    groupId: AVATAR_GROUP_ID,
                    value: false,
                    title: t(`createNotificationModal.without-${AVATAR_GROUP_ID}`),
                    itemType: MultiStepFilterItemType.RADIO,
                  },
                ],
              },
              ...orgUsersFiltersData,
            ],
          },
        ],
      };
    }, [orgUsersFiltersData, t]);

    const { data: stories, isFetching: isFetchingStories } = useGetStoriesByChannelIdQuery(
      { channelId },
      { skip: pinContentType !== PinContentType.CARD }
    );

    const nonEmptyStories = useMemo(
      () => stories?.filter(({ cardsCount }) => Boolean(cardsCount)),
      [stories]
    );

    const [selectedStoryId, setSelectedStoryId] = useState(pushCard?.storyId ?? 0);

    const [selectedCardId, setSelectedCardId] = useState(pushCard?.id ?? 0);

    const storyItems = useMemo(() => {
      return (
        nonEmptyStories?.map(({ id, title }) => {
          return { id, title } as SelectItem;
        }) ?? []
      );
    }, [nonEmptyStories]);

    useEffect(() => {
      if (recipient === RecipientType.ORG) {
        setSelectedStoryId(0);
        setSelectedCardId(0);

        if (pinContentType === PinContentType.CARD) {
          setPinContentType(PinContentType.NONE);
        }
      }
    }, [pinContentType, recipient]);

    useEffect(() => {
      if (nonEmptyStories?.length && !selectedStoryId) {
        setSelectedStoryId(nonEmptyStories[0].id);
      }
    }, [nonEmptyStories, selectedStoryId]);

    const getStoryCardsFeed = useCallback(async () => {
      setStoryCardsFeed(null);

      const cardsFeed = await storyCardsFeedTrigger({ storyId: selectedStoryId }).unwrap();

      setStoryCardsFeed(cardsFeed);

      setSelectedCardId(cardsFeed.items[0].id);

      setTimeout(() => {
        document.querySelector('#swiper')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);
    }, [selectedStoryId, storyCardsFeedTrigger]);

    useEffect(() => {
      if (pushCard || !selectedStoryId) {
        return;
      }

      getStoryCardsFeed();
    }, [getStoryCardsFeed, pushCard, selectedStoryId]);

    const hasStoryCards = useMemo(
      () => Boolean(storyCardsFeed?.items.length),
      [storyCardsFeed?.items.length]
    );

    const [postingTime, setPostingTime] = useState<Date | null>(null);

    const [url, setUrl] = useState<string>('');

    const [urlError, setUrlError] = useState<boolean>(false);

    const [imageAspectRatioError, setImageAspectRatioError] = useState<boolean>(false);

    const urlRef = useRef<HTMLInputElement>(null);

    const createButtonLabel = useMemo(() => {
      switch (true) {
        case Boolean(postingTime):
          return t('common.schedule');
        default:
          return t('common.send-now');
      }
    }, [postingTime, t]);

    const createButtonDisabled = useMemo(() => {
      if (isPush) {
        return Boolean(
          title.trim().length < PUSH_TITLE_MIN_LIMIT ||
            message.trim().length < PUSH_MESSAGE_MIN_LIMIT ||
            (pinContentType === PinContentType.URL && urlError) ||
            (pinContentType === PinContentType.CARD && !hasStoryCards)
        );
      }

      return Boolean(
        title.trim().length < INAPP_TITLE_MIN_LIMIT ||
          message.trim().length < INAPP_MESSAGE_MIN_LIMIT ||
          buttonText.trim().length < INAPP_BUTTON_MIN_LIMIT ||
          (buttonLinkType !== ButtonLinkType.CANCEL && urlError) ||
          loading ||
          imageAspectRatioError
      );
    }, [
      buttonLinkType,
      buttonText,
      hasStoryCards,
      imageAspectRatioError,
      isPush,
      loading,
      message,
      pinContentType,
      title,
      urlError,
    ]);

    useEffect(() => {
      if (!pushCard && pinContentType !== PinContentType.CARD) {
        setSelectedStoryId(0);
        setSelectedCardId(0);
      }
    }, [pinContentType, pushCard]);

    useEffect(() => {
      if (inAppRecipientType !== InAppRecipientType.USER) {
        setUserFilter([]);
      }
    }, [inAppRecipientType]);

    useEffect(() => {
      if (urlRef.current) {
        urlRef.current.focus();
      }
    }, [pinContentType, buttonLinkType]);

    useEffect(() => {
      if (galleryItems.length && galleryItems[0].image) {
        const src = galleryItems[0].image.url;
        const image = new Image();
        image.onload = () => {
          setImageAspectRatioError(image.naturalWidth / image.naturalHeight < 0.75);
        };
        image.src = src;
      }

      setImageAspectRatioError(false);
    }, [galleryItems]);

    const urlChangeHandler = useCallback((value: string) => {
      setUrlError(false);
      setUrl(value);
    }, []);

    const urlBlurHandler = useCallback(() => {
      if (!isUrl(url)) {
        setUrlError(true);
      }
    }, [url]);

    const onSlideChangeHandler = useCallback(
      async (activeIndex: number) => {
        if (!storyCardsFeed) {
          return;
        }

        setSelectedCardId(storyCardsFeed.items[activeIndex].id);

        const { items, pageInfo } = storyCardsFeed;

        const { perPage, hasNextPage } = pageInfo;

        const index = activeIndex + 1;

        if (index >= items.length && hasNextPage) {
          const { items, pageInfo } = await storyCardsFeedTrigger({
            storyId: selectedStoryId,
            page: Math.floor(index / perPage) + 1,
            size: perPage,
          }).unwrap();

          setStoryCardsFeed({ items: [...storyCardsFeed.items, ...items], pageInfo });
        }
      },
      [selectedStoryId, storyCardsFeed, storyCardsFeedTrigger]
    );

    const pinContentTypeComponents = useMemo(() => {
      switch (pinContentType) {
        case PinContentType.URL:
          return (
            <InputField
              ref={urlRef}
              value={url}
              label={t('common.url')}
              onChange={({ target }) => urlChangeHandler(target.value)}
              placeholder={t('createNotificationModal.url-placeholder')}
              onBlur={urlBlurHandler}
              {...(urlError && { error: true, errorMessage: t('common.url-invalid') })}
            />
          );
        case PinContentType.CARD:
          return (
            <>
              {Boolean(selectedStoryId) && (
                <Select
                  label={t('common.story')}
                  selectedItemId={selectedStoryId}
                  items={storyItems}
                  disabled={isFetchingStories}
                  listMaxHeightRem={12.5}
                  onChange={(value) => setSelectedStoryId(value as number)}
                  staticListPosition
                  search
                />
              )}
              {hasStoryCards && (
                <Swiper
                  id={'swiper'}
                  navigation
                  keyboard={{ enabled: true, onlyInViewport: true }}
                  slidesPerView={1}
                  spaceBetween={32}
                  modules={[Navigation, Keyboard]}
                  style={{ overflow: 'visible' }}
                  onSlideChange={({ activeIndex }) => onSlideChangeHandler(activeIndex)}
                >
                  {storyCardsFeed?.items.map((card) => (
                    <SwiperSlide key={card.id}>
                      <FeedCardPreview card={card} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </>
          );
        default:
          return null;
      }
    }, [
      hasStoryCards,
      isFetchingStories,
      onSlideChangeHandler,
      pinContentType,
      selectedStoryId,
      storyCardsFeed?.items,
      storyItems,
      t,
      url,
      urlBlurHandler,
      urlChangeHandler,
      urlError,
    ]);

    const createNotification = useCallback(async () => {
      onClose();

      try {
        let notification;

        if (isPush) {
          const pushOrgFields: PushNotificationOrganisationCreateArgs = {
            title,
            message,
            url,
            deliveryTime: postingTime?.toISOString(),
          };

          const pushChannelFields: PushNotificationChannelCreateArgs = {
            ...pushOrgFields,
            channelId,
            storyId: selectedStoryId,
            itemId: selectedCardId,
          };

          notification = await toast.promise(
            (recipient === RecipientType.CHANNEL
              ? pushNotificationChannelCreate(pushChannelFields)
              : pushNotificationOrganisationCreate(pushOrgFields)
            ).unwrap(),
            {
              pending: t(`createNotificationModal.create-pending-push`),
              success: t(`createNotificationModal.create-success-push`),
              error: t(`createNotificationModal.create-error-push`),
            }
          );
        } else {
          const avatar = getGroupValue({ filter: userFilter, groupId: AVATAR_GROUP_ID });

          const roles = getGroupValue({
            filter: userFilter,
            groupId: ROLES_GROUP_ID,
            multiCheck: true,
          });

          const department = getGroupValue({
            filter: userFilter,
            groupId: DEPARTMENT_GROUP_ID,
            multiCheck: true,
          });

          const position = getGroupValue({
            filter: userFilter,
            groupId: POSITION_GROUP_ID,
            multiCheck: true,
          });

          const inAppChannelFields: InAppMessageChannelCreateArgs = {
            channelId,
            title,
            intro: message,
            url,
            textColor,
            bgColor: backgroundColor,
            expirationHours,
            buttonLinkType,
            buttonText,
            buttonTextColor,
            buttonBackgroundColor,
            deliveryTime: postingTime?.toISOString(),
            imageId: galleryItems[0]?.image?.id,
            ...(typeof avatar !== 'undefined' && { avatar }),
            ...(roles?.length && { roles }),
            ...(department?.length && { department }),
            ...(position?.length && { position }),
          };

          notification = await toast.promise(
            inAppMessageChannelCreate(inAppChannelFields).unwrap(),
            {
              pending: t(`createNotificationModal.create-pending-in-app`),
              success: t(`createNotificationModal.create-success-in-app`),
              error: t(`createNotificationModal.create-error-in-app`),
            }
          );
        }

        if (!notification) {
          return;
        }

        if (Boolean(search)) {
          navigate('/admin/notifications');
        }

        if (isPush) {
          dispatch(getPushNotifications({ channelId, orderDirection, filters: { query } }));
          return;
        }

        dispatch(getInAppMessages({ channelId, orderDirection, filters: { query } }));
      } catch (_) {}
    }, [
      backgroundColor,
      buttonBackgroundColor,
      buttonLinkType,
      buttonText,
      buttonTextColor,
      channelId,
      dispatch,
      expirationHours,
      galleryItems,
      getGroupValue,
      inAppMessageChannelCreate,
      isPush,
      message,
      navigate,
      onClose,
      orderDirection,
      postingTime,
      pushNotificationChannelCreate,
      pushNotificationOrganisationCreate,
      query,
      recipient,
      search,
      selectedCardId,
      selectedStoryId,
      t,
      textColor,
      title,
      url,
      userFilter,
    ]);

    const pushCardCancellation = useCallback(() => {
      setPushCard(undefined);
      setSelectedStoryId(0);
      setSelectedCardId(0);
    }, []);

    const pushCardRelatedContent = useMemo(() => {
      if (!pushCard) {
        return (
          <>
            {organisationPushNotificationAllowToCreate && (
              <Select
                label={t('createNotificationModal.recipient')}
                selectedItemId={recipient}
                items={recipientTypeItems}
                onChange={(value) => setRecipient(value as RecipientType)}
                staticListPosition
              />
            )}

            <Select
              key={pinContentTypeItems.map(({ id }) => id).join(',')}
              label={t('createNotificationModal.pin-content')}
              selectedItemId={pinContentType}
              items={pinContentTypeItems}
              onChange={(value) => setPinContentType(value as PinContentType)}
              staticListPosition
            />

            {pinContentTypeComponents}
          </>
        );
      }

      return (
        <div className={classes['create-notification__pushCard']}>
          <FeedCardPreview card={pushCard} />
          <span
            className={classes['create-notification__pushCard-cancel']}
            onClick={pushCardCancellation}
          ></span>
        </div>
      );
    }, [
      organisationPushNotificationAllowToCreate,
      pinContentType,
      pinContentTypeComponents,
      pinContentTypeItems,
      pushCard,
      pushCardCancellation,
      recipient,
      recipientTypeItems,
      t,
    ]);

    const createNotificationContent = useMemo(() => {
      if (isPush) {
        return (
          <>
            <TextAreaField
              label={t('createNotificationModal.title-label')}
              placeholder={t('createNotificationModal.title-placeholder')}
              value={title}
              minLength={PUSH_TITLE_MIN_LIMIT}
              maxLength={PUSH_TITLE_MAX_LIMIT}
              onChange={({ target }) => setTitle(target.value)}
              disableLineBreak
            />
            <TextAreaField
              label={t('createNotificationModal.message-label')}
              placeholder={t('createNotificationModal.message-placeholder')}
              value={message}
              minLength={PUSH_MESSAGE_MIN_LIMIT}
              maxLength={PUSH_MESSAGE_MAX_LIMIT}
              onChange={({ target }) => setMessage(target.value)}
              minRows={2}
              disableLineBreak
            />

            {pushCardRelatedContent}
          </>
        );
      }

      return (
        <>
          <FileUpload
            galleryItems={galleryItems}
            setGalleryItems={setGalleryItems}
            loading={loading}
            setLoading={setLoading}
            fileAcceptTypes={[FileAcceptType.IMAGE]}
            uploadLimit={1}
          />

          <FilePreview galleryItems={galleryItems} setGalleryItems={setGalleryItems} />

          {imageAspectRatioError && (
            <span className={classes['create-notification__error']}>
              {t('createNotificationModal.image-aspect-ratio-error')}
            </span>
          )}

          <TextAreaField
            label={t('createNotificationModal.title-label')}
            placeholder={t('createNotificationModal.title-placeholder')}
            value={title}
            minLength={INAPP_TITLE_MIN_LIMIT}
            maxLength={INAPP_TITLE_MAX_LIMIT}
            onChange={({ target }) => setTitle(target.value)}
            disableLineBreak
          />

          <TextAreaField
            label={t('createNotificationModal.message-label')}
            placeholder={t('createNotificationModal.message-placeholder')}
            value={message}
            minLength={INAPP_MESSAGE_MIN_LIMIT}
            maxLength={INAPP_MESSAGE_MAX_LIMIT}
            onChange={({ target }) => setMessage(target.value)}
            minRows={2}
            disableLineBreak
          />

          <div className={classes['create-notification__colors']}>
            <div className={classes['create-notification__colors-item']}>
              <ColorPicker
                value={backgroundColor}
                onChange={setBackgroundColor}
                label={t('createNotificationModal.background-color')}
              />
            </div>
            <div className={classes['create-notification__colors-item']}>
              <ColorPicker
                value={textColor}
                onChange={setTextColor}
                label={t('createNotificationModal.text-color')}
              />
            </div>
          </div>

          <div className={classes['create-notification__colors']}>
            <div className={classes['create-notification__colors-item']}>
              <ColorPicker
                value={buttonBackgroundColor}
                onChange={setButtonBackgroundColor}
                label={t('createNotificationModal.button-background-color')}
              />
            </div>

            <div className={classes['create-notification__colors-item']}>
              <ColorPicker
                value={buttonTextColor}
                onChange={setButtonTextColor}
                label={t('createNotificationModal.button-text-color')}
              />
            </div>
          </div>

          <TextAreaField
            inputClassName={classes['create-notification__input']}
            label={t('createNotificationModal.button-label')}
            placeholder={t('createNotificationModal.button-placeholder')}
            value={buttonText}
            minLength={INAPP_BUTTON_MIN_LIMIT}
            maxLength={INAPP_BUTTON_MAX_LIMIT}
            onChange={({ target }) => setButtonText(target.value)}
            disableLineBreak
          />

          <Select
            label={t('createNotificationModal.button-link')}
            selectedItemId={buttonLinkType}
            items={buttonLinkTypeItems}
            onChange={(value) => setButtonLinkType(value as ButtonLinkType)}
            staticListPosition
          />

          {buttonLinkType !== ButtonLinkType.CANCEL && (
            <InputField
              ref={urlRef}
              value={url}
              label={t('common.url')}
              onChange={({ target }) => urlChangeHandler(target.value)}
              placeholder={t('createNotificationModal.url-placeholder')}
              onBlur={urlBlurHandler}
              {...(urlError && { error: true, errorMessage: t('common.url-invalid') })}
            />
          )}

          <Select
            label={t('createNotificationModal.expiration-time')}
            selectedItemId={expirationHours}
            items={expirationHoursItems}
            onChange={(value) => setExpirationHours(value as number)}
            staticListPosition
          />

          <Select
            label={t('createNotificationModal.recipient')}
            selectedItemId={inAppRecipientType}
            items={inAppRecipientTypeItems}
            onChange={(value) => setInAppRecipientType(value as InAppRecipientType)}
            staticListPosition
          />

          {isUserRecipientType && (
            <MultiStepFilter
              key={JSON.stringify(userFilterData)}
              filter={userFilter}
              onChange={setUserFilter}
              data={userFilterData}
              groupIdLocaliseKey={'createNotificationModal'}
              disabled={isFetchingOrgUsersFilters}
            />
          )}
        </>
      );
    }, [
      backgroundColor,
      buttonBackgroundColor,
      buttonLinkType,
      buttonLinkTypeItems,
      buttonText,
      buttonTextColor,
      expirationHours,
      expirationHoursItems,
      galleryItems,
      imageAspectRatioError,
      inAppRecipientType,
      inAppRecipientTypeItems,
      isFetchingOrgUsersFilters,
      isPush,
      isUserRecipientType,
      loading,
      message,
      pushCardRelatedContent,
      t,
      textColor,
      title,
      url,
      urlBlurHandler,
      urlChangeHandler,
      urlError,
      userFilter,
      userFilterData,
    ]);

    const body = useMemo(() => {
      return (
        <div className={classes['create-notification']}>
          <div className={classes['create-notification__title']}>
            {t('createNotificationModal.add-headline', { type: t(`notificationsPage.${type}`) })}
          </div>

          <div className={classes['create-notification__content']}>{createNotificationContent}</div>

          <div className={classes['create-notification__buttons']}>
            <ScheduleInput
              postingTime={postingTime}
              setPostingTime={setPostingTime}
              title={`${t('common.schedule')} ${t(`notificationsPage.${type}`)}`}
              subTitle={t('createNotificationModal.schedule-description', { type })}
            />
            <Button
              type={ButtonType.primary}
              label={createButtonLabel}
              disabled={createButtonDisabled}
              onClick={createNotification}
            />
          </div>
        </div>
      );
    }, [
      createButtonDisabled,
      createButtonLabel,
      createNotification,
      createNotificationContent,
      postingTime,
      t,
      type,
    ]);

    return <Modal isOpen={isOpen} body={body} onClose={onClose} alignTop />;
  }
);
