import { useTranslation } from 'react-i18next';
import { StoryCardType } from '../../../services';

const GROUP_ID = 'card-type';

export const useCardTypeFilter = () => {
  const { t } = useTranslation();

  return {
    group: {
      id: GROUP_ID,
      title: t(`common.${GROUP_ID}`),
      multiCheck: true,
      allowReset: true,
      items: [
        {
          id: StoryCardType.ARTICLE,
          label: t('cardTypes.article'),
          value: StoryCardType.ARTICLE,
        },
        {
          id: StoryCardType.QUOTE,
          label: t('cardTypes.quote'),
          value: StoryCardType.QUOTE,
        },
        {
          id: StoryCardType.EDITORIAL,
          label: t('cardTypes.editorial'),
          value: StoryCardType.EDITORIAL,
        },

        {
          id: StoryCardType.IMAGE,
          label: t('cardTypes.image'),
          value: StoryCardType.IMAGE,
        },
        {
          id: StoryCardType.AUDIO,
          label: t('cardTypes.audio'),
          value: StoryCardType.AUDIO,
        },
        {
          id: StoryCardType.VIDEO,
          label: t('cardTypes.video'),
          value: StoryCardType.VIDEO,
        },
        {
          id: StoryCardType.PDF,
          label: t('cardTypes.pdf'),
          value: StoryCardType.PDF,
        },
        {
          id: StoryCardType.THREAD,
          label: t('cardTypes.thread'),
          value: StoryCardType.THREAD,
        },
        {
          id: StoryCardType.POST,
          label: t('cardTypes.post'),
          value: StoryCardType.POST,
        },
      ],
    },
  };
};
