import { useCallback } from 'react';
import {
  ImageUploadArgs,
  TransloaditAssembliesResult,
  TransloaditAssembly,
  TransloaditAssemblyStatus,
  TransloaditAuthArgs,
  TransloaditAuthResult,
  UploadArgs,
  useAudioUploadMutation,
  useFileUploadMutation,
  useImageUploadMutation,
  usePdfUploadMutation,
  useTransloaditAuthMutation,
  useVideoUploadMutation,
} from '../../services';
import { TRANSLOADIT_URL } from '../constants';

export enum FileAcceptType {
  IMAGE = 'image/*',
  AUDIO = '.mp3,.wav,.aiff',
  VIDEO = 'video/*',
  PDF = '.pdf',
  FILE = '.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.rtf,.odt,.ods,.odp',
}

export enum TransloaditAuthTemplate {
  PROFILE_FILE = 'profile-file',
  ITEM_FILE = 'item-file',
  COMMENT_FILE = 'comment-file',
  FILE = 'file',
  ITEM = 'item',
  URL = 'url',
}

export enum UploadType {
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  PDF = 'pdf',
  FILE = 'file',
}

export enum ImageUploadType {
  PROFILE = 'profile',
  ITEM = 'item',
  COMMENT = 'comment',
}

export interface UploadHandlerArgs {
  file?: File;
  url?: string;
  template: TransloaditAuthTemplate;
  type: UploadType;
  imageUploadType?: ImageUploadType;
}

interface UploadCompletedArgs {
  assembly_id: string;
  callback?: () => void;
}

export const useUpload = () => {
  const [transloaditAuth] = useTransloaditAuthMutation();
  const [imageUpload] = useImageUploadMutation();
  const [audioUpload] = useAudioUploadMutation();
  const [videoUpload] = useVideoUploadMutation();
  const [pdfUpload] = usePdfUploadMutation();
  const [fileUpload] = useFileUploadMutation();

  const onUploadCompleted = useCallback(({ assembly_id, callback }: UploadCompletedArgs) => {
    const interval = setInterval(async () => {
      const assembly: TransloaditAssembly = await fetch(`${TRANSLOADIT_URL}/${assembly_id}`).then(
        (result) => result.json()
      );

      if (assembly.ok === TransloaditAssemblyStatus.completed) {
        callback?.();
        clearInterval(interval);
      }
    }, 1500);
  }, []);

  const getUploadType = useCallback(({ acceptType }: { acceptType: FileAcceptType }) => {
    switch (acceptType) {
      case FileAcceptType.AUDIO:
        return UploadType.AUDIO;
      case FileAcceptType.VIDEO:
        return UploadType.VIDEO;
      case FileAcceptType.PDF:
        return UploadType.PDF;
      case FileAcceptType.FILE:
        return UploadType.FILE;
      default:
        return UploadType.IMAGE;
    }
  }, []);

  const getTemplateType = useCallback(
    ({ acceptType, isUrl }: { acceptType: FileAcceptType; isUrl?: boolean }) => {
      if (isUrl) {
        return acceptType === FileAcceptType.IMAGE
          ? TransloaditAuthTemplate.ITEM
          : TransloaditAuthTemplate.URL;
      }

      return acceptType === FileAcceptType.IMAGE
        ? TransloaditAuthTemplate.ITEM_FILE
        : TransloaditAuthTemplate.FILE;
    },
    []
  );

  const uploadHandler = useCallback(
    async ({ file, url, template, type, imageUploadType }: UploadHandlerArgs) => {
      try {
        const { params, signature }: TransloaditAuthResult = await transloaditAuth({
          template,
          type,
          url,
        } as TransloaditAuthArgs).unwrap();

        const assembliesResponse = await new Promise((resolve) => {
          const req = new XMLHttpRequest();

          req.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
              resolve(JSON.parse(this.responseText));
            }
          };

          const formData = new FormData();
          formData.append('file', file as Blob);
          formData.append('params', JSON.stringify(params));
          formData.append('signature', signature);

          req.open('POST', TRANSLOADIT_URL);
          req.send(formData);
        });

        const { assembly_id } = assembliesResponse as TransloaditAssembliesResult;

        const args: UploadArgs | ImageUploadArgs = {
          assemblyId: assembly_id,
          originalFilename: file?.name ?? '',
          ...(type === UploadType.IMAGE && { imageUploadType }),
        };

        let id;

        switch (type) {
          case UploadType.IMAGE:
            id = (await imageUpload(args as ImageUploadArgs).unwrap()).id;
            break;
          case UploadType.AUDIO:
            id = (await audioUpload(args as UploadArgs).unwrap()).id;
            break;
          case UploadType.VIDEO:
            id = (await videoUpload(args as UploadArgs).unwrap()).id;
            break;
          case UploadType.PDF:
            id = (await pdfUpload(args as UploadArgs).unwrap()).id;
            break;
          case UploadType.FILE:
            id = (await fileUpload(args as UploadArgs).unwrap()).id;
            break;
          default:
            id = 0;
        }

        return { id, assembly_id };
      } catch (_) {
        return { id: 0, assembly_id: '' };
      }
    },
    [audioUpload, fileUpload, imageUpload, pdfUpload, transloaditAuth, videoUpload]
  );

  return { uploadHandler, getUploadType, getTemplateType, onUploadCompleted };
};
