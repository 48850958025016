import { differenceInDays, subDays } from 'date-fns';
import { FunctionComponent, memo, useEffect, useMemo, useState } from 'react';
import { AnalyticChartInterval, useContentActivityQuery } from '../../../../services';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader, StatsItem, TimeSeriesChart } from '../../helpers';
import { ContentProps } from '../Content';

import classes from '../../Analytics.module.scss';

export const ContentActivity: FunctionComponent<ContentProps> = memo(
  ({ from, to, channelId, platform, categoryId, organisationTags }) => {
    const { data: contentActivity, isFetching: isFetchingContentActivity } =
      useContentActivityQuery({
        from,
        to,
        interval: AnalyticChartInterval.AUTO,
        channelId,
        platform,
        categoryId,
        organisationTags,
      });

    const daysDiff = differenceInDays(new Date(to), new Date(from)) + 1;

    const { data: contentActivityPrev, isFetching: isFetchingContentActivityPrev } =
      useContentActivityQuery({
        from: subDays(new Date(from), daysDiff).toISOString(),
        to: subDays(new Date(to), daysDiff).toISOString(),
        channelId,
        platform,
        categoryId,
        organisationTags,
      });

    const [contentActivityLabels, setContentActivityLabels] = useState<string[]>([]);

    useEffect(() => {
      if (contentActivity && !contentActivityLabels.length) {
        setContentActivityLabels(contentActivity.labels);
      }
    }, [contentActivity, contentActivityLabels.length]);

    const renderStats = useMemo(() => {
      if (isFetchingContentActivity || isFetchingContentActivityPrev) {
        return <Loader stats />;
      }

      if (!contentActivity || !contentActivityPrev) {
        return null;
      }

      const { datasets } = contentActivity;
      const { datasets: datasetsPrev } = contentActivityPrev;

      return (
        <div className={classes['analytics__chart-stats']}>
          {datasets.map(({ label, data }, index) => {
            const isDisabled = !contentActivityLabels.includes(label);
            const isClickable = contentActivityLabels.length > 1 || isDisabled;

            return (
              <StatsItem
                key={label}
                label={label}
                localiseKey={`contentActivityLabels.${label}`}
                hintLocaliseKey={`contentActivityHints.${label}`}
                color={COLORS[index]}
                value={data.reduce((partialSum, value) => partialSum + value, 0)}
                valuePrev={datasetsPrev.find((set) => set.label === label)?.data[0] ?? 0}
                labels={contentActivityLabels}
                setLabels={setContentActivityLabels}
                isClickable={isClickable}
                isDisabled={isDisabled}
              />
            );
          })}
        </div>
      );
    }, [
      contentActivity,
      contentActivityLabels,
      contentActivityPrev,
      isFetchingContentActivity,
      isFetchingContentActivityPrev,
    ]);

    return (
      <ChartWrapper
        typeLocaliseKey={'chartTypes.content-activity'}
        infoLocaliseKey={'contentActivity.info'}
      >
        {renderStats}
        {contentActivity && (
          <TimeSeriesChart
            timeSeries={contentActivity}
            labels={contentActivityLabels}
            localiseKeyLabels={'contentActivityLabels'}
            loading={isFetchingContentActivity}
          />
        )}
      </ChartWrapper>
    );
  }
);
