import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { ConfigContext } from '../../context';
import { InAppActiveMessage } from '../../services';
import { useAnalytics } from '../../shared';
import { Modal } from '../../shared/components/Modal';

import classNames from 'classnames';
import classes from './InAppMessage.module.scss';

interface InAppMessageProps {
  isOpen: boolean;
  message: InAppActiveMessage;
  onClose: () => void;
}

export const InAppMessage: FunctionComponent<InAppMessageProps> = memo(
  ({ message, isOpen, onClose }) => {
    const { logInAppMessageView } = useAnalytics();

    const { organisationLogoUrl } = useContext(ConfigContext).config.elements;

    const { id, title, intro, textColor, bgColor, primary, image } = message;
    const {
      text: buttonText,
      textColor: buttonTextColor,
      bgColor: buttonBgColor,
      action,
    } = primary;

    const buttonClickHandler = useCallback(() => {
      //return auto overflow for body via custom button click
      //usually it works via standard modal close button or Accept/Cancel buttons
      document.body.style.overflow = 'auto';
      onClose();

      logInAppMessageView({
        iam_id: id,
        iam_action: 'primary',
        time_stamp: new Date().toISOString(),
      });

      const urlString = action.split('?url=')[1];

      //cancel action
      if (!urlString) {
        return;
      }

      //for now we always do redirect for all non-cancel actions
      window.open(decodeURIComponent(urlString), '_blank', 'noreferrer');
    }, [action, id, logInAppMessageView, onClose]);

    const body = useMemo(
      () => (
        <div className={classes['body']}>
          <img
            className={classNames(classes['body__image'], {
              [classes['body__image--logo']]: !image?.url,
            })}
            {...(image && { width: image.width })}
            src={image?.url ?? organisationLogoUrl}
            alt={'poster'}
          />
          <div
            className={classes['body__text']}
            style={{
              color: textColor,
            }}
          >
            <div className={classes['body__title']}>{title}</div>
            <div className={classes['body__intro']}>{intro}</div>
          </div>
          <button
            className={classes['body__button']}
            onClick={buttonClickHandler}
            style={{
              backgroundColor: buttonBgColor,
              color: buttonTextColor,
            }}
          >
            {buttonText}
          </button>
        </div>
      ),
      [
        buttonBgColor,
        buttonClickHandler,
        buttonText,
        buttonTextColor,
        image,
        intro,
        organisationLogoUrl,
        textColor,
        title,
      ]
    );

    return (
      <Modal
        isOpen={isOpen}
        body={body}
        onClose={onClose}
        contentStyle={{ backgroundColor: bgColor }}
      />
    );
  }
);
