import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  HorizontalStoryFeedData,
  NewsFeedFilter,
  NewsFeedItem,
  NewsFeedType,
  StoryCard,
  StoryCardItemData,
} from '../services';
import {
  addComment,
  deleteCard,
  deleteComment,
  editComment,
  getNewsFeed,
  getStoryFeedHandler,
  hideComment,
  highlightComment,
  loadMoreNewsFeed,
  pinCard,
  reactOnCard,
  reactOnComment,
  repostCard,
  toggleCardSection,
  togglePublishOnCard,
} from './common';
import {
  addCommentReducer,
  deleteCommentReducer,
  editCommentReducer,
  hideCommentReducer,
  highlightCommentReducer,
  reactOnCardReducer,
  reactOnCommentReducer,
} from './extraReducers';

export const getPinnedFeed = createAsyncThunk('pinnedFeed', getStoryFeedHandler);

export const loadMorePinnedFeed = createAsyncThunk('pinnedFeed/loadMore', getStoryFeedHandler);

export const horizontalStoryFeedLoadMore = createAsyncThunk(
  'horizontalStoryFeed/loadMore',
  getStoryFeedHandler
);

const getNewsFeedCard = (newsFeed: NewsFeedItem[], id: number) =>
  (
    newsFeed.find(
      ({ type, data }) =>
        type === NewsFeedType.STORY_CARD_ITEM && (data as StoryCardItemData).item.id === id
    )?.data as StoryCardItemData
  )?.item;

const getNewsFeedCardIndex = (newsFeed: NewsFeedItem[], id: number) =>
  newsFeed.findIndex(
    ({ type, data }) =>
      type === NewsFeedType.STORY_CARD_ITEM && (data as StoryCardItemData).item.id === id
  );

const getNewsFeedHorizontalIndex = (newsFeed: NewsFeedItem[], id: number) =>
  newsFeed.findIndex(
    ({ type, data }) =>
      type === NewsFeedType.HORIZONTAL_STORY_FEED &&
      (data as HorizontalStoryFeedData).items.find((item) => item.id === id)
  );

interface NewsFeedState {
  newsFeed: NewsFeedItem[];
  page: number;
  hasNextPage: boolean;
  isFetching: boolean;
  filter?: NewsFeedFilter;
}

const initialState: NewsFeedState = {
  newsFeed: [],
  page: 0,
  hasNextPage: false,
  isFetching: false,
  filter: undefined,
};

const newsFeedSlice = createSlice({
  name: 'newsFeed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewsFeed.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPinnedFeed.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getNewsFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.newsFeed = items;
        state.page = page;
        state.hasNextPage = hasNextPage;
        state.isFetching = false;
        state.filter = action.meta.arg.filter;
      })
      .addCase(loadMoreNewsFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.newsFeed.push(...items);
        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(horizontalStoryFeedLoadMore.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;

        const horizontalStoryFeed = state.newsFeed.find(
          ({ type }) => type === NewsFeedType.HORIZONTAL_STORY_FEED
        );

        if (horizontalStoryFeed) {
          const horizontalStoryFeedData = horizontalStoryFeed.data as HorizontalStoryFeedData;
          horizontalStoryFeedData.items.push(...items);
          horizontalStoryFeedData.pageInfo = pageInfo;
        }
      })
      .addCase(getPinnedFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.newsFeed = items.map((item: StoryCard) => {
          return {
            type: NewsFeedType.STORY_CARD_ITEM,
            data: { item },
          };
        });

        state.isFetching = false;
        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(loadMorePinnedFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.newsFeed = [
          ...state.newsFeed,
          ...items.map((item: StoryCard) => {
            return {
              type: NewsFeedType.STORY_CARD_ITEM,
              data: { item },
            };
          }),
        ];

        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        const { itemId, comment } = action.payload;

        const card = getNewsFeedCard(state.newsFeed, itemId);

        if (!card) {
          return;
        }

        addCommentReducer({ card, comment });
      })
      .addCase(editComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = getNewsFeedCard(state.newsFeed, storyCardId);

        if (!card) {
          return;
        }

        editCommentReducer({ card, comment });
      })
      .addCase(hideComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = getNewsFeedCard(state.newsFeed, storyCardId);

        if (!card) {
          return;
        }

        hideCommentReducer({ card, comment });
      })
      .addCase(highlightComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;

        const card = getNewsFeedCard(state.newsFeed, storyCardId);

        if (!card) {
          return;
        }

        highlightCommentReducer({ card, comment });
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        const { deleted, cardId, commentId, parentId } = action.payload;

        if (!deleted) {
          return;
        }

        const card = getNewsFeedCard(state.newsFeed, cardId);

        if (!card) {
          return;
        }

        deleteCommentReducer({ card, commentId, parentId });
      })
      .addCase(reactOnComment.fulfilled, (state, action) => {
        const { reactions, cardId, commentId } = action.payload;

        const card = getNewsFeedCard(state.newsFeed, cardId);

        if (!card) {
          return;
        }

        reactOnCommentReducer({ card, commentId, reactions });
      })
      .addCase(reactOnCard.fulfilled, (state, action) => {
        const { cardId, reaction, reactions } = action.payload;

        const card = getNewsFeedCard(state.newsFeed, cardId);

        if (!card) {
          return;
        }

        reactOnCardReducer({ card, reaction, reactions });
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        const { deleted, storyCardId } = action.payload;

        if (!deleted) {
          return;
        }

        const cardIndex = getNewsFeedCardIndex(state.newsFeed, storyCardId);

        if (cardIndex !== -1) {
          state.newsFeed.splice(cardIndex, 1);
        }

        const horizontalIndex = getNewsFeedHorizontalIndex(state.newsFeed, storyCardId);

        if (horizontalIndex === -1) {
          return;
        }

        const horizontal = state.newsFeed[horizontalIndex];
        const horizontalData = horizontal.data as HorizontalStoryFeedData;
        const horizontalFilteredItems = horizontalData.items.filter(({ id }) => id !== storyCardId);

        state.newsFeed[horizontalIndex] = {
          ...horizontal,
          data: { ...horizontalData, items: horizontalFilteredItems },
        };
      })
      .addCase(togglePublishOnCard.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!payload) {
          return;
        }

        const { id: storyCardId, status } = payload;

        const cardIndex = getNewsFeedCardIndex(state.newsFeed, storyCardId);

        if (cardIndex === -1) {
          return;
        }

        if (state.filter?.status?.length && !state.filter.status.includes(status)) {
          state.newsFeed.splice(cardIndex, 1);
          return;
        }

        (state.newsFeed[cardIndex].data as StoryCardItemData).item.status = status;
      })
      .addCase(repostCard.fulfilled, (state, action) => {
        const storyCard = action.payload;

        if (!storyCard) {
          return;
        }

        const card = getNewsFeedCard(state.newsFeed, storyCard.id);

        if (!card) {
          return;
        }

        card.dateToDisplay = storyCard.dateToDisplay;
      })
      .addCase(pinCard.fulfilled, (state, action) => {
        const { payload, storyCardId } = action.payload;

        if (!payload) {
          return;
        }

        const card = getNewsFeedCard(state.newsFeed, storyCardId);

        if (!card) {
          return;
        }

        const pinnedCard = (
          state.newsFeed.find(
            ({ type, data }) =>
              type === NewsFeedType.STORY_CARD_ITEM &&
              (data as StoryCardItemData).item.isPinnedToTopOfNewsFeed
          )?.data as StoryCardItemData
        )?.item;

        if (pinnedCard && pinnedCard.id !== storyCardId) {
          pinnedCard.isPinnedToTopOfNewsFeed = false;
        }

        card.isPinnedToTopOfNewsFeed = !card.isPinnedToTopOfNewsFeed;
      })
      .addCase(toggleCardSection.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!payload) {
          return;
        }

        const { id: storyCardId, section } = payload;

        const card = getNewsFeedCard(state.newsFeed, storyCardId);

        if (!card) {
          return;
        }

        card.section = section;
      });
  },
});

export default newsFeedSlice.reducer;
