import { format } from 'date-fns';
import { FetchedMessageWithActions } from 'pubnub/lib/types/core/types/api/history';
import {
  Dispatch,
  FunctionComponent,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { FeedCard } from '../../../../components';
import { ChatContext, ConfigContext, UserContext } from '../../../../context';
import { ChatAccess, ChatMessageType, ChatTypes, StoryCard } from '../../../../services';
import {
  AbuseReportType,
  Avatar,
  DropdownMenu,
  DSAReportType,
  emojiTypes,
  getCssVar,
  IconLabel,
  isEmojiString,
  layoutPath,
  Popup,
  ReactionsCount,
  ReactionsCounter,
  ReactionSelect,
  timeTokenToDate,
  useAbuseReportPopup,
  useDSAReportPopup,
  useLinkify,
} from '../../../../shared';
import { MessageReaction } from '../ChatHistory';

import classNames from 'classnames';
import dropdownMenuClasses from '../../../../shared/components/DropdownMenu/DropdownMenu.module.scss';
import classes from './Message.module.scss';

interface MyReactionData {
  value: string;
  actionTimetoken: string;
}

interface RenderMessageReactionsArgs {
  reaction: MessageReaction;
  myReaction: MyReactionData | null;
}

interface RenderMessageActionsArgs {
  token: string;
  myReaction: MyReactionData | null;
}

interface ReactionSelectArgs {
  reaction: string;
  token: string;
  myReaction: MyReactionData | null;
}

interface MessageData {
  id: number;
  title: string;
  type: string;
}

interface RenderMessageContentArgs {
  messageType: string;
  text: string;
  messageData: MessageData;
}

interface MessageProps {
  fetchedMessage: FetchedMessageWithActions;
  fetchedChanges: FetchedMessageWithActions[];
  storyCards: StoryCard[];
  isNewAuthor: boolean;
  setDeleteMessageToken: Dispatch<SetStateAction<string>>;
}

export const Message: FunctionComponent<MessageProps> = memo(
  ({ fetchedMessage, fetchedChanges, storyCards, isNewAuthor, setDeleteMessageToken }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { linkify } = useLinkify();

    const { pubnub, activeChat, chatUsers } = useContext(ChatContext);

    const { id: userId } = useContext(UserContext).userProfile;

    const {
      abuseReport: { enabled: abuseReportEnabled },
      dsaReport: { enabled: dsaReportEnabled },
    } = useContext(ConfigContext).config.elements;

    const {
      chatId = 0,
      type: chatType = ChatTypes.group,
      chatDescriptor = '',
      name = '',
      access = null,
    } = { ...activeChat };

    const { message, timetoken, data } = fetchedMessage;

    const {
      type: messageType,
      text,
      author,
      authorName,
      data: messageData,
    } = message as {
      type: string;
      text: string;
      author: number;
      authorName: string;
      data: { id: number; title: string; type: string };
    };

    const reportArgs = useMemo(() => {
      return {
        chatId,
        chatName: name,
        chatType,
        messageId: timetoken.toString(),
        messageText: text,
        messageAuthorId: author.toString(),
        messageAuthorName: authorName,
        messageType,
      };
    }, [author, authorName, chatId, chatType, messageType, name, text, timetoken]);

    const { abuseReportModal, abuseReportHandler } = useAbuseReportPopup({
      type: AbuseReportType.Message,
      ...reportArgs,
    });

    const { DSAReportModal, DSAReportHandler } = useDSAReportPopup({
      type: DSAReportType.Message,
      ...reportArgs,
    });

    const [reactionMessageToken, setReactionMessageToken] = useState('');

    const [isActionsOpen, setIsActionsOpen] = useState<boolean>(false);

    const hasAdminAccess = useMemo(() => access === ChatAccess.admin, [access]);

    const isP2P = useMemo(() => chatType === ChatTypes.p2p, [chatType]);

    const isOther = useMemo(() => author !== userId, [author, userId]);

    const token = useMemo(() => timetoken.toString(), [timetoken]);

    const isDeleted = Boolean(
      fetchedChanges.find(({ message }) => (message as { messageId: string }).messageId === token)
    );

    const getMyReactionData = useCallback(
      (reaction?: MessageReaction): MyReactionData => {
        for (const reactionType in reaction) {
          const reactionArray = reaction[reactionType as keyof MessageReaction];

          if (reactionArray) {
            for (const reaction of reactionArray) {
              if (reaction.uuid === userId.toString()) {
                return { value: reactionType, actionTimetoken: reaction.actionTimetoken };
              }
            }
          }
        }
        return { value: '', actionTimetoken: '' };
      },
      [userId]
    );

    const { reaction = {} } = { ...data };

    const myReaction = !isDeleted ? getMyReactionData(reaction) : null;

    const getMappedReactions = useCallback((reaction: MessageReaction) => {
      const mappedReactions: ReactionsCount[] = [];

      emojiTypes.forEach((name) => {
        const actionData = reaction[name as keyof MessageReaction];
        const count = actionData ? actionData.length : 0;

        if (count > 0) {
          mappedReactions.push({ count, name });
        }
      });

      return mappedReactions;
    }, []);

    const renderMessageReactions = useCallback(
      ({ reaction, myReaction }: RenderMessageReactionsArgs) => {
        if (isDeleted || !Object.keys(reaction).length) {
          return null;
        }

        return (
          <ReactionsCounter
            className={classes['message__reaction']}
            reactions={getMappedReactions(reaction)}
            myReaction={myReaction?.value ?? ''}
            reactionSize={18}
            individualOnly
          />
        );
      },
      [getMappedReactions, isDeleted]
    );

    const renderMessageAuthor = useCallback(
      (author: number) => {
        if (isP2P || !isNewAuthor || !isOther) {
          return null;
        }

        const { screenName, avatar } = { ...chatUsers.find(({ id }) => id === author) };

        const onClick = () => navigate(layoutPath(`/profile/${author}`));

        return (
          <div className={classes['author']} onClick={onClick}>
            <Avatar url={avatar?.url} />
            <div className={classes['author__name']}>{screenName}</div>
          </div>
        );
      },
      [chatUsers, isNewAuthor, isOther, isP2P, navigate]
    );

    const onReactionSelect = useCallback(
      ({ reaction, token, myReaction }: ReactionSelectArgs) => {
        setReactionMessageToken('');

        const { value, actionTimetoken } = { ...myReaction };

        const addReaction = () => {
          pubnub.addMessageAction({
            channel: chatDescriptor,
            messageTimetoken: token,
            action: { type: 'reaction', value: reaction },
          });
        };

        if (value && actionTimetoken) {
          pubnub.removeMessageAction(
            {
              channel: chatDescriptor,
              messageTimetoken: token,
              actionTimetoken,
            },
            () => {
              if (value !== reaction) {
                addReaction();
              }
            }
          );
          return;
        }

        addReaction();
      },
      [chatDescriptor, pubnub]
    );

    const abuseReportClick = useCallback(() => {
      setIsActionsOpen(false);
      abuseReportHandler();
    }, [abuseReportHandler]);

    const DSAReportClick = useCallback(() => {
      setIsActionsOpen(false);
      DSAReportHandler();
    }, [DSAReportHandler]);

    const deleteMessageClick = useCallback(
      (token: string) => {
        setIsActionsOpen(false);
        setDeleteMessageToken(token);
      },
      [setDeleteMessageToken]
    );

    const actionMenuItems = useMemo(() => {
      return (
        <>
          {abuseReportEnabled && (
            <IconLabel
              iconId={'report'}
              iconSize={18}
              label={t('abuseReport.label')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={abuseReportClick}
              singleColor
            />
          )}
          {dsaReportEnabled && (
            <IconLabel
              iconId={'problem'}
              iconSize={18}
              label={t('DSAReport.label')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={DSAReportClick}
              singleColor
            />
          )}
          {(!isOther || hasAdminAccess) && (
            <IconLabel
              iconId={'delete'}
              iconSize={18}
              label={t('common.delete')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              color={getCssVar('--color-danger')}
              hoverColor={getCssVar('--color-danger-hover')}
              onClick={() => deleteMessageClick(token)}
            />
          )}
        </>
      );
    }, [
      DSAReportClick,
      abuseReportClick,
      abuseReportEnabled,
      deleteMessageClick,
      dsaReportEnabled,
      hasAdminAccess,
      isOther,
      t,
      token,
    ]);

    const renderMessageActions = useCallback(
      ({ token, myReaction }: RenderMessageActionsArgs) => {
        if (isDeleted || access === ChatAccess.r) {
          return null;
        }

        const className = classNames({
          [classes['message__icon']]: !isActionsOpen && reactionMessageToken !== token,
        });

        return (
          <>
            <Popup
              isOpen={isActionsOpen}
              setIsOpen={setIsActionsOpen}
              iconId={'dots-menu'}
              bodyBottom={'1.5rem'}
              parentClassName={className}
              body={<DropdownMenu width={'12rem'} content={actionMenuItems} />}
              {...(isOther && { bodyRight: '-1.25rem' })}
              {...(!isOther && { bodyLeft: '-1.25rem' })}
            />

            <ReactionSelect
              className={className}
              pickerClassName={classes['message__emoji-picker']}
              onSelect={(reaction) => onReactionSelect({ reaction, token, myReaction })}
              myReaction={myReaction?.value}
              onShowPicker={() => setReactionMessageToken(token)}
              onHidePicker={() => setReactionMessageToken('')}
              defaultIconId={'mood'}
              hideLabel
            />
          </>
        );
      },
      [
        access,
        actionMenuItems,
        isActionsOpen,
        isDeleted,
        isOther,
        onReactionSelect,
        reactionMessageToken,
      ]
    );

    const renderMessageContentCard = useCallback(
      ({ text, messageData }: { text: string; messageData: MessageData }) => {
        const { id } = messageData;

        const card = storyCards.find((card) => card.id === id);

        if (!card) {
          return <Skeleton width={'12rem'} height={'4rem'} />;
        }

        return (
          <div className={classes['message__content-card']}>
            <FeedCard card={card} chatId={chatId} />
            <div dangerouslySetInnerHTML={{ __html: linkify(text || ' ') }}></div>
          </div>
        );
      },
      [chatId, linkify, storyCards]
    );

    const renderMessageContent = useCallback(
      ({ messageType, text, messageData }: RenderMessageContentArgs) => {
        if (isDeleted) {
          return <i>{t('chatServerMessages.message-removed')}</i>;
        }

        if (messageType === ChatMessageType.post) {
          return renderMessageContentCard({ text, messageData });
        }

        return (
          <div
            className={classNames(classes['message__content-text'], {
              [classes['message__content-emoji']]: isEmojiString(text),
            })}
            dangerouslySetInnerHTML={{ __html: linkify(text) }}
          ></div>
        );
      },
      [isDeleted, linkify, renderMessageContentCard, t]
    );

    return (
      <>
        {renderMessageAuthor(author)}
        <div
          className={classNames(classes['message'], {
            [classes['message--other']]: isOther,
            [classes['message--deleted']]: isDeleted,
            [classes['message--has-reaction']]: Boolean(
              !isDeleted && Object.keys(reaction).length > 0
            ),
          })}
        >
          {renderMessageActions({ token, myReaction })}
          <div className={classes['message__content']}>
            {renderMessageContent({ messageType, text, messageData })}

            <span className={classes['message__content-time']}>
              {format(timeTokenToDate(token), 'HH:mm')}
            </span>
          </div>

          {renderMessageReactions({ reaction, myReaction })}
        </div>

        {abuseReportModal}

        {DSAReportModal}
      </>
    );
  }
);
