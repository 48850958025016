import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { OrganisationContext } from '../../../context';
import {
  ChannelAppTabAction,
  OrganisationTagType,
  StoryCardSection,
  StoryCardsFeedFilter,
  storyCardApi,
} from '../../../services';
import {
  DATE_RANGE_GROUP_ID,
  Filter,
  FilterGroup,
  FilterValue,
  STORY_TAB_ITEMS_ADMIN_COOKIE_KEY,
  cookieOptions,
  optArrItem,
  useAppDispatch,
  useAppTabsLabel,
  useAuthorsFilter,
  useCardCategoryFilter,
  useCardStatusFilter,
  useCardTypeFilter,
  useDateRangeFilter,
  useFilter,
  useTags,
  useTagsFilter,
} from '../../../shared';
import { getStoryFeed } from '../../../slices';

import classes from './StoryFeedItemsAdminRightSide.module.scss';

const SECTIONS = 'sections';

export const StoryFeedItemsAdminRightSide: FunctionComponent = () => {
  const { t } = useTranslation();

  const storyId = Number(useParams().storyId);

  const dispatch = useAppDispatch();

  const { getAppTabsLabel } = useAppTabsLabel();

  const { hasInternalTags, hasExternalTags } = useTags();

  const hasCategories = Boolean(useContext(OrganisationContext).organisation.categories.length);

  const { getGroupValue, getDateRangeValue } = useFilter();

  const [cookies, setCookie] = useCookies([STORY_TAB_ITEMS_ADMIN_COOKIE_KEY]);

  const [fetchStoryAuthors] =
    storyCardApi.endpoints.storyCardAuthorsFiltersByStoryId.useLazyQuery();

  const [filter, setFilter] = useState<FilterValue[]>(
    cookies[STORY_TAB_ITEMS_ADMIN_COOKIE_KEY]?.filter ?? []
  );

  const { group: dateRangeGroup } = useDateRangeFilter({ filter });

  const { group: authorsGroup } = useAuthorsFilter({
    filter,
    fetchItemsQuery: fetchStoryAuthors,
    fetchItemsQueryArgs: { storyId },
  });

  const { group: categoryGroup } = useCardCategoryFilter({ filter });

  const { group: internalTagsGroup } = useTagsFilter({
    filter,
    types: [OrganisationTagType.INTERNAL],
  });

  const { group: externalTagsGroup } = useTagsFilter({
    filter,
    types: [OrganisationTagType.EXTERNAL],
  });

  const { group: cardTypeGroup } = useCardTypeFilter();

  const { group: cardStatusGroup } = useCardStatusFilter();

  const groups: FilterGroup[] = [
    {
      id: SECTIONS,
      title: t(`common.${SECTIONS}`),
      multiCheck: true,
      allowReset: true,
      items: [
        {
          id: StoryCardSection.WEB_FEED,
          label: `${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)} ${t('common.web')}`,
          value: StoryCardSection.WEB_FEED,
        },
        {
          id: StoryCardSection.MOBILE_FEED,
          label: `${getAppTabsLabel(ChannelAppTabAction.NEWS_FEED)} ${t('common.mobile')}`,
          value: StoryCardSection.MOBILE_FEED,
        },
      ],
    },
    ...optArrItem(hasInternalTags, internalTagsGroup),
    ...optArrItem(hasExternalTags, externalTagsGroup),
    ...optArrItem(hasCategories, categoryGroup),
    authorsGroup,
    dateRangeGroup,
    cardTypeGroup,
    cardStatusGroup,
  ];

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      if (!storyId) {
        return;
      }

      const authorId = getGroupValue({
        filter,
        groupId: authorsGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const categoryId = getGroupValue({
        filter,
        groupId: categoryGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const internalTagsId = getGroupValue({
        filter,
        groupId: internalTagsGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const externalTagsId = getGroupValue({
        filter,
        groupId: externalTagsGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const section = getGroupValue({ filter, groupId: SECTIONS, multiCheck: true });

      const { dateRange, from, to } = getDateRangeValue({ filter, groupId: DATE_RANGE_GROUP_ID });

      const storyCardType = getGroupValue({ filter, groupId: cardTypeGroup.id, multiCheck: true });

      const status = getGroupValue({ filter, groupId: cardStatusGroup.id, multiCheck: true });

      const requestFilterToSave: StoryCardsFeedFilter = {
        ...(authorId?.length && { authorId }),
        ...(categoryId?.length && { categoryId }),
        ...((internalTagsId?.length || externalTagsId?.length) && {
          organisationTags: {
            internalTagsId: internalTagsId?.[0] === null ? null : internalTagsId,
            externalTagsId: externalTagsId?.[0] === null ? null : externalTagsId,
          },
        }),
        ...(section?.length && { section }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        dateRange,
      };

      const requestFilter: StoryCardsFeedFilter = {
        ...(authorId?.length && { authorId }),
        ...(categoryId?.length && { categoryId }),
        ...((internalTagsId?.length || externalTagsId?.length) && {
          organisationTags: {
            internalTagsId: internalTagsId?.[0] === null ? null : internalTagsId,
            externalTagsId: externalTagsId?.[0] === null ? null : externalTagsId,
          },
        }),
        ...(section?.length && { section }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        ...(from && { from }),
        ...(to && { to }),
      };

      setCookie(
        STORY_TAB_ITEMS_ADMIN_COOKIE_KEY,
        { requestFilter: requestFilterToSave, filter },
        cookieOptions()
      );

      dispatch(getStoryFeed({ storyId: Number(storyId), filter: requestFilter }));

      setFilter(filter);
    },
    [
      authorsGroup.id,
      cardStatusGroup.id,
      cardTypeGroup.id,
      categoryGroup.id,
      dispatch,
      externalTagsGroup.id,
      getDateRangeValue,
      getGroupValue,
      internalTagsGroup.id,
      setCookie,
      storyId,
    ]
  );

  return (
    <div className={classes['story-feed-items-right-side']}>
      <Filter filter={filter} groups={groups} onChange={filterChange} resetPageScrollOnChange />
    </div>
  );
};
