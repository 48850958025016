import { TransloaditAssembly, TransloaditAssemblyStatus } from '../../services';
import { TRANSLOADIT_URL } from '../constants';

export const isMediaParsed = (externalId?: string) => {
  if (!externalId) {
    return;
  }

  return new Promise<void>((resolve, reject) => {
    const getAssemblyInterval = setInterval(async () => {
      const assemblyResult: TransloaditAssembly = await fetch(
        `${TRANSLOADIT_URL}/${externalId}`
      ).then((result) => result.json());

      if (assemblyResult.error) {
        clearInterval(getAssemblyInterval);
        reject();
      }

      if (assemblyResult.ok === TransloaditAssemblyStatus.completed) {
        clearInterval(getAssemblyInterval);
        resolve();
      }
    }, 1500);
  });
};
