import { FunctionComponent, memo } from 'react';
import { useLinkify } from '../../../../shared';

import classNames from 'classnames';
import classes from './Paragraph.module.scss';

interface ParagraphProps {
  text: string;
  source?: boolean;
  doLinkify?: boolean;
}

export const Paragraph: FunctionComponent<ParagraphProps> = memo(({ text, source, doLinkify }) => {
  const { linkify } = useLinkify();

  const content = text.replace(/href/g, "target='_blank' href");

  return (
    <p
      className={classNames(classes['paragraph'], {
        [classes['paragraph--source']]: source,
      })}
      dangerouslySetInnerHTML={{ __html: doLinkify ? linkify(content) : content }}
    ></p>
  );
});
