import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { ChannelContext, ConfigContext, UserContext } from '../../context';
import {
  ChannelAppTabAction,
  useGetStoriesByChannelIdQuery,
  useStoryCardCategoriesByChannelIdQuery,
} from '../../services';
import {
  CreateCard,
  Select,
  getAppTabHrefByActionType,
  getWebAppUrl,
  optArrItem,
  useAppSelector,
} from '../../shared';
import { Footer } from '../Footer';
import { Menu } from '../Menu';
import { SpecialTeaser } from '../SpecialTeaser';

import classNames from 'classnames';
import classes from './Sidebar.module.scss';

interface SidebarProps {
  onMobilePanelClose?: () => void;
}

export const Sidebar: FunctionComponent<SidebarProps> = memo(({ onMobilePanelClose }) => {
  const { t } = useTranslation();

  const tabs = useContext(ChannelContext).appTabs;

  const { channelId, id: userId } = useContext(UserContext).userInfo.userData;

  const { channelLogos, userDirectory } = useContext(ConfigContext).config.elements;

  const {
    showAsPrimaryTab: userDirectoryShowAsPrimaryTab,
    primaryTabIconUrl: userDirectoryPrimaryTabIconUrl,
    localiseKey: userDirectoryLocaliseKey,
  } = userDirectory;

  const appTabs = useMemo(
    () => [
      ...tabs,
      ...optArrItem(userDirectoryShowAsPrimaryTab, {
        iconUrl: userDirectoryPrimaryTabIconUrl,
        action: { type: ChannelAppTabAction.USER_DIRECTORY },
        title: [
          { language: 'en-US', localization: t(userDirectoryLocaliseKey) },
          { language: 'de-DE', localization: t(userDirectoryLocaliseKey) },
        ],
      }),
    ],
    [
      t,
      tabs,
      userDirectoryLocaliseKey,
      userDirectoryPrimaryTabIconUrl,
      userDirectoryShowAsPrimaryTab,
    ]
  );

  const { channels } = useAppSelector(({ channels }) => channels);

  const { data: postInStories = [], isFetching: isFetchingPostInStories } =
    useGetStoriesByChannelIdQuery({ channelId, filter: { onlyPostIn: true } });

  const { data: categories = [], isFetching: isFetchingCategories } =
    useStoryCardCategoriesByChannelIdQuery({ channelId });

  const channelItems = useMemo(() => {
    return channels.map(({ id, name }) => ({ id, title: name })) ?? [];
  }, [channels]);

  const showLogo: boolean = useMemo(() => {
    const currentChannel = channels.find(({ id }) => id === channelId);

    if (!currentChannel) {
      return false;
    }

    return Boolean(channelLogos.find(({ channelId }) => channelId === currentChannel.id));
  }, [channelId, channelLogos, channels]);

  const hasAppTabs = useMemo(() => Boolean(appTabs.length), [appTabs.length]);

  const onChannelChange = useCallback(
    (channelId: number) => {
      if (!hasAppTabs) {
        return;
      }

      const selectedChannel = channels
        .find(({ id }) => id === channelId)
        ?.subdomain.replace(/\s/g, '');

      if (!selectedChannel) {
        return;
      }

      const appTabHref = getAppTabHrefByActionType({ action: appTabs[0].action.type }).split(
        '/'
      )[1];

      window.location.replace(
        `${getWebAppUrl(selectedChannel)}${appTabHref ? `/${appTabHref}` : ''}`
      );
    },
    [appTabs, channels, hasAppTabs]
  );

  const logo = useMemo(() => {
    if (!showLogo) {
      return;
    }

    const logo = channelLogos.find((channelLogo) => channelLogo.channelId === channelId)?.logoUrl;

    if (!logo) {
      return;
    }

    return <img className={classes['logo']} src={logo} alt={'logo'} />;
  }, [channelId, channelLogos, showLogo]);

  const renderChannelSwitcher = useMemo(
    () => Boolean(hasAppTabs && channels.length),
    [channels.length, hasAppTabs]
  );

  const hasPostInStories = useMemo(() => Boolean(postInStories.length), [postInStories.length]);

  const hasCategories = useMemo(() => Boolean(categories.length), [categories.length]);

  const renderCardCreate = useMemo(() => {
    if (isFetchingPostInStories || isFetchingCategories) {
      return <Skeleton height={'2.5rem'} />;
    }

    if (!hasPostInStories && !hasCategories) {
      return null;
    }

    return (
      <CreateCard
        postInStories={postInStories}
        categories={categories}
        onMobilePanelClose={onMobilePanelClose}
      />
    );
  }, [
    categories,
    hasCategories,
    hasPostInStories,
    isFetchingCategories,
    isFetchingPostInStories,
    onMobilePanelClose,
    postInStories,
  ]);

  const appTabsRelatedContent = useMemo(() => {
    return (
      <>
        {renderChannelSwitcher && (
          <Select
            selectedItemId={channelId}
            items={channelItems}
            logo={logo}
            onChange={(channelId) => onChannelChange(channelId as number)}
            disabled={channels.length === 1}
            noBorder
          />
        )}
        {hasAppTabs && (
          <Menu
            appTabs={appTabs}
            hasPostInStories={hasPostInStories}
            channelId={channelId}
            userId={userId}
          />
        )}
      </>
    );
  }, [
    appTabs,
    channelId,
    channelItems,
    channels.length,
    hasAppTabs,
    hasPostInStories,
    logo,
    onChannelChange,
    renderChannelSwitcher,
    userId,
  ]);

  return (
    <div
      className={classNames(classes['sidebar'], {
        [classes['sidebar--mobile']]: isMobileOnly,
      })}
    >
      {appTabsRelatedContent}

      {renderCardCreate}

      <SpecialTeaser />

      <Footer />
    </div>
  );
});
