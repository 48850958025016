import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../context';
import {
  OrganisationTagType,
  storyCardApi,
  StoryCardsOrganisationTagFeedFilters,
} from '../../../services';
import {
  cookieOptions,
  DATE_RANGE_GROUP_ID,
  Filter,
  FilterGroup,
  FilterValue,
  optArrItem,
  TAGS_FEED_ADMIN_COOKIE_KEY,
  useAppDispatch,
  useAuthorsFilter,
  useCardStatusFilter,
  useCardTypeFilter,
  useDateRangeFilter,
  useFilter,
  useTags,
  useTagsFilter,
} from '../../../shared';
import { getTagsFeed } from '../../../slices';

import classes from './TagsFeedAdminRightSide.module.scss';

export const TagsFeedAdminRightSide: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const organisationTagId = Number(useParams().organisationTagId);

  const { hasInternalTags, hasExternalTags } = useTags();

  const { getGroupValue, getDateRangeValue } = useFilter();

  const [fetchChannelAuthors] =
    storyCardApi.endpoints.storyCardAuthorsFiltersByChannelId.useLazyQuery();

  const [cookies, setCookie] = useCookies([TAGS_FEED_ADMIN_COOKIE_KEY]);

  const [filter, setFilter] = useState<FilterValue[]>(
    cookies[TAGS_FEED_ADMIN_COOKIE_KEY]?.filter ?? []
  );

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { group: dateRangeGroup } = useDateRangeFilter({ filter });

  const { group: authorsGroup } = useAuthorsFilter({
    filter,
    fetchItemsQuery: fetchChannelAuthors,
    fetchItemsQueryArgs: { channelId },
  });

  const { group: internalTagsGroup } = useTagsFilter({
    filter,
    types: [OrganisationTagType.INTERNAL],
  });

  const { group: externalTagsGroup } = useTagsFilter({
    filter,
    types: [OrganisationTagType.EXTERNAL],
  });

  const { group: cardTypeGroup } = useCardTypeFilter();

  const { group: cardStatusGroup } = useCardStatusFilter();

  const groups: FilterGroup[] = [
    ...optArrItem(hasInternalTags, internalTagsGroup),
    ...optArrItem(hasExternalTags, externalTagsGroup),
    authorsGroup,
    dateRangeGroup,
    cardTypeGroup,
    cardStatusGroup,
  ];

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      const internalTagsId = getGroupValue({
        filter,
        groupId: internalTagsGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const externalTagsId = getGroupValue({
        filter,
        groupId: externalTagsGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const authorId = getGroupValue({
        filter,
        groupId: authorsGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const { dateRange, from, to } = getDateRangeValue({ filter, groupId: DATE_RANGE_GROUP_ID });

      const storyCardType = getGroupValue({ filter, groupId: cardTypeGroup.id, multiCheck: true });

      const status = getGroupValue({ filter, groupId: cardStatusGroup.id, multiCheck: true });

      const requestFilterToSave: StoryCardsOrganisationTagFeedFilters = {
        ...((internalTagsId?.length || externalTagsId?.length) && {
          organisationTags: {
            internalTagsId: internalTagsId?.[0] === null ? null : internalTagsId,
            externalTagsId: externalTagsId?.[0] === null ? null : externalTagsId,
          },
        }),
        ...(authorId?.length && { authorId }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        dateRange,
      };

      const filters: StoryCardsOrganisationTagFeedFilters = {
        ...((internalTagsId?.length || externalTagsId?.length) && {
          organisationTags: {
            internalTagsId: internalTagsId?.[0] === null ? null : internalTagsId,
            externalTagsId: externalTagsId?.[0] === null ? null : externalTagsId,
          },
        }),
        ...(authorId?.length && { authorId }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        ...(from && { from }),
        ...(to && { to }),
      };

      setCookie(
        TAGS_FEED_ADMIN_COOKIE_KEY,
        { requestFilter: requestFilterToSave, filter },
        cookieOptions()
      );

      setFilter(filter);

      dispatch(getTagsFeed({ channelId, organisationTagId, filters }));
    },
    [
      authorsGroup.id,
      cardStatusGroup.id,
      cardTypeGroup.id,
      channelId,
      dispatch,
      externalTagsGroup.id,
      getDateRangeValue,
      getGroupValue,
      internalTagsGroup.id,
      organisationTagId,
      setCookie,
    ]
  );

  return (
    <div className={classes['tags-feed-admin-right-side']}>
      <Filter filter={filter} groups={groups} onChange={filterChange} resetPageScrollOnChange />
    </div>
  );
};
