import { ElementType, FunctionComponent, memo, useContext, useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DetailsModalContext, UserContext } from '../../../../context';
import { getCardModal, getChatCardModal } from '../../../../slices';
import { useAnalytics, useAppDispatch, useAppSelector } from '../../../hooks';
import { Modal } from '../../Modal';
import { DetailsContentProps } from '../DetailsContentCommon';

interface DetailsModalCommonProps {
  ContentComponent: ElementType<DetailsContentProps>;
}

export const DetailsModalCommon: FunctionComponent<DetailsModalCommonProps> = memo(
  ({ ContentComponent }) => {
    const dispatch = useAppDispatch();

    const { logClickOpenArticle } = useAnalytics();

    const { detailsModalData, setDetailsModalData } = useContext(DetailsModalContext);

    const { storyId, cardId, chatId, scrollToTags, scrollToComments, activeCommentsInput } =
      detailsModalData;

    const { channelId } = useContext(UserContext).userInfo.userData;

    useEffect(() => {
      if (!storyId || !cardId) {
        return;
      }

      if (chatId) {
        dispatch(getChatCardModal({ chatId, cardId }));
      } else {
        dispatch(getCardModal({ storyId, cardId }));
      }

      logClickOpenArticle({
        channel_id: channelId,
        story_id: storyId,
        item_id: cardId,
        time_stamp: new Date().toISOString(),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardId, logClickOpenArticle, storyId]);

    const { card, isFetching } = useAppSelector(({ cardDetailsModal }) => cardDetailsModal);

    const body = useMemo(() => {
      if (!card) {
        return <Skeleton height={200} width={'100%'} />;
      }

      return (
        <ContentComponent
          card={card}
          chatId={chatId}
          scrollToTags={scrollToTags}
          scrollToComments={scrollToComments}
          activeCommentsInput={activeCommentsInput}
          commentsWrapper={document.querySelector('.modal-content') as HTMLElement}
        />
      );
    }, [ContentComponent, activeCommentsInput, card, chatId, scrollToComments, scrollToTags]);

    if (!isFetching && !card) {
      return null;
    }

    return (
      <Modal
        isOpen={Boolean(storyId && cardId)}
        body={body}
        onClose={() => setDetailsModalData({ storyId: null, cardId: null })}
      />
    );
  }
);
