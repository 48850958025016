import { GalleryItem, ImageFile, isAdminLayout, PaginationInfo } from '../shared';
import { channelApi } from './channelApi';
import { graphqlApi } from './graphqlApi';
import { storyCardQuery } from './helpers';
import { StoryCard } from './storyCard';
import { User } from './user';

export enum ChatAccess {
  admin = 'admin',
  banned = 'banned',
  r = 'r',
  rw = 'rw',
}

export enum ChatMessageType {
  text = 'text',
  post = 'post',
  info = 'info',
}

export interface UserChatMember extends User {
  chatAccess: ChatAccess;
}

export enum ChatAccessTypes {
  publicRead = 'publicRead',
  publicWrite = 'publicWrite',
  private = 'private',
}

export enum ChatLevels {
  channel = 'channel',
  organisation = 'organisation',
}

export enum ChatTypes {
  p2p = 'p2p',
  group = 'group',
}

export enum MessageTypes {
  chatDelete = 'chatDelete',
  delete = 'delete',
}

export enum MessageSubTypes {
  create = 'create',
  join = 'join',
  leave = 'leave',
  change = 'change',
  changeUsersAccess = 'changeUsersAccess',
}

export interface Chat {
  access: ChatAccess | null;
  accessType: ChatAccessTypes;
  channelId: number;
  chatDescriptor: string;
  chatId: number;
  created: string;
  image: ImageFile | null;
  imageId: number | null;
  isHiddenFromPublic: boolean;
  level: ChatLevels;
  name: string;
  organisationId: number;
  payload: string;
  publicToJoin: boolean;
  receivePushes: boolean;
  recipientId: null;
  thumbnails: [{ image: string | null; initials: string }];
  type: ChatTypes;
  updated: string;
  usersCount: number;
}

interface ChannelChatsResponse {
  chats: Chat[];
  publishKey: string;
  subscribeKey: string;
  token: string;
  tokenV3: string;
}

interface GetChannelChatByIdArgs {
  chatId: number;
}

interface CreateChatArgs {
  type: ChatTypes;
  imageId: number | null;
  name: string;
  payload: string;
  level: ChatLevels;
  accessType: ChatAccessTypes;
  users: number[];
}

interface DeleteChatArgs {
  chatId: number;
}

interface UpdateChatArgs {
  chatId: number;
  imageId: number | null;
  type: ChatTypes;
  name: string;
  payload: string;
  level: ChatLevels;
  accessType: ChatAccessTypes;
  users: number[];
}

interface PublicGroupChatsArgs {
  limit?: number;
  offset?: number;
  searchString?: string;
}

interface PublicChatsResponse {
  chats: Chat[];
  chatsCount: number;
}

interface JoinChatArgs {
  chatId: number;
}

interface LeaveChatArgs {
  chatId: number;
}

interface ChangeMessageArgs {
  chatId: number;
  messageId: string;
  actionType: string;
}

interface ChatP2PCandidatesArgs {
  page?: number;
  size?: number;
  query?: string;
}

interface ChatP2PCandidatesData {
  items: User[];
  pageInfo: PaginationInfo;
}

interface ChatP2PCandidatesResponse {
  data: {
    chatP2PCandidates: ChatP2PCandidatesData;
  };
}

interface ChatChannelGroupCandidatesData {
  items: User[];
  pageInfo: PaginationInfo;
}

interface ChatChannelGroupCandidatesResponse {
  data: {
    chatChannelGroupCandidates: ChatChannelGroupCandidatesData;
  };
}

interface ChatChannelGroupCandidatesArgs {
  channelId: number;
  page?: number;
  size?: number;
  query?: string;
}

interface ChatOrganisationGroupCandidatesData {
  items: User[];
  pageInfo: PaginationInfo;
}

interface ChatOrganisationGroupCandidatesResponse {
  data: {
    chatOrganisationGroupCandidates: ChatOrganisationGroupCandidatesData;
  };
}

interface ChatOrganisationGroupCandidatesArgs {
  page?: number;
  size?: number;
  query?: string;
}

export interface ChatMembersData {
  items: UserChatMember[];
  pageInfo: PaginationInfo;
}

interface ChatMembersResponse {
  data: {
    chatMembers: ChatMembersData;
  };
}

export interface ChatMembersArgs {
  chatId: number;
  page?: number;
  size?: number;
  query?: string;
  usersId?: number[];
}

export interface ChatGroupMoreCandidatesData {
  items: User[];
  pageInfo: PaginationInfo;
}

interface ChatGroupMoreCandidatesResponse {
  data: {
    chatGroupMoreCandidates: ChatGroupMoreCandidatesData;
  };
}

export interface ChatGroupMoreCandidatesArgs {
  chatId: number;
  page?: number;
  size?: number;
  query?: string;
}

interface AddMembersArgs {
  chatId: number;
  users: number[];
}

interface RemoveMembersArgs {
  chatId: number;
  users: number[];
}

interface MembersAccessArgs {
  chatId: number;
  users: { id: number; access: ChatAccess }[];
}

interface ChatItemsArgs {
  chatId: number;
  storyCardsIds: number[];
}

interface AddChatItemsArgs {
  chatId: number;
  type: string;
  formattedText: string;
  gallery: GalleryItem[];
}

export interface ChatStoryCardsFeedData {
  items: StoryCard[];
  pageInfo: PaginationInfo;
}

interface ChatStoryCardsFeedResponse {
  data: {
    chatStoryCardsFeed: {
      payload: ChatStoryCardsFeedData;
    };
  };
}

export interface ChatStoryCardsFeedArgs {
  chatId: number;
  ids?: number[];
  page?: number;
  size?: number;
}

export const chatsApi = channelApi.injectEndpoints({
  endpoints: (builder) => ({
    getChannelChats: builder.query<ChannelChatsResponse, void>({
      query: () => ({
        url: '/chats',
      }),
      forceRefetch: () => true,
    }),
    getChannelChatById: builder.query<Chat, GetChannelChatByIdArgs>({
      query: ({ chatId }) => ({
        url: `/chats/${chatId}`,
      }),
      forceRefetch: () => true,
    }),
    getPublicChats: builder.query<PublicChatsResponse, PublicGroupChatsArgs>({
      query: ({ limit = 20, offset = 0, searchString = '' }) => ({
        url: `/chats/public?limit=${limit}&offset=${offset}&searchString=${searchString}`,
      }),
      forceRefetch: () => true,
    }),
    createChat: builder.mutation<Chat, CreateChatArgs>({
      query: ({ type, imageId, name, payload, level, accessType, users }) => ({
        url: `/chats`,
        method: 'POST',
        body: {
          type,
          imageId,
          name,
          payload,
          level,
          accessType,
          users,
        },
      }),
    }),
    updateChat: builder.mutation<Chat, UpdateChatArgs>({
      query: ({ chatId, type, imageId, name, payload, level, accessType, users }) => ({
        url: `/chats/${chatId}`,
        method: 'PUT',
        body: {
          type,
          imageId,
          name,
          payload,
          level,
          accessType,
          users,
        },
      }),
    }),
    deleteChat: builder.mutation<void, DeleteChatArgs>({
      query: ({ chatId }) => ({
        url: `/chats/${chatId}`,
        method: 'DELETE',
      }),
    }),
    joinChat: builder.mutation<Chat, JoinChatArgs>({
      query: ({ chatId }) => ({
        url: `/chats/${chatId}/users`,
        method: 'POST',
        body: { self: true },
      }),
    }),
    addMembers: builder.mutation<void, AddMembersArgs>({
      query: ({ chatId, users }) => ({
        url: `/chats/${chatId}/users`,
        method: 'POST',
        body: { users },
      }),
    }),
    membersAccess: builder.mutation<void, MembersAccessArgs>({
      query: ({ chatId, users }) => ({
        url: `/chats/${chatId}/users`,
        method: 'PUT',
        body: { users },
      }),
    }),
    leaveChat: builder.mutation<void, LeaveChatArgs>({
      query: ({ chatId }) => ({
        url: `/chats/${chatId}/users/remove`,
        method: 'POST',
        body: { self: true },
      }),
    }),
    removeMembers: builder.mutation<void, RemoveMembersArgs>({
      query: ({ chatId, users }) => ({
        url: `/chats/${chatId}/users/remove`,
        method: 'POST',
        body: { users },
      }),
    }),
    changeMessage: builder.mutation<void, ChangeMessageArgs>({
      query: ({ chatId, messageId, actionType }) => ({
        url: `chats/${chatId}/change-message`,
        method: 'POST',
        body: { messageId, actionType },
      }),
    }),
    getChatItems: builder.query<StoryCard[], ChatItemsArgs>({
      query: ({ chatId, storyCardsIds }) => ({
        url: `/chats/${chatId}/items?ids=${storyCardsIds}`,
      }),
      forceRefetch: () => true,
    }),
    addChatItems: builder.mutation<StoryCard, AddChatItemsArgs>({
      query: ({ chatId, type, gallery, formattedText }) => ({
        url: `/chats/${chatId}/items`,
        method: 'POST',
        body: {
          type,
          gallery,
          formattedText,
        },
      }),
    }),
  }),
});

export const graphqlChatsApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    chatP2PCandidates: builder.query<ChatP2PCandidatesData, ChatP2PCandidatesArgs>({
      query: ({ page = 1, size = 10, query = '' }) => ({
        url: '/graphql/webapp?chatP2PCandidates',
        method: 'POST',
        body: {
          query: `query ChatP2PCandidates($page: Int, $size: Int, $filters: GetChatCandidatesFiltersArgsType) {
            chatP2PCandidates(page: $page, size: $size, filters: $filters) {
              items {
                id
                screenName
                avatar {
                  averageColor
                  externalId
                  externalType
                  id
                  originalFilename
                  rightholder
                  status
                  statusCopyrightId
                  thumb
                  url
                }
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            page,
            size,
            filters: { query },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChatP2PCandidatesResponse) => response.data.chatP2PCandidates,
    }),
    chatChannelGroupCandidates: builder.query<
      ChatChannelGroupCandidatesData,
      ChatChannelGroupCandidatesArgs
    >({
      query: ({ channelId, page = 1, size = 10, query = '' }) => ({
        url: '/graphql/webapp?chatChannelGroupCandidates',
        method: 'POST',
        body: {
          query: `query ChatChannelGroupCandidates($channelId: Int!, $page: Int, $size: Int, $filters: GetChatCandidatesFiltersArgsType) {
            chatChannelGroupCandidates(channelId: $channelId, page: $page, size: $size, filters: $filters) {
              items {
                id
                screenName
                avatar {
                  averageColor
                  externalId
                  externalType
                  id
                  originalFilename
                  rightholder
                  status
                  statusCopyrightId
                  thumb
                  url
                }
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            channelId,
            page,
            size,
            filters: { query },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChatChannelGroupCandidatesResponse) =>
        response.data.chatChannelGroupCandidates,
    }),
    chatOrganisationGroupCandidates: builder.query<
      ChatOrganisationGroupCandidatesData,
      ChatOrganisationGroupCandidatesArgs
    >({
      query: ({ page = 1, size = 10, query = '' }) => ({
        url: '/graphql/webapp?chatOrganisationGroupCandidates',
        method: 'POST',
        body: {
          query: `query ChatOrganisationGroupCandidates($page: Int, $size: Int, $filters: GetChatCandidatesFiltersArgsType) {
            chatOrganisationGroupCandidates(page: $page, size: $size, filters: $filters) {
              items {
                id
                screenName
                  avatar {
                  averageColor
                  externalId
                  externalType
                  id
                  originalFilename
                  rightholder
                  status
                  statusCopyrightId
                  thumb
                  url
                }
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            page,
            size,
            filters: { query },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChatOrganisationGroupCandidatesResponse) =>
        response.data.chatOrganisationGroupCandidates,
    }),
    chatMembers: builder.query<ChatMembersData, ChatMembersArgs>({
      query: ({ chatId, page = 1, size = 30, query = '', usersId }) => ({
        url: '/graphql/webapp?chatMembers',
        method: 'POST',
        body: {
          query: `query ChatMembers($chatId: Int!, $page: Int, $size: Int, $filters: GetChatMembersFiltersArgsType) {
            chatMembers(chatId: $chatId, page: $page, size: $size, filters: $filters) {
              items {
                id
                screenName
                chatAccess
                avatar {
                  averageColor
                  externalId
                  externalType
                  id
                  originalFilename
                  rightholder
                  status
                  statusCopyrightId
                  thumb
                  url
                }
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            chatId,
            page,
            size,
            filters: { query, usersId },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChatMembersResponse) => response.data.chatMembers,
    }),
    chatGroupMoreCandidates: builder.query<
      ChatGroupMoreCandidatesData,
      ChatGroupMoreCandidatesArgs
    >({
      query: ({ chatId, page = 1, size = 30, query = '' }) => ({
        url: '/graphql/webapp?chatGroupMoreCandidates',
        method: 'POST',
        body: {
          query: `query ChatGroupMoreCandidates($chatId: Int!, $page: Int, $size: Int, $filters: GetChatCandidatesFiltersArgsType) {
            chatGroupMoreCandidates(chatId: $chatId, page: $page, size: $size, filters: $filters) {
              items {
                id
                screenName
                avatar {
                  averageColor
                  externalId
                  externalType
                  id
                  originalFilename
                  rightholder
                  status
                  statusCopyrightId
                  thumb
                  url
                }
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            chatId,
            page,
            size,
            filters: { query },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChatGroupMoreCandidatesResponse) =>
        response.data.chatGroupMoreCandidates,
    }),
    chatStoryCardsFeed: builder.query<ChatStoryCardsFeedData, ChatStoryCardsFeedArgs>({
      query: ({ chatId, ids = [], page = 1, size = 30 }) => ({
        url: '/graphql/webapp?chatStoryCardsFeed',
        method: 'POST',
        body: {
          query: `query ChatStoryCardsFeed($chatId: Int!, $page: Int, $size: Int, $filters: GetChatStoryCardsFeedFiltersArgs) {
            chatStoryCardsFeed(chatId: $chatId, page: $page, size: $size, filters: $filters) {
              payload {
                items {
                  ${storyCardQuery({ isFeed: true, isAdminLayout: isAdminLayout() })}
                }
              }
            }
          }`,
          variables: {
            chatId,
            page,
            size,
            filters: { ids },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChatStoryCardsFeedResponse) =>
        response.data.chatStoryCardsFeed.payload,
    }),
  }),
});

export const { useGetChannelChatsQuery } = chatsApi;
export const { useChatMembersQuery, useChatGroupMoreCandidatesQuery } = graphqlChatsApi;
