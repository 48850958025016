import { useCallback, useContext } from 'react';
import { ChannelContext } from '../../context';
import { ChannelAppTabAction } from '../../services';
import { getLocale } from '../utils';

export const useAppTabsLabel = () => {
  const { allAppTabs } = useContext(ChannelContext);

  const getAppTabsLabel = useCallback(
    (actionType: ChannelAppTabAction) => {
      const { title } = { ...allAppTabs.find(({ action: { type } }) => type === actionType) };

      if (!title) {
        return '';
      }

      return title.find(({ language }) => language === getLocale())?.localization ?? '';
    },
    [allAppTabs]
  );

  return { getAppTabsLabel };
};
