import { ResponsivePie } from '@nivo/pie';
import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context';
import {
  useUserRoleContentEngagementAvgQuery,
  useUserRoleContentEngagementQuery,
  useUserRoleContentPublishedQuery,
  useUserRoleContentViewAvgQuery,
  useUserRoleContentViewQuery,
} from '../../../../services';
import { ANALYTICS_ADMIN_COOKIE_KEY, cookieOptions, Select } from '../../../../shared';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader } from '../../helpers';
import { UserProps } from '../User';

import classes from '../../Analytics.module.scss';

enum UserRoleContentType {
  PUBLISHED = 'PUBLISHED',
  VIEW = 'VIEW',
  VIEW_AVG = 'VIEW_AVG',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_AVG = 'ENGAGEMENT_AVG',
}

export const UserRoleContent: FunctionComponent<UserProps> = memo(({ from, to, platform }) => {
  const { t } = useTranslation();

  const { channelId: selectedChannelId } = useContext(UserContext).userInfo.userData;

  const COOKIE_KEY = useMemo(
    () => `${ANALYTICS_ADMIN_COOKIE_KEY}_${selectedChannelId}_userRoleContent`,
    [selectedChannelId]
  );

  const [cookies, setCookie] = useCookies([COOKIE_KEY]);

  const { contentType = UserRoleContentType.PUBLISHED } = { ...cookies[COOKIE_KEY] };

  const dropdownItems = useMemo(() => {
    return Object.values(UserRoleContentType).map((value) => {
      return { id: value, title: t(`userRoleContentTypes.${value}`) };
    });
  }, [t]);

  const contentTypeChange = useCallback(
    (contentType: UserRoleContentType) => {
      setCookie(COOKIE_KEY, { ...cookies[COOKIE_KEY], contentType }, cookieOptions());
    },
    [COOKIE_KEY, cookies, setCookie]
  );

  const contentQuery = useMemo(() => {
    switch (contentType) {
      case UserRoleContentType.VIEW:
        return useUserRoleContentViewQuery;
      case UserRoleContentType.VIEW_AVG:
        return useUserRoleContentViewAvgQuery;
      case UserRoleContentType.ENGAGEMENT:
        return useUserRoleContentEngagementQuery;
      case UserRoleContentType.ENGAGEMENT_AVG:
        return useUserRoleContentEngagementAvgQuery;
      default:
        return useUserRoleContentPublishedQuery;
    }
  }, [contentType]);

  const { data: userRoleContent, isFetching: isFetchingUserRoleContent } = contentQuery({
    from,
    to,
    platform,
  });

  const renderChart = useMemo(() => {
    if (isFetchingUserRoleContent) {
      return <Loader />;
    }

    if (!userRoleContent) {
      return null;
    }

    const { items } = userRoleContent;

    if (!items.filter(({ value }) => Boolean(value)).length) {
      return <div className={classes['analytics__chart-no-records']}>{t('common.no-records')}</div>;
    }

    const data = items.map(({ label, value }) => {
      const localizedLabel = t(`roles.${label}`);

      return {
        id: localizedLabel,
        label: localizedLabel,
        value,
      };
    });

    return (
      <div className={classes['analytics__chart-height']}>
        <ResponsivePie
          margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
          data={data}
          colors={COLORS}
          innerRadius={0.35}
          activeOuterRadiusOffset={6}
          arcLinkLabelsTextColor={{ from: 'color' }}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLinkLabelsThickness={2}
          arcLinkLabelsSkipAngle={1}
          arcLabelsSkipAngle={1}
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              translateY: 20,
              itemWidth: 100,
              itemHeight: 60,
              itemTextColor: '#999',
              itemDirection: 'top-to-bottom',
              symbolShape: 'circle',
              toggleSerie: true,
              effects: [{ on: 'hover', style: { itemTextColor: '#000' } }],
            },
          ]}
          theme={{ labels: { text: { fontSize: 14 } } }}
        />
      </div>
    );
  }, [isFetchingUserRoleContent, t, userRoleContent]);

  return (
    <ChartWrapper
      typeLocaliseKey={'chartTypes.user-role-content'}
      infoLocaliseKey={'UserRoleContent.info'}
      component={
        <Select
          className={classes['analytics__chart-header-select']}
          selectedItemId={contentType}
          items={dropdownItems}
          onChange={(contentType) => contentTypeChange(contentType as UserRoleContentType)}
        />
      }
    >
      {renderChart}
    </ChartWrapper>
  );
});
