import { IS_PRODUCTION, TCHOP_ROOT_URL } from '../../env';
import { DeeplinkType } from '../components';
import { getOrganisationDomain } from './getOrganisationDomain';

interface DeepLinkUrlProps {
  type: DeeplinkType;
  channelId: number;
  userId?: number;
  storyId?: number;
  cardId?: number;
  chatId?: number;
}

export const getDeepLinkUrl = ({
  type,
  channelId,
  userId,
  storyId,
  cardId,
  chatId,
}: DeepLinkUrlProps) => {
  if (!IS_PRODUCTION) {
    return '';
  }

  const { protocol = '', host = '' } = new URL(TCHOP_ROOT_URL ?? '');

  const appsPath = `${protocol}//${getOrganisationDomain().replace(/_/g, '')}.${host}/apps`;

  switch (type) {
    case DeeplinkType.CHANNEL:
      return `${appsPath}/channels/${channelId}`;
    case DeeplinkType.STORY:
      return `${appsPath}/stories/${channelId}/${storyId}`;
    case DeeplinkType.USER:
      return `${appsPath}/users/${channelId}/${userId}`;
    case DeeplinkType.CARD:
      return `${appsPath}/posts/${channelId}/${storyId}/${cardId}`;
    case DeeplinkType.DRAFT:
      return `${appsPath}/posts/${channelId}/${storyId}/${cardId}?draft=1`;
    case DeeplinkType.CHAT:
      return `${appsPath}/chats/${channelId}/${chatId}`;
    default:
      return '';
  }
};
