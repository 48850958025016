import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  StoryCard,
  StoryCardContentArticleQuote,
  StoryCardPdfContent,
  StoryCardStatus,
} from '../../../services';
import { reactOnCard, togglePublishOnCard } from '../../../slices';
import {
  useAppDispatch,
  useCardEdit,
  useRefreshFeeds,
  useRefreshStoryCardsUserStats,
} from '../../hooks';
import { getCssVar } from '../../utils';
import { IconLabel, IconLabelHintDirection } from '../IconLabel';
import { PublishToggle } from '../PublishToggle';
import { ScheduleIcon } from '../ScheduleIcon';
import { CommentsCounter } from './CommentsCounter';
import { ReactionSelect } from './ReactionSelect';
import { Share } from './Share';

export interface ActionPaneProps {
  card: StoryCard;
  myReaction: string;
  commentsCount: number;
  commentsCounterClick: () => void;
  isProfileFeedCard?: boolean;
}

export const ActionPane: FunctionComponent<ActionPaneProps> = memo(
  ({ card, myReaction, commentsCount, commentsCounterClick, isProfileFeedCard }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { refreshFeeds } = useRefreshFeeds({ scrollTop: true });

    const { refreshStoryCardsUserStats } = useRefreshStoryCardsUserStats();

    const {
      id: cardId,
      options,
      status: cardStatus,
      permissions,
      storyId,
      postingTime,
      postedAt,
      content,
    } = card;

    const { url: articleQuoteUrl } = content as StoryCardContentArticleQuote;

    const { gallery } = content as StoryCardPdfContent;

    const pdfUrl = useMemo(() => gallery?.[0]?.pdf?.url, [gallery]);

    const { cardEditModal, cardEditHandler } = useCardEdit({ storyCard: card, isProfileFeedCard });

    const { allowCardReaction, allowComment, allowSharing } = options;

    const { allowToUpdate, allowToUpdateStatus } = permissions;

    const cardPublished = useMemo(() => cardStatus === StoryCardStatus.PUBLISHED, [cardStatus]);

    const status = useMemo(
      () => (cardPublished ? StoryCardStatus.UNPUBLISHED : StoryCardStatus.PUBLISHED),
      [cardPublished]
    );

    const [publishToggling, setPublishToggling] = useState(false);

    const toggleStatus = useCallback(async () => {
      setPublishToggling(true);

      try {
        const { payload } = await toast.promise(
          dispatch(
            togglePublishOnCard({
              storyId,
              storyCardId: cardId,
              fields: { status },
            })
          ).unwrap(),
          {
            pending: t(`publishToggle.${status}-toggling`),
            success: t(`publishToggle.${status}-toggled`),
            error: t(`publishToggle.${status}-error`),
          }
        );

        refreshStoryCardsUserStats();

        if (payload.postedAt !== postedAt) {
          refreshFeeds();
        }
      } catch (_) {
        toast(t(`publishToggle.${status}-error`));
      } finally {
        setPublishToggling(false);
      }
    }, [cardId, dispatch, postedAt, refreshFeeds, refreshStoryCardsUserStats, status, storyId, t]);

    const onReactionSelect = useCallback(
      (reaction: string) => {
        dispatch(reactOnCard({ storyId, cardId, reaction }));
      },
      [cardId, dispatch, storyId]
    );

    return (
      <>
        {allowCardReaction && (
          <ReactionSelect myReaction={myReaction} onSelect={onReactionSelect} />
        )}

        {allowComment && (
          <CommentsCounter commentsCount={commentsCount} onClick={commentsCounterClick} />
        )}

        {allowSharing && (
          <Share
            storyId={storyId}
            cardId={cardId}
            articleQuoteUrl={articleQuoteUrl}
            pdfUrl={pdfUrl}
          />
        )}

        {postingTime && (
          <ScheduleIcon postingTime={postingTime} hintDirection={IconLabelHintDirection.bottom} />
        )}

        {allowToUpdateStatus && (
          <PublishToggle
            isPublished={cardPublished}
            onClick={toggleStatus}
            disabled={publishToggling}
          />
        )}

        {allowToUpdate && !isMobileOnly && (
          <>
            <IconLabel
              iconId={'edit'}
              iconSize={18}
              color={getCssVar('--action-pane-icon-color')}
              hoverColor={getCssVar('--action-pane-icon-hover-color')}
              onClick={cardEditHandler}
            />
            {cardEditModal}
          </>
        )}
      </>
    );
  }
);
