import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { UserContext } from '../../../../context';
import { StoryCardVideoContent } from '../../../../services';
import { useAnalytics } from '../../../hooks';
import { FileStatus } from '../../../models';
import { VideoPlayer } from '../../VideoPlayer';
import { FeedCardFileError } from '../FeedCardFileError';
import { FeedCardMargin } from '../FeedCardMargin';
import { FeedCardProps } from '../model';

export const FeedCardVideoCommon: FunctionComponent<FeedCardProps> = memo(
  ({ card, isProfileFeedCard }) => {
    const { channelId } = useContext(UserContext).userInfo.userData;

    const { logItemPlayed, logClickOpenArticle } = useAnalytics();

    const { id, storyId, content } = card;

    const { gallery } = content as StoryCardVideoContent;

    const { image, video, title } = gallery[0];

    const logCommonProps = useMemo(() => {
      return {
        channel_id: channelId,
        story_id: storyId,
        item_id: id,
        time_stamp: new Date().toISOString(),
      };
    }, [channelId, id, storyId]);

    const onLogItemPlayed = useCallback(
      (duration: number) => {
        if (isProfileFeedCard) {
          return;
        }

        logItemPlayed({ ...logCommonProps, duration });
      },
      [isProfileFeedCard, logCommonProps, logItemPlayed]
    );

    const onLogClickOpenArticle = useCallback(() => {
      if (isProfileFeedCard) {
        return;
      }

      logClickOpenArticle({ ...logCommonProps });
    }, [isProfileFeedCard, logClickOpenArticle, logCommonProps]);

    const videoContent = useMemo(() => {
      switch (video.status) {
        case FileStatus.ERROR:
          return (
            <FeedCardMargin top right bottom left>
              <FeedCardFileError />
            </FeedCardMargin>
          );
        case FileStatus.PROCESSING:
          return (
            <FeedCardMargin top right bottom left>
              <Skeleton height={'5rem'} />
            </FeedCardMargin>
          );
        default: {
          return (
            <VideoPlayer
              url={video?.url}
              thumb={image?.url ?? video?.thumb}
              caption={title}
              copyright={image?.rightholder}
              onLogItemPlayed={onLogItemPlayed}
              onLogClickOpenArticle={onLogClickOpenArticle}
            />
          );
        }
      }
    }, [
      image?.rightholder,
      image?.url,
      onLogClickOpenArticle,
      onLogItemPlayed,
      title,
      video.status,
      video?.thumb,
      video?.url,
    ]);

    return videoContent;
  }
);
