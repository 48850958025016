import { FunctionComponent, memo } from 'react';
import { StoryCardVideoContent } from '../../../services';
import { FeedCardContentCommon, FeedCardProps, FeedCardVideoCommon } from '../../../shared';

export const FeedCardAdminVideo: FunctionComponent<FeedCardProps> = memo(({ card }) => {
  const { storyId, id, type, content, isPinnedToTopOfNewsFeed } = card;
  const { sourceName, headline, subHeadline, text, textHTML } = content as StoryCardVideoContent;

  return (
    <FeedCardContentCommon
      storyId={storyId}
      cardId={id}
      type={type}
      pinned={isPinnedToTopOfNewsFeed}
      source={sourceName}
      title={headline}
      media={<FeedCardVideoCommon card={card} />}
      subTitle={subHeadline}
      text={text}
      htmlText={textHTML}
    />
  );
});
