import { Dispatch, SetStateAction, createContext } from 'react';

export interface MusicPlayerData {
  channelId: number;
  storyId: number;
  cardId: number;
  cardTitle: string;
  src: string;
  isPlaying: boolean;
  chatId?: number;
}

export interface MusicPlayerContextProps {
  musicPlayerData: MusicPlayerData;
  setMusicPlayerData: Dispatch<SetStateAction<MusicPlayerData>>;
}

export const MusicPlayerContext = createContext<MusicPlayerContextProps>(
  {} as MusicPlayerContextProps
);
