import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { Config, User } from '../../services';
import {
  cookieOptions,
  FREE_LOGIN_COOKIE_KEY,
  FREE_LOGIN_USER_ID_STORAGE_KEY,
  generateRandomString,
  getOrganisationDomain,
  useDeepLink,
} from '../../shared';

interface FreeLoginProps {
  freeLoginHandler: () => void;
  userProfile?: User;
  config?: Config;
}

export const useFreeLogin = ({ freeLoginHandler, userProfile, config }: FreeLoginProps) => {
  const { isDeepLink } = useDeepLink();

  const cookieKey = useMemo(() => `${FREE_LOGIN_COOKIE_KEY}_${getOrganisationDomain()}`, []);

  const [cookies, setCookie] = useCookies([cookieKey]);

  const { freeLoginSessionsCount = 0 } = { ...cookies[cookieKey] };

  useEffect(() => {
    if (!config || !userProfile?.isAnonymous) {
      return;
    }

    if (!localStorage.getItem(FREE_LOGIN_USER_ID_STORAGE_KEY)) {
      localStorage.setItem(FREE_LOGIN_USER_ID_STORAGE_KEY, generateRandomString(20));
    }

    if (isDeepLink) {
      return;
    }

    const { sessionsLimit = 0 } = { ...config.elements.freeLogin };

    const sessionCount = freeLoginSessionsCount + 1;

    if (sessionCount % sessionsLimit === 0) {
      setTimeout(() => freeLoginHandler(), 1500);
    }

    setCookie(cookieKey, { freeLoginSessionsCount: sessionCount }, cookieOptions());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, userProfile?.isAnonymous, isDeepLink]);
};
