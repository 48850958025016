import { NavigateFunction } from 'react-router-dom';
import { getWebAppUrl } from './getWebAppUrl';

interface OpenStoryCardDetailsProps {
  navigate: NavigateFunction;
  channelSubdomain: string;
  storyId: number;
  cardId: number;
}

export const openStoryCardDetails = ({
  navigate,
  channelSubdomain,
  storyId,
  cardId,
}: OpenStoryCardDetailsProps) => {
  const path = `/details/${storyId}/${cardId}`;

  const targetOrigin = `${getWebAppUrl(channelSubdomain)}`;

  window.location.origin === targetOrigin
    ? navigate(path)
    : window.open(`${targetOrigin}${path}`, '_blank', 'noreferrer');
};
