import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './NotificationMentionContent.module.scss';

interface NotificationMentionContentProps {
  authorName: string;
  categoryName: string;
}

export const NotificationMentionContent: FunctionComponent<NotificationMentionContentProps> = memo(
  ({ authorName, categoryName }) => {
    const { t } = useTranslation();

    return (
      <div className={classes['content']}>
        <strong>{authorName}</strong>&nbsp;
        {t('channelNotifications.mentioned', { category: categoryName })}
      </div>
    );
  }
);
