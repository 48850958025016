import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import classes from './FeedCardPlaceholder.module.scss';

export const FeedCardPlaceholder: FunctionComponent = () => {
  return (
    <div className={classes['card']}>
      <div className={classes['card__header']}>
        <Skeleton circle height={48} width={48} />
        <div className={classes['card__header-author']}>
          <Skeleton height={20} width={150} />
          <Skeleton height={20} width={250} />
        </div>
      </div>
      <div className={classes['card__content']}>
        <Skeleton height={300} />
        <div className={classes['card__content-text']}>
          <Skeleton height={20} />
          <Skeleton height={20} width={250} />
        </div>
      </div>
    </div>
  );
};
