import { Channel } from './channelsNew';
import { graphqlApi } from './graphqlApi';

interface OidConnection {
  clientId: string;
  scopes: string;
  algorithm: string;
  audience: string;
  name: string;
  authorizationEndpoint: string;
  tokenEndpoint: string;
  issuer: string;
  endSessionEndpoint: string;
}

interface OidConnectionsResponse {
  data?: {
    oidConnections: OidConnection[];
  };
}

interface OidcLoginArgs {
  issuer: string;
  accessToken: string;
}

interface OidcLoginResponsePayload {
  accessToken: string;
  channel: Channel;
}

interface OidcLoginResponse {
  data: {
    oidcLogin: {
      payload: OidcLoginResponsePayload;
    };
  };
}

interface OidcExchangeCodeToAccessTokenArgs {
  code: string;
  codeVerifier: string;
  issuer: string;
  redirectUrl: string;
}

interface OidcExchangeCodeToAccessTokenPayload {
  accessToken: string;
}

interface OidcExchangeCodeToAccessTokenResponse {
  data: {
    oidcExchangeCodeToAccessToken: {
      payload: OidcExchangeCodeToAccessTokenPayload;
    };
  };
}

export const oidApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    oidConnections: builder.query<OidConnection[], void>({
      query: () => ({
        url: '/graphql/webapp?oidConnections',
        method: 'POST',
        body: {
          query: `query OidConnections {
            oidConnections {
              clientId
              scopes
              algorithm
              audience
              name
              authorizationEndpoint
              tokenEndpoint
              issuer
              endSessionEndpoint
            }
          }`,
        },
      }),
      transformResponse: (response: OidConnectionsResponse) => response.data?.oidConnections ?? [],
    }),
    oidcLogin: builder.query<OidcLoginResponsePayload, OidcLoginArgs>({
      query: ({ issuer, accessToken }) => ({
        url: '/graphql/webapp?oidcLogin',
        method: 'POST',
        body: {
          query: `mutation OidcLogin($input: OidcLoginInput!) {
            oidcLogin(input: $input) {
              payload {
                accessToken
                channel {
                  subdomain
                }
              }
            }
          }`,
          variables: {
            input: { issuer, accessToken },
          },
        },
      }),
      transformResponse: (response: OidcLoginResponse) => response.data.oidcLogin.payload,
    }),
    oidcExchangeCodeToAccessToken: builder.query<
      OidcExchangeCodeToAccessTokenPayload,
      OidcExchangeCodeToAccessTokenArgs
    >({
      query: ({ code, codeVerifier, issuer, redirectUrl }) => ({
        url: '/graphql/webapp?oidcExchangeCodeToAccessToken',
        method: 'POST',
        body: {
          query: `mutation OidcExchangeCodeToAccessToken($input: OidcExchangeCodeToAccessTokenInput!) {
            oidcExchangeCodeToAccessToken(input: $input) {
              payload {
                accessToken
              }
            }
          }`,
          variables: {
            input: { code, codeVerifier, issuer, redirectUrl },
          },
        },
      }),
      transformResponse: (response: OidcExchangeCodeToAccessTokenResponse) =>
        response.data.oidcExchangeCodeToAccessToken.payload,
    }),
  }),
});

export const { useOidConnectionsQuery, useOidcLoginQuery, useOidcExchangeCodeToAccessTokenQuery } =
  oidApi;
