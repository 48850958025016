import { StoryCardType } from '../../../../../services';
import { GalleryItem } from '../../../../models';
import { LinkPostArticleFields, LinkPostQuoteFields, PostType } from '../CreateCardModal';

interface GetPostInCardTypeArgs {
  postType: PostType;
  shortPostGallery: GalleryItem[];
  linkPostArticle?: LinkPostArticleFields;
  linkPostQuote?: LinkPostQuoteFields;
}

export const getPostInCardType = ({
  postType,
  shortPostGallery,
  linkPostArticle,
  linkPostQuote,
}: GetPostInCardTypeArgs) => {
  const isShortPost = postType === PostType.SHORT;

  const isLinkPost = postType === PostType.LINK;

  switch (true) {
    case postType === PostType.THREAD: {
      return StoryCardType.THREAD;
    }
    case postType === PostType.FULL: {
      return StoryCardType.POST;
    }
    case Boolean(isShortPost && !shortPostGallery.length): {
      return StoryCardType.EDITORIAL;
    }
    case Boolean(
      isShortPost && shortPostGallery.find((galleryItem) => Boolean(galleryItem?.audio))
    ): {
      return StoryCardType.AUDIO;
    }
    case Boolean(
      isShortPost && shortPostGallery.find((galleryItem) => Boolean(galleryItem?.video))
    ): {
      return StoryCardType.VIDEO;
    }
    case Boolean(
      isShortPost && shortPostGallery.find((galleryItem) => Boolean(galleryItem?.pdf))
    ): {
      return StoryCardType.PDF;
    }
    case Boolean(
      isShortPost && shortPostGallery.find((galleryItem) => Boolean(galleryItem?.image))
    ): {
      return StoryCardType.IMAGE;
    }
    case Boolean(isLinkPost && linkPostArticle): {
      return StoryCardType.ARTICLE;
    }
    case Boolean(isLinkPost && linkPostQuote): {
      return StoryCardType.QUOTE;
    }
  }
};
