import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { isChrome, isMobileOnly, isSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { ConfigContext, UserContext } from '../../context';
import { useOrganisationDownloadAppLinkRequestForUserQuery } from '../../services';
import { Button, ButtonType, IconLabel, Modal, useDeepLink } from '../../shared';

import classes from './DeepLinkModal.module.scss';

export const DeepLinkModal: FunctionComponent = () => {
  const { t } = useTranslation();

  const { appIconUrl, appName } = useContext(ConfigContext).config.elements.deeplink;

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { isDeepLink } = useDeepLink();

  const { data: appLink } = useOrganisationDownloadAppLinkRequestForUserQuery({ channelId });

  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (!isMobileOnly || !isDeepLink) {
      return;
    }

    if (appLink?.link && typeof isOpen === 'undefined') {
      setIsOpen(true);
    }
  }, [appLink?.link, isDeepLink, isOpen]);

  const iconId = useMemo(() => {
    switch (true) {
      case isChrome:
        return 'chrome';
      case isSafari:
        return 'safari';
      default:
        return 'browser-default';
    }
  }, []);

  const label = useMemo(() => {
    switch (true) {
      case isChrome:
        return 'Chrome';
      case isSafari:
        return 'Safari';
      default:
        return 'Browser';
    }
  }, []);

  const body = useMemo(() => {
    return (
      <div className={classes['deep-link-modal']}>
        <div className={classes['deep-link-modal__header']}>{t('deepLink.header')}</div>
        <div className={classes['deep-link-modal__option']}>
          <div className={classes['deep-link-modal__option-app']}>
            <img width={24} height={24} src={appIconUrl} alt={'app logo'} />
            <IconLabel label={appName} nonClickable />
          </div>
          <Button
            className={classes['deep-link-modal__option-button']}
            type={ButtonType.secondary}
            label={t('deepLink.open')}
            onClick={() => window.location.replace(appLink?.link ?? '')}
          />
        </div>
        <div className={classes['deep-link-modal__option']}>
          <IconLabel iconId={iconId} label={label} nonClickable />
          <Button
            className={classes['deep-link-modal__option-button']}
            type={ButtonType.secondary}
            label={t('common.continue')}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
    );
  }, [appIconUrl, appLink?.link, appName, iconId, label, t]);

  return <Modal isOpen={Boolean(isOpen)} body={body} alignBottom keepOpened />;
};
