/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  organisationApi,
  OrganisationTag,
  OrganisationTagsFiltersArgs,
  OrganisationTagType,
} from '../../../../services';
import { FilterGroupAutoComplete, FilterValue } from '../../../components';
import { useFilter } from '../useFilter';

import classes from './useTagsFilter.module.scss';

interface UseTagsFilterArgs {
  filter: FilterValue[];
  types: OrganisationTagType[];
}

interface OrganisationTagsQueryArgs {
  query: string;
  filters: OrganisationTagsFiltersArgs;
  page?: number;
}

export const useTagsFilter = ({ filter, types }: UseTagsFilterArgs) => {
  const { t } = useTranslation();

  const { getGroupById } = useFilter();

  const [organisationTags] = organisationApi.endpoints.organisationTags.useLazyQuery();

  const fetchItemsQuery = useCallback(
    ({ query, filters, page }: OrganisationTagsQueryArgs) =>
      organisationTags({ page, filters: { ...filters, query } }),
    [organisationTags]
  );

  const type = useMemo(() => types[0], [types]);

  const groupId = useMemo(() => `tags-${type}`, [type]);

  const itemTemplate = useCallback(
    ({ name }: OrganisationTag) => <div className={classes['name']}>{name}</div>,
    []
  );

  const fetchItemsQueryArgs = useMemo(() => {
    return { filters: { types } };
  }, [types]);

  const autoComplete: FilterGroupAutoComplete = {
    propToIdentify: 'id',
    propToDisplay: 'name',
    fetchItemsQuery,
    fetchItemsQueryArgs,
    itemTemplate,
    placeholder: t('common.type-to-search'),
    excludeLabel: t(`organisationTags.exclude-${type}`),
  };

  return {
    group: {
      id: groupId,
      title: t(`organisationTags.title-${type}`),
      value: getGroupById({ filter, groupId })?.groupItems ?? [],
      allowReset: true,
      autoComplete,
    },
  };
};
