import {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { UserContext } from '../../../context';
import {
  StoryCardContentArticleQuote,
  StoryCardContentGallery,
  StoryCardQuoteContent,
  StoryCardType,
} from '../../../services';
import {
  FeedCardProps,
  GalleryItem,
  IconLabel,
  getChannelDomain,
  getCssVar,
  getStoryCardPreviewUrl,
  getWebAppUrl,
  useAnalytics,
  useCardDate,
} from '../../../shared';

import classNames from 'classnames';
import classes from './FeedCardPreviewWrapper.module.scss';

export const FeedCardPreviewWrapper: FunctionComponent<PropsWithChildren<FeedCardProps>> = memo(
  ({ card, children }) => {
    const { logClickOpenArticle } = useAnalytics();

    const { channelId } = useContext(UserContext).userInfo.body;

    const { id: cardId, storyId, type, dateToDisplay, content } = card;

    const date = useCardDate(dateToDisplay);

    const { url } = content as StoryCardContentArticleQuote;

    const { gallery } = content as StoryCardContentGallery;

    const { quoteSource } = content as StoryCardQuoteContent;

    const galleryItem = gallery ? (gallery[0] as GalleryItem) : undefined;

    const previewUrl = getStoryCardPreviewUrl({ type, galleryItem });

    const isImageGallery = useMemo(
      () =>
        [StoryCardType.IMAGE, StoryCardType.QUOTE].includes(type) && gallery && gallery.length > 1,
      [gallery, type]
    );

    const shouldRenderOverlay = useMemo(
      () =>
        isImageGallery ||
        (previewUrl &&
          [StoryCardType.VIDEO, StoryCardType.AUDIO, StoryCardType.PDF].includes(type)),
      [isImageGallery, previewUrl, type]
    );

    const cardClickHandler = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((event.target as HTMLElement).tagName.toLowerCase() === 'a') {
          event.preventDefault();
        }

        let redirectUrl = `${getWebAppUrl(getChannelDomain())}/details/${storyId}/${cardId}`;

        if (url && [StoryCardType.ARTICLE, StoryCardType.QUOTE].includes(type)) {
          logClickOpenArticle({
            channel_id: channelId,
            story_id: storyId,
            item_id: cardId,
            time_stamp: new Date().toISOString(),
          });

          redirectUrl = url;
        }

        window.open(`${redirectUrl}`, '_blank', 'noreferrer');
      },
      [cardId, channelId, logClickOpenArticle, storyId, type, url]
    );

    const iconId = useMemo(() => {
      switch (type) {
        case StoryCardType.ARTICLE:
        case StoryCardType.POST:
          return 'article';
        case StoryCardType.IMAGE:
          return isImageGallery ? 'gallery' : type.toLowerCase();
        case StoryCardType.QUOTE:
          return isImageGallery ? 'gallery' : quoteSource?.toLowerCase();
        default:
          return type.toLowerCase();
      }
    }, [isImageGallery, quoteSource, type]);

    const iconColor = useMemo(
      () => (!previewUrl ? getCssVar('--card-overlay-icon-color') : '#ffffff'),
      [previewUrl]
    );

    return (
      <div className={classes['card']} onClick={cardClickHandler}>
        <div
          className={classNames(classes['card__teaser'], {
            [classes['card__teaser--placeholder']]: !previewUrl,
            [classes['card__teaser--overlay']]: shouldRenderOverlay,
          })}
          {...(previewUrl && { style: { backgroundImage: `url(${previewUrl})` } })}
        >
          {(!previewUrl || shouldRenderOverlay) && (
            <IconLabel
              className={classes['card__teaser-icon']}
              iconId={iconId}
              iconSize={32}
              color={iconColor}
              singleColor
            />
          )}
        </div>
        <div className={classes['card__content']}>
          {children}
          {date && <div className={classes['card__posted-time']}>{date}</div>}
        </div>
      </div>
    );
  }
);
