import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
  StoryCardCategoryTemplate,
  useStoryCardCategoryDefaultTemplatesQuery,
} from '../../../../../services';
import { Button, ButtonType } from '../../../../../shared';

import classNames from 'classnames';
import classes from './DefaultTemplates.module.scss';

interface DefaultTemplatesProps {
  templates: StoryCardCategoryTemplate[];
  setTemplates: (templates: StoryCardCategoryTemplate[]) => void;
  addTemplatesCallback: () => void;
}

export const DefaultTemplates: FunctionComponent<DefaultTemplatesProps> = memo(
  ({ templates, setTemplates, addTemplatesCallback }) => {
    const { t } = useTranslation();

    const { data: defaultTemplates = [] } = useStoryCardCategoryDefaultTemplatesQuery();

    const [selectedTemplates, setSelectedTemplates] = useState<
      (StoryCardCategoryTemplate | undefined)[]
    >([]);

    const selectedTemplatesFiltered = useMemo(
      () => selectedTemplates.filter((template) => Boolean(template)),
      [selectedTemplates]
    ) as StoryCardCategoryTemplate[];

    const templatesCount = useMemo(
      () => selectedTemplatesFiltered.length,
      [selectedTemplatesFiltered.length]
    );

    const onTemplateSelect = useCallback(
      (index: number) => {
        const selectedTemplatesCopy = [...selectedTemplates];

        selectedTemplatesCopy[index] = selectedTemplatesCopy[index]
          ? undefined
          : defaultTemplates[index];

        setSelectedTemplates(selectedTemplatesCopy);
      },
      [defaultTemplates, selectedTemplates]
    );

    const addTemplates = useCallback(() => {
      setTemplates([...templates, ...selectedTemplatesFiltered]);
      addTemplatesCallback();
    }, [addTemplatesCallback, selectedTemplatesFiltered, setTemplates, templates]);

    const renderList = useMemo(() => {
      if (!defaultTemplates.length) {
        return (
          <>
            <Skeleton width={'13.325rem'} height={'9rem'} />
            <Skeleton width={'13.325rem'} height={'9rem'} />
            <Skeleton width={'13.325rem'} height={'9rem'} />
          </>
        );
      }

      return (
        <>
          {defaultTemplates.map(({ name, text, gallery }, index) => {
            return (
              <div
                key={`template-${index}`}
                className={classNames(classes['templates__list-item'], {
                  [classes['templates__list-item--selected']]: Boolean(selectedTemplates[index]),
                })}
                onClick={() => onTemplateSelect(index)}
              >
                <div
                  className={classes['templates__list-item-image']}
                  style={{ backgroundImage: `url(${gallery[0].image.url})` }}
                ></div>
                <div className={classes['templates__list-item-name']}>{name}</div>
                <div className={classes['templates__list-item-text']}>
                  {text.replace(/(.{70})..+/, '$1…')}
                </div>
              </div>
            );
          })}
        </>
      );
    }, [defaultTemplates, onTemplateSelect, selectedTemplates]);

    return (
      <div className={classes['templates']}>
        <div className={classes['templates__title']}>
          {t('storyCardCategoryDefaultTemplates.title')}
        </div>
        <div className={classes['templates__list']}>{renderList}</div>
        <Button
          type={ButtonType.primary}
          className={classes['templates__button']}
          label={t('storyCardCategoryDefaultTemplates.add-templates', {
            count: templatesCount,
          })}
          disabled={!templatesCount}
          onClick={addTemplates}
        />
      </div>
    );
  }
);
