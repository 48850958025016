import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChatStoryCardByIdArgs, StoryCard, storyCardApi, StoryCardByIdArgs } from '../services';
import {
  addComment,
  deleteCard,
  deleteComment,
  editCard,
  editComment,
  hideComment,
  highlightComment,
  loadMoreComments,
  reactOnCard,
  reactOnComment,
  repostCard,
  toggleCardSection,
} from './common';
import {
  addCommentReducer,
  deleteCommentReducer,
  editCommentReducer,
  hideCommentReducer,
  highlightCommentReducer,
  reactOnCardReducer,
  reactOnCommentReducer,
} from './extraReducers';

interface CardDetailsModalState {
  card?: StoryCard;
  isFetching: boolean;
}

const initialState: CardDetailsModalState = {
  card: undefined,
  isFetching: false,
};

export const getCardModal = createAsyncThunk(
  'getCardModal',
  async (args: StoryCardByIdArgs, { dispatch }) => {
    return await dispatch(storyCardApi.endpoints.storyCardById.initiate(args)).unwrap();
  }
);

export const getChatCardModal = createAsyncThunk(
  'getChatCardModal',
  async (args: ChatStoryCardByIdArgs, { dispatch }) => {
    return await dispatch(storyCardApi.endpoints.chatStoryCardById.initiate(args)).unwrap();
  }
);

const cardDetailsModalSlice = createSlice({
  name: 'cardDetailsModal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCardModal.pending, (state) => {
        state.card = undefined;
        state.isFetching = true;
      })
      .addCase(getCardModal.fulfilled, (state, action) => {
        state.card = action.payload;
        state.isFetching = false;
      })
      .addCase(getChatCardModal.pending, (state) => {
        state.card = undefined;
        state.isFetching = true;
      })
      .addCase(getChatCardModal.fulfilled, (state, action) => {
        state.card = action.payload;
        state.isFetching = false;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        const { itemId, comment } = action.payload;

        const { card } = state;

        if (!card || itemId !== card.id) {
          return;
        }

        addCommentReducer({ card, comment });
      })
      .addCase(editComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;
        const { card } = state;

        if (!card || storyCardId !== card.id) {
          return;
        }

        editCommentReducer({ card, comment });
      })
      .addCase(hideComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;
        const { card } = state;

        if (!card || storyCardId !== card.id) {
          return;
        }

        hideCommentReducer({ card, comment });
      })
      .addCase(highlightComment.fulfilled, (state, action) => {
        const { comment } = action.payload;
        const { storyCardId } = comment;
        const { card } = state;

        if (!card || storyCardId !== card.id) {
          return;
        }

        highlightCommentReducer({ card, comment });
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        const { deleted, cardId, commentId, parentId } = action.payload;

        if (!deleted) {
          return;
        }

        const { card } = state;

        if (!card || cardId !== card.id) {
          return;
        }

        deleteCommentReducer({ card, commentId, parentId });
      })
      .addCase(reactOnComment.fulfilled, (state, action) => {
        const { reactions, cardId, commentId } = action.payload;
        const { card } = state;

        if (!card || cardId !== card.id) {
          return;
        }

        reactOnCommentReducer({ card, commentId, reactions });
      })
      .addCase(reactOnCard.fulfilled, (state, action) => {
        const { cardId, reaction, reactions } = action.payload;
        const { card } = state;

        if (!card || cardId !== card.id) {
          return;
        }

        reactOnCardReducer({ card, reaction, reactions });
      })
      .addCase(loadMoreComments.fulfilled, (state, action) => {
        const { itemId, items, parentId } = action.payload;
        const { card } = state;

        if (!card || itemId !== card.id) {
          return;
        }

        const { comments } = card;

        if (parentId) {
          const replies = comments.find(({ id }) => id === parentId)?.replies;

          items.forEach((reply) => {
            if (!replies?.find(({ id }) => id === reply.id)) {
              replies?.push(reply);
            }
          });
        } else {
          comments.push(...items.filter(({ id }) => !comments.map(({ id }) => id)?.includes(id)));
        }
      })
      .addCase(editCard, (state, action) => {
        const { id: storyCardId } = action.payload;

        if (!state.card || state.card.id !== storyCardId) {
          return;
        }

        state.card = action.payload;
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        const { deleted, storyCardId } = action.payload;
        const { card } = state;

        if (!deleted || !card || storyCardId !== card.id) {
          return;
        }

        state.card = undefined;
      })
      .addCase(repostCard.fulfilled, (state, action) => {
        const storyCard = action.payload;

        if (!state.card || storyCard.id !== state.card.id) {
          return;
        }

        state.card.dateToDisplay = storyCard.dateToDisplay;
      })
      .addCase(toggleCardSection.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!state.card || payload.id !== state.card.id) {
          return;
        }

        state.card.section = payload.section;
      });
  },
});

export default cardDetailsModalSlice.reducer;
