import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Channel,
  ChannelNotificationPreferenceUpdateArgs,
  ChannelsArgs,
  channelsNewApi,
} from '../services';

interface ChannelsState {
  channels: Channel[];
  isFetching: boolean;
}

const initialState: ChannelsState = {
  channels: [],
  isFetching: false,
};

export const getChannels = createAsyncThunk(
  'getChannels',
  async (args: ChannelsArgs, { dispatch }) => {
    return dispatch(channelsNewApi.endpoints.channels.initiate(args)).unwrap();
  }
);

export const channelNotificationPreferenceUpdate = createAsyncThunk(
  'channelNotificationPreferenceUpdate',
  async (args: ChannelNotificationPreferenceUpdateArgs, { dispatch }) => {
    const updated = await dispatch(
      channelsNewApi.endpoints.channelNotificationPreferenceUpdate.initiate(args)
    ).unwrap();

    return { ...args, updated };
  }
);

const ChannelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChannels.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getChannels.fulfilled, (state, action) => {
        state.channels = action.payload;
        state.isFetching = false;
      })
      .addCase(channelNotificationPreferenceUpdate.fulfilled, (state, action) => {
        const { updated, channelId, fields } = action.payload;

        if (!updated) {
          return;
        }

        const channel = state.channels.find(({ id }) => id === channelId);

        if (!channel) {
          return;
        }

        channel.userNotificationPreference.allowToReceiveChannelNotification =
          fields.allowToReceiveChannelNotification;
      });
  },
});

export default ChannelsSlice.reducer;
