import { ChannelAppTabAction } from '../../services';

interface GetAppTabHrefByActionTypeArgs {
  action: ChannelAppTabAction;
  storyId?: number;
}

export const getAppTabHrefByActionType = ({ action, storyId }: GetAppTabHrefByActionTypeArgs) => {
  switch (action) {
    case ChannelAppTabAction.STORIES:
      return '/storyTab1';
    case ChannelAppTabAction.STORIES2:
      return '/storyTab2';
    case ChannelAppTabAction.PROFILE:
      return '/profile';
    case ChannelAppTabAction.PINNED_STORY:
      return `/pinnedStoryTab/${storyId}/items`;
    case ChannelAppTabAction.CHATS:
      return '/chats';
    case ChannelAppTabAction.USER_DIRECTORY:
      return '/userDirectory';
    default:
      return '/';
  }
};
