import {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfigContext, UserContext } from '../../../../context';
import {
  ApiClientType,
  OrganisationTag,
  Story,
  StoryCard,
  StoryCardArticleParsedUrl,
  StoryCardAudioParsedUrl,
  StoryCardFieldsPostInStoryInput,
  StoryCardImageParsedUrl,
  StoryCardPdfParsedUrl,
  StoryCardPostContentBlockFields,
  StoryCardQuoteParsedUrl,
  StoryCardStatus,
  StoryCardTeaserImageStyle,
  StoryCardType,
  StoryCardVideoParsedUrl,
  User,
  storyCardApi,
} from '../../../../services';
import { addCard, editCard, getCard } from '../../../../slices';
import { POST_IN_STORAGE_KEY } from '../../../constants';
import {
  CARD,
  CARD_LOCALE,
  CardProps,
  useAppDispatch,
  useFilter,
  useRefreshFeeds,
  useRefreshStoryCardsUserStats,
  useTags,
} from '../../../hooks';
import { GalleryItem } from '../../../models';
import {
  getChannelDomain,
  getCssVar,
  getWebAppUrl,
  isAdminLayout,
  isCardMediaCompleted,
  optArrItem,
  toggleStoryCardDisabled,
} from '../../../utils';
import { FilterValue } from '../../Filter';
import { IconLabel } from '../../IconLabel';
import { Modal } from '../../Modal';
import { TabItem, Tabs } from '../../Tabs';
import { UserMentionModal } from '../../UserMentionModal';
import { FullPost } from '../FullPost';
import { LinkPost } from '../LinkPost';
import { ShortPost } from '../ShortPost';
import { ThreadPost } from '../ThreadPost';
import { CreateCardButtons } from './CreateCardButtons';
import { CreateCardHeader } from './CreateCardHeader';
import { CreateCardSettings, CreateCardSettingsTabType } from './CreateCardSettings';
import { getPostInCardType, getPostTypeValueDefault, usePostInStoryIdDefault } from './helpers';

import classes from './CreateCardModal.module.scss';

export enum PostType {
  SHORT = 'SHORT',
  FULL = 'FULL',
  LINK = 'LINK',
  THREAD = 'THREAD',
}

enum TabType {
  SHORT_TAB,
  FULL_TAB,
  LINK_TAB,
  THREAD_TAB,
}

interface CardSettingsMedia {
  galleryItems?: GalleryItem[];
  setGalleryItems?: (galleryItems: GalleryItem[]) => void;
  activeGalleryItemIndex?: number;
  teaserStyle?: StoryCardTeaserImageStyle;
  setTeaserStyle?: (teaserStyle: StoryCardTeaserImageStyle) => void;
}

interface CardSettingsTags {
  external: OrganisationTag[];
  setExternal: (items: OrganisationTag[]) => void;
  internal: OrganisationTag[];
  setInternal: (items: OrganisationTag[]) => void;
}

export interface CardSettings {
  activeSettingsTabIndex: number;
  setActiveSettingsTabIndex: (index: number) => void;
  card: FilterValue[];
  media: CardSettingsMedia;
  tags?: CardSettingsTags;
}

export interface ShortPostFields {
  sourceName: string;
  headline: string;
  subHeadline: string;
  text: string;
  gallery: GalleryItem[];
}

export interface FullPostFields {
  sourceName: string;
  title: string;
  headline: string;
  abstract: string;
  contentAuthor: string;
  gallery: GalleryItem[];
  styles: { teaserImageStyle: StoryCardTeaserImageStyle };
  contentBlocks: StoryCardPostContentBlockFields[];
}

export interface ThreadPostFields {
  title: string;
}

export interface LinkPostQuoteFields extends StoryCardQuoteParsedUrl {
  headline: string;
}

export interface LinkPostArticleFields extends StoryCardArticleParsedUrl {
  headline: string;
  styles: { teaserImageStyle: StoryCardTeaserImageStyle };
}

export interface LinkPostFields {
  url: string;
  type?: StoryCardType;
  article?: LinkPostArticleFields;
  quote?: LinkPostQuoteFields;
  image?: StoryCardImageParsedUrl;
  audio?: StoryCardAudioParsedUrl;
  video?: StoryCardVideoParsedUrl;
  pdf?: StoryCardPdfParsedUrl;
}

interface CreateCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  postInStories: Story[];
  card?: StoryCard;
  shortPostFilledFields?: ShortPostFields;
  fullPostFilledFields?: FullPostFields;
  linkPostFilledFields?: LinkPostFields;
  threadPostFilledFields?: ThreadPostFields;
  files?: FileList;
  fileUrl?: string;
}

const FETCH_CARD_ATTEMPTS_LIMIT = 2;

const FETCH_CARD_MS_TIMEOUT = 5000;

export const CreateCardModal: FunctionComponent<CreateCardModalProps> = memo(
  ({
    isOpen,
    onClose,
    postInStories,
    card,
    shortPostFilledFields,
    fullPostFilledFields,
    linkPostFilledFields,
    threadPostFilledFields,
    files,
    fileUrl,
  }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { excludeLongPost, excludeThreadPost } =
      useContext(ConfigContext).config.elements.addContent;

    const [cardPostIn] = storyCardApi.endpoints.storyCardPostInStory.useLazyQuery();

    const [cardUpdate] = storyCardApi.endpoints.storyCardUpdate.useLazyQuery();

    const { refreshFeeds } = useRefreshFeeds({ scrollTop: false });

    const { refreshStoryCardsUserStats } = useRefreshStoryCardsUserStats();

    const { getGroupValue, getGroupItemValue } = useFilter();

    const { hasTags } = useTags();

    const { channelName } = useContext(UserContext).userInfo.body;

    const postInStorageKey = useMemo(() => `${POST_IN_STORAGE_KEY}_${channelName}`, [channelName]);

    const [postInStoryId, setPostInStoryId] = useState<number>(
      usePostInStoryIdDefault({ postInStories, postInStorageKey })
    );

    const fetchCardAttemptsCount = useRef<number>(0);

    const {
      id: cardId = 0,
      status: cardStatus,
      options: cardOptions,
      category: cardCategory,
      mentionedUsers: cardMentionedUsers = [],
      postingTime: cardPostingTime,
      externalTags: cardExternalTags,
      internalTags: cardInternalTags,
      localeId,
      apiClient,
      assignedUserId,
    } = { ...card };

    const {
      allowComment,
      allowCardReaction,
      allowSharing,
      allowDisplayAuthor,
      shouldUpdatePostedTimeByComments,
    } = { ...cardOptions };

    const [loading, setLoading] = useState(false);

    const editMode = useMemo(() => Boolean(cardId), [cardId]);

    const {
      id: categoryId,
      name: categoryName,
      story: categoryStory,
      settings: categorySettings,
    } = { ...cardCategory };

    const { id: categoryStoryId } = { ...categoryStory };

    const isCategoryCard = useMemo(() => Boolean(categoryId), [categoryId]);

    const [mentionUsersModalOpen, setMentionUsersModalOpen] = useState<boolean>(
      Boolean(categorySettings?.userSelection && !editMode)
    );

    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

    const settingsButton = useRef<HTMLDivElement>(null);

    const scrollToSettings = useCallback(() => {
      setTimeout(() => {
        settingsButton?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }, []);

    const setSettingsOpenWithScroll = useCallback(
      (isOpen: boolean) => {
        setSettingsOpen(isOpen);
        scrollToSettings();
      },
      [scrollToSettings]
    );

    const [postType, setPostType] = useState<PostType>(
      getPostTypeValueDefault({
        fullPostFilledFields,
        linkPostFilledFields,
        threadPostFilledFields,
      })
    );

    const isShortPost = useMemo(() => postType === PostType.SHORT, [postType]);

    const isFullPost = useMemo(() => postType === PostType.FULL, [postType]);

    const isLinkPost = useMemo(() => postType === PostType.LINK, [postType]);

    const isIntegrationCard = useMemo(
      () => Boolean(!assignedUserId && apiClient?.type === ApiClientType.API_CLIENTS_EXTERNAL),
      [apiClient?.type, assignedUserId]
    );

    const [postingTime, setPostingTime] = useState<Date | null>(
      cardPostingTime ? new Date(cardPostingTime) : null
    );

    const postInStory = useMemo(
      () => postInStories.find(({ id }) => id === postInStoryId),
      [postInStories, postInStoryId]
    );

    const postInStoryTeaserStyle = useMemo(
      () =>
        postInStory?.settings.cardStyles.article.teaserStyle ?? StoryCardTeaserImageStyle.STANDARD,
      [postInStory?.settings.cardStyles.article.teaserStyle]
    );

    const [externalTags, setExternalTags] = useState<OrganisationTag[]>(
      (!editMode ? postInStory?.externalTags : cardExternalTags) ?? []
    );

    const [internalTags, setInternalTags] = useState<OrganisationTag[]>(
      (!editMode ? postInStory?.internalTags : cardInternalTags) ?? []
    );

    const [mentionedUsers, setMentionedUsers] = useState<User[]>(
      editMode ? cardMentionedUsers : []
    );

    useEffect(() => {
      if (editMode) {
        return;
      }

      setExternalTags(postInStory?.externalTags ?? []);
      setInternalTags(postInStory?.internalTags ?? []);
    }, [editMode, postInStory]);

    const [shortPostFields, setShortPostFields] = useState<ShortPostFields>(
      shortPostFilledFields ?? {
        sourceName: '',
        headline: '',
        subHeadline: '',
        text: '',
        gallery: [],
      }
    );

    const setShortPostGalleryItems = useCallback(
      (gallery: GalleryItem[]) => setShortPostFields({ ...shortPostFields, gallery }),
      [setShortPostFields, shortPostFields]
    );

    const defaultActiveSettingsTabIndex = useMemo(() => {
      switch (true) {
        case isAdminLayout():
          return CreateCardSettingsTabType.CARD;
        case hasTags:
          return CreateCardSettingsTabType.TAGS;
        default:
          return CreateCardSettingsTabType.MEDIA;
      }
    }, [hasTags]);

    const [activeSettingsTabIndex, setActiveSettingsTabIndex] = useState(
      defaultActiveSettingsTabIndex
    );

    const isMediaTabActive = useMemo(
      () => activeSettingsTabIndex === CreateCardSettingsTabType.MEDIA,
      [activeSettingsTabIndex]
    );

    const [activeShortPostGalleryItemIndex, setActiveShortPostGalleryItemIndex] = useState(0);

    const [fullPostFields, setFullPostFields] = useState<FullPostFields>(
      fullPostFilledFields ?? {
        sourceName: '',
        title: '',
        headline: '',
        abstract: '',
        contentAuthor: '',
        gallery: [],
        contentBlocks: [],
        styles: { teaserImageStyle: postInStoryTeaserStyle },
      }
    );

    const setFullPostGalleryItems = useCallback(
      (gallery: GalleryItem[]) => setFullPostFields({ ...fullPostFields, gallery }),
      [fullPostFields]
    );

    const setFullPostTeaserStyle = useCallback(
      (teaserImageStyle: StoryCardTeaserImageStyle) =>
        setFullPostFields({ ...fullPostFields, styles: { teaserImageStyle } }),
      [fullPostFields, setFullPostFields]
    );

    const [activeFullPostGalleryItemIndex, setActiveFullPostGalleryItemIndex] = useState(0);

    const [threadPostFields, setThreadPostFields] = useState<ThreadPostFields>(
      threadPostFilledFields ?? { title: '' }
    );

    const [activeLinkPostGalleryItemIndex, setActiveLinkPostGalleryItemIndex] = useState(0);

    const [linkPostFields, setLinkPostFields] = useState<LinkPostFields>(
      linkPostFilledFields ?? { url: '' }
    );

    const { text: shortPostText, gallery: shortPostGallery } = shortPostFields;

    const {
      title: fullPostTitle,
      gallery: fullPostGallery,
      styles: { teaserImageStyle: fullPostTeaserStyle },
      contentBlocks: fullPostContentBlocks,
    } = fullPostFields;

    const { title: threadPostTitle } = threadPostFields;

    const {
      url: linkPostFieldsUrl,
      type: linkPostType,
      article: linkPostArticle,
      quote: linkPostQuote,
    } = linkPostFields;

    const {
      styles: linkPostArticleStyles,
      sourceName: linkPostArticleSourceName,
      title: linkPostArticleTitle,
      gallery: linkPostArticleGallery,
    } = { ...linkPostArticle };

    useEffect(() => {
      if (!editMode) {
        const styles = { teaserImageStyle: postInStoryTeaserStyle };

        setFullPostFields({ ...fullPostFields, styles });

        if (linkPostFields.article) {
          setLinkPostFields({ ...linkPostFields, article: { ...linkPostFields.article, styles } });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postInStoryTeaserStyle]);

    const setLinkPostArticleGalleryItems = useCallback(
      (gallery: GalleryItem[]) => {
        if (!linkPostFields.article) {
          return;
        }

        setLinkPostFields({ ...linkPostFields, article: { ...linkPostFields.article, gallery } });
      },
      [linkPostFields]
    );

    const setLinkPostArticleTeaserStyle = useCallback(
      (teaserImageStyle: StoryCardTeaserImageStyle) => {
        if (!linkPostFields.article) {
          return;
        }

        setLinkPostFields({
          ...linkPostFields,
          article: { ...linkPostFields.article, styles: { teaserImageStyle } },
        });
      },
      [linkPostFields]
    );

    const {
      quote: linkPostQuoteContent,
      quotePersonImage: linkPostQuoteContentPersonImage,
      gallery: linkPostQuoteGallery,
    } = { ...linkPostQuote };

    const setLinkPostQuoteGalleryItems = useCallback(
      (gallery: GalleryItem[]) => {
        if (!linkPostFields.quote) {
          return;
        }

        setLinkPostFields({ ...linkPostFields, quote: { ...linkPostFields.quote, gallery } });
      },
      [linkPostFields]
    );

    const [fullPostTextEditorOpen, setFullPostTextEditorOpen] = useState(
      Boolean(!editMode && !fullPostContentBlocks.length)
    );

    const [fullPostTextEditorUsePrefill, setFullPostTextEditorUsePrefill] = useState(
      Boolean(!editMode)
    );

    const [linkPostPrevUrl, setLinkPostPrevUrl] = useState<string>(
      editMode ? linkPostFieldsUrl : ''
    );

    const convertToLinkPost = useCallback(
      (url: string, headline: string) => {
        if (editMode || url === linkPostFieldsUrl) {
          return;
        }

        setLinkPostFields({
          url,
          article: { headline } as LinkPostArticleFields,
          quote: { headline } as LinkPostQuoteFields,
        });

        setPostType(PostType.LINK);
      },
      [editMode, linkPostFieldsUrl]
    );

    const postInCardType = useMemo(
      () => getPostInCardType({ postType, shortPostGallery, linkPostArticle, linkPostQuote }),
      [linkPostArticle, linkPostQuote, postType, shortPostGallery]
    );

    const isArticle = useMemo(() => postInCardType === StoryCardType.ARTICLE, [postInCardType]);

    const isQuote = useMemo(() => postInCardType === StoryCardType.QUOTE, [postInCardType]);

    const getCardItems = useMemo(() => {
      if (!editMode) {
        const { settings } = { ...postInStory };

        const {
          allowComments: storyAllowComments,
          allowCardReactions: storyAllowCardReactions,
          allowSharing: storyAllowSharing,
          shouldUpdatePostedTimeByComments: storyShouldUpdatePostedTimeByComments,
          showAllAuthors: storyShowAllAuthorsStory,
        } = { ...settings };

        return [
          ...optArrItem(Boolean(storyAllowComments), {
            id: CardProps.allowComments,
            value: CardProps.allowComments,
          }),
          ...optArrItem(Boolean(storyAllowCardReactions), {
            id: CardProps.allowCardReactions,
            value: CardProps.allowCardReactions,
          }),
          ...optArrItem(Boolean(storyAllowSharing), {
            id: CardProps.allowSharing,
            value: CardProps.allowSharing,
          }),
          ...optArrItem(Boolean(storyShouldUpdatePostedTimeByComments), {
            id: CardProps.shouldUpdatePostedTimeByComments,
            value: CardProps.shouldUpdatePostedTimeByComments,
          }),
          ...optArrItem(Boolean(storyShowAllAuthorsStory), {
            id: CardProps.showAllAuthors,
            value: CardProps.showAllAuthors,
          }),
        ];
      }

      return [
        ...optArrItem(Boolean(allowComment), {
          id: CardProps.allowComments,
          value: CardProps.allowComments,
        }),
        ...optArrItem(Boolean(allowCardReaction), {
          id: CardProps.allowCardReactions,
          value: CardProps.allowCardReactions,
        }),
        ...optArrItem(Boolean(allowSharing), {
          id: CardProps.allowSharing,
          value: CardProps.allowSharing,
        }),
        ...optArrItem(Boolean(shouldUpdatePostedTimeByComments), {
          id: CardProps.shouldUpdatePostedTimeByComments,
          value: CardProps.shouldUpdatePostedTimeByComments,
        }),
        ...optArrItem(Boolean(allowDisplayAuthor), {
          id: CardProps.showAllAuthors,
          value: CardProps.showAllAuthors,
        }),
      ];
    }, [
      allowCardReaction,
      allowComment,
      allowDisplayAuthor,
      allowSharing,
      editMode,
      postInStory,
      shouldUpdatePostedTimeByComments,
    ]);

    const getCardLocaleItems = useMemo(() => {
      if (!editMode) {
        return [{ value: postInStory?.localeId }];
      }

      return [{ value: localeId }];
    }, [editMode, localeId, postInStory?.localeId]);

    const { galleryItems, setGalleryItems, activeGalleryItemIndex, teaserStyle, setTeaserStyle } =
      useMemo(() => {
        switch (postType) {
          case PostType.SHORT:
            return {
              galleryItems: shortPostGallery,
              setGalleryItems: setShortPostGalleryItems,
              activeGalleryItemIndex: activeShortPostGalleryItemIndex,
            };
          case PostType.FULL:
            return {
              galleryItems: fullPostGallery,
              setGalleryItems: setFullPostGalleryItems,
              activeGalleryItemIndex: activeFullPostGalleryItemIndex,
              teaserStyle: fullPostTeaserStyle,
              setTeaserStyle: setFullPostTeaserStyle,
            };
          case PostType.LINK:
            if (isArticle) {
              return {
                galleryItems: linkPostArticleGallery ?? [],
                setGalleryItems: setLinkPostArticleGalleryItems,
                activeGalleryItemIndex: activeLinkPostGalleryItemIndex,
                teaserStyle: linkPostArticleStyles?.teaserImageStyle,
                setTeaserStyle: setLinkPostArticleTeaserStyle,
              };
            }
            return {
              galleryItems: linkPostQuoteGallery ?? [],
              setGalleryItems: setLinkPostQuoteGalleryItems,
              activeGalleryItemIndex: activeLinkPostGalleryItemIndex,
            };
          default:
            return {
              galleryItems: [],
              setGalleryItems: () => null,
              activeGalleryItemIndex: 0,
            };
        }
      }, [
        activeFullPostGalleryItemIndex,
        activeLinkPostGalleryItemIndex,
        activeShortPostGalleryItemIndex,
        fullPostGallery,
        fullPostTeaserStyle,
        isArticle,
        linkPostArticleGallery,
        linkPostArticleStyles?.teaserImageStyle,
        linkPostQuoteGallery,
        postType,
        setFullPostGalleryItems,
        setFullPostTeaserStyle,
        setLinkPostArticleGalleryItems,
        setLinkPostArticleTeaserStyle,
        setLinkPostQuoteGalleryItems,
        setShortPostGalleryItems,
        shortPostGallery,
      ]);

    const hasMedia = useMemo(() => Boolean(galleryItems.length), [galleryItems.length]);

    const [settings, setSettings] = useState<CardSettings>({
      activeSettingsTabIndex,
      setActiveSettingsTabIndex,
      card: [
        { groupId: CARD, groupItems: getCardItems },
        { groupId: CARD_LOCALE, groupItems: getCardLocaleItems },
      ],
      media: {
        galleryItems,
        setGalleryItems,
        activeGalleryItemIndex,
        teaserStyle,
        setTeaserStyle,
      },

      ...(hasTags && {
        tags: {
          external: externalTags,
          setExternal: setExternalTags,
          internal: internalTags,
          setInternal: setInternalTags,
        },
      }),
    });

    useEffect(() => {
      setSettings({
        ...settings,
        activeSettingsTabIndex,
        setActiveSettingsTabIndex,
        card: [
          { groupId: CARD, groupItems: getCardItems },
          { groupId: CARD_LOCALE, groupItems: getCardLocaleItems },
        ],
        media: {
          galleryItems,
          setGalleryItems,
          activeGalleryItemIndex,
          teaserStyle,
          setTeaserStyle,
        },
        ...(hasTags && {
          tags: {
            external: externalTags,
            setExternal: setExternalTags,
            internal: internalTags,
            setInternal: setInternalTags,
          },
        }),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      activeGalleryItemIndex,
      activeSettingsTabIndex,
      galleryItems,
      setGalleryItems,
      setTeaserStyle,
      teaserStyle,
      getCardItems,
      getCardLocaleItems,
      hasTags,
      externalTags,
      internalTags,
    ]);

    const { card: cardFilter } = settings;

    const renderSettingsToggle = useMemo(
      () => Boolean(isAdminLayout() || (!isCategoryCard && (hasMedia || hasTags))),
      [hasMedia, hasTags, isCategoryCard]
    );

    const galleryMap = useCallback((galleryItems?: GalleryItem[]) => {
      return galleryItems?.map((galleryItem) => {
        const { image, audio, video, pdf, title } = galleryItem;

        const { id, rightholder } = { ...image };

        const imageMapped = id && { image: { id, ...(rightholder && { rightholder }) } };

        switch (true) {
          case Boolean(audio):
            return { audio: { id: audio?.id, useDefaultThumb: false }, ...imageMapped, title };
          case Boolean(video):
            return { video: { id: video?.id, useDefaultThumb: true }, ...imageMapped, title };
          case Boolean(pdf):
            return { pdf: { id: pdf?.id, useDefaultThumb: false }, ...imageMapped, title };
          case Boolean(image): {
            return { ...imageMapped, ...(title && { title }) };
          }
          default:
            return null;
        }
      });
    }, []);

    const postCard = useCallback(
      (status: StoryCardStatus) => {
        return new Promise<void>(async (resolve, reject) => {
          fetchCardAttemptsCount.current = 0;
          toggleStoryCardDisabled({ storyCardId: cardId, disabled: true });
          onClose();

          try {
            const mediaFields = { ...shortPostFields, gallery: galleryMap(shortPostGallery) };

            const { gallery, ...editorialFields } = mediaFields;

            const postFields = { ...fullPostFields, gallery: galleryMap(fullPostGallery) };

            const articleData = { ...linkPostArticle, gallery: galleryMap(linkPostArticleGallery) };

            const { type: articleType, ...articleFields } = articleData;

            const quoteData = { ...linkPostQuote, gallery: galleryMap(linkPostQuoteGallery) };

            const { type: quoteType, quotePersonImage, ...quoteFields } = quoteData;

            const allowComment = Boolean(
              getGroupItemValue({
                filter: cardFilter,
                groupId: CARD,
                itemId: CardProps.allowComments,
              })
            );

            const allowCardReaction = Boolean(
              getGroupItemValue({
                filter: cardFilter,
                groupId: CARD,
                itemId: CardProps.allowCardReactions,
              })
            );

            const allowSharing = Boolean(
              getGroupItemValue({
                filter: cardFilter,
                groupId: CARD,
                itemId: CardProps.allowSharing,
              })
            );

            const shouldUpdatePostedTimeByComments = Boolean(
              getGroupItemValue({
                filter: cardFilter,
                groupId: CARD,
                itemId: CardProps.shouldUpdatePostedTimeByComments,
              })
            );

            const allowDisplayAuthor = Boolean(
              getGroupItemValue({
                filter: cardFilter,
                groupId: CARD,
                itemId: CardProps.showAllAuthors,
              })
            );

            const localeId = getGroupValue({ filter: cardFilter, groupId: CARD_LOCALE });

            const fields = {
              status,
              postingTime: postingTime?.toISOString(),
              options: {
                allowComment,
                allowCardReaction,
                allowSharing,
                allowDisplayAuthor,
                shouldUpdatePostedTimeByComments,
              },
              ...(!cardCategory && { localeId }),
              ...(!editMode && cardCategory && { categoryId }),
              mentionedUsersId: mentionedUsers.map(({ id }) => id),
              organisationTags: {
                externalTagsId: externalTags.map(({ id }) => id),
                ...(internalTags && { internalTagsId: internalTags.map(({ id }) => id) }),
              },
              ...(postInCardType === StoryCardType.THREAD && { threadFields: threadPostFields }),
              ...(postInCardType === StoryCardType.EDITORIAL && { editorialFields }),
              ...(postInCardType === StoryCardType.IMAGE && { imageFields: mediaFields }),
              ...(postInCardType === StoryCardType.AUDIO && { audioFields: mediaFields }),
              ...(postInCardType === StoryCardType.VIDEO && { videoFields: mediaFields }),
              ...(postInCardType === StoryCardType.PDF && { pdfFields: mediaFields }),
              ...(isArticle &&
                linkPostArticle && { articleFields: { url: linkPostFieldsUrl, ...articleFields } }),
              ...(isQuote &&
                linkPostQuote && {
                  quoteFields: {
                    url: linkPostFieldsUrl,
                    ...quoteFields,
                    ...(linkPostQuoteContentPersonImage?.id && {
                      quotePersonImageId: linkPostQuoteContentPersonImage?.id,
                    }),
                  },
                }),
              ...(postInCardType === StoryCardType.POST && { postFields }),
            } as StoryCardFieldsPostInStoryInput;

            const postInStoryArgs = {
              storyId: categoryStoryId ?? postInStoryId,
              fields,
            };

            const cardUpdateArgs = { ...postInStoryArgs, storyCardId: cardId };

            const { payload, error } = await (editMode
              ? cardUpdate(cardUpdateArgs)
              : cardPostIn(postInStoryArgs)
            ).unwrap();

            const payloadAction = editMode ? editCard : addCard;

            if (payload) {
              if (isCardMediaCompleted(payload)) {
                toggleStoryCardDisabled({ storyCardId: cardId, disabled: false });
                refreshFeeds();
                refreshStoryCardsUserStats();
                dispatch(payloadAction(payload));
                resolve();
                return;
              }

              const { storyId: postedStoryId, id: postedCardId } = payload;

              const postedCardInterval = setInterval(async () => {
                fetchCardAttemptsCount.current = fetchCardAttemptsCount.current + 1;

                const postedCard = await dispatch(
                  getCard({ storyId: postedStoryId, cardId: postedCardId })
                ).unwrap();

                if (!isCardMediaCompleted(postedCard)) {
                  if (fetchCardAttemptsCount.current === FETCH_CARD_ATTEMPTS_LIMIT) {
                    toast.dismiss('postCard');
                    toast(t('addContent.long-processing'), { delay: 1000 });
                  }
                  return;
                }

                if (fetchCardAttemptsCount.current < FETCH_CARD_ATTEMPTS_LIMIT) {
                  toggleStoryCardDisabled({ storyCardId: cardId, disabled: false });
                  refreshFeeds();
                  refreshStoryCardsUserStats();
                  dispatch(payloadAction(postedCard));
                  resolve();
                  clearInterval(postedCardInterval);
                  return;
                }

                const detailsUrl = `${getWebAppUrl(
                  getChannelDomain()
                )}/details/${postedStoryId}/${postedCardId}`;

                toast.dismiss('postCard');

                toast(
                  <IconLabel
                    label={t('addContent.card-processed')}
                    color={getCssVar('--base-link-text-color')}
                    hoverColor={getCssVar('--base-link-text-hover-color')}
                    onClick={() => window.open(detailsUrl, '_blank', 'noopener, noreferrer')}
                  />,
                  { autoClose: false }
                );

                clearInterval(postedCardInterval);
              }, FETCH_CARD_MS_TIMEOUT);

              return;
            }

            if (error) {
              toast.error(
                error.__typename === 'StoryCardUrlUniquenessConflictError'
                  ? t('addContent.unique-conflict-error')
                  : t('addContent.error-message')
              );

              reject();
            }
          } catch (_) {
            reject();
          }
        });
      },
      [
        cardCategory,
        cardFilter,
        cardId,
        cardPostIn,
        cardUpdate,
        categoryId,
        categoryStoryId,
        dispatch,
        editMode,
        externalTags,
        fullPostFields,
        fullPostGallery,
        galleryMap,
        getGroupItemValue,
        getGroupValue,
        internalTags,
        isArticle,
        isQuote,
        linkPostArticle,
        linkPostArticleGallery,
        linkPostFieldsUrl,
        linkPostQuote,
        linkPostQuoteContentPersonImage?.id,
        linkPostQuoteGallery,
        mentionedUsers,
        onClose,
        postInCardType,
        postInStoryId,
        postingTime,
        refreshFeeds,
        refreshStoryCardsUserStats,
        shortPostFields,
        shortPostGallery,
        t,
        threadPostFields,
      ]
    );

    const onTabChange = useCallback(
      (index: number) => {
        if (editMode) {
          return;
        }

        switch (index) {
          case TabType.FULL_TAB: {
            setPostType(PostType.FULL);
            break;
          }
          case TabType.LINK_TAB: {
            setPostType(PostType.LINK);
            break;
          }
          case TabType.THREAD_TAB: {
            setPostType(PostType.THREAD);
            break;
          }
          default: {
            setPostType(PostType.SHORT);
            break;
          }
        }
      },
      [editMode]
    );

    const hideSettingsCaption = useMemo(
      () => isFullPost || isArticle || isQuote,
      [isArticle, isFullPost, isQuote]
    );

    const tabContent = useCallback((content: JSX.Element) => {
      return <div className={classes['create-card__content']}>{content}</div>;
    }, []);

    const notEditNorCategory = useMemo(
      () => !editMode && !isCategoryCard,
      [editMode, isCategoryCard]
    );

    const hideLongPost = useMemo(() => !isAdminLayout() && excludeLongPost, [excludeLongPost]);

    const hideThreadPost = useMemo(
      () => !isAdminLayout() && excludeThreadPost,
      [excludeThreadPost]
    );

    const tabItems: TabItem[] = useMemo(
      () => [
        ...optArrItem(!editMode || isShortPost, {
          index: TabType.SHORT_TAB,
          name: isCategoryCard ? categoryName : t('addContent.short-post'),
          content: tabContent(
            <ShortPost
              loading={loading}
              setLoading={setLoading}
              shortPostFields={shortPostFields}
              setShortPostFields={setShortPostFields}
              setShortPostGalleryItems={setShortPostGalleryItems}
              activeShortPostGalleryItemIndex={activeShortPostGalleryItemIndex}
              setActiveShortPostGalleryItemIndex={setActiveShortPostGalleryItemIndex}
              convertToLinkPost={convertToLinkPost}
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpenWithScroll}
              activeShortPostSettingsMediaTab={isMediaTabActive}
              setActiveShortPostSettingsTabIndex={setActiveSettingsTabIndex}
              mentionedUsers={mentionedUsers}
              setMentionUsersModalOpen={setMentionUsersModalOpen}
              category={cardCategory}
              {...(files && { files })}
              {...(fileUrl && { fileUrl })}
            />
          ),
        }),
        ...optArrItem((notEditNorCategory && !hideLongPost) || postType === PostType.FULL, {
          index: TabType.FULL_TAB,
          name: t('addContent.full-post'),
          content: tabContent(
            <FullPost
              loading={loading}
              setLoading={setLoading}
              fullPostFields={fullPostFields}
              setFullPostFields={setFullPostFields}
              setFullPostGalleryItems={setFullPostGalleryItems}
              fullPostTextEditorOpen={fullPostTextEditorOpen}
              setFullPostTextEditorOpen={setFullPostTextEditorOpen}
              fullPostTextEditorUsePrefill={fullPostTextEditorUsePrefill}
              setFullPostTextEditorUsePrefill={setFullPostTextEditorUsePrefill}
              activeFullPostGalleryItemIndex={activeFullPostGalleryItemIndex}
              setActiveFullPostGalleryItemIndex={setActiveFullPostGalleryItemIndex}
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpenWithScroll}
              activeFullPostSettingsMediaTab={isMediaTabActive}
              setActiveFullPostSettingsTabIndex={setActiveSettingsTabIndex}
            />
          ),
        }),
        ...optArrItem(notEditNorCategory || postType === PostType.LINK, {
          index: TabType.LINK_TAB,
          name: t('addContent.link-post'),
          content: tabContent(
            <LinkPost
              loading={loading}
              setLoading={setLoading}
              linkPostFields={linkPostFields}
              setLinkPostFields={setLinkPostFields}
              linkPostPrevUrl={linkPostPrevUrl}
              setLinkPostPrevUrl={setLinkPostPrevUrl}
              setLinkPostArticleGalleryItems={setLinkPostArticleGalleryItems}
              setLinkPostQuoteGalleryItems={setLinkPostQuoteGalleryItems}
              activeLinkPostGalleryItemIndex={activeLinkPostGalleryItemIndex}
              setActiveLinkPostGalleryItemIndex={setActiveLinkPostGalleryItemIndex}
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpenWithScroll}
              activeLinkPostSettingsMediaTab={isMediaTabActive}
              setActiveLinkPostSettingsTabIndex={setActiveSettingsTabIndex}
              postInStoryTeaserStyle={postInStoryTeaserStyle}
            />
          ),
        }),
        ...optArrItem((notEditNorCategory && !hideThreadPost) || postType === PostType.THREAD, {
          index: TabType.THREAD_TAB,
          name: t('addContent.thread-post'),
          content: tabContent(
            <ThreadPost
              threadPostFields={threadPostFields}
              setThreadPostFields={setThreadPostFields}
              setActiveThreadPostSettingsTabIndex={setActiveSettingsTabIndex}
              setSettingsOpen={setSettingsOpenWithScroll}
            />
          ),
        }),
      ],
      [
        activeFullPostGalleryItemIndex,
        activeLinkPostGalleryItemIndex,
        activeShortPostGalleryItemIndex,
        cardCategory,
        categoryName,
        convertToLinkPost,
        editMode,
        fileUrl,
        files,
        fullPostFields,
        fullPostTextEditorOpen,
        fullPostTextEditorUsePrefill,
        hideLongPost,
        hideThreadPost,
        isCategoryCard,
        isMediaTabActive,
        isShortPost,
        linkPostFields,
        linkPostPrevUrl,
        loading,
        mentionedUsers,
        notEditNorCategory,
        postInStoryTeaserStyle,
        postType,
        setFullPostGalleryItems,
        setLinkPostArticleGalleryItems,
        setLinkPostQuoteGalleryItems,
        setSettingsOpenWithScroll,
        setShortPostGalleryItems,
        settingsOpen,
        shortPostFields,
        t,
        tabContent,
        threadPostFields,
      ]
    );

    const body = useMemo(() => {
      return (
        <div className={classes['create-card']}>
          <CreateCardHeader
            postInStories={postInStories}
            postInStoryId={postInStoryId}
            setPostInStoryId={setPostInStoryId}
            postInStorageKey={postInStorageKey}
            editMode={editMode}
            isFullPost={isFullPost}
            isLinkPost={isLinkPost}
            isCategoryCard={isCategoryCard}
            loading={loading}
            linkPostFieldsUrl={linkPostFieldsUrl}
            postInStoryTeaserStyle={postInStoryTeaserStyle}
            setPostType={setPostType}
            setShortPostFields={setShortPostFields}
            setFullPostFields={setFullPostFields}
            setLinkPostFields={setLinkPostFields}
            setLinkPostPrevUrl={setLinkPostPrevUrl}
            setFullPostTextEditorOpen={setFullPostTextEditorOpen}
            linkPostType={linkPostType}
          />

          <Tabs
            items={tabItems}
            selectedTabIndex={TabType[`${postType}_TAB`]}
            setSelectedTabIndex={onTabChange}
            disabled={loading}
          />

          <CreateCardButtons
            settingsButton={settingsButton}
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
            scrollToSettings={scrollToSettings}
            renderSettingsToggle={renderSettingsToggle}
            postType={postType}
            postingTime={postingTime}
            setPostingTime={setPostingTime}
            cardStatus={cardStatus}
            shortPostText={shortPostText}
            shortPostGallery={shortPostGallery}
            fullPostTitle={fullPostTitle}
            threadPostTitle={threadPostTitle}
            linkPostType={linkPostType}
            linkPostArticle={linkPostArticle}
            linkPostArticleSourceName={linkPostArticleSourceName}
            linkPostArticleTitle={linkPostArticleTitle}
            linkPostQuote={linkPostQuote}
            linkPostQuoteContent={linkPostQuoteContent}
            linkPostQuoteGallery={linkPostQuoteGallery}
            isCategoryCard={isCategoryCard}
            editMode={editMode}
            loading={loading}
            postCard={postCard}
          />

          {renderSettingsToggle && settingsOpen && (
            <CreateCardSettings
              settings={settings}
              onChange={setSettings}
              hideCaption={hideSettingsCaption}
              showEnableComments={postInCardType !== StoryCardType.THREAD}
              showDisplayUser={!isIntegrationCard}
              showMedia={hasMedia && !isCategoryCard}
              loading={loading}
              onTabChangeCallback={scrollToSettings}
            />
          )}

          {mentionUsersModalOpen && (
            <UserMentionModal
              isOpen={mentionUsersModalOpen}
              onClose={() => setMentionUsersModalOpen(false)}
              mentionedUsers={mentionedUsers}
              setMentionedUsers={setMentionedUsers}
            />
          )}
        </div>
      );
    }, [
      cardStatus,
      editMode,
      fullPostTitle,
      hasMedia,
      hideSettingsCaption,
      isCategoryCard,
      isFullPost,
      isIntegrationCard,
      isLinkPost,
      linkPostArticle,
      linkPostArticleSourceName,
      linkPostArticleTitle,
      linkPostFieldsUrl,
      linkPostQuote,
      linkPostQuoteContent,
      linkPostQuoteGallery,
      linkPostType,
      loading,
      mentionUsersModalOpen,
      mentionedUsers,
      onTabChange,
      postCard,
      postInCardType,
      postInStorageKey,
      postInStories,
      postInStoryId,
      postInStoryTeaserStyle,
      postType,
      postingTime,
      renderSettingsToggle,
      scrollToSettings,
      settings,
      settingsOpen,
      shortPostGallery,
      shortPostText,
      tabItems,
      threadPostTitle,
    ]);

    return <Modal isOpen={isOpen} body={body} onClose={onClose} alignTop />;
  }
);
