import { FunctionComponent, memo } from 'react';
import { StoryCardVideoContent } from '../../../services';
import { FeedCardContentCommon, FeedCardProps, FeedCardVideoCommon } from '../../../shared';

export const FeedCardVideo: FunctionComponent<FeedCardProps> = memo((props) => {
  const { card, chatId } = props;
  const { storyId, id, type, content, isPinnedToTopOfNewsFeed } = card;
  const { sourceName, headline, subHeadline, text, textHTML } = content as StoryCardVideoContent;

  return (
    <FeedCardContentCommon
      storyId={storyId}
      cardId={id}
      chatId={chatId}
      type={type}
      pinned={isPinnedToTopOfNewsFeed}
      source={sourceName}
      title={headline}
      media={<FeedCardVideoCommon {...props} />}
      subTitle={subHeadline}
      text={text}
      htmlText={textHTML}
    />
  );
});
