import { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../context';
import { storyCardApi } from '../../services';
import { layoutPath } from '../utils';

export const useRefreshStoryCardsUserStats = () => {
  const { pathname } = useLocation();

  const [storiesCardsUserFeedStatistic] =
    storyCardApi.endpoints.storiesCardsUserFeedStatistic.useLazyQuery();

  const {
    userInfo: {
      userData: { channelId },
    },
    userProfile: { id: userId },
  } = useContext(UserContext);

  const refreshStoryCardsUserStats = useCallback(() => {
    if (pathname !== layoutPath('/profile')) {
      return;
    }

    storiesCardsUserFeedStatistic({ channelId, userId });
  }, [channelId, pathname, storiesCardsUserFeedStatistic, userId]);

  return { refreshStoryCardsUserStats };
};
