import { FileAcceptType } from '../../../../hooks';
import { bytesToMegabytes } from '../../../../utils';
import { generateAudioTeaserUrl } from './generateAudioTeaserUrl';
import { generatePdfTeaserUrl } from './generatePdfTeaserUrl';
import { generateVideoTeaserUrl } from './generateVideoTeaserUrl';

interface GenerateTeaserUrlProps {
  file?: File;
  acceptType: FileAcceptType;
}

const FILE_SIZE_LIMIT_MB = 350;

export const generateTeaserUrl = ({ file, acceptType }: GenerateTeaserUrlProps) => {
  if (!file || bytesToMegabytes(file.size) > FILE_SIZE_LIMIT_MB) {
    return;
  }

  switch (acceptType) {
    case FileAcceptType.AUDIO:
      return generateAudioTeaserUrl(file);
    case FileAcceptType.VIDEO:
      return generateVideoTeaserUrl(file);
    case FileAcceptType.PDF:
      return generatePdfTeaserUrl(file);
    default:
      return null;
  }
};
