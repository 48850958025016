import { FunctionComponent, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChannelContext, ConfigContext } from '../../context';
import { ConfigurationLink, FooterCopy, IconLabel, IconLabelSizes, getCssVar } from '../../shared';

import classes from './Footer.module.scss';

export const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { dashboardUrl } = useContext(ChannelContext);

  const { links } = useContext(ConfigContext).config.elements.footer;

  const renderFooterLinks = useMemo(
    () =>
      links.map((link) => (
        <ConfigurationLink
          key={link.title}
          link={link}
          labelSize={IconLabelSizes.small}
          labelClassName={classes['footer__links-item-label']}
        />
      )),
    [links]
  );

  return (
    <div className={classes['footer']}>
      {dashboardUrl && (
        <IconLabel
          iconId={'switch'}
          label={t('common.admin-dashboard')}
          color={getCssVar('--base-link-text-color')}
          hoverColor={getCssVar('--base-link-text-hover-color')}
          onClick={() => navigate('/admin')}
        />
      )}

      {Boolean(renderFooterLinks.length) && (
        <div className={classes['footer__links']}>{renderFooterLinks}</div>
      )}
      <FooterCopy />
    </div>
  );
};
