import {
  StoryCard,
  StoryCardContentArticlePost,
  StoryCardContentText,
  StoryCardQuoteContent,
  StoryCardType,
} from '../../services';

export const getStoryCardIntro = (card: StoryCard) => {
  const { type, content } = card;

  switch (type) {
    case StoryCardType.EDITORIAL:
    case StoryCardType.IMAGE:
    case StoryCardType.AUDIO:
    case StoryCardType.VIDEO:
    case StoryCardType.PDF: {
      const { subHeadline, textHTML } = content as StoryCardContentText;
      return subHeadline || textHTML;
    }
    case StoryCardType.ARTICLE:
    case StoryCardType.POST:
      return (content as StoryCardContentArticlePost).abstract;
    case StoryCardType.QUOTE:
      return (content as StoryCardQuoteContent).quote;
    default:
      return '';
  }
};
