import { FunctionComponent, memo, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import classes from './Loader.module.scss';

interface LoaderProps {
  stats?: boolean;
}

export const Loader: FunctionComponent<LoaderProps> = memo(({ stats }) => {
  const loader = useMemo(() => {
    return (
      <div className={classes['loader']}>
        <Skeleton height={'1rem'} />
        <Skeleton height={'1rem'} />
        <Skeleton height={'1rem'} />
      </div>
    );
  }, []);

  if (stats) {
    return (
      <div className={classes['loader--stats']}>
        {loader}
        {loader}
        {loader}
      </div>
    );
  }

  return loader;
});
