import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import {
  ConfirmationModal,
  CookieActionType,
  cookieOptions,
  getCssVar,
  layoutPath,
  useOnClickOutside,
} from '../../shared';
import { IconLabel } from '../../shared/components/IconLabel';

import classes from './SearchInput.module.scss';

const SEARCH_HISTORY_COOKIE_KEY = 'searchHistory';

const MAX_PINNED_ALLOWED = 3;

interface SearchHistoryItem {
  value: string;
  isPinned?: boolean;
}

export const SearchInput: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [cookies, setCookie, removeCookie] = useCookies([SEARCH_HISTORY_COOKIE_KEY]);

  const historyItems: SearchHistoryItem[] = useMemo(
    () => cookies[SEARCH_HISTORY_COOKIE_KEY]?.items ?? [],
    [cookies]
  );

  const [value, setValue] = useState('');

  const [showHistory, setShowHistory] = useState(false);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const searchPagePath = useMemo(() => layoutPath('/search'), []);

  const pinAllowed = useMemo(
    () => historyItems.filter(({ isPinned }) => Boolean(isPinned)).length < MAX_PINNED_ALLOWED,
    [historyItems]
  );

  useOnClickOutside(containerRef, () => {
    setShowHistory(false);
  });

  const setHistoryItems = useCallback(
    (items: SearchHistoryItem[]) => {
      setCookie(SEARCH_HISTORY_COOKIE_KEY, { items }, cookieOptions());
    },
    [setCookie]
  );

  useEffect(() => {
    if (pathname !== searchPagePath) {
      setValue('');
    }
  }, [pathname, searchPagePath]);

  const clear = useCallback(() => {
    setValue('');
    inputRef.current?.focus();
  }, []);

  const search = useCallback(
    (value: string) => {
      inputRef.current?.blur();

      setShowHistory(false);

      navigate({
        pathname: searchPagePath,
        search: `?${createSearchParams({ qs: value })}`,
      });
    },
    [navigate, searchPagePath]
  );

  const onKeyDown = useCallback(
    ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
      if (key === 'Enter' && value) {
        search(value);

        const itemIndex = historyItems.findIndex((historyItem) => historyItem.value === value);

        if (itemIndex !== -1 && historyItems.length === 1) {
          return;
        }

        const items = [
          itemIndex !== -1 && historyItems.length
            ? historyItems.splice(itemIndex, 1)[0]
            : { value },
          ...historyItems,
        ];

        setHistoryItems(items);
      }
    },
    [historyItems, search, setHistoryItems, value]
  );

  const pinHistoryItem = useCallback(
    (value: string, isPinned: boolean) => {
      const index = historyItems.findIndex((historyItem) => historyItem.value === value);

      if (index === -1) {
        return;
      }

      historyItems[index].isPinned = !isPinned;

      setHistoryItems(historyItems);
    },
    [historyItems, setHistoryItems]
  );

  const pinHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string, isPinned: boolean) => {
      event.stopPropagation();
      pinHistoryItem(value, isPinned);
    },
    [pinHistoryItem]
  );

  const deleteHistoryItem = useCallback(
    (value: string) => {
      const index = historyItems.findIndex((historyItem) => historyItem.value === value);

      if (index === -1) {
        return;
      }

      historyItems.splice(index, 1);

      setHistoryItems(historyItems);
    },
    [historyItems, setHistoryItems]
  );

  const deleteHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
      event.stopPropagation();
      deleteHistoryItem(value);
    },
    [deleteHistoryItem]
  );

  const deleteConfirmationHandler = useCallback(() => {
    removeCookie(SEARCH_HISTORY_COOKIE_KEY, cookieOptions({ action: CookieActionType.REMOVE }));

    setIsDeleteOpen(false);
  }, [removeCookie]);

  const clearHistory = useCallback(() => {
    setShowHistory(false);
    setIsDeleteOpen(true);
  }, []);

  const renderHistoryItems = useMemo(() => {
    const filteredHistoryItems = historyItems.filter((historyItem) =>
      historyItem.value.toLowerCase().startsWith(value.toLowerCase())
    );

    if (!filteredHistoryItems.length) {
      return (
        <div className={classes['search__history-empty']}>{t('search.no-recent-searches')}</div>
      );
    }

    return (
      <>
        <div className={classes['search__history-header']}>
          <div className={classes['search__history-header-title']}>{t('common.recent')}</div>
          <IconLabel
            label={t('common.clear-all')}
            color={getCssVar('--base-link-text-color')}
            hoverColor={getCssVar('--base-link-text-hover-color')}
            onClick={clearHistory}
          />
        </div>
        <div className={classes['search__history-list']}>
          {filteredHistoryItems
            .sort(({ isPinned: isPinnedA }, { isPinned: isPinnedB }) =>
              isPinnedA === isPinnedB ? 0 : isPinnedA ? -1 : 1
            )
            .map(({ value, isPinned }: SearchHistoryItem) => {
              return (
                <div
                  key={value}
                  className={classes['search__history-list-item']}
                  onClick={() => search(value)}
                >
                  <span className={classes['search__history-list-item-value']}>{value}</span>
                  <span className={classes['search__history-list-item-actions']}>
                    {(isPinned || pinAllowed) && (
                      <IconLabel
                        iconId={'pin'}
                        iconSize={20}
                        color={isPinned ? getCssVar('--base-link-text-hover-color') : '#b3b3b3'}
                        hoverColor={getCssVar('--base-link-text-hover-color')}
                        onClick={(event) => pinHandler(event, value, Boolean(isPinned))}
                      />
                    )}
                    <IconLabel
                      iconId={'delete'}
                      iconSize={20}
                      color={'#b3b3b3'}
                      hoverColor={getCssVar('--color-danger')}
                      onClick={(event) => deleteHandler(event, value)}
                    />
                  </span>
                </div>
              );
            })}
        </div>
      </>
    );
  }, [clearHistory, deleteHandler, historyItems, pinAllowed, pinHandler, search, t, value]);

  return (
    <div ref={containerRef} className={classes['search']}>
      <IconLabel
        className={classes['search__icon']}
        iconId={'search'}
        iconSize={20}
        color={getCssVar('--search-input-icon-color')}
        singleColor
        onClick={() => inputRef.current?.focus()}
      />
      <input
        ref={inputRef}
        type={'text'}
        className={classes['search__input']}
        placeholder={t('common.search')}
        value={value}
        onKeyDown={onKeyDown}
        onChange={({ target }) => setValue(target.value)}
        onFocus={() => setShowHistory(true)}
      />
      {showHistory && <div className={classes['search__history']}>{renderHistoryItems}</div>}
      {value && (
        <IconLabel
          className={classes['search__clear']}
          iconId={'close'}
          color={getCssVar('--search-input-close-color')}
          singleColor
          onClick={clear}
        />
      )}

      <ConfirmationModal
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        acceptLabel={t('common.delete')}
        onAccept={deleteConfirmationHandler}
        title={t('search.delete-confirmation-title')}
        subTitle={t('search.delete-confirmation-sub-title')}
        danger
      />
    </div>
  );
};
