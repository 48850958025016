import { FunctionComponent, memo } from 'react';
import { ImageBlockData, ImageGallery, ImageGalleryItem } from '../../../../shared';

export const Image: FunctionComponent<ImageBlockData> = memo(({ items }) => {
  const imageGalleryItems: ImageGalleryItem[] = items.map(({ image, caption, copyright }) => {
    const { url } = image;
    return { caption, image: { url, rightholder: copyright } };
  });

  return <ImageGallery items={imageGalleryItems} singleImageFullSize modalPreviewOnClick />;
});
