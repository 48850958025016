import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from '../../../context';
import { Story, StoryStatus, storyApi } from '../../../services';
import {
  COLLAPSE_STORAGE_KEY,
  CollapseMode,
  IconLabel,
  PublishToggle,
  STORY_TAB_ADMIN_COOKIE_KEY,
  StoryTags,
  getCssVar,
  useAppDispatch,
  useAppSelector,
} from '../../../shared';
import { getStories, togglePublishOnStory, updateStoriesPositionOnDrop } from '../../../slices';
import { NoContent } from '../../Error';
import { CreateStoryModal } from '../CreateStoryModal';
import { DeleteStoryModal } from '../DeleteStoryModal';
import { StoryMenuAdmin } from './StoryMenuAdmin';

import classNames from 'classnames';
import classes from './StoryListAdmin.module.scss';

export const StoryListAdmin: FunctionComponent = () => {
  const { t } = useTranslation();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { stories, isFetching } = useAppSelector(({ stories }) => stories);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [cookies] = useCookies([STORY_TAB_ADMIN_COOKIE_KEY]);

  const [storyUpdatePositionTrigger] = storyApi.endpoints.storyUpdatePosition.useLazyQuery();

  const [deleteStoryId, setDeleteStoryId] = useState<number | null>(null);

  const [updateStoryId, setUpdateStoryId] = useState<number | null>(null);

  const [draggingStoryId, setDraggingStoryId] = useState<number | null>(null);

  const [dropProcessing, setDropProcessing] = useState<boolean>(false);

  const [publishToggling, setPublishToggling] = useState(false);

  const { requestFilter = undefined } = {
    ...cookies[STORY_TAB_ADMIN_COOKIE_KEY],
  };

  const [collapseMode, setCollapseMode] = useState(
    localStorage.getItem(COLLAPSE_STORAGE_KEY) || CollapseMode.STANDARD
  );

  useEffect(() => {
    window.addEventListener('storage', () => {
      setCollapseMode(localStorage.getItem(COLLAPSE_STORAGE_KEY) || CollapseMode.STANDARD);
    });
  }, []);

  const isStandard = useMemo(() => collapseMode === CollapseMode.STANDARD, [collapseMode]);

  useEffect(() => {
    dispatch(getStories({ channelId, filter: requestFilter }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, dispatch]);

  const navigateToStoryFeedItems = useCallback(
    (id: number) => {
      navigate(`/admin/storyTab/${id}/items`);
    },
    [navigate]
  );

  const getTitle = useCallback(
    (title: string) => <div className={classes['story-list__item-top-title']}>{title}</div>,
    []
  );

  const getStatus = useCallback(
    (status: StoryStatus, cardsCount: number) => {
      return (
        <div
          className={classNames(classes['story-list__item-top-status'], {
            [classes['story-list__item-top-status--published']]: status === StoryStatus.PUBLISHED,
          })}
        >
          {t(`common.${status.toLowerCase()}`)}, {t('common.cards-count', { count: cardsCount })}
        </div>
      );
    },
    [t]
  );

  const storyStatusChange = useCallback(
    async (storyId: number, isPublished: boolean) => {
      setPublishToggling(true);

      const status = isPublished ? StoryStatus.UNPUBLISHED : StoryStatus.PUBLISHED;

      try {
        await toast.promise(
          dispatch(togglePublishOnStory({ storyId, fields: { status } })).unwrap(),
          {
            pending: t(`publishToggle.${status}-toggling`),
            success: t(`publishToggle.${status}-toggled`),
            error: t(`publishToggle.${status}-error`),
          }
        );
      } catch (_) {
        toast(t(`publishToggle.${status}-error`));
      } finally {
        setPublishToggling(false);
      }
    },
    [dispatch, t]
  );

  const getContent = useCallback(
    (story: Story) => {
      const { id, title, subtitle, status, cardsCount, image } = story;
      const { url: imageUrl } = { ...image };

      const isPublished = status === StoryStatus.PUBLISHED;

      if (isStandard) {
        return (
          <>
            <div
              className={classes['story-list__item-top']}
              onClick={() => navigateToStoryFeedItems(id)}
            >
              <div className={classes['story-list__item-top-description']}>
                {getStatus(status, cardsCount)}

                <StoryTags story={story} />

                {getTitle(title)}
                {subtitle && (
                  <div className={classes['story-list__item-top-subTitle']}>{subtitle}</div>
                )}
              </div>
              {imageUrl && (
                <div className={classes['story-list__item-top-image-wrapper']}>
                  <img
                    className={classes['story-list__item-top-image']}
                    src={imageUrl}
                    alt={'preview'}
                  />
                </div>
              )}
            </div>
            <div className={classes['story-list__item-bottom']}>
              <PublishToggle
                isPublished={isPublished}
                onClick={() => storyStatusChange(id, isPublished)}
                disabled={publishToggling}
              />
              {!isMobileOnly && (
                <IconLabel
                  iconId={'edit'}
                  iconSize={18}
                  label={t('common.edit')}
                  color={getCssVar('--base-link-text-color')}
                  hoverColor={getCssVar('--base-link-text-hover-color')}
                  onClick={() => setUpdateStoryId(id)}
                />
              )}
              <IconLabel
                iconId={'delete'}
                iconSize={18}
                label={t('common.delete')}
                color={getCssVar('--color-danger')}
                hoverColor={getCssVar('--color-danger-hover')}
                onClick={() => setDeleteStoryId(id)}
              />
              <StoryMenuAdmin storyId={id} />
            </div>
          </>
        );
      }

      return (
        <div
          className={classes['story-list__item-top']}
          onClick={() => navigateToStoryFeedItems(id)}
        >
          <div className={classes['story-list__item-top-description']}>
            {getStatus(status, cardsCount)}
            {getTitle(title)}
          </div>
        </div>
      );
    },
    [
      getStatus,
      getTitle,
      isStandard,
      navigateToStoryFeedItems,
      publishToggling,
      storyStatusChange,
      t,
    ]
  );

  const storyDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>, storyId: number, position: number) => {
      if (draggingStoryId === null) {
        return;
      }

      event.preventDefault();

      (event.target as HTMLDivElement).classList.remove(classes['story-list__item--ghost']);

      if (storyId === draggingStoryId) {
        return;
      }

      setDropProcessing(true);

      dispatch(updateStoriesPositionOnDrop({ draggingStoryId, targetStoryId: storyId }));

      const updated = await storyUpdatePositionTrigger({
        storyId: draggingStoryId,
        position,
      }).unwrap();

      if (!updated) {
        dispatch(getStories({ channelId, filter: requestFilter }));
      }

      setDraggingStoryId(null);

      setDropProcessing(false);
    },
    [channelId, dispatch, draggingStoryId, requestFilter, storyUpdatePositionTrigger]
  );

  const storyDragEnd = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (draggingStoryId === null) {
        return;
      }

      (event.target as HTMLDivElement).classList.remove(classes['story-list__item--ghost']);

      if (!dropProcessing) {
        setDraggingStoryId(null);
      }
    },
    [draggingStoryId, dropProcessing]
  );

  const storyDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (draggingStoryId === null) {
        return;
      }

      (event.target as HTMLDivElement).classList.remove(classes['story-list__item--ghost']);
    },
    [draggingStoryId]
  );

  const storyDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (draggingStoryId === null) {
        return;
      }

      event.preventDefault();

      (event.target as HTMLDivElement).classList.add(classes['story-list__item--ghost']);
    },
    [draggingStoryId]
  );

  if (isFetching) {
    return (
      <div className={classes['story-list__loader']}>
        <Skeleton height={'5rem'} />
        <Skeleton height={'5rem'} />
        <Skeleton height={'5rem'} />
      </div>
    );
  }

  if (!stories.length) {
    return <NoContent />;
  }

  return (
    <div className={classes['story-list']}>
      {stories.map((story) => {
        const { id, position } = story;

        return (
          <div
            key={id}
            className={classNames(`story-${id}`, classes['story-list__item'], {
              [classes['story-list__item--dragging']]: Boolean(draggingStoryId),
            })}
            draggable
            onDragStart={() => {
              setDraggingStoryId(id);
            }}
            onDrop={(event) => storyDrop(event, id, position)}
            onDragEnd={storyDragEnd}
            onDragLeave={storyDragLeave}
            onDragOver={storyDragOver}
          >
            <IconLabel
              iconId={'drag'}
              iconSize={14}
              className={classes['story-list__item-drag-icon']}
              singleColor
            />
            <div className={classes['story-list__item-content']} draggable>
              {getContent(story)}
            </div>
          </div>
        );
      })}

      {deleteStoryId && (
        <DeleteStoryModal
          isOpen={Boolean(deleteStoryId)}
          onClose={() => setDeleteStoryId(null)}
          storyId={deleteStoryId}
        />
      )}

      {updateStoryId && (
        <CreateStoryModal
          isOpen={Boolean(updateStoryId)}
          onClose={() => setUpdateStoryId(null)}
          story={stories.find(({ id }) => id === updateStoryId) as Story}
        />
      )}
    </div>
  );
};
