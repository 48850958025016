import { createContext } from 'react';
import { AppTab, ChannelPermissions } from '../services';

export interface ChannelContextProps {
  appTabs: AppTab[];
  allAppTabs: AppTab[];
  dashboardUrl: string | null;
  permissions: ChannelPermissions;
}

export const ChannelContext = createContext<ChannelContextProps>({} as ChannelContextProps);
