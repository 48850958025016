import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';
import { useCallback, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { PUBLIC_URL } from '../../env';
import { Config, ConfigFirebaseAnalytics, userApi } from '../../services';
import { USER_DEVICE_COOKIE_KEY } from '../constants';
import { cookieOptions, CUSTOM_CLIENT_CONFIG, getOrganisationDomain } from '../utils';

export const useUserDevice = (config?: Config) => {
  const organisation = useMemo(() => getOrganisationDomain(), []);

  const cookieKey = useMemo(() => `${USER_DEVICE_COOKIE_KEY}_${organisation}`, [organisation]);

  const [cookies, setCookie] = useCookies([cookieKey]);

  const { fcmToken = '' } = { ...cookies[cookieKey] };

  const [userDeviceRegistration] = userApi.endpoints.userDeviceRegistration.useLazyQuery();

  const registerDevice = useCallback(
    async ({
      messaging,
      firebaseAnalytics,
      vapidKey,
    }: {
      messaging: Messaging;
      firebaseAnalytics: ConfigFirebaseAnalytics;
      vapidKey: string;
    }) => {
      if (Notification.permission !== 'granted' || fcmToken) {
        return;
      }

      const firebaseConfig = window
        .btoa(JSON.stringify(firebaseAnalytics))
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register(
            `${PUBLIC_URL}/firebase-messaging-sw-custom.js?firebaseConfig=${firebaseConfig}&organisation=${organisation}&urlStructure=${CUSTOM_CLIENT_CONFIG?.urlStructure}&publicUrl=${PUBLIC_URL}`.replace(
              /\/\//g,
              '/'
            )
          )
          .then(async (serviceWorkerRegistration) => {
            if (!serviceWorkerRegistration.active) {
              return;
            }

            const token = await getToken(messaging, { vapidKey, serviceWorkerRegistration });

            if (!token) {
              return;
            }

            const registered = await userDeviceRegistration({ fcmToken: token }).unwrap();

            if (registered) {
              setCookie(cookieKey, { fcmToken: token }, cookieOptions());
            }
          });
      }
    },
    [cookieKey, fcmToken, organisation, setCookie, userDeviceRegistration]
  );

  useEffect(() => {
    if (!config) {
      return;
    }

    const { firebaseAnalytics, vapidKey } = config.elements;

    if (!firebaseAnalytics || !vapidKey) {
      return;
    }

    initializeApp(firebaseAnalytics);

    const messaging = getMessaging();

    registerDevice({ messaging, firebaseAnalytics, vapidKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);
};
