import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DetailsModalData } from '../../context';
import { layoutPath } from '../utils';

interface LinkClickArgs {
  channelId?: number;
  userId?: number;
  setDetailsModalData: Dispatch<SetStateAction<DetailsModalData>>;
}

interface LinkClickHandlerArgs {
  event: MouseEvent;
  href: string;
}

export const useLinkClick = ({ channelId, userId, setDetailsModalData }: LinkClickArgs) => {
  const navigate = useNavigate();

  const storyCardDeepLinkClick = useCallback(
    ({ event, href }: LinkClickHandlerArgs) => {
      event.preventDefault();

      const [storyId, cardId] = href.split(`/apps/posts/${channelId}/`)[1].split('/');

      setDetailsModalData({ storyId: parseInt(storyId, 10), cardId: parseInt(cardId, 10) });
    },
    [channelId, setDetailsModalData]
  );

  const userLinkClick = useCallback(
    ({ event, href }: LinkClickHandlerArgs) => {
      const id = parseInt(href.split('user://')[1], 10);

      if (!id) {
        return;
      }

      event.preventDefault();

      navigate(layoutPath(`${id === userId ? '/profile' : `/profile/${id}`}`));
    },
    [navigate, userId]
  );

  useEffect(() => {
    if (!channelId || !userId) {
      return;
    }

    const handleClick = (event: MouseEvent) => {
      const link = (event.target as HTMLElement).closest('a');

      if (!link?.href) {
        return;
      }

      const { href } = link;

      switch (true) {
        case href.includes(`/apps/posts/${channelId}/`):
          return storyCardDeepLinkClick({ event, href });
        case href.includes('user://'):
          return userLinkClick({ event, href });
      }
    };

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, [channelId, storyCardDeepLinkClick, userId, userLinkClick]);
};
