import { isAdminLayout, PaginationInfo } from '../shared';
import { graphqlApi } from './graphqlApi';
import { storyCardQuery } from './helpers';
import { StoryCard, StoryCardStatus, StoryCardType } from './storyCard';

export enum NewsFeedType {
  CHAT_PROMOTIONS = 'CHAT_PROMOTIONS',
  HORIZONTAL_STORY_FEED = 'HORIZONTAL_STORY_FEED',
  STORY_CARD_ITEM = 'STORY_CARD_ITEM',
}

export enum NewsFeedScope {
  WEB_FEED = 'WEB_FEED',
  MOBILE_FEED = 'MOBILE_FEED',
}

export interface HorizontalStoryFeedData {
  pageInfo: PaginationInfo;
  storyId: number;
  items: StoryCard[];
}

export interface StoryCardItemData {
  item: StoryCard;
}

export interface NewsFeedItem {
  type: NewsFeedType;
  data: HorizontalStoryFeedData | StoryCardItemData;
}

interface NewsFeedData {
  items: NewsFeedItem[];
  pageInfo: PaginationInfo;
}

interface NewsFeedResponse {
  data: { newsfeedComposed: NewsFeedData };
  errors?: [{ message: string }];
}

export interface NewsFeedFilter {
  query?: string;
  from?: string;
  to?: string;
  onlyCompletedMedia?: boolean;
  feedType?: NewsFeedType[];
  scope?: NewsFeedScope;
  storyCardType?: StoryCardType[];
  authorId?: number[];
  categoryId: number[];
  organisationTags?: { externalTagsId: number[] | null; internalTagsId: number[] | null };
  storyId?: number[];
  status?: StoryCardStatus[];
}

export interface NewsFeedArgs {
  channelId: number;
  page?: number;
  size?: number;
  filter?: NewsFeedFilter;
}

export const newsFeedApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    newsFeed: builder.query<NewsFeedData, NewsFeedArgs>({
      query: ({ channelId, page = 1, size = 10, filter }) => ({
        url: '/graphql/webapp?newsfeedComposed',
        method: 'POST',
        body: {
          query: `query NewsfeedComposed($channelId: Int!, $page: Int, $size: Int, $filter:GetNewsfeedComposeFilterArgs) {
            newsfeedComposed(channelId: $channelId, page: $page, size: $size, filter: $filter) {
              items {
                ... on NewsfeedComposeElementHorizontalStoryFeed {
                  type
                  data {
                    pageInfo {
                      page
                      perPage
                      hasNextPage
                    }
                    storyId
                    items {
                      ${storyCardQuery({ isFeed: true, isAdminLayout: isAdminLayout() })}
                    }
                  }     
                }
                ... on NewsfeedComposeElementStoryCardItem {
                  type
                  data {
                    item {
                      ${storyCardQuery({ isFeed: true, isAdminLayout: isAdminLayout() })}
                    }
                  }
                }
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            channelId,
            page,
            size,
            filter,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: NewsFeedResponse) => {
        if (response?.errors?.length) {
          return {
            items: [],
            pageInfo: { page: 0, hasNextPage: false } as PaginationInfo,
          };
        }

        return response.data.newsfeedComposed;
      },
    }),
  }),
});

export const { useNewsFeedQuery } = newsFeedApi;
