import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ChannelContext, ChatContext, OrganisationContext, UserContext } from '../../../context';
import {
  Chat,
  ChatAccessTypes,
  ChatLevels,
  chatsApi,
  ChatTypes,
  graphqlChatsApi,
  User,
} from '../../../services';
import {
  AutoComplete,
  AutoCompleteProps,
  Avatar,
  AvatarUpload,
  Button,
  ButtonType,
  ImageFile,
  InputField,
  layoutPath,
  Modal,
  optArrItem,
  Select,
  TextAreaField,
} from '../../../shared';

import classes from './CreateChatModal.module.scss';

interface CreateChatModalProps {
  chat: Chat;
  isOpen: boolean;
  onClose: () => void;
}

interface ChatUpdateData extends Partial<Chat> {
  chatId: number;
}

const NAME_MAX_LENGTH = 160;

const PURPOSE_MAX_LENGTH = 200;

export const CreateChatModal: FunctionComponent<CreateChatModalProps> = memo(
  ({ chat, isOpen, onClose }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const { permissions: channelPermissions } = useContext(ChannelContext);

    const { permissions: orgPermissions } = useContext(OrganisationContext).organisation;

    const [getChannelChats] = chatsApi.endpoints.getChannelChats.useLazyQuery();

    const [createChat] = chatsApi.endpoints.createChat.useMutation();

    const [updateChat] = chatsApi.endpoints.updateChat.useMutation();

    const [chatP2PCandidates] = graphqlChatsApi.endpoints.chatP2PCandidates.useLazyQuery();

    const [chatChannelGroupCandidates] =
      graphqlChatsApi.endpoints.chatChannelGroupCandidates.useLazyQuery();

    const [chatOrganisationGroupCandidates] =
      graphqlChatsApi.endpoints.chatOrganisationGroupCandidates.useLazyQuery();

    const { pubnub, setActiveChat, setChats } = useContext(ChatContext);

    const chatLevelItems = useMemo(() => {
      return [
        ...optArrItem(channelPermissions.chatGroupAllowToCreate, {
          id: ChatLevels.channel,
          title: t(`chatLevels.${ChatLevels.channel}`),
          hint: t(`chatLevelHints.${ChatLevels.channel}`),
        }),
        ...optArrItem(orgPermissions.chatGroupAllowToCreate, {
          id: ChatLevels.organisation,
          title: t(`chatLevels.${ChatLevels.organisation}`),
          hint: t(`chatLevelHints.${ChatLevels.organisation}`),
        }),
      ];
    }, [channelPermissions.chatGroupAllowToCreate, orgPermissions.chatGroupAllowToCreate, t]);

    const {
      chatId,
      type,
      name: chatName = '',
      payload: chatPayload = '',
      level: chatLevel = chatLevelItems[0]?.id,
      accessType: chatAccessType = ChatAccessTypes.private,
      image,
    } = { ...chat };

    const [users, setUsers] = useState<User[]>([]);

    const originalChatData = useMemo(() => ({ ...chat }), [chat]);

    const [avatarLoading, setAvatarLoading] = useState<boolean>(false);

    const [chatPosting, setChatPosting] = useState<boolean>(false);

    const [avatar, setAvatar] = useState<ImageFile | null>(image ?? null);

    const [name, setName] = useState<string>(chatName);

    const [payload, setPayload] = useState<string>(chatPayload);

    const [level, setLevel] = useState<ChatLevels>(chatLevel);

    const [accessType, setAccessType] = useState<ChatAccessTypes>(chatAccessType);

    const editMode = useMemo(() => Boolean(chatId), [chatId]);

    const isP2P = useMemo(() => type === ChatTypes.p2p, [type]);

    const isOrgLevel = useMemo(() => level === ChatLevels.organisation, [level]);

    const header = useMemo(() => {
      return (
        <div className={classes['chat__header']}>
          {t(`${type}Chat${editMode ? 'Edit' : 'Create'}.title`)}
        </div>
      );
    }, [editMode, t, type]);

    const openChatLabel = useMemo(
      () => t(`${editMode ? 'common.save' : 'chatActions.open'}`),
      [editMode, t]
    );

    const chatAccessTypesItems = useMemo(() => {
      return Object.keys(ChatAccessTypes).map((type) => {
        return { id: type, title: t(`chatAccessTypes.${type}`) };
      });
    }, [t]);

    const levelChangeHandler = useCallback(
      (value: ChatLevels) => {
        if (!editMode && isOrgLevel) {
          setUsers([]);
        }
        setLevel(value);
      },
      [editMode, isOrgLevel]
    );

    const getChangedFields = useCallback(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const changedFields: any = { chatId };

      const currentChatData: ChatUpdateData = {
        chatId,
        image: avatar,
        imageId: avatar?.id ?? null,
        name,
        payload,
        level,
        accessType,
      };

      for (const key in currentChatData) {
        const chatKey = key as keyof ChatUpdateData;

        if (currentChatData[chatKey] !== originalChatData[chatKey]) {
          changedFields[chatKey] = currentChatData[chatKey];
        }
      }

      return changedFields;
    }, [accessType, avatar, chatId, level, name, originalChatData, payload]);

    const chatP2PCandidatesFetchQuery = useCallback(
      (query: string, page?: number) => {
        return chatP2PCandidates({ page, query });
      },
      [chatP2PCandidates]
    );

    const chatChannelGroupCandidatesFetchQuery = useCallback(
      (query: string, page?: number) => {
        return chatChannelGroupCandidates({ channelId, page, query });
      },
      [channelId, chatChannelGroupCandidates]
    );

    const chatOrganisationGroupCandidatesFetchQuery = useCallback(
      (query: string, page?: number) => {
        return chatOrganisationGroupCandidates({ page, query });
      },
      [chatOrganisationGroupCandidates]
    );

    const userTemplate = useCallback(({ id, screenName, avatar }: User) => {
      return (
        <>
          <Avatar url={avatar?.url} />
          <div>{screenName}</div>
        </>
      );
    }, []);

    const content = useMemo(() => {
      const autoCompleteCommonProps = {
        value: users,
        itemTemplate: userTemplate,
        propToIdentify: 'id',
        propToDisplay: 'screenName',
        placeholder: t('chats.search-users-placeholder'),
      } as AutoCompleteProps;

      if (isP2P) {
        return (
          <AutoComplete
            {...autoCompleteCommonProps}
            fetchQuery={chatP2PCandidatesFetchQuery}
            onChange={(value) => setUsers(value.length ? [value.pop()] : [])}
            autoFocus
            closeOnSelect
          />
        );
      }

      const fetchQuery = isOrgLevel
        ? chatOrganisationGroupCandidatesFetchQuery
        : chatChannelGroupCandidatesFetchQuery;

      return (
        <>
          <AvatarUpload
            avatar={avatar}
            setAvatar={setAvatar}
            avatarSize={72}
            loading={avatarLoading}
            setLoading={setAvatarLoading}
            title={t('createChat.change-picture')}
          />
          <InputField
            value={name}
            onChange={({ target }) => setName(target.value)}
            label={t('createChat.name')}
            placeholder={t('common.input-max-length-allowed', { count: NAME_MAX_LENGTH })}
            maxLength={NAME_MAX_LENGTH}
          />
          <TextAreaField
            label={t('createChat.purpose')}
            value={payload}
            onChange={({ target }) => setPayload(target.value)}
            placeholder={t('common.input-max-length-allowed', { count: PURPOSE_MAX_LENGTH })}
            maxLength={PURPOSE_MAX_LENGTH}
            minRows={2}
          />
          <Select
            label={t('createChat.level')}
            selectedItemId={level}
            onChange={(value) => levelChangeHandler(value as ChatLevels)}
            items={chatLevelItems}
            disabled={editMode && isOrgLevel}
            staticListPosition
          />
          <Select
            label={t('createChat.access-type')}
            selectedItemId={accessType}
            onChange={(value) => setAccessType(value as ChatAccessTypes)}
            items={chatAccessTypesItems}
            staticListPosition
          />
          {!editMode && (
            <AutoComplete
              {...autoCompleteCommonProps}
              key={level}
              fetchQuery={fetchQuery}
              onChange={(value) => setUsers(value)}
            />
          )}
        </>
      );
    }, [
      accessType,
      avatar,
      avatarLoading,
      chatAccessTypesItems,
      chatChannelGroupCandidatesFetchQuery,
      chatLevelItems,
      chatOrganisationGroupCandidatesFetchQuery,
      chatP2PCandidatesFetchQuery,
      editMode,
      isOrgLevel,
      isP2P,
      level,
      levelChangeHandler,
      name,
      payload,
      t,
      userTemplate,
      users,
    ]);

    const createChatHandler = useCallback(async () => {
      const chat = await createChat({
        type,
        imageId: avatar?.id ?? null,
        name,
        payload,
        level,
        accessType,
        users: users.map(({ id }) => id),
      }).unwrap();

      const { chatId, chatDescriptor } = chat;

      setActiveChat(chat);

      const { chats, tokenV3 } = await getChannelChats().unwrap();

      pubnub.setAuthKey(tokenV3);

      setChats(chats);

      pubnub.subscribe({ channels: [chatDescriptor, `${chatDescriptor}.changes`] });

      navigate(layoutPath(`/chats?chatId=${chatId}`));

      // if (!channels) {
      //   pubnub.fetchMessages({ channels: [chatDescriptor], count: 1 }, (_, response) =>
      //     setChannels(response?.channels ?? null)
      //   );
      // }
    }, [
      accessType,
      avatar?.id,
      createChat,
      getChannelChats,
      level,
      name,
      navigate,
      payload,
      pubnub,
      setActiveChat,
      setChats,
      type,
      users,
    ]);

    const updateChatHandler = useCallback(async () => {
      const fieldsToUpdate = getChangedFields();

      await updateChat(fieldsToUpdate).unwrap();

      setChats((chatsPrev) => {
        const chatToUpdateIndex = chatsPrev.findIndex((chat) => chat.chatId === chatId);

        if (chatToUpdateIndex === -1) {
          return chatsPrev;
        }

        const chatsPrevCopy = [...chatsPrev];

        chatsPrevCopy[chatToUpdateIndex] = {
          ...chatsPrevCopy[chatToUpdateIndex],
          ...fieldsToUpdate,
        };

        return chatsPrevCopy;
      });

      setActiveChat((activeChatPrev) => {
        return { ...activeChatPrev, ...fieldsToUpdate };
      });
    }, [chatId, getChangedFields, setActiveChat, setChats, updateChat]);

    const postChatHandler = useCallback(async () => {
      setChatPosting(true);

      try {
        await (editMode ? updateChatHandler() : createChatHandler());
      } catch (e) {
        toast.error(t('common.error-message'));
      } finally {
        setChatPosting(false);
      }

      onClose();
    }, [createChatHandler, editMode, onClose, t, updateChatHandler]);

    const postDisabled = useMemo(() => {
      return avatarLoading || chatPosting || (isP2P && !users.length) || (!isP2P && !name.length);
    }, [avatarLoading, chatPosting, isP2P, name.length, users.length]);

    const body = useMemo(() => {
      return (
        <div className={classes['chat']}>
          {header}
          {content}
          <Button
            label={openChatLabel}
            type={ButtonType.primary}
            onClick={postChatHandler}
            loading={chatPosting}
            disabled={postDisabled}
          />
        </div>
      );
    }, [chatPosting, content, header, openChatLabel, postChatHandler, postDisabled]);

    return (
      <Modal isOpen={isOpen} body={body} onClose={onClose} keepOpened={chatPosting} alignTop />
    );
  }
);
