import { FunctionComponent, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { UserContext } from '../../../context';
import { useStoriesCardsUserFeedStatisticQuery } from '../../../services';
import { IconLabel, getCssVar } from '../../../shared';

import classes from './PostStats.module.scss';

export const PostStats: FunctionComponent = () => {
  const { t } = useTranslation();

  const { id: userId } = useContext(UserContext).userInfo.userData;

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { data: stats, isFetching } = useStoriesCardsUserFeedStatisticQuery({ channelId, userId });

  const fields = useMemo(() => {
    if (!stats) {
      return null;
    }

    const items = [];

    for (const [key, value] of Object.entries(stats)) {
      if (key === 'drafts') {
        continue;
      }

      items.push(
        <div key={key} className={classes['post-stats__field']}>
          <IconLabel
            iconSize={20}
            iconId={key}
            label={t(`common.${key}`)}
            color={getCssVar('--profile-info-label-color')}
            labelClassName={classes['post-stats__field-value']}
            nonClickable
          />
          <span className={classes['post-stats__field-value']}>{value}</span>
        </div>
      );
    }

    return items;
  }, [stats, t]);

  if (isFetching) {
    return <Skeleton width={'100%'} height={'10rem'} />;
  }

  if (!stats) {
    return null;
  }

  return (
    <div className={classes['post-stats']}>
      <div className={classes['post-stats__title']}>{t('profile.statistic')}</div>
      {fields}
    </div>
  );
};
