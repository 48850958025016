import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { ConfigContext, UserContext } from '../../../context';
import { StoryCardStatus, StoryStatus, useGetStoriesByChannelIdQuery } from '../../../services';
import { IconLabel, Select, SelectItem, useAppDispatch, useAppSelector } from '../../../shared';
import { getLatestsStoryCardsFeed } from '../../../slices';
import { LatestsStoryCard } from './LatestsStoryCard';

import classes from './LatestsStoryCards.module.scss';

export const LatestsStoryCards: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { channelId, channelName } = useContext(UserContext).userInfo.body;

  const { setup, cardsLimit } = useContext(ConfigContext).config.elements.latestsStoryCards;

  const storyIds = useMemo(
    () => setup.find((setupItems) => setupItems.channelId === channelId)?.storyIds ?? [],
    [channelId, setup]
  );

  const { data: stories, isFetching: isFetchingStories } = useGetStoriesByChannelIdQuery({
    channelId,
    filter: { onlyPostIn: false, status: [StoryStatus.PUBLISHED] },
  });

  const filteredStories = useMemo(() => {
    if (!Boolean(storyIds.length)) {
      return stories;
    }

    return stories?.filter(({ id }) => storyIds.includes(id));
  }, [stories, storyIds]);

  const { storyFeed: cards, isFetching: isFetchingLatestsStoryCardsFeed } = useAppSelector(
    ({ latestsStoryCardsFeed }) => latestsStoryCardsFeed
  );

  const [selectedStoryId, setSelectedStoryId] = useState<number>();

  const storageKey = useMemo(() => `Selected story for channel:${channelName}`, [channelName]);

  const storedStoryId = useMemo(
    () => parseInt(localStorage.getItem(storageKey) ?? '', 10),
    [storageKey]
  );

  const fallbackStoryId = useMemo(() => filteredStories?.[0]?.id, [filteredStories]);

  const storyOptions = useMemo(() => {
    return filteredStories?.map(({ id, title }) => {
      return { id, title } as SelectItem;
    });
  }, [filteredStories]);

  const getCards = useCallback(
    (storyId: number) => {
      dispatch(
        getLatestsStoryCardsFeed({
          storyId,
          size: cardsLimit,
          filter: { onlyCompletedMedia: true, status: [StoryCardStatus.PUBLISHED] },
        })
      );
    },
    [cardsLimit, dispatch]
  );

  const storySelectHandler = useCallback(
    (storyId: number) => {
      setSelectedStoryId(storyId);
      getCards(storyId);
    },
    [getCards]
  );

  useEffect(() => {
    if (!filteredStories) {
      return;
    }

    if (filteredStories?.find(({ id }) => id === storedStoryId)) {
      storySelectHandler(storedStoryId);
      return;
    }

    localStorage.removeItem(storageKey);
    fallbackStoryId && storySelectHandler(fallbackStoryId);
  }, [fallbackStoryId, filteredStories, storageKey, storedStoryId, stories, storySelectHandler]);

  const onStoryChange = useCallback(
    (storyId: number) => {
      localStorage.setItem(storageKey, storyId.toString());
      storySelectHandler(storyId);
    },
    [storageKey, storySelectHandler]
  );

  const renderCards = useMemo(() => {
    if (isFetchingLatestsStoryCardsFeed) {
      return (
        <Skeleton className={classes['latest-story-cards__cards-loader']} count={cardsLimit} />
      );
    }

    if (!Boolean(cards.length)) {
      return (
        <div className={classes['latest-story-cards__empty']}>
          <IconLabel
            className={classes['latest-story-cards__empty-icon']}
            iconId={'image'}
            iconSize={32}
            color={'#98A1A6'}
            nonClickable
          />
          {t('latestsStoryCards.no-content')}
        </div>
      );
    }

    return cards.map((card) => <LatestsStoryCard key={card.id} card={card} />);
  }, [cards, cardsLimit, isFetchingLatestsStoryCardsFeed, t]);

  if (!isFetchingStories && !filteredStories?.length) {
    return null;
  }

  if (isFetchingStories) {
    return <Skeleton width={'18.75rem'} height={'30rem'} />;
  }

  return (
    <div className={classes['latest-story-cards']}>
      {selectedStoryId && storyOptions && (
        <Select
          selectedItemId={selectedStoryId}
          label={t('latestsStoryCards.select-title')}
          items={storyOptions}
          onChange={(storyId) => onStoryChange(storyId as number)}
          disabled={Boolean(storyOptions.length === 1)}
          noBorder
        />
      )}
      {renderCards}
    </div>
  );
};
