import { Dispatch, SetStateAction, createContext } from 'react';

export interface DetailsModalData {
  storyId: number | null;
  cardId: number | null;
  chatId?: number;
  scrollToTags?: boolean;
  scrollToComments?: boolean;
  activeCommentsInput?: boolean;
}

export interface DetailsModalContextProps {
  detailsModalData: DetailsModalData;
  setDetailsModalData: Dispatch<SetStateAction<DetailsModalData>>;
}

export const DetailsModalContext = createContext<DetailsModalContextProps>(
  {} as DetailsModalContextProps
);
