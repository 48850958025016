import {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { DetailsModalContext } from '../../../../context';
import {
  StoryCard,
  StoryCardContentGallery,
  StoryCardQuoteContent,
  StoryCardType,
} from '../../../../services';
import {
  CardActions,
  GalleryItem,
  IconLabel,
  getCssVar,
  getStoryCardPreviewUrl,
  useCardDate,
} from '../../../../shared';

import classNames from 'classnames';
import classes from './HorizontalStoryFeedCardWrapper.module.scss';

interface HorizontalStoryFeedCardWrapperProps {
  card: StoryCard;
}

export const HorizontalStoryFeedCardWrapper: FunctionComponent<
  PropsWithChildren<HorizontalStoryFeedCardWrapperProps>
> = memo(({ card, children }) => {
  const navigate = useNavigate();

  const { setDetailsModalData } = useContext(DetailsModalContext);

  const { id: cardId, storyId, type, dateToDisplay, content, externalTags } = card;

  const date = useCardDate(dateToDisplay);

  const { gallery } = content as StoryCardContentGallery;

  const { quoteSource } = content as StoryCardQuoteContent;

  const galleryItem = gallery ? (gallery[0] as GalleryItem) : undefined;

  const previewUrl = getStoryCardPreviewUrl({ type, galleryItem });

  const isImageGallery = useMemo(
    () =>
      [StoryCardType.IMAGE, StoryCardType.QUOTE].includes(type) && gallery && gallery.length > 1,
    [gallery, type]
  );

  const shouldRenderOverlay = useMemo(
    () =>
      isImageGallery ||
      (previewUrl && [StoryCardType.VIDEO, StoryCardType.AUDIO, StoryCardType.PDF].includes(type)),
    [isImageGallery, previewUrl, type]
  );

  const cardClickHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if ((event.target as HTMLElement).tagName.toLowerCase() === 'a') {
        event.preventDefault();
      }

      isMobileOnly
        ? navigate(`/details/${storyId}/${cardId}`)
        : setDetailsModalData({ storyId, cardId });
    },
    [cardId, navigate, setDetailsModalData, storyId]
  );

  const tagsClickHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();

      isMobileOnly
        ? navigate(`/details/${storyId}/${cardId}?scrollToTags=true`)
        : setDetailsModalData({ storyId, cardId, scrollToTags: true });
    },
    [cardId, navigate, setDetailsModalData, storyId]
  );

  const iconId = useMemo(() => {
    switch (type) {
      case StoryCardType.ARTICLE:
      case StoryCardType.POST:
        return 'article';
      case StoryCardType.IMAGE:
        return isImageGallery ? 'gallery' : type.toLowerCase();
      case StoryCardType.QUOTE:
        return isImageGallery ? 'gallery' : quoteSource?.toLowerCase();
      default:
        return type.toLowerCase();
    }
  }, [isImageGallery, quoteSource, type]);

  const iconColor = useMemo(
    () => (!previewUrl ? getCssVar('--card-overlay-icon-color') : '#ffffff'),
    [previewUrl]
  );

  return (
    <>
      <div className={classNames(`card-${cardId}`, classes['card'])} onClick={cardClickHandler}>
        <div
          className={classNames(classes['card__teaser'], {
            [classes['card__teaser--placeholder']]: !previewUrl,
            [classes['card__teaser--overlay']]: shouldRenderOverlay,
          })}
          {...(previewUrl && { style: { backgroundImage: `url(${previewUrl})` } })}
        >
          {(!previewUrl || shouldRenderOverlay) && (
            <IconLabel
              className={classes['card__teaser-icon']}
              iconId={iconId}
              iconSize={32}
              color={iconColor}
              singleColor
            />
          )}
        </div>

        <div className={classes['card__content']}>
          {children}
          {date && <div className={classes['card__posted-time']}>{date}</div>}
        </div>
      </div>

      {Boolean(externalTags.length) && (
        <IconLabel
          className={classes['card__tags']}
          iconSize={18}
          iconId={'tags'}
          color={getCssVar('--action-pane-icon-color')}
          hoverColor={getCssVar('--action-pane-icon-hover-color')}
          onClick={tagsClickHandler}
        />
      )}

      <CardActions card={card} isHorizontalFeedCard />
    </>
  );
});
