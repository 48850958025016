import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoryCardCategoriesQuery } from '../../../services';
import { FilterValue, SelectItem } from '../../components';
import { useFilter } from './useFilter';

interface UseCardCategoryFilterArgs {
  filter: FilterValue[];
}

export const useCardCategoryFilter = ({ filter }: UseCardCategoryFilterArgs) => {
  const { t } = useTranslation();

  const { data: categories, isFetching } = useStoryCardCategoriesQuery();

  const { getGroupValue } = useFilter();

  const options = useMemo(() => {
    return (
      categories?.map(({ id, name }) => {
        return { id, title: name } as SelectItem;
      }) ?? []
    );
  }, [categories]);

  const groupId = 'category';

  const dropdown = useMemo(() => {
    return {
      disabled: isFetching,
      options,
      staticListPosition: false,
      search: true,
      multiCheck: true,
    };
  }, [isFetching, options]);

  return {
    group: {
      id: groupId,
      title: t('cardCategoryFilter.title'),
      value: getGroupValue({ filter, groupId, multiCheck: true, propToMap: 'id' }) ?? [],
      dropdown,
    },
  };
};
