import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';
import { channelsNewApi, User } from '../../../services';
import { AutoComplete } from '../AutoComplete';
import { Avatar } from '../Avatar';
import { Button, ButtonType } from '../Button';
import { Modal } from '../Modal';

import classes from './UserMentionModal.module.scss';

interface UserMentionModalProps {
  isOpen: boolean;
  onClose: () => void;
  mentionedUsers: User[];
  setMentionedUsers: (mentionedUsers: User[]) => void;
}

export const UserMentionModal: FunctionComponent<UserMentionModalProps> = memo(
  ({ isOpen, onClose, mentionedUsers, setMentionedUsers }) => {
    const { t } = useTranslation();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const [channelUsers] = channelsNewApi.endpoints.channelUsers.useLazyQuery();

    const [value, setValue] = useState<User[]>(mentionedUsers);

    const itemTemplate = useCallback(({ avatar, screenName }: User) => {
      return (
        <>
          <Avatar url={avatar?.url} />
          <div className={classes['user-mention__name']}>{screenName}</div>
        </>
      );
    }, []);

    const fetchQuery = useCallback(
      (query: string, page?: number) => {
        return channelUsers({ channelId, page, filters: { query } });
      },
      [channelId, channelUsers]
    );

    const onSave = useCallback(() => {
      setMentionedUsers(value);
      onClose();
    }, [onClose, setMentionedUsers, value]);

    const body = useMemo(() => {
      return (
        <div className={classes['user-mention']}>
          <div className={classes['user-mention__title']}>{t('userMention.title')}</div>

          <AutoComplete
            value={value}
            fetchQuery={fetchQuery}
            itemTemplate={itemTemplate}
            propToIdentify={'id'}
            propToDisplay={'screenName'}
            placeholder={t('common.type-to-search')}
            onChange={setValue}
            autoFocus
          />

          <div className={classes['user-mention__buttons']}>
            <Button type={ButtonType.secondary} label={t('common.cancel')} onClick={onClose} />
            <Button type={ButtonType.primary} label={t('common.save')} onClick={onSave} />
          </div>
        </div>
      );
    }, [fetchQuery, itemTemplate, onClose, onSave, t, value]);

    return <Modal isOpen={isOpen} body={body} root={'modal-mention-root'} keepOpened />;
  }
);
