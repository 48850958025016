import { FunctionComponent, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Story } from '../../../services';
import { getCssVar } from '../../utils';
import { IconLabel } from '../IconLabel';

import classNames from 'classnames';
import classes from './StoryFeedItemsHeader.module.scss';

interface StoryFeedItemsHeaderProps {
  story: Story;
}

export const StoryFeedItemsHeader: FunctionComponent<StoryFeedItemsHeaderProps> = memo(
  ({ story }) => {
    const navigate = useNavigate();

    const historyState = useMemo(() => window.history.state, []);

    const { title, subtitle, image } = story;

    const { url: imageUrl } = { ...image };

    return (
      <>
        <div
          className={classNames(classes['header'], {
            [classes['header--pointer']]: historyState,
          })}
          onClick={() => historyState && navigate(-1)}
        >
          {historyState && (
            <IconLabel
              iconId={'arrow-rounded-left'}
              color={getCssVar('--story-feed-back-color')}
              className={classes['header__icon']}
              singleColor
            />
          )}
          <div className={classes['header__description']}>
            <div
              className={classNames(classes['header__description-title'], {
                [classes['header__description-title--one-liner']]: imageUrl,
              })}
            >
              {title}
            </div>
            {subtitle && !imageUrl && (
              <div className={classes['header__description-subTitle']}>{subtitle}</div>
            )}
          </div>
        </div>
        {imageUrl && (
          <div className={classes['wrapper']}>
            <img className={classes['wrapper__image']} src={imageUrl} alt={'preview'} />
            <div className={classes['wrapper__text']}>
              <div className={classes['wrapper__text-title']}>{title}</div>
              {subtitle && <div className={classes['wrapper__text-subTitle']}>{subtitle}</div>}
            </div>
          </div>
        )}
      </>
    );
  }
);
