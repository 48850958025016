import { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { ConfigContext, UserContext } from '../../context';
import { useGetStoriesByChannelIdQuery } from '../../services';
import {
  CreateCardWithInput,
  StoryFeedItemsHeader,
  useAnalytics,
  useBeforeUnload,
  useDuration,
} from '../../shared';
import { NoContent } from '../Error';
import { StoryFeed } from '../StoryFeedItems';

import classes from './PinnedStoryFeedItems.module.scss';

export const PinnedStoryFeedItems: FunctionComponent = () => {
  const { t } = useTranslation();

  const storyId = Number(useParams().storyId);

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { data: stories, isFetching } = useGetStoriesByChannelIdQuery({ channelId });

  const { showOnPinnedStoryFeed, addContentLocaliseKey } =
    useContext(ConfigContext).config.elements.addContent;

  const { logClickOpenStory } = useAnalytics();

  const { startTrackTime, getDuration } = useDuration();

  const logClickOpen = useCallback(
    () =>
      logClickOpenStory({
        channel_id: channelId,
        story_id: storyId,
        mix_id: storyId,
        time_stamp: new Date().toISOString(),
        duration: getDuration(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channelId, logClickOpenStory, storyId]
  );

  useEffect(() => {
    startTrackTime();
    return () => logClickOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logClickOpen]);

  useBeforeUnload(logClickOpen);

  const story = stories?.find(({ id }) => id === storyId);

  if (isFetching) {
    return <Skeleton height={'10rem'} />;
  }

  if (!story) {
    return <NoContent />;
  }

  return (
    <div className={classes['story-feed-items']}>
      <StoryFeedItemsHeader story={story} />
      {showOnPinnedStoryFeed && <CreateCardWithInput label={t(addContentLocaliseKey)} />}
      <StoryFeed storyId={Number(storyId)} />
    </div>
  );
};
