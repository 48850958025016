import {
  Config,
  ConfigActionPane,
  ConfigAdminBadge,
  ConfigAnalytics,
  ConfigAnalyticsCharts,
  ConfigAnalyticsChartsTable,
  ConfigAuthorInfo,
  ConfigBaseLink,
  ConfigButton,
  ConfigButtons,
  ConfigCard,
  ConfigCardContent,
  ConfigCardTeaser,
  ConfigChats,
  ConfigChatsInput,
  ConfigChatsMessage,
  ConfigCheck,
  ConfigCircularLoader,
  ConfigCollapseSwitcher,
  ConfigComments,
  ConfigCommentsInput,
  ConfigCommentsItem,
  ConfigCreateCard,
  ConfigCreateCardSettings,
  ConfigCreateCardTeaser,
  ConfigCreateCardThread,
  ConfigDetailsBlock,
  ConfigDetailsBlockAudio,
  ConfigDetailsBlockCallout,
  ConfigDetailsBlockCheckList,
  ConfigDetailsBlockCommon,
  ConfigDetailsBlockDelimiter,
  ConfigDetailsBlockEmbedLink,
  ConfigDetailsBlockHeader,
  ConfigDetailsBlockList,
  ConfigDetailsBlockParagraph,
  ConfigDetailsBlockQuote,
  ConfigDetailsBlockTable,
  ConfigDetailsBlockVideo,
  ConfigErrorLayout,
  ConfigErrorLayoutPage,
  ConfigExternalTagsList,
  ConfigFilter,
  ConfigFooter,
  ConfigForm,
  ConfigFrontDoorLinks,
  ConfigHeader,
  ConfigHorizontalFeed,
  ConfigIconLabelWithActive,
  ConfigInput,
  ConfigLatestsStoryCards,
  ConfigLogin,
  ConfigMedia,
  ConfigMediaAudio,
  ConfigMediaFile,
  ConfigMediaVideo,
  ConfigModal,
  ConfigMultiStepFilter,
  ConfigNavMenu,
  ConfigNavMenuLink,
  ConfigNoContent,
  ConfigNotifications,
  ConfigPostedIn,
  ConfigProfile,
  ConfigProfileAvatar,
  ConfigProfileFeed,
  ConfigProfileFeedCreateCard,
  ConfigProfileUpload,
  ConfigPublishToggle,
  ConfigRadio,
  ConfigScheduleIcon,
  ConfigSearch,
  ConfigSearchInput,
  ConfigSelect,
  ConfigSettings,
  ConfigSignup,
  ConfigSocialCard,
  ConfigSpecialTeaser,
  ConfigStoryFeed,
  ConfigStoryTab,
  ConfigTabs,
  ConfigThreadCard,
  ConfigUserDirectory,
  DropdownMenu,
} from '../../services';

export const getCssVar = (variable: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable);
};

export const setCssVar = (variable: string, value: string) => {
  if (!variable || !value) {
    return;
  }
  return document.documentElement.style.setProperty(variable, value);
};

const setSelectVars = (select: ConfigSelect) => {
  const { iconColor, backgroundColor, backgroundHoverColor, labelColor, titleColor, borderColor } =
    { ...select };

  const prefix = '--select';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-background-hover-color`, backgroundHoverColor);
  setCssVar(`${prefix}-label-color`, labelColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-border-color`, borderColor);
};

const setButtonVars = (button: ConfigButton, prefix: string) => {
  const {
    borderColor,
    borderHoverColor,
    borderDisabledColor,
    textColor,
    textHoverColor,
    textDisabledColor,
    backgroundColor,
    backgroundHoverColor,
    backgroundDisabledColor,
  } = { ...button };

  setCssVar(`${prefix}-border-color`, borderColor);
  setCssVar(`${prefix}-border-hover-color`, borderHoverColor);
  setCssVar(`${prefix}-border-disabled-color`, borderDisabledColor);
  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-text-hover-color`, textHoverColor);
  setCssVar(`${prefix}-text-disabled-color`, textDisabledColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-background-hover-color`, backgroundHoverColor);
  setCssVar(`${prefix}-background-disabled-color`, backgroundDisabledColor);
};

const setButtonsVars = (buttons: ConfigButtons) => {
  const { primary, secondary } = { ...buttons };

  setButtonVars(primary, '--btn-primary');
  setButtonVars(secondary, '--btn-secondary');
};

const setDropdownMenuVars = (dropdownMenu: DropdownMenu) => {
  const { iconColor, textColor, backgroundColor, itemBackgroundHoverColor } = { ...dropdownMenu };

  const prefix = '--dropdown-menu';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-item-background-hover-color`, itemBackgroundHoverColor);
};

const setInputVars = (input: ConfigInput, prefix?: string) => {
  const {
    backgroundColor,
    backgroundHoverColor,
    backgroundDisabledColor,
    backgroundErrorColor,
    textColor,
    textHoverColor,
    textDisabledColor,
    textErrorColor,
    placeholderColor,
    borderColor,
    borderFocusColor,
    borderDisabledColor,
    borderErrorColor,
    labelColor,
    iconColor,
    iconErrorColor,
    errorMessageColor,
  } = { ...input };

  const prefixRes = prefix ?? '--input';

  setCssVar(`${prefixRes}-background-color`, backgroundColor);
  setCssVar(`${prefixRes}-background-hover-color`, backgroundHoverColor);
  setCssVar(`${prefixRes}-background-disabled-color`, backgroundDisabledColor);
  setCssVar(`${prefixRes}-background-error-color`, backgroundErrorColor);
  setCssVar(`${prefixRes}-text-color`, textColor);
  setCssVar(`${prefixRes}-text-hover-color`, textHoverColor);
  setCssVar(`${prefixRes}-text-disabled-color`, textDisabledColor);
  setCssVar(`${prefixRes}-text-error-color`, textErrorColor);
  setCssVar(`${prefixRes}-placeholder-color`, placeholderColor);
  setCssVar(`${prefixRes}-border-color`, borderColor);
  setCssVar(`${prefixRes}-border-focus-color`, borderFocusColor);
  setCssVar(`${prefixRes}-border-disabled-color`, borderDisabledColor);
  setCssVar(`${prefixRes}-border-error-color`, borderErrorColor);
  setCssVar(`${prefixRes}-label-color`, labelColor);
  setCssVar(`${prefixRes}-icon-color`, iconColor);
  setCssVar(`${prefixRes}-icon-error-color`, iconErrorColor);
  setCssVar(`${prefixRes}-error-message-color`, errorMessageColor);
};

const setHeaderVars = (header: ConfigHeader) => {
  const { backgroundColor, borderBottomColor } = { ...header };

  const prefix = '--header';

  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-border-bottom-color`, borderBottomColor);
};

const setNoContentVars = (noContent: ConfigNoContent) => {
  const {
    borderColor,
    backgroundColor,
    faceBackgroundColor,
    faceColor,
    titleColor,
    subTitleColor,
  } = { ...noContent };

  const prefix = '--no-content';

  setCssVar(`${prefix}-border-color`, borderColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-face-background-color`, faceBackgroundColor);
  setCssVar(`${prefix}-face-color`, faceColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-subtitle-color`, subTitleColor);
};

const setErrorLayoutPageVars = (page: ConfigErrorLayoutPage) => {
  const {
    outerBorderColor,
    innerBorderColor,
    outerBackgroundColor,
    innerBackgroundColor,
    headerColor,
    faceColor,
    textColor,
    overlayColor,
    circleColor,
  } = { ...page };

  const prefix = '--error-layout-page';

  setCssVar(`${prefix}-outer-border-color`, outerBorderColor);
  setCssVar(`${prefix}-inner-border-color`, innerBorderColor);
  setCssVar(`${prefix}-outer-background-color`, outerBackgroundColor);
  setCssVar(`${prefix}-inner-background-color`, innerBackgroundColor);
  setCssVar(`${prefix}-header-color`, headerColor);
  setCssVar(`${prefix}-face-color`, faceColor);
  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-overlay-color`, overlayColor);
  setCssVar(`${prefix}-circle-color`, circleColor);
};

const setErrorLayoutVars = (errorLayout: ConfigErrorLayout) => {
  const {
    backgroundColor,
    borderColor,
    titleColor,
    subTitleColor,
    messageColor,
    page,
    outerBackgroundColor,
  } = { ...errorLayout };

  const prefix = '--error-layout';

  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-outer-background-color`, outerBackgroundColor);
  setCssVar(`${prefix}-border-color`, borderColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-subtitle-color`, subTitleColor);
  setCssVar(`${prefix}-message-color`, messageColor);

  setErrorLayoutPageVars(page);
};

const setModalVars = (modal: ConfigModal) => {
  const { overlayBackgroundColor, backgroundColor, closeColor, previewCloseColor, titleColor } = {
    ...modal,
  };

  const prefix = '--modal';

  setCssVar(`${prefix}-overlay-background-color`, overlayBackgroundColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-close-color`, closeColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-preview-close-color`, previewCloseColor);
};

const setStoryTabVars = (storyTab: ConfigStoryTab) => {
  const {
    headlineColor,
    titleColor,
    titleHoverColor,
    subTitleColor,
    subTitleHoverColor,
    iconColor,
    borderColor,
    backgroundColor,
  } = { ...storyTab };

  const prefix = '--story-tab';

  setCssVar(`${prefix}-headline-color`, headlineColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-title-hover-color`, titleHoverColor);
  setCssVar(`${prefix}-subtitle-color`, subTitleColor);
  setCssVar(`${prefix}-subtitle-hover-color`, subTitleHoverColor);
  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-border-color`, borderColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setStoryFeedVars = (storyFeed: ConfigStoryFeed) => {
  const {
    backColor,
    titleColor,
    subTitleColor,
    overlayColor,
    overlayTitleColor,
    overlaySubTitleColor,
  } = { ...storyFeed };

  const prefix = '--story-feed';

  setCssVar(`${prefix}-back-color`, backColor);
  setCssVar(`${prefix}-overlay-color`, overlayColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-overlay-title-color`, overlayTitleColor);
  setCssVar(`${prefix}-subtitle-color`, subTitleColor);
  setCssVar(`${prefix}-overlay-subtitle-color`, overlaySubTitleColor);
};

const setHorizontalFeedVars = (horizontalFeed: ConfigHorizontalFeed) => {
  const {
    titleColor,
    cardsPerView,
    spaceBetweenCards,
    overlayBackgroundColor,
    placeholderBackgroundColor,
    placeholderColor,
  } = { ...horizontalFeed };

  const prefix = '--horizontal-feed';

  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-cards-per-view`, cardsPerView.toString());
  setCssVar(`${prefix}-space-between-cards`, spaceBetweenCards.toString());
  setCssVar(`${prefix}-overlay-background-color`, overlayBackgroundColor);
  setCssVar(`${prefix}-placeholder-background-color`, placeholderBackgroundColor);
  setCssVar(`${prefix}-placeholder-color`, placeholderColor);
};

const setProfileAvatarVars = (avatar: ConfigProfileAvatar) => {
  const { size, borderColor, borderHoverColor, backgroundColor } = { ...avatar };

  const prefix = '--profile-avatar';

  setCssVar(`${prefix}-size`, size.toString());
  setCssVar(`${prefix}-border-color`, borderColor);
  setCssVar(`${prefix}-border-hover-color`, borderHoverColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setProfileUploadVars = (upload: ConfigProfileUpload) => {
  const { overlayColor, labelColor, labelHoverColor, iconColor } = { ...upload };

  const prefix = '--profile-upload';

  setCssVar(`${prefix}-label-color`, labelColor);
  setCssVar(`${prefix}-label-hover-color`, labelHoverColor);
  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-overlay-color`, overlayColor);
};

const setProfileFeedCreateCardVars = (createCard: ConfigProfileFeedCreateCard) => {
  const { iconColor, iconBackgroundColor, labelColor, labelHoverColor, backgroundColor } = {
    ...createCard,
  };

  const prefix = '--profile-feed-create-card';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-icon-background-color`, iconBackgroundColor);
  setCssVar(`${prefix}-label-color`, labelColor);
  setCssVar(`${prefix}-label-hover-color`, labelHoverColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setProfileFeedVars = (feed: ConfigProfileFeed) => {
  const { titleColor, createCard } = { ...feed };

  const prefix = '--profile-feed';

  setCssVar(`${prefix}-title-color`, titleColor);

  setProfileFeedCreateCardVars(createCard);
};

const setProfileVars = (profile: ConfigProfile) => {
  const {
    nameColor,
    bioColor,
    headBackgroundColor,
    borderColor,
    avatar,
    socialFieldsColor,
    infoLabelColor,
    infoValueColor,
    infoBackgroundColor,
    menuIconColor,
    menuIconHoverColor,
    backColor,
    backHoverColor,
    upload,
    feed,
  } = { ...profile };

  const prefix = '--profile';

  setCssVar(`${prefix}-name-color`, nameColor);
  setCssVar(`${prefix}-bio-color`, bioColor);
  setCssVar(`${prefix}-head-background-color`, headBackgroundColor);
  setCssVar(`${prefix}-border-color`, borderColor);
  setCssVar(`${prefix}-social-color`, socialFieldsColor);
  setCssVar(`${prefix}-menu-icon-color`, menuIconColor);
  setCssVar(`${prefix}-menu-icon-hover-color`, menuIconHoverColor);
  setCssVar(`${prefix}-back-color`, backColor);
  setCssVar(`${prefix}-back-hover-color`, backHoverColor);
  setCssVar(`${prefix}-info-label-color`, infoLabelColor);
  setCssVar(`${prefix}-info-value-color`, infoValueColor);
  setCssVar(`${prefix}-info-background-color`, infoBackgroundColor);

  setProfileAvatarVars(avatar);
  setProfileUploadVars(upload);
  setProfileFeedVars(feed);
};

const setDetailsBlockHeaderVars = (header: ConfigDetailsBlockHeader) => {
  const { h1Color, h2Color, h3Color, h4Color, h5Color, h6Color } = { ...header };

  const prefix = '--details-block-header';

  setCssVar(`${prefix}-1-color`, h1Color);
  setCssVar(`${prefix}-2-color`, h2Color);
  setCssVar(`${prefix}-3-color`, h3Color);
  setCssVar(`${prefix}-4-color`, h4Color);
  setCssVar(`${prefix}-5-color`, h5Color);
  setCssVar(`${prefix}-6-color`, h6Color);
};

const setDetailsBlockParagraphVars = (paragraph: ConfigDetailsBlockParagraph) => {
  const { color } = { ...paragraph };

  const prefix = '--details-block-paragraph';

  setCssVar(`${prefix}-color`, color);
};

const setDetailsBlockAudioVars = (audio: ConfigDetailsBlockAudio) => {
  const { nameColor, captionColor } = { ...audio };

  const prefix = '--details-block-audio';

  setCssVar(`${prefix}-name-color`, nameColor);
  setCssVar(`${prefix}-caption-color`, captionColor);
};

const setDetailsBlockVideoVars = (video: ConfigDetailsBlockVideo) => {
  const { captionColor } = { ...video };

  const prefix = '--details-block-video';

  setCssVar(`${prefix}-caption-color`, captionColor);
};

const setDetailsBlockCalloutVars = (callout: ConfigDetailsBlockCallout) => {
  const { textColor, backgroundColor } = { ...callout };

  const prefix = '--details-block-callout';

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setDetailsBlockCheckListVars = (checkList: ConfigDetailsBlockCheckList) => {
  const { textColor, backgroundColor, iconColor } = { ...checkList };

  const prefix = '--details-block-check-list';

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-icon-color`, iconColor);
};

const setDetailsBlockDelimiterVars = (delimiter: ConfigDetailsBlockDelimiter) => {
  const { url, color } = { ...delimiter };

  const prefix = '--details-block-delimiter';

  setCssVar(`${prefix}-url`, url);
  setCssVar(`${prefix}-color`, color);
};

const setDetailsBlockCommonVars = (common: ConfigDetailsBlockCommon) => {
  const { markBackground, codeBackground } = { ...common };

  const prefix = '--details-block-common';

  setCssVar(`${prefix}-mark-background-color`, markBackground);
  setCssVar(`${prefix}-code-background-color`, codeBackground);
};

const setDetailsBlockEmbedLinkVars = (embedLink: ConfigDetailsBlockEmbedLink) => {
  const { urlColor, iconColor, textColor, sourceColor, titleColor, borderColor, borderHoverColor } =
    { ...embedLink };

  const prefix = '--details-block-embed';

  setCssVar(`${prefix}-url-color`, urlColor);
  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-source-color`, sourceColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-border-color`, borderColor);
  setCssVar(`${prefix}-border-hover-color`, borderHoverColor);
};

const setDetailsBlockListVars = (list: ConfigDetailsBlockList) => {
  const { textColor } = { ...list };

  const prefix = '--details-block-list';

  setCssVar(`${prefix}-text-color`, textColor);
};

const setDetailsBlockQuoteVars = (quote: ConfigDetailsBlockQuote) => {
  const { iconColor, textColor, captionColor } = { ...quote };

  const prefix = '--details-block-quote';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-caption-color`, captionColor);
};

const setDetailsBlockTableVars = (table: ConfigDetailsBlockTable) => {
  const { headerTextColor, headerBackgroundColor } = { ...table };

  const prefix = '--details-block-table';

  setCssVar(`${prefix}-header-text-color`, headerTextColor);
  setCssVar(`${prefix}-header-background-color`, headerBackgroundColor);
};

const setDetailsBlockVars = (detailsBlock: ConfigDetailsBlock) => {
  const {
    common,
    header,
    paragraph,
    audio,
    video,
    callout,
    checkList,
    delimiter,
    embedLink,
    list,
    quote,
    table,
  } = detailsBlock;

  setDetailsBlockCommonVars(common);
  setDetailsBlockHeaderVars(header);
  setDetailsBlockParagraphVars(paragraph);
  setDetailsBlockAudioVars(audio);
  setDetailsBlockVideoVars(video);
  setDetailsBlockCalloutVars(callout);
  setDetailsBlockCheckListVars(checkList);
  setDetailsBlockDelimiterVars(delimiter);
  setDetailsBlockEmbedLinkVars(embedLink);
  setDetailsBlockListVars(list);
  setDetailsBlockQuoteVars(quote);
  setDetailsBlockTableVars(table);
};

const setSearchVars = (search: ConfigSearch) => {
  const { titleColor, countColor, queryColor, textColor } = { ...search };

  const prefix = '--search';

  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-count-color`, countColor);
  setCssVar(`${prefix}-query-color`, queryColor);
};

const setMediaAudioVars = (audio: ConfigMediaAudio) => {
  const { iconColor, timeColor, backgroundColor, indicatorColor, progressColor, captionColor } = {
    ...audio,
  };

  const prefix = '--media-audio';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-time-color`, timeColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-indicator-color`, indicatorColor);
  setCssVar(`${prefix}-progress-color`, progressColor);
  setCssVar(`${prefix}-caption-color`, captionColor);
};

const setMediaVideoVars = (video: ConfigMediaVideo) => {
  const { captionColor } = { ...video };

  const prefix = '--media-video';

  setCssVar(`${prefix}-caption-color`, captionColor);
};

const setMediaFileVars = (file: ConfigMediaFile) => {
  const { typeColor, nameColor, iconColor, backgroundColor, captionColor } = { ...file };

  const prefix = '--media-file';

  setCssVar(`${prefix}-type-color`, typeColor);
  setCssVar(`${prefix}-name-color`, nameColor);
  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-caption-color`, captionColor);
};

const setMediaVars = (media: ConfigMedia) => {
  const { audio, video, file } = { ...media };

  setMediaAudioVars(audio);
  setMediaVideoVars(video);
  setMediaFileVars(file);
};

const setAuthorInfoVars = (authorInfo: ConfigAuthorInfo) => {
  const { titleColor, subTitleColor } = { ...authorInfo };

  const prefix = '--author-info';

  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-subtitle-color`, subTitleColor);
};

const setCommentsItemVars = (item: ConfigCommentsItem) => {
  const {
    like: {
      color: likeColor,
      filledColor: likeFilledColor,
      hoverColor: likeHoverColor,
      counterColor: likeCounterColor,
    },
    highlight: {
      color: highlightColor,
      filledColor: highlightFilledColor,
      hoverColor: highlightHoverColor,
    },
    hide: { color: hideColor, filledColor: hideFilledColor, hoverColor: hideHoverColor },
    authorColor,
    publishedColor,
    editedColor,
    textColor,
    confirmationTextColor,
  } = { ...item };

  const prefix = '--comments-item';

  setCssVar(`${prefix}-like-color`, likeColor);
  setCssVar(`${prefix}-like-filled-color`, likeFilledColor);
  setCssVar(`${prefix}-like-hover-color`, likeHoverColor);
  setCssVar(`${prefix}-like-counter-color`, likeCounterColor);
  setCssVar(`${prefix}-highlight-color`, highlightColor);
  setCssVar(`${prefix}-highlight-filled-color`, highlightFilledColor);
  setCssVar(`${prefix}-highlight-hover-color`, highlightHoverColor);
  setCssVar(`${prefix}-hide-color`, hideColor);
  setCssVar(`${prefix}-hide-filled-color`, hideFilledColor);
  setCssVar(`${prefix}-hide-hover-color`, hideHoverColor);
  setCssVar(`${prefix}-author-color`, authorColor);
  setCssVar(`${prefix}-published-color`, publishedColor);
  setCssVar(`${prefix}-edited-color`, editedColor);
  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-confirmation-text-color`, confirmationTextColor);
};

const setCommentsInputVars = (input: ConfigCommentsInput) => {
  const {
    iconColor,
    iconHoverColor,
    textColor,
    placeholderColor,
    backgroundColor,
    borderFocusColor,
  } = { ...input };

  const prefix = '--comments-input';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-icon-hover-color`, iconHoverColor);
  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-placeholder-color`, placeholderColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-border-focus-color`, borderFocusColor);
};

const setCommentsVars = (comments: ConfigComments) => {
  const { item, input } = { ...comments };

  setCommentsItemVars(item);
  setCommentsInputVars(input);
};

const setFormVars = (form: ConfigForm) => {
  const {
    backgroundColor,
    outerBackgroundColor,
    borderColor,
    errorColor,
    titleColor,
    subTitleColor,
  } = { ...form };

  const prefix = '--form';

  setCssVar(`${prefix}-border-color`, borderColor);
  setCssVar(`${prefix}-error-color`, errorColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-subtitle-color`, subTitleColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-outer-background-color`, outerBackgroundColor);
};

const setActionPaneVars = (actionPane: ConfigActionPane) => {
  const { iconColor, iconHoverColor } = { ...actionPane };

  const prefix = '--action-pane';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-icon-hover-color`, iconHoverColor);
};

const setCardThreadVars = (thread: ConfigThreadCard) => {
  const { backgroundColor, discussionColor, titleColor } = { ...thread };

  const prefix = '--card-thread';

  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-discussion-color`, discussionColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setCardSocialVars = (social: ConfigSocialCard) => {
  const { borderColor, iconColor, textColor } = { ...social };

  const prefix = '--card-social';

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-border-color`, borderColor);
};

const setCardContent = (content: ConfigCardContent) => {
  const { sourceColor, headlineColor, headlineIconColor, subHeadlineColor, textColor } = {
    ...content,
  };

  const prefix = '--card-content';

  setCssVar(`${prefix}-source-color`, sourceColor);
  setCssVar(`${prefix}-headline-color`, headlineColor);
  setCssVar(`${prefix}-headline-icon-color`, headlineIconColor);
  setCssVar(`${prefix}-sub-headline-color`, subHeadlineColor);
  setCssVar(`${prefix}-text-color`, textColor);
};

const setCardTeaserVars = (teaser: ConfigCardTeaser) => {
  const { iconColor, backgroundColor, textColor } = { ...teaser };

  const prefix = '--card-teaser';

  setCssVar(`${prefix}-icon-color`, textColor);
  setCssVar(`${prefix}-text-color`, iconColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setCardVars = (card: ConfigCard) => {
  const {
    content,
    backgroundColor,
    borderColor,
    postedTimeColor,
    thread,
    social,
    overlayColor,
    overlayIconColor,
    commentColor,
    byAuthorColor,
    teaser,
  } = { ...card };

  const prefix = '--card';

  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-border-color`, borderColor);
  setCssVar(`${prefix}-posted-time-color`, postedTimeColor);
  setCssVar(`${prefix}-overlay-color`, overlayColor);
  setCssVar(`${prefix}-overlay-icon-color`, overlayIconColor);
  setCssVar(`${prefix}-comment-color`, commentColor);
  setCssVar(`${prefix}-by-author-color`, byAuthorColor);

  setCardContent(content);
  setCardThreadVars(thread);
  setCardSocialVars(social);
  setCardTeaserVars(teaser);
};

const setExternalTagsListVars = (externalTagsList: ConfigExternalTagsList) => {
  const { backgroundColor } = { ...externalTagsList };

  const prefix = '--external-tags-list';

  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setLatestsStoryCardsVars = (latestsStoryCards: ConfigLatestsStoryCards) => {
  const { backgroundColor, backgroundHoverColor, textColor } = { ...latestsStoryCards };

  const prefix = '--latest-story-cards';

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-background-hover-color`, backgroundHoverColor);
};

const setFooterVars = (footer: ConfigFooter) => {
  const { backgroundColor, dividerColor, copyTextColor } = { ...footer };

  const prefix = '--footer';

  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-divider-color`, dividerColor);
  setCssVar(`${prefix}-copy-text-color`, copyTextColor);
};

const setNavMenuLinkVars = (link: ConfigNavMenuLink) => {
  const { textColor, textHoverColor, selectedTextColor, selectedBackgroundColor } = { ...link };

  const prefix = '--nav-menu-link';

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-text-hover-color`, textHoverColor);
  setCssVar(`${prefix}-selected-text-color`, selectedTextColor);
  setCssVar(`${prefix}-selected-background-color`, selectedBackgroundColor);
};

const setNavMenuVars = (sideMenu: ConfigNavMenu) => {
  const { backgroundColor, link } = { ...sideMenu };

  const prefix = '--nav-menu';

  setCssVar(`${prefix}-background-color`, backgroundColor);

  setNavMenuLinkVars(link);
};

const setSettingsVars = (settings: ConfigSettings) => {
  const { iconColor, iconHoverColor } = { ...settings };

  const prefix = '--settings';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-icon-hover-color`, iconHoverColor);
};

const setSearchInputVars = (searchInput: ConfigSearchInput) => {
  const { closeColor } = { ...searchInput };

  const prefix = '--search-input';

  setInputVars(searchInput, prefix);

  setCssVar(`${prefix}-close-color`, closeColor);
};

const setBaseLinkVars = (baseLink: ConfigBaseLink) => {
  const { textColor, textHoverColor } = { ...baseLink };

  const prefix = '--base-link';

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-text-hover-color`, textHoverColor);
};

const setNotificationsVars = (notifications: ConfigNotifications) => {
  const {
    iconColor,
    iconHoverColor,
    iconIndicatorColor,
    backgroundColor,
    backgroundHoverColor,
    borderColor,
    titleColor,
    commentLineColor,
    primaryTextColor,
    secondaryTextColor,
  } = { ...notifications };

  const prefix = '--notifications';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-icon-hover-color`, iconHoverColor);
  setCssVar(`${prefix}-icon-indicator-color`, iconIndicatorColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-background-hover-color`, backgroundHoverColor);
  setCssVar(`${prefix}-border-color`, borderColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-comment-line-color`, commentLineColor);
  setCssVar(`${prefix}-primary-text-color`, primaryTextColor);
  setCssVar(`${prefix}-secondary-text-color`, secondaryTextColor);
};

const setTabsVars = (tabs: ConfigTabs) => {
  const { labelColor, activeColor, backgroundColor, borderColor } = { ...tabs };

  const prefix = '--tabs';

  setCssVar(`${prefix}-label-color`, labelColor);
  setCssVar(`${prefix}-active-color`, activeColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-border-color`, borderColor);
};

const setCreateCardThreadVars = (thread: ConfigCreateCardThread) => {
  const { backgroundColor, iconColor, titleColor, textColor, inputColor } = { ...thread };

  const prefix = '--create-card-thread';

  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-input-color`, inputColor);
};

const setCreateCardSettingsVars = (settings: ConfigCreateCardSettings) => {
  const { iconColor, iconHoverColor, titleColor, itemTitleColor, itemTextColor, itemActiveColor } =
    { ...settings };

  const prefix = '--create-card-settings';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-icon-hover-color`, iconHoverColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-item-title-color`, itemTitleColor);
  setCssVar(`${prefix}-item-text-color`, itemTextColor);
  setCssVar(`${prefix}-item-active-color`, itemActiveColor);
};

const setCreateCardTeaserVars = (teaser: ConfigCreateCardTeaser) => {
  const { titleColor, itemTextColor, itemActiveColor, backgroundActiveColor } = { ...teaser };

  const prefix = '--create-card-teaser';

  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-item-text-color`, itemTextColor);
  setCssVar(`${prefix}-item-active-color`, itemActiveColor);
  setCssVar(`${prefix}-background-active-color`, backgroundActiveColor);
};

const setCreateCardVars = (createCard: ConfigCreateCard) => {
  const {
    titleColor,
    postInColor,
    captionTitleColor,
    captionInputColor,
    linkColor,
    linkHoverColor,
    filePreviewBackgroundColor,
    thread,
    teaser,
    settings,
  } = { ...createCard };

  const prefix = '--create-card';

  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-post-in-color`, postInColor);
  setCssVar(`${prefix}-caption-title-color`, captionTitleColor);
  setCssVar(`${prefix}-caption-input-color`, captionInputColor);
  setCssVar(`${prefix}-link-color`, linkColor);
  setCssVar(`${prefix}-link-hover-color`, linkHoverColor);
  setCssVar(`${prefix}-file-preview-background-color`, filePreviewBackgroundColor);

  setCreateCardThreadVars(thread);
  setCreateCardSettingsVars(settings);
  setCreateCardTeaserVars(teaser);
};

const setCircularLoaderVars = (circularLoader: ConfigCircularLoader) => {
  const { backgroundColor, fillColor } = { ...circularLoader };

  const prefix = '--circular-loader';

  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-fill-color`, fillColor);
};

const setSpecialTeaserVars = (specialTeaser: ConfigSpecialTeaser) => {
  const { backgroundColor, sourceColor, titleColor, timeColor } = { ...specialTeaser };

  const prefix = '--special-teaser';

  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-source-color`, sourceColor);
  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-time-color`, timeColor);
};

const setFilterVars = (filter: ConfigFilter) => {
  const {
    titleColor,
    itemColor,
    activeColor,
    activeIconColor,
    searchIconColor,
    badgeBackgroundColor,
    badgeTextColor,
  } = { ...filter };

  const prefix = '--filter';

  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-item-color`, itemColor);
  setCssVar(`${prefix}-active-color`, activeColor);
  setCssVar(`${prefix}-active-icon-color`, activeIconColor);
  setCssVar(`${prefix}-search-icon-color`, searchIconColor);
  setCssVar(`${prefix}-badge-background-color`, badgeBackgroundColor);
  setCssVar(`${prefix}-badge-text-color`, badgeTextColor);
};

const setUserDirectoryVars = (userDirectory: ConfigUserDirectory) => {
  const {
    titleColor,
    emptyMessageColor,
    nameColor,
    positionColor,
    itemColor,
    itemHoverColor,
    linkColor,
    linkHoverColor,
  } = { ...userDirectory };

  const prefix = '--user-directory';

  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-empty-message-color`, emptyMessageColor);
  setCssVar(`${prefix}-name-color`, nameColor);
  setCssVar(`${prefix}-position-color`, positionColor);
  setCssVar(`${prefix}-item-color`, itemColor);
  setCssVar(`${prefix}-item-hover-color`, itemHoverColor);
  setCssVar(`${prefix}-link-color`, linkColor);
  setCssVar(`${prefix}-link-hover-color`, linkHoverColor);
};

const setLoginVars = (login: ConfigLogin) => {
  const prefix = '--login';

  const { signUpTextColor, dividerTextColor, dividerBorderColor } = { ...login };

  setCssVar(`${prefix}-sign-up-text-color`, signUpTextColor);
  setCssVar(`${prefix}-divider-text-color`, dividerTextColor);
  setCssVar(`${prefix}-divider-border-color`, dividerBorderColor);
};

const setSignupVars = (signup: ConfigSignup) => {
  const prefix = '--signup';

  const { textColor } = { ...signup };

  setCssVar(`${prefix}-text-color`, textColor);
};

const setFrontDoorLinksVars = (frontDoorLinks: ConfigFrontDoorLinks) => {
  const prefix = '--front-door-links';

  const {
    titleColor,
    itemTitleColor,
    itemTitleHoverColor,
    itemSubTitleColor,
    itemSubTitleHoverColor,
    itemIconColor,
    itemBorderColor,
    itemBackgroundColor,
  } = { ...frontDoorLinks };

  setCssVar(`${prefix}-title-color`, titleColor);
  setCssVar(`${prefix}-item-title-color`, itemTitleColor);
  setCssVar(`${prefix}-item-title-hover-color`, itemTitleHoverColor);
  setCssVar(`${prefix}-item-subtitle-color`, itemSubTitleColor);
  setCssVar(`${prefix}-item-subtitle-hover-color`, itemSubTitleHoverColor);
  setCssVar(`${prefix}-item-icon-color`, itemIconColor);
  setCssVar(`${prefix}-item-border-color`, itemBorderColor);
  setCssVar(`${prefix}-item-background-color`, itemBackgroundColor);
};

const setPublishToggleVars = (publishToggle: ConfigPublishToggle) => {
  const prefix = '--publish-toggle';

  const { textColor, textHoverColor } = { ...publishToggle };

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-text-hover-color`, textHoverColor);
};

const setCollapseSwitcherVars = (collapseSwitcher: ConfigCollapseSwitcher) => {
  const prefix = '--collapse-switcher';

  const { activeColor, backgroundActiveColor } = { ...collapseSwitcher };

  setCssVar(`${prefix}-active-color`, activeColor);
  setCssVar(`${prefix}-background-active-color`, backgroundActiveColor);
};

const setIconLabelWithActiveVars = (iconLabelWithActive: ConfigIconLabelWithActive) => {
  const prefix = '--icon-label-with-active';

  const { textColor, textActiveColor, backgroundActiveColor } = { ...iconLabelWithActive };

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-text-active-color`, textActiveColor);
  setCssVar(`${prefix}-background-active-color`, backgroundActiveColor);
};

const setCheckVars = (check: ConfigCheck) => {
  const prefix = '--check';

  const { backgroundColor } = { ...check };

  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setRadioVars = (radio: ConfigRadio) => {
  const prefix = '--radio';

  const { backgroundColor } = { ...radio };

  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setScheduleIconVars = (scheduleIcon: ConfigScheduleIcon) => {
  const prefix = '--schedule-icon';

  const { iconColor, iconHoverColor } = { ...scheduleIcon };

  setCssVar(`${prefix}-color`, iconColor);
  setCssVar(`${prefix}-hover-color`, iconHoverColor);
};

const setAdminBadgeVars = (adminBadge: ConfigAdminBadge) => {
  const prefix = '--admin-badge';

  const { textColor, backgroundColor } = { ...adminBadge };

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setMultiStepFilterVars = (multiStepFilter: ConfigMultiStepFilter) => {
  const prefix = '--multi-step-filter';

  const { textColor, textHoverColor } = { ...multiStepFilter };

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-text-hover-color`, textHoverColor);
};

const setPostedInVars = (postedIn: ConfigPostedIn) => {
  const prefix = '--posted-in';

  const { color } = { ...postedIn };

  setCssVar(`${prefix}-color`, color);
};

const setAnalyticsChartsTableVars = (table: ConfigAnalyticsChartsTable) => {
  const prefix = '--analytics-charts-table';

  const { headerBackgroundColor, headerTextColor } = { ...table };

  setCssVar(`${prefix}-header-background-color`, headerBackgroundColor);
  setCssVar(`${prefix}-header-text-color`, headerTextColor);
};

const setAnalyticsChartsVars = (charts: ConfigAnalyticsCharts) => {
  const { table } = { ...charts };

  setAnalyticsChartsTableVars(table);
};

const setAnalyticsVars = (analytics: ConfigAnalytics) => {
  const { charts } = { ...analytics };

  setAnalyticsChartsVars(charts);
};

const setChatsMessageVars = (message: ConfigChatsMessage) => {
  const { textColor, backgroundColor, dateColor } = { ...message };

  const prefix = '--chats-message';

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-date-color`, dateColor);
};

const setChatsMessageOtherVars = (message: ConfigChatsMessage) => {
  const { textColor, backgroundColor, dateColor } = { ...message };

  const prefix = '--chats-message-other';

  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
  setCssVar(`${prefix}-date-color`, dateColor);
};

const setChatsInputVars = (input: ConfigChatsInput) => {
  const {
    iconColor,
    iconHoverColor,
    sendIconColor,
    sendIconHoverColor,
    textColor,
    placeholderColor,
    backgroundColor,
  } = { ...input };

  const prefix = '--chats-input';

  setCssVar(`${prefix}-icon-color`, iconColor);
  setCssVar(`${prefix}-icon-hover-color`, iconHoverColor);
  setCssVar(`${prefix}-send-icon-color`, sendIconColor);
  setCssVar(`${prefix}-send-icon-hover-color`, sendIconHoverColor);
  setCssVar(`${prefix}-text-color`, textColor);
  setCssVar(`${prefix}-placeholder-color`, placeholderColor);
  setCssVar(`${prefix}-background-color`, backgroundColor);
};

const setChatsVars = (chats: ConfigChats) => {
  const { message, messageOther, input } = { ...chats };

  setChatsMessageVars(message);
  setChatsMessageOtherVars(messageOther);
  setChatsInputVars(input);
};

export const setConfigCSSVariables = (config: Config) => {
  const { elements } = { ...config };

  const {
    bodyBackgroundColor,
    baseLink,
    switchColor,
    card,
    avatarBackgroundColor,
    buttons,
    input,
    select,
    dropdownMenu,
    header,
    searchInput,
    settings,
    navMenu,
    footer,
    externalTagsList,
    latestsStoryCards,
    noContent,
    errorLayout,
    modal,
    storyTab,
    storyFeed,
    horizontalFeed,
    profile,
    detailsBlock,
    search,
    media,
    authorInfo,
    comments,
    form,
    actionPane,
    notifications,
    tabs,
    addContent,
    circularLoader,
    specialTeaser,
    filter,
    userDirectory,
    login,
    signup,
    frontDoorLinks,
    publishToggle,
    collapseSwitcher,
    iconLabelWithActive,
    check,
    radio,
    scheduleIcon,
    adminBadge,
    multiStepFilter,
    postedIn,
    analytics,
    chats,
  } = { ...elements };

  setCssVar('--body-background-color', bodyBackgroundColor);
  setCssVar('--avatar-background-color', avatarBackgroundColor);
  setCssVar('--switch-color', switchColor);

  setBaseLinkVars(baseLink);
  setCardVars(card);
  setButtonsVars(buttons);
  setInputVars(input);
  setSelectVars(select);
  setDropdownMenuVars(dropdownMenu);
  setHeaderVars(header);
  setSearchInputVars(searchInput);
  setSettingsVars(settings);
  setNavMenuVars(navMenu);
  setFooterVars(footer);
  setExternalTagsListVars(externalTagsList);
  setLatestsStoryCardsVars(latestsStoryCards);
  setNoContentVars(noContent);
  setErrorLayoutVars(errorLayout);
  setModalVars(modal);
  setStoryTabVars(storyTab);
  setStoryFeedVars(storyFeed);
  setHorizontalFeedVars(horizontalFeed);
  setProfileVars(profile);
  setDetailsBlockVars(detailsBlock);
  setSearchVars(search);
  setMediaVars(media);
  setAuthorInfoVars(authorInfo);
  setCommentsVars(comments);
  setFormVars(form);
  setActionPaneVars(actionPane);
  setNotificationsVars(notifications);
  setTabsVars(tabs);
  setCreateCardVars(addContent);
  setCircularLoaderVars(circularLoader);
  setSpecialTeaserVars(specialTeaser);
  setFilterVars(filter);
  setUserDirectoryVars(userDirectory);
  setLoginVars(login);
  setSignupVars(signup);
  setFrontDoorLinksVars(frontDoorLinks);
  setPublishToggleVars(publishToggle);
  setCollapseSwitcherVars(collapseSwitcher);
  setIconLabelWithActiveVars(iconLabelWithActive);
  setCheckVars(check);
  setRadioVars(radio);
  setScheduleIconVars(scheduleIcon);
  setAdminBadgeVars(adminBadge);
  setMultiStepFilterVars(multiStepFilter);
  setPostedInVars(postedIn);
  setAnalyticsVars(analytics);
  setChatsVars(chats);
};
