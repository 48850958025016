import { graphqlApi } from './graphqlApi';

interface SocialLoginConnection {
  clientId: string;
  type: string;
}

interface SocialLoginConnectionsResponse {
  data?: {
    socialLoginConnections: SocialLoginConnection[];
  };
}

export const socialLoginConnectionsApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    socialLoginConnections: builder.query<SocialLoginConnection[], void>({
      query: () => ({
        url: '/graphql/webapp?socialLoginConnections',
        method: 'POST',
        body: {
          query: `query SocialLoginConnections {
            socialLoginConnections {
              clientId
              type
            }
          }`,
        },
      }),
      transformResponse: (response: SocialLoginConnectionsResponse) =>
        response.data?.socialLoginConnections ?? [],
    }),
  }),
});

export const { useSocialLoginConnectionsQuery } = socialLoginConnectionsApi;
