import { Story, StoryCard, User } from '../services';
import { File, ImageFile } from '../shared';
import { graphqlApi } from './graphqlApi';
import { commentQuery } from './helpers';

export interface CommentsReactions {
  count: number;
  name: string;
}

interface CommentPermissions {
  allowToEditContent: boolean;
  allowToDelete: boolean;
  allowToHide: boolean;
  allowToHighlight: boolean;
}

export interface CommentAttachment {
  image?: ImageFile;
  imageId?: number;
  video?: File;
  videoId?: number;
}

export interface Comment {
  attachments: CommentAttachment[];
  author: User;
  authorId: number;
  content: string;
  contentUpdatedAt: string;
  createdAt: string;
  id: number;
  isHidden: boolean;
  isHighlighted: boolean;
  myReaction: string;
  parentId: number;
  reactions: CommentsReactions[];
  replies: Comment[];
  repliesCount: number;
  story: Story;
  storyCard: StoryCard;
  storyCardId: number;
  storyId: number;
  permissions: CommentPermissions;
}

export interface CommentDeleteArgs {
  storyId: number;
  cardId: number;
  commentId: number;
  parentId?: number;
}

export interface CommentDeleteResponse {
  data: {
    commentDelete: {
      payload: boolean;
    };
  };
}

export interface CommentContentUpdateArgs {
  storyId: number;
  commentId: number;
  content: string;
  attachments: CommentAttachmentInput[];
}

export interface CommentContentUpdateResponse {
  data: {
    commentContentUpdate: {
      payload: Comment;
    };
  };
}

export interface CommentAttachmentInput {
  imageId?: number;
  videoId?: number;
}

export interface CommentPostInStoryCardArgs {
  storyId: number;
  itemId: number;
  content: string;
  attachments: CommentAttachmentInput[];
  parentId?: number;
}

interface CommentPostInStoryCardResponse {
  data: {
    commentPostInStoryCard: {
      payload: Comment;
    };
  };
}

export interface CommentReactionArgs {
  storyId: number;
  cardId: number;
  commentId: number;
}

interface CommentReactionResponse {
  data: {
    commentReaction: {
      payload: CommentsReactions[];
    };
  };
}

export interface CommentHideArgs {
  storyId: number;
  commentId: number;
}

interface CommentHideResponse {
  data: {
    commentHide: {
      payload: Comment;
    };
  };
}

export interface CommentHighlightArgs {
  storyId: number;
  commentId: number;
}

interface CommentHighlightResponse {
  data: {
    commentHighlight: {
      payload: Comment;
    };
  };
}

export const commentApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    commentContentUpdate: builder.query<Comment, CommentContentUpdateArgs>({
      query: ({ storyId, commentId, content, attachments }) => ({
        url: '/graphql/webapp?commentContentUpdate',
        method: 'POST',
        body: {
          query: `mutation CommentContentUpdate($input: CommentContentUpdateInput!) {
            commentContentUpdate(input: $input) {
              payload {
                id
                content
                attachments {
                  imageId
                  image {
                    id
                    originalFilename
                    url
                    averageColor
                    rightholder
                  }
                  videoId
                  video {
                    id
                    originalFilename
                    thumb
                    url
                    status
                  }
                }
                storyCardId
                parentId
                contentUpdatedAt
              }
              error {
                ... on CommentUpdateIsForbiddenError  {
                  message
                }
                ... on StoryCardCommentNotFoundError  {
                  message
                }
                ... on UnknownError  {
                  message
                  kind
                }
              }
            }
          }`,
          variables: {
            input: {
              storyId,
              commentId,
              content,
              attachments,
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: CommentContentUpdateResponse) =>
        response.data.commentContentUpdate.payload,
    }),
    commentDelete: builder.query<boolean, CommentDeleteArgs>({
      query: ({ storyId, commentId }) => ({
        url: '/graphql/webapp?commentDelete',
        method: 'POST',
        body: {
          query: `mutation CommentDelete($input: CommentDeleteInput!) {
            commentDelete(input: $input) {
              payload
              error {
                ... on CommentUpdateIsForbiddenError {
                  message
                }
                ... on StoryCardCommentNotFoundError {
                  message
                }
                ... on UnknownError {
                  message
                  kind
                }
              }
            }
          }`,
          variables: { input: { storyId, commentId } },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: CommentDeleteResponse) => response.data.commentDelete.payload,
    }),
    commentPostInStoryCard: builder.query<Comment, CommentPostInStoryCardArgs>({
      query: ({ storyId, itemId, content, parentId, attachments }) => ({
        url: '/graphql/webapp?commentPostInStoryCard',
        method: 'POST',
        body: {
          query: `mutation CommentPostInStoryCard($input: CommentPostInStoryCardInput!) {
            commentPostInStoryCard(input: $input) {
              payload {
                ${commentQuery()}
              }
              error {
                ... on UnknownError {
                  message
                  kind
                }
              }
            }
          }`,
          variables: {
            input: {
              storyId,
              itemId,
              content,
              attachments,
              ...(Boolean(parentId) && { parentId }),
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: CommentPostInStoryCardResponse) =>
        response.data.commentPostInStoryCard?.payload,
    }),
    commentReaction: builder.query<CommentsReactions[], CommentReactionArgs>({
      query: ({ storyId, commentId }) => ({
        url: '/graphql/webapp?commentReaction',
        method: 'POST',
        body: {
          query: `mutation CommentReaction($input: CommentReactionInput!) {
            commentReaction(input: $input) {
              payload {
                count
                name
              }
              error {
                ... on UnknownError {
                  message
                  kind
                }
              }
            }
          }`,
          variables: {
            input: { storyId, commentId, reaction: 'like' },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: CommentReactionResponse) =>
        response.data.commentReaction.payload,
    }),
    commentHide: builder.query<Comment, CommentHideArgs>({
      query: ({ storyId, commentId }) => ({
        url: '/graphql/webapp?commentHide',
        method: 'POST',
        body: {
          query: `mutation CommentHide($input: CommentHideInput!) {
            commentHide(input: $input) {
                payload {
                  ${commentQuery()}
                }
                error {
                  ... on UnknownError {
                    message
                    kind
                  }
                }
              }
          }`,
          variables: {
            input: { storyId, commentId },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: CommentHideResponse) => response.data.commentHide.payload,
    }),
    commentHighlight: builder.query<Comment, CommentHighlightArgs>({
      query: ({ storyId, commentId }) => ({
        url: '/graphql/webapp?commentHighlight',
        method: 'POST',
        body: {
          query: `mutation CommentHighlight($input: CommentHighlightInput!) {
            commentHighlight(input: $input) {
                payload {
                  ${commentQuery()}
                }
                error {
                  ... on UnknownError {
                    message
                    kind
                  }
                }
              }
          }`,
          variables: {
            input: { storyId, commentId },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: CommentHighlightResponse) =>
        response.data.commentHighlight.payload,
    }),
  }),
});

export const { useCommentContentUpdateQuery } = commentApi;
