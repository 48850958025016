import {
  Fragment,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigContext, OrganisationContext, UserContext } from '../../../context';
import {
  ConfigProfileFields,
  OrganisationTagType,
  userApi,
  UserGender,
  UserSocialLinks,
} from '../../../services';
import {
  AvatarUpload,
  Button,
  ButtonType,
  getCssVar,
  IconLabel,
  ImageFile,
  InputField,
  isUrl,
  phoneValidationError,
  Select,
  TagsPicker,
} from '../../../shared';

import classes from './EditProfile.module.scss';

interface RenderProfileFieldProps {
  fieldName: keyof ConfigProfileFields;
  value: string;
  setter?: (value: SetStateAction<string>) => void;
  changeCallBack?: () => void;
  error?: boolean;
  errorMessage?: string;
  maxLength?: number;
}

export const EditProfile: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { userProfile, setUserProfile } = useContext(UserContext);

  const { tags } = useContext(OrganisationContext).organisation;

  const hasTags = useMemo(
    () => Boolean(tags.filter(({ type }) => type === OrganisationTagType.USER).length),
    [tags]
  );

  const {
    email: userEmail,
    screenName: userScreenName,
    gender: userGender,
    location: userLocation,
    bio: userBio,
    phone: userPhone,
    position: userPosition,
    department: userDepartment,
    subscription: userSubscription,
    avatar: userAvatar,
    url: userUrl,
    links: userLinks,
    organisationTags: userOrganisationTags,
  } = userProfile;

  const [updateProfile] = userApi.endpoints.userProfileUpdate.useLazyQuery();

  const { config } = useContext(ConfigContext);

  const { avatar, fields, socialFields } = config.elements.profile;

  const { size: avatarSize } = avatar;

  const [validationError, setValidationError] = useState({
    screenName: false,
    phone: false,
    facebook: false,
    instagram: false,
    twitter: false,
    youtube: false,
    snapchat: false,
    tiktok: false,
    linkedin: false,
    url: false,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [email] = useState<string>(userEmail ?? '');

  const [screenName, setScreenName] = useState<string>(userScreenName);

  const [gender, setGender] = useState<UserGender | null>(userGender);

  const [locationText, setLocationText] = useState<string>(userLocation.text);

  const [bio, setBio] = useState<string>(userBio);

  const [phone, setPhone] = useState<string>(userPhone ?? '');

  const [position, setPosition] = useState<string>(userPosition);

  const [department, setDepartment] = useState<string>(userDepartment);

  const [subscription, setSubscription] = useState<string>(userSubscription);

  const [url, setUrl] = useState<string>(userUrl);

  const [avatarImg, setAvatarImg] = useState<ImageFile | null>(userAvatar ?? null);

  const [links, setLinks] = useState<UserSocialLinks>(userLinks);

  const [userTags, setUserTags] = useState(userOrganisationTags);

  const goToProfile = useCallback(() => {
    navigate(pathname.replace('/edit', ''));
  }, [navigate, pathname]);

  const screenNameValidityCheckPassed = useCallback(() => {
    const { visible, editable } = fields['screenName'];

    if (!visible || !editable) {
      return true;
    }

    if (Boolean(screenName.trim().length < 2)) {
      setValidationError({ ...validationError, screenName: true });
      return false;
    }

    return true;
  }, [fields, screenName, validationError]);

  const socialLinksValidityCheckPassed = useCallback(() => {
    for (const [key, value] of Object.entries(links)) {
      if (Boolean(socialFields.includes(key) && value?.length && !isUrl(value))) {
        setValidationError({ ...validationError, [key]: true });
        return false;
      }
    }

    return true;
  }, [links, socialFields, validationError]);

  const phoneValidityCheckPassed = useCallback(() => {
    const { visible, editable } = fields['phone'];

    if (!phone || !visible || !editable) {
      return true;
    }

    if (phoneValidationError(phone)) {
      setValidationError({ ...validationError, phone: true });
      return false;
    }

    return true;
  }, [fields, phone, validationError]);

  const urlValidityCheckPassed = useCallback(() => {
    const { visible, editable } = fields['url'];

    if (!url || !visible || !editable) {
      return true;
    }

    if (!isUrl(url)) {
      setValidationError({ ...validationError, url: true });
      return false;
    }

    return true;
  }, [fields, url, validationError]);

  const validationPassed = useCallback(() => {
    return !Boolean(
      [
        screenNameValidityCheckPassed(),
        phoneValidityCheckPassed(),
        socialLinksValidityCheckPassed(),
        urlValidityCheckPassed(),
      ].some((validityCheckPassed) => !Boolean(validityCheckPassed))
    );
  }, [
    phoneValidityCheckPassed,
    screenNameValidityCheckPassed,
    socialLinksValidityCheckPassed,
    urlValidityCheckPassed,
  ]);

  const saveHandler = useCallback(async () => {
    const { id, email, avatar, roleId, isAnonymous, isStaff, organisationTags, ...infoToSave } =
      userProfile;

    if (!validationPassed()) {
      return;
    }

    setUserProfile(
      await updateProfile({
        fields: {
          ...infoToSave,
          screenName,
          gender,
          bio,
          phone,
          position,
          department,
          location: { text: locationText },
          subscription,
          organisationTagsId: userTags.map(({ id }) => id),
          url,
          links,
          imageId: avatarImg?.id ?? null,
        },
      }).unwrap()
    );

    goToProfile();
  }, [
    avatarImg?.id,
    bio,
    department,
    gender,
    goToProfile,
    links,
    locationText,
    phone,
    position,
    screenName,
    setUserProfile,
    subscription,
    updateProfile,
    url,
    userProfile,
    userTags,
    validationPassed,
  ]);

  const renderProfileInputField = useCallback(
    ({
      fieldName,
      value,
      setter,
      changeCallBack,
      error,
      errorMessage,
      maxLength,
    }: RenderProfileFieldProps) => {
      const { visible = false, editable = false, localiseKey } = { ...fields[fieldName] };

      if (!visible) {
        return null;
      }

      return (
        <InputField
          label={t(localiseKey ?? `common.${fieldName}`)}
          value={value}
          disabled={!editable}
          error={error}
          errorMessage={errorMessage}
          maxLength={maxLength}
          {...(setter && {
            onChange: ({ target }) => {
              setter(target.value);
              changeCallBack?.();
            },
          })}
        />
      );
    },
    [fields, t]
  );

  const renderGenderField = useMemo(() => {
    const { visible = false, editable = false, localiseKey } = { ...fields['gender'] };

    if (!visible) {
      return null;
    }

    const items = Object.values(UserGender).map(
      (value) => {
        return { id: value, title: t(`genderTypes.${value}`) };
      },
      [t]
    );

    const onChange = (gender: UserGender) => {
      setGender(gender === UserGender.NOT_SELECTED ? null : gender);
    };

    return (
      <Select
        label={t(localiseKey ?? 'common.gender')}
        selectedItemId={gender ?? UserGender.NOT_SELECTED}
        items={items}
        onChange={(gender) => onChange(gender as UserGender)}
        disabled={!editable}
      />
    );
  }, [fields, gender, t]);

  const renderUserTags = useMemo(() => {
    const { visible = false, editable = false, localiseKey } = { ...fields['organisationTags'] };

    if (!visible || !hasTags) {
      return null;
    }

    return (
      <TagsPicker
        tags={userTags}
        setTags={setUserTags}
        type={OrganisationTagType.USER}
        title={t(localiseKey ?? 'organisationTags.title-USER')}
        disabled={!editable}
        hideSubTitle
      />
    );
  }, [fields, hasTags, t, userTags]);

  const profileSocialLinkChange = useCallback(
    (linkType: keyof UserSocialLinks, value: string) => {
      setLinks({ ...links, [linkType]: value });
    },
    [links]
  );

  const renderProfileSocialLinks = useMemo(() => {
    const sortedLinkTypes: (keyof UserSocialLinks)[] = [
      'facebook',
      'instagram',
      'twitter',
      'youtube',
      'snapchat',
      'tiktok',
      'linkedin',
    ];

    return sortedLinkTypes.map((linkType: keyof UserSocialLinks) => {
      return (
        <Fragment key={linkType}>
          {socialFields.includes(linkType) && (
            <InputField
              label={t(`common.${linkType}`)}
              value={links[linkType] ?? ''}
              onChange={({ target }) => {
                profileSocialLinkChange(linkType, target.value);
                setValidationError({ ...validationError, [linkType]: false });
              }}
              error={validationError[linkType]}
              errorMessage={t('profile.social-link-invalid')}
              icon={
                <>
                  {!validationError[linkType] && (
                    <IconLabel
                      iconId={linkType}
                      iconSize={18}
                      color={getCssVar('--profile-social-color')}
                      singleColor
                      nonClickable
                    />
                  )}
                </>
              }
            />
          )}
        </Fragment>
      );
    });
  }, [links, profileSocialLinkChange, socialFields, t, validationError]);

  return (
    <div className={classes['edit-profile']}>
      <AvatarUpload
        avatar={avatarImg}
        setAvatar={setAvatarImg}
        avatarSize={avatarSize}
        loading={loading}
        setLoading={setLoading}
        title={t('profile.change-picture')}
        className={classes['edit-profile__avatar-upload']}
        wrapperClassName={classes['edit-profile__avatar-wrapper']}
      />

      <div className={classes['edit-profile__fields-wrapper']}>
        {renderProfileInputField({
          fieldName: 'screenName',
          value: screenName,
          setter: setScreenName,
          changeCallBack: () => setValidationError({ ...validationError, screenName: false }),
          maxLength: 100,
          error: validationError.screenName,
          errorMessage: t('profile.name-invalid'),
        })}

        {renderProfileInputField({ fieldName: 'email', value: email })}

        {renderGenderField}

        {renderUserTags}

        {renderProfileInputField({
          fieldName: 'phone',
          value: phone,
          setter: setPhone,
          changeCallBack: () => setValidationError({ ...validationError, phone: false }),
          error: validationError.phone,
          errorMessage: t('profile.phone-invalid'),
        })}

        {renderProfileInputField({ fieldName: 'bio', value: bio, setter: setBio, maxLength: 300 })}

        {renderProfileInputField({
          fieldName: 'position',
          value: position,
          setter: setPosition,
          maxLength: 250,
        })}

        {renderProfileInputField({
          fieldName: 'department',
          value: department,
          setter: setDepartment,
          maxLength: 250,
        })}

        {renderProfileInputField({
          fieldName: 'location',
          value: locationText,
          setter: setLocationText,
        })}

        {renderProfileInputField({
          fieldName: 'subscription',
          value: subscription,
          setter: setSubscription,
          maxLength: 250,
        })}

        {renderProfileInputField({
          fieldName: 'url',
          value: url,
          setter: setUrl,
          changeCallBack: () => setValidationError({ ...validationError, url: false }),
          error: validationError.url,
          errorMessage: t('profile.social-link-invalid'),
        })}

        {renderProfileSocialLinks}
      </div>

      <div className={classes['edit-profile__buttons-wrapper']}>
        <Button
          type={ButtonType.secondary}
          label={t('common.cancel')}
          disabled={loading}
          onClick={goToProfile}
        />

        <Button
          type={ButtonType.primary}
          label={t('profile.save-profile')}
          disabled={loading}
          onClick={saveHandler}
        />
      </div>
    </div>
  );
};
