import { FunctionComponent, memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { ChatContext } from '../../../../context';
import { Chat, ChatGroupMoreCandidatesData, chatsApi, graphqlChatsApi } from '../../../../services';
import {
  Avatar,
  Button,
  ButtonType,
  Check,
  IconLabel,
  InputField,
  layoutPath,
  useDebounce,
} from '../../../../shared';
import { ChatDetailsType } from '../ChatDetails';

import classes from './AddChatMembers.module.scss';

interface AddChatMembersProps {
  setDetailsType: (type: ChatDetailsType | null) => void;
}

export const AddChatMembers: FunctionComponent<AddChatMembersProps> = memo(({ setDetailsType }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { activeChat, setActiveChat } = { ...useContext(ChatContext) };

  const { chatId, usersCount } = activeChat as Chat;

  const [addMembers] = chatsApi.endpoints.addMembers.useMutation();

  const [chatGroupMoreCandidates] =
    graphqlChatsApi.endpoints.chatGroupMoreCandidates.useLazyQuery();

  const [candidatesData, setCandidatesData] = useState<ChatGroupMoreCandidatesData | null>(null);

  const [candidatesQuery, setCandidatesQuery] = useState<string>('');

  const candidatesQueryDebounced = useDebounce<string>(candidatesQuery);

  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const getCandidates = useCallback(
    async ({ page }: { page: number }) => {
      const data = await chatGroupMoreCandidates({
        chatId,
        page,
        query: candidatesQueryDebounced,
      }).unwrap();

      setCandidatesData((candidatesDataPrev) => {
        if (page === 1) {
          return data;
        }

        return { ...data, items: [...(candidatesDataPrev?.items ?? []), ...data.items] };
      });
    },
    [candidatesQueryDebounced, chatGroupMoreCandidates, chatId]
  );

  useEffect(() => {
    getCandidates({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCandidates({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidatesQueryDebounced]);

  const candidatesLoadMore = useCallback(() => {
    if (!candidatesData) {
      return;
    }
    getCandidates({ page: candidatesData.pageInfo.page + 1 });
  }, [candidatesData, getCandidates]);

  const onUserClick = useCallback(
    (id: number) => {
      navigate(layoutPath(`/profile/${id}`));
    },
    [navigate]
  );

  const onUserCheck = useCallback(
    (id: number) => {
      const index = selectedUserIds.findIndex((selectedUserId) => selectedUserId === id);

      if (index === -1) {
        setSelectedUserIds([...selectedUserIds, id]);
        return;
      }

      const selectedIdsCopy = [...selectedUserIds];

      selectedIdsCopy.splice(index, 1);

      setSelectedUserIds(selectedIdsCopy);
    },
    [selectedUserIds]
  );

  const addMembersHandler = useCallback(async () => {
    setLoading(true);

    await addMembers({ chatId, users: selectedUserIds });
    setSelectedUserIds([]);
    setCandidatesData(null);
    getCandidates({ page: 1 });
    setActiveChat({ ...(activeChat as Chat), usersCount: usersCount + selectedUserIds.length });

    setLoading(false);
  }, [activeChat, addMembers, chatId, getCandidates, selectedUserIds, setActiveChat, usersCount]);

  if (!candidatesData) {
    return (
      <div className={classes['add-members__loader']}>
        <Skeleton height={'3rem'} />
        <Skeleton height={'3rem'} />
        <Skeleton height={'3rem'} />
      </div>
    );
  }

  const {
    items,
    pageInfo: { hasNextPage },
  } = candidatesData;

  return (
    <div className={classes['add-members']}>
      <IconLabel
        iconId={'arrow-rounded-left'}
        iconSize={18}
        label={t('chatActions.add-members')}
        labelClassName={classes['add-members__title']}
        onClick={() => setDetailsType(ChatDetailsType.INFO)}
        singleColor
      />
      <InputField
        value={candidatesQuery}
        onChange={({ target }) => setCandidatesQuery(target.value)}
        placeholder={t('chats.search-users-placeholder')}
        {...(candidatesQuery && {
          icon: (
            <IconLabel
              iconId={'close'}
              iconSize={18}
              color={'#b3b3b3'}
              onClick={() => setCandidatesQuery('')}
              singleColor
            />
          ),
        })}
      />
      <InfiniteScroll
        loader={<Skeleton height={'3rem'} />}
        next={candidatesLoadMore}
        hasMore={hasNextPage}
        dataLength={items.length}
        className={classes['add-members__list']}
        height={'calc(100vh - 17.5rem)'}
      >
        {items.map(({ id, screenName, avatar }) => (
          <div key={id} className={classes['add-members__list-item']}>
            <Check checked={selectedUserIds.includes(id)} onChange={() => onUserCheck(id)} />
            <div
              className={classes['add-members__list-item-content']}
              onClick={() => onUserClick(id)}
            >
              <Avatar url={avatar?.url} />
              <div className={classes['add-members__list-item-content-name']}>{screenName}</div>
            </div>
          </div>
        ))}
        {!items.length && (
          <IconLabel
            iconSize={40}
            iconId={'search-off'}
            label={t('common.nothing-found')}
            className={classes['add-members__list-no-data']}
            labelClassName={classes['add-members__list-no-data-label']}
            singleColor
            nonClickable
          />
        )}
      </InfiniteScroll>
      <Button
        type={ButtonType.primary}
        label={`${t('chatActions.add-selected')} (${selectedUserIds.length})`}
        disabled={Boolean(loading || !selectedUserIds.length)}
        loading={loading}
        onClick={addMembersHandler}
        fullWidth
      />
    </div>
  );
});
