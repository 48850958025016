import { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { StoryCardCategoryTemplate } from '../../../../services';
import { CATEGORY_TEMPLATES_COLLAPSE_STORAGE_KEY } from '../../../constants';
import { Button, ButtonType } from '../../Button';
import { CollapseMode, CollapseSwitcher } from '../../CollapseSwitcher';
import { Modal } from '../../Modal';

import classNames from 'classnames';
import classes from './CategoryTemplateSelectionModal.module.scss';

interface CategoryTemplateSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  templates: StoryCardCategoryTemplate[];
  onTemplateSelectCallback: (template: StoryCardCategoryTemplate) => void;
}

export const CategoryTemplateSelectionModal: FunctionComponent<CategoryTemplateSelectionModalProps> =
  memo(({ isOpen, onClose, templates, onTemplateSelectCallback }) => {
    const { t } = useTranslation();

    const [collapseMode, setCollapseMode] = useState(
      localStorage.getItem(CATEGORY_TEMPLATES_COLLAPSE_STORAGE_KEY) || CollapseMode.COLLAPSED
    );

    useEffect(() => {
      window.addEventListener('storage', () => {
        setCollapseMode(
          localStorage.getItem(CATEGORY_TEMPLATES_COLLAPSE_STORAGE_KEY) || CollapseMode.COLLAPSED
        );
      });
    }, []);

    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState<number | null>(null);

    const isCollapsed = useMemo(() => collapseMode === CollapseMode.COLLAPSED, [collapseMode]);

    const templateContent = useCallback(
      ({ name, text, gallery }: StoryCardCategoryTemplate) => {
        const imageUrl = gallery[0].image.url;

        if (isCollapsed) {
          return (
            <>
              <div
                className={classes['templates__list-item-cover']}
                style={{ backgroundImage: `url(${imageUrl})` }}
              ></div>
              <div className={classes['templates__list-item-name']}>{name}</div>
            </>
          );
        }

        return (
          <>
            <img className={classes['templates__list-item-image']} src={imageUrl} alt={'preview'} />
            <div className={classes['templates__list-item-name']}>{name}</div>
            <div className={classes['templates__list-item-text']}>{text}</div>
          </>
        );
      },
      [isCollapsed]
    );

    const onTemplateSelect = useCallback(
      (index: number) => {
        setSelectedTemplateIndex(index !== selectedTemplateIndex ? index : null);
      },
      [selectedTemplateIndex]
    );

    const buttonClickHandler = useCallback(() => {
      if (selectedTemplateIndex === null) {
        return;
      }

      onTemplateSelectCallback(templates[selectedTemplateIndex]);
    }, [onTemplateSelectCallback, selectedTemplateIndex, templates]);

    const body = useMemo(() => {
      return (
        <div className={classes['templates']}>
          <div className={classes['templates__title']}>
            {t('storyCardCategory.templates-library')}
          </div>

          <CollapseSwitcher
            storageKey={CATEGORY_TEMPLATES_COLLAPSE_STORAGE_KEY}
            defaultMode={CollapseMode.COLLAPSED}
            className={classes['templates__switcher']}
          />

          <div
            className={classNames(classes['templates__list'], {
              [classes['templates__list--mobile']]: isMobileOnly,
            })}
          >
            {templates.map((template, index) => {
              return (
                <div
                  key={`template-${index}`}
                  className={classNames(classes['templates__list-item'], {
                    [classes['templates__list-item--selected']]: index === selectedTemplateIndex,
                    [classes['templates__list-item--collapsed']]: isCollapsed,
                  })}
                  onClick={() => onTemplateSelect(index)}
                >
                  {templateContent(template)}
                </div>
              );
            })}
          </div>

          <div className={classes['templates__buttons']}>
            <Button type={ButtonType.secondary} label={t('common.cancel')} onClick={onClose} />
            <Button
              type={ButtonType.primary}
              label={t('common.continue')}
              disabled={selectedTemplateIndex === null}
              onClick={buttonClickHandler}
            />
          </div>
        </div>
      );
    }, [
      buttonClickHandler,
      isCollapsed,
      onClose,
      onTemplateSelect,
      selectedTemplateIndex,
      t,
      templateContent,
      templates,
    ]);

    return <Modal isOpen={isOpen} body={body} onClose={onClose} alignTop keepOpened />;
  });
