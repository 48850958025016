/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoryCard, storyCardApi, StoryCardsUserProfileFeedArgs } from '../services';
import { deleteCard, editCard, togglePublishOnCard } from './common';
import { updateStoryCardPosition } from './reducers';

const getStoryCardsUserProfileFeedHandler = async (
  args: StoryCardsUserProfileFeedArgs,
  { dispatch }: any
) => {
  return dispatch(storyCardApi.endpoints.storyCardsUserProfileFeed.initiate({ ...args })).unwrap();
};

export const getStoryCardsUserProfileFeed = createAsyncThunk(
  'storyCardsUserProfileFeed',
  getStoryCardsUserProfileFeedHandler
);

export const loadMoreStoryCardsUserProfileFeed = createAsyncThunk(
  'storyCardsUserProfileFeed/loadMore',
  getStoryCardsUserProfileFeedHandler
);

export const createProfileFeedStoryCard = createAction<StoryCard>('createProfileFeedStoryCard');

interface StoriesCardsUserFeedState {
  storyCardsUserProfileFeed: StoryCard[];
  page: number;
  perPage: number;
  hasNextPage: boolean;
  isFetching: boolean;
}

const initialState: StoriesCardsUserFeedState = {
  storyCardsUserProfileFeed: [],
  page: 0,
  perPage: 0,
  hasNextPage: false,
  isFetching: false,
};

const storyCardsUserProfileFeedSlice = createSlice({
  name: 'storyCardsUserProfileFeed',
  initialState,
  reducers: {
    updateProfileFeedCardPosition: (
      state,
      action: PayloadAction<{ dragId: number; targetId: number }>
    ) => {
      const { dragId, targetId } = action.payload;

      updateStoryCardPosition({ dragId, targetId, feed: state.storyCardsUserProfileFeed });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoryCardsUserProfileFeed.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getStoryCardsUserProfileFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, perPage, hasNextPage } = pageInfo;

        state.storyCardsUserProfileFeed = items;
        state.page = page;
        state.perPage = perPage;
        state.hasNextPage = hasNextPage;
        state.isFetching = false;
      })
      .addCase(loadMoreStoryCardsUserProfileFeed.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.storyCardsUserProfileFeed.push(...items);
        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(createProfileFeedStoryCard, (state, action) => {
        state.storyCardsUserProfileFeed.unshift(action.payload);
      })
      .addCase(editCard, (state, action) => {
        const { id: storyCardId } = action.payload;

        const cardIndex = state.storyCardsUserProfileFeed.findIndex(({ id }) => id === storyCardId);

        if (cardIndex === -1) {
          return;
        }

        state.storyCardsUserProfileFeed[cardIndex] = action.payload;
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        const { deleted, storyCardId } = action.payload;

        if (!deleted) {
          return;
        }

        state.storyCardsUserProfileFeed = state.storyCardsUserProfileFeed.filter(
          ({ id }) => id !== storyCardId
        );
      })
      .addCase(togglePublishOnCard.fulfilled, (state, action) => {
        const { payload } = action.payload;

        if (!payload) {
          return;
        }

        const { id: storyCardId, status, postingTime } = payload;

        const card = state.storyCardsUserProfileFeed.find(({ id }) => id === storyCardId);

        if (!card) {
          return;
        }

        card.status = status;
        card.postingTime = postingTime;
      });
  },
});

export const { updateProfileFeedCardPosition } = storyCardsUserProfileFeedSlice.actions;
export default storyCardsUserProfileFeedSlice.reducer;
