import {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { ChatContext } from '../../../context';
import { Chat, chatsApi, ChatTypes } from '../../../services';
import {
  CHAT_LOAD_MESSAGES_LIMIT,
  dateToTimeToken,
  IconLabel,
  layoutPath,
  Tabs,
} from '../../../shared';
import { ChatTabsTypes } from '../Chats';
import { getChatCookieKey } from '../helpers';
import { ChatsListItem } from './ChatsListItem';
import { useMessageListener } from './useMessageListener';

import classNames from 'classnames';
import classes from './ChatsList.module.scss';

interface ChatsListProps {
  search: string;
}

const PUBLIC_TAB_CHATS_LIMIT = 20;

export const ChatsList: FunctionComponent<ChatsListProps> = memo(({ search }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [getPublicChats] = chatsApi.endpoints.getPublicChats.useLazyQuery();

  const {
    pubnub,
    chats,
    setActiveChat,
    selectedTabIndex,
    setSelectedTabIndex,
    channels,
    setChannels,
    setChannelsChanges,
    setChannelsUnreadCount,
  } = useContext(ChatContext);

  const [chatsSorted, setChatsSorted] = useState<Chat[]>([]);

  const [chatsFiltered, setChatsFiltered] = useState<Chat[]>([]);

  const [publicTabChatsCount, setPublicTabChatsCount] = useState<number>(0);

  const [isFetchingPublicTabChats, setIsFetchingPublicTabChats] = useState<boolean>(false);

  const publicTabChatsOffsetRef = useRef<number>(0);

  const chatDescriptors = useMemo(() => chats.map(({ chatDescriptor }) => chatDescriptor), [chats]);

  const chatsCookieKeys = chatDescriptors.map((chatDescriptor) => getChatCookieKey(chatDescriptor));

  const [cookies] = useCookies(chatsCookieKeys);

  const { messageListener } = useMessageListener(chatsCookieKeys);

  const isFetchingMessages = useRef<boolean>(true);

  const chatsCookies = Object.fromEntries(
    Object.entries(cookies).filter(([key]) => chatsCookieKeys.includes(key))
  );

  useEffect(() => {
    if (!channels) {
      return;
    }

    const sortedKeys = [...chatDescriptors];

    sortedKeys.sort((a, b) => {
      const timetokenA = channels[a]?.length
        ? channels[a][channels[a].length - 1]?.timetoken?.toString()
        : dateToTimeToken(
            new Date(chats.find(({ chatDescriptor }) => chatDescriptor === a)?.updated ?? '')
          );

      const timetokenB = channels[b]?.length
        ? channels[b][channels[b].length - 1]?.timetoken?.toString()
        : dateToTimeToken(
            new Date(chats.find(({ chatDescriptor }) => chatDescriptor === b)?.updated ?? '')
          );

      return parseInt(timetokenB, 10) - parseInt(timetokenA, 10);
    });

    setChatsSorted(
      [...chats].sort(
        ({ chatDescriptor: chatDescriptorA }, { chatDescriptor: chatDescriptorB }) => {
          return sortedKeys.indexOf(chatDescriptorA) - sortedKeys.indexOf(chatDescriptorB);
        }
      )
    );
  }, [channels, chatDescriptors, chats]);

  const chatType = useMemo(() => {
    switch (selectedTabIndex) {
      case ChatTabsTypes.direct:
        return ChatTypes.p2p;
      case ChatTabsTypes.public:
        return ChatTypes.group;
      default:
        return;
    }
  }, [selectedTabIndex]);

  const isPublicTab = useMemo(() => chatType === ChatTypes.group, [chatType]);

  const contentLoader = useMemo(() => {
    return (
      <Skeleton
        containerClassName={classes['chats-list__content-loader']}
        height={'3rem'}
        count={2}
      />
    );
  }, []);

  const setPublicTabChats = useCallback(async () => {
    setIsFetchingPublicTabChats(true);

    const offset = publicTabChatsOffsetRef.current;

    const { chats, chatsCount } = await getPublicChats({
      limit: PUBLIC_TAB_CHATS_LIMIT,
      offset,
      searchString: search,
    }).unwrap();

    setPublicTabChatsCount(chatsCount);

    setChatsFiltered((chatsFilteredPrev) => {
      if (!offset) {
        return chats;
      }
      return [...chatsFilteredPrev, ...chats];
    });

    setIsFetchingPublicTabChats(false);
  }, [getPublicChats, search]);

  useEffect(() => {
    if (isPublicTab) {
      publicTabChatsOffsetRef.current = 0;
      setPublicTabChats();
      return;
    }

    setChatsFiltered(
      chatsSorted.filter(
        ({ name, type }) =>
          name.toLowerCase().startsWith(search.toLowerCase()) && (!chatType || chatType === type)
      )
    );
  }, [chatType, chatsSorted, isPublicTab, search, setPublicTabChats]);

  useEffect(() => {
    pubnub.addListener(messageListener);

    if (!chatDescriptors.length) {
      isFetchingMessages.current = false;
      return;
    }

    pubnub.fetchMessages(
      {
        channels: chatDescriptors.map((chatDescriptor) => `${chatDescriptor}.changes`),
        count: CHAT_LOAD_MESSAGES_LIMIT,
      },
      (_, response) => setChannelsChanges(response?.channels ?? null)
    );

    pubnub.fetchMessages(
      { channels: chatDescriptors, count: CHAT_LOAD_MESSAGES_LIMIT },
      (_, response) => {
        setChannels(response?.channels ?? null);
        isFetchingMessages.current = false;
      }
    );

    const getChannelTimeToken = (descriptor: string) => {
      const { timetoken = '' } = { ...chatsCookies[getChatCookieKey(descriptor)] };

      if (timetoken) {
        return timetoken;
      }

      return dateToTimeToken(
        new Date(
          chats.find(({ chatDescriptor }) => chatDescriptor === descriptor)?.created ?? Date.now()
        )
      );
    };

    const channelTimetokens = chatDescriptors.map((chatDescriptor) =>
      getChannelTimeToken(chatDescriptor)
    );

    pubnub.messageCounts({ channels: chatDescriptors, channelTimetokens }, (_, response) =>
      setChannelsUnreadCount(response?.channels ?? null)
    );

    const chatDescriptorsWithChanges = [
      ...chatDescriptors,
      ...chatDescriptors.map((chatDescriptor) => `${chatDescriptor}.changes`),
    ];

    pubnub.subscribe({ channels: chatDescriptorsWithChanges });

    return () => {
      pubnub.removeListener(messageListener);
      pubnub.unsubscribe({ channels: chatDescriptorsWithChanges });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infiniteScrollNext = useCallback(() => {
    if (!isPublicTab) {
      return;
    }

    publicTabChatsOffsetRef.current += PUBLIC_TAB_CHATS_LIMIT;

    setPublicTabChats();
  }, [isPublicTab, setPublicTabChats]);

  const infiniteScrollHasMore = useMemo(
    () => (!isPublicTab ? false : publicTabChatsCount > chatsFiltered.length),
    [chatsFiltered.length, isPublicTab, publicTabChatsCount]
  );

  const infiniteScrollLoader = useMemo(() => {
    if (!isPublicTab) {
      return false;
    }

    return contentLoader;
  }, [contentLoader, isPublicTab]);

  const tabContent = useMemo(() => {
    if (isFetchingPublicTabChats && !publicTabChatsOffsetRef.current) {
      return contentLoader;
    }

    if (!chatsFiltered.length && !isFetchingMessages.current) {
      return (
        <IconLabel
          iconSize={40}
          iconId={'search-off'}
          label={t('chats.no-content')}
          className={classes['chats-list__content-no-data']}
          labelClassName={classes['chats-list__content-no-data-label']}
          singleColor
          nonClickable
        />
      );
    }

    return (
      <InfiniteScroll
        next={infiniteScrollNext}
        hasMore={infiniteScrollHasMore}
        loader={infiniteScrollLoader}
        dataLength={chatsFiltered.length}
        className={classNames(
          classes['chats-list__content-infinite-scroll'],
          'chats-list__content-infinite-scroll'
        )}
        height={'calc(100vh - (15rem - 0.25rem))'}
      >
        {chatsFiltered.map((chat) => (
          <ChatsListItem key={chat.chatId} chat={chat} />
        ))}
      </InfiniteScroll>
    );
  }, [
    chatsFiltered,
    contentLoader,
    infiniteScrollHasMore,
    infiniteScrollLoader,
    infiniteScrollNext,
    isFetchingPublicTabChats,
    t,
  ]);

  const tabItems = useMemo(
    () =>
      Object.values(ChatTabsTypes)
        .filter((value) => typeof value === 'number')
        .map((_, index) => {
          return {
            index,
            name: t(`chatTabs.tab-${index + 1}-label`),
            content: tabContent,
          };
        }),
    [t, tabContent]
  );

  const onTabChange = useCallback(
    (tabIndex: number) => {
      setSelectedTabIndex(tabIndex);
      setActiveChat(null);
      navigate(layoutPath('/chats'));

      if (tabIndex === ChatTabsTypes.public) {
        return;
      }

      document.querySelector('.chats-list__content-infinite-scroll')?.scrollTo(0, 0);
    },
    [navigate, setActiveChat, setSelectedTabIndex]
  );

  const content = useMemo(() => {
    if (isFetchingMessages.current) {
      return contentLoader;
    }

    return (
      <Tabs
        items={tabItems}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={onTabChange}
        tabNamesClassName={classes['chats-list__content-tab-names']}
        disabled={isFetchingPublicTabChats}
        noBackground
      />
    );
  }, [contentLoader, isFetchingPublicTabChats, onTabChange, selectedTabIndex, tabItems]);

  return (
    <div className={classes['chats-list']}>
      <div className={classes['chats-list__content']}>{content}</div>
    </div>
  );
});
