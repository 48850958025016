import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from '../../context';
import { Story, StoryStatus, StoryType } from '../../services';
import {
  Avatar,
  BreadCrumbs,
  COLLAPSE_STORAGE_KEY,
  CollapseMode,
  CollapseSwitcher,
  CreateCardAdmin,
  Deeplink,
  DeeplinkType,
  DropdownMenu,
  IconLabel,
  ImageGallery,
  Popup,
  PublishToggle,
  StoryTags,
  getCssVar,
  getWebAppUrl,
  useAnalytics,
  useAppDispatch,
  useAppSelector,
  useBeforeUnload,
  useDuration,
} from '../../shared';
import { getStories, togglePublishOnStory } from '../../slices';
import { NoContent } from '../Error';
import { CreateStoryModal, DeleteStoryModal } from '../StoryTabListAdmin';
import { StoryFeedAdmin } from './StoryFeedAdmin';

import classNames from 'classnames';
import dropdownMenuClasses from '../../shared/components/DropdownMenu/DropdownMenu.module.scss';
import classes from './StoryFeedItemsAdmin.module.scss';

export const StoryFeedItemsAdmin: FunctionComponent = () => {
  const { t } = useTranslation();

  const {
    userProfile,
    userInfo: {
      userData: { channelId },
    },
  } = useContext(UserContext);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { logClickOpenStory } = useAnalytics();

  const { startTrackTime, getDuration } = useDuration();

  const storyId = Number(useParams().storyId);

  const { stories, isFetching } = useAppSelector(({ stories }) => stories);

  const [story, setStory] = useState<Story | undefined>(undefined);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const [publishToggling, setPublishToggling] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [collapseMode, setCollapseMode] = useState(
    localStorage.getItem(COLLAPSE_STORAGE_KEY) || CollapseMode.STANDARD
  );

  useEffect(() => {
    window.addEventListener('storage', () => {
      setCollapseMode(localStorage.getItem(COLLAPSE_STORAGE_KEY) || CollapseMode.STANDARD);
    });
  }, []);

  useEffect(() => {
    dispatch(getStories({ channelId }));
  }, [channelId, dispatch]);

  useEffect(() => {
    if (stories.length) {
      setStory(stories.find(({ id }) => id === storyId));
    }
  }, [stories, storyId]);

  const logClickOpen = useCallback(
    () =>
      logClickOpenStory({
        channel_id: channelId,
        story_id: storyId,
        mix_id: storyId,
        time_stamp: new Date().toISOString(),
        duration: getDuration(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channelId, logClickOpenStory, storyId]
  );

  useEffect(() => {
    startTrackTime();
    return () => logClickOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logClickOpen]);

  useBeforeUnload(logClickOpen);

  const isPublished = useMemo(() => story?.status === StoryStatus.PUBLISHED, [story?.status]);

  const hasSources = useMemo(() => Boolean(story?.sources?.length), [story?.sources?.length]);

  const status = useMemo(
    () => (isPublished ? StoryStatus.UNPUBLISHED : StoryStatus.PUBLISHED),
    [isPublished]
  );

  const storyStatusChange = useCallback(async () => {
    if (!story) {
      return;
    }

    setPublishToggling(true);

    try {
      const updatedStory = await toast.promise(
        dispatch(togglePublishOnStory({ storyId, fields: { status } })).unwrap(),
        {
          pending: t(`publishToggle.${status}-toggling`),
          success: t(`publishToggle.${status}-toggled`),
          error: t(`publishToggle.${status}-error`),
        }
      );

      updatedStory && setStory({ ...story, status });
    } catch (_) {
      toast(t(`publishToggle.${status}-error`));
    } finally {
      setPublishToggling(false);
    }
  }, [dispatch, status, story, storyId, t]);

  const sourceStoryClick = useCallback(async () => {
    if (!storyId || !story?.sources?.length) {
      return;
    }

    const {
      channel: { subdomain },
      story: { id },
    } = story.sources[0];

    window.open(`${getWebAppUrl(subdomain)}/admin/storyTab/${id}/items`, '_blank', 'noreferrer');
  }, [story?.sources, storyId]);

  if (isFetching) {
    return <Skeleton height={'10rem'} />;
  }

  if (!story) {
    return <NoContent />;
  }

  const { title, subtitle, image, author, type } = story;

  const { url: imageUrl } = { ...image };

  const { id, avatar, screenName } = { ...author };

  const renderImage = Boolean(imageUrl && collapseMode === CollapseMode.STANDARD);

  const openProfile = () => {
    navigate(`/admin/profile${id !== userProfile.id ? `/${id}` : ''}`);
  };

  const breadCrumbsItems = [
    { link: '/admin/storyTab', title: t('adminLayoutMenu.mixes') },
    { title },
  ];

  return (
    <div className={classes['feed-items']}>
      <div className={classes['feed-items__header']}>
        <BreadCrumbs
          items={breadCrumbsItems}
          className={classes['feed-items__header-breadCrumbs']}
        />
        <CollapseSwitcher storageKey={COLLAPSE_STORAGE_KEY} />
      </div>

      <div className={classNames(`story-${storyId}`, classes['feed-items__story'])}>
        {renderImage && <ImageGallery items={[{ image }]} />}

        <div className={classes['feed-items__story-content']}>
          <div className={classes['feed-items__story-author']} onClick={openProfile}>
            <Avatar url={avatar?.url} nonClickable />
            <div className={classes['feed-items__story-author-info']}>
              <div className={classes['feed-items__story-author-info-label']}>
                {t('common.author')}
              </div>
              <div className={classes['feed-items__story-author-info-name']}>{screenName}</div>
            </div>
          </div>

          <div className={classes['feed-items__story-headlines']}>
            <div className={classes['feed-items__story-headlines-title']}>{title}</div>
            {subtitle && (
              <div className={classes['feed-items__story-headlines-subTitle']}>{subtitle}</div>
            )}
          </div>

          <StoryTags story={story} />

          <div className={classes['feed-items__story-buttons']}>
            <PublishToggle
              isPublished={isPublished}
              onClick={storyStatusChange}
              disabled={publishToggling}
            />
            <IconLabel
              label={t('common.delete')}
              color={getCssVar('--base-link-text-color')}
              hoverColor={getCssVar('--base-link-text-hover-color')}
              onClick={() => setIsDeleteOpen(true)}
            />
            {!isMobileOnly && (
              <IconLabel
                label={t('common.edit')}
                color={getCssVar('--base-link-text-color')}
                hoverColor={getCssVar('--base-link-text-hover-color')}
                onClick={() => setIsUpdateOpen(true)}
              />
            )}
            {hasSources && (
              <IconLabel
                labelFirst
                iconId={'arrow-rounded-right-up-box'}
                iconSize={14}
                label={t('common.source-story')}
                color={getCssVar('--base-link-text-color')}
                hoverColor={getCssVar('--base-link-text-hover-color')}
                onClick={sourceStoryClick}
              />
            )}
            <Popup
              isOpen={isMenuOpen}
              setIsOpen={setIsMenuOpen}
              iconId={'dots-menu'}
              color={getCssVar('--action-pane-icon-color')}
              hoverColor={getCssVar('--action-pane-icon-hover-color')}
              parentClassName={classes['feed-items__story-buttons-menu']}
              bodyTop={'1.5rem'}
              bodyRight={'0'}
              body={
                <DropdownMenu
                  width={'10rem'}
                  content={
                    <Deeplink
                      type={DeeplinkType.STORY}
                      storyId={storyId}
                      onClick={() => setIsMenuOpen(false)}
                      className={dropdownMenuClasses['dropdown-menu__item--small']}
                    />
                  }
                />
              }
            />
          </div>
        </div>
      </div>

      {Boolean(type !== StoryType.READONLY) && <CreateCardAdmin story={story} />}

      <StoryFeedAdmin storyId={storyId} />

      <DeleteStoryModal
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        storyId={storyId}
        navigateUrl={'/admin/storyTab'}
      />

      {isUpdateOpen && (
        <CreateStoryModal
          isOpen={isUpdateOpen}
          onClose={() => setIsUpdateOpen(false)}
          story={story}
        />
      )}
    </div>
  );
};
