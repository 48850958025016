import { FunctionComponent, memo, RefObject, useCallback, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { StoryCardStatus, StoryCardType } from '../../../../../services';
import { GalleryItem } from '../../../../models';
import { isEmptyString } from '../../../../utils';
import { Button, ButtonType } from '../../../Button';
import { IconLabel } from '../../../IconLabel';
import { IconLabelWithActive } from '../../../IconLabelWithActive';
import { PublishToggle } from '../../../PublishToggle';
import { ScheduleInput } from '../../../ScheduleInput';
import { LinkPostArticleFields, LinkPostQuoteFields, PostType } from '../CreateCardModal';

import classes from './CreateCardButtons.module.scss';

interface CreateCardButtonsProps {
  settingsButton: RefObject<HTMLDivElement>;
  settingsOpen: boolean;
  setSettingsOpen: (isOpen: boolean) => void;
  scrollToSettings: () => void;
  renderSettingsToggle: boolean;
  postType: PostType;
  postingTime: Date | null;
  setPostingTime: (postingTime: Date | null) => void;
  cardStatus?: StoryCardStatus;
  shortPostText: string;
  shortPostGallery: GalleryItem[];
  fullPostTitle: string;
  threadPostTitle: string;
  linkPostType?: StoryCardType;
  linkPostArticle?: LinkPostArticleFields;
  linkPostArticleSourceName?: string;
  linkPostArticleTitle?: string;
  linkPostQuote?: LinkPostQuoteFields;
  linkPostQuoteContent?: string;
  linkPostQuoteGallery?: GalleryItem[];
  isCategoryCard?: boolean;
  editMode: boolean;
  loading: boolean;
  postCard: (status: StoryCardStatus) => Promise<void>;
}

export const CreateCardButtons: FunctionComponent<CreateCardButtonsProps> = memo(
  ({
    settingsButton,
    settingsOpen,
    setSettingsOpen,
    scrollToSettings,
    renderSettingsToggle,
    postType,
    postingTime,
    setPostingTime,
    cardStatus,
    shortPostText,
    shortPostGallery,
    fullPostTitle,
    threadPostTitle,
    linkPostType,
    linkPostArticle,
    linkPostArticleSourceName,
    linkPostArticleTitle,
    linkPostQuote,
    linkPostQuoteContent,
    linkPostQuoteGallery,
    isCategoryCard,
    editMode,
    loading,
    postCard,
  }) => {
    const { t } = useTranslation();

    const [editCardStatus, setEditCardStatus] = useState<StoryCardStatus | undefined>(cardStatus);

    const isEditCardPublished = useMemo(
      () => editCardStatus === StoryCardStatus.PUBLISHED,
      [editCardStatus]
    );

    const postCardValidationError = useMemo(() => {
      switch (postType) {
        case PostType.SHORT:
          return Boolean(
            isCategoryCard
              ? !shortPostGallery.length
              : !shortPostGallery.length && isEmptyString(shortPostText)
          );
        case PostType.FULL:
          return isEmptyString(fullPostTitle);
        case PostType.THREAD:
          return isEmptyString(threadPostTitle);
        case PostType.LINK:
          return Boolean(
            !linkPostType ||
              (linkPostArticle &&
                (isEmptyString(linkPostArticleSourceName) ||
                  isEmptyString(linkPostArticleTitle))) ||
              (linkPostQuote &&
                isEmptyString(linkPostQuoteContent) &&
                !linkPostQuoteGallery?.length)
          );
        default:
          return false;
      }
    }, [
      fullPostTitle,
      isCategoryCard,
      linkPostArticle,
      linkPostArticleSourceName,
      linkPostArticleTitle,
      linkPostQuote,
      linkPostQuoteContent,
      linkPostQuoteGallery?.length,
      linkPostType,
      postType,
      shortPostGallery.length,
      shortPostText,
      threadPostTitle,
    ]);

    const postCardHandler = useCallback(
      async (status: StoryCardStatus) => {
        if (postCardValidationError) {
          return toast.error(
            t(`addContent.${isCategoryCard ? 'category' : postType}-post-validation-error`)
          );
        }

        const action = editMode ? 'edit' : 'create';

        await toast.promise(
          postCard(status),
          {
            pending: t(`addContent.${action}-pending`),
            success: t(`addContent.${action}-success`),
            error: t(`addContent.${action}-error`),
          },
          { toastId: 'postCard' }
        );
      },
      [editMode, isCategoryCard, postCard, postCardValidationError, postType, t]
    );

    const saveButton = useMemo(() => {
      if (editMode) {
        return null;
      }

      const props = {
        onClick: () => postCardHandler(StoryCardStatus.UNPUBLISHED),
        disabled: Boolean(loading || postingTime),
      };

      if (isMobileOnly) {
        return <IconLabel iconId={'save'} iconSize={20} {...props} />;
      }

      return <Button type={ButtonType.secondary} label={t('common.save-draft')} {...props} />;
    }, [editMode, loading, postCardHandler, postingTime, t]);

    const setEditCardStatusHandler = useCallback(() => {
      setEditCardStatus(
        isEditCardPublished ? StoryCardStatus.UNPUBLISHED : StoryCardStatus.PUBLISHED
      );
    }, [isEditCardPublished]);

    const publishLabel = useMemo(() => {
      switch (true) {
        case editMode:
          return t('common.save');
        case Boolean(postingTime):
          return t('common.schedule');
        case isCategoryCard:
          return t('common.sending');
        default:
          return t('common.publish');
      }
    }, [editMode, isCategoryCard, postingTime, t]);

    const publishClickHandler = useCallback(() => {
      if (!editMode) {
        postCardHandler(postingTime ? StoryCardStatus.SCHEDULED : StoryCardStatus.PUBLISHED);
        return;
      }

      if (isEditCardPublished) {
        postCardHandler(StoryCardStatus.PUBLISHED);
        return;
      }

      postCardHandler(postingTime ? StoryCardStatus.SCHEDULED : StoryCardStatus.UNPUBLISHED);
    }, [editMode, isEditCardPublished, postCardHandler, postingTime]);

    const buttons = useMemo(() => {
      return (
        <>
          {(!editMode || editCardStatus !== StoryCardStatus.PUBLISHED) && (
            <ScheduleInput
              postingTime={postingTime}
              setPostingTime={setPostingTime}
              title={t('addContent.schedule-post')}
              subTitle={t('addContent.schedule-post-description')}
            />
          )}
          {saveButton}
          {editMode && (
            <PublishToggle isPublished={isEditCardPublished} onClick={setEditCardStatusHandler} />
          )}
          <Button
            type={ButtonType.primary}
            label={publishLabel}
            disabled={loading}
            onClick={publishClickHandler}
          />
        </>
      );
    }, [
      editCardStatus,
      editMode,
      isEditCardPublished,
      loading,
      postingTime,
      publishClickHandler,
      publishLabel,
      saveButton,
      setEditCardStatusHandler,
      setPostingTime,
      t,
    ]);

    return (
      <div className={classes['buttons']}>
        {renderSettingsToggle && (
          <IconLabelWithActive
            ref={settingsButton}
            iconId={'settings'}
            active={settingsOpen}
            setActive={setSettingsOpen}
            onActive={scrollToSettings}
            className={classes['buttons__settings']}
            {...(!isMobileOnly && { label: t('common.settings') })}
          />
        )}
        {buttons}
      </div>
    );
  }
);
