import { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { CloseButton, GalleryItem, IconLabel } from '../../../../shared';

import classes from './MessageGallery.module.scss';

interface MessageGalleryProps {
  gallery: GalleryItem[];
  setGallery: (gallery: GalleryItem[]) => void;
  loading: boolean;
}

export const MessageGallery: FunctionComponent<MessageGalleryProps> = memo(
  ({ gallery, setGallery, loading }) => {
    const removeGalleryItem = useCallback(
      (index: number) => {
        setGallery(gallery.filter((_, i) => i !== index));
      },
      [gallery, setGallery]
    );

    const renderRemoveButton = useCallback(
      (index: number) => {
        return (
          <CloseButton
            className={classes['message-gallery__remove']}
            onClick={() => removeGalleryItem(index)}
            disabled={loading}
          />
        );
      },
      [loading, removeGalleryItem]
    );

    const renderMediaPreview = useCallback(
      ({ iconId, index, teaserUrl }: { iconId: string; index: number; teaserUrl?: string }) => {
        return (
          <div
            key={index}
            className={classes['message-gallery__media-placeholder']}
            style={{ backgroundImage: `url(${teaserUrl})` }}
          >
            <IconLabel
              iconId={iconId}
              iconSize={32}
              className={classes['message-gallery__media-placeholder-icon']}
              color={'#ffffff'}
              nonClickable
              singleColor
            />
            {renderRemoveButton(index)}
          </div>
        );
      },
      [renderRemoveButton]
    );

    const preview = useMemo(() => {
      return gallery.map(({ image, audio, video, pdf }, index) => {
        const teaserUrl = image?.url;

        switch (true) {
          case Boolean(audio):
            return renderMediaPreview({ iconId: 'audio', index, teaserUrl });
          case Boolean(video):
            return renderMediaPreview({ iconId: 'video', index, teaserUrl });
          case Boolean(pdf):
            return renderMediaPreview({ iconId: 'pdf', index, teaserUrl });
          case Boolean(image):
            return (
              <div key={index} className={classes['message-gallery__image-wrapper']}>
                <div
                  className={classes['message-gallery__image']}
                  style={{ backgroundImage: `url(${teaserUrl})` }}
                ></div>
                {renderRemoveButton(index)}
              </div>
            );
          default:
            return null;
        }
      });
    }, [gallery, renderMediaPreview, renderRemoveButton]);

    if (!gallery.length) {
      return null;
    }

    return <div className={classes['message-gallery']}>{preview}</div>;
  }
);
