import { combineReducers } from '@reduxjs/toolkit';
import { channelApi, graphqlApi, rootApi } from '../services';
import cardDetailsModal from '../slices/CardDetailsModalSlice';
import cardDetails from '../slices/CardDetailsSlice';
import channelNotifications from '../slices/ChannelNotificationsSlice';
import channels from '../slices/ChannelsSlice';
import inAppMessages from '../slices/InAppMessagesSlice';
import latestsStoryCardsFeed from '../slices/LatestsStoryCardsFeedSlice';
import newsFeed from '../slices/NewsFeedSlice';
import organisationUsers from '../slices/OrganisationUsersSlice';
import pushNotifications from '../slices/PushNotificationsSlice';
import searchFeed from '../slices/SearchFeedSlice';
import storiesCardsUserFeed from '../slices/StoriesCardsUserFeedSlice';
import stories from '../slices/StoriesSlice';
import storyCardsUserProfileFeed from '../slices/StoryCardsUserProfileFeedSlice';
import storyFeed from '../slices/StoryFeedSlice';
import tagsFeed from '../slices/TagsFeedSlice';

export const rootReducer = combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,
  [channelApi.reducerPath]: channelApi.reducer,
  [graphqlApi.reducerPath]: graphqlApi.reducer,
  newsFeed,
  searchFeed,
  storyFeed,
  tagsFeed,
  storiesCardsUserFeed,
  storyCardsUserProfileFeed,
  latestsStoryCardsFeed,
  cardDetailsModal,
  cardDetails,
  channelNotifications,
  pushNotifications,
  inAppMessages,
  organisationUsers,
  channels,
  stories,
});
