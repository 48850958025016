import { FunctionComponent, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ChannelContext, UserContext } from '../../context';
import { ChannelAppTabAction, StorySettings, StoryStatus } from '../../services';
import { getLocale, useAppDispatch, useAppSelector } from '../../shared';
import { getStories } from '../../slices';
import { NoContent } from '../Error';
import { StoryList } from './StoryList';

import classes from './StoryTabList.module.scss';

export enum StoryTabType {
  tab1 = 'storyTab1',
  tab2 = 'storyTab2',
}

interface StoryTabListProps {
  tab: StoryTabType;
}

export const StoryTabList: FunctionComponent<StoryTabListProps> = memo(({ tab }) => {
  const dispatch = useAppDispatch();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { appTabs } = useContext(ChannelContext);

  const { stories, isFetching: isFetchingStories } = useAppSelector(({ stories }) => stories);

  useEffect(() => {
    dispatch(
      getStories({ channelId, filter: { onlyPostIn: false, status: [StoryStatus.PUBLISHED] } })
    );
  }, [channelId, dispatch]);

  const includeCondition = useCallback(
    ({ includeInMixTab, includeInMixTab2 }: StorySettings) => {
      switch (tab) {
        case StoryTabType.tab1:
          return includeInMixTab;
        case StoryTabType.tab2:
          return includeInMixTab2;
        default:
          return true;
      }
    },
    [tab]
  );

  const filteredStories = useMemo(() => {
    return stories?.filter(({ settings }) => includeCondition(settings));
  }, [includeCondition, stories]);

  const content = useMemo(() => {
    if (isFetchingStories) {
      return (
        <div className={classes['story-tab-list__loader']}>
          <Skeleton height={'5rem'} />
          <Skeleton height={'5rem'} />
          <Skeleton height={'5rem'} />
        </div>
      );
    }

    return filteredStories?.length ? (
      <StoryList stories={filteredStories} tab={tab} />
    ) : (
      <NoContent />
    );
  }, [filteredStories, isFetchingStories, tab]);

  const appTabLabel = useMemo(() => {
    const appTabAction = ChannelAppTabAction[tab === StoryTabType.tab1 ? 'STORIES' : 'STORIES2'];

    const appTabTitle = appTabs.find(({ action }) => action.type === appTabAction)?.title;

    return appTabTitle?.find(({ language }) => language === getLocale())?.localization;
  }, [appTabs, tab]);

  return (
    <div className={classes['story-tab-list']}>
      <div className={classes['story-tab-list__title']}>{appTabLabel}</div>
      {content}
    </div>
  );
});
