import { FunctionComponent, memo } from 'react';
import { StoryCardImageContent } from '../../../services';
import { FeedCardContentCommon, FeedCardImageCommon, FeedCardProps } from '../../../shared';

export const FeedCardImage: FunctionComponent<FeedCardProps> = memo((props) => {
  const { card, chatId } = props;
  const { storyId, id, type, content, isPinnedToTopOfNewsFeed, mentionedUsers } = card;
  const { sourceName, headline, subHeadline, text, textHTML } = content as StoryCardImageContent;

  return (
    <FeedCardContentCommon
      storyId={storyId}
      cardId={id}
      chatId={chatId}
      type={type}
      pinned={isPinnedToTopOfNewsFeed}
      source={sourceName}
      title={headline}
      media={<FeedCardImageCommon {...props} />}
      subTitle={subHeadline}
      text={text}
      htmlText={textHTML}
      mentionedUsers={mentionedUsers}
    />
  );
});
