import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfigContext } from '../../../../../context';
import {
  Story,
  storyCardApi,
  StoryCardTeaserImageStyle,
  StoryCardType,
} from '../../../../../services';
import { getCssVar, isAdminLayout } from '../../../../utils';
import { IconLabel, IconLabelHintDirection } from '../../../IconLabel';
import { Select } from '../../../Select';
import { FullPostFields, LinkPostFields, PostType, ShortPostFields } from '../CreateCardModal';

import classes from './CreateCardHeader.module.scss';

interface CreateCardHeaderProps {
  postInStories: Story[];
  postInStoryId: number;
  setPostInStoryId: (id: number) => void;
  postInStorageKey: string;
  editMode: boolean;
  isFullPost: boolean;
  isLinkPost: boolean;
  isCategoryCard: boolean;
  loading: boolean;
  linkPostFieldsUrl: string;
  postInStoryTeaserStyle: StoryCardTeaserImageStyle;
  setPostType: (type: PostType) => void;
  setShortPostFields: (shortPostFields: ShortPostFields) => void;
  setFullPostFields: (fullPostFields: FullPostFields) => void;
  setLinkPostFields: (linkPostFields: LinkPostFields) => void;
  setLinkPostPrevUrl: (url: string) => void;
  setFullPostTextEditorOpen: (isOpen: boolean) => void;
  linkPostType?: StoryCardType;
}

export const CreateCardHeader: FunctionComponent<CreateCardHeaderProps> = memo(
  ({
    postInStories,
    postInStoryId,
    setPostInStoryId,
    postInStorageKey,
    editMode,
    isFullPost,
    isLinkPost,
    isCategoryCard,
    loading,
    linkPostFieldsUrl,
    postInStoryTeaserStyle,
    setPostType,
    setShortPostFields,
    setFullPostFields,
    setLinkPostFields,
    setLinkPostPrevUrl,
    setFullPostTextEditorOpen,
    linkPostType,
  }) => {
    const { t } = useTranslation();

    const { excludeLongPost } = useContext(ConfigContext).config.elements.addContent;

    const { storyCardParseUrlAsNative, storyCardParseUrlAsPost } = storyCardApi.endpoints;

    const [cardParseUrlAsNative] = storyCardParseUrlAsNative.useLazyQuery();

    const [cardParseUrlAsPost] = storyCardParseUrlAsPost.useLazyQuery();

    const [convertBackToLinkPost, setConvertBackToLinkPost] = useState(false);

    const hideConvertToLongPost = useMemo(
      () => !isAdminLayout() && excludeLongPost,
      [excludeLongPost]
    );

    const postInChangeHandler = useCallback(
      (id: number) => {
        localStorage.setItem(postInStorageKey, id.toString());
        setPostInStoryId(id);
      },
      [postInStorageKey, setPostInStoryId]
    );

    const convertToShortPost = useCallback(async () => {
      const toastId = toast.loading(t('addContent.converting-to-short-post'));

      const fields = await cardParseUrlAsNative({ url: linkPostFieldsUrl }).unwrap();

      toast.dismiss(toastId);

      if (fields) {
        setConvertBackToLinkPost(true);
        setShortPostFields({ gallery: [], ...fields });
        setPostType(PostType.SHORT);
        return;
      }

      toast.error(t('addContent.convert-to-short-post-error'), { delay: 1000 });
    }, [
      cardParseUrlAsNative,
      linkPostFieldsUrl,
      setConvertBackToLinkPost,
      setPostType,
      setShortPostFields,
      t,
    ]);

    const convertToFullPost = useCallback(async () => {
      const toastId = toast.loading(t('addContent.converting-to-full-post'));

      const fields = await cardParseUrlAsPost({ url: linkPostFieldsUrl }).unwrap();

      toast.dismiss(toastId);

      if (fields) {
        setConvertBackToLinkPost(true);

        setFullPostFields({
          ...fields,
          headline: '',
          styles: { teaserImageStyle: postInStoryTeaserStyle },
        } as FullPostFields);

        setPostType(PostType.FULL);

        return;
      }

      toast.error(t('addContent.convert-to-full-post-error'), { delay: 1000 });
    }, [
      cardParseUrlAsPost,
      linkPostFieldsUrl,
      postInStoryTeaserStyle,
      setConvertBackToLinkPost,
      setFullPostFields,
      setPostType,
      t,
    ]);

    const convertBackToLinkPostHandler = useCallback(() => {
      setConvertBackToLinkPost(false);
      setPostType(PostType.LINK);
    }, [setConvertBackToLinkPost, setPostType]);

    const resetLinkPostHandler = useCallback(() => {
      setLinkPostFields({ url: '' });
      setLinkPostPrevUrl('');
    }, [setLinkPostFields, setLinkPostPrevUrl]);

    const buttonCommonProps = useMemo(() => {
      return {
        iconSize: 18,
        disabled: loading,
        color: getCssVar('--base-link-text-color'),
        hoverColor: getCssVar('--base-link-text-hover-color'),
      };
    }, [loading]);

    const renderLinkPostConvert = useMemo(
      () => Boolean(isLinkPost && linkPostType === StoryCardType.ARTICLE),
      [isLinkPost, linkPostType]
    );

    const renderLinkPostReset = useMemo(
      () => Boolean(!editMode && isLinkPost && linkPostType),
      [editMode, isLinkPost, linkPostType]
    );

    const buttons = useMemo(() => {
      if (!renderLinkPostConvert && !renderLinkPostReset && !convertBackToLinkPost && !isFullPost) {
        return null;
      }

      return (
        <div className={classes['header__buttons']}>
          {renderLinkPostConvert && (
            <>
              <IconLabel
                iconId={'editorial'}
                onClick={convertToShortPost}
                {...buttonCommonProps}
                {...(!isMobileOnly && {
                  data: t('addContent.link-post-convert-to-short-post'),
                  hintDirection: IconLabelHintDirection.left,
                })}
              />
              {!hideConvertToLongPost && (
                <IconLabel
                  iconId={'article'}
                  onClick={convertToFullPost}
                  {...buttonCommonProps}
                  {...(!isMobileOnly && {
                    data: t('addContent.link-post-convert-to-full-post'),
                    hintDirection: IconLabelHintDirection.left,
                  })}
                />
              )}
            </>
          )}
          {renderLinkPostReset && (
            <IconLabel
              iconId={'add-link'}
              onClick={resetLinkPostHandler}
              {...buttonCommonProps}
              {...(!isMobileOnly && {
                label: !renderLinkPostConvert ? t('addContent.link-post-reset-label') : '',
                data: renderLinkPostConvert ? t('addContent.link-post-reset-label') : '',
                hintDirection: IconLabelHintDirection.left,
              })}
            />
          )}
          {isFullPost && (
            <IconLabel
              iconId={'edit'}
              onClick={() => setFullPostTextEditorOpen(true)}
              {...buttonCommonProps}
              {...(!isMobileOnly && { label: t('addContent.full-post-open-text-editor') })}
            />
          )}
          {convertBackToLinkPost && (
            <IconLabel
              iconId={'redo'}
              onClick={convertBackToLinkPostHandler}
              {...buttonCommonProps}
              {...(!isMobileOnly && { label: t('addContent.convert-back-to-link-post') })}
            />
          )}
        </div>
      );
    }, [
      buttonCommonProps,
      convertBackToLinkPost,
      convertBackToLinkPostHandler,
      convertToFullPost,
      convertToShortPost,
      hideConvertToLongPost,
      isFullPost,
      renderLinkPostConvert,
      renderLinkPostReset,
      resetLinkPostHandler,
      setFullPostTextEditorOpen,
      t,
    ]);

    if (isCategoryCard) {
      return null;
    }

    return (
      <div className={classes['header']}>
        <span className={classes['header__label']}>
          {t(`common.${editMode ? 'edit' : 'create'}-post-in`)}
        </span>
        <Select
          className={classes['header__select']}
          items={postInStories}
          onChange={(id) => postInChangeHandler(id as number)}
          selectedItemId={postInStoryId}
          listMaxHeightRem={10.5}
          disabled={editMode}
          noSidePadding={editMode}
          noBorder
        />
        {buttons}
      </div>
    );
  }
);
