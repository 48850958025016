import { FunctionComponent, memo, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { PostedIn } from '../../../components';
import { NoContent } from '../../../pages';
import { StoryCard } from '../../../services';
import { COLLAPSE_STORAGE_KEY } from '../../constants';
import { useAppSelector, useTagsFeed } from '../../hooks';
import { isAdminLayout } from '../../utils';
import { CollapseSwitcher } from '../CollapseSwitcher';
import { FeedCardLoader } from '../FeedCard';
import { IconLabel } from '../IconLabel';

import classes from './TagsFeedCommon.module.scss';

interface TagsFeedCommonProps {
  cardComponent: (card: StoryCard) => JSX.Element;
}

export const TagsFeedCommon: FunctionComponent<TagsFeedCommonProps> = memo(({ cardComponent }) => {
  const navigate = useNavigate();

  const { getTagsFeedItems, loadMoreTagsFeedItems } = useTagsFeed();

  const { tagsFeed: cards, hasNextPage, isFetching } = useAppSelector(({ tagsFeed }) => tagsFeed);

  const organisationTagId = Number(useParams().organisationTagId);

  const tagName = useMemo(
    () => cards[0]?.externalTags.find(({ id }) => id === organisationTagId)?.name ?? '',
    [cards, organisationTagId]
  );

  const hasCards = useMemo(() => Boolean(cards.length), [cards.length]);

  const historyState = useMemo(() => window.history.state, []);

  useEffect(() => {
    getTagsFeedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationTagId]);

  const content = useMemo(() => {
    if (!hasCards) {
      return <NoContent />;
    }

    return (
      <InfiniteScroll
        next={loadMoreTagsFeedItems}
        hasMore={hasNextPage}
        loader={<FeedCardLoader />}
        dataLength={cards.length}
        className={classes['tags-feed__content']}
      >
        <div className={classes['tags-feed__content-cards']}>
          {cards.map((card, index, cardsArray) => (
            <div key={card.id}>
              {card.story.title !== cardsArray[index - 1]?.story.title && (
                <PostedIn story={card.story} />
              )}
              {cardComponent(card)}
            </div>
          ))}
        </div>
      </InfiniteScroll>
    );
  }, [cardComponent, cards, hasCards, hasNextPage, loadMoreTagsFeedItems]);

  if (isFetching) {
    return <FeedCardLoader />;
  }

  return (
    <div className={classes['tags-feed']}>
      <div className={classes['tags-feed__header']}>
        {tagName && (
          <IconLabel
            {...(historyState && { iconId: 'arrow-rounded-left' })}
            label={`#${tagName}`}
            labelClassName={classes['tags-feed__header-title']}
            onClick={() => historyState && navigate(-1)}
            color={'#43445b'}
            singleColor
          />
        )}

        {isAdminLayout() && (
          <CollapseSwitcher
            storageKey={COLLAPSE_STORAGE_KEY}
            className={classes['tags-feed__header-switcher']}
          />
        )}
      </div>
      {content}
    </div>
  );
});
