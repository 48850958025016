import { FunctionComponent, memo, useCallback } from 'react';
import { StoryCardPostContentBlockFields } from '../../../services';
import {
  AudioBlock,
  BlockTypes,
  CalloutBlock,
  ChecklistBlock,
  CodeBlock,
  EmbedLinkBlock,
  FileBlock,
  HeaderBlock,
  HeaderBlockData,
  ImageBlock,
  ListBlock,
  MentionBlock,
  ParagraphBlock,
  PdfBlock,
  QuoteBlock,
  StoryBlock,
  StoryCardBlock,
  TableBlock,
  UserBlock,
  VideoBlock,
} from '../../../shared';
import { Audio } from './Audio';
import { Callout } from './Callout';
import { CheckList } from './CheckList';
import { Code } from './Code';
import { Delimiter } from './Delimiter';
import { EmbedLink } from './EmbedLink';
import { File } from './File';
import { Header } from './Header';
import { Image } from './Image';
import { List } from './List';
import { Mention } from './Mention';
import { Paragraph } from './Paragraph';
import { Pdf } from './Pdf';
import { Quote } from './Quote';
import { Story } from './Story';
import { StoryCard } from './StoryCard';
import { Table } from './Table';
import { UserProfile } from './UserProfile';
import { Video } from './Video';

import classNames from 'classnames';
import classes from './DetailsBlock.module.scss';

interface DetailsBlockProps {
  block: StoryCardPostContentBlockFields;
  storyId?: number;
  cardId?: number;
  chatId?: number;
  cardTitle?: string;
  doLinkify?: boolean;
}

export const DetailsBlock: FunctionComponent<DetailsBlockProps> = memo(
  ({ block, doLinkify, storyId, cardId, chatId, cardTitle }) => {
    const getBlockByType = useCallback(
      (type: string) => {
        switch (type) {
          case BlockTypes.header:
            return <Header {...(block as HeaderBlock).data} />;
          case BlockTypes.paragraph:
            return <Paragraph {...(block as ParagraphBlock).data} doLinkify={doLinkify} />;
          case BlockTypes.list:
            return <List {...(block as ListBlock).data} />;
          case BlockTypes.checklist:
            return <CheckList {...(block as ChecklistBlock).data} />;
          case BlockTypes.embedlink:
            return <EmbedLink {...(block as EmbedLinkBlock).data} />;
          case BlockTypes.table:
            return <Table {...(block as TableBlock).data} />;
          case BlockTypes.delimiter:
            return <Delimiter />;
          case BlockTypes.callout:
            return <Callout {...(block as CalloutBlock).data} />;
          case BlockTypes.quote:
            return <Quote {...(block as QuoteBlock).data} />;
          case BlockTypes.image:
            return <Image {...(block as ImageBlock).data} />;
          case BlockTypes.audio:
            return (
              <Audio
                {...(block as AudioBlock).data}
                storyId={storyId}
                cardId={cardId}
                chatId={chatId}
                cardTitle={cardTitle}
              />
            );
          case BlockTypes.video:
            return <Video {...(block as VideoBlock).data} storyId={storyId} cardId={cardId} />;
          case BlockTypes.pdf:
            return <Pdf {...(block as PdfBlock).data} />;
          case BlockTypes.file:
            return <File {...(block as FileBlock).data} />;
          case BlockTypes.code:
            return <Code {...(block as CodeBlock).data} />;
          case BlockTypes.user:
            return <UserProfile {...(block as UserBlock).data} />;
          case BlockTypes.story:
            return <Story {...(block as StoryBlock).data} />;
          case BlockTypes.card:
            return <StoryCard {...(block as StoryCardBlock).data} />;
          case BlockTypes.mention:
            return <Mention {...(block as MentionBlock).data} />;
          default:
            return null;
        }
      },
      [block, cardId, cardTitle, chatId, doLinkify, storyId]
    );

    const getBlockClass = useCallback((block: StoryCardPostContentBlockFields) => {
      const { type, data } = block;

      switch (type) {
        case BlockTypes.paragraph:
          return classNames(classes['block'], classes['block__paragraph']);
        case BlockTypes.header:
          return classNames(
            classes['block'],
            classes[`block__header-${(data as HeaderBlockData).level}`]
          );
        default:
          return classes['block'];
      }
    }, []);

    return <div className={getBlockClass(block)}>{getBlockByType(block.type)}</div>;
  }
);
