import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AudioBlock,
  BlockTypes,
  BlockUnion,
  EmbedLinkBlock,
  GalleryItem,
  HeaderBlock,
  ImageBlock,
  MentionBlock,
  ParagraphBlock,
  PdfBlock,
  useCardTitle,
  VideoBlock,
} from '../../../shared';

import { ConfigContext } from '../../../context';
import {
  StoryCard,
  StoryCardContentArticlePost,
  StoryCardContentArticleQuote,
  StoryCardContentGallery,
  StoryCardContentText,
  StoryCardContentTitle,
  StoryCardQuoteContent,
  StoryCardType,
} from '../../../services';
import { DetailsBlock } from '../DetailsBlock';

interface DetailsCardToBlockProps {
  card: StoryCard;
  chatId?: number;
}

export const DetailsCardToBlock: FunctionComponent<DetailsCardToBlockProps> = memo(
  ({ card, chatId }) => {
    const { t } = useTranslation();

    const cardTitle = useCardTitle(card);

    const { thread } = useContext(ConfigContext).config.elements.card;

    const threadTitle = useMemo(
      () => t(`${thread.localiseKey ?? 'common.discussion'}`),
      [t, thread.localiseKey]
    );

    const { id: cardId, storyId, type, content, mentionedUsers } = card;

    const { title } = content as StoryCardContentTitle;
    const { sourceName, headline, subHeadline, text, textHTML } = content as StoryCardContentText;
    const { abstract } = content as StoryCardContentArticlePost;
    const { url } = content as StoryCardContentArticleQuote;
    const { quote, quoteSource } = content as StoryCardQuoteContent;
    const { gallery } = content as StoryCardContentGallery;

    const doLinkify = useMemo(
      () => ![StoryCardType.QUOTE, StoryCardType.ARTICLE, StoryCardType.POST].includes(type),
      [type]
    );

    const getPreviewSrc = useCallback(
      (galleryItem: GalleryItem) => {
        const imageUrl = galleryItem?.image?.url;

        switch (type) {
          case StoryCardType.IMAGE:
          case StoryCardType.QUOTE:
          case StoryCardType.POST:
          case StoryCardType.ARTICLE:
            return imageUrl;
          case StoryCardType.AUDIO:
            return imageUrl || galleryItem?.audio?.thumb;
          case StoryCardType.VIDEO:
            return imageUrl || galleryItem?.video?.thumb;
          case StoryCardType.PDF:
            return imageUrl || galleryItem?.pdf?.thumb;
          default:
            return '';
        }
      },
      [type]
    );

    const getBlockItem = useCallback((exists: boolean, block: BlockUnion) => {
      return exists ? [block] : [];
    }, []);

    const galleryItems = gallery as GalleryItem[];

    const galleryItem = galleryItems?.[0] as GalleryItem;

    const rightholder = useMemo(
      () => galleryItem?.image?.rightholder,
      [galleryItem?.image?.rightholder]
    );

    const caption = useMemo(() => galleryItem?.title, [galleryItem?.title]);

    const sourceNameBlock = useMemo(() => {
      return getBlockItem(Boolean(sourceName), {
        type: BlockTypes.paragraph,
        data: { text: sourceName, source: true },
      } as ParagraphBlock);
    }, [getBlockItem, sourceName]);

    const headlineBlock = useMemo(() => {
      return getBlockItem(Boolean(headline), {
        type: BlockTypes.header,
        data: { text: headline, level: 1 },
      } as HeaderBlock);
    }, [getBlockItem, headline]);

    const mentionBlock = useMemo(() => {
      return getBlockItem(Boolean(mentionedUsers.length), {
        type: BlockTypes.mention,
        data: { mentionedUsers },
      } as MentionBlock);
    }, [getBlockItem, mentionedUsers]);

    const subHeadlineBlock = useMemo(() => {
      return getBlockItem(Boolean(subHeadline), {
        type: BlockTypes.header,
        data: { text: subHeadline, level: 4 },
      } as HeaderBlock);
    }, [getBlockItem, subHeadline]);

    const textBlock = useMemo(() => {
      return getBlockItem(Boolean(textHTML || text), {
        type: BlockTypes.paragraph,
        data: { text: textHTML || text },
      } as ParagraphBlock);
    }, [getBlockItem, text, textHTML]);

    const imageBlock = useMemo(() => {
      return getBlockItem(Boolean(galleryItems?.length), {
        type: BlockTypes.image,
        data: {
          items: galleryItems?.map((galleryItem: GalleryItem) => {
            return {
              caption: galleryItem.title,
              image: {
                id: galleryItem.image?.id,
                url: getPreviewSrc(galleryItem),
              },
              copyright: galleryItem.image?.rightholder,
            };
          }),
          withBorder: false,
          withBackground: false,
          stretched: false,
        },
      } as ImageBlock);
    }, [galleryItems, getBlockItem, getPreviewSrc]);

    const audio = useMemo(() => galleryItem?.audio, [galleryItem?.audio]);
    const audioBlock = useMemo(() => {
      return getBlockItem(Boolean(audio), {
        type: BlockTypes.audio,
        data: {
          items: [
            {
              audio,
              caption,
              previewUrl: getPreviewSrc(galleryItem),
              copyright: rightholder,
            },
          ],
        },
      } as AudioBlock);
    }, [audio, caption, galleryItem, getBlockItem, getPreviewSrc, rightholder]);

    const video = useMemo(() => galleryItem?.video, [galleryItem?.video]);
    const videoBlock = useMemo(() => {
      return getBlockItem(Boolean(video), {
        type: BlockTypes.video,
        data: {
          items: [
            {
              video,
              caption,
              copyright: rightholder,
              previewUrl: getPreviewSrc(galleryItem),
            },
          ],
        },
      } as VideoBlock);
    }, [caption, galleryItem, getBlockItem, getPreviewSrc, rightholder, video]);

    const pdf = useMemo(() => galleryItem?.pdf, [galleryItem?.pdf]);
    const pdfBlock = useMemo(() => {
      return getBlockItem(Boolean(pdf), {
        type: BlockTypes.pdf,
        data: {
          items: [
            {
              pdf,
              caption,
              previewUrl: getPreviewSrc(galleryItem),
              copyright: rightholder,
            },
          ],
        },
      } as PdfBlock);
    }, [caption, galleryItem, getBlockItem, getPreviewSrc, pdf, rightholder]);

    const embedLinkCommentBlock = useMemo(() => {
      return getBlockItem(Boolean(headline), {
        type: BlockTypes.header,
        data: { text: headline, level: 5 },
      } as HeaderBlock);
    }, [getBlockItem, headline]);

    const blocks: BlockUnion[] = useMemo(() => {
      switch (type) {
        case StoryCardType.EDITORIAL:
          return [...sourceNameBlock, ...headlineBlock, ...subHeadlineBlock, ...textBlock];
        case StoryCardType.IMAGE:
          return [
            ...sourceNameBlock,
            ...headlineBlock,
            ...mentionBlock,
            ...imageBlock,
            ...subHeadlineBlock,
            ...textBlock,
          ];
        case StoryCardType.PDF:
          return [
            ...sourceNameBlock,
            ...headlineBlock,
            ...pdfBlock,
            ...subHeadlineBlock,
            ...textBlock,
          ];
        case StoryCardType.AUDIO:
          return [
            ...sourceNameBlock,
            ...headlineBlock,
            ...audioBlock,
            ...subHeadlineBlock,
            ...textBlock,
          ];
        case StoryCardType.VIDEO:
          return [
            ...sourceNameBlock,
            ...headlineBlock,
            ...videoBlock,
            ...subHeadlineBlock,
            ...textBlock,
          ];
        case StoryCardType.THREAD:
          return [
            { type: BlockTypes.paragraph, data: { text: threadTitle } } as ParagraphBlock,
            { type: BlockTypes.header, data: { text: title, level: 1 } } as HeaderBlock,
          ];
        case StoryCardType.QUOTE:
          return [
            ...embedLinkCommentBlock,
            {
              type: BlockTypes.embedlink,
              data: {
                link: {
                  url,
                  text: quote,
                  quoteSource,
                  image: { url: getPreviewSrc(galleryItem) },
                },
              },
            } as EmbedLinkBlock,
          ];
        case StoryCardType.ARTICLE:
          return [
            ...embedLinkCommentBlock,
            {
              type: BlockTypes.embedlink,
              data: {
                link: {
                  url,
                  title,
                  text: abstract,
                  quoteSource,
                  image: { url: getPreviewSrc(galleryItem) },
                },
              },
            } as EmbedLinkBlock,
          ];
        default:
          return [];
      }
    }, [
      abstract,
      audioBlock,
      embedLinkCommentBlock,
      galleryItem,
      getPreviewSrc,
      headlineBlock,
      imageBlock,
      mentionBlock,
      pdfBlock,
      quote,
      quoteSource,
      sourceNameBlock,
      subHeadlineBlock,
      textBlock,
      threadTitle,
      title,
      type,
      url,
      videoBlock,
    ]);

    return (
      <>
        {blocks.map((block, index) => (
          <DetailsBlock
            key={`${block.type}${index}`}
            block={block}
            storyId={storyId}
            cardId={cardId}
            chatId={chatId}
            cardTitle={cardTitle}
            doLinkify={doLinkify}
          />
        ))}
      </>
    );
  }
);
