/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Story, StoryCardCopySyncMoveData, storyCardApi } from '../../../../services';
import { getStoryFeed } from '../../../../slices';
import { COPY_CUT_COOKIE_KEY, CopyCutAction } from '../../../constants';
import { useAppDispatch, useOnClickOutside } from '../../../hooks';
import { CookieActionType, cookieOptions, getCssVar, getOrganisationDomain } from '../../../utils';
import { ConfirmationModal } from '../../ConfirmationModal';
import { DropdownMenu } from '../../DropdownMenu';
import { Popup } from '../../Popup';
import { CreateCardWithInput } from '../CreateCardWithInput';

import dropdownMenuClasses from '../../DropdownMenu/DropdownMenu.module.scss';

interface CreateCardAdminProps {
  story?: Story;
}

export const CreateCardAdmin: FunctionComponent<CreateCardAdminProps> = memo(({ story }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [cookies, _, removeCookie] = useCookies([COPY_CUT_COOKIE_KEY]);

  const {
    organisation: copyCutOrganisation = '',
    storyId: copyCutStoryId = 0,
    cardId: copyCutCardId = 0,
    cardReadOnly: copyCutIsReadOnly = false,
    action: copyCutAction = '',
  } = { ...cookies[COPY_CUT_COOKIE_KEY] };

  const { storyId: targetStoryId } = useParams();

  const [moveToOtherStory] = storyCardApi.endpoints.storyCardMoveToOtherStory.useLazyQuery();

  const [postInStoryAsCopy] = storyCardApi.endpoints.storyCardPostInStoryAsCopy.useLazyQuery();

  const [postInStoryAsSync] = storyCardApi.endpoints.storyCardPostInStoryAsSync.useLazyQuery();

  const [errorMessage, setErrorMessage] = useState('');

  const [isCopySyncCutListOpen, setIsCopySyncCutListOpen] = useState(false);

  const isStoryFeed = useMemo(() => Boolean(targetStoryId), [targetStoryId]);

  const renderCopyPaste = useMemo(() => copyCutAction === CopyCutAction.COPY, [copyCutAction]);

  const renderCopySync = useMemo(
    () =>
      Boolean(!copyCutIsReadOnly && renderCopyPaste && copyCutStoryId !== Number(targetStoryId)),
    [copyCutIsReadOnly, copyCutStoryId, renderCopyPaste, targetStoryId]
  );

  const renderCutPaste = useMemo(
    () => Boolean(copyCutAction === CopyCutAction.CUT && copyCutStoryId !== Number(targetStoryId)),
    [copyCutAction, copyCutStoryId, targetStoryId]
  );

  const renderCopySyncCutActions = useMemo(
    () =>
      Boolean(
        copyCutOrganisation === getOrganisationDomain() &&
          isStoryFeed &&
          (renderCopyPaste || renderCutPaste)
      ),
    [copyCutOrganisation, isStoryFeed, renderCopyPaste, renderCutPaste]
  );

  const copySyncCutContainer = useRef<HTMLDivElement>(null);

  useOnClickOutside(copySyncCutContainer, () => {
    setIsCopySyncCutListOpen(false);
  });

  const copySyncMoveActionHandler = useCallback(
    async (action: any) => {
      setIsCopySyncCutListOpen(false);

      try {
        const { payload, error }: StoryCardCopySyncMoveData = await toast.promise(
          action({
            sourceStoryId: copyCutStoryId,
            sourceStoryCardId: copyCutCardId,
            targetStoryId: Number(targetStoryId),
          }).unwrap(),
          {
            pending: t('cardActions.pasting'),
            success: t('cardActions.pasted'),
            error: t('cardActions.paste-error'),
          }
        );

        if (payload) {
          removeCookie(COPY_CUT_COOKIE_KEY, cookieOptions({ action: CookieActionType.REMOVE }));

          dispatch(getStoryFeed({ storyId: Number(targetStoryId) }));
        } else {
          toast.error(
            error.__typename === 'DuplicatedStoryItemIdError'
              ? t('cardActions.duplicate-error')
              : t('common.error-message')
          );
        }
      } catch (_) {}
    },
    [copyCutCardId, copyCutStoryId, dispatch, removeCookie, t, targetStoryId]
  );

  const dropdownMenuContent = useMemo(() => {
    return (
      <>
        {renderCopyPaste && (
          <span
            className={dropdownMenuClasses['dropdown-menu__item']}
            onClick={() => copySyncMoveActionHandler(postInStoryAsCopy)}
          >
            {t('common.paste-card')}
          </span>
        )}
        {renderCopySync && (
          <span
            className={dropdownMenuClasses['dropdown-menu__item']}
            onClick={() => copySyncMoveActionHandler(postInStoryAsSync)}
          >
            {t('common.sync-card')}
          </span>
        )}
        {renderCutPaste && (
          <span
            className={dropdownMenuClasses['dropdown-menu__item']}
            onClick={() => copySyncMoveActionHandler(moveToOtherStory)}
          >
            {t('common.paste-card')}
          </span>
        )}
      </>
    );
  }, [
    copySyncMoveActionHandler,
    moveToOtherStory,
    postInStoryAsCopy,
    postInStoryAsSync,
    renderCopyPaste,
    renderCopySync,
    renderCutPaste,
    t,
  ]);

  const adminActions = useMemo(() => {
    return (
      <>
        {renderCopySyncCutActions && (
          <Popup
            isOpen={isCopySyncCutListOpen}
            setIsOpen={setIsCopySyncCutListOpen}
            iconId={'copy'}
            color={getCssVar('--create-card-link-color')}
            hoverColor={getCssVar('--create-card-link-hover-color')}
            bodyTop={'2.5rem'}
            bodyRight={'0'}
            body={<DropdownMenu width={'10rem'} content={dropdownMenuContent} />}
          />
        )}

        <ConfirmationModal
          isOpen={Boolean(errorMessage)}
          onClose={() => setErrorMessage('')}
          onAccept={() => setErrorMessage('')}
          acceptLabel={t('common.accept')}
          title={t('cardSyncError.duplicate-story-error-title')}
          subTitle={errorMessage}
          hideCancel
        />
      </>
    );
  }, [dropdownMenuContent, errorMessage, isCopySyncCutListOpen, renderCopySyncCutActions, t]);

  return <CreateCardWithInput story={story} adminActions={adminActions} />;
});
