import { FunctionComponent, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { UserContext } from '../../../../context';
import {
  FileStatus,
  MediaUploadPreview,
  VideoBlockData,
  VideoPlayer,
  useAnalytics,
} from '../../../../shared';

import classes from './Video.module.scss';

interface VideoProps extends VideoBlockData {
  storyId?: number;
  cardId?: number;
}

export const Video: FunctionComponent<VideoProps> = memo(({ items, storyId, cardId }) => {
  const { t } = useTranslation();

  const { logItemPlayed } = useAnalytics();

  const { channelId } = useContext(UserContext).userInfo.userData;

  if (!items.length) {
    return null;
  }

  const { video, caption, copyright, previewUrl } = items[0];

  if (!video) {
    return null;
  }

  const { url, status } = video;

  if (status === FileStatus.PROCESSING) {
    return <Skeleton height={'5rem'} />;
  }

  if (!url) {
    return <MediaUploadPreview iconId={'video'} label={t('richTextEditor.video-placeholder')} />;
  }

  return (
    <div className={classes['video']}>
      <VideoPlayer
        url={url}
        thumb={previewUrl}
        caption={caption}
        copyright={copyright}
        captionClassName={classes['video__caption']}
        onLogItemPlayed={(duration) =>
          logItemPlayed({
            channel_id: channelId,
            story_id: storyId ?? 0,
            item_id: cardId ?? 0,
            duration,
            time_stamp: new Date().toISOString(),
          })
        }
      />
    </div>
  );
});
