import { ResponsiveBar } from '@nivo/bar';
import { format, parseISO } from 'date-fns';
import { FunctionComponent, memo, useCallback, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Emoticon } from '../../../../assets/emojis';
import { UserContext } from '../../../../context';
import {
  AnalyticContentListOrderBy,
  StoryCardType,
  useContentListQuery,
} from '../../../../services';
import {
  ANALYTICS_ADMIN_COOKIE_KEY,
  OrderDirection,
  Select,
  cookieOptions,
  getWebAppUrl,
} from '../../../../shared';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader, getHorizontalBarsLayer } from '../../helpers';
import { ContentProps } from '../Content';

import analytics from '../../Analytics.module.scss';
import classes from './ContentTop.module.scss';

const BAR_HEIGHT = 32;
const MARGIN_TOP = 20;
const MARGIN_BOTTOM = 40;
const PADDING = BAR_HEIGHT / 2;

interface TooltipProps {
  label: string;
  type: StoryCardType;
  postedAt: string;
  views: number;
  shares: number;
  engagement: number;
  comments: number;
  commentReactionsTotal: number;
  reactionsTotal: number;
  reactionsPositive: number;
  reactionsNegative: number;
  likeCount: number;
  loveCount: number;
  hahaCount: number;
  wowCount: number;
  sadCount: number;
  angryCount: number;
}

export const ContentTop: FunctionComponent<ContentProps> = memo(
  ({ from, to, channelId, platform, categoryId, organisationTags }) => {
    const { t } = useTranslation();

    const { channelId: selectedChannelId } = useContext(UserContext).userInfo.userData;

    const COOKIE_KEY = useMemo(
      () => `${ANALYTICS_ADMIN_COOKIE_KEY}_${selectedChannelId}_contentTop`,
      [selectedChannelId]
    );

    const [cookies, setCookie] = useCookies([COOKIE_KEY]);

    const { orderBy = AnalyticContentListOrderBy.VIEWS } = { ...cookies[COOKIE_KEY] };

    const { data: contentList, isFetching: isFetchingContentList } = useContentListQuery({
      from,
      to,
      channelId,
      platform,
      categoryId,
      organisationTags,
      orderBy,
      storyCardType: [],
      orderDirection: OrderDirection.DESC,
      page: 1,
      size: 5,
    });

    const tooltip = useCallback(
      ({
        label,
        type,
        postedAt,
        views,
        shares,
        engagement,
        comments,
        commentReactionsTotal,
        reactionsTotal,
        reactionsPositive,
        reactionsNegative,
        likeCount,
        loveCount,
        hahaCount,
        wowCount,
        sadCount,
        angryCount,
      }: TooltipProps) => {
        return (
          <div key={label} className={analytics['analytics__chart-tooltip']}>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={analytics['analytics__chart-tooltip-item-label']}>
                {t('contentListLabels.type')}
              </span>
              <span className={analytics['analytics__chart-tooltip-item-value']}>
                {t(`cardTypes.${type.toLowerCase()}`)}
              </span>
            </div>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={analytics['analytics__chart-tooltip-item-label']}>
                {t('contentListLabels.posted-time')}
              </span>
              <span className={analytics['analytics__chart-tooltip-item-value']}>
                {format(parseISO(postedAt), 'dd.MM.yy, HH:mm')}
              </span>
            </div>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={analytics['analytics__chart-tooltip-item-label']}>
                {t('contentListLabels.views')}
              </span>
              <span className={analytics['analytics__chart-tooltip-item-value']}>{views ?? 0}</span>
            </div>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={analytics['analytics__chart-tooltip-item-label']}>
                {t('contentListLabels.shares')}
              </span>
              <span className={analytics['analytics__chart-tooltip-item-value']}>
                {shares ?? 0}
              </span>
            </div>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={analytics['analytics__chart-tooltip-item-label']}>
                {t('contentListLabels.engagement')}
              </span>
              <span className={analytics['analytics__chart-tooltip-item-value']}>
                {engagement ?? 0}
              </span>
            </div>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={analytics['analytics__chart-tooltip-item-label']}>
                {t('contentListLabels.comments')}
              </span>
              <span className={analytics['analytics__chart-tooltip-item-value']}>
                {comments ?? 0}
              </span>
            </div>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={analytics['analytics__chart-tooltip-item-label']}>
                {t('contentListLabels.likes-on-comments')}
              </span>
              <span className={analytics['analytics__chart-tooltip-item-value']}>
                {commentReactionsTotal ?? 0}
              </span>
            </div>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={analytics['analytics__chart-tooltip-item-label']}>
                {t('contentListLabels.reactions')}
              </span>
              <span className={analytics['analytics__chart-tooltip-item-value']}>
                {reactionsTotal ?? 0}
              </span>
            </div>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={analytics['analytics__chart-tooltip-item-label']}>
                {t('contentListLabels.reactions-positive')}
              </span>
              <span className={analytics['analytics__chart-tooltip-item-value']}>
                {reactionsPositive ?? 0}
              </span>
            </div>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={analytics['analytics__chart-tooltip-item-label']}>
                {t('contentListLabels.reactions-negative')}
              </span>
              <span className={analytics['analytics__chart-tooltip-item-value']}>
                {reactionsNegative ?? 0}
              </span>
            </div>
            <div className={analytics['analytics__chart-tooltip-item']}>
              <span className={classes['reaction']}>
                <Emoticon reaction={'like'} size={16} />
                {likeCount ?? 0}
              </span>
              <span className={classes['reaction']}>
                <Emoticon reaction={'love'} size={16} />
                {loveCount ?? 0}
              </span>
              <span className={classes['reaction']}>
                <Emoticon reaction={'haha'} size={16} />
                {hahaCount ?? 0}
              </span>
              <span className={classes['reaction']}>
                <Emoticon reaction={'wow'} size={16} />
                {wowCount ?? 0}
              </span>
              <span className={classes['reaction']}>
                <Emoticon reaction={'sad'} size={16} />
                {sadCount ?? 0}
              </span>
              <span className={classes['reaction']}>
                <Emoticon reaction={'angry'} size={16} />
                {angryCount ?? 0}
              </span>
            </div>
          </div>
        );
      },
      [t]
    );

    const renderChart = useMemo(() => {
      if (isFetchingContentList) {
        return <Loader />;
      }

      if (!contentList) {
        return null;
      }

      const { items } = contentList;

      const itemsFiltered = items.filter(({ value }) => value);

      if (!itemsFiltered.length) {
        return (
          <div className={analytics['analytics__chart-no-records']}>{t('common.no-records')}</div>
        );
      }

      const keys: string[] = [];

      const data = itemsFiltered.map(({ label, value, payload }) => {
        const {
          channel: { subdomain },
          views,
          shares,
          engagement,
          comments,
          commentReactionsTotal,
          reactions,
          reactionsTotal,
          reactionsPositive,
          reactionsNegative,
          storyCard: { id: cardId, storyId, type, postedAt },
        } = payload;

        const labelWithId = `(${cardId}) ${label}`;

        keys.push(labelWithId);

        const { count: likeCount = 0 } = { ...reactions.find(({ name }) => name === 'like') };
        const { count: loveCount = 0 } = { ...reactions.find(({ name }) => name === 'love') };
        const { count: hahaCount = 0 } = { ...reactions.find(({ name }) => name === 'haha') };
        const { count: wowCount = 0 } = { ...reactions.find(({ name }) => name === 'wow') };
        const { count: sadCount = 0 } = { ...reactions.find(({ name }) => name === 'sad') };
        const { count: angryCount = 0 } = { ...reactions.find(({ name }) => name === 'angry') };

        return {
          [labelWithId]: value,
          label: labelWithId,
          type,
          postedAt,
          views,
          shares,
          engagement,
          comments,
          commentReactionsTotal,
          reactionsTotal,
          reactionsPositive,
          reactionsNegative,
          likeCount,
          loveCount,
          hahaCount,
          wowCount,
          sadCount,
          angryCount,
          detailsPath: `${getWebAppUrl(subdomain)}/admin/details/${storyId}/${cardId}`,
        };
      });

      const height = `calc(${data.length * BAR_HEIGHT}px + ${MARGIN_TOP}px + ${MARGIN_BOTTOM}px + ${
        (data.length + 1) * PADDING
      }px)`;

      return (
        <div style={{ height }}>
          <ResponsiveBar
            layout={'horizontal'}
            enableGridY={false}
            margin={{ top: MARGIN_TOP, right: 50, bottom: MARGIN_BOTTOM, left: 20 }}
            padding={0.5}
            borderRadius={4}
            tooltip={({ data }) => tooltip(data)}
            axisLeft={{
              renderTick: ({ value, y }) => (
                <text y={y - PADDING} fontSize={14} fill={'#1E1E1E'}>
                  {value.replace(/ *\([^)]*\) */g, '')}
                </text>
              ),
            }}
            axisBottom={{ format: (value) => (value === Math.floor(value) ? value : '') }}
            data={data.reverse()}
            keys={keys}
            indexBy={'label'}
            colors={[COLORS[0]]}
            label={''}
            onClick={({ data: { detailsPath } }) =>
              window.open(detailsPath, '_blank', 'noopener, noreferrer')
            }
            layers={['axes', 'bars', ({ bars }) => getHorizontalBarsLayer({ bars })]}
          />
        </div>
      );
    }, [contentList, isFetchingContentList, t, tooltip]);

    const dropdownItems = useMemo(() => {
      return [
        AnalyticContentListOrderBy.VIEWS,
        AnalyticContentListOrderBy.ENGAGEMENT,
        AnalyticContentListOrderBy.REACTIONS_POSITIVE,
        AnalyticContentListOrderBy.REACTIONS_NEGATIVE,
      ].map((value) => {
        return { id: value, title: t(`contentTopOrderBy.${value}`) };
      });
    }, [t]);

    const orderByChange = useCallback(
      (orderBy: AnalyticContentListOrderBy) => {
        setCookie(COOKIE_KEY, { ...cookies[COOKIE_KEY], orderBy }, cookieOptions());
      },
      [COOKIE_KEY, cookies, setCookie]
    );

    return (
      <ChartWrapper
        typeLocaliseKey={'chartTypes.content-top'}
        infoLocaliseKey={'contentTop.info'}
        component={
          <Select
            className={analytics['analytics__chart-header-select']}
            selectedItemId={orderBy}
            items={dropdownItems}
            onChange={(orderBy) => orderByChange(orderBy as AnalyticContentListOrderBy)}
          />
        }
      >
        {renderChart}
      </ChartWrapper>
    );
  }
);
