import { FunctionComponent, memo, useMemo } from 'react';
import { StoryCardType } from '../../services';
import { FeedCardProps } from '../../shared';
import { FeedCardArticle } from './FeedCardArticle';
import { FeedCardAudio } from './FeedCardAudio';
import { FeedCardEditorial } from './FeedCardEditorial';
import { FeedCardImage } from './FeedCardImage';
import { FeedCardPdf } from './FeedCardPdf';
import { FeedCardPost } from './FeedCardPost';
import { FeedCardSocial } from './FeedCardSocial';
import { FeedCardThread } from './FeedCardThread';
import { FeedCardVideo } from './FeedCardVideo';
import { FeedCardWrapper } from './FeedCardWrapper';

export const FeedCard: FunctionComponent<FeedCardProps> = memo((props) => {
  const card = useMemo(() => {
    switch (props.card.type) {
      case StoryCardType.THREAD:
        return <FeedCardThread {...props} />;
      case StoryCardType.IMAGE:
        return <FeedCardImage {...props} />;
      case StoryCardType.EDITORIAL:
        return <FeedCardEditorial {...props} />;
      case StoryCardType.AUDIO:
        return <FeedCardAudio {...props} />;
      case StoryCardType.VIDEO:
        return <FeedCardVideo {...props} />;
      case StoryCardType.PDF:
        return <FeedCardPdf {...props} />;
      case StoryCardType.QUOTE:
        return <FeedCardSocial {...props} />;
      case StoryCardType.ARTICLE:
        return <FeedCardArticle {...props} />;
      case StoryCardType.POST:
        return <FeedCardPost {...props} />;
      default:
        return null;
    }
  }, [props]);

  return <FeedCardWrapper {...props}>{card}</FeedCardWrapper>;
});
