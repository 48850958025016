import { isAfter, isThisMonth, isThisWeek } from 'date-fns';
import {
  Fragment,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigContext, DetailsModalContext, UserContext } from '../../../../context';
import {
  NotificationComment,
  NotificationDefault,
  NotificationMention,
  NotificationType,
  StoryCardArticleContent,
  StoryCardQuoteContent,
} from '../../../../services';
import { getChannelNotifications, loadMoreChannelNotifications } from '../../../../slices';
import { NOTIFICATIONS_STORAGE_KEY } from '../../../constants';
import { useAnalytics, useAppDispatch, useAppSelector } from '../../../hooks';
import { getCssVar, layoutPath } from '../../../utils';
import { Popup } from '../../Popup';
import { TabItem, Tabs } from '../../Tabs';
import { NotificationCommentContent } from '../NotificationCommentContent';
import { NotificationDefaultContent } from '../NotificationDefaultContent';
import { NotificationMentionContent } from '../NotificationMentionContent';
import { NotificationWrapper } from '../NotificationWrapper';

import classNames from 'classnames';
import classes from './NotificationsCommon.module.scss';

export enum NotificationsTabType {
  VIEW_ALL,
  FOLLOWING,
  GENERAL,
}

interface RenderNotificationArgs {
  type: NotificationType;
  notification: NotificationDefault | NotificationComment | NotificationMention;
}

export const NotificationsCommon: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  const { logPushOpen } = useAnalytics();

  const { channelNotifications, page, hasNextPage, isFetching } = useAppSelector(
    ({ channelNotifications }) => channelNotifications
  );

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { setDetailsModalData } = useContext(DetailsModalContext);

  const { config } = useContext(ConfigContext);

  const { defaultLogoUrl } = config.elements.notifications;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [newTopNotificationTime, setNewTopNotificationTime] = useState<string | undefined>(
    undefined
  );

  const storageKey = useMemo(() => `${NOTIFICATIONS_STORAGE_KEY}_${channelId}`, [channelId]);

  const type = useMemo(() => {
    switch (selectedTabIndex) {
      case NotificationsTabType.GENERAL:
        return [NotificationType.DEFAULT];
      case NotificationsTabType.FOLLOWING:
        return [NotificationType.COMMENT, NotificationType.STORY_CARD_MENTION];
      default:
        return [
          NotificationType.DEFAULT,
          NotificationType.COMMENT,
          NotificationType.STORY_CARD_MENTION,
        ];
    }
  }, [selectedTabIndex]);

  useEffect(() => {
    setIsOpen(false);
    setDetailsModalData({ storyId: null, cardId: null });
  }, [location, setDetailsModalData]);

  const getFirstPageNotifications = useCallback(async () => {
    const { items } = await dispatch(getChannelNotifications({ channelId, type })).unwrap();

    const topNotification = items[0];

    if (
      topNotification &&
      typeof newTopNotificationTime === 'undefined' &&
      selectedTabIndex === 0
    ) {
      const { deliveredTime: topNotificationTime } = topNotification;
      const lastTopNotificationTime = localStorage.getItem(storageKey);

      if (
        !lastTopNotificationTime ||
        isAfter(new Date(topNotificationTime), new Date(lastTopNotificationTime))
      ) {
        setNewTopNotificationTime(topNotificationTime);
      }
    }
  }, [channelId, dispatch, newTopNotificationTime, selectedTabIndex, storageKey, type]);

  const loadMore = useCallback(() => {
    dispatch(loadMoreChannelNotifications({ channelId, type, page: page + 1 }));
  }, [channelId, dispatch, page, type]);

  const iconClickHandler = useCallback(() => {
    setIsOpen(!isOpen);

    if (newTopNotificationTime) {
      localStorage.setItem(storageKey, newTopNotificationTime);
      setNewTopNotificationTime('');
    }
  }, [isOpen, newTopNotificationTime, storageKey]);

  useEffect(() => {
    getFirstPageNotifications();
  }, [getFirstPageNotifications, selectedTabIndex]);

  const logOpen = useCallback(
    (notification_id: number) =>
      logPushOpen({ channel_id: channelId, notification_id, time_stamp: new Date().toISOString() }),
    [channelId, logPushOpen]
  );

  const renderNotificationDefault = useCallback(
    (notification: NotificationDefault) => {
      const { id, title, url, story, storyCard, message, deliveredTime } = notification;

      const hasLink = Boolean(url || (story && storyCard));

      const onClickHandler = () => {
        if (!hasLink) {
          return;
        }

        if (url) {
          window.open(url, '_blank', 'noreferrer');
        } else if (story && storyCard) {
          const { id: storyId, channelId: notificationChannelId } = story;
          const { id: cardId, content } = storyCard;

          if (notificationChannelId === channelId) {
            if (isMobileOnly) {
              navigate(layoutPath(`/details/${storyId}/${cardId}`));
            } else {
              setDetailsModalData({ storyId, cardId });
              setIsOpen(false);
            }
            return;
          }

          const { url } = content as StoryCardArticleContent | StoryCardQuoteContent;

          window.open(url, '_blank', 'noreferrer');
        }

        logOpen(id);

        setIsOpen(false);
      };

      return (
        <NotificationWrapper
          key={id}
          hasLink={hasLink}
          avatarUrl={defaultLogoUrl}
          deliveredTime={deliveredTime}
          onClick={onClickHandler}
        >
          <NotificationDefaultContent title={title} message={message} hasLink={hasLink} />
        </NotificationWrapper>
      );
    },
    [channelId, defaultLogoUrl, logOpen, navigate, setDetailsModalData]
  );

  const renderNotificationComment = useCallback(
    (notification: NotificationComment) => {
      const { id, deliveredTime, action, comment, parentComment, author } = notification;

      const { story, storyCard } = comment;

      const onClickHandler = () => {
        if (story && storyCard) {
          const { id: storyId } = story;
          const { id: cardId } = storyCard;

          isMobileOnly
            ? navigate(layoutPath(`/details/${storyId}/${cardId}?scrollToComments=true`))
            : setDetailsModalData({ storyId, cardId, scrollToComments: true });

          logOpen(id);
        }

        setIsOpen(false);
      };

      const { id: userId, avatar } = author;

      return (
        <NotificationWrapper
          key={id}
          userId={userId}
          avatarUrl={avatar?.url}
          deliveredTime={deliveredTime}
          onClick={onClickHandler}
          hasLink
        >
          <NotificationCommentContent
            commentAction={action}
            comment={comment}
            parentComment={parentComment}
            author={author}
          />
        </NotificationWrapper>
      );
    },
    [logOpen, navigate, setDetailsModalData]
  );

  const renderNotificationMention = useCallback(
    (notification: NotificationMention) => {
      const { id, deliveredTime, author, story, storyCard } = notification;

      const onClickHandler = () => {
        if (story && storyCard) {
          const { id: storyId } = story;
          const { id: cardId } = storyCard;

          isMobileOnly
            ? navigate(layoutPath(`/details/${storyId}/${cardId}`))
            : setDetailsModalData({ storyId, cardId });

          logOpen(id);
        }

        setIsOpen(false);
      };

      const { id: userId, avatar, screenName } = author;

      return (
        <NotificationWrapper
          key={id}
          userId={userId}
          avatarUrl={avatar?.url}
          deliveredTime={deliveredTime}
          onClick={onClickHandler}
          hasLink
        >
          <NotificationMentionContent
            authorName={screenName}
            categoryName={storyCard?.category?.name ?? ''}
          />
        </NotificationWrapper>
      );
    },
    [logOpen, navigate, setDetailsModalData]
  );

  const getGroupLabel = useCallback(
    (time: string) => {
      switch (true) {
        case isThisWeek(new Date(time), { weekStartsOn: 1 }):
          return t('channelNotifications.this-week');
        case isThisMonth(new Date(time)):
          return t('channelNotifications.this-month');
        default:
          return t('channelNotifications.earlier');
      }
    },
    [t]
  );

  const renderNotification = useCallback(
    ({ type, notification }: RenderNotificationArgs) => {
      switch (type) {
        case NotificationType.DEFAULT:
          return renderNotificationDefault(notification as NotificationDefault);
        case NotificationType.COMMENT:
          return renderNotificationComment(notification as NotificationComment);
        case NotificationType.STORY_CARD_MENTION:
          return renderNotificationMention(notification as NotificationMention);
        default:
          return null;
      }
    },
    [renderNotificationComment, renderNotificationDefault, renderNotificationMention]
  );

  const renderNotifications = useMemo(() => {
    return (
      <>
        {channelNotifications.map((notification, index, notificationsArray) => {
          const { id, type, deliveredTime } = notification;

          return (
            <Fragment key={id}>
              {getGroupLabel(notificationsArray[index - 1]?.deliveredTime) !==
                getGroupLabel(deliveredTime) && (
                <div className={classes['notifications__group-label']}>
                  {getGroupLabel(deliveredTime)}
                </div>
              )}
              {renderNotification({ type, notification })}
            </Fragment>
          );
        })}
      </>
    );
  }, [channelNotifications, getGroupLabel, renderNotification]);

  const loader = useMemo(() => <Skeleton className={classes['notifications__loader']} />, []);

  const tabContent = useMemo(() => {
    if (isFetching) {
      return loader;
    }

    if (!isFetching && !channelNotifications.length) {
      return (
        <div className={classes['notifications__no-content']}>
          {t('channelNotifications.no-content')}
        </div>
      );
    }

    return (
      <InfiniteScroll
        className={classes['notifications__infinite-scroll']}
        height={'30rem'} //needed for infinite scroll inside modal
        next={loadMore}
        hasMore={hasNextPage}
        loader={loader}
        dataLength={channelNotifications.length}
      >
        {renderNotifications}
      </InfiniteScroll>
    );
  }, [
    channelNotifications.length,
    hasNextPage,
    isFetching,
    loadMore,
    loader,
    renderNotifications,
    t,
  ]);

  const tabItems: TabItem[] = useMemo(() => {
    return Object.values(NotificationsTabType)
      .filter((values) => typeof values === 'string')
      .map((value) => {
        return {
          index: NotificationsTabType[value as keyof typeof NotificationsTabType],
          name: t(`channelNotificationsTabs.${value}`),
          content: tabContent,
        };
      });
  }, [t, tabContent]);

  const popupBody = useMemo(() => {
    return (
      <div
        className={classNames(classes['notifications'], {
          [classes['notifications--mobile']]: isMobileOnly,
        })}
      >
        <div className={classes['notifications__header']}>
          <span className={classes['notifications__header-title']}>
            {t('channelNotifications.title')}
          </span>
        </div>
        <Tabs
          items={tabItems}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          noBackground
        />
      </div>
    );
  }, [selectedTabIndex, t, tabItems]);

  return (
    <Popup
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      iconId={'bell'}
      color={getCssVar('--notifications-icon-color')}
      hoverColor={getCssVar('--notifications-icon-hover-color')}
      onClick={iconClickHandler}
      bodyTop={'2rem'}
      bodyRight={'0'}
      body={popupBody}
      className={classNames({
        [classes['notifications__icon--has-new']]: Boolean(newTopNotificationTime),
      })}
    />
  );
};
