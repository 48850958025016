import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { FunctionComponent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import {
  Chat,
  ChatAccess,
  ChatTypes,
  StoryCard,
  storyCardApi,
  useGetChannelChatsQuery,
} from '../../../../../services';
import {
  CircularLoader,
  EmojiSelectProps,
  getCssVar,
  getEmojiPickerLocale,
  useDebounce,
  useLoaderText,
  useOnClickOutside,
} from '../../../../../shared';
import { Avatar } from '../../../Avatar';
import { Check } from '../../../Check';
import { IconLabel } from '../../../IconLabel';
import { InputField } from '../../../InputField';
import { Modal } from '../../../Modal';
import { Tabs } from '../../../Tabs';
import { TextAreaField } from '../../../TextAreaField';

import classes from './ForwardToChatModal.module.scss';

interface ForwardToChatModalProps {
  isOpen: boolean;
  onClose: () => void;
  storyCard: StoryCard;
}

enum ChatTabsTypes {
  direct,
  public,
}

export const ForwardToChatModal: FunctionComponent<ForwardToChatModalProps> = memo(
  ({ isOpen, onClose, storyCard }) => {
    const { t } = useTranslation();

    const [storyCardPostInChatAsCopy] =
      storyCardApi.endpoints.storyCardPostInChatAsCopy.useLazyQuery();

    const { data: channelChatsData, isFetching } = useGetChannelChatsQuery();

    const [chatsFiltered, setChatsFiltered] = useState<Chat[]>([]);

    const [search, setSearch] = useState<string>('');

    const debouncedSearch = useDebounce<string>(search);

    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(ChatTabsTypes.direct);

    const [selectedChatIds, setSelectedChatIds] = useState<number[]>([]);

    const [message, setMessage] = useState<string>('');

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const emojiPickerRef = useRef(null);

    const [posting, setPosting] = useState(false);

    const { loaderText } = useLoaderText(posting);

    const hasSelectedChats = useMemo(
      () => Boolean(selectedChatIds.length),
      [selectedChatIds.length]
    );

    useOnClickOutside(emojiPickerRef, () => {
      setShowEmojiPicker(false);
    });

    const onEmojiSelect = useCallback(
      ({ native }: EmojiSelectProps) => setMessage(`${message}${native}`),
      [message, setMessage]
    );

    const chatType = useMemo(() => {
      switch (selectedTabIndex) {
        case ChatTabsTypes.direct:
          return ChatTypes.p2p;
        case ChatTabsTypes.public:
          return ChatTypes.group;
        default:
          return;
      }
    }, [selectedTabIndex]);

    useEffect(() => {
      if (!data) {
        return;
      }

      const { chats = [] } = { ...channelChatsData };

      setChatsFiltered(
        chats.filter(
          ({ name, type, access }) =>
            name.toLowerCase().startsWith(search.toLowerCase()) &&
            [ChatAccess.admin, ChatAccess.rw].includes(access as ChatAccess) &&
            chatType === type
        )
      );
    }, [channelChatsData, chatType, debouncedSearch, search]);

    const onChatSelect = useCallback(
      (id: number) => {
        const index = selectedChatIds.findIndex((selectedChatId) => selectedChatId === id);

        if (index === -1) {
          setSelectedChatIds([...selectedChatIds, id]);
          return;
        }

        const selectedIdsCopy = [...selectedChatIds];

        selectedIdsCopy.splice(index, 1);

        setSelectedChatIds(selectedIdsCopy);
      },
      [selectedChatIds]
    );

    const chatListItem = useCallback(
      (chat: Chat) => {
        const { chatId, type, name, image, thumbnails } = chat;

        const isP2P = type === ChatTypes.p2p;

        const avatarUrl = isP2P ? thumbnails[0].image : image?.url;

        const avatarFallBackIconId = isP2P ? 'avatar' : 'avatars';

        const checked = selectedChatIds.includes(chatId);

        return (
          <div key={chatId} className={classes['forward-to-chat__list-item']}>
            <Check checked={checked} onChange={() => onChatSelect(chatId)} />
            <Avatar size={48} url={avatarUrl} fallBackIconId={avatarFallBackIconId} nonClickable />
            <div className={classes['forward-to-chat__list-item-name']}>{name}</div>
          </div>
        );
      },
      [onChatSelect, selectedChatIds]
    );

    const tabContent = useMemo(() => {
      if (!chatsFiltered.length) {
        return (
          <IconLabel
            iconSize={40}
            iconId={'search-off'}
            label={t('common.nothing-found')}
            className={classes['forward-to-chat__no-data']}
            labelClassName={classes['forward-to-chat__no-data-label']}
            singleColor
            nonClickable
          />
        );
      }

      return (
        <div className={classes['forward-to-chat__list']}>
          {chatsFiltered.map((chat) => chatListItem(chat))}
        </div>
      );
    }, [chatListItem, chatsFiltered, t]);

    const tabItems = useMemo(
      () =>
        Object.values(ChatTypes).map((value, index) => {
          return {
            index,
            name: t(`chatTypes.${value}`),
            content: tabContent,
          };
        }),
      [t, tabContent]
    );

    const onSubmit = useCallback(async () => {
      if (!selectedChatIds.length) {
        return;
      }

      setPosting(true);

      const { id: storyCardId, storyId } = storyCard;

      try {
        for (const chatId of selectedChatIds) {
          await storyCardPostInChatAsCopy({
            chatId,
            sourceStoryId: storyId,
            sourceStoryCardId: storyCardId,
            chatComment: message,
          });
        }

        toast.success(t('forwardToChat.success-label'));
      } catch (_) {
        toast.error(t('forwardToChat.error-label'));
      } finally {
        setPosting(false);
      }

      onClose();
    }, [message, onClose, selectedChatIds, storyCard, storyCardPostInChatAsCopy, t]);

    const onInputKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const { key, shiftKey, target } = event;

        if (key === 'Enter' && !shiftKey && hasSelectedChats) {
          event.preventDefault();
          onSubmit();

          (target as HTMLTextAreaElement).blur();
        }
      },
      [hasSelectedChats, onSubmit]
    );

    const sendComponent = useMemo(() => {
      if (posting) {
        return (
          <CircularLoader
            sizeRem={1.25}
            className={classes['forward-to-chat__input-icons-loader']}
            text={loaderText}
          />
        );
      }

      return (
        <IconLabel
          iconId={'send'}
          label={t('common.send')}
          iconSize={20}
          className={classes['forward-to-chat__input-icons-send']}
          color={getCssVar('--chats-input-send-icon-color')}
          hoverColor={getCssVar('--chats-input-send-icon-hover-color')}
          onClick={onSubmit}
          disabled={!hasSelectedChats}
          labelFirst
        />
      );
    }, [hasSelectedChats, loaderText, onSubmit, posting, t]);

    const body = useMemo(() => {
      if (isFetching) {
        return <Skeleton height={'5rem'} />;
      }

      return (
        <div className={classes['forward-to-chat']}>
          <div className={classes['forward-to-chat__title']}>{t('forwardToChat.title')}</div>
          <InputField
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            placeholder={t('common.search')}
          />
          <Tabs
            items={tabItems}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            noBackground
          />

          <div className={classes['forward-to-chat__input-wrapper']}>
            <TextAreaField
              inputClassName={classes['forward-to-chat__input']}
              placeholder={t('chats.input-placeholder')}
              value={message}
              onChange={({ target }) => setMessage(target.value)}
              onKeyDown={onInputKeyDown}
            />

            <div className={classes['forward-to-chat__input-icons']}>
              <IconLabel
                iconId={'mood'}
                iconSize={20}
                color={getCssVar('--chats-input-icon-color')}
                hoverColor={getCssVar('--chats-input-icon-hover-color')}
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              />

              {sendComponent}
            </div>
          </div>

          {showEmojiPicker && (
            <div ref={emojiPickerRef} className={classes['forward-to-chat__emoji-picker']}>
              <Picker
                data={data}
                locale={getEmojiPickerLocale()}
                onEmojiSelect={onEmojiSelect}
                maxFrequentRows={3}
                perLine={10}
                emojiSize={18}
                previewPosition={'none'}
                searchPosition={'static'}
                navPosition={'bottom'}
              />
            </div>
          )}
        </div>
      );
    }, [
      isFetching,
      message,
      onEmojiSelect,
      onInputKeyDown,
      search,
      selectedTabIndex,
      sendComponent,
      showEmojiPicker,
      t,
      tabItems,
    ]);

    return <Modal isOpen={isOpen} body={body} onClose={onClose} keepOpened={posting} alignTop />;
  }
);
