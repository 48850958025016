import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { Keyboard, Navigation, Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { Copyright } from '../Copyright';
import { Modal } from '../Modal';
import { ImageSingle } from './ImageSingle';

import classNames from 'classnames';
import classes from './ImageGallery.module.scss';

export interface ImageGalleryItem {
  caption?: string;
  image: {
    url?: string;
    rightholder?: string;
    averageColor?: string;
  };
}

interface ImageGalleryProps {
  items: ImageGalleryItem[];
  captionClass?: string;
  singleImageFullSize?: boolean;
  singleImageMaxHeight?: string;
  modalPreviewOnClick?: boolean;
  onLogClickOpenArticle?: () => void;
}

export const ImageGallery: FunctionComponent<ImageGalleryProps> = memo(
  ({
    items,
    captionClass,
    singleImageFullSize = false,
    singleImageMaxHeight,
    modalPreviewOnClick = false,
    onLogClickOpenArticle,
  }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [swiped, setSwiped] = useState(false);
    const [isModalPreviewOpened, setIsModalPreviewOpened] = useState(false);
    const [swiper, setSwiper] = useState<SwiperClass | null>(null);

    const swiperCommonOptions: SwiperOptions = useMemo(() => {
      return {
        navigation: true,
        pagination: true,
        keyboard: { enabled: true, onlyInViewport: true },
        modules: [Navigation, Pagination, Keyboard],
      };
    }, []);

    const modalPreviewBody = useMemo(
      () => (
        <Swiper
          {...swiperCommonOptions}
          initialSlide={activeIndex}
          onSlideChange={({ activeIndex }) => {
            swiper?.slideTo(activeIndex);
          }}
        >
          {items.map(({ image }, index) => {
            const { url } = image;

            return (
              <SwiperSlide
                key={`${url}-${index}`}
                className={classes['image-gallery__modal-swiper-slide']}
              >
                <img
                  className={classes['image-gallery__modal-preview']}
                  src={url}
                  alt={'modal-preview'}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      ),
      [activeIndex, items, swiper, swiperCommonOptions]
    );

    const modalPreviewOnClickHandler = useCallback(() => {
      onLogClickOpenArticle?.();
      setIsModalPreviewOpened(true);
    }, [onLogClickOpenArticle]);

    if (!items.length || !items.filter(({ image }) => Boolean(image.url)).length) {
      return null;
    }

    if (items.length > 1) {
      return (
        <>
          <div className={classes['image-gallery']}>
            <div className={classes['image-gallery__swiper']}>
              <Swiper
                {...swiperCommonOptions}
                onSwiper={setSwiper}
                onSlideChange={({ activeIndex }) => {
                  if (!swiped) {
                    onLogClickOpenArticle?.();
                    setSwiped(true);
                  }
                  setActiveIndex(activeIndex);
                }}
              >
                {items.map(({ image }, index) => {
                  const { url } = image;

                  return (
                    <SwiperSlide
                      key={`${url}-${index}`}
                      onClick={() => modalPreviewOnClick && modalPreviewOnClickHandler()}
                      className={classNames({
                        [classes['image-gallery__swiper-clickable-slide']]: modalPreviewOnClick,
                      })}
                    >
                      <div
                        className={classes['image-gallery__background']}
                        style={{ backgroundImage: `url(${url})` }}
                      ></div>
                      <img
                        className={classes['image-gallery__preview']}
                        src={url}
                        alt={'preview'}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              {items[activeIndex] && items[activeIndex].image.rightholder && (
                <Copyright copyright={items[activeIndex].image.rightholder} />
              )}
            </div>

            {items[activeIndex] && items[activeIndex].caption && (
              <div
                className={classNames(classes['image-gallery__caption'], captionClass)}
                dangerouslySetInnerHTML={{
                  __html:
                    items[activeIndex].caption?.replace(/href/g, "target='_blank' href") ?? '',
                }}
              ></div>
            )}
          </div>

          {modalPreviewOnClick && (
            <Modal
              isOpen={isModalPreviewOpened}
              body={modalPreviewBody}
              onClose={() => setIsModalPreviewOpened(false)}
              previewSrc={items[activeIndex].image.url}
              root={'modal-preview-root'}
              previewMode
            />
          )}
        </>
      );
    }

    if (!items[0].image.url) {
      return null;
    }

    return (
      <ImageSingle
        item={items[0]}
        captionClass={captionClass}
        fullSize={singleImageFullSize}
        maxHeight={singleImageMaxHeight}
        modalPreviewOnClick={modalPreviewOnClick}
        onLogClickOpenArticle={onLogClickOpenArticle}
      />
    );
  }
);
