import { FunctionComponent, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DetailsModalContext, UserContext } from '../../../context';
import { ConfigLink } from '../../../services';
import { openStoryCardDetails } from '../../utils';
import { IconLabel, IconLabelSizes } from '../IconLabel';

interface ConfigurationLinkProps {
  link: ConfigLink;
  className?: string;
  labelClassName?: string;
  labelSize?: IconLabelSizes;
  singleColor?: boolean;
  customClickHandler?: () => void;
  clickCallback?: () => void;
}

export const ConfigurationLink: FunctionComponent<ConfigurationLinkProps> = memo(
  ({
    link,
    className,
    labelClassName,
    labelSize = IconLabelSizes.medium,
    singleColor,
    customClickHandler,
    clickCallback,
  }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { channelId } = useContext(UserContext).userInfo.userData;

    const { setDetailsModalData } = useContext(DetailsModalContext);

    const { title, localiseKey, iconUrl, iconSize, cardDetail, url, color, hoverColor, mailto } =
      link;

    const onClickHandler = useCallback(() => {
      if (url) {
        if (url.includes(`/apps/posts/${channelId}/`)) {
          const [storyId, cardId] = url.split(`/apps/posts/${channelId}/`)[1].split('/');
          setDetailsModalData({ storyId: parseInt(storyId, 10), cardId: parseInt(cardId, 10) });
        } else {
          window.open(url, '_blank', 'noreferrer');
        }
      } else if (cardDetail) {
        openStoryCardDetails({ navigate, ...cardDetail });
      } else if (mailto) {
        const mail = document.createElement('a');
        mail.href = `mailto:${mailto}`;
        mail.click();
      } else if (typeof customClickHandler !== 'undefined') {
        customClickHandler?.();
      }

      clickCallback?.();
    }, [
      cardDetail,
      channelId,
      clickCallback,
      customClickHandler,
      mailto,
      navigate,
      setDetailsModalData,
      url,
    ]);

    return (
      <IconLabel
        iconUrl={iconUrl}
        label={localiseKey ? t(`${localiseKey}`) : title}
        color={color}
        hoverColor={hoverColor}
        iconSize={iconSize}
        labelSize={labelSize}
        className={className}
        labelClassName={labelClassName}
        singleColor={singleColor}
        onClick={onClickHandler}
      />
    );
  }
);
