import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { storyCardApi, StoryCardCategory, StoryCardCategoryTemplate } from '../../../services';
import {
  Button,
  ButtonType,
  Check,
  ConfirmationModal,
  getCssVar,
  IconLabel,
} from '../../../shared';
import { CreateCategoryModal } from './CreateCategoryModal';

import classes from './Category.module.scss';

const CATEGORIES_LIMIT = 10;

export const Category: FunctionComponent = () => {
  const { t } = useTranslation();

  const [storyCardCategories] = storyCardApi.endpoints.storyCardCategories.useLazyQuery();

  const [categoryDelete] = storyCardApi.endpoints.storyCardCategoryDelete.useLazyQuery();

  const [fetchingCategories, setFetchingCategories] = useState<boolean>(true);

  const [categories, setCategories] = useState<StoryCardCategory[]>([]);

  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState<boolean>(false);

  const [deleteCategoryId, setDeleteCategoryId] = useState<number | null>(null);

  const [editCategoryId, setEditCategoryId] = useState<number | null>(null);

  const [forceDelete, setForceDelete] = useState<boolean>(false);

  const [templates, setTemplates] = useState<StoryCardCategoryTemplate[]>([]);

  const getCategories = useCallback(async () => {
    setFetchingCategories(true);
    setCategories(await storyCardCategories().unwrap());
    setFetchingCategories(false);
  }, [storyCardCategories]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const content = useMemo(() => {
    if (fetchingCategories) {
      return <Skeleton height={'5rem'} />;
    }

    if (!categories.length) {
      return (
        <div className={classes['category__no-content']}>{t('storyCardCategory.no-content')}</div>
      );
    }

    return (
      <table className={classes['category__list']}>
        <thead>
          <tr>
            <th>{t('storyCardCategoryList.title')}</th>
            <th>{t('storyCardCategoryList.templates')}</th>
            <th>{t('storyCardCategoryList.user-selection')}</th>
            <th>{t('storyCardCategoryList.notifications')}</th>
            <th>{t('storyCardCategoryList.assigned-to-story')}</th>
            <th>{t('storyCardCategoryList.channel')}</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(({ id, name, channel, settings, templates, story }) => {
            return (
              <tr key={id}>
                <td>{name}</td>
                <td>{templates.length}</td>
                <td>{t(`common.${settings.userSelection ? 'yes' : 'no'}`)}</td>
                <td>{t(`common.${settings.emailNotification ? 'yes' : 'no'}`)}</td>
                <td>{story?.title}</td>
                <td>{channel?.name}</td>
                <td>
                  <IconLabel
                    iconId={'edit'}
                    iconSize={18}
                    color={getCssVar('--base-link-text-color')}
                    hoverColor={getCssVar('--base-link-text-hover-color')}
                    onClick={() => setEditCategoryId(id)}
                  />
                </td>
                <td>
                  <IconLabel
                    iconId={'delete'}
                    iconSize={20}
                    color={getCssVar('--color-danger')}
                    hoverColor={getCssVar('--color-danger-hover')}
                    onClick={() => setDeleteCategoryId(id)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }, [categories, fetchingCategories, t]);

  const onDeleteCategoryModalClose = useCallback(() => {
    setDeleteCategoryId(null);
    setForceDelete(false);
  }, []);

  const onCategoryDelete = useCallback(async () => {
    if (!deleteCategoryId) {
      return;
    }

    await categoryDelete({ categoryId: deleteCategoryId, forceDelete });

    onDeleteCategoryModalClose();

    getCategories();
  }, [categoryDelete, deleteCategoryId, forceDelete, getCategories, onDeleteCategoryModalClose]);

  return (
    <>
      <div className={classes['category']}>
        <div className={classes['category__intro']}>{t('storyCardCategory.intro')}</div>
        <div className={classes['category__header']}>
          <div className={classes['category__header-title']}>{t('storyCardCategory.title')}</div>
          <Button
            type={ButtonType.primary}
            label={t('storyCardCategory.new-category')}
            onClick={() => setIsCreateCategoryModalOpen(true)}
            disabled={fetchingCategories || categories.length >= CATEGORIES_LIMIT}
          />
        </div>
        {content}
      </div>

      <CreateCategoryModal
        isOpen={isCreateCategoryModalOpen}
        setIsOpen={setIsCreateCategoryModalOpen}
        templates={templates}
        setTemplates={setTemplates}
        categories={categories}
        editCategoryId={editCategoryId}
        setEditCategoryId={setEditCategoryId}
        submitCallback={getCategories}
      />

      <ConfirmationModal
        isOpen={Boolean(deleteCategoryId)}
        onClose={onDeleteCategoryModalClose}
        acceptLabel={t('common.delete')}
        onAccept={onCategoryDelete}
        title={t('storyCardCategory.delete-confirmation-title')}
        subTitle={
          <div className={classes['category-removal']}>
            <div>{t('storyCardCategory.delete-confirmation-sub-title')}</div>
            <Check
              checked={forceDelete}
              label={t('storyCardCategory.delete-check-label')}
              onChange={setForceDelete}
            />
          </div>
        }
        danger
      />
    </>
  );
};
