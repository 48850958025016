import { ButtonLinkType } from '../pages';
import { ImageFile, OrderDirection, PaginationInfo } from '../shared';
import { graphqlApi } from './graphqlApi';
import { Story } from './story';
import { StoryCard } from './storyCard';
import { User } from './user';

export enum NotificationCommentAction {
  COMMENT = 'COMMENT',
  REPLY = 'REPLY',
  LIKE = 'LIKE',
  MENTION_IN_CARD = 'MENTION_IN_CARD',
}

export enum NotificationType {
  DEFAULT = 'DEFAULT',
  COMMENT = 'COMMENT',
  STORY_CARD_MENTION = 'STORY_CARD_MENTION',
}

export interface NotificationCommentItem {
  id: number;
  content: string;
  contentUpdatedAt: string;
  createdAt: string;
  authorId: number;
  author: User;
  story: Story;
  storyCard: StoryCard;
}

interface Notification {
  id: number;
  type: NotificationType;
  deliveredTime: string;
}

export interface NotificationDefault extends Notification {
  title: string;
  message: string;
  story: Story | null;
  storyCard: StoryCard | null;
  createdTime: string;
  expirationTime: string;
  permissions: { allowToDelete: boolean; allowToUpdate: boolean };
  statistic: { open: number; sent: number };
  channelId?: number;
  url?: string;
}

export interface NotificationComment extends Notification {
  action: NotificationCommentAction;
  comment: NotificationCommentItem;
  parentComment: NotificationCommentItem;
  author: User;
}

export interface NotificationMention extends Notification {
  author: User;
  story?: Story;
  storyCard?: StoryCard;
}

enum InAppMessageLayout {
  CARD = 'CARD',
}

export interface InAppMessage {
  id: number;
  title: string;
  intro: string;
  created: string;
  delivery: string;
  expire: string;
  bgColor: string;
  layout: string;
  textColor: string;
  primary: {
    action: string;
    bgColor: string;
    text: string;
    textColor: string;
  };
  permissions: {
    allowToDelete: boolean;
  };
  statistic: {
    open: number;
    sent: number;
  };
  channelId?: number;
  image?: ImageFile;
  imageId?: number;
}

export interface ChannelNotificationsArgs {
  channelId: number;
  type: NotificationType[];
  page?: number;
  size?: number;
}

interface ChannelNotificationsPayload {
  items: (NotificationDefault | NotificationComment | NotificationMention)[];
  pageInfo: PaginationInfo;
}

interface ChannelNotificationsResponse {
  data: {
    channelNotifications: ChannelNotificationsPayload;
  };
  errors?: [{ message: string }];
}

export interface PushNotificationsArgs {
  channelId: number;
  filters?: { query: string };
  orderDirection?: OrderDirection;
  page?: number;
  size?: number;
}

interface PushNotificationsPayload {
  items: NotificationDefault[];
  pageInfo: PaginationInfo;
}

interface PushNotificationsResponse {
  data: {
    pushNotifications: PushNotificationsPayload;
  };
  errors?: [{ message: string }];
}

export interface PushNotificationDeleteArgs {
  notificationId: number;
}

interface PushNotificationDeleteResponse {
  data: {
    pushNotificationDelete: {
      payload: boolean;
    };
  };
}

export interface PushNotificationChannelCreateArgs {
  channelId: number;
  title: string;
  message: string;
  deliveryTime?: string;
  url?: string;
  storyId?: number;
  itemId?: number;
}

interface PushNotificationChannelCreateResponse {
  data: {
    pushNotificationChannelCreate: {
      payload: NotificationDefault;
    };
  };
}

export interface PushNotificationOrganisationCreateArgs {
  title: string;
  message: string;
  deliveryTime?: string;
  url?: string;
}

interface PushNotificationOrganisationCreateResponse {
  data: {
    pushNotificationOrganisationCreate: {
      payload: NotificationDefault;
    };
  };
}

export interface InAppMessagesArgs {
  channelId: number;
  filters?: { query: string };
  orderDirection?: OrderDirection;
  page?: number;
  size?: number;
}

interface InAppMessagesPayload {
  items: InAppMessage[];
  pageInfo: PaginationInfo;
}

interface InAppMessagesResponse {
  data: {
    inAppMessages: InAppMessagesPayload;
  };
  errors?: [{ message: string }];
}

export interface InAppMessageDeleteArgs {
  inAppMessageId: number;
}

interface InAppMessageDeleteResponse {
  data: {
    inAppMessageDelete: {
      payload: boolean;
    };
  };
}

export interface InAppMessageChannelCreateArgs {
  channelId: number;
  title: string;
  intro: string;
  textColor: string;
  bgColor: string;
  expirationHours: number;
  buttonLinkType: string;
  buttonText: string;
  buttonTextColor: string;
  buttonBackgroundColor: string;
  deliveryTime?: string;
  imageId?: number;
  url?: string;
  avatar?: boolean;
  position?: string[];
  department?: string[];
  roles?: number[];
}

interface InAppMessageChannelCreateResponse {
  data: {
    inAppMessageChannelCreate: {
      payload: InAppMessage;
    };
  };
}

export const notificationsApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    channelNotifications: builder.query<ChannelNotificationsPayload, ChannelNotificationsArgs>({
      query: ({ channelId, type, page = 1, size = 10 }) => ({
        url: '/graphql/webapp?channelNotifications',
        method: 'POST',
        body: {
          query: `query ChannelNotifications($channelId: Int!, $page: Int, $size: Int, $filter: GetChannelNotificationsFilterArgs) {
            channelNotifications(channelId: $channelId, page: $page, size: $size, filter: $filter) { 
              items {
                ... on NotificationDefault {
                  id
                  type
                  deliveredTime
                  title
                  message
                  url
                  story {
                    id
                    channelId
                  }
                  storyCard {
                    id
                    type
                    content {
                      ... on StoryCardQuoteContent {
                        url
                      }
                      ... on StoryCardArticleContent {
                        url
                      }
                    }
                  }
                }
                ... on NotificationComment {
                  id
                  type
                  deliveredTime
                  action
                  author {
                    id
                    screenName
                    avatar {
                      averageColor
                      externalId
                      externalType
                      id
                      originalFilename
                      rightholder
                      status
                      statusCopyrightId
                      thumb
                      url
                    }
                  }
                  comment {
                    id
                    content
                    story {
                      id
                    }
                    storyCard {
                      id
                      type
                    }
                  }
                  parentComment {
                    content
                  }
                }
                ... on NotificationStoryCardMention {
                  id
                  type
                  deliveredTime
                  author {
                    id
                    screenName
                    avatar {
                      averageColor
                      externalId
                      externalType
                      id
                      originalFilename
                      rightholder
                      status
                      statusCopyrightId
                      thumb
                      url
                    }
                  }
                  story {
                    id
                  }
                  storyCard {
                    id
                    category {
                      name
                    }
                  }
                }
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            channelId,
            filter: { type },
            page,
            size,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChannelNotificationsResponse) => {
        if (response?.errors?.length) {
          return {
            items: [],
            pageInfo: {
              page: 0,
              hasNextPage: false,
            } as PaginationInfo,
          };
        }
        return response.data.channelNotifications;
      },
    }),
    pushNotifications: builder.query<PushNotificationsPayload, PushNotificationsArgs>({
      query: ({
        channelId,
        filters,
        orderDirection = OrderDirection.DESC,
        page = 1,
        size = 10,
      }) => ({
        url: '/graphql/webapp?pushNotifications',
        method: 'POST',
        body: {
          query: `query PushNotifications($channelId: Int, $filters: GetPushNotificationsFilterArgs, $orderDirection: OrderDirectionEnum!, $page: Int, $size: Int) {
            pushNotifications(channelId: $channelId, filters: $filters, orderDirection: $orderDirection, page: $page, size: $size) { 
              items {
                id
                channelId
                title
                message
                url
                createdTime
                deliveredTime
                expirationTime
                statistic {
                  open
                  sent
                }
                permissions {
                  allowToDelete
                }
                storyCard {
                  storyId
                  id
                }
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            channelId,
            filters,
            orderDirection,
            page,
            size,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: PushNotificationsResponse) => {
        if (response?.errors?.length) {
          return {
            items: [],
            pageInfo: { page: 0, hasNextPage: false } as PaginationInfo,
          };
        }
        return response.data.pushNotifications;
      },
    }),
    pushNotificationDelete: builder.query<boolean, PushNotificationDeleteArgs>({
      query: ({ notificationId }) => ({
        url: '/graphql/webapp?pushNotificationDelete',
        method: 'POST',
        body: {
          query: `mutation PushNotificationDelete($input: PushNotificationDeleteArgs!) {
            pushNotificationDelete(input: $input) {
              payload
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: { input: { notificationId } },
        },
      }),
      transformResponse: (response: PushNotificationDeleteResponse) =>
        response.data.pushNotificationDelete.payload,
    }),
    pushNotificationChannelCreate: builder.query<
      NotificationDefault,
      PushNotificationChannelCreateArgs
    >({
      query: ({ channelId, title, message, url, deliveryTime, storyId, itemId }) => ({
        url: '/graphql/webapp?pushNotificationChannelCreate',
        method: 'POST',
        body: {
          query: `mutation PushNotificationChannelCreate($input: PushNotificationChannelCreateInput!) {
            pushNotificationChannelCreate(input: $input) {
              payload {
                id
              }
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: {
              channelId,
              fields: {
                title,
                message,
                ...(Boolean(url) && { url }),
                ...(Boolean(deliveryTime) && { deliveryTime }),
                ...(Boolean(storyId) && { storyId }),
                ...(Boolean(itemId) && { itemId }),
              },
            },
          },
        },
      }),
      transformResponse: (response: PushNotificationChannelCreateResponse) =>
        response.data.pushNotificationChannelCreate.payload,
    }),
    pushNotificationOrganisationCreate: builder.query<
      NotificationDefault,
      PushNotificationOrganisationCreateArgs
    >({
      query: ({ title, message, url, deliveryTime }) => ({
        url: '/graphql/webapp?pushNotificationOrganisationCreate',
        method: 'POST',
        body: {
          query: `mutation PushNotificationOrganisationCreate($input: PushNotificationOrganisationCreateInput!) {
            pushNotificationOrganisationCreate(input: $input) {
              payload {
                id
              }
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: {
              fields: {
                title,
                message,
                ...(Boolean(url) && { url }),
                ...(Boolean(deliveryTime) && { deliveryTime }),
              },
            },
          },
        },
      }),
      transformResponse: (response: PushNotificationOrganisationCreateResponse) =>
        response.data.pushNotificationOrganisationCreate.payload,
    }),
    inAppMessages: builder.query<InAppMessagesPayload, InAppMessagesArgs>({
      query: ({
        channelId,
        filters,
        orderDirection = OrderDirection.DESC,
        page = 1,
        size = 10,
      }) => ({
        url: '/graphql/webapp?inAppMessages',
        method: 'POST',
        body: {
          query: `query InAppMessages($channelId: Int, $filters: GetInAppMessagesFilterArgs, $orderDirection: OrderDirectionEnum!, $page: Int, $size: Int) {
            inAppMessages(channelId: $channelId, filters: $filters, orderDirection: $orderDirection, page: $page, size: $size) { 
              items {
                id
                title
                intro
                created
                delivery
                expire
                channelId
                permissions {
                  allowToDelete
                }
                statistic {
                  open
                  sent
                }
                primary {
                  action
                }
                image {
                  url
                }
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            channelId,
            filters,
            orderDirection,
            page,
            size,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: InAppMessagesResponse) => {
        if (response?.errors?.length) {
          return {
            items: [],
            pageInfo: { page: 0, hasNextPage: false } as PaginationInfo,
          };
        }
        return response.data.inAppMessages;
      },
    }),
    inAppMessageDelete: builder.query<boolean, InAppMessageDeleteArgs>({
      query: ({ inAppMessageId }) => ({
        url: '/graphql/webapp?inAppMessageDelete',
        method: 'POST',
        body: {
          query: `mutation InAppMessageDelete($input: InAppMessageDeleteArgs!) {
            inAppMessageDelete(input: $input) {
              payload
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: { inAppMessageId },
        },
      }),
      transformResponse: (response: InAppMessageDeleteResponse) =>
        response.data.inAppMessageDelete.payload,
    }),
    inAppMessageChannelCreate: builder.query<InAppMessage, InAppMessageChannelCreateArgs>({
      query: ({
        channelId,
        title,
        intro,
        textColor,
        bgColor,
        buttonLinkType,
        buttonText,
        buttonTextColor,
        buttonBackgroundColor,
        deliveryTime,
        expirationHours,
        imageId,
        url,
        avatar,
        roles,
        position,
        department,
      }) => ({
        url: '/graphql/webapp?inAppMessageChannelCreate',
        method: 'POST',
        body: {
          query: `mutation InAppMessageChannelCreate($input: InAppMessageChannelCreateInput!) {
            inAppMessageChannelCreate(input: $input) {
              payload {
                id
              }
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: {
              channelId,
              fields: {
                title,
                intro,
                textColor,
                bgColor,
                primary: {
                  text: buttonText,
                  textColor: buttonTextColor,
                  bgColor: buttonBackgroundColor,
                  action: {
                    ...(Boolean(buttonLinkType === ButtonLinkType.URL_BROWSER) && {
                      openExternalAction: { url },
                    }),
                    ...(Boolean(buttonLinkType === ButtonLinkType.URL_APP) && {
                      openUrlAction: { url },
                    }),
                    ...(Boolean(buttonLinkType === ButtonLinkType.SHARE) && {
                      shareAction: { url },
                    }),
                  },
                },
                expirationHours,
                layout: InAppMessageLayout.CARD,
                conditions: {
                  types: 'login',
                  ...(Boolean(typeof avatar !== 'undefined') && { avatar }),
                  ...(Boolean(roles) && { roles }),
                  ...(Boolean(position) && { position }),
                  ...(Boolean(department) && { department }),
                },
                ...(Boolean(imageId) && { imageId }),
                ...(Boolean(deliveryTime) && { deliveryTime }),
              },
            },
          },
        },
      }),
      transformResponse: (response: InAppMessageChannelCreateResponse) =>
        response.data.inAppMessageChannelCreate.payload,
    }),
  }),
});

export const {
  useChannelNotificationsQuery,
  usePushNotificationsQuery,
  usePushNotificationChannelCreateQuery,
} = notificationsApi;
