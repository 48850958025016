import { FunctionComponent, memo, useCallback, useEffect, useMemo } from 'react';
import { StoryCardCategoryTemplate } from '../../../../services';
import { Modal } from '../../../../shared';
import { CreateTemplate } from './CreateTemplate';
import { DefaultTemplates } from './DefaultTemplates';

interface CategoryTemplatesModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  templates: StoryCardCategoryTemplate[];
  setTemplates: (templates: StoryCardCategoryTemplate[]) => void;
  customTemplateIndex: number | null;
  setCustomTemplateIndex: (customTemplateIndex: number | null) => void;
}

export const CategoryTemplatesModal: FunctionComponent<CategoryTemplatesModalProps> = memo(
  ({ isOpen, setIsOpen, templates, setTemplates, customTemplateIndex, setCustomTemplateIndex }) => {
    useEffect(() => {
      if (customTemplateIndex === null) {
        return;
      }

      setIsOpen(true);
    }, [customTemplateIndex, setIsOpen]);

    const onClose = useCallback(() => {
      setIsOpen(false);
      setCustomTemplateIndex(null);
    }, [setCustomTemplateIndex, setIsOpen]);

    const body = useMemo(() => {
      if (customTemplateIndex !== null) {
        return (
          <CreateTemplate
            templates={templates}
            setTemplates={setTemplates}
            customTemplateIndex={customTemplateIndex}
            saveTemplateCallback={onClose}
          />
        );
      }

      return (
        <DefaultTemplates
          templates={templates}
          setTemplates={setTemplates}
          addTemplatesCallback={onClose}
        />
      );
    }, [customTemplateIndex, onClose, setTemplates, templates]);

    return <Modal isOpen={isOpen} body={body} onClose={onClose} alignTop />;
  }
);
