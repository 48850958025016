import { PaginationInfo } from '../shared';
import { graphqlApi } from './graphqlApi';
import { User } from './user';

export enum ChannelAppTabAction {
  CHATS = 'CHATS',
  NEWS_FEED = 'NEWS_FEED',
  PINNED_STORY = 'PINNED_STORY',
  PROFILE = 'PROFILE',
  STORIES = 'STORIES',
  STORIES2 = 'STORIES2',
  USER_DIRECTORY = 'USER_DIRECTORY', // * artificial field
}

export enum ChannelRole {
  ADMIN = 1,
  EDITOR = 2,
  EDITOR_LIMITED = 3,
  READER = 5,
}

export interface ChannelPermissions {
  channelInAppMessageAllowToCreate: boolean;
  channelPushNotificationAllowToCreate: boolean;
  chatGroupAllowToCreate: boolean;
  storyAllowToCreate: boolean;
}

interface ChannelAppTabTitle {
  language: string;
  localization: string;
}

interface ChannelAppTab {
  action: ChannelAppTabAction;
  iconUrl: string;
  id: number;
  title: ChannelAppTabTitle[];
}

export interface Channel {
  appTabs: ChannelAppTab[];
  dashboardUrl: string;
  id: number;
  locale: string;
  name: string;
  role: { id: number };
  subdomain: string;
  pinnedStoryId?: number;
  userNotificationPreference: {
    allowToReceiveChannelNotification: boolean;
  };
  permissions: ChannelPermissions;
}

export interface ChannelsArgs {
  filters: { excludeArchived: boolean };
}

interface ChannelsResponse {
  data: {
    channels: Channel[];
  };
}

export interface ChannelNotificationPreferenceUpdateArgs {
  channelId: number;
  fields: { allowToReceiveChannelNotification: boolean };
}

interface ChannelNotificationPreferenceUpdateResponse {
  data: {
    channelUserNotificationPreferenceUpdate: {
      payload: boolean;
    };
  };
}

interface ChannelUsersFilter {
  query?: string;
  userId?: number[];
}

export interface ChannelUsersArgs {
  channelId: number;
  page?: number;
  size?: number;
  filters?: ChannelUsersFilter;
}

interface ChannelUsersPayload {
  items: User[];
  pageInfo: PaginationInfo;
}

interface ChannelUsersResponse {
  data: {
    channelUsers: ChannelUsersPayload;
  };
}

interface ChannelBySubdomainArgs {
  subdomain: string;
}

export interface ChannelAppLocalizedTitleTab {
  language: string;
  localization: string;
}

export interface AppTab {
  iconUrl: string;
  action: { type: ChannelAppTabAction; storyId?: number };
  title: ChannelAppLocalizedTitleTab[];
}

export interface ChannelBySubdomainPayload {
  appTabs: AppTab[];
  allAppTabs: AppTab[];
  dashboardUrl: string | null;
  permissions: ChannelPermissions;
}

interface ChannelBySubdomainResponse {
  data: {
    channelBySubdomain: {
      payload: ChannelBySubdomainPayload;
    };
  };
}

export const channelsNewApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    channels: builder.query<Channel[], ChannelsArgs>({
      query: ({ filters }) => ({
        url: '/graphql/webapp?channels',
        method: 'POST',
        body: {
          query: `query Channels($filters: GetChannelsFiltersArgsType) {
            channels(filters: $filters) {
              id
              name
              subdomain
              pinnedStoryId
              userNotificationPreference {
                allowToReceiveChannelNotification
              }
              permissions {
                channelInAppMessageAllowToCreate
                channelPushNotificationAllowToCreate
                chatGroupAllowToCreate
                storyAllowToCreate
              }
            }
          }`,
          variables: { filters },
        },
      }),
      transformResponse: (response: ChannelsResponse) => response.data.channels,
    }),
    channelNotificationPreferenceUpdate: builder.query<
      boolean,
      ChannelNotificationPreferenceUpdateArgs
    >({
      query: ({ channelId, fields }) => ({
        url: '/graphql/webapp?channelUserNotificationPreferenceUpdate',
        method: 'POST',
        body: {
          query: `mutation ChannelUserNotificationPreferenceUpdate($input: ChannelUserNotificationPreferenceUpdateInput!) {
            channelUserNotificationPreferenceUpdate(input: $input) {
              payload
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { channelId, fields },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChannelNotificationPreferenceUpdateResponse) =>
        response.data.channelUserNotificationPreferenceUpdate.payload,
    }),
    channelUsers: builder.query<ChannelUsersPayload, ChannelUsersArgs>({
      query: ({ channelId, page = 1, size = 15, filters }) => ({
        url: '/graphql/webapp?channelUsers',
        method: 'POST',
        body: {
          query: `query ChannelUsers($channelId: Int!, $size: Int, $page: Int, $filters: GetChannelUsersFiltersArgsType) {
            channelUsers(channelId: $channelId, size: $size, page: $page, filters: $filters) {
              items {
                id
                avatar {
                  averageColor
                  externalId
                  externalType
                  id
                  originalFilename
                  rightholder
                  status
                  statusCopyrightId
                  thumb
                  url
                }
                department
                email
                phone
                position
                screenName
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: { channelId, page, size, filters },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChannelUsersResponse) => response.data.channelUsers,
    }),
    channelBySubdomain: builder.query<ChannelBySubdomainPayload, ChannelBySubdomainArgs>({
      query: ({ subdomain }) => ({
        url: '/graphql/webapp?channelBySubdomain',
        method: 'POST',
        body: {
          query: `query ChannelBySubdomain($subdomain: String!) {
            channelBySubdomain(subdomain: $subdomain) {
              payload {
                appTabs {
                  iconUrl
                  action {
                    type
                    ... on ChannelAppTabPinnedStory {
                      storyId
                    }
                  }
                  title {
                    language
                    localization
                  }
                }
                allAppTabs {
                  iconUrl
                  action {
                    type
                    ... on ChannelAppTabPinnedStory {
                      storyId
                    }
                  }
                  title {
                    language
                    localization
                  }
                }
                dashboardUrl
                permissions {
                  channelInAppMessageAllowToCreate
                  channelPushNotificationAllowToCreate
                  chatGroupAllowToCreate
                  storyAllowToCreate
                }
              }
            }
          }`,
          variables: { subdomain },
        },
      }),
      transformResponse: (response: ChannelBySubdomainResponse) =>
        response.data.channelBySubdomain.payload,
    }),
  }),
});

export const { useChannelsQuery, useChannelNotificationPreferenceUpdateQuery } = channelsNewApi;
