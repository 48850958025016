import {
  File,
  GalleryItem,
  ImageFile,
  isAdminLayout,
  OrderDirection,
  ORG_TAGS_SIZE_LIMIT,
  PaginationInfo,
  ReactionsCount,
} from '../shared';
import { Channel } from './channelsNew';
import { Comment } from './comment';
import { graphqlApi } from './graphqlApi';
import { commentQuery, contentBlocksQuery, storyCardQuery } from './helpers';
import { OrganisationTag, OrganisationTagType } from './organisation';
import { Story, StoryCardTeaserImageStyle } from './story';
import { User } from './user';

export enum StoryCardType {
  ARTICLE = 'ARTICLE',
  AUDIO = 'AUDIO',
  EDITORIAL = 'EDITORIAL',
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  POST = 'POST',
  QUOTE = 'QUOTE',
  THREAD = 'THREAD',
  VIDEO = 'VIDEO',
}

export enum StoryCardStatus {
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
  UNPUBLISHED = 'UNPUBLISHED',
  DRAFTED = 'DRAFTED',
}

export enum StoryCardQuoteSource {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER', // ! deprecated
  X = 'X',
  BSKY = 'BSKY',
}

export enum StoryCardSection {
  HORIZONTAL = 'HORIZONTAL',
  MIX_LIST = 'MIX_LIST',
  MIX_LIST_2 = 'MIX_LIST_2',
  MOBILE_FEED = 'MOBILE_FEED',
  PINNED = 'PINNED',
  UNASSIGNED = 'UNASSIGNED',
  WEB_FEED = 'WEB_FEED',
}

export interface StoryCardArticleGalleryItem {
  image: ImageFile;
}

export interface StoryCardQuoteGalleryItem {
  image: ImageFile;
}

export interface StoryCardPostGalleryItem {
  image: ImageFile;
}

export interface StoryCardImageGalleryItem {
  image: ImageFile;
  title: string;
}

export interface StoryCardAudioGalleryItem {
  audio: File;
  image: ImageFile | null;
  title: string;
}

export interface StoryCardVideoGalleryItem {
  video: File;
  image: ImageFile | null;
  title: string;
}

export interface StoryCardPdfGalleryItem {
  pdf: File;
  image: ImageFile | null;
  title: string;
}

interface StoryCardEditorialParsedUrl {
  headline: string;
  sourceName: string;
  subHeadline: string;
  text: string;
  type: StoryCardType;
}

export interface StoryCardArticleParsedUrl {
  gallery: GalleryItem[];
  abstract: string;
  contentAuthor: string;
  title: string;
  sourceName: string;
  type: StoryCardType;
}

interface StoryCardPostParsedUrl {
  title: string;
  sourceName: string;
  abstract: string;
  contentAuthor: string;
  contentBlocks: StoryCardPostContentBlockFields[];
  gallery: StoryCardArticleGalleryItem[];
  type: StoryCardType;
}

export interface StoryCardQuoteParsedUrl {
  gallery: GalleryItem[];
  quote: string;
  quoteSource: StoryCardQuoteSource;
  quoteCreated: string;
  quotePerson: string;
  quotePersonHandle: string;
  quotePersonImage: ImageFile;
  type: StoryCardType;
}

export interface StoryCardAudioParsedUrl {
  type: StoryCardType;
  gallery: StoryCardAudioGalleryItem[];
}

export interface StoryCardImageParsedUrl {
  type: StoryCardType;
  headline: string;
  sourceName: string;
  subHeadline: string;
  text: string;
  gallery: StoryCardImageGalleryItem[];
}

export interface StoryCardPdfParsedUrl {
  type: StoryCardType;
  gallery: StoryCardPdfGalleryItem[];
}

export interface StoryCardVideoParsedUrl {
  type: StoryCardType;
  gallery: StoryCardVideoGalleryItem[];
}

export type StoryCardParseUrlPayload =
  | StoryCardArticleParsedUrl
  | StoryCardQuoteParsedUrl
  | StoryCardAudioParsedUrl
  | StoryCardImageParsedUrl
  | StoryCardPdfParsedUrl
  | StoryCardVideoParsedUrl;

type StoryCardParsedUrlAsNative = StoryCardEditorialParsedUrl | StoryCardImageParsedUrl;

interface StoryCardParseUrlResponse {
  data: {
    storyCardParseUrl: {
      payload: StoryCardParseUrlPayload;
    };
  };
}

interface StoryCardParseUrlArgs {
  url: string;
}

interface StoryCardEditorialFieldsPostInStoryInput {
  headline: string;
  sourceName: string;
  subHeadline: string;
  text: string;
}

interface StoryCardAudioFieldsPostInStoryInput extends StoryCardEditorialFieldsPostInStoryInput {
  gallery: StoryCardAudioGalleryItem[];
}

interface StoryCardImageFieldsPostInStoryInput extends StoryCardEditorialFieldsPostInStoryInput {
  gallery: StoryCardImageGalleryItem[];
}

interface StoryCardPdfFieldsPostInStoryInput extends StoryCardEditorialFieldsPostInStoryInput {
  gallery: StoryCardPdfGalleryItem[];
}

interface StoryCardVideoFieldsPostInStoryInput extends StoryCardEditorialFieldsPostInStoryInput {
  gallery: StoryCardVideoGalleryItem[];
}

interface StoryCardThreadFieldsPostInStoryInput {
  title: string;
}

export interface StoryCardPostContentBlockFields {
  data: unknown;
  id: string;
  type: string;
}

interface StoryCardArticleFieldsPostInStoryInput {
  abstract: string;
  contentAuthor: string;
  contentReadTime: number;
  gallery: StoryCardArticleGalleryItem[];
  headline: string;
  sourceName: string;
  title: string;
  url: string;
  styles: {
    teaserImageStyle: StoryCardTeaserImageStyle;
  };
}

interface StoryCardPostFieldsPostInStoryInput {
  abstract: string;
  contentAuthor: string;
  contentReadTime: number;
  contentBlocks: StoryCardPostContentBlockFields[];
  gallery: StoryCardPostGalleryItem[];
  headline: string;
  sourceName: string;
  title: string;
  styles: {
    teaserImageStyle: StoryCardTeaserImageStyle;
  };
}

interface StoryCardQuoteFieldsPostInStoryInput {
  gallery: StoryCardQuoteGalleryItem[];
  headline: string;
  quote: string;
  quoteCreated: string;
  quotePerson: string;
  quotePersonHandle: string;
  quotePersonImageId: number;
  quoteSource: StoryCardQuoteSource;
  url: string;
}

interface StoryCardOptionsFieldsPostInStoryInput {
  allowCardReaction: boolean;
  allowComment: boolean;
  allowDisplayAuthor: boolean;
  allowSharing: boolean;
  shouldUpdatePostedTimeByComments: boolean;
}

export interface StoryCardFieldsPostInStoryInput {
  status?: StoryCardStatus;
  postingTime?: Date;
  localeId?: number;
  options?: StoryCardOptionsFieldsPostInStoryInput;
  articleFields?: StoryCardArticleFieldsPostInStoryInput;
  postFields?: StoryCardPostFieldsPostInStoryInput;
  audioFields?: StoryCardAudioFieldsPostInStoryInput;
  editorialFields?: StoryCardEditorialFieldsPostInStoryInput;
  imageFields?: StoryCardImageFieldsPostInStoryInput;
  pdfFields?: StoryCardPdfFieldsPostInStoryInput;
  quoteFields?: StoryCardQuoteFieldsPostInStoryInput;
  videoFields?: StoryCardVideoFieldsPostInStoryInput;
  threadFields?: StoryCardThreadFieldsPostInStoryInput;
  includeInWebappNewsFeed?: boolean;
  includeInNewsFeed?: boolean;
  assignedUserId?: number;
  organisationTags?: { externalTagsId?: number[]; internalTagsId?: number[] };
  categoryId?: number;
  mentionedUsersId?: number[];
}

interface StoryCardPostInStoryArgs {
  storyId: number;
  fields: StoryCardFieldsPostInStoryInput;
}

interface StoryCardPostInStoryData {
  payload: StoryCard;
  error: { __typename: string; message: string };
}

interface StoryCardPostInStoryResponse {
  data: {
    storyCardPostInStory: StoryCardPostInStoryData;
  };
}

export interface StoryCardFieldsPostInChatInput {
  chatComment?: string;
  mentionedUsersId?: number[];
  articleFields?: StoryCardArticleFieldsPostInStoryInput;
  imageFields?: StoryCardImageFieldsPostInStoryInput;
  quoteFields?: StoryCardQuoteFieldsPostInStoryInput;
  videoFields?: StoryCardVideoFieldsPostInStoryInput;
}

interface StoryCardPostInChatArgs {
  chatId: number;
  fields: StoryCardFieldsPostInChatInput;
}

interface StoryCardPostInChatData {
  payload: StoryCard;
  error: { __typename: string; message: string };
}

interface StoryCardPostInChatResponse {
  data: {
    storyCardPostInChat: StoryCardPostInChatData;
  };
}

interface StoryCardPostInUserProfileStoryArgs {
  fields: StoryCardFieldsPostInStoryInput;
}

interface StoryCardPostInUserProfileStoryData {
  payload: StoryCard;
  error: {
    __typename: string;
    message: string;
  };
}

interface StoryCardPostInUserProfileStoryResponse {
  data: {
    storyCardPostInUserProfileStory: StoryCardPostInUserProfileStoryData;
  };
}

export interface StoryCardByIdArgs {
  storyId: number;
  cardId: number;
}

export interface ChatStoryCardByIdArgs {
  chatId: number;
  cardId: number;
}

export interface StoryCardEditorialContent {
  headline: string;
  sourceName: string;
  subHeadline: string;
  text: string;
  textHTML: string;
}

export interface StoryCardVideoContent extends StoryCardEditorialContent {
  gallery: StoryCardVideoGalleryItem[];
}

export interface StoryCardAudioContent extends StoryCardEditorialContent {
  gallery: StoryCardAudioGalleryItem[];
}

export interface StoryCardImageContent extends StoryCardEditorialContent {
  gallery: StoryCardImageGalleryItem[];
}

export interface StoryCardPdfContent extends StoryCardEditorialContent {
  gallery: StoryCardPdfGalleryItem[];
}

export interface StoryCardThreadContent {
  title: string;
}

export interface StoryCardArticleContent {
  abstract: string;
  contentAuthor: string;
  contentReadTime: number;
  gallery: StoryCardArticleGalleryItem[];
  headline: string;
  sourceName: string;
  styles: { teaserImageStyle: StoryCardTeaserImageStyle };
  title: string;
  url: string;
}

export interface StoryCardQuoteContent {
  gallery: StoryCardQuoteGalleryItem[];
  headline: string;
  quote: string;
  quoteCreated: string;
  quotePerson: string;
  quotePersonHandle: string;
  quotePersonImage: ImageFile;
  quoteSource: StoryCardQuoteSource;
  url: string;
}

export interface StoryCardPostContent {
  abstract: string;
  contentAuthor: string;
  contentBlocks: StoryCardPostContentBlockFields[];
  contentReadTime: number;
  gallery: StoryCardPostGalleryItem[];
  headline: string;
  sourceName: string;
  styles: { teaserImageStyle: StoryCardTeaserImageStyle };
  title: string;
}

export type StoryCardContentText =
  | StoryCardEditorialContent
  | StoryCardAudioContent
  | StoryCardVideoContent
  | StoryCardImageContent
  | StoryCardPdfContent;

export type StoryCardContentTitle =
  | StoryCardThreadContent
  | StoryCardArticleContent
  | StoryCardPostContent;

export type StoryCardContentSourceName =
  | StoryCardEditorialContent
  | StoryCardArticleContent
  | StoryCardPostContent;

export type StoryCardContentHeadline =
  | StoryCardEditorialContent
  | StoryCardArticleContent
  | StoryCardPostContent
  | StoryCardQuoteContent;

export type StoryCardContentMedia =
  | StoryCardImageContent
  | StoryCardAudioContent
  | StoryCardVideoContent
  | StoryCardPdfContent;

export type StoryCardContentArticleQuote = StoryCardArticleContent | StoryCardQuoteContent;

export type StoryCardContentArticlePost = StoryCardArticleContent | StoryCardPostContent;

export type StoryCardContentImageVideo = StoryCardImageContent | StoryCardVideoContent;

export type StoryCardContentGallery =
  | StoryCardVideoContent
  | StoryCardAudioContent
  | StoryCardImageContent
  | StoryCardPdfContent
  | StoryCardArticleContent
  | StoryCardQuoteContent
  | StoryCardPostContent;

type StoryCardContent =
  | StoryCardArticleContent
  | StoryCardAudioContent
  | StoryCardEditorialContent
  | StoryCardImageContent
  | StoryCardPdfContent
  | StoryCardQuoteContent
  | StoryCardThreadContent
  | StoryCardVideoContent
  | StoryCardPostContent;

interface StoryCardByIdResponse {
  data: {
    storyCardById: {
      payload: StoryCard;
    };
  };
}

interface ChatStoryCardByIdResponse {
  data: {
    chatStoryCardById: {
      payload: StoryCard;
    };
  };
}

export interface StoryCardUpdateArgs {
  storyId: number;
  storyCardId: number;
  fields: StoryCardFieldsPostInStoryInput;
}

interface StoryCardUpdateData {
  payload: StoryCard;
  error: {
    __typename: string;
    message: string;
  };
}

interface StoryCardUpdateResponse {
  data: {
    storyCardUpdate: StoryCardUpdateData;
  };
}

export interface StoryCardDeleteArgs {
  storyId: number;
  storyCardId: number;
}

interface StoryCardDeleteResponse {
  data: {
    storyCardDelete: {
      payload: boolean;
      error: [{ message: string }];
    };
  };
}

export interface StoryCardsFeedFilter {
  query?: string;
  from?: string;
  to?: string;
  onlyCompletedMedia?: boolean;
  status?: StoryCardStatus[];
  section?: StoryCardSection[];
  storyCardType?: StoryCardType[];
  authorId?: number[];
  categoryId?: number[];
  organisationTags?: { externalTagsId: number[] | null; internalTagsId: number[] | null };
}

export interface StoryCardsFeedArgs {
  storyId: number;
  page?: number;
  size?: number;
  orderDirection?: OrderDirection;
  filter?: StoryCardsFeedFilter;
}

interface StoryCardOptions {
  allowCardReaction: boolean;
  allowComment: boolean;
  allowDisplayAuthor: boolean;
  allowSharing: boolean;
  shouldUpdatePostedTimeByComments: boolean;
}

export interface StoryCardPermission {
  allowToDelete: boolean;
  allowToUpdate: boolean;
  allowToUpdatePosition: boolean;
  allowToUpdateStatus: boolean;
}

export enum ApiClientType {
  API_CLIENTS_OFFICIAL = 'API_CLIENTS_OFFICIAL', // curate-backend
  API_CLIENTS_EXTERNAL = 'API_CLIENTS_EXTERNAL', // integration
  READ_CLIENTS_PUBLIC = 'READ_CLIENTS_PUBLIC', // stream
  READ_CLIENTS_PRIVATE = 'READ_CLIENTS_PRIVATE', // app
}

export interface ApiClient {
  id: number;
  image: ImageFile;
  imageId: number;
  name: string;
  type: ApiClientType;
}

export interface StoryCard {
  id: number;
  apiClient: ApiClient;
  assignedUserId: number;
  author: User;
  user: User;
  mentionedUsers: User[];
  authorId: number;
  comments: Comment[];
  commentsCount: number;
  commentsRootCount: number;
  highlightedComments: Comment[];
  content: StoryCardContent;
  createdAt: string;
  dateToDisplay: string;
  isPinnedToTopOfNewsFeed: boolean;
  isReadOnly: boolean;
  myReaction: string;
  options: StoryCardOptions;
  originalUserId: number;
  permissions: StoryCardPermission;
  position: number;
  postedAt: string;
  postingTime: string;
  reactions: ReactionsCount[];
  section: StoryCardSection[];
  sourceStoryId: number;
  status: StoryCardStatus;
  story: Story;
  storyId: number;
  type: StoryCardType;
  updatedAt: string;
  localeId: number;
  externalTags: OrganisationTag[];
  internalTags?: OrganisationTag[];
  category?: StoryCardCategory;
}

export interface StoryCardsFeedPayload {
  items: StoryCard[];
  pageInfo: PaginationInfo;
}

interface StoryCardsFeedResponse {
  data: {
    storyCardsFeed: {
      payload: StoryCardsFeedPayload;
      error: [{ message: string }];
    };
  };
  errors?: [{ message: string }];
}

interface StoriesCardsUserFeedStatisticArgs {
  channelId: number;
  userId: number;
}

interface StoriesCardsUserFeedStatisticPayload {
  published: number;
  scheduled: number;
  unpublished: number;
  drafts: number;
}

interface StoriesCardsUserFeedStatisticResponse {
  data: {
    storiesCardsUserFeedStatistic: {
      payload: StoriesCardsUserFeedStatisticPayload;
    };
  };
}

export interface StoriesCardsUserFeedFilter {
  from: string;
  to: string;
  storyCardType: StoryCardType[];
  status: StoryCardStatus[];
}

export interface StoriesCardsUserFeedArgs {
  channelId: number;
  userId: number;
  filter?: StoriesCardsUserFeedFilter;
  page?: number;
  size?: number;
}

interface StoriesCardsUserFeedPayload {
  items: StoryCard[];
  pageInfo: PaginationInfo;
}

interface StoriesCardsUserFeedResponse {
  data: {
    storiesCardsUserFeed: {
      payload: StoriesCardsUserFeedPayload;
      error: [{ message: string }];
    };
  };
  errors?: [{ message: string }];
}

export interface StoryCardsUserProfileFeedFilters {
  from?: string;
  to?: string;
  status?: StoryCardStatus[];
  storyCardType?: StoryCardType[];
  organisationTags?: { externalTagsId: number[] | null; internalTagsId: number[] | null };
}

export interface StoryCardsUserProfileFeedArgs {
  userId: number;
  orderDirection?: OrderDirection;
  page?: number;
  size?: number;
  filters?: StoryCardsUserProfileFeedFilters;
}

interface StoryCardsUserProfileFeedPayload {
  items: StoryCard[];
  pageInfo: PaginationInfo;
}

interface StoryCardsUserProfileFeedResponse {
  data: {
    storyCardsUserProfileFeed: {
      payload: StoryCardsUserProfileFeedPayload;
      error: [{ message: string }];
    };
  };
  errors?: [{ message: string }];
}

interface StoryCardAuthorsFiltersByStoryIdArgs {
  storyId: number;
  query: string;
  page?: number;
  size?: number;
}

interface StoryCardAuthorsFiltersByStoryIdPayload {
  items: User[];
  pageInfo: PaginationInfo;
}

interface StoryCardAuthorsFiltersByStoryIdResponse {
  data: {
    storyCardAuthorsFiltersByStoryId: StoryCardAuthorsFiltersByStoryIdPayload;
  };
}

export interface StoryCardAuthorsFiltersByChannelIdArgs {
  channelId: number;
  query: string;
  page?: number;
  size?: number;
}

interface StoryCardAuthorsFiltersByChannelIdPayload {
  items: User[];
  pageInfo: PaginationInfo;
}

interface StoryCardAuthorsFiltersByChannelIdResponse {
  data: {
    storyCardAuthorsFiltersByChannelId: StoryCardAuthorsFiltersByChannelIdPayload;
  };
}

interface StoryCardPositionUpdateArgs {
  storyId: number;
  storyCardId: number;
  position: number;
}

interface StoryCardPositionUpdateResponse {
  data: {
    storyCardPositionUpdate: {
      payload: boolean;
    };
  };
}

interface StoryCardMoveToOtherStoryArgs {
  sourceStoryCardId: number;
  sourceStoryId: number;
  targetStoryId: number;
}

export interface StoryCardCopySyncMoveData {
  payload: StoryCard;
  error: { __typename: string; message: string };
}

interface StoryCardMoveToOtherStoryResponse {
  data: {
    storyCardMoveToOtherStory: StoryCardCopySyncMoveData;
  };
}

interface StoryCardPostInStoryAsCopyArgs {
  sourceStoryCardId: number;
  sourceStoryId: number;
  targetStoryId: number;
}

interface StoryCardPostInStoryAsCopyResponse {
  data: {
    storyCardPostInStoryAsCopy: StoryCardCopySyncMoveData;
  };
}

interface StoryCardPostInChatAsCopyArgs {
  chatId: number;
  sourceStoryCardId: number;
  sourceStoryId: number;
  chatComment: string;
}

interface StoryCardPostInChatAsCopyData {
  payload: StoryCard;
  error: { __typename: string; message: string };
}

interface StoryCardPostInChatAsCopyResponse {
  data: {
    storyCardPostInChatAsCopy: StoryCardPostInChatAsCopyData;
  };
}

interface StoryCardPostInStoryAsSyncArgs {
  sourceStoryCardId: number;
  sourceStoryId: number;
  targetStoryId: number;
}

interface StoryCardPostInStoryAsSyncResponse {
  data: {
    storyCardPostInStoryAsSync: StoryCardCopySyncMoveData;
  };
}

export interface StoryCardReactionArgs {
  storyId: number;
  cardId: number;
  reaction: string;
}

interface StoryCardReactionResponse {
  data: {
    storyCardReaction: {
      payload: ReactionsCount[];
    };
  };
}

export interface StoryCardCommentsArgs {
  itemId: number;
  storyId?: number;
  parentId?: number;
  page?: number;
  size?: number;
}

interface StoryCardCommentsPayload {
  items: Comment[];
  pageInfo: PaginationInfo;
}

interface StoryCardCommentsResponse {
  data: {
    storyCardComments: {
      payload: StoryCardCommentsPayload;
    };
  };
}

export interface StoryCardNewsfeedPinnedStatusUpdateArgs {
  storyId: number;
  storyCardId: number;
}

interface StoryCardNewsfeedPinnedStatusUpdateResponse {
  data: {
    storyCardNewsfeedPinnedStatusUpdate: {
      payload: boolean;
    };
  };
}

interface StoryCardSource {
  story: Story;
  channel: Channel;
}

interface StoryCardSourceByIdArgs {
  itemId: number;
}

interface StoryCardSourceByIdResponse {
  data: {
    storyCardSourceById: {
      payload: StoryCardSource;
    };
  };
}

export interface StoryCardRepostArgs {
  storyId: number;
  storyCardId: number;
}

interface StoryCardRepostResponse {
  data: {
    storyCardRepost: {
      payload: StoryCard;
    };
  };
}

interface StoryCardCreateDeeplinkArgs {
  storyId: number;
  storyCardId: number;
}

interface StoryCardCreateDeeplinkResponse {
  data: {
    storyCardCreateDeeplink: {
      payload: string;
    };
  };
}

interface StoryCardParseUrlAsNativeArgs {
  url: string;
}

interface StoryCardParseUrlAsNativeResponse {
  data: {
    storyCardParseUrlAsNative: {
      payload: StoryCardParsedUrlAsNative;
    };
  };
}

interface StoryCardParseUrlAsPostArgs {
  url: string;
}

interface StoryCardParseUrlAsPostResponse {
  data: {
    storyCardParseUrlAsPost: {
      payload: StoryCardPostParsedUrl;
    };
  };
}

export interface StoryCardsOrganisationTagFeedFilters {
  authorId?: number[];
  from?: string;
  to?: string;
  onlyCompletedMedia?: boolean;
  section?: StoryCardSection[];
  status?: StoryCardStatus[];
  storyCardType?: StoryCardType[];
  organisationTags?: { externalTagsId: number[] | null; internalTagsId: number[] | null };
}

export interface StoryCardsOrganisationTagFeedArgs {
  channelId: number;
  organisationTagId: number;
  orderDirection?: OrderDirection;
  page?: number;
  size?: number;
  filters?: StoryCardsOrganisationTagFeedFilters;
}

interface StoryCardsOrganisationTagFeedPayload {
  items: StoryCard[];
  pageInfo: PaginationInfo;
}

interface StoryCardsOrganisationTagFeedResponse {
  data: {
    storyCardsOrganisationTagFeed: StoryCardsOrganisationTagFeedPayload;
  };
}

interface StoryCardsChannelSearchPayload {
  items: StoryCard[];
  pageInfo: PaginationInfo;
}

export interface StoryCardsChannelSearchFilters {
  query?: string;
  from?: string;
  to?: string;
  onlyCompletedMedia?: boolean;
  section?: StoryCardSection[];
  storyCardType?: StoryCardType[];
  authorId?: number[];
  categoryId?: number[];
  organisationTags?: { externalTagsId: number[] | null; internalTagsId: number[] | null };
  storyId?: number[];
  status?: StoryCardStatus[];
}

interface StoryCardsChannelSearchResponse {
  data: {
    storyCardsChannelSearch: {
      payload: StoryCardsChannelSearchPayload;
    };
  };
  errors?: [{ message: string }];
}

export interface StoryCardsChannelSearchArgs {
  channelId: number;
  orderDirection?: OrderDirection;
  page?: number;
  size?: number;
  filters?: StoryCardsChannelSearchFilters;
}

interface StoryPreview {
  id: number;
  title: string;
  subtitle: string;
}

export interface StoryCardCategoryTemplate {
  gallery: StoryCardImageGalleryItem[];
  name: string;
  text: string;
}

export interface StoryCardCategorySettings {
  userSelection: boolean;
  emailNotification: boolean;
  editableMedia: boolean;
  timeToLive: number;
  buttonTextEn: string;
  buttonTextDe: string;
  showAsOption: boolean;
  showAsButton: boolean;
}

export interface StoryCardCategory {
  id: number;
  name: string;
  channel: Channel | null;
  templates: StoryCardCategoryTemplate[];
  settings: StoryCardCategorySettings;
  story?: StoryPreview;
}

interface StoryCardCategoriesPayload {
  items: StoryCardCategory[];
  pageInfo: PaginationInfo;
}

interface StoryCardCategoriesResponse {
  data: {
    storyCardCategories: StoryCardCategoriesPayload;
  };
}

interface StoryCardCategoryCreateArgs {
  name: string;
  settings: StoryCardCategorySettings;
  templates: StoryCardCategoryTemplate[];
  storyId?: number;
}

interface StoryCardCategoryCreateResponse {
  data: {
    storyCardCategoryCreate: {
      payload: StoryCardCategory;
    };
  };
}

interface StoryCardCategoryUpdateArgs {
  categoryId: number;
  name: string;
  settings: StoryCardCategorySettings;
  templates: StoryCardCategoryTemplate[];
  storyId?: number;
}

interface StoryCardCategoryUpdateResponse {
  data: {
    storyCardCategoryUpdate: {
      payload: StoryCardCategory;
    };
  };
}

interface StoryCardCategoryDeleteArgs {
  categoryId: number;
  forceDelete: boolean;
}

interface StoryCardCategoryDeleteResponse {
  data: {
    storyCardCategoryDelete: {
      payload: boolean;
    };
  };
}

interface StoryCardCategoryDefaultTemplatesResponse {
  data: {
    storyCardCategoryDefaultTemplates: StoryCardCategoryTemplate[];
  };
}

interface StoryCardCategoriesByChannelIdArgs {
  channelId: number;
}

interface StoryCardCategoriesByChannelIdResponse {
  data: {
    storyCardCategoriesByChannelId: {
      items: StoryCardCategory[];
    };
  };
}

interface StoryCardsUsedTagsFilters {
  type: OrganisationTagType;
}

interface StoryCardsUsedTagsArgs {
  channelId: number;
  filters?: StoryCardsUsedTagsFilters;
  page?: number;
  size?: number;
}

interface StoryCardsUsedTagsResponse {
  data: {
    storyCardsUsedTags: {
      items: OrganisationTag[];
    };
  };
}

export const storyCardApi = graphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    storyCardById: builder.query<StoryCard, StoryCardByIdArgs>({
      query: ({ storyId, cardId }) => ({
        url: '/graphql/webapp?storyCardById',
        method: 'POST',
        body: {
          query: `query StoryCardById($storyId: Int!, $itemId: Int!) {
            storyCardById(storyId: $storyId, itemId: $itemId) {
              payload {
                ${storyCardQuery({ isFeed: false, isAdminLayout: isAdminLayout() })}
              }
            }
          }`,
          variables: { storyId, itemId: cardId },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardByIdResponse) => response.data.storyCardById.payload,
    }),
    chatStoryCardById: builder.query<StoryCard, ChatStoryCardByIdArgs>({
      query: ({ chatId, cardId }) => ({
        url: '/graphql/webapp?chatStoryCardById',
        method: 'POST',
        body: {
          query: `query ChatStoryCardById($chatId: Int!, $itemId: Int!) {
            chatStoryCardById(chatId: $chatId, itemId: $itemId) {
              payload {
                ${storyCardQuery({ isFeed: false, isAdminLayout: isAdminLayout() })}
              }
            }
          }`,
          variables: { chatId, itemId: cardId },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: ChatStoryCardByIdResponse) =>
        response.data.chatStoryCardById.payload,
    }),
    storyCardParseUrl: builder.query<StoryCardParseUrlPayload, StoryCardParseUrlArgs>({
      query: ({ url }) => ({
        url: '/graphql/webapp?storyCardParseUrl',
        method: 'POST',
        body: {
          query: `mutation StoryCardParseUrl($input: StoryCardParseUrlInput!) {
            storyCardParseUrl(input: $input) {
              payload {
                ... on StoryCardArticleParsedUrl {
                  type
                  abstract
                  contentAuthor
                  title
                  sourceName
                  gallery {
                    image {
                      id
                      externalId
                      originalFilename
                      url
                    }
                  }
                }
                ... on StoryCardQuoteParsedUrl {
                  type
                  quote
                  quoteCreated
                  quoteSource
                  quotePerson
                  quotePersonHandle
                  quotePersonImage {
                    id
                    url
                  }
                  gallery {
                    image {
                      id
                      externalId
                      originalFilename
                      url
                    }
                  }
                }
                ... on StoryCardAudioParsedUrl {
                  type
                  gallery {
                    audio {
                      id
                      externalType
                    }
                  }
                }
                ... on StoryCardImageParsedUrl {
                  type
                  gallery {
                    image {
                      id
                      url
                      externalType
                    }
                  }
                }
                ... on StoryCardPdfParsedUrl {
                  type
                  gallery {
                    pdf {
                      id
                      externalType
                    }
                  }
                }
                ... on StoryCardVideoParsedUrl {
                  type
                  gallery {
                    video {
                      id
                      externalType
                    }
                  }
                }
              }
            }
          }`,
          variables: { input: { url } },
        },
      }),
      transformResponse: (response: StoryCardParseUrlResponse) =>
        response.data.storyCardParseUrl.payload,
    }),
    storyCardPostInStory: builder.query<StoryCardPostInStoryData, StoryCardPostInStoryArgs>({
      query: ({ storyId, fields }) => ({
        url: '/graphql/webapp?storyCardPostInStory',
        method: 'POST',
        body: {
          query: `mutation StoryCardPostInStory($input: StoryCardPostInStoryInput!) {
            storyCardPostInStory(input: $input) {
              payload {
                ${storyCardQuery({ isFeed: false, isAdminLayout: isAdminLayout() })}
              }
              error {
                __typename
                ... on StoryCardPostContentValidationError {
                  message
                }
                ... on StoryCardUrlUniquenessConflictError {
                  message
                }
                ... on StoryCardValidationError {
                  details {
                    message
                    path
                    type
                  }
                }
                ... on StoryReadOnlyAccessError {
                  message
                }
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { storyId, fields },
          },
        },
      }),
      transformResponse: (response: StoryCardPostInStoryResponse) =>
        response.data.storyCardPostInStory,
    }),
    storyCardPostInChat: builder.query<StoryCardPostInChatData, StoryCardPostInChatArgs>({
      query: ({ chatId, fields }) => ({
        url: '/graphql/webapp?storyCardPostInChat',
        method: 'POST',
        body: {
          query: `mutation StoryCardPostInChat($input: StoryCardPostInChatInput!) {
            storyCardPostInChat(input: $input) {
              payload {
               id
              }
            }
          }`,
          variables: { input: { chatId, fields } },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardPostInChatResponse) =>
        response.data.storyCardPostInChat,
    }),

    storyCardPostInUserProfileStory: builder.query<
      StoryCardPostInUserProfileStoryData,
      StoryCardPostInUserProfileStoryArgs
    >({
      query: ({ fields }) => ({
        url: '/graphql/webapp?storyCardPostInUserProfileStory',
        method: 'POST',
        body: {
          query: `mutation StoryCardPostInUserProfileStory($input: StoryCardPostInUserProfileStoryInput!) {
            storyCardPostInUserProfileStory(input: $input) {
                payload {
                   ${storyCardQuery({ isFeed: false, isAdminLayout: isAdminLayout() })}
                }
                error {
                  ... on OrganisationTagNotFoundError  {
                    message
                  }
                  ... on StoryCardPostContentValidationError  {
                    message
                  }
                  ... on StoryCardUrlUniquenessConflictError  {
                    message
                  }
                  ... on StoryCardValidationError  {
                    message
                  }
                  ... on StoryReadOnlyAccessError  {
                    message
                  }
                  ... on UnknownError  {
                    message
                    kind
                  }
                }
              }
          }`,
          variables: {
            input: { fields },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardPostInUserProfileStoryResponse) =>
        response.data.storyCardPostInUserProfileStory,
    }),
    storyCardUpdate: builder.query<StoryCardUpdateData, StoryCardUpdateArgs>({
      query: ({ storyId, storyCardId, fields }) => ({
        url: '/graphql/webapp?storyCardUpdate',
        method: 'POST',
        body: {
          query: `mutation StoryCardUpdate($input: StoryCardUpdateInput!) {
            storyCardUpdate(input: $input) {
              payload {
                ${storyCardQuery({ isFeed: false, isAdminLayout: isAdminLayout() })}
              }
              error {
                ... on StoryCardNotFoundError {
                  message
                }
                ... on StoryCardPostContentValidationError {
                  message
                }
                ... on StoryCardValidationError {
                  message
                }
                ... on StoryReadOnlyAccessError {
                  message
                }
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { storyId, storyCardId, fields },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardUpdateResponse) => response.data.storyCardUpdate,
    }),
    storyCardDelete: builder.query<boolean, StoryCardDeleteArgs>({
      query: ({ storyId, storyCardId }) => ({
        url: '/graphql/webapp?storyCardDelete',
        method: 'POST',
        body: {
          query: `mutation StoryCardDelete($input: StoryCardDeleteInput!) {
            storyCardDelete(input: $input) {
              payload
              error {
                ... on StoryCardNotFoundError {
                  message
                }
                ... on StoryReadOnlyAccessError {
                  message
                }
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { storyId, storyCardId },
          },
        },
      }),
      transformResponse: (response: StoryCardDeleteResponse) =>
        response.data.storyCardDelete.payload,
    }),
    storyCardsFeed: builder.query<StoryCardsFeedPayload, StoryCardsFeedArgs>({
      query: ({ storyId, page = 1, size = 10, orderDirection = OrderDirection.DESC, filter }) => ({
        url: '/graphql/webapp?storyCardsFeed',
        method: 'POST',
        body: {
          query: `query StoryCardsFeed($storyId: Int!, $page: Int, $size: Int, $orderDirection: OrderDirectionEnum!, $filter: GetStoryCardsFeedFilterArgs) {
            storyCardsFeed(storyId: $storyId, page: $page, size: $size, orderDirection: $orderDirection, filter: $filter) {
              payload {
                items {
                  ${storyCardQuery({ isFeed: true, isAdminLayout: isAdminLayout() })}
                }
                pageInfo {
                  page
                  perPage
                  hasNextPage
                }
              }
              error {
                ... on StoryNotFoundError {
                  message
                }
              }
            }
          }`,
          variables: {
            storyId,
            page,
            size,
            orderDirection,
            filter: {
              ...filter,
              onlyCompletedMedia: filter?.onlyCompletedMedia ?? !isAdminLayout(),
            },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardsFeedResponse) => {
        if (response?.errors?.length) {
          return {
            items: [],
            pageInfo: { page: 0, hasNextPage: false } as PaginationInfo,
          };
        }

        return response.data.storyCardsFeed.payload;
      },
    }),
    storiesCardsUserFeedStatistic: builder.query<
      StoriesCardsUserFeedStatisticPayload,
      StoriesCardsUserFeedStatisticArgs
    >({
      query: ({ channelId, userId }) => ({
        url: '/graphql/webapp?storiesCardsUserFeedStatistic',
        method: 'POST',
        body: {
          query: `query StoriesCardsUserFeedStatistic($channelId: Int!, $userId: Int!) {
            storiesCardsUserFeedStatistic(channelId: $channelId, userId: $userId) {
              payload {
                published
                scheduled
                unpublished
                drafts
              }
            }
          }`,
          variables: { channelId, userId },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoriesCardsUserFeedStatisticResponse) =>
        response.data.storiesCardsUserFeedStatistic.payload,
    }),
    storiesCardsUserFeed: builder.query<StoriesCardsUserFeedPayload, StoriesCardsUserFeedArgs>({
      query: ({ channelId, userId, filter, page = 1, size = 10 }) => ({
        url: '/graphql/webapp?storiesCardsUserFeed',
        method: 'POST',
        body: {
          query: `query StoriesCardsUserFeed($channelId: Int!, $userId: Int!, $filter: GetStoriesCardsUserFeedFilterArgs, $page: Int, $size: Int) {
            storiesCardsUserFeed(channelId: $channelId, userId: $userId, filter: $filter, page: $page, size: $size) {
              payload {
                items {
                  ${storyCardQuery({ isFeed: true, isAdminLayout: isAdminLayout() })}
                }
                pageInfo {
                  page
                  hasNextPage
                }
              }
              error {
                ... on UserNotFoundError {
                  message
                }
              }
            }
          }`,
          variables: {
            channelId,
            userId,
            filter,
            page,
            size,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoriesCardsUserFeedResponse) => {
        if (response?.errors?.length) {
          return {
            items: [],
            pageInfo: { page: 0, hasNextPage: false } as PaginationInfo,
          };
        }

        return response.data.storiesCardsUserFeed.payload;
      },
    }),
    storyCardsUserProfileFeed: builder.query<
      StoryCardsUserProfileFeedPayload,
      StoryCardsUserProfileFeedArgs
    >({
      query: ({ userId, orderDirection = OrderDirection.DESC, page = 1, size = 10, filters }) => ({
        url: '/graphql/webapp?storyCardsUserProfileFeed',
        method: 'POST',
        body: {
          query: `query StoryCardsUserProfileFeed($userId: Int!, $orderDirection: OrderDirectionEnum!, $page: Int, $size: Int, $filters: GetStoryCardsUserProfileFeedFiltersArgs) {
            storyCardsUserProfileFeed(userId: $userId, orderDirection: $orderDirection, page: $page, size: $size, filters: $filters) {
              payload {
                items {
                  ${storyCardQuery({ isFeed: true, isAdminLayout: isAdminLayout() })}
                }
                pageInfo {
                  page
                  perPage
                  hasNextPage
                }
              }
              error {
                ... on StoryNotFoundError {
                  message
                }
              }
            }
          }`,
          variables: {
            userId,
            page,
            size,
            orderDirection,
            filters,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardsUserProfileFeedResponse) => {
        if (response?.errors?.length) {
          return {
            items: [],
            pageInfo: { page: 0, hasNextPage: false } as PaginationInfo,
          };
        }

        return response.data.storyCardsUserProfileFeed.payload;
      },
    }),
    storyCardAuthorsFiltersByStoryId: builder.query<
      StoryCardAuthorsFiltersByStoryIdPayload,
      StoryCardAuthorsFiltersByStoryIdArgs
    >({
      query: ({ storyId, query, page = 1, size = 10 }) => ({
        url: '/graphql/webapp?storyCardAuthorsFiltersByStoryId',
        method: 'POST',
        body: {
          query: `query StoryCardAuthorsFiltersByStoryId($storyId: Int!, $filter: GetStoryCardAuthorFiltersByStoryIdFilterArgs, $page: Int, $size: Int) {
            storyCardAuthorsFiltersByStoryId(storyId: $storyId, filter: $filter, page: $page, size: $size) {
              items {
                avatar {
                  averageColor
                  externalId
                  externalType
                  id
                  originalFilename
                  rightholder
                  status
                  statusCopyrightId
                  thumb
                  url
                }
                email
                id
                screenName
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: { storyId, filter: { query }, page, size },
        },
      }),
      transformResponse: (response: StoryCardAuthorsFiltersByStoryIdResponse) =>
        response.data.storyCardAuthorsFiltersByStoryId,
    }),
    storyCardAuthorsFiltersByChannelId: builder.query<
      StoryCardAuthorsFiltersByChannelIdPayload,
      StoryCardAuthorsFiltersByChannelIdArgs
    >({
      query: ({ channelId, query, page = 1, size = 10 }) => ({
        url: '/graphql/webapp?storyCardAuthorsFiltersByChannelId',
        method: 'POST',
        body: {
          query: `query StoryCardAuthorsFiltersByChannelId($channelId: Int!, $filter: GetStoryCardAuthorFiltersByChannelIdFilterArgs, $page: Int, $size: Int) {
            storyCardAuthorsFiltersByChannelId(channelId: $channelId, filter: $filter, page: $page, size: $size) {
              items {
                avatar {
                  averageColor
                  externalId
                  externalType
                  id
                  originalFilename
                  rightholder
                  status
                  statusCopyrightId
                  thumb
                  url
                }
                email
                id
                screenName
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: { channelId, filter: { query }, page, size },
        },
      }),
      transformResponse: (response: StoryCardAuthorsFiltersByChannelIdResponse) =>
        response.data.storyCardAuthorsFiltersByChannelId,
    }),
    storyCardPositionUpdate: builder.query<boolean, StoryCardPositionUpdateArgs>({
      query: ({ storyId, storyCardId, position }) => ({
        url: '/graphql/webapp?storyCardPositionUpdate',
        method: 'POST',
        body: {
          query: `mutation StoryCardPositionUpdate($input: StoryCardPositionUpdateInput!) {
            storyCardPositionUpdate(input: $input) {
              payload
              error {
                ... on StoryCardNotFoundError {
                  message
                }
                ... on StoryReadOnlyAccessError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { storyId, storyCardId, position },
          },
        },
      }),
      transformResponse: (response: StoryCardPositionUpdateResponse) =>
        response.data.storyCardPositionUpdate.payload,
    }),
    storyCardMoveToOtherStory: builder.query<
      StoryCardCopySyncMoveData,
      StoryCardMoveToOtherStoryArgs
    >({
      query: ({ sourceStoryId, sourceStoryCardId, targetStoryId }) => ({
        url: '/graphql/webapp?storyCardMoveToOtherStory',
        method: 'POST',
        body: {
          query: `mutation StoryCardMoveToOtherStory($input: StoryCardMoveToOtherStoryInput!) {
            storyCardMoveToOtherStory(input: $input) {
              payload {
                id
              }
              error {
                ... on SourceAndTargetStoryAreEqualErrorType {
                  message
                }
                ... on DuplicatedStoryItemIdError {
                  message
                }
                ... on SourceStoryCardNotFoundError {
                  message
                }
                ... on StoryCardUrlUniquenessConflictError {
                  message
                }
                ... on StoryNotFoundError {
                  message
                }
                ... on StoryCardNotFoundError {
                  message
                }
                ... on StoryReadOnlyAccessError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { sourceStoryId, sourceStoryCardId, targetStoryId },
          },
        },
      }),
      transformResponse: (response: StoryCardMoveToOtherStoryResponse) =>
        response.data.storyCardMoveToOtherStory,
    }),
    storyCardPostInStoryAsCopy: builder.query<
      StoryCardCopySyncMoveData,
      StoryCardPostInStoryAsCopyArgs
    >({
      query: ({ sourceStoryId, sourceStoryCardId, targetStoryId }) => ({
        url: '/graphql/webapp?storyCardPostInStoryAsCopy',
        method: 'POST',
        body: {
          query: `mutation StoryCardPostInStoryAsCopy($input: StoryCardPostInStoryAsCopyInput!) {
            storyCardPostInStoryAsCopy(input: $input) {
              payload {
                id
              }
              error {
                ... on SourceAndTargetStoryAreEqualErrorType {
                  message
                }
                ... on SourceStoryCardNotFoundError {
                  message
                }
                ... on StoryCardUrlUniquenessConflictError {
                  message
                }
                ... on StoryNotFoundError {
                  message
                }
                ... on StoryCardNotFoundError {
                  message
                }
                ... on StoryReadOnlyAccessError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { sourceStoryId, sourceStoryCardId, targetStoryId },
          },
        },
      }),
      transformResponse: (response: StoryCardPostInStoryAsCopyResponse) =>
        response.data.storyCardPostInStoryAsCopy,
    }),
    storyCardPostInChatAsCopy: builder.query<
      StoryCardPostInChatAsCopyData,
      StoryCardPostInChatAsCopyArgs
    >({
      query: ({ chatId, sourceStoryId, sourceStoryCardId, chatComment }) => ({
        url: '/graphql/webapp?storyCardPostInChatAsCopy',
        method: 'POST',
        body: {
          query: `mutation StoryCardPostInChatAsCopy($input: StoryCardPostInChatAsCopyInput!) {
            storyCardPostInChatAsCopy(input: $input) {
              payload {
                id
              }
            }
          }`,
          variables: {
            input: {
              chatId,
              sourceStoryId,
              sourceStoryCardId,
              fields: { chatComment },
            },
          },
        },
      }),
      transformResponse: (response: StoryCardPostInChatAsCopyResponse) =>
        response.data.storyCardPostInChatAsCopy,
    }),
    storyCardPostInStoryAsSync: builder.query<
      StoryCardCopySyncMoveData,
      StoryCardPostInStoryAsSyncArgs
    >({
      query: ({ sourceStoryId, sourceStoryCardId, targetStoryId }) => ({
        url: '/graphql/webapp?storyCardPostInStoryAsSync',
        method: 'POST',
        body: {
          query: `mutation StoryCardPostInStoryAsSync($input: StoryCardPostInStoryAsSyncInput!) {
            storyCardPostInStoryAsSync(input: $input) {
              payload {
                id
              }
              error {
                __typename
                ... on SourceAndTargetStoryAreEqualErrorType {
                  message
                }
                ... on SourceStoryCardNotFoundError {
                  message
                }
                ... on StoryCardUrlUniquenessConflictError {
                  message
                }
                ... on StoryNotFoundError {
                  message
                }
                ... on StoryCardNotFoundError {
                  message
                }
                ... on StoryReadOnlyAccessError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { sourceStoryId, sourceStoryCardId, targetStoryId },
          },
        },
      }),
      transformResponse: (response: StoryCardPostInStoryAsSyncResponse) =>
        response.data.storyCardPostInStoryAsSync,
    }),
    storyCardReaction: builder.query<ReactionsCount[], StoryCardReactionArgs>({
      query: ({ storyId, cardId, reaction }) => ({
        url: '/graphql/webapp?storyCardReaction',
        method: 'POST',
        body: {
          query: `mutation StoryCardReaction($input: StoryCardReactionInput!) {
            storyCardReaction(input: $input) {
              payload {
                count
                name
              }
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { storyId, storyCardId: cardId, reaction },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardReactionResponse) =>
        response.data.storyCardReaction.payload,
    }),
    storyCardComments: builder.query<StoryCardCommentsPayload, StoryCardCommentsArgs>({
      query: ({ itemId, storyId, parentId, page = 1, size = 10 }) => ({
        url: '/graphql/webapp?storyCardComments',
        method: 'POST',
        body: {
          query: `query StoryCardComments($itemId: Int!, $storyId: Int, $parentId: Int, $page: Int, $size: Int) {
            storyCardComments(
              itemId: $itemId, 
              storyId: $storyId, 
              parentId: $parentId, 
              page: $page, 
              size: $size,
              filters: {showHidden: ${isAdminLayout()}},
              orderDirection: ${parentId ? OrderDirection.ASC : OrderDirection.DESC}) {
              payload {
                items {
                  ${commentQuery(2, OrderDirection.ASC)}
                }
                pageInfo {
                  page
                }
              }
            }
          }`,
          variables: { itemId, storyId, parentId, page, size },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardCommentsResponse) =>
        response.data.storyCardComments.payload,
    }),
    storyCardNewsfeedPinnedStatusUpdate: builder.query<
      boolean,
      StoryCardNewsfeedPinnedStatusUpdateArgs
    >({
      query: ({ storyId, storyCardId }) => ({
        url: '/graphql/webapp?storyCardNewsfeedPinnedStatusUpdate',
        method: 'POST',
        body: {
          query: `mutation StoryCardNewsfeedPinnedStatusUpdate($input: StoryCardNewsfeedPinnedStatusUpdateInput!) {
            storyCardNewsfeedPinnedStatusUpdate(input: $input) {
              payload
              error {
                ... on StoryCardNewsFeedPinnedStatusError {
                  message
                }
                ... on StoryCardNotFoundError {
                  message
                }
                ... on StoryNotFoundError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { storyId, storyCardId },
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardNewsfeedPinnedStatusUpdateResponse) =>
        response.data.storyCardNewsfeedPinnedStatusUpdate.payload,
    }),
    storyCardSourceById: builder.query<StoryCardSource, StoryCardSourceByIdArgs>({
      query: ({ itemId }) => ({
        url: '/graphql/webapp?storyCardSourceById',
        method: 'POST',
        body: {
          query: `query StoryCardSourceById($itemId: Int!) {
            storyCardSourceById(itemId: $itemId) {
              payload {
                story {
                  id
                }
                channel {
                  subdomain
                }
              }
            }
          }`,
          variables: { itemId },
        },
      }),
      transformResponse: (response: StoryCardSourceByIdResponse) =>
        response.data.storyCardSourceById.payload,
    }),
    storyCardRepost: builder.query<StoryCard, StoryCardRepostArgs>({
      query: ({ storyId, storyCardId }) => ({
        url: '/graphql/webapp?storyCardRepost',
        method: 'POST',
        body: {
          query: `mutation StoryCardRepost($input: StoryCardRepostInput!) {
            storyCardRepost(input: $input) {
              payload {
                id
                dateToDisplay
              }
              error {
                ... on StoryCardNotFoundError {
                  message
                }
                ... on StoryCardReadOnlyAccessError {
                  message
                }
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { storyId, storyCardId },
          },
        },
      }),
      transformResponse: (response: StoryCardRepostResponse) =>
        response.data.storyCardRepost.payload,
    }),
    storyCardCreateDeeplink: builder.query<string, StoryCardCreateDeeplinkArgs>({
      query: ({ storyId, storyCardId }) => ({
        url: '/graphql/webapp?storyCardCreateDeeplink',
        method: 'POST',
        body: {
          query: `mutation StoryCardCreateDeeplink($input: StoryCardCreateDeeplinkInput!) {
            storyCardCreateDeeplink(input: $input) {
              payload
              error {
                ... on DeeplinkKeyIsInactiveError {
                  message
                }
                ... on StoryCardNotFoundError {
                  message
                }
                ... on StoryNotFoundError {
                  message
                }
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { storyId, storyCardId },
          },
        },
      }),
      transformResponse: (response: StoryCardCreateDeeplinkResponse) =>
        response.data.storyCardCreateDeeplink.payload,
    }),
    storyCardParseUrlAsNative: builder.query<
      StoryCardParsedUrlAsNative,
      StoryCardParseUrlAsNativeArgs
    >({
      query: ({ url }) => ({
        url: '/graphql/webapp?storyCardParseUrlAsNative',
        method: 'POST',
        body: {
          query: `mutation StoryCardParseUrlAsNative($input: StoryCardParseUrlInput!) {
            storyCardParseUrlAsNative(input: $input) {
              payload {
                ... on StoryCardEditorialParsedUrl {
                  headline
                  sourceName
                  subHeadline
                  text
                }
                ... on StoryCardImageParsedUrl {
                  headline
                  sourceName
                  subHeadline
                  text
                  gallery {
                    image {
                      id
                      originalFilename
                      url
                      averageColor
                      rightholder
                    }
                    title
                  }
                }
              }
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { url },
          },
        },
      }),
      transformResponse: (response: StoryCardParseUrlAsNativeResponse) =>
        response.data.storyCardParseUrlAsNative.payload,
    }),
    storyCardParseUrlAsPost: builder.query<StoryCardPostParsedUrl, StoryCardParseUrlAsPostArgs>({
      query: ({ url }) => ({
        url: '/graphql/webapp?storyCardParseUrlAsPost',
        method: 'POST',
        body: {
          query: `mutation StoryCardParseUrlAsPost($input: StoryCardParseUrlInput!) {
            storyCardParseUrlAsPost(input: $input) {
              payload {
                title
                sourceName
                abstract
                contentAuthor
                contentBlocks {
                 ${contentBlocksQuery}
                }
                gallery {
                  image {
                    id
                    originalFilename
                    url
                    averageColor
                    rightholder
                  }
                }
              }
              error {
                ... on UnknownError {
                  message
                }
              }
            }
          }`,
          variables: {
            input: { url },
          },
        },
      }),
      transformResponse: (response: StoryCardParseUrlAsPostResponse) =>
        response.data?.storyCardParseUrlAsPost?.payload,
    }),
    storyCardsOrganisationTagFeed: builder.query<
      StoryCardsOrganisationTagFeedPayload,
      StoryCardsOrganisationTagFeedArgs
    >({
      query: ({ channelId, organisationTagId, page = 1, size = 10, filters }) => ({
        url: '/graphql/webapp?storyCardsOrganisationTagFeed',
        method: 'POST',
        body: {
          query: `query StoryCardsOrganisationTagFeed($channelId: Int!, $organisationTagId: Int!, $page: Int, $size: Int, $filters: GetStoryCardsOrganisationTagFeedFiltersArgs) {
            storyCardsOrganisationTagFeed(channelId: $channelId, organisationTagId: $organisationTagId, page: $page, size: $size, filters: $filters) {
              items {
                ${storyCardQuery({ isFeed: true, isAdminLayout: isAdminLayout() })}
              }
              pageInfo {
                page
                hasNextPage
              }
            }
          }`,
          variables: {
            channelId,
            organisationTagId,
            page,
            size,
            filters,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardsOrganisationTagFeedResponse) =>
        response.data.storyCardsOrganisationTagFeed,
    }),
    storyCardsChannelSearch: builder.query<
      StoryCardsChannelSearchPayload,
      StoryCardsChannelSearchArgs
    >({
      query: ({
        channelId,
        page = 1,
        size = 10,
        orderDirection = OrderDirection.DESC,
        filters,
      }) => ({
        url: '/graphql/webapp?storyCardsChannelSearch',
        method: 'POST',
        body: {
          query: `query StoryCardsChannelSearch($channelId: Int!, $page: Int, $size: Int, $orderDirection: OrderDirectionEnum!, $filters: GetStoryCardsChannelSearchFiltersArgs!) {
            storyCardsChannelSearch(channelId: $channelId, page: $page, size: $size, orderDirection: $orderDirection, filters: $filters) {
              payload {
                items {
                  ${storyCardQuery({ isFeed: true, isAdminLayout: isAdminLayout() })}
                }
                pageInfo {
                  page
                  hasNextPage
                  totalItems
                }
              }
            }
          }`,
          variables: {
            channelId,
            page,
            size,
            orderDirection,
            filters,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardsChannelSearchResponse) => {
        if (response?.errors?.length) {
          return {
            items: [],
            pageInfo: { totalItems: 0, page: 0, hasNextPage: false } as PaginationInfo,
          };
        }

        return response.data.storyCardsChannelSearch.payload;
      },
    }),
    storyCardCategories: builder.query<StoryCardCategory[], void>({
      query: () => ({
        url: '/graphql/webapp?storyCardCategories',
        method: 'POST',
        body: {
          query: `query StoryCardCategories {
            storyCardCategories {
              items {
                id
                name
                channel {
                  name
                }
                settings {
                  userSelection
                  emailNotification
                  editableMedia
                  timeToLive
                  buttonTextEn
                  buttonTextDe
                  showAsOption
                  showAsButton
                }
                templates {
                  name
                  text
                  gallery {
                    image {
                      id
                      url
                    }
                  }
                }
                story {
                  id
                  title
                }
              }
            }
          }`,
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardCategoriesResponse) =>
        response.data.storyCardCategories.items,
    }),
    storyCardCategoryCreate: builder.query<StoryCardCategory, StoryCardCategoryCreateArgs>({
      query: ({ name, settings, templates, storyId }) => ({
        url: '/graphql/webapp?storyCardCategoryCreate',
        method: 'POST',
        body: {
          query: `mutation StoryCardCategoryCreate($input: StoryCardCategoryCreateInput!) {
            storyCardCategoryCreate(input: $input) {
              payload {
                id
              }
            }
          }`,
          variables: {
            input: {
              fields: { name, settings, templates, storyId },
            },
          },
        },
      }),
      transformResponse: (response: StoryCardCategoryCreateResponse) =>
        response.data.storyCardCategoryCreate.payload,
    }),
    storyCardCategoryUpdate: builder.query<StoryCardCategory, StoryCardCategoryUpdateArgs>({
      query: ({ categoryId, name, settings, templates, storyId }) => ({
        url: '/graphql/webapp?storyCardCategoryUpdate',
        method: 'POST',
        body: {
          query: `mutation StoryCardCategoryUpdate($input: StoryCardCategoryUpdateInput!) {
            storyCardCategoryUpdate(input: $input) {
              payload {
                id
              }
            }
          }`,
          variables: {
            input: {
              categoryId,
              fields: { name, settings, templates, storyId },
            },
          },
        },
      }),
      transformResponse: (response: StoryCardCategoryUpdateResponse) =>
        response.data.storyCardCategoryUpdate.payload,
    }),
    storyCardCategoryDelete: builder.query<boolean, StoryCardCategoryDeleteArgs>({
      query: ({ categoryId, forceDelete }) => ({
        url: '/graphql/webapp?storyCardCategoryDelete',
        method: 'POST',
        body: {
          query: `mutation StoryCardCategoryDelete($input: StoryCardCategoryDeleteInput!) {
            storyCardCategoryDelete(input: $input) {
              payload
            }
          }`,
          variables: {
            input: { categoryId, forceDelete },
          },
        },
      }),
      transformResponse: (response: StoryCardCategoryDeleteResponse) =>
        response.data.storyCardCategoryDelete.payload,
    }),
    storyCardCategoryDefaultTemplates: builder.query<StoryCardCategoryTemplate[], void>({
      query: () => ({
        url: '/graphql/webapp?storyCardCategoryDefaultTemplates',
        method: 'POST',
        body: {
          query: `query StoryCardCategoryDefaultTemplates {
            storyCardCategoryDefaultTemplates {
              name
              text
              gallery {
                image {
                  id
                  url
                }
              }
            }
          }`,
        },
      }),
      transformResponse: (response: StoryCardCategoryDefaultTemplatesResponse) =>
        response.data.storyCardCategoryDefaultTemplates,
    }),
    storyCardCategoriesByChannelId: builder.query<
      StoryCardCategory[],
      StoryCardCategoriesByChannelIdArgs
    >({
      query: ({ channelId }) => ({
        url: '/graphql/webapp?storyCardCategoriesByChannelId',
        method: 'POST',
        body: {
          query: `query StoryCardCategoriesByChannelId($channelId: Int!) {
            storyCardCategoriesByChannelId(channelId: $channelId) {
              items {
                id
                name
                settings {
                  userSelection
                  emailNotification
                  editableMedia
                  timeToLive
                  buttonTextEn
                  buttonTextDe
                  showAsOption
                  showAsButton
                }
                templates {
                  name
                  text
                  gallery {
                    image {
                      id
                      url
                    }
                  }
                }
                story {
                  id
                  title
                }
              }
            }
          }`,
          variables: { channelId },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardCategoriesByChannelIdResponse) =>
        response.data.storyCardCategoriesByChannelId.items,
    }),
    storyCardsUsedTags: builder.query<OrganisationTag[], StoryCardsUsedTagsArgs>({
      query: ({ channelId, filters, page = 1, size = ORG_TAGS_SIZE_LIMIT }) => ({
        url: '/graphql/webapp?storyCardsUsedTags',
        method: 'POST',
        body: {
          query: `query StoryCardsUsedTags($channelId: Int!, $filters: GetStoryCardsUsedTagsFiltersArgs, $page: Int, $size: Int) {
            storyCardsUsedTags(channelId:$channelId, filters: $filters, page: $page, size: $size,) {
              items {
                id
                name
              }
            }
          }`,
          variables: {
            channelId,
            filters,
            page,
            size,
          },
        },
      }),
      forceRefetch: () => true,
      transformResponse: (response: StoryCardsUsedTagsResponse) =>
        response.data.storyCardsUsedTags.items,
    }),
  }),
});

export const {
  useStoryCardByIdQuery,
  useStoryCardParseUrlQuery,
  useStoryCardPostInStoryQuery,
  useStoryCardDeleteQuery,
  useStoryCardsFeedQuery,
  useStoriesCardsUserFeedStatisticQuery,
  useStoriesCardsUserFeedQuery,
  useStoryCardAuthorsFiltersByStoryIdQuery,
  useStoryCardsOrganisationTagFeedQuery,
  useStoryCardCategoriesQuery,
  useStoryCardCategoryDefaultTemplatesQuery,
  useStoryCardCategoriesByChannelIdQuery,
  useStoryCardsUsedTagsQuery,
} = storyCardApi;
