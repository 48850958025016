import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { format, isSameDay, isToday, isYesterday } from 'date-fns';
import { FetchedMessageWithActions } from 'pubnub/lib/types/core/types/api/history';
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EmbedLink } from '../../../components';
import { ChatContext, ConfigContext, UserContext } from '../../../context';
import {
  ChatAccess,
  ChatAccessTypes,
  ChatMessageType,
  chatsApi,
  ChatTypes,
  graphqlChatsApi,
  MessageTypes,
  organisationApi,
  StoryCard,
  storyCardApi,
  StoryCardArticleParsedUrl,
  StoryCardFieldsPostInChatInput,
  StoryCardImageParsedUrl,
  StoryCardParseUrlPayload,
  StoryCardQuoteParsedUrl,
  StoryCardType,
} from '../../../services';
import {
  AbuseReportType,
  Avatar,
  CHAT_LOAD_MESSAGES_LIMIT,
  CHAT_TYPING_USERS_LIMIT,
  CircularLoader,
  ConfirmationModal,
  CookieActionType,
  cookieOptions,
  dateToTimeToken,
  Deeplink,
  DeeplinkType,
  DropdownMenu,
  DSAReportType,
  EmojiSelectProps,
  FileAcceptType,
  GalleryItem,
  generateTeaserUrl,
  getAcceptTypeByFileType,
  getCssVar,
  getDateFnsLocale,
  getEmojiPickerLocale,
  IconLabel,
  IconLabelHintDirection,
  IconLabelSizes,
  ImageUploadType,
  isAdminLayout,
  isMediaParsed,
  isUrl,
  layoutPath,
  LinkData,
  Modal,
  Popup,
  TextAreaField,
  timeTokenToDate,
  unescape,
  urlToFile,
  useAbuseReportPopup,
  useBeforeUnload,
  useDSAReportPopup,
  useLoaderText,
  useOnClickOutside,
  usePrevious,
  useUpload,
} from '../../../shared';
import { ChatDetails, ChatDetailsType } from '../ChatDetails';
import { ChatTabsTypes } from '../Chats';
import { getChatCookieKey } from '../helpers';
import { JoinChat } from '../JoinChat';
import { Message } from './Message';
import { MessageGallery } from './MessageGallery';
import { ServerMessage } from './ServerMessage';

import classNames from 'classnames';
import dropdownMenuClasses from '../../../shared/components/DropdownMenu/DropdownMenu.module.scss';
import classes from './ChatHistory.module.scss';

export interface MessageReactionData {
  uuid: string;
  actionTimetoken: string;
}

export interface MessageReaction {
  like?: MessageReactionData[];
  love?: MessageReactionData[];
  haha?: MessageReactionData[];
  wow?: MessageReactionData[];
  sad?: MessageReactionData[];
  angry?: MessageReactionData[];
}

export interface ChatsMessage {
  [p: string]: string;
}

export interface ChatsGallery {
  [p: string]: GalleryItem[];
}

export interface ChatsFileLoading {
  [p: string]: boolean;
}

interface ParseUrlData {
  url: string;
  payload: StoryCardParseUrlPayload;
}

export const ChatHistory: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { uploadHandler, getUploadType, getTemplateType, onUploadCompleted } = useUpload();

  const { uploadHandler: teaserUploadHandler } = useUpload();

  const [parseUrl] = storyCardApi.endpoints.storyCardParseUrl.useLazyQuery();

  const [getChannelChats] = chatsApi.endpoints.getChannelChats.useLazyQuery();

  const [changeMessage] = chatsApi.endpoints.changeMessage.useMutation();

  const [leaveChat] = chatsApi.endpoints.leaveChat.useMutation();

  const [deleteChat] = chatsApi.endpoints.deleteChat.useMutation();

  const [organisationUsers] = organisationApi.endpoints.organisationUsers.useLazyQuery();

  const [getChatItems] = graphqlChatsApi.endpoints.chatStoryCardsFeed.useLazyQuery();

  const [storyCardPostInChat] = storyCardApi.endpoints.storyCardPostInChat.useLazyQuery();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const {
    pubnub,
    activeChat,
    setActiveChat,
    channels,
    setChannels,
    channelsChanges,
    setChannelsChanges,
    setChats,
    setSelectedTabIndex,
    setChannelsUnreadCount,
    chatUsers,
    setChatUsers,
    channelTypingUsers,
  } = useContext(ChatContext);

  const { id, screenName } = useContext(UserContext).userProfile;

  const {
    abuseReport: { enabled: abuseReportEnabled },
    dsaReport: { enabled: dsaReportEnabled },
  } = useContext(ConfigContext).config.elements;

  const {
    chatId = 0,
    type = ChatTypes.group,
    chatDescriptor = '',
    name = '',
    image = null,
    access = null,
    thumbnails = [],
    recipientId = 0,
    accessType,
  } = { ...activeChat };

  const reportArgs = useMemo(() => {
    return {
      chatId,
      chatName: name,
      chatType: type,
    };
  }, [chatId, name, type]);

  const { abuseReportModal, abuseReportHandler } = useAbuseReportPopup({
    type: AbuseReportType.Chat,
    ...reportArgs,
  });

  const { DSAReportModal, DSAReportHandler } = useDSAReportPopup({
    type: DSAReportType.Chat,
    ...reportArgs,
  });

  const chatUsersIds = useMemo(() => chatUsers.map(({ id }) => id), [chatUsers]);

  const isP2P = useMemo(() => type === ChatTypes.p2p, [type]);

  const avatarUrl = useMemo(
    () => (isP2P ? thumbnails[0]?.image : image?.url),
    [image?.url, isP2P, thumbnails]
  );

  const avatarFallBackIconId = useMemo(() => (isP2P ? 'avatar' : 'avatars'), [isP2P]);

  const fetchedMessages = useMemo(
    () => channels?.[chatDescriptor] ?? [],
    [channels, chatDescriptor]
  );

  const chatChangesDescriptor = useMemo(() => `${chatDescriptor}.changes`, [chatDescriptor]);

  const fetchedChanges = useMemo(
    () => channelsChanges?.[chatChangesDescriptor] ?? [],
    [channelsChanges, chatChangesDescriptor]
  );

  const hasAdminAccess = useMemo(() => access === ChatAccess.admin, [access]);

  const hasRWAccess = useMemo(() => access === ChatAccess.rw, [access]);

  const messageAllowed = useMemo(
    () => hasAdminAccess || hasRWAccess,
    [hasAdminAccess, hasRWAccess]
  );

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [linkParseLoading, setLinkParseLoading] = useState(false);

  const [isActionsOpen, setIsActionsOpen] = useState(false);

  const [isDeleteChatOpen, setIsDeleteChatOpen] = useState(false);

  const [deleteMessageToken, setDeleteMessageToken] = useState('');

  const [chatDetailsType, setChatDetailsType] = useState<ChatDetailsType | null>(null);

  const [chatsMessages, setChatsMessages] = useState<ChatsMessage | null>(null);

  const [parseUrlData, setParseUrlData] = useState<ParseUrlData | null>(null);

  const message = useMemo(
    () => chatsMessages?.[chatDescriptor] ?? '',
    [chatDescriptor, chatsMessages]
  );

  const setMessage = useCallback(
    (value: string) => {
      setChatsMessages({ ...chatsMessages, [chatDescriptor]: value });
    },
    [chatDescriptor, chatsMessages]
  );

  const [showScrollToBottom, setShowScrollToBottom] = useState<boolean>(false);

  const [showScrollToBottomNewMessage, setShowScrollToBottomNewMessage] = useState<boolean>(false);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [avatarPreviewOpen, setAvatarPreviewOpen] = useState<boolean>(false);

  const emojiPickerRef = useRef(null);

  const messageRef = useRef<HTMLTextAreaElement>(null);

  const isTyping = useRef(false);

  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const [storyCards, setStoryCards] = useState<StoryCard[]>([]);

  const storyCardsIds = useMemo(() => storyCards.map(({ id }) => id), [storyCards]);

  const [chatsFileLoading, setChatsFileLoading] = useState<ChatsFileLoading | null>(null);

  const fileLoading = useMemo(
    () => chatsFileLoading?.[chatDescriptor] ?? false,
    [chatDescriptor, chatsFileLoading]
  );

  const setFileLoading = useCallback(
    (value: boolean) => {
      setChatsFileLoading({ ...chatsFileLoading, [chatDescriptor]: value });
    },
    [chatDescriptor, chatsFileLoading]
  );

  const { loaderText } = useLoaderText(fileLoading);

  const [chatsGallery, setChatsGallery] = useState<ChatsGallery | null>(null);

  const gallery = useMemo(
    () => chatsGallery?.[chatDescriptor] ?? [],
    [chatDescriptor, chatsGallery]
  );

  const setGallery = useCallback(
    (value: GalleryItem[]) => {
      setChatsGallery({ ...chatsGallery, [chatDescriptor]: value as GalleryItem[] });
    },
    [chatDescriptor, chatsGallery]
  );

  const hasGallery = useMemo(() => Boolean(gallery.length), [gallery.length]);

  const fileRef = useRef<HTMLInputElement | null>(null);

  const lastMessage = useMemo(() => fetchedMessages[fetchedMessages.length - 1], [fetchedMessages]);

  const lastMessageTimetokenPrev = usePrevious(lastMessage?.timetoken) ?? '';

  const typingUsersCount = useMemo(
    () => channelTypingUsers?.[chatDescriptor]?.length ?? 0,
    [channelTypingUsers, chatDescriptor]
  );

  useOnClickOutside(emojiPickerRef, () => {
    setShowEmojiPicker(false);
  });

  const scrollToBottom = useCallback(() => {
    document.querySelector('.chat-history__body-messages-scroll')?.scrollIntoView({ block: 'end' });
  }, []);

  const chatCookieKey = useMemo(() => getChatCookieKey(chatDescriptor), [chatDescriptor]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie, removeCookie] = useCookies([chatCookieKey]);

  const readMessages = useCallback(() => {
    setChannelsUnreadCount((channelsUnreadCountPrev) => {
      return { ...channelsUnreadCountPrev, [chatDescriptor]: 0 };
    });

    setCookie(
      chatCookieKey,
      { timetoken: lastMessage?.timetoken ?? dateToTimeToken(new Date()) },
      cookieOptions()
    );
  }, [chatCookieKey, chatDescriptor, lastMessage?.timetoken, setChannelsUnreadCount, setCookie]);

  const getUniqueAuthorIds = useCallback(() => {
    if (!fetchedMessages.length) {
      return [];
    }

    const authors = new Set<number>();

    fetchedMessages.forEach((item) => {
      const { message } = { ...item };
      const { author = 0 } = { ...(message as { author: number }) };

      if (author && !chatUsersIds.includes(author)) {
        authors.add(author);
      }
    });

    return Array.from(authors);
  }, [chatUsersIds, fetchedMessages]);

  const getChatUsers = useCallback(async () => {
    const usersId = getUniqueAuthorIds();

    if (!usersId.length) {
      return;
    }

    const { items } = await organisationUsers({
      size: CHAT_LOAD_MESSAGES_LIMIT,
      filter: { usersId },
    }).unwrap();

    setChatUsers(
      [...chatUsers, ...items].filter(
        (item, index, self) => index === self.findIndex((user) => user.id === item.id)
      )
    );
  }, [chatUsers, getUniqueAuthorIds, organisationUsers, setChatUsers]);

  const getChatStoryCards = useCallback(async () => {
    const cardsIds = fetchedMessages
      .filter(
        ({ message, timetoken }) =>
          (message as { type: string }).type === ChatMessageType.post &&
          !storyCardsIds.includes((message as { data: { id: number } }).data.id) &&
          !fetchedChanges.find(
            ({ message }) => (message as { messageId: string }).messageId === timetoken
          )
      )
      .map(({ message }) => (message as { data: { id: number } }).data.id);

    if (!cardsIds.length) {
      return;
    }

    const { items } = await getChatItems({ chatId, ids: cardsIds }).unwrap();

    if (cardsIds.length) {
      setStoryCards([...storyCards, ...items]);
    }
  }, [chatId, fetchedChanges, fetchedMessages, getChatItems, storyCards, storyCardsIds]);

  const fetchMessages = useCallback(() => {
    pubnub.fetchMessages(
      {
        channels: [chatChangesDescriptor],
        count: CHAT_LOAD_MESSAGES_LIMIT,
      },
      (_, response) => {
        if (!response?.channels) {
          return;
        }

        setChannelsChanges((channelsChangesPrev) => {
          return {
            ...channelsChangesPrev,
            [chatChangesDescriptor]: response.channels[chatChangesDescriptor] ?? [],
          };
        });
      }
    );

    pubnub.fetchMessages(
      {
        channels: [chatDescriptor],
        includeMessageActions: true,
        count: CHAT_LOAD_MESSAGES_LIMIT,
      },
      (_, response) => {
        if (!response?.channels) {
          return;
        }

        setChannels((channelsPrev) => {
          return {
            ...channelsPrev,
            [chatDescriptor]: response.channels[chatDescriptor] ?? [],
          };
        });
      }
    );
  }, [chatChangesDescriptor, chatDescriptor, pubnub, setChannels, setChannelsChanges]);

  const fetchMessagesLoadMore = useCallback(() => {
    pubnub.fetchMessages(
      {
        channels: [chatChangesDescriptor],
        count: CHAT_LOAD_MESSAGES_LIMIT,
        start: fetchedChanges[0]?.timetoken?.toString(),
      },
      (_, response) => {
        if (!response?.channels) {
          return;
        }

        setChannelsChanges((channelsChangesPrev) => {
          return {
            ...channelsChangesPrev,
            [chatChangesDescriptor]: [
              ...(response.channels[chatChangesDescriptor] ?? []),
              ...(channelsChangesPrev?.[chatChangesDescriptor] ?? []),
            ],
          };
        });
      }
    );

    pubnub.fetchMessages(
      {
        channels: [chatDescriptor],
        includeMessageActions: true,
        count: CHAT_LOAD_MESSAGES_LIMIT,
        start: fetchedMessages[0]?.timetoken?.toString(),
      },
      (_, response) => {
        if (!response?.channels) {
          return;
        }

        setChannels((channelsPrev) => {
          return {
            ...channelsPrev,
            [chatDescriptor]: [
              ...(response.channels[chatDescriptor] ?? []),
              ...(channelsPrev?.[chatDescriptor] ?? []),
            ],
          };
        });
      }
    );
  }, [
    chatChangesDescriptor,
    chatDescriptor,
    fetchedChanges,
    fetchedMessages,
    pubnub,
    setChannels,
    setChannelsChanges,
  ]);

  const setTyping = useCallback(
    (typing: boolean) => {
      isTyping.current = typing;
      pubnub.signal({ channel: chatDescriptor, message: { typing, authorName: screenName } });
    },
    [chatDescriptor, pubnub, screenName]
  );

  useBeforeUnload(() => setTyping(false));

  const scrollMessageIndicator = useCallback(() => {
    if (!showScrollToBottom) {
      return;
    }

    if (lastMessageTimetokenPrev !== lastMessage?.timetoken) {
      setShowScrollToBottomNewMessage(true);
    }
  }, [lastMessage?.timetoken, lastMessageTimetokenPrev, showScrollToBottom]);

  useEffect(() => {
    if (!showScrollToBottomNewMessage) {
      return;
    }

    if (!showScrollToBottom) {
      setShowScrollToBottomNewMessage(false);
    }
  }, [showScrollToBottom, showScrollToBottomNewMessage]);

  useEffect(() => {
    if (!chatDescriptor || !access) {
      return;
    }

    fetchMessages();

    messageRef.current?.focus();

    scrollToBottom();

    return () => setShowScrollToBottom(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatDescriptor, access]);

  useEffect(() => {
    if (!chatDescriptor || !access) {
      return;
    }

    getChatStoryCards();

    getChatUsers();

    readMessages();

    scrollMessageIndicator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fetchedMessages), chatDescriptor, access]);

  const typingUsers = useMemo(() => {
    if (!channelTypingUsers?.[chatDescriptor]) {
      return null;
    }

    const filteredUsers = channelTypingUsers[chatDescriptor].filter((user) => user.id !== id);

    if (!filteredUsers.length) {
      return null;
    }

    const typingMessage = `${filteredUsers[0].screenName} ${
      filteredUsers.length === 1
        ? t('chatActions.is-typing')
        : t('chatActions.are-typing', { count: filteredUsers.length - 1 })
    }`;

    return <div className={classes['chat-history__body-typing-users']}>{typingMessage}</div>;
  }, [channelTypingUsers, chatDescriptor, id, t]);

  const postInCardType = useMemo(() => {
    const parseUrlType = parseUrlData?.payload?.type;

    switch (true) {
      case Boolean(gallery.find((galleryItem) => Boolean(galleryItem?.audio))):
        return StoryCardType.AUDIO;
      case Boolean(gallery.find((galleryItem) => Boolean(galleryItem?.video))):
        return StoryCardType.VIDEO;
      case Boolean(gallery.find((galleryItem) => Boolean(galleryItem?.pdf))):
        return StoryCardType.PDF;
      case Boolean(gallery.find((galleryItem) => Boolean(galleryItem?.image))):
        return StoryCardType.IMAGE;
      case Boolean(parseUrlType):
        return parseUrlType;
      default:
        return '';
    }
  }, [gallery, parseUrlData?.payload?.type]);

  const galleryMap = useCallback((galleryItems?: GalleryItem[]) => {
    return galleryItems?.map((galleryItem) => {
      const { image, audio, video, pdf } = galleryItem;

      const { id } = { ...image };

      const imageMapped = id && { image: { id } };

      switch (true) {
        case Boolean(audio):
          return { audio: { id: audio?.id, useDefaultThumb: true }, ...imageMapped };
        case Boolean(video):
          return { video: { id: video?.id, useDefaultThumb: true }, ...imageMapped };
        case Boolean(pdf):
          return { pdf: { id: pdf?.id, useDefaultThumb: true }, ...imageMapped };
        case Boolean(image): {
          return { ...imageMapped };
        }
        default:
          return null;
      }
    });
  }, []);

  const postInFields = useMemo(() => {
    if (hasGallery) {
      const mappedGallery = { gallery: galleryMap(gallery) };

      return {
        ...(postInCardType === StoryCardType.IMAGE && { imageFields: mappedGallery }),
        ...(postInCardType === StoryCardType.AUDIO && { audioFields: mappedGallery }),
        ...(postInCardType === StoryCardType.VIDEO && { videoFields: mappedGallery }),
        ...(postInCardType === StoryCardType.PDF && { pdfFields: mappedGallery }),
      };
    }

    if (parseUrlData) {
      const { url, payload } = parseUrlData;

      const { type, gallery, ...payloadFields } = payload;

      const mappedGallery = galleryMap(gallery as GalleryItem[]);

      return {
        ...(postInCardType === StoryCardType.IMAGE && { imageFields: { gallery: mappedGallery } }),
        ...(postInCardType === StoryCardType.ARTICLE && {
          articleFields: { ...payloadFields, url, gallery: mappedGallery, headline: '' },
        }),
        ...(postInCardType === StoryCardType.QUOTE && {
          quoteFields: {
            ...payloadFields,
            url,
            gallery: mappedGallery,
            headline: '',
            quotePersonImage: undefined,
          },
        }),
      };
    }

    return;
  }, [gallery, galleryMap, hasGallery, parseUrlData, postInCardType]);

  const publishMessage = useCallback(async () => {
    if (!activeChat || Boolean(!postInFields && !message.length)) {
      return;
    }

    if (postInFields) {
      storyCardPostInChat({
        chatId,
        fields: { ...postInFields, chatComment: message } as StoryCardFieldsPostInChatInput,
      });

      setGallery([]);

      setParseUrlData(null);
    } else {
      pubnub.publish({
        channel: chatDescriptor,
        message: {
          chatId,
          chatDescriptor,
          chatName: name,
          chatType: type,
          channel: channelId,
          author: id,
          authorName: screenName,
          type: ChatMessageType.text,
          text: message,
          mentions: [],
          nonMemberMentions: [],
        },
      });
    }

    setMessage('');

    scrollToBottom();

    setTimeout(() => messageRef.current?.focus(), 0);
  }, [
    activeChat,
    channelId,
    chatDescriptor,
    chatId,
    id,
    message,
    name,
    postInFields,
    pubnub,
    screenName,
    scrollToBottom,
    setGallery,
    setMessage,
    storyCardPostInChat,
    type,
  ]);

  const openChatDetails = useCallback((type: ChatDetailsType) => {
    setIsActionsOpen(false);
    setChatDetailsType(type);
  }, []);

  const deleteHandler = useCallback(() => {
    setIsActionsOpen(false);
    setIsDeleteChatOpen(true);
  }, []);

  const leaveHandler = useCallback(async () => {
    setIsActionsOpen(false);

    try {
      await leaveChat({ chatId }).unwrap();

      pubnub.unsubscribe({ channels: [chatDescriptor, `${chatDescriptor}.changes`] });

      const { chats, tokenV3 } = await getChannelChats().unwrap();

      pubnub.setAuthKey(tokenV3);

      setChannels((channelsPrev) => {
        const channelsPrevCopy = { ...channelsPrev };

        delete channelsPrevCopy[chatDescriptor];

        return channelsPrevCopy;
      });

      setChannelsChanges((channelsChangesPrev) => {
        const channelsChangesPrevCopy = { ...channelsChangesPrev };

        delete channelsChangesPrevCopy[`${chatDescriptor}.changes`];

        return channelsChangesPrevCopy;
      });

      setChats(chats);

      setActiveChat(null);

      setSelectedTabIndex(ChatTabsTypes.all);

      navigate(layoutPath('/chats'));
    } catch (e) {
      toast.error(t('chatActions.leave-error'));
    }
  }, [
    chatDescriptor,
    chatId,
    getChannelChats,
    leaveChat,
    navigate,
    pubnub,
    setActiveChat,
    setChannels,
    setChannelsChanges,
    setChats,
    setSelectedTabIndex,
    t,
  ]);

  const abuseReportClick = useCallback(() => {
    setIsActionsOpen(false);
    abuseReportHandler();
  }, [abuseReportHandler]);

  const DSAReportClick = useCallback(() => {
    setIsActionsOpen(false);
    DSAReportHandler();
  }, [DSAReportHandler]);

  const chatDelete = useCallback(async () => {
    setDeleteLoading(true);

    try {
      await deleteChat({ chatId });

      const publishPayload = {
        channel: chatDescriptor,
        message: {
          type: MessageTypes.chatDelete,
          text: unescape(t('chatActions.removed', { name })),
        },
      };

      await pubnub.publish(publishPayload);

      removeCookie(chatCookieKey, cookieOptions({ action: CookieActionType.REMOVE }));

      setIsDeleteChatOpen(false);
    } catch (_) {
      toast.error(t('deleteConfirmation.error-message'));
    } finally {
      setDeleteLoading(false);
    }
  }, [chatCookieKey, chatDescriptor, chatId, deleteChat, name, pubnub, removeCookie, t]);

  const messageDelete = useCallback(async () => {
    setDeleteLoading(true);

    try {
      await changeMessage({ chatId, actionType: 'delete', messageId: deleteMessageToken });
      setDeleteMessageToken('');
    } catch (_) {
      toast.error(t('deleteConfirmation.error-message'));
    } finally {
      setDeleteLoading(false);
    }
  }, [changeMessage, chatId, deleteMessageToken, t]);

  const isGroupOrAdmin = useMemo(() => !isP2P || hasAdminAccess, [hasAdminAccess, isP2P]);

  const enableDeeplink = useMemo(
    () =>
      isAdminLayout() &&
      !isP2P &&
      [ChatAccessTypes.publicRead, ChatAccessTypes.publicWrite].includes(
        accessType as ChatAccessTypes
      ),
    [accessType, isP2P]
  );

  const dropdownMenuContent = useMemo(() => {
    return (
      <>
        {abuseReportEnabled && (
          <IconLabel
            iconId={'report'}
            iconSize={18}
            label={t('abuseReport.label')}
            className={dropdownMenuClasses['dropdown-menu__item--small']}
            onClick={abuseReportClick}
            singleColor
          />
        )}
        {dsaReportEnabled && (
          <IconLabel
            iconId={'problem'}
            iconSize={18}
            label={t('DSAReport.label')}
            className={dropdownMenuClasses['dropdown-menu__item--small']}
            onClick={DSAReportClick}
            singleColor
          />
        )}
        {enableDeeplink && (
          <Deeplink
            type={DeeplinkType.CHAT}
            chatId={chatId}
            className={dropdownMenuClasses['dropdown-menu__item--small']}
            onClick={() => setIsActionsOpen(false)}
          />
        )}
        {!isP2P && (
          <IconLabel
            iconId={'exit'}
            iconSize={18}
            label={t('chatActions.leave')}
            className={dropdownMenuClasses['dropdown-menu__item--small']}
            onClick={leaveHandler}
            color={getCssVar('--color-danger')}
            singleColor
          />
        )}
        {hasAdminAccess && (
          <IconLabel
            iconId={'delete'}
            iconSize={18}
            label={t('common.delete')}
            className={dropdownMenuClasses['dropdown-menu__item--small']}
            onClick={deleteHandler}
            color={getCssVar('--color-danger')}
            singleColor
          />
        )}
      </>
    );
  }, [
    DSAReportClick,
    abuseReportClick,
    abuseReportEnabled,
    chatId,
    deleteHandler,
    dsaReportEnabled,
    enableDeeplink,
    hasAdminAccess,
    isP2P,
    leaveHandler,
    t,
  ]);

  const onInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      const { key, shiftKey, target } = event;

      if (key === 'Enter' && !shiftKey && !fileLoading && !linkParseLoading) {
        event.preventDefault();
        publishMessage();

        (target as HTMLTextAreaElement).blur();
      }
    },
    [fileLoading, linkParseLoading, publishMessage]
  );

  const openProfile = useCallback(() => {
    if (!isP2P) {
      return;
    }

    navigate(layoutPath(`/profile/${recipientId}`));
  }, [isP2P, navigate, recipientId]);

  const onEmojiSelect = useCallback(
    ({ native }: EmojiSelectProps) => setMessage(`${message}${native}`),
    [message, setMessage]
  );

  const onMessageChange = useCallback(
    async (value: string) => {
      setMessage(value);

      if (!isTyping.current && typingUsersCount < CHAT_TYPING_USERS_LIMIT) {
        setTyping(true);
      }

      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      setTypingTimeout(setTimeout(() => setTyping(false), 1000));
    },
    [setMessage, setTyping, typingTimeout, typingUsersCount]
  );

  const onScroll = useCallback(({ target }: MouseEvent) => {
    setShowScrollToBottom(Boolean(Math.abs((target as HTMLElement).scrollTop) > 48));
  }, []);

  const renderContent = useCallback(
    ({
      fetchedMessage,
      nextFetchedMessage,
    }: {
      fetchedMessage: FetchedMessageWithActions;
      nextFetchedMessage: FetchedMessageWithActions;
    }) => {
      const { uuid, message } = fetchedMessage;

      if (uuid === 'server' && (message as { type: string }).type !== ChatMessageType.post) {
        return <ServerMessage fetchedMessage={fetchedMessage} />;
      }

      const { message: nextMessage } = nextFetchedMessage;

      const isNewAuthor =
        (message as { author: number })?.author !== (nextMessage as { author: number })?.author;

      return (
        <Message
          fetchedMessage={fetchedMessage}
          fetchedChanges={fetchedChanges}
          storyCards={storyCards}
          isNewAuthor={isNewAuthor}
          setDeleteMessageToken={setDeleteMessageToken}
        />
      );
    },
    [fetchedChanges, storyCards]
  );

  const renderDate = useCallback(
    ({
      fetchedMessage,
      nextFetchedMessage,
    }: {
      fetchedMessage: FetchedMessageWithActions;
      nextFetchedMessage: FetchedMessageWithActions;
    }) => {
      const { timetoken } = fetchedMessage;
      const { timetoken: nextTimeToken } = nextFetchedMessage;

      if (nextTimeToken && isSameDay(timeTokenToDate(timetoken), timeTokenToDate(nextTimeToken))) {
        return null;
      }

      const date = timeTokenToDate(timetoken);

      const locale = getDateFnsLocale();

      const day = (() => {
        switch (true) {
          case isToday(date):
            return t('common.today');
          case isYesterday(date):
            return t('common.yesterday');
          default:
            return format(date, 'EEEE', { locale });
        }
      })();

      return (
        <div className={classes['chat-history__body-messages-item-date']}>
          {day}, {format(date, 'P', { locale })}
        </div>
      );
    },
    [t]
  );

  const bodyMessages = useMemo(() => {
    return (
      <div id={'scrollable'} className={classes['chat-history__body-messages']}>
        <InfiniteScroll
          onScroll={onScroll}
          scrollableTarget={'scrollable'}
          className={classNames(
            classes['chat-history__body-messages-scroll'],
            'chat-history__body-messages-scroll'
          )}
          next={fetchMessagesLoadMore}
          dataLength={fetchedMessages.length}
          inverse={true}
          hasMore={true}
          loader={null}
        >
          {[...fetchedMessages].reverse().map((fetchedMessage, index, fetchedMessages) => {
            const { timetoken } = fetchedMessage;
            const nextFetchedMessage = { ...fetchedMessages[index + 1] };

            return (
              <div key={timetoken} className={classes['chat-history__body-messages-item-wrapper']}>
                {renderDate({ fetchedMessage, nextFetchedMessage })}
                {renderContent({ fetchedMessage, nextFetchedMessage })}
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    );
  }, [fetchMessagesLoadMore, fetchedMessages, onScroll, renderContent, renderDate]);

  const sendComponent = useMemo(() => {
    if (fileLoading || linkParseLoading) {
      return (
        <CircularLoader
          sizeRem={1.25}
          className={classes['chat-history__body-input-icons-loader']}
          text={loaderText}
        />
      );
    }

    if (!message.trim().length && !gallery.length) {
      return null;
    }

    return (
      <IconLabel
        iconId={'send'}
        iconSize={20}
        className={classes['chat-history__body-input-icons-send']}
        color={getCssVar('--chats-input-send-icon-color')}
        hoverColor={getCssVar('--chats-input-send-icon-hover-color')}
        onClick={publishMessage}
        disabled={fileLoading}
      />
    );
  }, [fileLoading, gallery.length, linkParseLoading, loaderText, message, publishMessage]);

  const uploadLimit = useMemo(() => {
    const { image, audio, video, pdf } = { ...gallery[0] };

    switch (true) {
      case Boolean(audio):
      case Boolean(video):
      case Boolean(pdf):
        return 1;
      case Boolean(image):
        return 20;
    }
  }, [gallery]);

  const hideUpload = useMemo(
    () => uploadLimit && gallery.length >= uploadLimit,
    [gallery.length, uploadLimit]
  );

  const acceptTypes = useMemo(() => {
    if (hasGallery) {
      return FileAcceptType.IMAGE;
    }

    return `${FileAcceptType.IMAGE}, ${FileAcceptType.AUDIO}, ${FileAcceptType.VIDEO}, ${FileAcceptType.PDF}`;
  }, [hasGallery]);

  const multiUpload = useMemo(
    () => Boolean(hasGallery && gallery.find(({ image }) => image)),
    [gallery, hasGallery]
  );

  const fileUploadDisabled = useMemo(
    () => Boolean(fileLoading || parseUrlData),
    [fileLoading, parseUrlData]
  );

  const embedLink = useMemo(() => {
    if (!parseUrlData) {
      return null;
    }

    const { payload } = parseUrlData;

    const { type, gallery } = payload;

    const link = { image: gallery[0]?.image ?? null } as LinkData;

    switch (type) {
      case StoryCardType.IMAGE: {
        link.sourceName = '';
        link.title = '';
        link.text = '';
        break;
      }
      case StoryCardType.ARTICLE: {
        const { title, sourceName, abstract } = payload as StoryCardArticleParsedUrl;

        link.sourceName = sourceName;
        link.title = title;
        link.text = abstract;
        break;
      }
      case StoryCardType.QUOTE: {
        const { quote, quoteSource, quotePerson, quotePersonHandle } =
          payload as StoryCardQuoteParsedUrl;

        link.sourceName = quotePersonHandle;
        link.quoteSource = quoteSource;
        link.title = quotePerson;
        link.text = quote;
        break;
      }
    }

    return (
      <div className={classes['chat-history__body-input-embed']}>
        <EmbedLink link={link} hideUrl />
        <IconLabel
          iconSize={18}
          iconId={'close'}
          color={'#B3B3B3'}
          className={classes['chat-history__body-input-embed-close']}
          onClick={() => setParseUrlData(null)}
          singleColor
        />
      </div>
    );
  }, [parseUrlData]);

  const getGalleryItem = useCallback(
    async ({ id, file, acceptType }: { id: number; file: File; acceptType: FileAcceptType }) => {
      const teaserUrl = await generateTeaserUrl({ file, acceptType });

      const teaser = teaserUrl
        ? {
            image: {
              id: (
                await teaserUploadHandler({
                  file: await urlToFile(teaserUrl),
                  template: getTemplateType({ acceptType: FileAcceptType.IMAGE }),
                  type: getUploadType({ acceptType: FileAcceptType.IMAGE }),
                  imageUploadType: ImageUploadType.ITEM,
                })
              ).id,
              url: teaserUrl,
            },
          }
        : null;

      switch (acceptType) {
        case FileAcceptType.IMAGE:
          return { image: { id, url: URL.createObjectURL(file) } };
        case FileAcceptType.AUDIO:
          return { audio: { id }, ...teaser };
        case FileAcceptType.VIDEO:
          return { video: { id }, ...teaser };
        case FileAcceptType.PDF:
          return { pdf: { id }, ...teaser };
      }
    },
    [getTemplateType, getUploadType, teaserUploadHandler]
  );

  const onFileChange = useCallback(
    async (files: FileList | File[] | null) => {
      if (!files?.length) {
        return;
      }

      setFileLoading(true);

      const uploadedFiles = [];

      const uploadedFilesCount = gallery.length + files.length;

      const filesToProcess =
        uploadLimit && uploadedFilesCount > uploadLimit
          ? Array.from(files).slice(0, uploadLimit - uploadedFilesCount)
          : files;

      let fileIndex = 0;

      for (const file of filesToProcess) {
        const { type } = file;

        const fileType = type.includes('application/') ? type : type.split('/')[0];

        const acceptType = getAcceptTypeByFileType(fileType);

        const isLastFile = fileIndex === filesToProcess.length - 1;

        if (!acceptType || !acceptTypes.includes(acceptType)) {
          toast.error(t('fileUpload.file-type-error'));

          if (isLastFile) {
            setFileLoading(false);
          }

          continue;
        }

        const { id, assembly_id } = await uploadHandler({
          file,
          template: getTemplateType({ acceptType }),
          type: getUploadType({ acceptType }),
          ...(acceptType === FileAcceptType.IMAGE && {
            imageUploadType: ImageUploadType.ITEM,
          }),
        });

        uploadedFiles.push({ ...(await getGalleryItem({ id, file, acceptType })) });

        setGallery([...gallery, ...uploadedFiles] as GalleryItem[]);

        if (isLastFile) {
          onUploadCompleted({ assembly_id, callback: () => setFileLoading(false) });
        }

        fileIndex++;
      }
    },
    [
      acceptTypes,
      gallery,
      getGalleryItem,
      getTemplateType,
      getUploadType,
      onUploadCompleted,
      setFileLoading,
      setGallery,
      t,
      uploadHandler,
      uploadLimit,
    ]
  );

  const onInputPaste = useCallback(
    async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      const { clipboardData } = event;
      const { files } = clipboardData;

      if (files.length) {
        onFileChange(files);
        return;
      }

      const value = clipboardData.getData('Text');

      if (!hasGallery && !parseUrlData && isUrl(value)) {
        setLinkParseLoading(true);

        try {
          const payload = await parseUrl({ url: value }).unwrap();

          if (
            ![StoryCardType.ARTICLE, StoryCardType.QUOTE, StoryCardType.IMAGE].includes(
              payload.type
            )
          ) {
            toast.error(t('storyCardParseUrl.unsupported-type'));
          } else {
            const { gallery } = payload as
              | StoryCardArticleParsedUrl
              | StoryCardQuoteParsedUrl
              | StoryCardImageParsedUrl;

            const { externalId } = { ...gallery[0]?.image };

            await isMediaParsed(externalId);

            setParseUrlData({ url: value, payload });
          }
        } catch (_) {
          toast.error(t('storyCardParseUrl.error'));
        } finally {
          setLinkParseLoading(false);
        }
      }
    },
    [hasGallery, onFileChange, parseUrl, parseUrlData, t]
  );

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (parseUrlData) {
        return;
      }

      (event.target as HTMLDivElement).classList.remove(
        classes['chat-history__body-input--dropzone']
      );

      const files = event.dataTransfer.files;

      if (!files.length) {
        return;
      }

      onFileChange(files);
    },
    [onFileChange, parseUrlData]
  );

  const onDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (parseUrlData) {
        return;
      }

      const target = event.target as HTMLDivElement;

      if (!target.classList.contains(classes['chat-history__body-input'])) {
        return;
      }

      (event.target as HTMLDivElement).classList.add(classes['chat-history__body-input--dropzone']);
    },
    [parseUrlData]
  );

  const onDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (parseUrlData) {
        return;
      }

      (event.target as HTMLDivElement).classList.remove(
        classes['chat-history__body-input--dropzone']
      );
    },
    [parseUrlData]
  );

  useEffect(() => {
    window.addEventListener('dragover', (event) => event.preventDefault(), false);
    window.addEventListener('drop', (event) => event.preventDefault(), false);
  }, []);

  if (!activeChat) {
    return (
      <IconLabel
        iconId={'face-placeholder'}
        iconSize={70}
        color={getCssVar('--base-link-text-color')}
        label={t('chats.no-select')}
        className={classes['chat-history__no-content']}
        labelClassName={classes['chat-history__no-content-label']}
        labelSize={IconLabelSizes.large}
        nonClickable
      />
    );
  }

  if (!access) {
    return <JoinChat chat={activeChat} />;
  }

  return (
    <>
      <div className={classes['chat-history']}>
        <div className={classes['chat-history__header']}>
          <div
            className={classNames(classes['chat-history__header-user'], {
              [classes['chat-history__header-user--clickable']]: isP2P,
            })}
            onClick={openProfile}
          >
            <Avatar
              size={48}
              url={avatarUrl}
              fallBackIconId={avatarFallBackIconId}
              onClick={() => !isP2P && avatarUrl && setAvatarPreviewOpen(true)}
              nonClickable={!avatarUrl}
            />
            <div className={classes['chat-history__header-user-name']}>{name}</div>
          </div>

          <IconLabel
            iconId={'info'}
            iconSize={20}
            className={classes['chat-history__header-info']}
            onClick={() => openChatDetails(ChatDetailsType.INFO)}
            singleColor
          />

          {isGroupOrAdmin && (
            <Popup
              isOpen={isActionsOpen}
              setIsOpen={setIsActionsOpen}
              iconId={'dots-menu'}
              bodyTop={'1.5rem'}
              bodyRight={'0'}
              body={<DropdownMenu width={'12rem'} content={dropdownMenuContent} />}
            />
          )}
        </div>
        <div className={classes['chat-history__body']}>
          {bodyMessages}

          {typingUsers}

          <div
            className={classes['chat-history__body-input-wrapper']}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
          >
            {messageAllowed && (
              <>
                <input
                  type={'file'}
                  ref={fileRef}
                  disabled={fileUploadDisabled}
                  accept={acceptTypes}
                  className={classes['chat-history__body-file']}
                  onChange={({ target }) => onFileChange(target.files)}
                  onClick={({ target }) => ((target as HTMLInputElement).value = '')}
                  multiple={multiUpload}
                />

                {embedLink}

                <TextAreaField
                  ref={messageRef}
                  inputClassName={classes['chat-history__body-input']}
                  placeholder={t('chats.input-placeholder')}
                  value={message}
                  onChange={({ target }) => onMessageChange(target.value)}
                  onKeyDown={onInputKeyDown}
                  onPaste={onInputPaste}
                />

                <MessageGallery gallery={gallery} setGallery={setGallery} loading={fileLoading} />

                <div className={classes['chat-history__body-input-icons']}>
                  <IconLabel
                    iconId={'mood'}
                    iconSize={20}
                    color={getCssVar('--chats-input-icon-color')}
                    hoverColor={getCssVar('--chats-input-icon-hover-color')}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  />

                  {!hideUpload && (
                    <IconLabel
                      iconId={'image'}
                      iconSize={20}
                      color={getCssVar('--chats-input-icon-color')}
                      hoverColor={getCssVar('--chats-input-icon-hover-color')}
                      onClick={() => fileRef.current?.click()}
                      disabled={fileLoading}
                      {...(parseUrlData && {
                        data: t('chats.media-upload-hint'),
                        hintDirection: IconLabelHintDirection.top,
                      })}
                    />
                  )}

                  {sendComponent}
                </div>
              </>
            )}

            {showScrollToBottom && (
              <IconLabel
                iconId={'arrow-down'}
                className={classNames(classes['chat-history__body-input-scroll-down'], {
                  [classes['chat-history__body-input-scroll-down--has-new']]:
                    showScrollToBottomNewMessage,
                })}
                onClick={scrollToBottom}
              />
            )}
          </div>
        </div>

        {showEmojiPicker && (
          <div ref={emojiPickerRef} className={classes['chat-history__emoji-picker']}>
            <Picker
              data={data}
              locale={getEmojiPickerLocale()}
              onEmojiSelect={onEmojiSelect}
              maxFrequentRows={3}
              perLine={10}
              emojiSize={18}
              previewPosition={'none'}
              searchPosition={'static'}
              navPosition={'bottom'}
            />
          </div>
        )}
      </div>

      <ChatDetails detailsType={chatDetailsType} setDetailsType={setChatDetailsType} />

      <ConfirmationModal
        isOpen={isDeleteChatOpen}
        onClose={() => setIsDeleteChatOpen(false)}
        acceptLabel={t('common.delete')}
        onAccept={chatDelete}
        title={t('deleteConfirmationChat.title')}
        subTitle={t('deleteConfirmationChat.sub-title')}
        keepOpened={deleteLoading}
        loading={deleteLoading}
        danger
      />

      <ConfirmationModal
        isOpen={Boolean(deleteMessageToken)}
        onClose={() => setDeleteMessageToken('')}
        acceptLabel={t('common.delete')}
        onAccept={messageDelete}
        title={t('deleteConfirmationChatMessage.title')}
        subTitle={t('deleteConfirmationChatMessage.sub-title')}
        keepOpened={deleteLoading}
        loading={deleteLoading}
        danger
      />

      <Modal
        isOpen={avatarPreviewOpen}
        body={<img src={avatarUrl ?? ''} alt={'preview'} />}
        onClose={() => setAvatarPreviewOpen(false)}
        root={'modal-preview-root'}
        previewMode
      />

      {abuseReportModal}

      {DSAReportModal}
    </>
  );
};
