/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  InAppMessage,
  InAppMessageDeleteArgs,
  InAppMessagesArgs,
  notificationsApi,
} from '../services';

interface InAppMessagesState {
  inAppMessages: InAppMessage[];
  page: number;
  hasNextPage: boolean;
  isFetching: boolean;
}

const initialState: InAppMessagesState = {
  inAppMessages: [],
  page: 0,
  hasNextPage: false,
  isFetching: false,
};

const { inAppMessages, inAppMessageDelete } = notificationsApi.endpoints;

const getInAppMessagesHandler = async (args: InAppMessagesArgs, { dispatch }: any) => {
  return dispatch(inAppMessages.initiate({ ...args })).unwrap();
};

export const getInAppMessages = createAsyncThunk('getInAppMessages', getInAppMessagesHandler);

export const loadMoreInAppMessages = createAsyncThunk(
  'getInAppMessages/loadMore',
  getInAppMessagesHandler
);

export const deleteInAppMessage = createAsyncThunk(
  'deleteInAppMessage',
  async (args: InAppMessageDeleteArgs, { dispatch }) => {
    const deleted = await dispatch(inAppMessageDelete.initiate(args)).unwrap();
    return { ...args, deleted };
  }
);

const InAppMessagesSlice = createSlice({
  name: 'inAppMessages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInAppMessages.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getInAppMessages.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.inAppMessages = items;
        state.page = page;
        state.hasNextPage = hasNextPage;
        state.isFetching = false;
      })
      .addCase(loadMoreInAppMessages.fulfilled, (state, action) => {
        const { items, pageInfo } = action.payload;
        const { page, hasNextPage } = pageInfo;

        state.inAppMessages.push(...items);
        state.page = page;
        state.hasNextPage = hasNextPage;
      })
      .addCase(deleteInAppMessage.fulfilled, (state, action) => {
        const { deleted, inAppMessageId } = action.payload;

        if (!deleted) {
          return;
        }

        state.inAppMessages = state.inAppMessages.filter(({ id }) => id !== inAppMessageId);
      });
    // .addCase(createPushNotificationChannel.fulfilled, (state, action) => {
    //   state.pushNotifications.unshift(action.payload);
    // })
    // .addCase(createPushNotificationOrganisation.fulfilled, (state, action) => {
    //   state.pushNotifications.unshift(action.payload);
    // });
  },
});

export default InAppMessagesSlice.reducer;
