import PubNub from 'pubnub';
import { FetchedMessageWithActions } from 'pubnub/lib/types/core/types/api/history';
import { createContext, Dispatch, SetStateAction } from 'react';
import { Chat, User } from '../services';

export interface Channels {
  [p: string]: FetchedMessageWithActions[];
}

export interface ChannelsUnreadCount {
  [p: string]: number;
}

export interface ChannelTypingUsers {
  [p: string]: User[];
}

export interface ChatContextProps {
  pubnub: PubNub;
  activeChat: Chat | null;
  setActiveChat: Dispatch<SetStateAction<Chat | null>>;
  chats: Chat[];
  setChats: Dispatch<SetStateAction<Chat[]>>;
  selectedTabIndex: number;
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
  channels: Channels | null;
  setChannels: Dispatch<SetStateAction<Channels | null>>;
  channelsChanges: Channels | null;
  setChannelsChanges: Dispatch<SetStateAction<Channels | null>>;
  channelsUnreadCount: ChannelsUnreadCount | null;
  setChannelsUnreadCount: Dispatch<SetStateAction<ChannelsUnreadCount | null>>;
  chatUsers: User[];
  setChatUsers: Dispatch<SetStateAction<User[]>>;
  channelTypingUsers: ChannelTypingUsers | null;
  setChannelTypingUsers: Dispatch<SetStateAction<ChannelTypingUsers | null>>;
}

export const ChatContext = createContext<ChatContextProps>({} as ChatContextProps);
