import { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { ConfigContext } from '../../../context';
import {
  OrganisationTagType,
  OrganisationUsersFilter,
  OrganisationUsersFiltersSection,
  useOrganisationUsersFiltersQuery,
  UserGender,
} from '../../../services';
import {
  cookieOptions,
  Filter,
  FilterValue,
  optArrItem,
  useAppDispatch,
  useFilter,
  USER_DIRECTORY_READER_COOKIE_KEY,
  useTagsFilter,
} from '../../../shared';
import { getOrganisationUsers } from '../../../slices';

import classes from './UserDirectoryRightSide.module.scss';

enum UserRoles {
  'admin' = 1,
  'editor' = 2,
  'editor-limited' = 3,
  'reader' = 5,
  'org-admin' = 7,
}

export const UserDirectoryRightSide: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { getGroupValue } = useFilter();

  const [cookies, setCookie] = useCookies([USER_DIRECTORY_READER_COOKIE_KEY]);

  const { config } = useContext(ConfigContext);

  const { profile, userDirectory } = config.elements;

  const { fields: profileFields } = profile;

  const { filters } = userDirectory;

  const {
    name: { visible: nameVisible },
    gender: { visible: genderVisible },
    department: { visible: departmentVisible },
    position: { visible: positionVisible },
    subscription: { visible: subscriptionVisible },
    location: { visible: locationVisible },
    organisationTags: { visible: tagsVisible },
    role: { visible: roleVisible },
  } = filters;

  const { data: organisationUsersFilters = [], isFetching } = useOrganisationUsersFiltersQuery({
    filters: {
      sections: [
        ...optArrItem(departmentVisible, OrganisationUsersFiltersSection.DEPARTMENT),
        ...optArrItem(positionVisible, OrganisationUsersFiltersSection.POSITION),
        ...optArrItem(subscriptionVisible, OrganisationUsersFiltersSection.SUBSCRIPTION),
      ],
    },
  });

  const [filter, setFilter] = useState<FilterValue[]>(
    cookies[USER_DIRECTORY_READER_COOKIE_KEY]?.filter ?? []
  );

  const { group: userTagsGroup } = useTagsFilter({
    filter,
    types: [OrganisationTagType.USER],
  });

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      const query = getGroupValue({ filter, groupId: 'name' });

      const filterPositions = getGroupValue({
        filter,
        groupId: OrganisationUsersFiltersSection.POSITION,
        multiCheck: true,
      });

      const filterDepartments = getGroupValue({
        filter,
        groupId: OrganisationUsersFiltersSection.DEPARTMENT,
        multiCheck: true,
      });

      const filterSubscriptions = getGroupValue({
        filter,
        groupId: OrganisationUsersFiltersSection.SUBSCRIPTION,
        multiCheck: true,
      });

      const genders = getGroupValue({
        filter,
        groupId: 'genders',
        multiCheck: true,
      });

      const organisationTagsId = getGroupValue({
        filter,
        groupId: userTagsGroup.id,
        propToMap: 'id',
        multiCheck: true,
      });

      const rolesId = getGroupValue({
        filter,
        groupId: 'roles',
        multiCheck: true,
      });

      const queryLocation = getGroupValue({ filter, groupId: 'location' });

      const requestFilter: OrganisationUsersFilter = {
        ...(query && { query }),
        ...(genders?.length && { genders }),
        ...(filterPositions?.length && { filterPositions }),
        ...(filterDepartments?.length && { filterDepartments }),
        ...(filterSubscriptions?.length && { filterSubscriptions }),
        ...(organisationTagsId?.length && { organisationTagsId }),
        ...(rolesId?.length && { rolesId }),
        ...(queryLocation && { queryLocation }),
      };

      setCookie(USER_DIRECTORY_READER_COOKIE_KEY, { requestFilter, filter }, cookieOptions());

      dispatch(getOrganisationUsers({ filter: requestFilter }));

      setFilter(filter);
    },
    [dispatch, getGroupValue, setCookie, userTagsGroup.id]
  );

  const nameGroup = useMemo(() => {
    const title = t(`${profileFields['screenName'].localiseKey ?? 'common.name'}`);

    return {
      id: 'name',
      title,
      search: { placeholder: title },
      value: getGroupValue({ filter, groupId: 'name' }) ?? '',
      allowReset: true,
    };
  }, [filter, getGroupValue, profileFields, t]);

  const gendersGroup = useMemo(() => {
    const title = t(`${profileFields['gender'].localiseKey ?? 'common.gender'}`);

    return {
      id: 'genders',
      title,
      items: Object.keys(UserGender).map((gender) => {
        return {
          id: gender,
          label: t(`genderTypes.${gender}`),
          value: gender !== UserGender.NOT_SELECTED ? gender : null,
        };
      }),
      multiCheck: true,
      allowReset: true,
    };
  }, [profileFields, t]);

  const orgGroups = useMemo(() => {
    if (isFetching) {
      return [];
    }

    return organisationUsersFilters
      .filter(({ values }) => values.length)
      .map(({ name, values }) => {
        const filter = name.toLowerCase();

        const title = t(
          `${profileFields[filter as keyof typeof profileFields].localiseKey ?? `common.${filter}`}`
        );

        return {
          id: name,
          title,
          items: values.map(({ value }) => {
            return { id: value, label: value, value };
          }),
          multiCheck: true,
          allowReset: true,
        };
      });
  }, [isFetching, organisationUsersFilters, profileFields, t]);

  const rolesGroup = useMemo(() => {
    const title = t(`${profileFields['role'].localiseKey ?? 'common.roles'}`);

    return {
      id: 'roles',
      title,
      items: Object.values(UserRoles)
        .filter((role) => typeof role !== 'number')
        .map((role) => {
          return {
            id: role,
            label: t(`roles.${role}`),
            value: UserRoles[role as keyof typeof UserRoles],
          };
        }),
      multiCheck: true,
      allowReset: true,
    };
  }, [profileFields, t]);

  const locationGroup = useMemo(() => {
    const title = t(`${profileFields['location'].localiseKey ?? 'common.location'}`);

    return {
      id: 'location',
      title,
      search: { placeholder: title },
      value: getGroupValue({ filter, groupId: 'location' }) ?? '',
      allowReset: true,
    };
  }, [filter, getGroupValue, profileFields, t]);

  const groups = useMemo(() => {
    return [
      ...optArrItem(nameVisible, nameGroup),
      ...optArrItem(genderVisible, gendersGroup),
      ...orgGroups,
      ...optArrItem(roleVisible, rolesGroup),
      ...optArrItem(tagsVisible, userTagsGroup),
      ...optArrItem(locationVisible, locationGroup),
    ];
  }, [
    genderVisible,
    gendersGroup,
    locationGroup,
    locationVisible,
    nameGroup,
    nameVisible,
    orgGroups,
    roleVisible,
    rolesGroup,
    tagsVisible,
    userTagsGroup,
  ]);

  if (isFetching) {
    return (
      <div className={classes['user-directory-right-side__loader']}>
        <Skeleton width={'100%'} height={'10rem'} />
      </div>
    );
  }

  return (
    <div className={classes['user-directory-right-side']}>
      <Filter
        groups={groups}
        groupItemsLimit={5}
        filter={filter}
        onChange={filterChange}
        resetPageScrollOnChange
      />
    </div>
  );
};
