import { ResponsiveBar } from '@nivo/bar';
import { format, parseISO } from 'date-fns';
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context';
import { AnalyticChartInterval, useUserActiveQuery } from '../../../../services';
import { ANALYTICS_ADMIN_COOKIE_KEY, Select, cookieOptions } from '../../../../shared';
import { COLORS } from '../../Analytics';
import { ChartWrapper, Loader, getBarsLayer, toggleLabel } from '../../helpers';

import classes from '../../Analytics.module.scss';

export const UserActive: FunctionComponent = () => {
  const { t } = useTranslation();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const COOKIE_KEY = useMemo(
    () => `${ANALYTICS_ADMIN_COOKIE_KEY}_${channelId}_userActive`,
    [channelId]
  );

  const [cookies, setCookie] = useCookies([COOKIE_KEY]);

  const { userActiveYear = new Date().getFullYear() } = { ...cookies[COOKIE_KEY] };

  const { data: userActive, isFetching: isFetchingUserActive } = useUserActiveQuery({
    from: new Date(`${userActiveYear}-01-01`).toISOString(),
    to: new Date(`${userActiveYear}-12-31`).toISOString(),
    interval: AnalyticChartInterval.AUTO,
  });

  const [userActiveLabels, setUserActiveLabels] = useState<string[]>([]);

  useEffect(() => {
    if (userActive && !userActiveLabels.length) {
      setUserActiveLabels(userActive.datasets.map(({ label }) => label));
    }
  }, [t, userActive, userActiveLabels.length]);

  const userActiveYears = useMemo(() => {
    let startYear = 2023;
    const items = [];

    while (startYear <= new Date().getFullYear()) {
      items.push({ id: startYear, title: startYear.toString() });
      startYear += 1;
    }

    return items;
  }, []);

  const userActiveYearChange = useCallback(
    (userActiveYear: number) => {
      setCookie(COOKIE_KEY, { ...cookies[COOKIE_KEY], userActiveYear }, cookieOptions());
    },
    [COOKIE_KEY, cookies, setCookie]
  );

  const tooltip = useCallback(
    ({ label, value, color }: { label: string | number; value: number; color: string }) => {
      return (
        <div key={label} className={classes['analytics__chart-tooltip']}>
          <div className={classes['analytics__chart-tooltip-item']}>
            <span
              className={classes['analytics__chart-tooltip-item-color']}
              style={{ backgroundColor: color }}
            ></span>
            <span className={classes['analytics__chart-tooltip-item-label']}>
              {t(`userActiveLabels.${label}`)}
            </span>
            <span className={classes['analytics__chart-tooltip-item-value']}>{value}</span>
          </div>
        </div>
      );
    },
    [t]
  );

  const renderChart = useMemo(() => {
    if (isFetchingUserActive) {
      return <Loader />;
    }

    if (!userActive) {
      return null;
    }

    const { datasets, intervals, labels } = userActive;

    if (!datasets.length) {
      return <div className={classes['analytics__chart-no-records']}>{t('common.no-records')}</div>;
    }

    const data = intervals.map((interval, index) => {
      return {
        interval,
        ...Object.assign(
          {},
          ...datasets
            .filter(({ label }) => userActiveLabels.includes(label))
            .map(({ label, data }) => ({ [label]: data[index] }))
        ),
      };
    });

    return (
      <div className={classes['analytics__chart-height']}>
        <ResponsiveBar
          margin={{ top: 40, right: 40, bottom: 80, left: 40 }}
          padding={0.35}
          innerPadding={8}
          borderRadius={4}
          data={data}
          colors={COLORS}
          keys={labels}
          indexBy={'interval'}
          groupMode={'grouped'}
          axisLeft={{ format: (value) => (value === Math.floor(value) ? value : '') }}
          axisBottom={{ format: (value) => format(parseISO(value), 'LLL') }}
          tooltip={({ id, value, color }) => tooltip({ label: id, value, color })}
          label={''}
          layers={[
            'grid',
            'axes',
            'bars',
            'legends',
            ({ bars }) => getBarsLayer({ bars, transform: 'rotate(-45)' }),
          ]}
          legends={[
            {
              onClick: ({ id }) => {
                toggleLabel({
                  labels: userActiveLabels,
                  setLabels: setUserActiveLabels,
                  label: id.toString(),
                });
              },
              data: labels.map((label, index) => ({
                id: label,
                color: userActiveLabels.includes(label) ? COLORS[index] : '#33333399',
                label: t(`userActiveLabels.${label}`),
              })),
              dataFrom: 'keys',
              anchor: 'bottom',
              direction: 'row',
              translateY: 60,
              itemWidth: 100,
              itemHeight: 20,
              itemOpacity: 0.85,
              symbolShape: 'circle',
              effects: [{ on: 'hover', style: { itemOpacity: 1 } }],
            },
          ]}
        />
      </div>
    );
  }, [isFetchingUserActive, t, tooltip, userActive, userActiveLabels]);

  return (
    <ChartWrapper
      typeLocaliseKey={'chartTypes.user-active'}
      infoLocaliseKey={'userActive.info'}
      component={
        <Select
          className={classes['analytics__chart-header-select']}
          selectedItemId={userActiveYear}
          items={userActiveYears}
          onChange={(userActiveYear) => userActiveYearChange(userActiveYear as number)}
        />
      }
    >
      {renderChart}
    </ChartWrapper>
  );
};
