import {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ChatContext, UserContext } from '../../../../context';
import {
  Chat,
  ChatAccess,
  ChatMembersData,
  chatsApi,
  graphqlChatsApi,
  UserChatMember,
} from '../../../../services';
import {
  Avatar,
  Button,
  ButtonType,
  Check,
  getCssVar,
  IconLabel,
  InputField,
  layoutPath,
  useDebounce,
} from '../../../../shared';
import { ChatDetailsType } from '../ChatDetails';
import { ChatMemberAccess } from './ChatMemberAccess';

import classes from './ManageChatMembers.module.scss';

interface ManageChatMembersProps {
  setDetailsType: (type: ChatDetailsType | null) => void;
}

export const ManageChatMembers: FunctionComponent<ManageChatMembersProps> = memo(
  ({ setDetailsType }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const userId = useContext(UserContext).userProfile.id;

    const { activeChat, setActiveChat } = { ...useContext(ChatContext) };

    const { chatId, usersCount, access } = activeChat as Chat;

    const [chatMembers] = graphqlChatsApi.endpoints.chatMembers.useLazyQuery();

    const [removeMembers] = chatsApi.endpoints.removeMembers.useMutation();

    const [membersData, setMembersData] = useState<ChatMembersData | null>(null);

    const [membersQuery, setMembersQuery] = useState<string>('');

    const membersQueryDebounced = useDebounce<string>(membersQuery);

    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const hasAdminAccess = useMemo(() => access === ChatAccess.admin, [access]);

    const getMembers = useCallback(
      async ({ page }: { page: number }) => {
        const data = await chatMembers({ chatId, page, query: membersQueryDebounced }).unwrap();

        setMembersData((membersDataPrev) => {
          if (page === 1) {
            return data;
          }

          return { ...data, items: [...(membersDataPrev?.items ?? []), ...data.items] };
        });
      },
      [chatId, chatMembers, membersQueryDebounced]
    );

    useEffect(() => {
      getMembers({ page: 1 });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      getMembers({ page: 1 });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membersQueryDebounced]);

    const membersLoadMore = useCallback(() => {
      if (!membersData) {
        return;
      }

      getMembers({ page: membersData.pageInfo.page + 1 });
    }, [getMembers, membersData]);

    const onUserCheck = useCallback(
      (id: number) => {
        const index = selectedUserIds.findIndex((selectedUserId) => selectedUserId === id);

        if (index === -1) {
          setSelectedUserIds([...selectedUserIds, id]);
          return;
        }

        const selectedIdsCopy = [...selectedUserIds];

        selectedIdsCopy.splice(index, 1);

        setSelectedUserIds(selectedIdsCopy);
      },
      [selectedUserIds]
    );

    const removeMembersHandler = useCallback(async () => {
      try {
        setLoading(true);

        await removeMembers({ chatId, users: selectedUserIds }).unwrap();
        setSelectedUserIds([]);
        setMembersData(null);
        getMembers({ page: 1 });
        setActiveChat({ ...(activeChat as Chat), usersCount: usersCount - selectedUserIds.length });

        setLoading(false);
      } catch (e) {
        toast.error(t('common.error-title'));
        setLoading(false);
      }
    }, [
      activeChat,
      chatId,
      getMembers,
      removeMembers,
      selectedUserIds,
      setActiveChat,
      t,
      usersCount,
    ]);

    const getChatUserAccess = useCallback(
      (access: ChatAccess) => {
        if (access === ChatAccess.rw) {
          return null;
        }

        const color = (() => {
          switch (access) {
            case ChatAccess.admin:
              return getCssVar('--base-link-text-color');
            case ChatAccess.banned:
              return getCssVar('--color-danger');
            case ChatAccess.r:
              return '#787878';
            default:
              return '';
          }
        })();

        return (
          <IconLabel label={t(`chatUserAccess.${access}`)} color={color} nonClickable singleColor />
        );
      },
      [t]
    );

    const renderMember = useCallback(
      (member: UserChatMember) => {
        const { id, screenName, avatar, chatAccess } = member;

        const onInfoClick = () => {
          navigate(layoutPath(`${id === userId ? '/profile' : `/profile/${id}`}`));
        };

        const info = (
          <div className={classes['manage-members__list-item-content']} onClick={onInfoClick}>
            <Avatar url={avatar?.url} />
            <div className={classes['manage-members__list-item-content-name']}>{screenName}</div>
            {getChatUserAccess(chatAccess)}
          </div>
        );

        if (!hasAdminAccess) {
          return info;
        }

        return (
          <>
            <Check checked={selectedUserIds.includes(id)} onChange={() => onUserCheck(id)} />
            {info}
            <ChatMemberAccess
              userId={id}
              chatAccess={chatAccess}
              membersData={membersData}
              setMembersData={setMembersData}
            />
          </>
        );
      },
      [
        getChatUserAccess,
        hasAdminAccess,
        membersData,
        navigate,
        onUserCheck,
        selectedUserIds,
        userId,
      ]
    );

    if (!membersData) {
      return (
        <div className={classes['manage-members__loader']}>
          <Skeleton height={'3rem'} />
          <Skeleton height={'3rem'} />
          <Skeleton height={'3rem'} />
        </div>
      );
    }

    const {
      items,
      pageInfo: { hasNextPage },
    } = membersData;

    return (
      <div className={classes['manage-members']}>
        <IconLabel
          iconId={'arrow-rounded-left'}
          iconSize={18}
          label={t('common.participants')}
          labelClassName={classes['manage-members__title']}
          onClick={() => setDetailsType(ChatDetailsType.INFO)}
          singleColor
        />

        <InputField
          value={membersQuery}
          onChange={({ target }) => setMembersQuery(target.value)}
          placeholder={t('chats.search-users-placeholder')}
          {...(membersQuery && {
            icon: (
              <IconLabel
                iconId={'close'}
                iconSize={18}
                color={'#b3b3b3'}
                onClick={() => setMembersQuery('')}
                singleColor
              />
            ),
          })}
        />
        <InfiniteScroll
          loader={<Skeleton height={'3rem'} />}
          next={membersLoadMore}
          hasMore={hasNextPage}
          dataLength={items.length}
          className={classes['manage-members__list']}
          height={'calc(100vh - 17.5rem)'}
        >
          {items.map((item) => (
            <div key={item.id} className={classes['manage-members__list-item']}>
              {renderMember(item)}
            </div>
          ))}

          {!items.length && (
            <IconLabel
              iconSize={40}
              iconId={'search-off'}
              label={t('common.nothing-found')}
              className={classes['manage-members__list-no-data']}
              labelClassName={classes['manage-members__list-no-data-label']}
              singleColor
              nonClickable
            />
          )}
        </InfiniteScroll>

        {hasAdminAccess && (
          <Button
            type={ButtonType.primary}
            label={`${t('chatActions.remove-selected')} (${selectedUserIds.length})`}
            disabled={Boolean(loading || !selectedUserIds.length)}
            loading={loading}
            onClick={removeMembersHandler}
            fullWidth
          />
        )}
      </div>
    );
  }
);
