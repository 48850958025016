import { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/router';
import { store } from './store';

import 'react-loading-skeleton/dist/skeleton.css';

export const App: FunctionComponent = () => {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};
