import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ConfigContext, UserContext } from '../../../../context';
import { Comment } from '../../../../services';
import {
  AbuseReportType,
  DSAReportType,
  useAbuseReportPopup,
  useCardTitle,
  useDSAReportPopup,
  useLinkify,
} from '../../../hooks';
import { getCssVar, layoutPath, timeAgo } from '../../../utils';
import { Avatar } from '../../Avatar';
import { ConfirmationModal } from '../../ConfirmationModal';
import { DropdownMenu } from '../../DropdownMenu';
import { IconLabel } from '../../IconLabel';
import { Popup } from '../../Popup';
import { CommentAttachments } from '../CommentAttachments';

import classNames from 'classnames';
import dropdownMenuClasses from '../../DropdownMenu/DropdownMenu.module.scss';
import classes from './CommentItem.module.scss';

interface CommentItemProps {
  comment: Comment;
  onLike: (commentId: number) => void;
  onDelete: (commentId: number) => void;
  onHide: (commentId: number) => void;
  onHighlight: (commentId: number) => void;
  onReply: () => void;
  onEdit: () => void;
  isParentHidden: boolean;
}

export const CommentItem: FunctionComponent<CommentItemProps> = memo(
  ({ comment, onLike, onReply, onEdit, onDelete, onHide, onHighlight, isParentHidden }) => {
    const { t } = useTranslation();

    const { linkify } = useLinkify();

    const {
      abuseReport: { enabled: abuseReportEnabled },
      dsaReport: { enabled: dsaReportEnabled },
    } = useContext(ConfigContext).config.elements;

    const { userProfile } = useContext(UserContext);

    const {
      id: commentId,
      attachments,
      author,
      createdAt,
      reactions,
      content,
      contentUpdatedAt,
      permissions,
      myReaction,
      storyId,
      storyCardId,
      story: { title: storyTitle },
      storyCard,
      isHidden,
      isHighlighted,
    } = comment;

    const { type: storyCardType } = storyCard;

    const storyCardTitle = useCardTitle(storyCard);

    const { id: userId, avatar, screenName: userScreenName } = { ...author };

    const { allowToDelete, allowToEditContent, allowToHide, allowToHighlight } = { ...permissions };

    const reportArgs = useMemo(() => {
      return {
        storyId,
        storyName: storyTitle,
        postId: storyCardId,
        postType: storyCardType,
        postTitle: storyCardTitle,
        commentId,
        commentText: content,
        commentAuthorId: userId,
        commentAuthorName: userScreenName,
      };
    }, [
      commentId,
      content,
      storyCardId,
      storyCardTitle,
      storyCardType,
      storyId,
      storyTitle,
      userId,
      userScreenName,
    ]);

    const { abuseReportModal, abuseReportHandler } = useAbuseReportPopup({
      type: AbuseReportType.Comment,
      ...reportArgs,
    });

    const { DSAReportModal, DSAReportHandler } = useDSAReportPopup({
      type: DSAReportType.Comment,
      ...reportArgs,
    });

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const isAuthor = useMemo(() => Boolean(userId === userProfile.id), [userId, userProfile.id]);

    const profileLink = useMemo(
      () => `${layoutPath('/profile')}${!isAuthor ? `/${userId}` : ''}`,
      [isAuthor, userId]
    );

    const commentLiked = useMemo(() => myReaction === 'like', [myReaction]);

    const closeDeleteModal = useCallback(() => {
      setDeleteModalOpen(false);
    }, []);

    const deleteConfirmed = useCallback(() => {
      setIsMenuOpen(false);
      onDelete(commentId);
      closeDeleteModal();
    }, [closeDeleteModal, commentId, onDelete]);

    const deleteCommentClick = useCallback(() => {
      setIsMenuOpen(false);
      setDeleteModalOpen(true);
    }, []);

    const abuseReportClick = useCallback(() => {
      setIsMenuOpen(false);
      abuseReportHandler();
    }, [abuseReportHandler]);

    const DSAReportClick = useCallback(() => {
      setIsMenuOpen(false);
      DSAReportHandler();
    }, [DSAReportHandler]);

    const dropdownMenuContent = useMemo(() => {
      return (
        <>
          {abuseReportEnabled && (
            <IconLabel
              iconId={'report'}
              iconSize={18}
              label={t('abuseReport.label')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={abuseReportClick}
              singleColor
            />
          )}
          {dsaReportEnabled && (
            <IconLabel
              iconId={'problem'}
              iconSize={18}
              label={t('DSAReport.label')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={DSAReportClick}
              singleColor
            />
          )}
          {allowToDelete && (
            <IconLabel
              iconId={'delete'}
              iconSize={18}
              label={t('common.delete')}
              className={dropdownMenuClasses['dropdown-menu__item--small']}
              onClick={deleteCommentClick}
              color={getCssVar('--color-danger')}
              singleColor
            />
          )}
        </>
      );
    }, [
      DSAReportClick,
      abuseReportClick,
      abuseReportEnabled,
      allowToDelete,
      deleteCommentClick,
      dsaReportEnabled,
      t,
    ]);

    const displayAsHidden = useMemo(() => isParentHidden || isHidden, [isHidden, isParentHidden]);

    const prefix = '--comments-item';

    const link_prefix = '--base-link';

    return (
      <div className={classes['comment']}>
        <Link onClick={() => window.scrollTo(0, 0)} to={profileLink}>
          <Avatar
            className={classNames({ [classes['comment__avatar--hidden']]: displayAsHidden })}
            url={avatar?.url}
          />
        </Link>
        <div className={classes['comment__content']}>
          <div
            className={classNames(classes['comment__content-header'], {
              [classes['comment__content-header--mobile']]: isMobileOnly,
              [classes['comment__content-header--hidden']]: displayAsHidden,
            })}
          >
            <span className={classes['comment__content-author']}>{userScreenName}</span>
            <span className={classes['comment__content-published']}>{`${timeAgo(createdAt)}`}</span>
          </div>

          {content && (
            <div
              className={classNames(classes['comment__content-text'], {
                [classes['comment__content-text--hidden']]: displayAsHidden,
              })}
              dangerouslySetInnerHTML={{ __html: linkify(content) }}
            ></div>
          )}

          <CommentAttachments attachments={attachments} />

          {contentUpdatedAt && (
            <span className={classes['comment__content-text-edited']}>({t('common.edited')})</span>
          )}

          <div className={classes['comment__content-actions']}>
            {allowToHighlight && (
              <IconLabel
                iconSize={16}
                iconId={'star'}
                color={getCssVar(`${prefix}-highlight-${isHighlighted ? 'filled-color' : 'color'}`)}
                hoverColor={getCssVar(`${prefix}-highlight-hover-color`)}
                onClick={() => onHighlight?.(commentId)}
              />
            )}
            <IconLabel
              iconSize={16}
              iconId={`thumb-up${commentLiked ? '-active' : ''}`}
              color={getCssVar(`${prefix}-like-${commentLiked ? 'filled-color' : 'color'}`)}
              hoverColor={getCssVar(`${prefix}-like-hover-color`)}
              onClick={() => onLike(commentId)}
            />
            {allowToHide && (
              <IconLabel
                iconSize={16}
                iconId={'eye-crossed'}
                color={getCssVar(`${prefix}-hide-${isHidden ? 'filled-color' : 'color'}`)}
                hoverColor={getCssVar(`${prefix}-hide-hover-color`)}
                onClick={() => onHide(commentId)}
              />
            )}
          </div>
          <div className={classes['comment__content-links']}>
            <IconLabel
              label={t('common.like-count', {
                count: reactions.find(({ name }) => name === 'like')?.count,
              })}
              color={getCssVar(`${prefix}-like-counter-color`)}
              singleColor
            />
            <IconLabel
              label={t('comments.reply')}
              onClick={onReply}
              color={getCssVar(`${link_prefix}-text-color`)}
              hoverColor={getCssVar(`${link_prefix}-text-hover-color`)}
            />
            {allowToEditContent && (
              <IconLabel
                iconId={'edit'}
                iconSize={18}
                label={t('common.edit')}
                onClick={onEdit}
                color={getCssVar(`${link_prefix}-text-color`)}
                hoverColor={getCssVar(`${link_prefix}-text-hover-color`)}
              />
            )}
            <Popup
              isOpen={isMenuOpen}
              setIsOpen={setIsMenuOpen}
              iconId={'dots-menu'}
              color={getCssVar('--action-pane-icon-color')}
              hoverColor={getCssVar('--action-pane-icon-hover-color')}
              bodyTop={'1.5rem'}
              bodyRight={'0'}
              body={<DropdownMenu width={'12em'} content={dropdownMenuContent} />}
            />
          </div>
        </div>

        <ConfirmationModal
          isOpen={deleteModalOpen}
          onClose={closeDeleteModal}
          acceptLabel={t('common.delete')}
          onAccept={deleteConfirmed}
          title={t('comments.delete-confirmation-title')}
          subTitle={t('comments.delete-confirmation-sub-title')}
          danger
        />
        {abuseReportModal}

        {DSAReportModal}
      </div>
    );
  }
);
