import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoryCardCategoryTemplate } from '../../../../../services';
import {
  Button,
  ButtonType,
  FileAcceptType,
  FilePreview,
  FileUpload,
  GalleryItem,
  InputField,
  TextAreaField,
} from '../../../../../shared';

import classes from './CreateTemplate.module.scss';

interface CreateTemplateProps {
  templates: StoryCardCategoryTemplate[];
  setTemplates: (templates: StoryCardCategoryTemplate[]) => void;
  customTemplateIndex: number;
  saveTemplateCallback: () => void;
}

export const CreateTemplate: FunctionComponent<CreateTemplateProps> = memo(
  ({ templates, setTemplates, customTemplateIndex, saveTemplateCallback }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const {
      name: templateName = '',
      text: templateText = '',
      gallery: templateGallery = [],
    } = { ...templates[customTemplateIndex] };

    const [name, setName] = useState<string>(templateName);

    const [text, setText] = useState<string>(templateText);

    const [galleryItems, setGalleryItems] = useState<GalleryItem[]>(templateGallery);

    const editMode = useMemo(() => customTemplateIndex !== -1, [customTemplateIndex]);

    const title = useMemo(
      () => t(`storyCardCategoryTemplate.${editMode ? 'edit' : 'create'}`),
      [editMode, t]
    );

    const buttonLabel = useMemo(() => t(`common.${editMode ? 'save' : 'create'}`), [editMode, t]);

    const buttonDisabled = useMemo(
      () => [name, text, galleryItems].some((value) => !value.length),
      [galleryItems, name, text]
    );

    const onTemplateSave = useCallback(() => {
      const templateToSave = { name, text, gallery: galleryItems } as StoryCardCategoryTemplate;

      if (editMode) {
        const templatesCopy = [...templates];

        templatesCopy[customTemplateIndex] = templateToSave;

        setTemplates(templatesCopy);
      } else {
        setTemplates([...templates, templateToSave]);
      }

      saveTemplateCallback();
    }, [
      customTemplateIndex,
      editMode,
      galleryItems,
      name,
      saveTemplateCallback,
      setTemplates,
      templates,
      text,
    ]);

    return (
      <div className={classes['create-template']}>
        <div className={classes['create-template__title']}>{title}</div>

        <FileUpload
          galleryItems={galleryItems}
          setGalleryItems={setGalleryItems}
          loading={loading}
          setLoading={setLoading}
          fileAcceptTypes={[FileAcceptType.IMAGE]}
          uploadLimit={1}
        />

        <FilePreview galleryItems={galleryItems} setGalleryItems={setGalleryItems} />

        <InputField
          value={name}
          placeholder={t('common.name')}
          onChange={({ target }) => setName(target.value)}
        />

        <TextAreaField
          value={text}
          placeholder={t('common.text')}
          onChange={({ target }) => setText(target.value)}
          minRows={3}
        />

        <Button
          className={classes['create-template__button']}
          type={ButtonType.primary}
          label={buttonLabel}
          disabled={buttonDisabled}
          onClick={onTemplateSave}
        />
      </div>
    );
  }
);
