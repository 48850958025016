import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfigContext } from '../../../context';
import { IS_PRODUCTION, TCHOP_ROOT_URL } from '../../../env';
import {
  Channel,
  channelsNewApi,
  oidApi,
  useOidConnectionsQuery,
  userApi,
} from '../../../services';
import {
  Button,
  ButtonType,
  CUSTOM_CLIENT_CONFIG,
  CookieActionType,
  FREE_LOGIN_COOKIE_KEY,
  LOGIN_REDIRECT_STORAGE_KEY,
  MZ_ACCOUNT_COOKIE_KEY,
  cookieOptions,
  generateCodeChallenge,
  generateRandomString,
  getOrganisationDomain,
  getWebAppUrl,
} from '../../../shared';
import { Form } from '../Form';
import { FreeLogin } from './FreeLogin';
import { LoginWithEmail } from './LoginWithEmail';
import { TwoFactorAuth, TwoFactorAuthSetup, TwoFactorAuthSetupProps } from './TwoFactor';

import classNames from 'classnames';
import formClasses from '../Form/Form.module.scss';
import classes from './Login.module.scss';

export enum LoginContentType {
  SELECT = 'SELECT',
  SSO = 'SSO',
  EMAIL = 'EMAIL',
  FREE = 'FREE',
  CHANNEL = 'CHANNEL',
  SETUP_2FA = 'SETUP_2FA',
  CODE_2FA = 'CODE_2FA',
}

export const Login: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  const code = new URLSearchParams(location.search).get('code');

  const loginSSO = new URLSearchParams(location.search).get('loginSSO');

  const loginSAML = new URLSearchParams(location.search).get('loginSAML');

  const loginEmail = new URLSearchParams(location.search).get('loginEmail');

  const { data: oidConnections } = useOidConnectionsQuery();

  const [triggerOidcLogin] = oidApi.endpoints.oidcLogin.useLazyQuery();

  const [triggerLoginByEmail] = userApi.endpoints.userLoginByEmail.useLazyQuery();

  const [exchangeCodeToAccessToken] = oidApi.endpoints.oidcExchangeCodeToAccessToken.useLazyQuery();

  const [getChannels] = channelsNewApi.endpoints.channels.useLazyQuery();

  const freeLoginCookieKey = useMemo(
    () => `${FREE_LOGIN_COOKIE_KEY}_${getOrganisationDomain()}`,
    []
  );

  const [cookies, setCookie] = useCookies([freeLoginCookieKey, MZ_ACCOUNT_COOKIE_KEY]);

  const { freeLoginSessionsCount = 0 } = { ...cookies[freeLoginCookieKey] };

  const loginRedirect = localStorage.getItem(LOGIN_REDIRECT_STORAGE_KEY);

  const {
    channelLogos,
    login: {
      title,
      intro,
      logoUrl,
      SSOLabel,
      saml: { label: SAMLLabel, enabled: SAMLEnabled },
      email: { enabled: emailEnabled },
    },
    freeLogin,
  } = useContext(ConfigContext).config.elements;

  const { isDefault: isFreeLoginDefault } = { ...freeLogin };

  const [email, setEmail] = useState<string>('');

  const [password, setPassword] = useState<string>('');

  const [loginContentType, setLoginContentType] = useState<LoginContentType | null>(null);

  const [twoFactorAuthSetup, setTwoFactorAuthSetup] = useState<TwoFactorAuthSetupProps>({
    otpAuthSecret: '',
    otpAuthUrlQRCode: '',
    onVerify: () => null,
    onBack: () => null,
  });

  const [channels, setChannels] = useState<Channel[]>([]);

  const successLoginRedirect = useCallback(
    ({ webappUrl, skipLoginRedirect }: { webappUrl: string; skipLoginRedirect?: boolean }) => {
      if (loginRedirect && !skipLoginRedirect) {
        window.location.replace(loginRedirect);
        localStorage.removeItem(LOGIN_REDIRECT_STORAGE_KEY);
        return;
      }

      IS_PRODUCTION ? window.location.replace(webappUrl) : navigate('/');
    },
    [loginRedirect, navigate]
  );

  const onSuccessLogin = useCallback(
    async ({ accessToken, webappUrl }: { accessToken: string; webappUrl: string }) => {
      if (CUSTOM_CLIENT_CONFIG?.baseHost) {
        setCookie(
          MZ_ACCOUNT_COOKIE_KEY,
          accessToken,
          cookieOptions({
            action: CookieActionType.SET,
            domain: `.${CUSTOM_CLIENT_CONFIG.baseHost}`,
          })
        );
      }

      const channels = await getChannels({ filters: { excludeArchived: true } }).unwrap();

      const isDeepLinkRedirect = Boolean(
        loginRedirect && new URLSearchParams(new URL(loginRedirect).search).get('r')
      );

      if (channels.length > 1 && !isDeepLinkRedirect) {
        setChannels(channels);
        setLoginContentType(LoginContentType.CHANNEL);
        return;
      }

      successLoginRedirect({ webappUrl });
    },
    [getChannels, loginRedirect, setCookie, successLoginRedirect]
  );

  const ssoProcessing = useCallback(async () => {
    const codeVerifier = window.sessionStorage.getItem('code_verifier');

    if (!code || !oidConnections?.length || !codeVerifier) {
      setLoginContentType(LoginContentType.SELECT);
      return;
    }

    const { issuer } = oidConnections[0];

    try {
      const { accessToken } = await exchangeCodeToAccessToken({
        code,
        codeVerifier,
        issuer,
        redirectUrl: window.location.href.split('?')[0],
      }).unwrap();

      const { accessToken: loginAccessToken, channel } = await triggerOidcLogin({
        issuer,
        accessToken,
      }).unwrap();

      await onSuccessLogin({
        accessToken: loginAccessToken,
        webappUrl: getWebAppUrl(channel.subdomain),
      });
    } catch (_) {}
  }, [code, exchangeCodeToAccessToken, oidConnections, onSuccessLogin, triggerOidcLogin]);

  useEffect(() => {
    if (window.location.href.includes(`-${getOrganisationDomain()}`)) {
      const url = new URL(window.location.href);
      url.hostname = url.hostname.replace(/^([^.]+)-(.*)$/, '$2');
      window.location.replace(url.href);
    }
  }, []);

  useEffect(() => {
    if (typeof oidConnections === 'undefined') {
      return;
    }

    if (oidConnections.length) {
      if (code) {
        setLoginContentType(LoginContentType.SSO);
        ssoProcessing();
        return;
      }

      setLoginContentType(LoginContentType.SELECT);
      return;
    }

    if (isFreeLoginDefault && !freeLoginSessionsCount) {
      setLoginContentType(LoginContentType.FREE);
      return;
    }

    setLoginContentType(SAMLEnabled ? LoginContentType.SELECT : LoginContentType.EMAIL);
  }, [
    SAMLEnabled,
    code,
    freeLoginSessionsCount,
    isFreeLoginDefault,
    oidConnections,
    ssoProcessing,
  ]);

  const renderContentHeader = useMemo(
    () =>
      Boolean(
        ![LoginContentType.CHANNEL, LoginContentType.SETUP_2FA, LoginContentType.CODE_2FA].includes(
          loginContentType as LoginContentType
        ) && [title, intro, logoUrl].some((item) => Boolean(item))
      ),
    [intro, loginContentType, logoUrl, title]
  );

  const contentHeader = useMemo(() => {
    if (logoUrl) {
      return <img src={logoUrl} alt={'logo'} />;
    }

    return (
      <>
        {title && <span className={formClasses['form__content-header-title']}>{title}</span>}
        {intro && <div className={formClasses['form__content-header-subtitle']}>{intro}</div>}
      </>
    );
  }, [intro, logoUrl, title]);

  const otpProcessing = useCallback(
    async ({ otp, otpBackup }: { otp?: string; otpBackup?: string }) => {
      const { payload } = await triggerLoginByEmail({ email, password, otp, otpBackup }).unwrap();

      if (payload) {
        const { accessToken, channel } = payload;

        await onSuccessLogin({ accessToken, webappUrl: getWebAppUrl(channel.subdomain) });

        return;
      }

      toast.error(t('auth.2fa-otp-invalid'));
    },
    [email, onSuccessLogin, password, t, triggerLoginByEmail]
  );

  const twoFactorBackHandler = useCallback(() => {
    setLoginContentType(LoginContentType.EMAIL);
  }, []);

  const loginWithSSOHandler = useCallback(async () => {
    if (!oidConnections?.length) {
      return;
    }

    setLoginContentType(LoginContentType.SSO);

    const codeVerifier = generateRandomString(70);

    const state = generateRandomString(8);

    window.sessionStorage.setItem('code_verifier', codeVerifier);

    const codeChallenge = await generateCodeChallenge(codeVerifier);

    const { clientId, authorizationEndpoint, scopes, audience } = oidConnections[0];

    const args = new URLSearchParams({
      response_type: 'code',
      client_id: clientId,
      scope: scopes,
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
      audience,
      redirect_uri: window.location.href.split('?')[0],
      state,
    });

    window.location.replace(`${authorizationEndpoint}/?${args}`);
  }, [oidConnections]);

  const loginWithSAMLHandler = useCallback(() => {
    if (!SAMLEnabled) {
      return;
    }
    window.location.replace(
      `${TCHOP_ROOT_URL}/api/webapp/sso/saml/login?organisation=${getOrganisationDomain()}`
    );
  }, [SAMLEnabled]);

  useEffect(() => {
    if (!oidConnections?.length) {
      return;
    }

    if (loginSSO) {
      loginWithSSOHandler();
    }
  }, [loginSSO, loginWithSSOHandler, oidConnections?.length]);

  useEffect(() => {
    if (loginSAML) {
      loginWithSAMLHandler();
    }
  }, [loginSAML, loginWithSAMLHandler]);

  useEffect(() => {
    if (loginEmail) {
      setLoginContentType(LoginContentType.EMAIL);
    }
  }, [loginEmail, loginSSO, loginWithSSOHandler]);

  const loginWithEmailContent = useMemo(() => {
    return (
      <>
        <LoginWithEmail
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          onSuccessLogin={onSuccessLogin}
          setTwoFactorAuthSetup={setTwoFactorAuthSetup}
          otpProcessing={otpProcessing}
          twoFactorBackHandler={twoFactorBackHandler}
          setLoginContentType={setLoginContentType}
        />

        {Boolean(oidConnections?.length) && (
          <div
            className={classNames(
              formClasses['form__content-link'],
              formClasses['form__content-back']
            )}
            onClick={() => setLoginContentType(LoginContentType.SELECT)}
          >
            {t('login.back-to-welcome')}
          </div>
        )}
      </>
    );
  }, [
    email,
    oidConnections?.length,
    onSuccessLogin,
    otpProcessing,
    password,
    t,
    twoFactorBackHandler,
  ]);

  const loginSelectContent = useMemo(() => {
    return (
      <div className={classes['login-buttons']}>
        {Boolean(oidConnections?.length) && (
          <Button
            type={ButtonType.primary}
            label={SSOLabel ?? t('login.with-sso')}
            onClick={loginWithSSOHandler}
            fullWidth
          />
        )}
        {SAMLEnabled && (
          <Button
            type={ButtonType.primary}
            label={SAMLLabel ?? t('login.with-saml')}
            onClick={loginWithSAMLHandler}
            fullWidth
          />
        )}
        {emailEnabled && (
          <Button
            type={ButtonType.secondary}
            label={t('login.with-email')}
            onClick={() => setLoginContentType(LoginContentType.EMAIL)}
            fullWidth
          />
        )}
        <FreeLogin onSuccessLogin={onSuccessLogin} />
      </div>
    );
  }, [
    SAMLEnabled,
    SAMLLabel,
    SSOLabel,
    emailEnabled,
    loginWithSAMLHandler,
    loginWithSSOHandler,
    oidConnections?.length,
    onSuccessLogin,
    t,
  ]);

  const loginSSOContent = useMemo(() => {
    return (
      <div className={classes['loader-wrapper']}>
        <Skeleton height={50} width={'100%'} />
      </div>
    );
  }, []);

  const loginChannelContent = useMemo(() => {
    return (
      <>
        <div className={formClasses['form__content-header']}>
          <span className={formClasses['form__content-header-title']}>
            {t('common.channels', { count: channels.length })}
          </span>
          <div className={formClasses['form__content-header-subtitle']}>
            {t('auth.channel-select')}
          </div>
        </div>
        <div className={classes['channels']}>
          <div className={classes['channels-list']}>
            {channels.map(({ id, name, subdomain }) => {
              const logo = channelLogos.find(({ channelId }) => channelId === id)?.logoUrl;

              const content = logo ? (
                <img src={logo} className={classes['channels-list-item-logo']} alt={'logo'} />
              ) : (
                <div className={classes['channels-list-item-name']}>{name}</div>
              );

              return (
                <div
                  key={id}
                  className={classes['channels-list-item']}
                  onClick={() =>
                    successLoginRedirect({
                      webappUrl: getWebAppUrl(subdomain),
                      skipLoginRedirect: true,
                    })
                  }
                >
                  {content}
                </div>
              );
            })}
          </div>
          <div className={classes['channels-info']}></div>
        </div>
      </>
    );
  }, [channelLogos, channels, successLoginRedirect, t]);

  const loginContent = useMemo(() => {
    switch (loginContentType) {
      case LoginContentType.SELECT:
        return loginSelectContent;
      case LoginContentType.EMAIL:
        return loginWithEmailContent;
      case LoginContentType.SSO:
        return loginSSOContent;
      case LoginContentType.FREE:
        return <FreeLogin onSuccessLogin={onSuccessLogin} label={t('common.get-started')} />;
      case LoginContentType.CHANNEL:
        return loginChannelContent;
      case LoginContentType.SETUP_2FA:
        return <TwoFactorAuthSetup {...twoFactorAuthSetup} />;
      case LoginContentType.CODE_2FA:
        return <TwoFactorAuth onValidate={otpProcessing} onBack={twoFactorBackHandler} />;
      default:
        return null;
    }
  }, [
    loginChannelContent,
    loginContentType,
    loginSSOContent,
    loginSelectContent,
    loginWithEmailContent,
    onSuccessLogin,
    otpProcessing,
    t,
    twoFactorAuthSetup,
    twoFactorBackHandler,
  ]);

  if (!oidConnections) {
    return null;
  }

  return (
    <Form>
      {renderContentHeader && (
        <div className={formClasses['form__content-header']}>{contentHeader}</div>
      )}
      {loginContent}
    </Form>
  );
};
