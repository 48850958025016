import { FunctionComponent, memo } from 'react';
import { StoryCardPdfContent } from '../../../services';
import { FeedCardContentCommon, FeedCardPdfCommon, FeedCardProps } from '../../../shared';

export const FeedCardPdf: FunctionComponent<FeedCardProps> = memo(({ card, chatId }) => {
  const { storyId, id, type, content, isPinnedToTopOfNewsFeed } = card;
  const { sourceName, headline, subHeadline, text, textHTML } = content as StoryCardPdfContent;

  return (
    <FeedCardContentCommon
      storyId={storyId}
      cardId={id}
      chatId={chatId}
      type={type}
      pinned={isPinnedToTopOfNewsFeed}
      source={sourceName}
      title={headline}
      media={<FeedCardPdfCommon card={card} />}
      subTitle={subHeadline}
      text={text}
      htmlText={textHTML}
    />
  );
});
