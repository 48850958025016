import { FunctionComponent, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ChatContext } from '../../../context';
import { Chat, chatsApi, ChatTypes } from '../../../services';
import { Avatar, Button, ButtonType } from '../../../shared';
import { ChatTabsTypes } from '../Chats';

import classes from './JoinChat.module.scss';

interface JoinChatProps {
  chat: Chat;
  callback?: () => void;
}

export const JoinChat: FunctionComponent<JoinChatProps> = memo(({ chat, callback }) => {
  const { t } = useTranslation();

  const { pubnub, activeChat, setActiveChat, setChats, setSelectedTabIndex } =
    useContext(ChatContext);

  const [joinChat] = chatsApi.endpoints.joinChat.useMutation();

  const [getChannelChats] = chatsApi.endpoints.getChannelChats.useLazyQuery();

  const [joinLoading, setJoinLoading] = useState(false);

  const { chatId, type, chatDescriptor, name, payload, thumbnails, image } = chat;

  const chatChangesDescriptor = useMemo(() => `${chatDescriptor}.changes`, [chatDescriptor]);

  const avatarUrl = useMemo(
    () => (type === ChatTypes.p2p ? thumbnails[0]?.image : image?.url),
    [image?.url, thumbnails, type]
  );

  const joinHandler = useCallback(async () => {
    setJoinLoading(true);

    try {
      await joinChat({ chatId });

      const { chats, tokenV3 } = await getChannelChats().unwrap();

      pubnub.setAuthKey(tokenV3);

      setChats(chats);

      setActiveChat(chats.find((chat) => chat.chatId === chatId) ?? activeChat);

      setSelectedTabIndex(ChatTabsTypes.all);

      pubnub.subscribe({ channels: [chatDescriptor, chatChangesDescriptor] });

      callback?.();
    } catch (_) {
      toast.error(t('chatActions.join-error'));
    } finally {
      setJoinLoading(false);
    }
  }, [
    activeChat,
    callback,
    chatChangesDescriptor,
    chatDescriptor,
    chatId,
    getChannelChats,
    joinChat,
    pubnub,
    setActiveChat,
    setChats,
    setSelectedTabIndex,
    t,
  ]);

  return (
    <div className={classes['join-chat']}>
      <Avatar size={56} url={avatarUrl} nonClickable />

      <div className={classes['join-chat__name']}>{name}</div>

      {payload && <div className={classes['join-chat__purpose']}>{payload}</div>}

      <Button
        type={ButtonType.primary}
        label={t('chatActions.join')}
        disabled={joinLoading}
        loading={joinLoading}
        onClick={joinHandler}
      />
    </div>
  );
});
