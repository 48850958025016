import { Crisp } from 'crisp-sdk-web';
import { FunctionComponent, memo, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { Outlet, useNavigate } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import {
  DetailsModalAdmin,
  InAppMessageManager,
  NavbarAdmin,
  SidebarAdmin,
  TermsModal,
} from '../../components';
import {
  ChannelContext,
  ConfigContext,
  DetailsModalContext,
  DetailsModalData,
  MusicPlayerContext,
  MusicPlayerData,
  OrganisationContext,
  UserContext,
} from '../../context';
import { useGetConfigQuery, useOrganisationQuery, User } from '../../services';
import {
  CRISP_SITE_ID,
  getOrganisationDomain,
  MusicPlayer,
  ScrollToTop,
  useAnalytics,
  useAppSelector,
  useBeforeUnload,
  useConfig,
  useDuration,
  useLinkClick,
  useNotificationsPermissionPopup,
  useUserDevice,
} from '../../shared';
import { useLayoutData } from '../useLayoutData';

import classNames from 'classnames';
import 'react-toastify/dist/ReactToastify.css';
import classes from './AdminLayout.module.scss';

interface AdminLayoutProps {
  rightSideModule?: JSX.Element;
  fullWidthContent?: boolean;
}

export const AdminLayout: FunctionComponent<AdminLayoutProps> = memo(
  ({ rightSideModule, fullWidthContent }) => {
    const navigate = useNavigate();

    const { data: layoutData } = useLayoutData();

    const { userProfile, userInfo, channelBySubdomain } = { ...layoutData };

    const userId = userProfile?.id ?? 0;

    const channelId = userInfo?.userData.channelId ?? 0;

    const { channels } = useAppSelector(({ channels }) => channels);

    const { startTrackTime, getDuration } = useDuration();

    const organisationDomain = useMemo(() => getOrganisationDomain(), []);

    const { data: organisation } = useOrganisationQuery();

    const { data: config } = useGetConfigQuery({ organisationDomain });

    useConfig(config);

    useUserDevice(config);

    useNotificationsPermissionPopup(config);

    const { logSessionEnd } = useAnalytics(config);

    const { appTabs, allAppTabs, dashboardUrl, permissions } = channelBySubdomain ?? {
      appTabs: [],
      allAppTabs: [],
      dashboardUrl: null,
      permissions: {
        channelInAppMessageAllowToCreate: false,
        channelPushNotificationAllowToCreate: false,
        chatGroupAllowToCreate: false,
        storyAllowToCreate: false,
      },
    };

    // redirect in case of no admin rights
    if (channelBySubdomain && !dashboardUrl) {
      navigate('/');
    }

    useEffect(() => {
      startTrackTime();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!userProfile || !userInfo) {
        return;
      }

      const { email, screenName, avatar, roleId } = userProfile;

      if (!isMobileOnly) {
        Crisp.configure(CRISP_SITE_ID);

        if (email) {
          Crisp.user.setEmail(email);
        }

        Crisp.user.setNickname(screenName);

        if (avatar?.url) {
          Crisp.user.setAvatar(avatar?.url);
        }

        Crisp.session.onLoaded(() => {
          Crisp.session.setData({
            tchop_user_id: userId,
            browser_name: window.navigator.userAgent,
            user_role: roleId,
            organization_name: organisationDomain,
            channel_id: channelId,
            channel_name: userInfo.body.channelName,
          });
        });

        Crisp.chat.show();
      }
    }, [channelId, organisationDomain, userId, userInfo, userProfile]);

    useBeforeUnload(() =>
      logSessionEnd({
        channel_id: channelId,
        duration: getDuration(),
        time_stamp: new Date().toISOString(),
      })
    );

    const [userProfileData, setUserProfile] = useState<User | undefined>();

    useEffect(() => {
      if (!userProfile) {
        return;
      }

      setUserProfile(userProfile);
    }, [userProfile]);

    const [musicPlayerData, setMusicPlayerData] = useState<MusicPlayerData>({
      channelId: 0,
      storyId: 0,
      cardId: 0,
      cardTitle: '',
      src: '',
      isPlaying: false,
    });

    const [detailsModalData, setDetailsModalData] = useState<DetailsModalData>({
      storyId: null,
      cardId: null,
    });

    useLinkClick({ channelId, userId, setDetailsModalData });

    if (
      !userProfileData ||
      !userInfo ||
      !channels.length ||
      !config ||
      !channelBySubdomain ||
      !organisation
    ) {
      return null;
    }

    const { title, faviconUrl } = config.elements.meta;

    return (
      <>
        <Helmet>
          <title>{title} - Admin</title>
          <link rel={'icon'} href={faviconUrl} />
        </Helmet>

        <ScrollToTop />

        <UserContext.Provider value={{ userProfile: userProfileData, setUserProfile, userInfo }}>
          <ConfigContext.Provider value={{ config }}>
            <OrganisationContext.Provider value={{ organisation }}>
              <ChannelContext.Provider value={{ appTabs, allAppTabs, dashboardUrl, permissions }}>
                <MusicPlayerContext.Provider value={{ musicPlayerData, setMusicPlayerData }}>
                  <DetailsModalContext.Provider value={{ detailsModalData, setDetailsModalData }}>
                    <div className={classes['layout']}>
                      <NavbarAdmin />

                      <div className={classes['layout__page']}>
                        {!isMobileOnly && <SidebarAdmin />}

                        <div
                          className={classNames(classes['layout__content'], {
                            [classes['layout__content--full-width']]: fullWidthContent,
                            [classes['layout__content--mobile']]: isMobileOnly,
                          })}
                        >
                          <Outlet />
                        </div>

                        {!isMobileOnly && rightSideModule}
                      </div>

                      <InAppMessageManager />
                    </div>

                    <TermsModal />

                    <MusicPlayer />

                    <DetailsModalAdmin />
                  </DetailsModalContext.Provider>
                </MusicPlayerContext.Provider>
              </ChannelContext.Provider>
            </OrganisationContext.Provider>
          </ConfigContext.Provider>
        </UserContext.Provider>

        <ToastContainer
          autoClose={2000}
          position={'bottom-center'}
          transition={Slide}
          newestOnTop
          hideProgressBar={true}
          pauseOnHover={false}
          draggable={false}
          style={{ zIndex: 999999 }}
        />
      </>
    );
  }
);
