import { FunctionComponent, PropsWithChildren, memo } from 'react';
import { CardActions, FeedCardProps, FeedCardWrapperCommon } from '../../../shared';

export const FeedCardWrapper: FunctionComponent<PropsWithChildren<FeedCardProps>> = memo(
  ({ children, ...props }) => {
    return (
      <FeedCardWrapperCommon CardActionsComponent={CardActions} {...props}>
        {children}
      </FeedCardWrapperCommon>
    );
  }
);
