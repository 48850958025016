import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import { OrganisationContext, UserContext } from '../../../context';
import {
  OrganisationTagType,
  storyCardApi,
  StoryCardsChannelSearchFilters,
} from '../../../services';
import {
  cookieOptions,
  DATE_RANGE_GROUP_ID,
  Filter,
  FilterGroup,
  FilterValue,
  optArrItem,
  SEARCH_ADMIN_COOKIE_KEY,
  useAppDispatch,
  useAuthorsFilter,
  useCardCategoryFilter,
  useCardStatusFilter,
  useCardStoryFilter,
  useCardTypeFilter,
  useDateRangeFilter,
  useFilter,
  useTags,
  useTagsFilter,
} from '../../../shared';
import { getSearchFeed } from '../../../slices';

import classes from './SearchFeedAdminRightSide.module.scss';

export const SearchFeedAdminRightSide: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const { channelId } = useContext(UserContext).userInfo.userData;

  const { getGroupValue, getDateRangeValue } = useFilter();

  const { hasInternalTags, hasExternalTags } = useTags();

  const hasCategories = Boolean(useContext(OrganisationContext).organisation.categories.length);

  const [fetchChannelAuthors] =
    storyCardApi.endpoints.storyCardAuthorsFiltersByChannelId.useLazyQuery();

  const [cookies, setCookie] = useCookies([SEARCH_ADMIN_COOKIE_KEY]);

  const [filter, setFilter] = useState<FilterValue[]>(
    cookies[SEARCH_ADMIN_COOKIE_KEY]?.filter ?? []
  );

  const { group: dateRangeGroup } = useDateRangeFilter({ filter });

  const { group: authorsGroup } = useAuthorsFilter({
    filter,
    fetchItemsQuery: fetchChannelAuthors,
    fetchItemsQueryArgs: { channelId },
  });

  const { group: categoryGroup } = useCardCategoryFilter({ filter });

  const { group: internalTagsGroup } = useTagsFilter({
    filter,
    types: [OrganisationTagType.INTERNAL],
  });

  const { group: externalTagsGroup } = useTagsFilter({
    filter,
    types: [OrganisationTagType.EXTERNAL],
  });

  const { group: cardTypeGroup } = useCardTypeFilter();

  const { group: cardStatusGroup } = useCardStatusFilter();

  const { group: cardStoryGroup } = useCardStoryFilter({ filter });

  const query = (searchParams.get('qs') ?? '').replace(/\\|"|'/g, '');

  const groups: FilterGroup[] = [
    ...optArrItem(hasInternalTags, internalTagsGroup),
    ...optArrItem(hasExternalTags, externalTagsGroup),
    ...optArrItem(hasCategories, categoryGroup),
    authorsGroup,
    cardStoryGroup,
    dateRangeGroup,
    cardTypeGroup,
    cardStatusGroup,
  ];

  const filterChange = useCallback(
    (filter: FilterValue[]) => {
      const authorId = getGroupValue({
        filter,
        groupId: authorsGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const categoryId = getGroupValue({
        filter,
        groupId: categoryGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const internalTagsId = getGroupValue({
        filter,
        groupId: internalTagsGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const externalTagsId = getGroupValue({
        filter,
        groupId: externalTagsGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const storyId = getGroupValue({
        filter,
        groupId: cardStoryGroup.id,
        multiCheck: true,
        propToMap: 'id',
      });

      const { dateRange, from, to } = getDateRangeValue({ filter, groupId: DATE_RANGE_GROUP_ID });

      const storyCardType = getGroupValue({ filter, groupId: cardTypeGroup.id, multiCheck: true });

      const status = getGroupValue({ filter, groupId: cardStatusGroup.id, multiCheck: true });

      const requestFilterToSave: StoryCardsChannelSearchFilters = {
        ...(authorId?.length && { authorId }),
        ...(categoryId?.length && { categoryId }),
        ...((internalTagsId?.length || externalTagsId?.length) && {
          organisationTags: {
            internalTagsId: internalTagsId?.[0] === null ? null : internalTagsId,
            externalTagsId: externalTagsId?.[0] === null ? null : externalTagsId,
          },
        }),
        ...(storyId?.length && { storyId }),
        ...(storyCardType?.length && { storyCardType }),
        ...(status?.length && { status }),
        dateRange,
      };

      const requestFilter: StoryCardsChannelSearchFilters = {
        ...requestFilterToSave,
        ...(from && { from }),
        ...(to && { to }),
      };

      setCookie(
        SEARCH_ADMIN_COOKIE_KEY,
        { requestFilter: requestFilterToSave, filter },
        cookieOptions()
      );

      dispatch(getSearchFeed({ channelId, filters: { ...requestFilter, query } }));

      setFilter(filter);
    },
    [
      authorsGroup.id,
      cardStatusGroup.id,
      cardStoryGroup.id,
      cardTypeGroup.id,
      categoryGroup.id,
      channelId,
      dispatch,
      externalTagsGroup.id,
      getDateRangeValue,
      getGroupValue,
      internalTagsGroup.id,
      query,
      setCookie,
    ]
  );

  return (
    <div className={classes['search-feed-admin-right-side']}>
      <Filter filter={filter} groups={groups} onChange={filterChange} resetPageScrollOnChange />
    </div>
  );
};
