import { FunctionComponent, memo, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context';
import { User } from '../../../services';
import { layoutPath } from '../../utils';

import classNames from 'classnames';
import classes from './UserMentionPreview.module.scss';

interface UserMentionPreviewProps {
  mentionedUsers: User[];
  openProfile?: boolean;
  onClick?: () => void;
}

export const UserMentionPreview: FunctionComponent<UserMentionPreviewProps> = memo(
  ({ mentionedUsers, openProfile, onClick }) => {
    const navigate = useNavigate();

    const userId = useContext(UserContext).userProfile.id;

    const onClickHandler = useCallback(
      (id: number) => {
        if (openProfile) {
          navigate(layoutPath(`${id === userId ? '/profile' : `/profile/${id}`}`));
          return;
        }

        onClick?.();
      },
      [navigate, onClick, openProfile, userId]
    );

    if (!mentionedUsers.length) {
      return null;
    }

    return (
      <div className={classes['user-mention']}>
        {mentionedUsers.map(({ id, screenName }) => {
          return (
            <div
              key={id}
              className={classNames(classes['user-mention__link'], {
                [classes['user-mention__link--clickable']]: Boolean(openProfile || onClick),
              })}
              onClick={() => onClickHandler(id)}
            >
              @{screenName}
            </div>
          );
        })}
      </div>
    );
  }
);
