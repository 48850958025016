import { FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../context';
import {
  IconLabel,
  USER_DIRECTORY_READER_COOKIE_KEY,
  useAppDispatch,
  useAppSelector,
} from '../../shared';
import { getOrganisationUsers, loadMoreOrganisationUsers } from '../../slices';
import { UserDirectoryItem } from './UserDirectoryItem';

import classes from './UserDirectory.module.scss';

export const UserDirectory: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    appTabsSecondaryOrders,
    userDirectory: { localiseKey, filters },
  } = useContext(ConfigContext).config.elements;

  if (!appTabsSecondaryOrders.includes('userDirectory')) {
    navigate('/');
  }

  const [cookies] = useCookies([USER_DIRECTORY_READER_COOKIE_KEY]);

  const {
    requestFilter = {
      query: '',
      genders: [],
      filterPositions: [],
      filterDepartments: [],
      filterSubscriptions: [],
      organisationTagsId: [],
      rolesId: [],
      queryLocation: '',
    },
  } = { ...cookies[USER_DIRECTORY_READER_COOKIE_KEY] };

  const {
    name: { visible: nameVisible },
    gender: { visible: genderVisible },
    position: { visible: positionVisible },
    department: { visible: departmentVisible },
    subscription: { visible: subscriptionVisible },
    organisationTags: { visible: organisationTagsVisible },
    role: { visible: roleVisible },
    location: { visible: locationVisible },
  } = filters;

  const filter = useMemo(() => {
    return {
      ...requestFilter,
      ...(!nameVisible && { query: '' }),
      ...(!genderVisible && { genders: [] }),
      ...(!positionVisible && { filterPositions: [] }),
      ...(!departmentVisible && { filterDepartments: [] }),
      ...(!subscriptionVisible && { filterSubscriptions: [] }),
      ...(!organisationTagsVisible && { organisationTagsId: [] }),
      ...(!roleVisible && { rolesId: [] }),
      ...(!locationVisible && { queryLocation: '' }),
    };
  }, [
    departmentVisible,
    genderVisible,
    locationVisible,
    nameVisible,
    organisationTagsVisible,
    positionVisible,
    requestFilter,
    roleVisible,
    subscriptionVisible,
  ]);

  const { users, page, hasNextPage, isFetching } = useAppSelector(
    ({ organisationUsers }) => organisationUsers
  );

  useEffect(() => {
    dispatch(getOrganisationUsers({ filter }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const loadMore = useCallback(() => {
    dispatch(loadMoreOrganisationUsers({ page: page + 1, filter }));
  }, [dispatch, filter, page]);

  const loader = useMemo(() => {
    return (
      <div className={classes['user-directory__loader']}>
        <Skeleton height={64} width={'100%'} />
        <Skeleton height={64} width={'100%'} />
        <Skeleton height={64} width={'100%'} />
      </div>
    );
  }, []);

  const hasUsers = useMemo(() => Boolean(users.length), [users.length]);

  const content = useMemo(() => {
    if (isFetching) {
      return loader;
    }

    if (!hasUsers) {
      return (
        <div className={classes['user-directory__empty']}>
          <IconLabel iconId={'search-off'} iconSize={48} nonClickable />
          <div className={classes['user-directory__empty-message']}>
            {t('userDirectory.empty-message')}
          </div>
        </div>
      );
    }
    return (
      <InfiniteScroll
        next={loadMore}
        hasMore={hasNextPage}
        loader={loader}
        dataLength={users.length}
        className={classes['user-directory__feed']}
      >
        {users.map((user) => (
          <UserDirectoryItem key={user.id} user={user} />
        ))}
      </InfiniteScroll>
    );
  }, [hasNextPage, hasUsers, isFetching, loadMore, loader, t, users]);

  return (
    <div className={classes['user-directory']}>
      <div className={classes['user-directory__title']}>{t(localiseKey)}</div>
      {content}
    </div>
  );
};
