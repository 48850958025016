import { Dispatch, FunctionComponent, SetStateAction, memo, useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useTags } from '../../../hooks';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';
import { TextAreaField } from '../../TextAreaField';
import { CreateCardSettingsTabType, ThreadPostFields } from '../CreateCardModal';

import classNames from 'classnames';
import classes from './ThreadPost.module.scss';

interface ThreadPostProps {
  threadPostFields: ThreadPostFields;
  setThreadPostFields: Dispatch<SetStateAction<ThreadPostFields>>;
  setActiveThreadPostSettingsTabIndex: (index: number) => void;
  setSettingsOpen: (isOpen: boolean) => void;
}

export const ThreadPost: FunctionComponent<ThreadPostProps> = memo(
  ({
    threadPostFields,
    setThreadPostFields,
    setActiveThreadPostSettingsTabIndex,
    setSettingsOpen,
  }) => {
    const { t } = useTranslation();

    const { hasTags } = useTags();

    const tagsClickHandler = useCallback(() => {
      setActiveThreadPostSettingsTabIndex(CreateCardSettingsTabType.TAGS);
      setSettingsOpen(true);
    }, [setActiveThreadPostSettingsTabIndex, setSettingsOpen]);

    return (
      <div className={classes['thread-post']}>
        <div
          className={classNames(classes['thread-post__scrollable'], {
            [classes['thread-post__scrollable--mobile']]: isMobileOnly,
            [classes['thread-post__scrollable--has-tags']]: hasTags,
          })}
        >
          <div className={classes['thread-post__description']}>
            <IconLabel
              iconId={'alert'}
              color={getCssVar('--create-card-thread-icon-color')}
              singleColor
              nonClickable
            />
            <div className={classes['thread-post__description-text']}>
              <div className={classes['thread-post__description-text--title']}>
                {t('addContent.thread-post-title')}
              </div>
              <div>{t('addContent.thread-post-description')}</div>
            </div>
          </div>

          <TextAreaField
            inputClassName={classes['thread-post__input']}
            value={threadPostFields.title}
            placeholder={t('addContent.thread-post-placeholder')}
            maxLength={360}
            onChange={({ target }) => setThreadPostFields({ title: target.value })}
            disableLineBreak
          />
        </div>
        {hasTags && (
          <IconLabel
            iconId={'tags'}
            iconSize={20}
            label={t('addContent.tags')}
            color={getCssVar('--create-card-link-color')}
            hoverColor={getCssVar('--create-card-link-hover-color')}
            className={classes['thread-post__tags']}
            onClick={tagsClickHandler}
          />
        )}
      </div>
    );
  }
);
