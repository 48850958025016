import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import React, {
  Dispatch,
  forwardRef,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CommentAttachment } from '../../../../../services';
import {
  FileAcceptType,
  ImageUploadType,
  TransloaditAuthTemplate,
  useLoaderText,
  useOnClickOutside,
  useUpload,
} from '../../../../hooks';
import {
  getAcceptTypeByFileType,
  getCssVar,
  getEmojiPickerLocale,
  urlToFile,
} from '../../../../utils';
import { CircularLoader } from '../../../CircularLoader';
import { generateTeaserUrl } from '../../../File';
import { IconLabel } from '../../../IconLabel';
import { TextAreaField } from '../../../TextAreaField';
import { CommentAttachments } from '../../CommentAttachments';
import { EmojiSelectProps } from '../CommentBox';

import classes from './CommentInput.module.scss';

interface CommentInputProps {
  text: string;
  setText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  attachments: CommentAttachment[];
  setAttachments: (attachments: CommentAttachment[]) => void;
  onEmojiSelect: (emoji: EmojiSelectProps) => void;
  onSubmit: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
}

export const CommentInput = memo(
  forwardRef<HTMLTextAreaElement, CommentInputProps>(
    (
      {
        text,
        setText,
        attachments,
        setAttachments,
        onEmojiSelect,
        onSubmit,
        loading,
        setLoading,
        onClose,
      },
      ref
    ) => {
      const { t } = useTranslation();

      const { uploadHandler, getUploadType, getTemplateType, onUploadCompleted } = useUpload();

      const { uploadHandler: teaserUploadHandler } = useUpload();

      const { loaderText } = useLoaderText(loading);

      const [showEmojiPicker, setShowEmojiPicker] = useState(false);

      const emojiPickerRef = useRef(null);

      const fileRef = useRef<HTMLInputElement | null>(null);

      const hasAttachments = useMemo(() => Boolean(attachments.length), [attachments.length]);

      useOnClickOutside(emojiPickerRef, () => {
        setShowEmojiPicker(false);
      });

      const onKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
          const { key, shiftKey, target } = event;

          if (key === 'Enter' && !shiftKey) {
            event.preventDefault();
            onSubmit();

            (target as HTMLTextAreaElement).blur();
          }

          if (key === 'Escape') {
            onClose?.();
          }
        },
        [onClose, onSubmit]
      );

      const getAttachment = useCallback(
        async ({
          id,
          file,
          acceptType,
        }: {
          id: number;
          file: File;
          acceptType: FileAcceptType;
        }) => {
          const teaserUrl = await generateTeaserUrl({ file, acceptType });

          const teaser = teaserUrl
            ? {
                image: {
                  id: (
                    await teaserUploadHandler({
                      file: await urlToFile(teaserUrl),
                      template: getTemplateType({ acceptType: FileAcceptType.IMAGE }),
                      type: getUploadType({ acceptType: FileAcceptType.IMAGE }),
                      imageUploadType: ImageUploadType.ITEM,
                    })
                  ).id,
                  url: teaserUrl,
                },
              }
            : null;

          switch (acceptType) {
            case FileAcceptType.IMAGE:
              return {
                imageId: id,
                image: { id, url: URL.createObjectURL(file) },
              };
            case FileAcceptType.VIDEO:
              return {
                videoId: id,
                video: { id },
                ...teaser,
              };
          }
        },
        [getTemplateType, getUploadType, teaserUploadHandler]
      );

      const onFileChange = useCallback(
        async (files: FileList | File[] | null) => {
          if (!files?.length) {
            return;
          }

          setLoading(true);

          const uploadedFiles = [];

          for (const file of files) {
            const { type } = file;

            const fileType = type.includes('application/') ? type : type.split('/')[0];

            const acceptType = getAcceptTypeByFileType(fileType);

            if (!acceptType || ![FileAcceptType.IMAGE, FileAcceptType.VIDEO].includes(acceptType)) {
              toast.error(t('fileUpload.file-type-error'));

              setLoading(false);

              continue;
            }

            const { id, assembly_id } = await uploadHandler({
              file,
              template: TransloaditAuthTemplate.COMMENT_FILE,
              type: getUploadType({ acceptType }),
              ...(acceptType === FileAcceptType.IMAGE && {
                imageUploadType: ImageUploadType.COMMENT,
              }),
            });

            uploadedFiles.push({ ...(await getAttachment({ id, file, acceptType })) });

            setAttachments([...attachments, ...uploadedFiles] as CommentAttachment[]);

            onUploadCompleted({ assembly_id, callback: () => setLoading(false) });
          }
        },
        [
          attachments,
          getAttachment,
          getUploadType,
          onUploadCompleted,
          setAttachments,
          setLoading,
          t,
          uploadHandler,
        ]
      );

      const onPaste = useCallback(
        async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
          const { clipboardData } = event;
          const { files } = clipboardData;

          if (!files.length) {
            return;
          }

          onFileChange([files[0]]);
        },
        [onFileChange]
      );

      const sendComponent = useMemo(() => {
        if (loading) {
          return (
            <CircularLoader
              sizeRem={1.25}
              className={classes['comment-input__icons-loader']}
              text={loaderText}
            />
          );
        }

        if (!text.trim().length && !hasAttachments) {
          return null;
        }

        return (
          <IconLabel
            iconId={'send'}
            iconSize={20}
            className={classes['comment-input__icons-send']}
            color={getCssVar('--comments-input-icon-color')}
            hoverColor={getCssVar('--comments-input-icon-hover-color')}
            onClick={onSubmit}
          />
        );
      }, [hasAttachments, loaderText, loading, onSubmit, text]);

      const onDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
          (event.target as HTMLDivElement).classList.remove(
            classes['comment-input__field--dropzone']
          );

          if (hasAttachments) {
            return;
          }

          const files = event.dataTransfer.files;

          if (!files.length) {
            return;
          }

          onFileChange([files[0]]);
        },
        [hasAttachments, onFileChange]
      );

      const onDragOver = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
          event.preventDefault();

          if (hasAttachments) {
            return;
          }

          const target = event.target as HTMLDivElement;

          if (!target.classList.contains(classes['comment-input__field'])) {
            return;
          }

          (event.target as HTMLDivElement).classList.add(classes['comment-input__field--dropzone']);
        },
        [hasAttachments]
      );

      const onDragLeave = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
          if (hasAttachments) {
            return;
          }

          (event.target as HTMLDivElement).classList.remove(
            classes['comment-input__field--dropzone']
          );
        },
        [hasAttachments]
      );

      useEffect(() => {
        window.addEventListener('dragover', (event) => event.preventDefault(), false);
        window.addEventListener('drop', (event) => event.preventDefault(), false);
      }, []);

      return (
        <div
          className={classes['comment-input']}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
        >
          <input
            type={'file'}
            ref={fileRef}
            disabled={loading}
            accept={`${FileAcceptType.IMAGE}, ${FileAcceptType.VIDEO}`}
            className={classes['comment-input__file']}
            onChange={({ target }) => onFileChange(target.files)}
            onClick={({ target }) => ((target as HTMLInputElement).value = '')}
          />

          <TextAreaField
            ref={ref}
            inputClassName={classes['comment-input__field']}
            value={text}
            onChange={setText}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            placeholder={t('comments.placeholder')}
            disabled={loading}
          />

          <CommentAttachments attachments={attachments} setAttachments={setAttachments} />

          <div className={classes['comment-input__icons']}>
            <IconLabel
              iconId={'mood'}
              iconSize={20}
              color={getCssVar('--comments-input-icon-color')}
              hoverColor={getCssVar('--comments-input-icon-hover-color')}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              disabled={loading}
            />

            {!hasAttachments && (
              <IconLabel
                iconId={'image'}
                iconSize={20}
                color={getCssVar('--comments-input-icon-color')}
                hoverColor={getCssVar('--comments-input-icon-hover-color')}
                onClick={() => fileRef.current?.click()}
                disabled={loading}
              />
            )}

            {onClose && (
              <IconLabel
                iconId={'close'}
                iconSize={20}
                color={getCssVar('--comments-input-icon-color')}
                hoverColor={getCssVar('--comments-input-icon-hover-color')}
                onClick={onClose}
                disabled={loading}
              />
            )}

            {sendComponent}
          </div>

          {showEmojiPicker && (
            <div ref={emojiPickerRef} className={classes['comment-input__emoji-picker']}>
              <Picker
                data={data}
                locale={getEmojiPickerLocale()}
                onEmojiSelect={onEmojiSelect}
                maxFrequentRows={3}
                perLine={isMobileOnly ? 6 : 10}
                emojiSize={18}
                previewPosition={'none'}
                searchPosition={'static'}
                navPosition={'bottom'}
              />
            </div>
          )}
        </div>
      );
    }
  )
);
