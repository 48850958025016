import {
  Dispatch,
  FunctionComponent,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ChatContext } from '../../../../../context';
import { Chat, ChatAccess, ChatMembersData, chatsApi } from '../../../../../services';
import { DropdownMenu, getCssVar, IconLabel, Popup } from '../../../../../shared';

import dropdownMenuClasses from '../../../../../shared/components/DropdownMenu/DropdownMenu.module.scss';
import classes from './ChatMemberAccess.module.scss';

interface ChatMemberAccessProps {
  userId: number;
  chatAccess: ChatAccess;
  membersData: ChatMembersData | null;
  setMembersData: Dispatch<SetStateAction<ChatMembersData | null>>;
}

export const ChatMemberAccess: FunctionComponent<ChatMemberAccessProps> = memo(
  ({ userId, chatAccess, membersData, setMembersData }) => {
    const { t } = useTranslation();

    const { activeChat } = useContext(ChatContext);

    const { chatId } = activeChat as Chat;

    const [membersAccess] = chatsApi.endpoints.membersAccess.useMutation();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const changeMembersAccess = useCallback(
      async (access: ChatAccess) => {
        setIsOpen(false);

        try {
          await membersAccess({ chatId, users: [{ id: userId, access }] }).unwrap();

          if (!membersData) {
            return;
          }

          const itemsCopy = [...membersData.items];

          const userToUpdateIndex = itemsCopy.findIndex(({ id }) => id === userId);

          if (userToUpdateIndex === -1) {
            return;
          }

          itemsCopy[userToUpdateIndex] = { ...itemsCopy[userToUpdateIndex], chatAccess: access };

          setMembersData({ ...membersData, items: itemsCopy });
        } catch (e) {
          toast.error(t('common.error-title'));
        }
      },
      [chatId, membersAccess, membersData, setMembersData, t, userId]
    );

    const getAccessOption = useCallback(
      (access: ChatAccess) => {
        return (
          <IconLabel
            label={t(`chatAccess.${access}`)}
            className={dropdownMenuClasses['dropdown-menu__item--small']}
            onClick={() => changeMembersAccess(access)}
            {...(access === ChatAccess.banned && { color: getCssVar('--color-danger') })}
            singleColor
          />
        );
      },
      [changeMembersAccess, t]
    );

    const chatAccessOptions = useMemo(() => {
      switch (chatAccess) {
        case ChatAccess.admin:
          return (
            <>
              {getAccessOption(ChatAccess.r)}
              {getAccessOption(ChatAccess.rw)}
              {getAccessOption(ChatAccess.banned)}
            </>
          );
        case ChatAccess.rw:
          return (
            <>
              {getAccessOption(ChatAccess.admin)}
              {getAccessOption(ChatAccess.r)}
              {getAccessOption(ChatAccess.banned)}
            </>
          );
        case ChatAccess.r:
          return (
            <>
              {getAccessOption(ChatAccess.admin)}
              {getAccessOption(ChatAccess.rw)}
              {getAccessOption(ChatAccess.banned)}
            </>
          );
        case ChatAccess.banned:
          return (
            <>
              {getAccessOption(ChatAccess.admin)}
              {getAccessOption(ChatAccess.rw)}
              {getAccessOption(ChatAccess.r)}
            </>
          );
      }
    }, [chatAccess, getAccessOption]);

    return (
      <Popup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        iconId={'dots-menu'}
        bodyRight={'0.125rem'}
        parentClassName={classes['chat-member-access']}
        body={<DropdownMenu width={'12rem'} content={chatAccessOptions} />}
      />
    );
  }
);
