import { format, parseISO } from 'date-fns';
import {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ApiClientType, storyCardApi } from '../../../services';
import {
  COLLAPSE_STORAGE_KEY,
  COPY_CUT_COOKIE_KEY,
  CardActionsAdmin,
  CollapseMode,
  CopyCutAction,
  FeedCardChangeAuthor,
  FeedCardCollapsedHeader,
  FeedCardCut,
  FeedCardMargin,
  FeedCardProps,
  FeedCardWrapperCommon,
  IconLabel,
  getCssVar,
  getWebAppUrl,
} from '../../../shared';

import classes from './FeedCardAdminWrapper.module.scss';

export const FeedCardAdminWrapper: FunctionComponent<PropsWithChildren<FeedCardProps>> = memo(
  ({ card, children }) => {
    const { t } = useTranslation();

    const [getStoryCardSource] = storyCardApi.endpoints.storyCardSourceById.useLazyQuery();

    const [cookies] = useCookies([COPY_CUT_COOKIE_KEY]);

    const { cardId: copyCutCardId = 0, action: copyCutAction = '' } = {
      ...cookies[COPY_CUT_COOKIE_KEY],
    };

    const [cardCollapsed, setCardCollapsed] = useState(true);

    const [collapseMode, setCollapseMode] = useState(
      localStorage.getItem(COLLAPSE_STORAGE_KEY) || CollapseMode.STANDARD
    );

    useEffect(() => {
      window.addEventListener('storage', () => {
        setCollapseMode(localStorage.getItem(COLLAPSE_STORAGE_KEY) || CollapseMode.STANDARD);
      });
    }, []);

    const isCollapsed = useMemo(
      () =>
        collapseMode === CollapseMode.COLLAPSED &&
        !Boolean(/profile|search/.test(window.location.href)),
      [collapseMode]
    );

    useEffect(() => {
      setCardCollapsed(isCollapsed);
    }, [isCollapsed]);

    const {
      id: cardId,
      story: { isReadOnly: storyReadOnly },
      storyId,
      createdAt,
      permissions: cardPermissions,
      isReadOnly: cardReadOnly,
      user,
      originalUserId,
      assignedUserId,
      apiClient,
    } = card;

    const { type: apiClientType, name: apiClientName } = { ...apiClient };

    const { allowToUpdate } = cardPermissions;

    const renderChangeAuthor = useMemo(
      () => allowToUpdate && !storyReadOnly && !cardReadOnly,
      [allowToUpdate, cardReadOnly, storyReadOnly]
    );

    const isCutCard = useMemo(
      () => copyCutAction === CopyCutAction.CUT && cardId === copyCutCardId,
      [copyCutAction, copyCutCardId, cardId]
    );

    const isIntegrationCard = useMemo(
      () => Boolean(!assignedUserId && apiClientType === ApiClientType.API_CLIENTS_EXTERNAL),
      [apiClientType, assignedUserId]
    );

    const sourceCardClick = useCallback(async () => {
      if (!storyId) {
        return;
      }

      const payload = await getStoryCardSource({ itemId: cardId }).unwrap();

      if (!payload) {
        toast.error(t('cardSyncError.open-source-card'));
        return;
      }

      const {
        story,
        channel: { subdomain },
      } = payload;

      window.open(
        `${getWebAppUrl(subdomain)}/admin/details/${story.id}/${cardId}`,
        '_blank',
        'noreferrer'
      );
    }, [cardId, getStoryCardSource, storyId, t]);

    const authorTimeSyncComponent = useMemo(() => {
      return (
        <FeedCardMargin left right>
          <div className={classes['author-time-sync']}>
            {renderChangeAuthor && (
              <FeedCardChangeAuthor
                storyId={storyId}
                cardId={cardId}
                user={user}
                originalUserId={originalUserId}
                {...(isIntegrationCard && { externalName: apiClientName })}
              />
            )}

            <div className={classes['author-time-sync__created-time']}>
              {renderChangeAuthor && <span>•</span>}
              {` ${t('common.created')} ${format(parseISO(createdAt), 'dd.MM.yyyy, HH:mm')} `}
              {cardReadOnly && <span>•</span>}
            </div>

            {cardReadOnly && (
              <IconLabel
                iconId={'sync'}
                iconSize={18}
                label={t('common.main-card')}
                color={getCssVar('--base-link-text-color')}
                hoverColor={getCssVar('--base-link-text-hover-color')}
                onClick={sourceCardClick}
                labelFirst
              />
            )}
          </div>
        </FeedCardMargin>
      );
    }, [
      apiClientName,
      cardId,
      cardReadOnly,
      createdAt,
      isIntegrationCard,
      originalUserId,
      renderChangeAuthor,
      sourceCardClick,
      storyId,
      t,
      user,
    ]);

    if (isCutCard) {
      return <FeedCardCut />;
    }

    if (isCollapsed) {
      <FeedCardCollapsedHeader
        card={card}
        collapsed={cardCollapsed}
        toggleCollapsed={() => setCardCollapsed(!cardCollapsed)}
      />;
    }

    return (
      <>
        {isCollapsed && (
          <FeedCardCollapsedHeader
            card={card}
            collapsed={cardCollapsed}
            toggleCollapsed={() => setCardCollapsed(!cardCollapsed)}
          />
        )}

        {!cardCollapsed && (
          <FeedCardWrapperCommon
            card={card}
            CardActionsComponent={CardActionsAdmin}
            authorTimeSyncComponent={authorTimeSyncComponent}
          >
            {children}
          </FeedCardWrapperCommon>
        )}
      </>
    );
  }
);
