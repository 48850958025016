import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  ChannelBySubdomainPayload,
  channelsNewApi,
  User,
  userApi,
  UserInfo,
  userInfoApi,
} from '../services';
import {
  getChannelDomain,
  getWebAppUrl,
  LOGIN_REDIRECT_STORAGE_KEY,
  useAppDispatch,
} from '../shared';
import { getChannels } from '../slices';

interface LayoutData {
  userProfile: User;
  userInfo: UserInfo;
  channelBySubdomain: ChannelBySubdomainPayload;
}

export const useLayoutData = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [data, setData] = useState<LayoutData>();

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: userProfile } = await dispatch(userApi.endpoints.me.initiate());

        if (!userProfile) {
          localStorage.setItem(LOGIN_REDIRECT_STORAGE_KEY, window.location.href);
          navigate('/auth/login');
          return null;
        }

        const channels = await dispatch(
          getChannels({ filters: { excludeArchived: true } })
        ).unwrap();

        if (!channels || !channels.length) {
          navigate('/noAccess');
          return null;
        }

        const subdomain = getChannelDomain();

        if (!subdomain) {
          window.location.replace(getWebAppUrl(channels[0].subdomain));
          return null;
        }

        const { data: channelBySubdomain } = await dispatch(
          channelsNewApi.endpoints.channelBySubdomain.initiate({ subdomain })
        );

        if (!channelBySubdomain) {
          navigate('/noAccess');
          return null;
        }

        const { data: userInfo } = await dispatch(userInfoApi.endpoints.getUserInfo.initiate());

        if (!userInfo) {
          navigate('/noAccess');
          return null;
        }

        setData({ userProfile, userInfo, channelBySubdomain });
      } catch (e) {
        console.log(e);
      }
    };

    getData();
  }, [dispatch, navigate]);

  return { data };
};
