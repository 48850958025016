import { FunctionComponent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationTagType } from '../../../../../services';
import { useCardFilter, useTags } from '../../../../hooks';
import { isAdminLayout, optArrItem } from '../../../../utils';
import { FileSettings } from '../../../File';
import { Filter } from '../../../Filter';
import { TabItem, Tabs } from '../../../Tabs';
import { TagsPicker } from '../../../TagsPicker';
import { CardSettings } from '../CreateCardModal';

import classes from './CreateCardSettings.module.scss';

export enum CreateCardSettingsTabType {
  CARD,
  TAGS,
  MEDIA,
}

interface CreateCardSettingsProps {
  settings: CardSettings;
  onChange: (settings: CardSettings) => void;
  hideCaption: boolean;
  showEnableComments: boolean;
  showDisplayUser: boolean;
  showMedia: boolean;
  loading: boolean;
  onTabChangeCallback: () => void;
}

export const CreateCardSettings: FunctionComponent<CreateCardSettingsProps> = memo(
  ({
    settings,
    onChange,
    hideCaption,
    showEnableComments,
    showDisplayUser,
    showMedia,
    loading,
    onTabChangeCallback,
  }) => {
    const { t } = useTranslation();

    const { hasInternalTags, hasExternalTags, hasTags } = useTags();

    const { activeSettingsTabIndex, setActiveSettingsTabIndex, card, media, tags } = settings;

    const {
      external = [],
      setExternal = () => null,
      internal = [],
      setInternal = () => null,
    } = { ...tags };

    const { groups: cardGroups } = useCardFilter({
      filter: card,
      showEnableComments,
      showDisplayUser,
    });

    const onTabChange = useCallback(
      (index: number) => {
        setActiveSettingsTabIndex(index);
        onTabChangeCallback();
      },
      [onTabChangeCallback, setActiveSettingsTabIndex]
    );

    const tabItems: TabItem[] = useMemo(
      () => [
        ...optArrItem(isAdminLayout(), {
          index: CreateCardSettingsTabType.CARD,
          name: t('createCardSettingsTabs.card'),
          content: (
            <Filter
              filter={card}
              groups={cardGroups}
              onChange={(card) => onChange({ ...settings, card })}
            />
          ),
        }),
        ...optArrItem(hasTags, {
          index: CreateCardSettingsTabType.TAGS,
          name: t('createCardSettingsTabs.tags'),
          content: (
            <div className={classes['create-card-settings__tags']}>
              {hasInternalTags && (
                <TagsPicker
                  tags={internal}
                  setTags={setInternal}
                  type={OrganisationTagType.INTERNAL}
                />
              )}
              {hasExternalTags && (
                <TagsPicker
                  tags={external}
                  setTags={setExternal}
                  type={OrganisationTagType.EXTERNAL}
                  hideTitle={!isAdminLayout()}
                  hideSubTitle={!isAdminLayout()}
                />
              )}
            </div>
          ),
        }),
        ...optArrItem(showMedia, {
          index: CreateCardSettingsTabType.MEDIA,
          name: t('createCardSettingsTabs.media'),
          content: <FileSettings {...media} hideCaption={hideCaption} loading={loading} />,
        }),
      ],
      [
        card,
        cardGroups,
        external,
        hasExternalTags,
        hasInternalTags,
        hasTags,
        hideCaption,
        internal,
        loading,
        media,
        onChange,
        setExternal,
        setInternal,
        settings,
        showMedia,
        t,
      ]
    );

    return (
      <div className={classes['create-card-settings']}>
        <Tabs
          items={tabItems}
          selectedTabIndex={activeSettingsTabIndex}
          setSelectedTabIndex={onTabChange}
          noBackground
        />
      </div>
    );
  }
);
