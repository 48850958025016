import { FunctionComponent } from 'react';
import { NavbarCommon, NotificationsCommon } from '../../shared';
import { SettingsMenu } from '../SettingsMenu';

export const NavbarAdmin: FunctionComponent = () => {
  return (
    <NavbarCommon>
      <SettingsMenu />
      <NotificationsCommon />
    </NavbarCommon>
  );
};
