import { FunctionComponent, memo, useMemo } from 'react';
import { EmbedLinkBlockData, getCssVar, IconLabel, LineClamp } from '../../../../shared';

import classNames from 'classnames';
import classes from './EmbedLink.module.scss';

export const EmbedLink: FunctionComponent<EmbedLinkBlockData> = memo(({ link, hideUrl }) => {
  const { sourceName, url, title, text, image, quoteSource } = { ...link };

  const renderTeaser = useMemo(() => image?.url || quoteSource, [image?.url, quoteSource]);

  const renderUrl = useMemo(() => !hideUrl && url, [hideUrl, url]);

  const renderSocialDefaultImg = useMemo(
    () => !image?.url && quoteSource,
    [image?.url, quoteSource]
  );

  if (!link) {
    return null;
  }

  return (
    <a
      href={url}
      className={classNames(classes['embed-link'], {
        [classes['embed-link--no-url']]: !url,
      })}
      target={'_blank'}
      rel="noreferrer"
    >
      <div
        className={classNames(classes['embed-link__description'], {
          [classes['embed-link__description--with-teaser']]: renderTeaser,
        })}
      >
        {sourceName && (
          <div className={classes['embed-link__description-source']}>{sourceName}</div>
        )}
        {title && <div className={classes['embed-link__description-title']}>{title}</div>}
        {text && (
          <LineClamp
            maxLines={5}
            contentClassName={classes['embed-link__description-text']}
            readMoreClassName={classes['embed-link__description-text']}
            htmlStringContent={text}
            fallBackLineHeight={24} // used to fix editorJS issue
            readMore={'...'}
          />
        )}
        {renderUrl && (
          <div className={classes['embed-link__description-url']}>
            {quoteSource && (
              <IconLabel
                iconId={quoteSource.toLowerCase()}
                iconSize={18}
                color={getCssVar('--details-block-embed-icon-color')}
                singleColor
              />
            )}
            <span className={classes['embed-link__description-link']}>{url}</span>
          </div>
        )}
      </div>
      {renderTeaser && (
        <div
          className={classNames(classes['embed-link__image'], {
            [classes[`embed-link__image--${quoteSource}`]]: renderSocialDefaultImg,
          })}
          {...(image?.url && { style: { backgroundImage: `url(${unescape(image.url)})` } })}
        ></div>
      )}
    </a>
  );
});
