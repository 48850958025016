import { FunctionComponent, memo, PropsWithChildren, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { layoutPath, timeAgo } from '../../../utils';
import { Avatar } from '../../Avatar';

import classNames from 'classnames';
import classes from './NotificationWrapper.module.scss';

interface NotificationWrapperProps {
  deliveredTime: string;
  avatarUrl?: string;
  onClick?: () => void;
  userId?: number;
  hasLink?: boolean;
}

export const NotificationWrapper: FunctionComponent<PropsWithChildren<NotificationWrapperProps>> =
  memo(({ avatarUrl, deliveredTime, onClick, userId, hasLink, children }) => {
    const renderAvatar = useMemo(() => {
      if (userId) {
        return (
          <Link to={layoutPath(`/profile/${userId}`)}>
            <Avatar url={avatarUrl} />
          </Link>
        );
      }
      return <Avatar url={avatarUrl} onClick={onClick} nonClickable={!hasLink} />;
    }, [avatarUrl, hasLink, onClick, userId]);

    return (
      <div
        className={classNames(classes['notification'], {
          [classes['notification--has-link']]: hasLink,
        })}
      >
        {renderAvatar}

        <div className={classes['notification__content']} onClick={onClick}>
          {children}
          {deliveredTime && (
            <div className={classes['notification__content-delivered-time']}>
              {timeAgo(deliveredTime)}
            </div>
          )}
        </div>
      </div>
    );
  });
