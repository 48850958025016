import { FunctionComponent, memo, useCallback, useEffect, useRef } from 'react';
import { useOnClickOutside } from '../../hooks';
import { IconLabel, IconLabelSizes } from '../IconLabel';

import classNames from 'classnames';
import classes from './Popup.module.scss';

interface PopupProps {
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  body: JSX.Element;
  bodyTop?: string;
  bodyRight?: string;
  bodyBottom?: string;
  bodyLeft?: string;
  bodyWidth?: string;
  iconId?: string;
  iconSize?: number;
  label?: string;
  labelFirst?: boolean;
  labelSize?: IconLabelSizes;
  color?: string;
  hoverColor?: string;
  singleColor?: boolean;
  disabled?: boolean;
  parentClassName?: string;
  className?: string;
  labelClassName?: string;
  onClick?: () => void;
  onClose?: () => void;
}

export const Popup: FunctionComponent<PopupProps> = memo(
  ({
    isOpen,
    setIsOpen,
    body,
    bodyTop,
    bodyRight,
    bodyBottom,
    bodyLeft,
    bodyWidth,
    iconId,
    iconSize,
    label,
    labelFirst,
    labelSize,
    color,
    hoverColor,
    singleColor,
    disabled,
    parentClassName,
    className,
    labelClassName,
    onClick,
    onClose,
  }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(containerRef, () => {
      setIsOpen?.(false);
    });

    const onClickHandler = useCallback(() => {
      setIsOpen?.(!isOpen);
      onClick?.();
    }, [isOpen, onClick, setIsOpen]);

    useEffect(() => {
      if (!isOpen) {
        onClose?.();
      }
    }, [isOpen, onClose]);

    return (
      <div ref={containerRef} className={classNames(classes['popup'], parentClassName)}>
        <IconLabel
          iconId={iconId}
          iconSize={iconSize}
          label={label}
          labelFirst={labelFirst}
          labelSize={labelSize}
          color={color}
          hoverColor={hoverColor}
          disabled={disabled}
          singleColor={singleColor}
          className={className}
          labelClassName={labelClassName}
          onClick={onClickHandler}
        />

        {isOpen && (
          <div
            className={classes['popup__content']}
            style={{
              top: bodyTop,
              right: bodyRight,
              bottom: bodyBottom,
              left: bodyLeft,
              width: bodyWidth,
            }}
          >
            {body}
          </div>
        )}
      </div>
    );
  }
);
