import { addDays, addMinutes, addYears } from 'date-fns';

export enum CookieActionType {
  SET = 'SET',
  REMOVE = 'REMOVE',
}

export enum CookieExpirationType {
  TWO_MINUTES = 'TWO_MINUTES',
  DAY = 'DAY',
  YEAR = 'YEAR',
}

interface CookieOptions {
  action?: CookieActionType;
  domain?: string;
  expiration?: CookieExpirationType;
}

export const cookieOptions = ({
  action = CookieActionType.SET,
  domain = window.location.hostname.split('.').slice(-2).join('.'),
  expiration,
}: CookieOptions = {}) => {
  const opts = { path: '/', domain };

  if (action === CookieActionType.REMOVE) {
    return opts;
  }

  switch (expiration) {
    case CookieExpirationType.TWO_MINUTES:
      return { ...opts, expires: addMinutes(new Date(), 2) };
    case CookieExpirationType.DAY:
      return { ...opts, expires: addDays(new Date(), 1) };
    default:
      return { ...opts, expires: addYears(new Date(), 1) };
  }
};
