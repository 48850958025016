import { FunctionComponent, memo, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import {
  StoryCard,
  StoryCardArticleContent,
  StoryCardPostContent,
  StoryCardTeaserImageStyle,
  StoryCardType,
} from '../../../../services';
import { FileStatus } from '../../../models';
import { getCssVar } from '../../../utils';
import { IconLabel } from '../../IconLabel';
import { ImageGallery, ImageGalleryItem } from '../../ImageGallery';
import { FeedCardContentCommon } from '../FeedCardContentCommon';
import { FeedCardFileError } from '../FeedCardFileError';
import { FeedCardMargin } from '../FeedCardMargin';

import classNames from 'classnames';
import classes from './FeedCardTeaser.module.scss';

interface FeedCardTeaserProps {
  card: StoryCard;
  chatId?: number;
}

export const FeedCardTeaser: FunctionComponent<FeedCardTeaserProps> = memo(({ card, chatId }) => {
  const { type, content, isPinnedToTopOfNewsFeed } = card;

  const { title, abstract, sourceName, gallery, styles } = content as
    | StoryCardArticleContent
    | StoryCardPostContent;

  const iconId = useMemo(
    () => (type === StoryCardType.ARTICLE ? 'arrow-rounded-right-up' : 'arrow-rounded-right'),
    [type]
  );

  if (Boolean(gallery.find(({ image: { status } }) => status === FileStatus.ERROR))) {
    return (
      <FeedCardMargin top right bottom left>
        <FeedCardFileError />
      </FeedCardMargin>
    );
  }

  if (Boolean(gallery.find(({ image: { status } }) => status === FileStatus.PROCESSING))) {
    return (
      <FeedCardMargin top right bottom left>
        <Skeleton height={'5rem'} />
      </FeedCardMargin>
    );
  }

  const image = gallery[0]?.image;

  if (!image) {
    return (
      <FeedCardContentCommon
        type={type}
        pinned={isPinnedToTopOfNewsFeed}
        source={sourceName}
        title={title}
        text={abstract}
        chatId={chatId}
      />
    );
  }

  const imageGalleryItems: ImageGalleryItem[] = gallery.map(({ image }) => {
    return { image };
  });

  const { teaserImageStyle } = styles;

  switch (true) {
    case Boolean(teaserImageStyle === StoryCardTeaserImageStyle.STANDARD || chatId):
      return (
        <>
          <ImageGallery items={imageGalleryItems} />
          <FeedCardContentCommon
            type={type}
            pinned={isPinnedToTopOfNewsFeed}
            source={sourceName}
            title={title}
            text={abstract}
            chatId={chatId}
          />
        </>
      );
    case Boolean(teaserImageStyle === StoryCardTeaserImageStyle.SMALL_WITH_TEXT):
      return (
        <FeedCardContentCommon
          type={type}
          pinned={isPinnedToTopOfNewsFeed}
          source={sourceName}
          title={title}
          text={abstract}
          teaser={image}
          chatId={chatId}
        />
      );
    case Boolean(teaserImageStyle === StoryCardTeaserImageStyle.SMALL_WITHOUT_TEXT):
      return (
        <FeedCardContentCommon
          type={type}
          pinned={isPinnedToTopOfNewsFeed}
          source={sourceName}
          title={title}
          teaser={image}
          chatId={chatId}
        />
      );
    case Boolean(teaserImageStyle === StoryCardTeaserImageStyle.BIG_WITHOUT_TEXT):
      return (
        <div className={classes['large-teaser']}>
          <ImageGallery items={imageGalleryItems} singleImageFullSize />
          <div
            className={classNames(classes['large-teaser__content'], {
              [classes['large-teaser__content--with-copy']]: image?.rightholder,
            })}
          >
            {(sourceName || isPinnedToTopOfNewsFeed) && (
              <div className={classes['large-teaser__content-source-wrapper']}>
                {isPinnedToTopOfNewsFeed && (
                  <IconLabel
                    iconId={'pin'}
                    {...(sourceName && { label: '•' })}
                    color={getCssVar('--card-teaser-text-color')}
                    singleColor
                    nonClickable
                  />
                )}
                {sourceName && (
                  <div className={classes['large-teaser__content-source']}>{sourceName}</div>
                )}
              </div>
            )}
            {title && (
              <div
                className={classNames(classes['large-teaser__content-title'], {
                  [classes['large-teaser__content-title--mobile']]: isMobileOnly,
                })}
              >
                {title}
                <span className={classes['large-teaser__content-title-icon']}>
                  <IconLabel
                    iconId={iconId}
                    iconSize={isMobileOnly ? 20 : 24}
                    color={getCssVar('--card-teaser-icon-color')}
                    singleColor
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      );
    default:
      return null;
  }
});
