/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FileAcceptType,
  TransloaditAuthTemplate,
  UploadHandlerArgs,
  UploadType,
} from '../../../../hooks';
import { FileBlockData } from '../../../../models';
import { BaseBlockTool } from '../baseBlockTool';
import { MediaUpload, MediaUploadPreview, ResetBlockTool } from '../helpers';

interface State extends FileBlockData {
  loading: boolean;
  pasteFile: File | null;
}

type Config = {
  dispatch: any;
  uploadHandler: (args: UploadHandlerArgs) => Promise<{ id: number; assembly_id: string }>;
};

export class FileTool extends BaseBlockTool<State, FileBlockData, Config> {
  static get toolbox() {
    return {
      title: 'File',
      icon: `
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" fill="white"/>
        <rect x="0.5" y="0.5" width="27" height="27" rx="5.5" stroke="#EBEBEB"/>
        <path d="M15.2495 8H10.2499C9.91841 8 9.60049 8.13169 9.36609 8.36609C9.13169 8.60049 9 8.91841 9 9.2499V19.2491C9 19.5806 9.13169 19.8986 9.36609 20.133C9.60049 20.3674 9.91841 20.499 10.2499 20.499H17.7493C18.0808 20.499 18.3987 20.3674 18.6331 20.133C18.8675 19.8986 18.9992 19.5806 18.9992 19.2491V11.7497L15.2495 8Z" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.25 8V11.7497H18.9997" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.4996 14.8743H11.5" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.4996 17.374H11.5" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.7499 12.3745H12.125H11.5" stroke="#1E1E1E" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
    };
  }

  static get pasteConfig() {
    return {
      files: { extensions: FileAcceptType.FILE.split(',').map((ext) => ext.replace(/^\./, '')) },
    };
  }

  onPaste(event: any) {
    if (event.type !== 'file') {
      return;
    }

    this.setState({ pasteFile: event.detail.file });
  }

  getJSXSettings(): JSX.Element | null {
    if (!this.state.items.length) {
      return null;
    }

    return <ResetBlockTool onClick={() => this.setState({ items: [] })} />;
  }

  getJSXTool(): JSX.Element {
    const { loading, items, pasteFile } = this.state;

    const { file = null } = { ...items[0] };

    if (file) {
      return <MediaUploadPreview iconId={'file'} label={this.api.i18n.t('label')} />;
    }

    return (
      <MediaUpload
        {...(this.config as Config)}
        loading={loading}
        setLoading={(loading) => this.setState({ loading })}
        items={items}
        setItems={(items) => this.setState({ items } as FileBlockData)}
        pasteFile={pasteFile}
        template={TransloaditAuthTemplate.FILE}
        uploadType={UploadType.FILE}
        fileAcceptType={FileAcceptType.FILE}
      />
    );
  }

  getDefaultState(data?: FileBlockData): State {
    return { items: data?.items ?? [], loading: false, pasteFile: null };
  }

  validate({ items }: FileBlockData): boolean {
    return Boolean(items.length);
  }

  save(): FileBlockData {
    return { items: this.state.items };
  }
}
