import { rootApi } from './rootApi';

interface ForgotArgs {
  email: string;
  generateMagicLink?: boolean;
  organisation: string;
}

interface ForgotTokenCheckArgs {
  token: string;
}

interface ForgotSetArgs {
  token: string;
  pwd: string;
}

export const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    logout: builder.query<void, void>({
      query: () => '/logout',
    }),
    forgot: builder.mutation<void, ForgotArgs>({
      query: ({ email, organisation, generateMagicLink }) => ({
        url: '/webapp/forgot_password',
        method: 'POST',
        body: { email, organisation, generateMagicLink },
      }),
    }),
    forgotTokenCheck: builder.query<void, ForgotTokenCheckArgs>({
      query: ({ token }) => `/forgot_password_set?token=${token}`,
    }),
    forgotSet: builder.mutation<void, ForgotSetArgs>({
      query: ({ pwd, token }) => ({
        url: `/forgot_password_set?token=${token}`,
        method: 'POST',
        body: { pwd },
      }),
    }),
  }),
});

export const { useLogoutQuery, useForgotMutation, useForgotSetMutation, useForgotTokenCheckQuery } =
  authApi;
