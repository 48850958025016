import { ElementType, FunctionComponent, memo, useContext, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../context';
import { StoryCardStatus } from '../../services';
import {
  DetailsContentProps,
  FeedCardPlaceholder,
  useAnalytics,
  useAppDispatch,
  useAppSelector,
  useDeepLink,
} from '../../shared';
import { getCard } from '../../slices';
import { NoContent } from '../Error';

interface CardDetailsProps {
  ContentComponent: ElementType<DetailsContentProps>;
}

export const CardDetails: FunctionComponent<CardDetailsProps> = memo(({ ContentComponent }) => {
  const dispatch = useAppDispatch();

  const { logClickOpenArticle } = useAnalytics();

  const { isDraft } = useDeepLink();

  const { card, isFetching } = useAppSelector(({ cardDetails }) => cardDetails);

  const { channelId } = useContext(UserContext).userInfo.userData;

  const [searchParams] = useSearchParams();

  const storyId = Number(useParams().storyId);

  const cardId = Number(useParams().cardId);

  const scrollToComments = searchParams.get('scrollToComments') === 'true';

  const scrollToTags = searchParams.get('scrollToTags') === 'true';

  useEffect(() => {
    logClickOpenArticle({
      channel_id: channelId,
      story_id: storyId,
      item_id: cardId,
      time_stamp: new Date().toISOString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logClickOpenArticle]);

  useEffect(() => {
    dispatch(getCard({ storyId, cardId }));
  }, [cardId, dispatch, storyId]);

  const content = useMemo(() => {
    if (isFetching) {
      return <FeedCardPlaceholder />;
    }

    if (!card) {
      return <NoContent />;
    }

    if (card.status === StoryCardStatus.PUBLISHED || isDraft) {
      return (
        <ContentComponent
          card={card}
          scrollToComments={scrollToComments}
          scrollToTags={scrollToTags}
        />
      );
    }

    return <NoContent />;
  }, [ContentComponent, card, isDraft, isFetching, scrollToComments, scrollToTags]);

  return content;
});
