import { FunctionComponent, memo, useContext, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { UserContext } from '../../../../context';
import { StoryCard, StoryCardAudioContent } from '../../../../services';
import { useAnalytics, useCardTitle } from '../../../hooks';
import { FileStatus } from '../../../models';
import { ImageGallery, ImageGalleryItem } from '../../ImageGallery';
import { MusicPlayerTrigger } from '../../MusicPlayer/MusicPlayerTrigger';
import { FeedCardFileError } from '../FeedCardFileError';
import { FeedCardMargin } from '../FeedCardMargin';

interface FeedCardAudioCommonProps {
  card: StoryCard;
  chatId?: number;
}

export const FeedCardAudioCommon: FunctionComponent<FeedCardAudioCommonProps> = memo(
  ({ card, chatId }) => {
    const { channelId } = useContext(UserContext).userInfo.userData;

    const { logClickOpenArticle } = useAnalytics();

    const { id: cardId, storyId, content } = card;

    const cardTitle = useCardTitle(card);

    const { gallery } = content as StoryCardAudioContent;

    const galleryItem = useMemo(() => gallery[0], [gallery]);

    const { audio, title } = { ...galleryItem };

    const imageContent = useMemo(() => {
      switch (true) {
        case Boolean(gallery.find(({ image }) => image?.status === FileStatus.ERROR)):
          return (
            <FeedCardMargin top right bottom left>
              <FeedCardFileError />
            </FeedCardMargin>
          );
        case Boolean(gallery.find(({ image }) => image?.status === FileStatus.PROCESSING)):
          return (
            <FeedCardMargin top right bottom left>
              <Skeleton height={'5rem'} />
            </FeedCardMargin>
          );
        default: {
          const imageGalleryItems: ImageGalleryItem[] = gallery
            .map(({ image }) => {
              return {
                image: {
                  url: image?.url,
                  rightholder: image?.rightholder,
                  averageColor: image?.averageColor,
                },
              };
            })
            .filter(({ image }) => Boolean(image.url));

          const logCommonProps = {
            channel_id: channelId,
            story_id: storyId,
            item_id: cardId,
            time_stamp: new Date().toISOString(),
          };

          return (
            <ImageGallery
              items={imageGalleryItems}
              modalPreviewOnClick
              onLogClickOpenArticle={() => logClickOpenArticle(logCommonProps)}
            />
          );
        }
      }
    }, [cardId, channelId, gallery, logClickOpenArticle, storyId]);

    const audioContent = useMemo(() => {
      switch (audio.status) {
        case FileStatus.ERROR:
          return (
            <FeedCardMargin top right bottom left>
              <FeedCardFileError />
            </FeedCardMargin>
          );
        case FileStatus.PROCESSING:
          return (
            <FeedCardMargin top right bottom left>
              <Skeleton height={'5rem'} />
            </FeedCardMargin>
          );
        default:
          return (
            <>
              {audio.url && (
                <FeedCardMargin top right left>
                  <MusicPlayerTrigger
                    storyId={storyId}
                    cardId={cardId}
                    cardTitle={cardTitle}
                    src={audio.url}
                    caption={title}
                    chatId={chatId}
                  />
                </FeedCardMargin>
              )}
            </>
          );
      }
    }, [audio.status, audio.url, cardId, cardTitle, chatId, storyId, title]);

    return (
      <>
        {imageContent}
        {audioContent}
      </>
    );
  }
);
