import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context';
import { FilterValue } from '../../components';
import { LanguageByCode, optArrItem } from '../../utils';
import { useFilter } from './useFilter';

export const CARD = 'card';
export const CARD_LOCALE = 'localeId';

export enum CardProps {
  allowComments = 'allowComments',
  allowCardReactions = 'allowCardReactions',
  allowSharing = 'allowSharing',
  shouldUpdatePostedTimeByComments = 'shouldUpdatePostedTimeByComments',
  showAllAuthors = 'showAllAuthors',
}

interface UseCardFilterArgs {
  filter: FilterValue[];
  showDisplayUser?: boolean;
  showEnableComments?: boolean;
}

export const useCardFilter = ({
  filter,
  showDisplayUser = true,
  showEnableComments = true,
}: UseCardFilterArgs) => {
  const { t } = useTranslation();

  const { getGroupValue } = useFilter();

  const { localeId } = useContext(UserContext).userInfo.userData;

  const locales = useMemo(
    () => [
      { id: 1, code: 'en-US' },
      { id: 2, code: 'de-DE' },
      { id: 3, code: 'es-ES' },
    ],
    []
  );

  const localeValue = useMemo(
    () => getGroupValue({ filter, groupId: CARD_LOCALE }) ?? localeId,
    [filter, getGroupValue, localeId]
  );

  const localeDropdown = useMemo(() => {
    return {
      options: locales.map(({ id, code }) => {
        return { id, title: LanguageByCode[code as keyof typeof LanguageByCode] };
      }),
    };
  }, [locales]);

  return {
    groups: [
      {
        id: CARD,
        multiCheck: true,
        items: [
          ...optArrItem(showEnableComments, {
            id: CardProps.allowComments,
            label: t('cardFilter.enable-comments'),
            value: CardProps.allowComments,
          }),
          {
            id: CardProps.allowCardReactions,
            label: t('cardFilter.enable-reactions'),
            value: CardProps.allowCardReactions,
          },
          {
            id: CardProps.allowSharing,
            label: t('cardFilter.enable-sharing'),
            value: CardProps.allowSharing,
          },
          {
            id: CardProps.shouldUpdatePostedTimeByComments,
            label: t('cardFilter.update-time-by-comments'),
            value: CardProps.shouldUpdatePostedTimeByComments,
          },
          ...optArrItem(showDisplayUser, {
            id: CardProps.showAllAuthors,
            label: t('cardFilter.display-user'),
            value: CardProps.showAllAuthors,
          }),
        ],
      },
      {
        id: CARD_LOCALE,
        title: t('cardFilter.default-language'),
        value: localeValue,
        dropdown: localeDropdown,
      },
    ],
  };
};
