import { useCallback, useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ConfigContext, UserContext } from '../../../context';
import { Button, ButtonType, IconLabel, Modal, TextAreaField } from '../../components';
import { ABUSE_REPORT_COOKIE_KEY } from '../../constants';
import { cookieOptions, optArrItem } from '../../utils';

import classes from './useAbuseReportPopup.module.scss';

export enum AbuseReportType {
  Post = 'post',
  Comment = 'comment',
  User = 'user',
  Chat = 'chat',
  Message = 'message',
}

export enum AbuseReason {
  NudityOrSexualActivity = 'nudity_or_sexual_activity',
  HateSpeechOrSymbols = 'hate_speech_or_symbols',
  ViolenceOrDangerousOrganizations = 'violence_or_dangerous_organizations',
  SaleOfIllegalOrRegulatedGoods = 'sale_of_illegal_or_regulated_goods',
  BullyingOrHarassment = 'bullying_or_harassment',
  IntellectualPropertyViolation = 'intellectual_property_violation',
  SuicideSelfInjuryOrEating = 'suicide_self_injury_or_eating',
  ScamOrFraud = 'scam_or_fraud',
  FalseInformation = 'false_information',
  SomethingElse = 'something_else',
}

interface ReporterInfo {
  id: string;
  email?: string;
  name: string;
}

export interface AbuseReport {
  type: AbuseReportType;
  reason: AbuseReason;
  reportedBy?: ReporterInfo;
  reporterComment?: string;
}

export interface PostAbuseReport extends AbuseReport {
  channelId: string;
  channelName: string;
  storyId: string;
  storyName: string;
  postId: string;
  postType: string;
  postTitle: string;
  authorId?: string;
  authorName?: string;
}

export interface CommentAbuseReport extends AbuseReport {
  channelId: string;
  channelName: string;
  storyId: string;
  storyName: string;
  postId: string;
  postType: string;
  postTitle: string;
  authorId?: string;
  authorName?: string;
  commentId: string;
  commentText: string;
  commentAuthorId?: string;
  commentAuthorName?: string;
}

export interface ChatAbuseReport extends AbuseReport {
  channelId?: string;
  channelName?: string;
  chatId: string;
  chatName: string;
  chatType: string;
}

export interface MessageAbuseReport extends ChatAbuseReport {
  messageId: string;
  messageText: string;
  messageAuthorId: string;
  messageAuthorName: string;
  messageType: string;
}

export interface UserAbuseReport extends AbuseReport {
  channelId: string;
  channelName: string;
  userID: string;
  userName: string;
  userEmail?: string;
}

interface AbuseReportProps {
  type: AbuseReportType;
  userID?: number;
  userName?: string;
  userEmail?: string;
  storyId?: number;
  storyName?: string;
  postId?: number;
  postType?: string;
  postTitle?: string;
  authorId?: number;
  authorName?: string;
  commentId?: number;
  commentText?: string;
  commentAuthorId?: number;
  commentAuthorName?: string;
  chatId?: number;
  chatName?: string;
  chatType?: string;
  messageId?: string;
  messageText?: string;
  messageAuthorId?: string;
  messageAuthorName?: string;
  messageType?: string;
}

export const useAbuseReportPopup = ({
  type,
  userID,
  userEmail,
  userName,
  storyId,
  storyName,
  postId,
  postType,
  postTitle,
  authorId,
  authorName,
  commentId,
  commentText,
  commentAuthorId,
  commentAuthorName,
  chatId,
  chatName,
  chatType,
  messageId,
  messageText,
  messageAuthorId,
  messageAuthorName,
  messageType,
}: AbuseReportProps) => {
  const { t } = useTranslation();

  const { appDomain } = useContext(ConfigContext).config.elements.abuseReport;

  const { channelId, channelName, user_id, name, email } = useContext(UserContext).userInfo.body;

  const [isOpen, setIsOpen] = useState(false);

  const [reason, setReason] = useState<AbuseReason>();

  const [reporterComment, setReporterComment] = useState<string>('');

  const [cookies, setCookie] = useCookies([ABUSE_REPORT_COOKIE_KEY]);

  const {
    reportedUserIds = [],
    reportedPostIds = [],
    reportedCommentIds = [],
    reportedChatIds = [],
    reportedMessageIds = [],
  } = {
    ...cookies[ABUSE_REPORT_COOKIE_KEY],
  };

  const onClose = useCallback(() => {
    setIsOpen(false);
    setReason(undefined);
    setReporterComment('');
  }, []);

  const reported = useMemo(() => {
    switch (type) {
      case AbuseReportType.User:
        return reportedUserIds.includes(userID);
      case AbuseReportType.Post:
        return reportedPostIds.includes(postId);
      case AbuseReportType.Comment:
        return reportedCommentIds.includes(commentId);
      case AbuseReportType.Chat:
        return reportedChatIds.includes(chatId);
      case AbuseReportType.Message:
        return reportedMessageIds.includes(messageId);
      default:
        return false;
    }
  }, [
    chatId,
    commentId,
    messageId,
    postId,
    reportedChatIds,
    reportedCommentIds,
    reportedMessageIds,
    reportedPostIds,
    reportedUserIds,
    type,
    userID,
  ]);

  const submitHandler = useCallback(async () => {
    onClose();

    if (!appDomain) {
      return;
    }

    const response = await toast.promise(
      fetch(`${appDomain}/api/v1/abuse-reports`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type,
          reason,
          channelId,
          channelName,
          reporterComment,
          reportedBy: { id: user_id, email, name },
          ...(userID && { userID }),
          ...(userEmail && { userEmail }),
          ...(userName && { userName }),
          ...(storyId && { storyId }),
          ...(storyName && { storyName }),
          ...(postId && { postId }),
          ...(postType && { postType }),
          ...(postTitle && { postTitle }),
          ...(authorId && { authorId }),
          ...(authorName && { authorName }),
          ...(commentId && { commentId }),
          ...(commentText && { commentText }),
          ...(commentAuthorId && { commentAuthorId }),
          ...(commentAuthorName && { commentAuthorName }),
          ...(chatId && { chatId }),
          ...(chatName && { chatName }),
          ...(chatType && { chatType }),
          ...(messageId && { messageId }),
          ...(messageText && { messageText }),
          ...(messageAuthorId && { messageAuthorId }),
          ...(messageAuthorName && { messageAuthorName }),
          ...(messageType && { messageType }),
        }),
      }),
      {
        pending: t('abuseReport.submitting'),
        success: t('abuseReport.submitted'),
        error: t('abuseReport.submit-error'),
      }
    );

    if (response.ok) {
      setCookie(
        ABUSE_REPORT_COOKIE_KEY,
        {
          reportedUserIds: [
            ...reportedUserIds,
            ...optArrItem(type === AbuseReportType.User, userID),
          ],
          reportedPostIds: [
            ...reportedPostIds,
            ...optArrItem(type === AbuseReportType.Post, postId),
          ],
          reportedCommentIds: [
            ...reportedCommentIds,
            ...optArrItem(type === AbuseReportType.Comment, commentId),
          ],
          reportedChatIds: [
            ...reportedChatIds,
            ...optArrItem(type === AbuseReportType.Chat, chatId),
          ],
          reportedMessageIds: [
            ...reportedMessageIds,
            ...optArrItem(type === AbuseReportType.Message, messageId),
          ],
        },
        cookieOptions()
      );
    }
  }, [
    appDomain,
    authorId,
    authorName,
    channelId,
    channelName,
    chatId,
    chatName,
    chatType,
    commentAuthorId,
    commentAuthorName,
    commentId,
    commentText,
    email,
    messageAuthorId,
    messageAuthorName,
    messageId,
    messageText,
    messageType,
    name,
    onClose,
    postId,
    postTitle,
    postType,
    reason,
    reportedChatIds,
    reportedCommentIds,
    reportedMessageIds,
    reportedPostIds,
    reportedUserIds,
    reporterComment,
    setCookie,
    storyId,
    storyName,
    t,
    type,
    userEmail,
    userID,
    userName,
    user_id,
  ]);

  const bodyContent = useMemo(() => {
    if (!reason) {
      return (
        <div className={classes['abuse-report__reasons']}>
          {Object.entries(AbuseReason).map(([key, value]) => {
            return (
              <span
                key={key}
                className={classes['abuse-report__reasons-item']}
                onClick={() => setReason(value)}
              >
                {t(`abuseReportReasons.${value}`)}
                <IconLabel iconId={'arrow-right'} iconSize={18} singleColor />
              </span>
            );
          })}
        </div>
      );
    }

    return (
      <>
        <TextAreaField
          placeholder={t('abuseReport.comment-placeholder')}
          value={reporterComment}
          maxLength={2000}
          onChange={({ target }) => setReporterComment(target.value)}
          disableLineBreak
        />

        <div className={classes['abuse-report__buttons']}>
          <Button
            type={ButtonType.secondary}
            label={t('common.back')}
            onClick={() => setReason(undefined)}
          />
          <Button type={ButtonType.primary} label={t('common.submit')} onClick={submitHandler} />
        </div>
      </>
    );
  }, [reason, reporterComment, submitHandler, t]);

  const body = useMemo(() => {
    return (
      <div className={classes['abuse-report']}>
        <div className={classes['abuse-report__title']}>{t('abuseReport.title')}</div>
        <div className={classes['abuse-report__subTitle']}>{t('abuseReport.sub-title')}</div>
        {bodyContent}
      </div>
    );
  }, [bodyContent, t]);

  const abuseReportModal = useMemo(() => {
    if (!isOpen) {
      return null;
    }

    return <Modal isOpen={isOpen} body={body} onClose={onClose} alignTop />;
  }, [body, isOpen, onClose]);

  const abuseReportHandler = useCallback(async () => {
    if (reported) {
      toast.info(t('abuseReport.was-reported'));
      return;
    }

    setIsOpen(true);
  }, [reported, t]);

  return { abuseReportModal, abuseReportHandler };
};
