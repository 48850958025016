/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, createRef } from 'react';
import { ImageBlockData } from '../../../../models';
import { IconLabel } from '../../../IconLabel';
import { BaseBlockTool } from '../baseBlockTool';

import {
  FileAcceptType,
  TransloaditAuthTemplate,
  UploadHandlerArgs,
  UploadType,
} from '../../../../hooks';
import { MediaUpload } from '../helpers';

import classNames from 'classnames';
import classes from './Gallery.module.scss';

const UPLOAD_LIMIT = 10;

interface State extends ImageBlockData {
  loading: boolean;
  selectId: number;
  dragId: number;
  pasteFile: File | null;
}

type Config = {
  dispatch: any;
  uploadHandler: (args: UploadHandlerArgs) => Promise<{ id: number; assembly_id: string }>;
};

export class Gallery extends BaseBlockTool<State, ImageBlockData, Config> {
  inputRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

  captionRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  static get toolbox() {
    return {
      title: 'ImageGallery',
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect x="5" y="5" width="14" height="14" rx="4" stroke="black" stroke-width="2"/>
          <path d="M5.13968 15.32L8.69058 11.5661C9.02934 11.2036 9.48873 11 9.96774 11C10.4467 11 10.9061 11.2036 11.2449 11.5661L15.3871 16M13.5806 14.0664L15.0132 12.533C15.3519 12.1705 15.8113 11.9668 16.2903 11.9668C16.7693 11.9668 17.2287 12.1705 17.5675 12.533L18.841 13.9634" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.7778 9.33331H13.7867" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
    };
  }

  static get pasteConfig() {
    return { files: { mimeTypes: [FileAcceptType.IMAGE] } };
  }

  onPaste(event: any) {
    if (event.type !== 'file') {
      return;
    }

    this.setState({ pasteFile: event.detail.file });
  }

  getJSXTool(): JSX.Element {
    const { loading, items, pasteFile, selectId, dragId } = this.state;

    const isDraggable = !loading && items.length > 1;

    const caption = items.find(({ image }) => image.id === selectId)?.caption ?? '';

    const preview = (
      <div className={classes['gallery__preview-wrapper']}>
        {items.map(({ image, caption }, index) => {
          const { id, url } = image;

          const settingsClickHandler = () => {
            const newSelectId = selectId === id ? 0 : id;

            this.setState({ selectId: newSelectId });

            if (newSelectId) {
              setTimeout(() => {
                const selection = window.getSelection();
                const range = document.createRange();
                selection?.removeAllRanges();
                range.selectNodeContents(this.captionRef.current as HTMLDivElement);
                range.collapse(false);
                selection?.addRange(range);
                this.captionRef.current?.focus();
              }, 0);
            }
          };

          return (
            <div
              key={id}
              draggable={isDraggable}
              onDragStart={() => this.onDragStart(id)}
              onDrop={(event) => this.onDrop(event, id)}
              onDragEnd={this.onDragEnd}
              onDragLeave={this.onDragLeave}
              onDragOver={this.onDragOver}
              className={classNames(classes['gallery__preview'], {
                [classes['gallery__preview--loading']]: loading,
                [classes['gallery__preview--move']]: isDraggable,
                [classes['gallery__preview--dragging']]: dragId,
              })}
            >
              <div
                className={classes['gallery__preview-img']}
                style={{ backgroundImage: `url(${url})` }}
              ></div>
              <div className={classes['gallery__preview-actions']}>
                <span
                  className={classNames(classes['gallery__preview-actions-settings'], {
                    [classes['gallery__preview-actions-settings--active']]: id === selectId,
                  })}
                  onClick={settingsClickHandler}
                >
                  <IconLabel iconId={caption ? 'pencil' : 'dots-menu'} iconSize={30} />
                </span>
                <span
                  className={classes['gallery__preview-actions-remove']}
                  onClick={() => this.removePreview(index)}
                ></span>
              </div>
            </div>
          );
        })}
      </div>
    );

    return (
      <div className={classes['gallery-wrapper']}>
        {Boolean(items.length < UPLOAD_LIMIT) && (
          <MediaUpload
            {...(this.config as Config)}
            loading={loading}
            setLoading={(loading) => this.setState({ loading })}
            items={items}
            setItems={(items) => this.setState({ items } as ImageBlockData)}
            pasteFile={pasteFile}
            template={TransloaditAuthTemplate.ITEM_FILE}
            uploadType={UploadType.IMAGE}
            fileAcceptType={FileAcceptType.IMAGE}
            uploadLimit={UPLOAD_LIMIT}
            multiple
          />
        )}

        <div className={classes['gallery']}>
          {Boolean(loading || items.length) && preview}

          {Boolean(selectId) && (
            <div className={classes['gallery__caption']}>
              <div className={classes['gallery__caption-title']}>{this.api.i18n.t('caption')}</div>
              <div
                contentEditable
                ref={this.captionRef}
                className={classes['gallery__caption-input']}
                dangerouslySetInnerHTML={{ __html: caption }}
                onBlur={({ target }) => this.setCaption(target.innerHTML)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  private setCaption = (caption: string) => {
    const { items, selectId } = this.state;

    this.setState({
      items: items.map((item) => (item.image.id === selectId ? { ...item, caption } : item)),
    });
  };

  private onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    (event.target as HTMLDivElement).classList.add(classes['gallery__preview--ghost']);
  };

  private onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    (event.target as HTMLDivElement).classList.remove(classes['gallery__preview--ghost']);
  };

  private onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    (event.target as HTMLDivElement).classList.remove(classes['gallery__preview--ghost']);
    this.setState({ dragId: 0 });
  };

  private onDragStart = (id: number) => {
    this.setState({ dragId: id });
  };

  private onDrop = (event: React.DragEvent<HTMLDivElement>, id: number) => {
    event.preventDefault();

    (event.target as HTMLDivElement).classList.remove(classes['gallery__preview--ghost']);

    const items = [...this.state.items];

    const dragIndex = items.findIndex((item) => item.image.id === this.state.dragId);

    if (dragIndex === -1) {
      this.setState({ dragId: 0 });
      return;
    }

    const currentIndex = items.findIndex((item) => item.image.id === id);

    [items[dragIndex], items[currentIndex]] = [items[currentIndex], items[dragIndex]];

    this.setState({ items, dragId: 0 });
  };

  private removePreview = (index: number) => {
    const items = [...this.state.items];

    const { image } = items.splice(index, 1)[0];

    this.setState({ items, ...(this.state.selectId === image.id && { selectId: 0 }) });
  };

  getDefaultState(data: ImageBlockData): State {
    return {
      items: data?.items ?? [],
      stretched: false,
      withBackground: false,
      withBorder: false,
      loading: false,
      selectId: 0,
      dragId: 0,
      pasteFile: null,
    };
  }

  validate({ items }: ImageBlockData): boolean {
    return Boolean(items.length);
  }

  save(): ImageBlockData {
    const { items, stretched, withBorder, withBackground } = this.state;

    return {
      items,
      stretched,
      withBorder,
      withBackground,
    };
  }
}
