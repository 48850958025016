import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedCardAdmin, HorizontalStoryFeedAdmin } from '../../components';
import { OrganisationContext, UserContext } from '../../context';
import { StorySection } from '../../services';
import {
  COLLAPSE_STORAGE_KEY,
  CollapseMode,
  CollapseSwitcher,
  CreateCardAdmin,
  FeedCardCollapsedLoader,
  FeedCardLoader,
  NewsFeedCommon,
  useAppSelector,
  useNewsFeed,
} from '../../shared';
import { NoContent } from '../Error';

import classes from './NewsFeedAdmin.module.scss';

export const NewsFeedAdmin: FunctionComponent = () => {
  const { t } = useTranslation();

  const { channels } = useAppSelector(({ channels }) => channels);

  const { channelId } = useContext(UserContext).userInfo.userData;

  const channelPinnedStoryId = channels.find(({ id }) => id === channelId)?.pinnedStoryId;

  const { pinnedStoryId: organisationPinnedStoryId } = useContext(OrganisationContext).organisation;

  const {
    getNewsFeedItems,
    loadMoreNewsFeedItems,
    getPinnedFeedItems,
    loadMorePinnedFeedItems,
    pinnedStorySection,
  } = useNewsFeed();

  const { newsFeed, isFetching: isFetchingNewsFeed } = useAppSelector(({ newsFeed }) => newsFeed);

  const [collapseMode, setCollapseMode] = useState(
    localStorage.getItem(COLLAPSE_STORAGE_KEY) || CollapseMode.STANDARD
  );

  useEffect(() => {
    window.addEventListener('storage', () => {
      setCollapseMode(localStorage.getItem(COLLAPSE_STORAGE_KEY) || CollapseMode.STANDARD);
    });
  }, []);

  const isPinnedStoryChannelSection = useMemo(
    () => pinnedStorySection === StorySection.PINNED_CHANNEL,
    [pinnedStorySection]
  );

  const pinnedStoryId = useMemo(() => {
    return (isPinnedStoryChannelSection ? channelPinnedStoryId : organisationPinnedStoryId) ?? -1;
  }, [channelPinnedStoryId, isPinnedStoryChannelSection, organisationPinnedStoryId]);

  useEffect(() => {
    if (pinnedStorySection) {
      getPinnedFeedItems(pinnedStoryId);
      return;
    }

    getNewsFeedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = useCallback(() => {
    if (pinnedStorySection) {
      loadMorePinnedFeedItems(pinnedStoryId);
      return;
    }

    loadMoreNewsFeedItems();
  }, [loadMoreNewsFeedItems, loadMorePinnedFeedItems, pinnedStoryId, pinnedStorySection]);

  const isCollapsed = useMemo(() => collapseMode === CollapseMode.COLLAPSED, [collapseMode]);

  const loader = useMemo(
    () => (isCollapsed ? <FeedCardCollapsedLoader /> : <FeedCardLoader />),
    [isCollapsed]
  );

  const hasCards = useMemo(() => Boolean(newsFeed.length), [newsFeed.length]);

  const content = useMemo(() => {
    if (isFetchingNewsFeed) {
      return loader;
    }

    if (!hasCards) {
      return <NoContent />;
    }

    return (
      <NewsFeedCommon
        loader={loader}
        loadMore={loadMore}
        HorizontalStoryFeedComponent={HorizontalStoryFeedAdmin}
        CardComponent={FeedCardAdmin}
      />
    );
  }, [hasCards, isFetchingNewsFeed, loadMore, loader]);

  return (
    <div className={classes['container']}>
      <div className={classes['container__header']}>
        <div className={classes['container__header-title']}>{t('adminLayoutMenu.content')}</div>
        <CollapseSwitcher
          storageKey={COLLAPSE_STORAGE_KEY}
          className={classes['container__header-switcher']}
        />
      </div>
      <CreateCardAdmin />
      {content}
    </div>
  );
};
